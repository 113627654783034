(function() {

    'use strict';

    angular.module('kennwerteApp')
        .factory('XlsxService', XlsxService);

    XlsxService.$inject = ['$http', 'FileSaver'];

    function XlsxService($http, FileSaver) {
        return {
            downloadXlsx: function (realEstateId, xlsxName) {
                return $http.get('api/xlsx/download?id=' + realEstateId, {responseType: 'blob'})
                    .then(function(response) {
                        var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                        FileSaver.saveAs(data, xlsxName + '.xlsx');
                    });
            }
        };
    }
})();
