(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('banner', BannerDirective);

    BannerDirective.$inject = [];

    function BannerDirective() {
        return {
            transclude: true,
            templateUrl: 'app/components/banner/banner.html',
            controller: BannerController,
            controllerAs: 'vm',
            scope: {
                mode: '@?' // values: BOOTSTRAP, FLEX, CONTENT_ROW
            }
        };
    }

    BannerController.$inject = ['$scope', '$timeout', '$window'];

    function BannerController($scope, $timeout, $window) {
        var vm = this;

        // values BOOTSTRAP, FLEX_IMAGE, FLEX_FORM
        if (!$scope.mode) {
            vm.mode = 'BOOTSTRAP';
        } else {
            vm.mode = $scope.mode;
        }

        var origWidth = 1920;
        var origHeight = 513;
        var minHeight = 375;
        var diffText1 = 160;
        var diffText2 = 185;

        function heightValueFun() {
            var imgWidth = $('.bannerImg').width();
            var wRatio = imgWidth / origWidth;
            return Math.max(minHeight, origHeight * wRatio);
        }

        //var imgHeight = 1081;
        var w = Math.max(origWidth, $window.innerWidth);
        var h = w > origWidth ? w/origWidth*origHeight : origHeight;

        var height = heightValueFun();
        vm.height =  height + 'px';
        vm.width = $('.bannerImg').width();
        vm.marginTopContentRow = vm.width > 767 ? ((height-diffText1) + 'px') : ((height - diffText2) + 'px');

        vm.onResize = function () {
            $timeout(function() {
                var height = heightValueFun();
                vm.height = height + 'px';
                vm.width = $('.bannerImg').width();
                vm.marginTopContentRow = vm.width > 767 ? ((height-diffText1) + 'px') : ((height - diffText2) + 'px');
            });
        };

    }

})();
