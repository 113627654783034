(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('mapsIntegration', MapsIntegrationDirective);

    MapsIntegrationDirective.$inject = ['$rootScope', '$window'];

    function MapsIntegrationDirective($rootScope, $window) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                mandatory: '@',
                descriptionBaseKey: '@'
            },
            templateUrl: 'app/components/maps/maps-integration.tpl.html',
            link: function (scope, tElement, tAttrs) {

                scope.googleLink = function () {
                    if (scope.disableButtons) {
                        //TODO "Schweiz" hardcoded in the url. To be adapted.
                        var link = (scope.$parent.$parent.realEstateContainer.location.street ? scope.$parent.$parent.realEstateContainer.location.street : "") + " " + scope.$parent.$parent.realEstateContainer.location.zipCode + " " + scope.$parent.$parent.realEstateContainer.location.place + " Schweiz";
                        $window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(link));
                    }
                };

                scope.$parent.$watch('[referenceZipcode, referenceLocation, reference]', function () {

                    if (scope.$parent.$parent.realEstateContainer.location.zipCode && scope.$parent.$parent.realEstateContainer.location.zipCode.toString().length > 0
                        && scope.$parent.$parent.realEstateContainer.location.place && scope.$parent.$parent.realEstateContainer.location.place.toString().length >= 3
                    ) {
                        scope.disableButtons = true;
                    } else {
                        scope.disableButtons = false;
                    }
                });

            }
        };
    }
})();
