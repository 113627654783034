(function () {
    "use strict";

    angular.module("kennwerteApp")
        .factory("RealestateService", RealestateService);

    RealestateService.$inject = ["$resource", "$q"];

    function RealestateService($resource, $q) {
        // var realEstate = {};
        var service = {
            getRealEstate: get,
            realEstate: {},
            setRealEstate: set,
            createEmptyRealEstate: createEmptyRealEstate,
            getByIdCached: getByIdCached,
            getByIdForceLoad: getByIdForceLoad
        };

        function get() {
            return service.realEstate;
        }

        function set(realEstateContainer) {
            service.realEstate = realEstateContainer;
        }

        function createEmptyRealEstate(newRealEstateDto) {
            var deferred = $q.defer();
            service.api.save(newRealEstateDto, function(newRealEstate) {
                service.realEstate = angular.copy(newRealEstate);

                deferred.resolve(service.realEstate);
            }, function(data, status, headers, config) {
                deferred.rejected('Rejected :', +status);
            });
            return deferred.promise;
        }

        function getByIdCached(id) {
            console.warn(id);
            if (id == null) {
                var defer = $q.defer();
                service.api.get({ id: null }, function(realestate) {
                    service.realEstate = angular.copy(realestate);
                    defer.resolve(service.realEstate);
                });
                return defer.promise;
            }
            if (id == service.realEstate.id) {
                var deferred = $q.defer();
                deferred.resolve(service.realEstate);
                return deferred.promise;
            } else {
                var defer2 = $q.defer();
                service.api.get({ id: id }, function(realestate) {
                    service.realEstate = angular.copy(realestate);
                    defer2.resolve(service.realEstate);

                });
                return defer2.promise;
            }
        }

        function getByIdForceLoad(id) {
            console.error('force load id');
            var defer2 = $q.defer();
            service.api.get({ id: id }, function(realestate) {
                service.realEstate = angular.copy(realestate);
                defer2.resolve(service.realEstate);

            });
            return defer2.promise;
        }


        service.api = $resource('api/realestates/:id', { id: '@_id' }, {
            'query': {
                method: 'GET', isArray: true
            },
            'update': {
                method: 'PUT' // this method issues a PUT request
            },
            'access': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'setFavorite': {
                method: 'POST',
                url: 'api/realestates/set_favorite'
            }
        });

    return service;

}
})();
