(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('SmallBuildingAgvInsuranceCalculatingErrorController', SmallBuildingAgvInsuranceCalculatingErrorController);

    SmallBuildingAgvInsuranceCalculatingErrorController.$inject = [];

    function SmallBuildingAgvInsuranceCalculatingErrorController() {
    }

})();
