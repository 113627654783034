(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildDemolitionService', RebuildDemolitionService);

    RebuildDemolitionService.$inject = ['DemolitionService', 'ConstructionKindService', '$translate'];

    function RebuildDemolitionService(DemolitionService, ConstructionKindService, $translate) {

        function getDemolitionAdditionLabel() {
            return $translate.instant("rebuild.template.addition.DEMOLITION_CAUSED_BY_VOLUME_REDUCTION");
        }

        function getDemolitionBuiltUpAreaAdditionLabel() {
            return $translate.instant("rebuild.template.addition.DEMOLITION_CAUSED_BY_BUF_REDUCTION");
        }

        function findIndexOfDemolitionAddition(additions) {
            return _.findIndex(additions, {
                'label': getDemolitionAdditionLabel(),
                'bkp': 'BKP_1_PREPARATIONS'
            });
        }

        function findIndexOfDemolitionBuiltUpAreaAddition(additions) {
            return _.findIndex(additions, {
                'label': getDemolitionBuiltUpAreaAdditionLabel(),
                'bkp': 'BKP_1_PREPARATIONS'
            });
        }

        function calcVolumeCosts(isTotalVolume416, overhaulTotalVolume416, facadeType) {
            var constructionKind = ConstructionKindService.getConstructionKind(facadeType);
            return DemolitionService.estimateVolumeCost(isTotalVolume416 - overhaulTotalVolume416, constructionKind);
        }

        function calcSurroundingAreaCosts(isBUF, overhaulBUF) {
            var surroundingAreaReduction = isBUF - overhaulBUF;
            return DemolitionService.estimateSurroundingAreaCost(surroundingAreaReduction);
        }

        return {
            calcVolumeCosts: calcVolumeCosts,
            calcSurroundingAreaCosts: calcSurroundingAreaCosts,
            findIndexOfDemolitionAddition: findIndexOfDemolitionAddition,
            findIndexOfDemolitionBuiltUpAreaAddition: findIndexOfDemolitionBuiltUpAreaAddition,
            getDemolitionAdditionLabel: getDemolitionAdditionLabel,
            getDemolitionBuiltUpAreaAdditionLabel: getDemolitionBuiltUpAreaAdditionLabel
        };
    }

})();
