(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('remarkInput', {
            bindings: {
                reference: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '=',
                mandatory: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                infotext: '@?'
            },
            templateUrl: 'app/components/remark/remark-input/remark-input.tpl.html',
            controller: RemarkInputController
        });

    RemarkInputController.$inject = ['$scope', '$rootScope', '$attrs', '$element', '$translate'];

    function RemarkInputController($scope, $rootScope, $attrs, $element, $translate) {
        var $ctrl = this;

        $scope.firstLoad = true;
        $ctrl.hasLoaded = false;
        $ctrl.referencePlaceholderString = '';
        $ctrl.maxLength = 5000;

        $ctrl.updateDescriptionAndImage = function() {
            var tempDescriptionKey;
            var imageKey;
            imageKey = $ctrl.image;
            tempDescriptionKey = $ctrl.descriptionBaseKey;
            $rootScope.$broadcast('updateImage', {
                rowid: $ctrl.rowid,
                imageSrc: imageKey
            });

            $rootScope.$broadcast('updateDescription', {
                rowid: $ctrl.rowid,
                // descriptionNumber: selectedItem,
                descriptionBaseKey: tempDescriptionKey
            });
        };

        $ctrl.autoExpand = function(e) {
            var minTextareaHeight = 24;
            var node = typeof e === 'object' ? e.target : document.getElementById(e);
            // var scrollHeight = node.scrollHeight;
            node.style.height = getHeight();

            function getHeight() {
                // var line = node.scrollHeight - node.offsetHeight;
                var height = Number(window.getComputedStyle(node)['height'].replace('px', ''));
                var lineHeight = Number(window.getComputedStyle(node)['line-height'].replace('px', ''));
                var newHeight = node.value.split('\n').length * lineHeight; //This Will set the new height to the number of lines in the Value of node
                //cache the initial height that can be adjusted using 'rows'
                if (!minTextareaHeight) {
                    minTextareaHeight = height;
                }

                if (newHeight <= minTextareaHeight) {
                    return minTextareaHeight;
                } else {
                    return newHeight;
                }
            }
        };

        // function expand() {
        //     $ctrl.autoExpand('TextArea');
        // }


        $ctrl.setCursorPos = function(event) {
            $ctrl.pointerLocation = event.target.selectionStart;
        };

        $ctrl.onFocus = function(event) {
            $ctrl.setCursorPos(event);
            // $ctrl.updateDescriptionAndImage();
        };

        this.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            // $ctrl.countCharacters();
            // Used for the validation
            var toggle = $element.find('.remarkInput');
            $(toggle).attr('id', $attrs.reference + '.input');
            // expand();
            $ctrl.hasLoaded = true;
        };
    }
})();
