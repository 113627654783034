(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('measureSliderInput', MeasureSliderInput);

    MeasureSliderInput.$inject = ['$rootScope', '$timeout', 'RebuildComponentSliderService'];

    function MeasureSliderInput($rootScope, $timeout, RebuildComponentSliderService) {
        return {
            restrict: 'E',
            scope: {
                rowid: '@', // used to determine in what row this directive is placed, so the correct image can be updated
                reference: '<',
                referenceStr: '@',
                component: '=?',
                realEstateContainer: '=?',
                presetValue: '=',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                image: '=',
                isDisabled: '=?',
                mandatory: '@?',
                lessValues: '@',
                tabindex: '@',
                tabgroup: '@',
                ownMeasure: '@?',
                infotext: '@?'
            },
            templateUrl: 'app/components/sliders/measure_slider/measure-slider_input_partial.html',
            controller: function () {

            },
            link: function (scope, tElement, tAttrs, ctrl) {

                function updateDescriptionRow(rebuildEstateComponent, measure, interventionGrade, interval, intervalGap, isNew, isChanging) {

                    function isInterventionGradeOutsideInterval() {
                        if (interval === undefined) return false;
                        var whiskerData = [interval[1], interval[5]];
                        return !(whiskerData[0] <= interventionGrade && interventionGrade <= whiskerData[1]);
                    }

                    function isInterventionGradeInIntervalGap() {
                        if (intervalGap === undefined) return false;
                        return intervalGap[0] < interventionGrade && interventionGrade <= intervalGap[1];
                    }

                    var classType = measure;

                    var extras = undefined;
                    if (rebuildEstateComponent === 'HEAT_GENERATION' && classType !== 'MK1' && _.includes(scope.realEstateContainer.quality.heatingTypes, 'CONTRACTING')){
                        extras = ['isContracting'];
                    }

                    if (classType === 'MK1') {
                        classType = (interventionGrade <= 0.0) ? 'MK1a' : 'MK1b';
                    }

                    if (classType === 'MK5') {
                        classType = (interventionGrade >= 100.0) ? 'MK5a' : 'MK5b';
                    }

                    if (scope.component.active) {
                        $rootScope.$broadcast('updateDescription', {
                            rowid: Number(tAttrs.rowid),
                            classType: classType,
                            descriptionBaseKey: tAttrs.descriptionBaseKey,
                            isNew: isNew,
                            isChanging: isChanging,
                            isOutsideSuggestion: isInterventionGradeOutsideInterval(),
                            inSuggestionGap: isInterventionGradeInIntervalGap(),
                            extras: extras,
                            sliderRange: values.length,
                            sliderNotPresent: true
                        });
                    }
                }

                scope.firstLoad = true;
                scope.doShowInterval = false;
                scope.gapPlotRefresh = 0;
                scope.doShowGapPlot = false;
                scope.boxPlotData = [{}];
                scope.boxPlotDataGap = [{}];
                scope.whConfig = undefined;
                scope.whConfigGap = undefined;
                scope.additionalHtml = null;

                $(document).ready(function() {
                    if (scope.isDisabled == null) {
                        scope.isDisabled = false;
                    }
                });

                var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
                    // setLanguageForSlider();
                });
                this.$onDestroy = function() {
                    translateSuccess();
                };
                //
                // function setLanguageForSlider() {
                //     //Translation keys for Slider Labels.
                //     $translate(['process-form.slider.0.label', 'process-form.slider.1.label', 'process-form.slider.2.label', 'process-form.slider.3.label', 'process-form.slider.4.label', 'process-form.slider.5.label', 'process-form.slider.6.label', 'process-form.slider.7.label']).then(function (translations) {
                //         var tempMore = [];
                //         var tempLess = [];
                //         _.forEach(translations, function (elem) {
                //             tempMore.push(elem);
                //         });
                //         tempLess.push(translations['process-form.slider.1.label']);
                //         tempLess.push(translations['process-form.slider.3.label']);
                //         tempLess.push(translations['process-form.slider.5.label']);
                //         //Slider Mapping set values by less
                //         //Slider Mapping set values by more
                //         updateSliderLabels();
                //     });
                //
                // }

                var values = ["MK1", "MK2", "MK3", "MK4", "MK5"];
                if (angular.isUndefined(scope.reference)) {
                    // scope.reference = 3;
                }

                var indexFun = function (value) {
                    return values.indexOf(value);
                };

                if (tAttrs.class === "subSlider") {
                    var inputElement = tElement.find(".cInputElement");
                    inputElement.addClass(tAttrs.class);
                }

                var inputField = tElement.find(".slider2Input");

                /**
                 *
                 * @param newValue -> is a MK1 - MK5 value.
                 */
                var setSlider = function(newValue) {
                    var valIndex = indexFun(newValue);
                    if (valIndex > -1) {
                        var slider = inputField.data('ionRangeSlider');
                        slider.update({
                            from: valIndex
                        });
                    }
                };

                var isCurrentSliderValue = function(value) {
                    var slider = inputField.data('ionRangeSlider');
                    // console.warn(inputField);
                    // console.log(slider);
                    // if (slider) {
                    // inputField = tElement.find(".slider2Input");
                    return slider.result.from_value === value;
                    // }
                };

                scope.$watch('[component.totalMeasure.measureUserInput,component.totalMeasure.interventionGradeUserInput]', function(newVal, oldVal) {
                    updateDescriptionRow(scope.component.rebuildEstateComponent, scope.component.totalMeasure.measure, scope.component.totalMeasure.interventionGrade, scope.component.totalMeasure.interval, scope.component.totalMeasure.intervalGap, !scope.component.activeInRealEstate && scope.component.active, scope.component.changing);
                }, true);

                scope.$watch('component.totalMeasure.measure', function(newValue, oldValue) {
                    if (newValue !== oldValue && !isCurrentSliderValue(newValue)) {
                        setSlider(scope.component.totalMeasure.measure);
                    }
                });

                scope.$watch('component.active', function(newValue, oldValue) {
                    $timeout(function () {
                        // timout needed as slider is hidden and therefore sliderData.coords.p_handle is still infinite.
                        if (newValue && !oldValue) {
                            updateSliderMarkByScopeData();
                            updateSliderBoxPlotByScopeData();
                        }
                    }, 500);
                });

                scope.$watch('component.totalMeasure.measureBackend', function(newValue, oldValue) {
                    updateSliderMarkByScopeData();
                });

                scope.$watch('component.totalMeasure.interval', function(newValue, oldValue) {
                    updateSliderBoxPlotByScopeData();
                });

                scope.$watch('isDisabled', function(newValue, oldVal) {
                    // inputField.data('ionRangeSlider').update(ionRangeSliderOptions);
                    if (newValue !== undefined) {
                        ionRangeSliderOptions.disable = newValue;
                        var slider = inputField.data('ionRangeSlider');
                        slider.destroy();
                        inputField.ionRangeSlider(ionRangeSliderOptions);
                        if (scope.additionalHtml) {
                            slider = inputField.data('ionRangeSlider');
                            // $slider is a jquery function
                            var $slider = slider.result.slider;
                            $slider.append(scope.additionalHtml);
                        }
                    }
                });

                function convertToPercent(num, min, max) {
                    return (num - min) / (max - min) * 100;
                }

                function toFixed(num) {
                    num = num.toFixed(5);
                    return +num;
                }

                function convertArr(arr, p_handle) {
                    return arr.map(function (e) {
                        var min = p_handle/200;
                        var max = 1 - min;
                        return rescale(e/100, 0, 1, min, max);
                    });
                }

                function updateSliderBoxPlotByScopeData() {
                    if (scope.component.totalMeasure !== undefined) {
                        var gap = scope.component.totalMeasure.intervalGap;
                        if (gap === undefined)
                            updateSliderBoxPlot(inputField.data("ionRangeSlider"), scope.component.totalMeasure.interval);
                        else
                            updateSliderBoxPlot(inputField.data("ionRangeSlider"), scope.component.totalMeasure.interval, gap[0], gap[1]);
                    }
                }

                function updateSliderBoxPlot(sliderData, interval, gapFrom, gapTo) {
                    if (interval === undefined) return;
                    interval = interval.map(RebuildComponentSliderService.transformInterventionGrade);
                    // sliderData: slider element
                    // interval: array containing the quartiles mapped to wk numbers [0, 7]
                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exist
                    if (sliderData && interval) {
                        scope.whConfig = {width: sliderData.coords.w_rs, height: 10, barWidth: 10-2};
                        var quartileData = interval.slice(2, 5);
                        var whiskerData = [interval[1], interval[5]];
                        var outlierData = [interval[0], interval[6]];
                        scope.boxPlotData[0] = {
                            'key': 0,
                            'quartile': convertArr(quartileData, sliderData.coords.p_handle),
                            'whiskers': convertArr(whiskerData, sliderData.coords.p_handle),
                            'outliers': convertArr(outlierData, sliderData.coords.p_handle),
                            'max': 1
                        };
                        if (gapFrom !== undefined && gapTo !== undefined) {
                            gapFrom = RebuildComponentSliderService.transformInterventionGrade(gapFrom);
                            gapTo = RebuildComponentSliderService.transformInterventionGrade(gapTo);
                            scope.boxPlotData[0].gap = convertArr([gapFrom, gapTo], sliderData.coords.p_handle);
                        }
                        scope.doShowInterval = true;
                    }
                }

                function updateSliderMarkByScopeData() {
                    if (scope.component.totalMeasure !== undefined && !scope.component.parent) {
                        updateSliderMark(inputField.data('ionRangeSlider'), scope.component.totalMeasure.measureBackend);
                    }
                }

                function updateSliderMark(sliderData, measure) {
                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exists
                    if (sliderData) {
                        var min = sliderData.result.min;
                        var max = sliderData.result.max;

                        var real_width = 100 - sliderData.coords.p_handle;
                        var val = toFixed(scope.getIdx(measure) / 100 * real_width);
                        var left = convertToPercent(val, min, max);
                        var html;
                        if (_.inRange(left, 0, 100)) {
                            html = '<span class="sliderMark" style="left: ' + left + '%"></span>';
                        } else {
                            if (left < 0) {
                                html = '<span class="sliderMark" style="left: ' + 0 + '%"></span>';
                            }
                            if (left > 89) {
                                html = '<span class="sliderMark" style="left: ' + 89.00 + '%"></span>';
                            }
                        }
                        $timeout(function() {
                            if (_.isFinite(sliderData.coords.p_handle)) {
                                ctrl.update(html);
                            }
                        });
                    }
                }

                scope.getIdx = function(value) {
                    return indexFun(value);
                };

                function rescale(val, b_min, b_max, a_min, a_max) {
                    // b_min: before min
                    // b_max: before max
                    // a_min: after min
                    // a_max: after max
                    return (a_max - a_min) * (val - b_min) / (b_max - b_min) + a_min;
                }

                var label;

                function Label(container) {
                    this.$label = $(container).find('.irs-single');
                    this.active = false;
                    this.ACTIVE = 'irs-single--active';
                }

                Label.prototype = {
                    start: function () {
                        if (!this.active) {
                            this.active = true;
                            this.$label.addClass(this.ACTIVE);
                        }
                    },

                    end: function () {
                        this.$label.removeClass(this.ACTIVE);
                        this.active = false;
                    }
                };

                var ionRangeSliderOptions = {
                    type: 'single',
                    grid: false,
                    keyboard: true,
                    hide_min_max: true,
                    disable: scope.isDisabled,
                    keyboard_step: 100 / values.length + 1 /* In % ! */,
                    from_value: tAttrs.reference /*Position of the value*/,
                    from: scope.getIdx(scope.reference),
                    onStart: function(data) {
                        label = new Label(data.slider);
                    },
                    onChange: function(data) {
                        label.start();
                        // Change the model if the slider changes
                        $timeout(function () {
                            scope.component.totalMeasure.setMeasureUserInput(data.from_value);
                        });
                    },
                    onFinish: function (data) {
                        $timeout(function () {
                            label.end();
                        }, 1000);
                    },
                    values: values
                };

                inputField.ionRangeSlider(ionRangeSliderOptions);

                ctrl.update = function (additionalHtml) {
                    scope.additionalHtml = additionalHtml;
                    var slider = inputField.data("ionRangeSlider");
                    slider.destroy();
                    inputField.ionRangeSlider(ionRangeSliderOptions);
                    if (additionalHtml) {
                        slider = inputField.data("ionRangeSlider");
                        // $slider is a jquery function
                        var $slider = slider.result.slider;
                        $slider.append(additionalHtml);
                    }
                };

            }
        };
    }

})();
