(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(translationConfig);

    translationConfig.$inject = ['$translateProvider', 'tmhDynamicLocaleProvider', 'VERSION', 'DEBUG_INFO_ENABLED'];

    function translationConfig($translateProvider, tmhDynamicLocaleProvider, VERSION, DEBUG_INFO_ENABLED) {
        // Initialize angular-translate
        var u;
        if (DEBUG_INFO_ENABLED) {
            u = 'i18n/{lang}/{part}.json';
            tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
        } else {
            u = 'i18n/' + VERSION + '/{lang}/{part}.json';
            tmhDynamicLocaleProvider.localeLocationPattern('i18n/' + VERSION + '/angular-locale_{{locale}}.js');
        }
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: u,
            loadFailureHandler: 'translationErrorHandler'
        });

        $translateProvider.preferredLanguage('de');
        $translateProvider.useStorage('translationStorageProvider');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
        $translateProvider.forceAsyncReload(true);

        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
        // $translateProvider.useMissingTranslationHandlerLog();
/*
        $rootScope.$on('$translatePartialLoaderStructureChanged', function() {
            console.log('refresh')
            $translate.refresh();
        });
       */
    }
})();
