(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('ZipcodeLocationMappingService', ZipcodeLocationMappingService);

    ZipcodeLocationMappingService.$inject = ['$http'];
//TODO cashing mit HTTP vielleicht bessere Idee möglich
    function ZipcodeLocationMappingService($http) {
        return {
            getMapping: function(canton, responseFunction, errorFunction) {
                return $http.get('assets/resources_generic/location.json')
                    .then(function(response) {
                        if (angular.isDefined(canton)) {
                            var array = _.filter(response.data, ['canton', canton]);
                            return responseFunction(array);
                        } else {
                            return responseFunction(response.data);
                        }
                    }, function(errorResponse) {
                        return errorFunction(errorResponse);
                    });
            }
        };
    }
})();
