(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('UsageService', UsageService);

    UsageService.$inject = ['$http'];

    function UsageService($http) {
        return {
            getUsageLifetime: function (usageType, usageStandard) {
                if (usageType == null || usageStandard == null) return null;
                return $http.get('api/usage/' + usageType + '/' + usageStandard)
                    .then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.error(error);
                        return null;
                    });
            }
        };
    }

})();
