(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('GlobalModalService', GlobalModalService);

    GlobalModalService.$inject = ['$uibModal'];

    function GlobalModalService($uibModal) {

        var service = {
            contactDtmModal: contactDtmModal
        };

        function contactDtmModal(modul) {
            $uibModal.open({
                templateUrl: 'app/modals/contact-dtm-dialog.html',
                controller: 'ContactDtmDialogController',
                // clickOutsideToClose: true, //remove backdrop to enable clickOutSideToClose
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    modul: function() {
                        return modul;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prices');
                        return $translate.refresh();
                    }]
                }
            });
        }

        return service;
    }
})();
