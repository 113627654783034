(function () {
    'use strict';


    angular
        .module('kennwerteApp')
        .directive('dimensionSheetDirective', DimensionSheetDirective)
        .controller('DimensionSheetController', DimensionSheetController);

    DimensionSheetDirective.$inject = [];

    function DimensionSheetDirective() {
        return {
            restrict: "E",
            scope: {
                rowid: '=',
                realEstateContainer: "=", // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@',
                isDisabled: '=',
                min: '@',
                max: '@',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                inputFocusFun: '&',
                inputBlurFun: '&',
                tabIndex: '=',
                tabGroup: '='
            },
            controller: DimensionSheetController,
            controllerAs: 'vm',
            templateUrl: 'app/components/dimension_sheet/dimension-sheet.tpl.html'
        };
    }

    DimensionSheetController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'usageTypeGroupFactory', '$translate', '$uibModal'];

    function DimensionSheetController($scope, $stateParams, $uibModalInstance, usageTypeGroupFactory, $translate, $uibModal) {
        var vm = this;
        vm.dimensionSheetOld = [];
        vm.tempUsages = JSON.parse(JSON.stringify($scope.realEstateContainer.usages)).filter(function (obj) {
            return !!obj.type;
        });
        vm.dimensionSheet = [];
        vm.notSafed = false;
        vm.broadcastChanges = false;
        vm.arr = [];
        vm.set = new Set();
        vm.oldValue = "";
        vm.withdrawalProductType = $scope.realEstateContainer.metaData.withdrawalProductType;

        // general well-known functions
        vm.isInStateBuildingCosts = isInStateBuildingCosts;
        vm.isInStateInsuranceValues = isInStateInsuranceValues;

        vm.cancel = cancel;
        vm.addNew = addNew;
        vm.anyPartNotEmpty = anyPartNotEmpty;
        vm.remove = remove;
        vm.calcArea416 = calcArea416;
        vm.calcAreaComplete416 = calcAreaComplete416;
        vm.calcVolume416 = calcVolume416;
        vm.calcVolumeComplete416 = calcVolumeComplete416;
        vm.calcVolume116 = calcVolume116;
        vm.calcVolumeComplete116 = calcVolumeComplete116;
        vm.saveAreas = saveAreas;
        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.calcAreaComplete416Overall = calcAreaComplete416Overall;
        vm.calcVolumeComplete416Overall = calcVolumeComplete416Overall;
        vm.calcVolumeComplete116Overall = calcVolumeComplete116Overall;
        vm.calcAreaComplete416Building = calcAreaComplete416Building;
        vm.calcVolumeComplete416Building = calcVolumeComplete416Building;
        vm.calcVolumeComplete116Building = calcVolumeComplete116Building;
        vm.closeWithUnsavedChanges = closeWithUnsavedChanges;
        vm.partChanged = partChanged;
        vm.calcPercentage = calcPercentage;
        vm.closeAndBroadcastChanges = closeAndBroadcastChanges;
        vm.closeWithoutBroadcast = closeWithoutBroadcast;
        vm.showInfo = showInfo;

        // row related functions
        vm.rowNotValid = rowNotValid;
        vm.isAnyCuboidSetOnRow = isAnyCuboidSetOnRow; // user has to specify either cuboid (width, height, etc.)
        vm.isAnyResultSetOnRow = isAnyResultSetOnRow; // .. or directly input result (gf / gv)

        var initialize = function () {
            for (var i = 0; i < vm.tempUsages.length; i++) {
                try {
                    if ($scope.dimensionSheet != null && $scope.dimensionSheet[i]) {

                        vm.dimensionSheet.push({
                            'type': $translate.instant('realEstate.usages.' + usageTypeGroupFactory.getTitleOfPrevious(vm.tempUsages[i].type)) + " - " + $translate.instant('realEstate.usages.' + vm.tempUsages[i].type),
                            'areaComplete416': $scope.dimensionSheet[i].areaComplete416,
                            'volumeComplete416': $scope.dimensionSheet[i].volumeComplete416,
                            'volumeComplete116': $scope.dimensionSheet[i].volumeComplete116,
                            "dimensionSheetEntries": JSON.parse(JSON.stringify($scope.dimensionSheet[i].dimensionSheetEntries))
                        });
                    } else {
                        vm.dimensionSheet.push({
                            'type': $translate.instant('realEstate.usages.' + usageTypeGroupFactory.getTitleOfPrevious(vm.tempUsages[i].type)) + " - " + $translate.instant('realEstate.usages.' + vm.tempUsages[i].type),
                            'areaComplete416': 0,
                            'volumeComplete416': 0,
                            'volumeComplete116': 0,
                            "dimensionSheetEntries": [{
                                id: 0,
                                part: "",
                                year: "",
                                location: "",
                                name: "",
                                volumeWithoutArea: "",
                                additionDeduction: "",
                                amount: undefined,
                                length: undefined,
                                width: undefined,
                                height: undefined,
                                area416: undefined,
                                volume416: undefined,
                                volume116: undefined
                            }]
                        });
                    }
                } catch (e) {
                    console.warn('dimension sheet translation error', e);
                }
            }
            vm.dimensionSheetOld = JSON.parse(JSON.stringify(vm.dimensionSheet));
            vm.partChanged();

        };
        initialize();

        function partChanged() {
            vm.set.clear();
            vm.dimensionSheet.forEach(function (entry) {
                entry.dimensionSheetEntries.forEach(function (p) {
                    vm.set.add(p.part);
                });
            });
            vm.arr = [];
            vm.set.forEach(function (item) {
                vm.arr.push(item);
            });

        }

        function anyPartNotEmpty() {
            return vm.arr.some(function (e) {
                return !!e;
            });
        }

        function addNew(usageTable, index) {
            var elems = $($("#usagetable" + index + " :input").get().reverse());
            var isValid = true;
            elems.each(function (idx, elem) {
                if (!elem.checkValidity()) {
                    isValid = false;
                }
            });
            if (isValid) {
                if (usageTable.dimensionSheetEntries.length > 1 && !usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 1].part) {
                    usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 1].part = usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 2].part;
                }
                if (usageTable.dimensionSheetEntries.length > 1 && !usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 1].year) {
                    usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 1].year = usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 2].year;
                }
                usageTable.dimensionSheetEntries.push({
                    id: usageTable.dimensionSheetEntries.length,
                    part: usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 1].part,
                    year: usageTable.dimensionSheetEntries[usageTable.dimensionSheetEntries.length - 1].year,
                    location: "",
                    name: "",
                    volumeWithoutArea: "",
                    additionDeduction: "",
                    amount: undefined,
                    length: undefined,
                    width: undefined,
                    height: undefined,
                    area416: undefined,
                    volume416: undefined,
                    volume116: undefined
                });
            } else {
                elems.each(function (idx, elem) {
                    elem.reportValidity();
                });
            }
            vm.partChanged();
        }

        function calcArea416(entry) {
            if (entry.amount && entry.length && entry.width)
                entry.area416 = entry.amount * entry.length * entry.width;
            return entry.area416;
        }

        function calcAreaComplete416(usageTable) {
            usageTable.areaComplete416 = 0;
            usageTable.dimensionSheetEntries.forEach(function (entry) {
                if (!isNaN(entry.area416) && !entry.volumeWithoutArea && !entry.additionDeduction) {
                    usageTable.areaComplete416 = usageTable.areaComplete416 + entry.area416;
                }
            });
            return usageTable.areaComplete416;
        }

        function calcVolume416(entry) {
            if (entry.amount && entry.length && entry.width && entry.height)
                entry.volume416 = entry.amount * entry.length * entry.width * entry.height;
            return entry.volume416;
        }

        function calcVolumeComplete416(usageTable) {
            usageTable.volumeComplete416 = 0;
            usageTable.dimensionSheetEntries.forEach(function (entry) {
                if (!isNaN(entry.volume416) && !entry.additionDeduction) {
                    usageTable.volumeComplete416 = usageTable.volumeComplete416 + entry.volume416;
                }
            });
            return usageTable.volumeComplete416;
        }

        function calcVolume116(outlinePart) {
            if (outlinePart.amount && outlinePart.length && outlinePart.width && outlinePart.height)
                outlinePart.volume116 = outlinePart.amount * outlinePart.length * outlinePart.width * outlinePart.height;
            return outlinePart.volume116;
        }

        function calcVolumeComplete116(element) {
            element.volumeComplete116 = 0;
            element.dimensionSheetEntries.forEach(function (entry) {
                if (!isNaN(entry.volume116)) {
                    element.volumeComplete116 = element.volumeComplete116 + entry.volume116;
                }
            });
            return element.volumeComplete116;
        }

        function calcAreaComplete416Building(part) {
            vm.areaComplete416B = 0;
            vm.dimensionSheet.forEach(function (entry) {
                entry.dimensionSheetEntries.forEach(function (p) {
                    if (p.part === part && !isNaN(p.area416) && !p.additionDeduction && !p.volumeWithoutArea) {

                        vm.areaComplete416B = vm.areaComplete416B + p.area416;
                    }
                });
            });
            vm.areaComplete416B = Math.round(vm.areaComplete416B);
            return vm.areaComplete416B;
        }

        function calcAreaComplete416Overall() {
            var temp = 0;
            vm.dimensionSheet.forEach(function (entry) {
                temp = temp + parseFloat(entry.areaComplete416);
            });
            vm.areaComplete416 = Math.round(temp);
            return vm.areaComplete416;
        }

        function calcVolumeComplete416Building(part) {
            vm.volumeComplete416B = 0;
            vm.dimensionSheet.forEach(function (entry) {
                entry.dimensionSheetEntries.forEach(function (p) {
                    if (p.part === part && !isNaN(p.volume416) && !p.additionDeduction) {
                        vm.volumeComplete416B = vm.volumeComplete416B + p.volume416;
                    }
                });
            });
            vm.volumeComplete416B = Math.round(vm.volumeComplete416B);
            return vm.volumeComplete416B;
        }

        function calcVolumeComplete416Overall() {
            var temp = 0;
            vm.dimensionSheet.forEach(function (entry) {
                temp = temp + Number(entry.volumeComplete416);
            });
            vm.volumeComplete416 = Math.round(temp);
            return vm.volumeComplete416;
        }

        function calcVolumeComplete116Building(part) {
            vm.volumeComplete116B = 0;
            vm.dimensionSheet.forEach(function (entry) {
                entry.dimensionSheetEntries.forEach(function (p) {
                    if (p.part === part && !isNaN(p.volume116)) {
                        vm.volumeComplete116B = vm.volumeComplete116B + p.volume116;
                    }
                });
            });
            vm.volumeComplete116B = Math.round(vm.volumeComplete116B);
            return vm.volumeComplete116B;
        }

        function calcVolumeComplete116Overall() {
            var temp = 0;
            vm.dimensionSheet.forEach(function (entry) {
                temp = temp + Number(entry.volumeComplete116);
            });
            vm.volumeComplete116 = Math.round(temp);
            return vm.volumeComplete116;
        }

        function calcPercentage(table, index) {
            vm.tempUsages[index].percentage = Math.round((Math.round(table.areaComplete416) / vm.areaComplete416) * 100);
            if (isNaN(vm.tempUsages[index].percentage))
                return 0;
            return vm.tempUsages[index].percentage;
        }

        function moveUp(outlinePart, usageTable) {
            var temp = outlinePart.id;
            var tempOther = usageTable.dimensionSheetEntries[outlinePart.id - 1].id;
            if (outlinePart.id > 1 && !outlinePart.part) {
                outlinePart.part = usageTable.dimensionSheetEntries[outlinePart.id - 2].part;
                if (!outlinePart.year) outlinePart.year = usageTable.dimensionSheetEntries[outlinePart.id - 2].year;
            }
            usageTable.dimensionSheetEntries[outlinePart.id - 1].id = temp;
            outlinePart.id = tempOther;
            usageTable.dimensionSheetEntries.sort(function (a, b) {
                return a.id - b.id;
            });
        }

        function moveDown(outlinePart, usageTable) {
            var temp = outlinePart.id;
            var tempOther = usageTable.dimensionSheetEntries[outlinePart.id + 1].id;

            if (!usageTable.dimensionSheetEntries[outlinePart.id + 1].part) {
                usageTable.dimensionSheetEntries[outlinePart.id + 1].part = usageTable.dimensionSheetEntries[outlinePart.id - 1].part;
                if (!usageTable.dimensionSheetEntries[outlinePart.id + 1].year) usageTable.dimensionSheetEntries[outlinePart.id + 1].year = usageTable.dimensionSheetEntries[outlinePart.id - 1].year;
            }
            usageTable.dimensionSheetEntries[outlinePart.id + 1].id = temp;
            outlinePart.id = tempOther;
            usageTable.dimensionSheetEntries.sort(function (a, b) {
                return a.id - b.id;
            });
        }

        function trimEntries(outlinePart) {
            if (outlinePart.part) {
                outlinePart.part.trim();
            }
            if (outlinePart.name) {
                outlinePart.name.trim();
            }
            if (outlinePart.location) {
                outlinePart.location.trim();
            }
        }

        function rowNotValid(outlinePart) {
            trimEntries(outlinePart);
            var isGfGvNotValid;
            if (outlinePart.volumeWithoutArea) {
                isGfGvNotValid = !outlinePart.volume416;
            } else {
                isGfGvNotValid = !outlinePart.area416 || !outlinePart.volume416;
            }
            return !!(outlinePart.year === undefined || (outlinePart.year && outlinePart.year.toString().length !== 4) || isGfGvNotValid);
        }

        function remove(outlinePart, usageTable) {
            var newDataList = [];
            outlinePart.selected = true;
            var index = 0;
            usageTable.dimensionSheetEntries.forEach(function (selected) {
                if (!selected.selected) {
                    selected.id = index;
                    newDataList.push(selected);
                    index++;
                }
            });
            usageTable.dimensionSheetEntries = newDataList;
        }

        function hasChange(dimensionSheetOld, dimensionSheetNew) {
            if (dimensionSheetOld.length !== dimensionSheetNew.length) {
                //console.log(angular.toJson(dimensionSheetOld));
                //console.log(angular.toJson(dimensionSheetNew));
                return true;
            }
            if (angular.toJson(dimensionSheetOld) !== angular.toJson(dimensionSheetNew)) {
                //console.log(angular.toJson(dimensionSheetOld));
                //console.log(angular.toJson(dimensionSheetNew));
                return true;
            }
            return false;
        }

        function isOverwrite() {
            for (var i = 0; i < vm.tempUsages.length; i++) {
                if ($scope.realEstateContainer.usages[i].percentage !== null && $scope.realEstateContainer.usages[i].percentage !== 0 && $scope.realEstateContainer.usages[i].percentage !== vm.tempUsages[i].percentage) return true;
            }
            if ($scope.realEstateContainer.geometry.totalVolume116 !== null && $scope.realEstateContainer.geometry.totalVolume116 !== vm.volumeComplete116) return true;
            if ($scope.realEstateContainer.geometry.totalVolume416 !== null && $scope.realEstateContainer.geometry.totalVolume416 !== vm.volumeComplete416) return true;
            if ($scope.realEstateContainer.geometry.totalFloorArea416 !== null && $scope.realEstateContainer.geometry.totalFloorArea416 !== vm.areaComplete416) return true;
            return false;
        }

        function cancel() {
            if (hasChange(vm.dimensionSheetOld, vm.dimensionSheet)) {
                vm.notSafed = true;
                return;
            }
            $uibModalInstance.dismiss('cancel');
        }

        function closeWithUnsavedChanges() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveAreas() {
            vm.dimensionSheet.forEach(function (table) {
                table.dimensionSheetEntries.forEach(function (entries) {
                    trimEntries(entries);
                });
            });
            $scope.saveInput(vm.dimensionSheet);
            if (isOverwrite()) {
                vm.broadcastChanges = true;
            } else {
                $scope.$emit('AreaChange', {
                    area416: vm.areaComplete416,
                    volume416: vm.volumeComplete416,
                    volume116: vm.volumeComplete116,
                    usages: vm.tempUsages
                });
                $uibModalInstance.close();
            }
        }

        function closeAndBroadcastChanges() {
            $scope.$emit('AreaChange', {
                area416: vm.areaComplete416,
                volume416: vm.volumeComplete416,
                volume116: vm.volumeComplete116,
                usages: vm.tempUsages
            });
            $uibModalInstance.close();
        }

        function closeWithoutBroadcast() {
            $uibModalInstance.close();
        }

        function showInfo(name) {
            var temp = "realEstate.geometry." +name;
            var modalInstance = $uibModal.open({
                controller: DimensionSheetController,
                animation: true,
                transclude:true,
                controllerAs: 'ctrl',
                bindToController: true,
                scope: $scope,
                clickOutsideToClose: true,
                windowClass: 'tooltip-modal',
                templateUrl: 'app/components/info_box/info-box.tpl.html'
            });
        }

        function isInStateBuildingCosts() {
            return vm.withdrawalProductType !== 'BUILDING_INSURANCE_VALUES';
        }

        function isInStateInsuranceValues() {
            return vm.withdrawalProductType === 'BUILDING_INSURANCE_VALUES';
        }

        function isAnyResultSetOnRow(row) {
            return row.volume416 || row.area416;
        }

        function isAnyCuboidSetOnRow(row) {
            return row.amount || row.length || row.width || row.height;
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        jQuery(document).ready( function($) {
            // Disable scroll when focused on a number input.
            $('form').on('focus', 'input[type=number]', function(e) {
                $(this).on('wheel', function(e) {
                    e.preventDefault();
                });
            });
            // Restore scroll on number inputs.
            $('form').on('blur', 'input[type=number]', function(e) {
                $(this).off('wheel');
            });
            // Disable up and down keys.
            $('form').on('keydown', 'input[type=number]', function(e) {
                if ( e.which == 38 || e.which == 40 )
                    e.preventDefault();
            });
        });

    }
})();
