(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('ReferenceGeometryFormController', ReferenceGeometryFormController);

    ReferenceGeometryFormController.$inject = ['$scope', '$stateParams', 'DimensionSheetService', 'RoomHeightLimitsWarningService'];

    function ReferenceGeometryFormController($scope, $stateParams, DimensionSheetService, RoomHeightLimitsWarningService) {

        $scope.dimensionSheet = $scope.realEstateContainer.geometry.dimensionSheet;

        $scope.saveInput = function (data) {
            $scope.realEstateContainer.geometry.dimensionSheet = JSON.parse(JSON.stringify(data));
            $scope.dimensionSheet = $scope.realEstateContainer.geometry.dimensionSheet;
        };

        $scope.showWarningIfOutOfLimit = function() {
            RoomHeightLimitsWarningService.showWarningIfOutOfLimit($scope.realEstateContainer);
        };

        $scope.openCalc = function () {
            DimensionSheetService.open($scope);
        };
    }

})();
