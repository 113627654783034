(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RealestateReferenceCreationController', RealestateReferenceCreationController)
        .factory('RealEstateReferenceCreationDialogService', RealEstateReferenceCreationDialogService);


    RealEstateReferenceCreationDialogService.$inject = ['$uibModal', 'GTMService'];

    function RealEstateReferenceCreationDialogService($uibModal, GTMService) {
        var service = {
            open: open,
            realEstateId: {},
            withdrawalProductType: {},
            getRealEstateId: getRealEstateId,
            getWithdrawalProductType: getWithdrawalProductType
        };

        var modalInstance = null;

        var resetModal = function () {
            modalInstance = null;
        };

        function getRealEstateId() {
            return service.realEstateId;
        }

        function getWithdrawalProductType() {
            return service.withdrawalProductType;
        }

        return service;

        //size from modal is defined in main.css as css class: modal-xlg
        function open(id, withdrawalProductType) {

            service.realEstateId = id;
            service.withdrawalProductType = withdrawalProductType;
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                backdrop: 'static',
                //Dont remove otherwise you can not upload images twice without upload due to ng-file-upload issues
                keyboard :false,
                size: "xlg",
                controller: RealestateReferenceCreationController,
                templateUrl: 'app/realestates/reference/realestate-reference-creation.html',
                controllerAs: 'vm',
                resolve: {
                    resetIsCompleteState: ['ValidationDataService', function(ValidationDataService) {
                        ValidationDataService.resetComplete();
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form')
                            .addPart('reference_reference-creation');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            });
            modalInstance.result.then(
                resetModal
            );
        }
    }


    RealestateReferenceCreationController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$http',
        '$timeout',
        'Principal',
        'RealestateReferenceService',
        '$window',
        '$q',
        '$uibModalInstance',
        'RealEstateReferenceCreationDialogService',
        'RealEstateReferenceDataService',
        'SliderPresetService',
        'GTMService'
    ];

    function RealestateReferenceCreationController($rootScope,
                                                   $scope,
                                                   $state,
                                                   $stateParams,
                                                   $http,
                                                   $timeout,
                                                   Principal,
                                                   RealestateReferenceService,
                                                   $window,
                                                   $q,
                                                   $uibModalInstance,
                                                   RealEstateReferenceCreationDialogService,
                                                   RealEstateReferenceDataService,
                                                   SliderPresetService,
                                                   GTMService) {
        var vm = this;

        $scope.dataHasLoaded = false;

        $scope.cancel = function () {
            $uibModalInstance.close();
            //Dont remove otherwise you can not upload images twice without upload due to ng-file-upload issues.
            $window.location.reload();
        };

        // navigation
        var stopChange = false;
        var showDepositOptions = false;

        vm.createImagePath = createImagePath;

        var init = function () {
            // initialize some variables that have to be shared in all child states of process-form.

            // holds the real estate

            $scope.formTabGroup = "0";

            $scope.isCurrentStateEditRecord = function () {
                return true;
            };

            $scope.isCurrentStateInput = function () {
                return false;
            };

            RealEstateReferenceDataService.load(RealEstateReferenceCreationDialogService.getRealEstateId(),
                RealEstateReferenceCreationDialogService.getWithdrawalProductType())
                .then(function (result) {
                    $scope.realEstateContainer = RealEstateReferenceDataService.realEstateReference;
                    $scope.realEstateContainer.metaData.withdrawalProductType = RealEstateReferenceCreationDialogService.getWithdrawalProductType();
                    $scope.realEstateContainer.metaData.tasks = [];
                    $scope.realEstateContainer.metaData.tasks.push($scope.realEstateContainer.metaData.withdrawalProductType);
                    if ($scope.realEstateContainer.id == null) {
                        SliderPresetService.setDefaultSliderModelValues($scope.realEstateContainer);
                        $scope.isEdit = false;
                    } else {
                        $scope.isEdit = true;
                    }
                    $scope.dataHasLoaded = true;
                });
        };

        $scope.endsWith = function (str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        };

        $scope.loadRealEstate = function (realEstateId, withdrawalProductType) {
            if (realEstateId) {
                // create a copy of the real estate given the id
                return RealestateReferenceService.get({id: realEstateId},
                    angular.identity,
                    angular.identity
                ).$promise;
            } else {
                return $scope.createNewRealEstate(withdrawalProductType);
            }
        };

        $scope.createNewRealEstate = function (withdrawalProductType) {

            return RealEstateReferenceDataService.create(withdrawalProductType).$promise;
        };

        // waits for angularjs digest cycle http://tech.endeepak.com/blog/2014/05/03/waiting-for-angularjs-digest-cycle/
        var waitForRender = function (callbackFun) {
            if ($http.pendingRequests.length > 0) {
                $timeout(waitForRender(callbackFun));
            } else {
                callbackFun();
            }
        };

        // Recognize change in window size
        var w = angular.element($window);
        $scope.$watch(
            function () {
                return $window.innerWidth;
            },
            function (value) {
                $rootScope.$broadcast("windowSizeChange", {
                    width: value
                });
                //$scope.windowWidth = value;
            },
            true
        );
        w.bind('resize', function () {
            $scope.$apply();
        });

        function createImagePath(userLogin, im) {
            return "image/" + userLogin + "/" + im.id + '.' + im.type;
        }

        $scope.$on('authenticationSuccess', function () {
            Principal.identity().then(function (account) {
                $scope.account = account;
                $scope.isAuthenticated = Principal.isAuthenticated();
            });
        });

        $rootScope.$on('appendImage', function (event, data) {
            vm.appendedImages.push(data);
        });

        // Call init at the end that all methods are available by then
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated();
        }).then(function () {
            init();
            if ($rootScope.inProduction && $scope.account.login !== 'admin' && $scope.account.login !== 'video') {
                GTMService.trackEvent({
                    action: 'reference-object-modal-open',
                    category: 'Reference-object'
                });
            }
        });

    }

})();
