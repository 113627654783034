(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('CalcRowTextService', CalcRowTextService);

    CalcRowTextService.$inject = ['RebuildEstateDataService', 'RebuildGeometryService', '$log'];

    function CalcRowTextService(RebuildEstateDataService, RebuildGeometryService, $log) {
        var service = {
            getTextBase: getTextBase
        };

        var mapText = {
            extension: {
                resolveFun: function(descriptionBaseKey) {
                    var temp = [];
                    if (RebuildGeometryService.isNone) temp.push('NONE');
                    else {
                        if (RebuildGeometryService.isAnnex) temp.push('ANNEX');
                        if (RebuildGeometryService.isAdditionStory) temp.push('ADDITION_STORY');
                    }
                    return (temp.length === 0)
                        ? descriptionBaseKey
                        : descriptionBaseKey + '.' + temp.join('_');
                }
            }
        };

        function getTextBase(key, descriptionBaseKey) {
            var value = mapText[key];
            if (value != null) {
                return value.resolveFun(descriptionBaseKey);
            }
        }
        return service;
    }
})();
