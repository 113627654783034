(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .factory('SmallBuildingUsageAutocompleteDataService', SmallBuildingUsageAutocompleteDataService);

    SmallBuildingUsageAutocompleteDataService.$inject = ['$http', '$q', 'TrieService'];

    function SmallBuildingUsageAutocompleteDataService($http, $q, TrieService) {
        var service = {
            data: [],
            trieStem: {},
            trieToken: {},
            getMapping: getMapping,
            getTrie: getTrie
        };

        function getMapping(responseFunction, errorFunction) {
            if (service.data.length === 0) {
                return $http.get('assets/resources_generic/small_building_autocomplete.json')
                    .then(function(response) {
                        service.data = response.data;
                        return responseFunction(response.data);
                    }, function(errorResponse) {
                        return errorFunction(errorResponse);
                    });
            } else {
                return responseFunction(service.data);
            }
        }

        function getTrie() {
            var deferred = $q.defer();
            // if (service.trieStem.size === 0) {
            if (service.trieStem.dictionary == null) {
                return getMapping(function(response) {
                    service.trieStem = TrieService.createTrie(function(obj) {
                        return obj['stem'].toString();
                    },false);
                    service.trieToken = TrieService.createTrie(function(obj) {
                        return obj['token'].toString();
                    },false);
                    Object.keys(service.data).forEach(function(key, index) {
                        var entry = response[key]['stem'].toString();
                        service.trieStem.addObject(entry, response[key]);
                        var entry2 = response[key]['token'].toString();
                        service.trieToken.addObject(entry2, response[key]);
                    });
                    deferred.resolve({ stem:service.trieStem, token:service.trieToken });
                    return deferred.promise;
                }, function(fail) {
                    console.warn(fail);
                    return $q.reject(fail);
                }).then(function(res) {
                    deferred.resolve({ stem:service.trieStem, token:service.trieToken });
                    return deferred.promise;
                });
            } else {
                deferred.resolve({ stem:service.trieStem, token:service.trieToken });
                return deferred.promise;
            }
        }
        return service;

    }
})();

