(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageAdditionsFormController', DamageAdditionsFormController);

    DamageAdditionsFormController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$translate', 'JhiLanguageService', 'RebuildTargetTypeDataService'];

    function DamageAdditionsFormController($rootScope, $scope, $stateParams, $state, $translate, JhiLanguageService, RebuildTargetTypeDataService) {
        $scope.data = $stateParams.data;
        //Set dynamicTranslations for additionsForm.
        $scope.noDynamicTranslations = false;
        $scope.hasCleanFire = null;
        $scope.hasDryer = null;
        $scope.hasWages = null;
        $scope.hasContaminant = null;
        var cleanFireTranslateKey = 'damage.additions.fire-cleanup';
        var dryerTranslateKey = 'damage.additions.drying';
        var wagesTranslateKey = 'rebuild.additions.wage';
        var contaminantTranslateKey = 'rebuild.additions.contaminant-cleanup';
        //check if addition exists in any language
        JhiLanguageService.getAll().then(function(values) {
            angular.forEach(values, function(lang, key) {
                $translate([cleanFireTranslateKey, dryerTranslateKey, wagesTranslateKey, contaminantTranslateKey],
                    {}, undefined, undefined, lang)
                    .then(function(trans) {
                        if ($scope.hasCleanFire == null) {
                            $scope.hasCleanFire = _.find($scope.realEstateContainer.additions, {
                                'label': trans[cleanFireTranslateKey],
                                'bkp': 'BKP_1_PREPARATIONS'
                            });
                            if ($scope.hasCleanFire != null) {
                                $scope.hasCleanFire.label = $translate.instant(cleanFireTranslateKey);
                            }
                        }
                        if ($scope.hasDryer == null) {
                            $scope.hasDryer = _.find($scope.realEstateContainer.additions, {
                                'label': trans[dryerTranslateKey],
                                'bkp': 'BKP_1_PREPARATIONS'
                            });
                            if ($scope.hasDryer != null) {
                                $scope.hasDryer.label = $translate.instant(dryerTranslateKey);
                            }
                        }
                        if ($scope.hasWages == null) {
                            $scope.hasWages = _.find($scope.realEstateContainer.additions, {
                                'label': trans[wagesTranslateKey],
                                'bkp': 'BKP_1_PREPARATIONS'
                            });
                            if ($scope.hasWages != null) {
                                $scope.hasWages.label = $translate.instant(wagesTranslateKey);
                            }
                        }
                        if ($scope.hasContaminant == null) {
                            $scope.hasContaminant = _.find($scope.realEstateContainer.additions, {
                                'label': trans[contaminantTranslateKey],
                                'bkp': 'BKP_1_PREPARATIONS'
                            });
                            if ($scope.hasContaminant != null) {
                                $scope.hasContaminant.label = $translate.instant(contaminantTranslateKey);
                            }
                        }
                    }).catch(function(e) {
                    console.warn(e);
                });
            });
        }).finally(function(val) {
            $translate([cleanFireTranslateKey, dryerTranslateKey, wagesTranslateKey, contaminantTranslateKey]).then(function(translations) {
                if ($scope.hasCleanFire == null) {
                    $scope.hasCleanFire = {
                        label: translations[cleanFireTranslateKey],
                        value: null,
                        bkp: 'BKP_1_PREPARATIONS',
                        targetType: RebuildTargetTypeDataService.OVERHAUL
                    };
                    $scope.realEstateContainer.additions.unshift($scope.hasCleanFire);
                }
                if ($scope.hasDryer == null) {
                    $scope.hasDryer = {
                        label: translations[dryerTranslateKey],
                        value: null,
                        bkp: 'BKP_28_CONSTRUCTION2',
                        targetType: RebuildTargetTypeDataService.OVERHAUL
                    };
                    $scope.realEstateContainer.additions.unshift($scope.hasDryer);
                }
                if ($scope.hasWages == null) {
                    $scope.hasWages = {
                        label: translations[wagesTranslateKey],
                        value: null,
                        bkp: 'BKP_29_WAGES',
                        targetType: RebuildTargetTypeDataService.OVERHAUL
                    };
                    $scope.realEstateContainer.additions.unshift($scope.hasWages);
                }
            }).catch(function(e) {
                console.warn(e);
            });
        }).catch(function(e) {
            console.warn(e);
        });

        var astbestWatch = $scope.$watch('realEstateContainer.constructionYear', function(newVal, oldVal) {
            if ($state.includes('damage-form.input')) {
                if (newVal != null && newVal < 1990) {
                    $scope.hasContaminant = _.find($scope.realEstateContainer.additions, {
                        'label': $translate.instant(contaminantTranslateKey),
                        'bkp': 'BKP_1_PREPARATIONS'
                    });
                    if ($scope.hasContaminant == null) {
                        $scope.hasContaminant = {
                            label: $translate.instant(contaminantTranslateKey),
                            value: null,
                            bkp: 'BKP_1_PREPARATIONS',
                            targetType: RebuildTargetTypeDataService.OVERHAUL
                        };
                        $scope.realEstateContainer.additions.unshift($scope.hasContaminant);
                    }
                }
            } else {
                astbestWatch();
            }
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            if ($scope.hasCleanFire != null) {
                $translate(cleanFireTranslateKey).then(function(trans) {
                    $scope.hasCleanFire.label = trans;
                }).catch(function(e) {
                    console.warn(e);
                });
            }
            if ($scope.hasDryer != null) {
                $translate(dryerTranslateKey).then(function(trans) {
                    $scope.hasDryer.label = trans;
                }).catch(function(e) {
                    console.warn(e);
                });
            }
            if ($scope.hasWages != null) {
                $translate(wagesTranslateKey).then(function(trans) {
                    $scope.hasWages.label = trans;
                }).catch(function(e) {
                    console.warn(e);
                });
            }
            if ($scope.hasContaminant != null) {
                $translate(contaminantTranslateKey).then(function(trans) {
                    $scope.hasContaminant.label = trans;
                }).catch(function(e) {
                    console.warn(e);
                });
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
    }

})();
