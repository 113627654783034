(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('decimalSliderInput', DecimalSliderInput);

    DecimalSliderInput.$inject = ['$timeout'];

    function DecimalSliderInput($timeout) {
        return {
            restrict: 'E',
            scope: {
                reference: '<',
                boxPlotData: '<',
                onUpdate: '<?',
                onChange: '<?',
                onFinish: '<?'
            },
            templateUrl: 'app/components/sliders/decimal_slider/decimal_slider_input_partial.html',
            controller: function () {
            },
            link: function (scope, tElement, tAttrs, ctrl) {

                var afterDecimal = function(value) {
                    return !value.toString().includes('.') ? 0 : value.toString().split('.')[1].length;
                };

                var originalBoxPlotMin = scope.boxPlotData[0].min;
                var originalBoxPlotMax = scope.boxPlotData[0].max;

                var inputField = tElement.find(".slider2Input");

                var ionRangeSliderOptions = {
                    step: 0.1,
                    grid: false,
                    skin: "flat",
                    from: scope.reference,
                    min: scope.boxPlotData[0].min,
                    max: scope.boxPlotData[0].max,
                    onFinish: function(data) {
                        if (scope.onFinish != null)
                            scope.onFinish(data.from);
                    },
                    onChange: function(data) {
                        if (scope.onChange != null)
                            scope.onChange(data.from);
                    },
                    onUpdate: function(data) {
                        if (scope.onUpdate != null)
                            scope.onUpdate(data.from);
                    },
                };

                inputField.ionRangeSlider(ionRangeSliderOptions);
                var slider = inputField.data("ionRangeSlider");

                scope.$watch('[reference,boxPlotData]', function() {
                    if (scope.reference != null) {
                        scope.boxPlotData[0].min = Math.min(originalBoxPlotMin, scope.reference);
                        scope.boxPlotData[0].max = Math.max(originalBoxPlotMax, scope.reference);
                        slider.update({
                            step: Math.pow(10, -Math.max(Math.min(afterDecimal(scope.reference), 3), 1)),
                            from: scope.reference,
                            min: scope.boxPlotData[0].min,
                            max: scope.boxPlotData[0].max
                        });
                    }
                });
            }
        };
    }

})();
