(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('Register', Register)
        .factory('RegisterByOrgInvite', RegisterByOrgInvite);

    Register.$inject = ['$resource'];

    function Register($resource) {
        return $resource('api/register', {}, {
            'createNew': {method: 'POST', url: 'api/register'},
            'check': {method: 'POST', url: 'api/register/check'}
        });
    }

    RegisterByOrgInvite.$inject = ['$http'];

    function RegisterByOrgInvite($http) {
        return {
            save: function (userByOrgInviteDTO, responseFunction, errorFunction) {
                $http.post('api/register_by_org_invite', userByOrgInviteDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            }
        };
    }
})();
