(function () {
    'use strict';

    angular.module('kennwerteApp')
    .component('realestateInfoBox', {
        bindings: {
            reference: '='
        },
        templateUrl: 'app/realestates/realestate-info/realestate-info-box-button.tpl.html',
        controller: RealEstateInfoBoxButtonController
    });

    RealEstateInfoBoxButtonController.$inject = ['$rootScope', '$scope', '$uibModal', 'Organisation'];

    function RealEstateInfoBoxButtonController($rootScope, $scope, $uibModal, Organisation) {
        var $ctrl = this;

        $ctrl.isEmpty = function() {
            return $ctrl.reference.additions.length === 0 && $ctrl.reference.additions[0] != null && ( $ctrl.reference.additions[0].value == null || $ctrl.reference.additions[0].bkp == null || $ctrl.reference.additions[0].label == null);
        };

        $ctrl.allInputsNull = function(){
            return $ctrl.isEmpty() && $ctrl.reference.metaData.remark == null;
        };

        $ctrl.getOrgansationName = function () {

            if ($ctrl.reference.metaData.userLogin === 'system') {
                $ctrl.enterpriseName = 'system';
            } else {
                Organisation.getName({userLogin: $ctrl.reference.metaData.userLogin}, function (response) {
                    $ctrl.enterpriseName = response.content;
                });
            }
        };

        $ctrl.showAdvanced = function () {
            $ctrl.getOrgansationName();
            var modalInstance = $uibModal.open({
                controller: RealEstateInfoBoxModalController,
                animation: true,
                size: 'lg',
                controllerAs: 'ctrl',
                bindToController: true,
                scope: $scope,
                clickOutsideToClose: true,
                templateUrl: 'app/realestates/realestate-info/realestate-info-box.tpl.html'
            });
        };
        RealEstateInfoBoxModalController.$inject = ['$scope', '$uibModalInstance'];

        function RealEstateInfoBoxModalController($scope, $uibModalInstance) {
            $scope.cancel = function () {
                $uibModalInstance.close();
            };
        }
    }
}

)();
