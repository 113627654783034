(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('compactFacadeTypeSelect', CompactFacadeTypeSelectDirective)
        .controller('compactFacadeTypeController', CompactFacadeTypeController);

    CompactFacadeTypeController.$inject = ['$scope', '$rootScope', '$timeout', 'facadeTypeGroupFactory', '$translate', '$attrs'];

    function CompactFacadeTypeController($scope, $rootScope, $timeout, facadeTypeGroupFactory, $translate, $attrs) {
        var vm = this;

        $scope.firstLoad = true;
        $scope.dataHasLoaded = false;

        vm.translateKey = function(postfix) {
            try {
                return $translate.instant('realEstate.quality.facadeType.' + postfix);
            } catch (e) {
                console.warn('translation error facade type', e);
                return '';
            }
        };

        vm.updateDescriptionAndImage = function(selectedItem) {
            if (!$scope.dataHasLoaded) return;
            if (selectedItem == null && vm.model != null) {
                selectedItem = vm.model;
            }
            var tempId;
            if (selectedItem) {
                tempId = selectedItem.id;
            } else {
                tempId = vm.model.id;
                if (tempId == null) {
                    tempId = 0;
                }
            }
            var key = $scope.descriptionBaseKey;
            var concatenatedGroupIds = '';
            var groupId = tempId;
            do {
                var prevGroupId = facadeTypeGroupFactory.getGroupIdById(Number(groupId), $scope.isRebuild);
                prevGroupId = Number(prevGroupId);
                if (prevGroupId !== 0) {
                    if (concatenatedGroupIds !== "") {
                        concatenatedGroupIds = prevGroupId + "." + concatenatedGroupIds;
                    } else {
                        concatenatedGroupIds = prevGroupId;
                    }
                }
                groupId = prevGroupId;
            } while (groupId !== 0);
            var k = key;
            if (concatenatedGroupIds !== '') {
                k = key + '.' + concatenatedGroupIds;
            }
            if (vm.model.id == 0) {
                k = key + '.' + groupId;
            }
            $rootScope.$broadcast('updateDescription', {
                rowid: $scope.rowid,
                descriptionNumber: tempId,
                dynamicTranslations: $scope.dynamicTranslations,
                descriptionBaseKey: k
            });

            if (vm.model.id == undefined || vm.model.id == 0 || vm.model.id > 100) {
                $rootScope
                    .$broadcast('updateImage', {
                        rowid: $scope.rowid,
                        imageSrc: '/assets/images/calculation/bg/komplexitaet.svg'
                    });
            } else {
                $rootScope.$broadcast('updateImage', {
                    rowid: $scope.rowid,
                    imageSrc: $scope.image + vm.model.id + '.png'
                });
            }
        };

        vm.onOpenClose = function (isOpen) {
            if (isOpen) {
                vm.updateDescriptionAndImage();
            }
        };

        vm.onSelect = function (selectedItem, $model) {
            vm.updateDescriptionAndImage(selectedItem);
            if (!selectedItem.parent) {
                var oldValue = $scope.reference;
                $scope.reference = selectedItem.title;
                $scope.actionCallback({
                    newValue: $scope.reference,
                    oldValue: oldValue,
                    args: { origin: $attrs.reference, index: $scope.index }
                });
                $scope.$broadcast('uiSelectClose');
            } else {
                $scope.loadChildGroupsOf(selectedItem);
            }
        };

        vm.model = facadeTypeGroupFactory.getByTitle($scope.reference, $scope.isRebuild);
        if (vm.model.id) {
            vm.model = {
                "id": vm.model.id,
                "title": vm.model.title,
                "parent": vm.model.parent,
                "translated": vm.translateKey(vm.model.title)
            };
        } else {
            vm.model = {
                "id": 0,
                "title": "All",
                "parent": true
            };
        }

        /**
         * This watch checks if the reference changes and updates the whole component.
         * This can happen if we load data from the db async.
         */
        $scope.$watch('reference', function (newValue) {
            if (newValue) {
                vm.loadReference();
                $scope.dataHasLoaded = true;
            }
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            if (vm.model != null) {
                vm.model.translated = vm.translateKey(vm.model.title);
            }
            // console.warn($scope.groups[0], $scope.groups[0].title, vm.translateKey($scope.groups[0].title));
            for (var i = 0; i < $scope.groups.length; i++) {
                if ($scope.groups[i].title != null) {
                    $scope.groups[i].translated = vm.translateKey($scope.groups[i].title);
                }
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
    }

    CompactFacadeTypeSelectDirective.$inject = ['$rootScope', '$timeout', 'facadeTypeGroupFactory', '$translate'];

    function CompactFacadeTypeSelectDirective($rootScope, $timeout, facadeTypeGroupFactory, $translate) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                rowid: '=',
                image: '=image',
                required: '=required',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                dynamicTranslations: '@?',
                isRebuild: '=?',
                actionCallback: '&'
            },
            templateUrl: 'app/components/compact_inputs/compact_facade_type_selecter/compact-facade-type-selecter.tpl.html',
            controller: CompactFacadeTypeController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, ctrl) {

                if (scope.isRebuild == null) {
                    scope.isRebuild = false;
                }

                if (!angular.isFunction(scope.actionCallback)) {
                    scope.actionCallback = angular.noop;
                }

                /**
                 * There is no check if the reference exists. So make sure it is checked.
                 */
                ctrl.loadModel = function() {
                    ctrl.model = facadeTypeGroupFactory.getByTitle(scope.reference, scope.isRebuild);
                    ctrl.model.translated = scope.translateKey(ctrl.model.title);
                    ctrl.updateDescriptionAndImage(ctrl.model);
                };

                /**
                 * Loading breadcrumbs and also loads Items for (group) with enhancedGroupFn
                 */
                ctrl.loadBreadCrumbs = function () {
                    var groupId = facadeTypeGroupFactory.getGroupIdById(ctrl.model.id, scope.isRebuild);
                    var prevGroups = facadeTypeGroupFactory.getAllPreviousGroups(ctrl.model.id, scope.isRebuild);
                    scope.breadcrumbs = [{ 'id': 0, 'title': 'All', 'parent': true }];
                    for (var prevGroupKey in prevGroups) {
                        var prevGroup = prevGroups[prevGroupKey];
                        scope.breadcrumbs.push(prevGroup);
                    }
                    enhanceGroupFn(facadeTypeGroupFactory.load(groupId, scope.isRebuild));
                };

                scope.translateKey = function(postfix) {
                    try {
                        return $translate.instant('realEstate.quality.facadeType.' + postfix);
                    } catch (e) {
                        console.warn('translate error on facadeType', e);
                    }
                };

                scope.breadcrumbs = [{ 'id': 0, 'title': 'All', 'parent': true }];
                ctrl.model = facadeTypeGroupFactory.getByTitle(scope.reference, scope.isRebuild);

                var gs = [];

                /**
                 * Loading Groups which should be currently displayed.
                 * @param gs is an Array of Usages (e.g. from group.service)
                 */
                var enhanceGroupFn = function(gs) {
                    for (var i in scope.groups) {
                        var oldg = scope.groups[i];
                        oldg.noshow = true;
                    }
                    var enhancedGroups = [];
                    for (var i in gs) {
                        var group = gs[i];
                        enhancedGroups.push({
                            "id": group.id,
                            "title": group.title,
                            "parent": group.parent,
                            "translated": scope.translateKey(group.title)
                        });
                    }
                    scope.groups = enhancedGroups;
                };

                /**
                 * loads Reference and can also be used to reload the reference
                 * which will result in a conistent state (model, groups and reference).
                 *
                 */
                ctrl.loadReference = function () {
                    if (scope.reference) {
                        ctrl.loadModel();
                        ctrl.loadBreadCrumbs();
                    } else {
                        gs = facadeTypeGroupFactory.load(0, scope.isRebuild);
                        enhanceGroupFn(gs);
                    }
                };

                ctrl.loadReference();


                scope.loadChildGroupsOf = function (group) {
                    scope.breadcrumbs.push(group);
                    var gs = facadeTypeGroupFactory.load(group.id, scope.isRebuild);
                    enhanceGroupFn(gs);
                    scope.$broadcast('uiSelectFocus');
                };

                scope.navigateBackTo = function (crumb) {
                    var index = _.findIndex(scope.breadcrumbs, {id: crumb.id});
                    scope.breadcrumbs.splice(index + 1, scope.breadcrumbs.length);
                    var gs = facadeTypeGroupFactory.load(_.last(scope.breadcrumbs).id, scope.isRebuild);
                    enhanceGroupFn(gs);
                };

                // Used for the validation
                var toggle = tElement.find(".drop2downFacadeTypesOuter");
                $(toggle).attr("id", tAttrs.reference + ".drop2down-toggle");

            }
        };
    }
})();
