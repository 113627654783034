(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .directive('agvSliderInput', AgvSliderInput)
        .controller('agvSliderInputCtrl', AgvSliderInputCtrl);

    AgvSliderInput.$inject = ['$rootScope', '$timeout', '$state', 'AgvSliderIdentMappingService', '$translate',
        'SmallBuildingAgvSliderPresetService', 'SmallBuildingService', 'SmallBuildingGalleryDataService'];

    function AgvSliderInput($rootScope, $timeout, $state, AgvSliderIdentMappingService, $translate,
        SmallBuildingAgvSliderPresetService, SmallBuildingService, SmallBuildingGalleryDataService) {

        return {
            restrict: 'E',
            scope: {
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                realEstateContainer: '=',
                reference: '=',
                referenceStr: '@',
                translateBaseKey: '@translateBaseKey',
                image: '=',
                defaultImage:'=?',
                mandatory: '@?',
                lessValues: '@',
                descriptionBaseKey: '@',
                tabindex: '@',
                tabgroup: '@',
                isLast: '@?',
                turnOnSlider: '=?',
                infotext: '@?'
            },
            templateUrl: 'app/small_building_agv_insurance_form/10_small_building_agv_insurance_input/30_small_building_agv_insurance_quality_form/agv_slider/agv_slider_input_partial.html',
            controller: function() {
            },
            link: function(scope, tElement, tAttrs, ctrl) {
                scope.firstLoad = true;
                scope.sliderPresetMark = null;
                scope.prefix = '';
                scope.values = [];
                scope.presetValue = null;
                var sliderActive = false;

                if (scope.turnOnSlider == undefined) {
                    scope.turnOnSlider = true;
                }

                scope.doShowInterval = false;
                scope.boxPlotData = [{}];
                scope.wkBoxPlotData = [{}];
                scope.whConfig = undefined;

                scope.wkLabelActive = false;

                var inputField = tElement.find('.normalSlider2Input');
                this.$label = inputField.data('ionRangeSlider');

                scope.values = AgvSliderIdentMappingService.getValuesByMore();
                scope.indexFun = angular.noop();
                if (tAttrs.lessValues === 'max') {
                    scope.indexFun = function(value) {
                        return AgvSliderIdentMappingService.getSliderValueIndexByMore(value);
                    };
                    scope.values = AgvSliderIdentMappingService.getValuesByMore();
                } else if (tAttrs.lessValues === 'min') {
                    scope.indexFun = function(value) {
                        return AgvSliderIdentMappingService.getSliderValueIndexByLess(value);
                    };
                    scope.values = AgvSliderIdentMappingService.getValuesByLess();
                }
                if (tAttrs.class === 'subSlider') {
                    var inputElement = tElement.find('.cInputElement');
                    inputElement.addClass(tAttrs.class);
                }

                var pushEvent = function(nominalValue, numericValue, ignoreSliderActive) {
                    // firstLoad is to make sure that default image and Description are not overwritten.
                    if (!scope.firstLoad && (sliderActive || !!ignoreSliderActive)) {
                        var usages = scope.realEstateContainer.agv.usages;
                        var nomUsageId = null;
                        if (usages.length === 0) {
                            nomUsageId = null;
                        } else {
                            nomUsageId = usages[0].type;
                        }
                        $rootScope.$broadcast('updateImage', {
                            rowid: scope.rowid,
                            key: 'agvWkSlider_' + nomUsageId + '_' + numericValue + '_' + scope.realEstateContainer.agv.totalArea416,
                            // imageSrc: sanitizeUrl(tAttrs.image) + numericValue + '.png' //currently we dont have good images
                            imageSrc: sanitizeUrl(tAttrs.defaultImage)
                        });
                        $rootScope.$broadcast('updateDescription', {
                            rowid: scope.rowid,
                            descriptionNumber: numericValue,
                            descriptionBaseKey: tAttrs.descriptionBaseKey,
                            sliderRange: scope.values.length,
                            sliderNotPresent: true,
                            remarks: true
                        });
                    } else {
                        scope.firstLoad = false;
                    }
                };


                var isCurrentSliderValue = function(value) {
                    var slider = inputField.data('ionRangeSlider');
                    var val = AgvSliderIdentMappingService.extractValue(value) - 1;
                    // console.info(slider.result.from, val, slider.result.from_value);
                    return slider.result.from === val;
                };

                scope.$watch('reference', function(newValue, oldValue) {
                    var modifiedNewValue = newValue;
                    //this happens when preset changes value.
                    if (!_.startsWith(modifiedNewValue, 'WK')) {
                        modifiedNewValue = 'WK' + (newValue + 1);
                    }
                    // console.warn(scope.reference, 'new ' + modifiedNewValue, 'old ' + oldValue, scope.presentationModel, !isCurrentSliderValue(modifiedNewValue));
                    if (modifiedNewValue !== oldValue && !isCurrentSliderValue(modifiedNewValue) && scope.turnOnSlider) {
                        $timeout(function() {
                            setSlider(modifiedNewValue);
                        });
                    }
                }, true);

                function addMarks(value) {
                    function convertToPercent(num, min, max) {
                        return (num - min) / (max - min) * 100;
                    }

                    function toFixed(num) {
                        num = num.toFixed(5);
                        return +num;
                    }

                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exists
                    var sliderData = inputField.data('ionRangeSlider');
                    if (sliderData) {
                        var min = sliderData.result.min;
                        var max = sliderData.result.max;

                        var real_width = 100 - sliderData.coords.p_handle;
                        var val = toFixed(scope.getIdx('WK' + (value + 1)) / 100 * real_width);
                        var left = convertToPercent(val, min, max);
                        if (left >= 0 && left <= 100) {
                            scope.sliderPresetMark = '<span class="sliderMark" style="left: ' + left + '%"></span>';
                        } else {
                            scope.sliderPresetMark = null;
                        }
                        if (scope.turnOnSlider) {
                            ctrl.update(scope.sliderPresetMark);
                        }
                    }
                }

                var presetsMark = $rootScope.$on('setPresetMarkAgv', function(event, args) {
                    // set the new image for the image in the corresponding row
                    if (args.referenceStr === scope.referenceStr && scope.turnOnSlider) {
                        var ret = SmallBuildingAgvSliderPresetService.requestPresetEvent(scope.realEstateContainer, scope.referenceStr);
                        addMarks(ret.preset);
                        addInterval(ret.interval);
                    }
                });

                scope.$on('getValuePreset', function(event, args) {
                    SmallBuildingService.getValueClassPreset(scope.realEstateContainer).then(function(response) {
                        console.log(response);
                        SmallBuildingService.valueClassPreset = response.data;
                        scope.presetValue = response.data;
                        // console.log('Reference :' + scope.reference + ' preset val: ', scope.presetValue, Math.round(scope.presetValue));
                        var preset = AgvSliderIdentMappingService.getSliderIdentByMore(scope.presetValue - 1);
                        if ($state.includes('**.input')) {
                            scope.reference = preset;
                        }
                        addMarks(preset);
                        console.log(preset);
                        $timeout(function() {
                            if (!!scope.realEstateContainer.agv.usages && scope.realEstateContainer.agv.usages.length > 0 && !!scope.realEstateContainer.agv.usages[0].type) {
                                var numericValue = Math.round(scope.presetValue) - 1;
                                if (numericValue >= 0) {
                                    pushEvent(numericValue, numericValue, true);
                                }
                            }
                        });
                    }, function(errorResponse){
                        var ret = SmallBuildingAgvSliderPresetService.requestPresetEvent(scope.realEstateContainer, scope.referenceStr);
                        addMarks(ret.preset);
                        console.warn(errorResponse);
                        //use default (no model value preset?)
                        $timeout(function() {
                            if (!!scope.realEstateContainer.agv.usages && scope.realEstateContainer.agv.usages.length > 0 && !!scope.realEstateContainer.agv.usages[0].type) {
                                var numericValue = Math.round(scope.presetValue)-1;
                                if (numericValue >= 0) {
                                    pushEvent(numericValue, numericValue, true);
                                }
                            }
                        });

                    });
                });

                function addInterval(interval) {
                    function rescale(val, b_min, b_max, a_min, a_max) {
                        // b_min: before min
                        // b_max: before max
                        // a_min: after min
                        // a_max: after max
                        return (a_max - a_min) * (val - b_min) / (b_max - b_min) + a_min;
                    }

                    function convertArr(arr, p_handle) {
                        return arr.map(function(e) {
                            var min = p_handle / 200;
                            var max = 1 - min;
                            return rescale(e / 7, 0, 1, min, max);
                        });
                    }

                    function convertArrToWk(arr, p_handle) {
                        return arr.map(function(e) {
                            var min = p_handle / 200;
                            var max = 1 - min;
                            return rescale(e, min, max, 1, 8);
                        });
                    }

                    // sliderData: slider element
                    // interval: array containing the quartiles mapped to wk numbers [0, 7]
                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exist
                    var sliderData = inputField.data('ionRangeSlider');
                    if (sliderData && interval) {
                        scope.whConfig = { width: sliderData.coords.w_rs, height: 10, barWidth: 10 - 2 };
                        var quartileData = interval.slice(2, 5);
                        var whiskerData = [interval[1], interval[5]];
                        var outlierData = [interval[0], interval[6]];
                        scope.boxPlotData[0] = {
                            'key': 0,
                            'quartile': convertArr(quartileData, sliderData.coords.p_handle),
                            'whiskers': convertArr(whiskerData, sliderData.coords.p_handle),
                            'outliers': convertArr(outlierData, sliderData.coords.p_handle),
                            'max': 1
                        };
                        scope.wkBoxPlotData[0] = {
                            'key': 0,
                            'quartile': convertArrToWk(scope.boxPlotData[0]['quartile'], sliderData.coords.p_handle),
                            'whiskers': convertArrToWk(scope.boxPlotData[0]['whiskers'], sliderData.coords.p_handle),
                            'outliers': convertArrToWk(scope.boxPlotData[0]['outliers'], sliderData.coords.p_handle),
                            'max': 1
                        };
                        if (scope.turnOnSlider) {
                            scope.doShowInterval = true;
                        }
                    }
                }

                scope.getIdx = function(value) {
                    return scope.indexFun(value);
                };

                var label;

                function Label(container) {
                    this.$label = $(container).find('.irs-single');
                    this.active = false;
                    this.ACTIVE = 'irs-single--active';
                }

                Label.prototype = {
                    start: function() {
                        if (!this.active) {
                            this.active = true;
                            this.$label.addClass(this.ACTIVE);
                            scope.wkLabelActive = true;
                        }
                    },
                    end: function() {
                        this.$label.removeClass(this.ACTIVE);
                        scope.wkLabelActive = false;
                        this.active = false;
                    }
                };

                var my_prettify = function(n) {
                    // console.warn(n + ' → ' + scope.prefix + (n + 1));
                    return scope.prefix + (n + 1);
                };

                scope.presentationModel = scope.getIdx(scope.reference);

                var ionRangeSliderOptions = {
                    type: 'single',
                    grid: false,
                    keyboard: true,
                    hide_min_max: true,
                    keyboard_step: 100 / scope.values.length + 1, /* In % ! */
                    // from_value: scope.reference, /*Position of the value*/
                    from: AgvSliderIdentMappingService.extractValue(scope.reference) - 1,
                    onStart: function(data) {
                        label = new Label(data.slider);
                    },
                    onChange: function(data) {
                        label.start();
                        sliderActive = true;
                        $timeout(function() {
                            //data.from_value is represents ordinal that's why we add +1.
                            scope.reference = 'WK' + (data.from_value + 1);
                        });
                        $timeout(function() {
                            pushEvent(data.from_value, data.from);
                        });
                    },
                    onFinish: function(data) {
                        // Change the model if the slider changes
                        $timeout(function() {
                            //data.from_value is represents ordinal that's why we add +1.
                            scope.reference = 'WK' + (data.from_value + 1);
                        });
                        $timeout(function() {
                            sliderActive = false;
                            label.end();
                        }, 1000);
                    },
                    onUpdate: function(data) {
                        $timeout(function() {
                            pushEvent(data.from_value, data.from);
                        });
                    },
                    prettify: my_prettify,
                    values: scope.values
                };
                inputField.ionRangeSlider(ionRangeSliderOptions);

                /**
                 * this must be below ionRangeSliderOptions.
                 * @param additionalHtml for sliderPresetMark.
                 */
                ctrl.update = function(additionalHtml) {
                    var slider = inputField.data('ionRangeSlider');
                    slider.destroy();
                    inputField.ionRangeSlider(ionRangeSliderOptions);
                    if (additionalHtml) {
                        slider = inputField.data('ionRangeSlider');
                        // $slider is a jquery function
                        var $slider = slider.result.slider;
                        $slider.append(additionalHtml);
                    }
                };
                /**
                 * this must be below ionRangeSliderOptions.
                 * @param newValue
                 */
                var setSlider = function(newValue) {
                    var valIndex = scope.indexFun(newValue);
                    if (valIndex > -1) {
                        // scope.presentationModel = valIndex;
                        var slider = inputField.data('ionRangeSlider');
                        ionRangeSliderOptions.from = valIndex;
                        ionRangeSliderOptions.prettify = my_prettify;
                        slider.update({
                            from: valIndex
                        });
                        $timeout(function() {
                            if (scope.sliderPresetMark != null) {
                                ctrl.update(scope.sliderPresetMark);
                            } else {
                                ctrl.update();
                            }
                        });
                    }
                };

                $(document).ready(function() {
                    setLanguageForSlider();
                });

                var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
                    setLanguageForSlider();
                });
                this.$onDestroy = function() {
                    var slider = inputField.data('ionRangeSlider');
                    slider.destroy();
                    translateSuccess();
                    presetsMark();
                };

                scope.$watch('SmallBuildingService.valueClassPreset', function(newVal, oldVal) {
                    console.log(newVal);
                }, true);


                /**
                 * this must be below ionRangeSliderOptions.
                 */
                function setLanguageForSlider() {
                    $translate('realEstate.agv.form.31_small_building_agv_insurance_costclass.slider.0.label')
                        .then(function(translation) {
                            scope.prefix = translation.slice(0, 2);
                            var slider = inputField.data('ionRangeSlider');
                            if (!angular.isUndefined(slider)) {
                                if (scope.sliderPresetMark != null) {
                                    ctrl.update(scope.sliderPresetMark);
                                } else {
                                    ctrl.update();
                                }
                            }
                        });
                }

                if (scope.isLast) {
                    $rootScope.$broadcast('requestPresetMarkAgv');
                }

                scope.$watch('turnOnSlider', function(newValue) {
                    if (scope.firstLoad) {
                        scope.firstLoad = false;
                        return;
                    }
                    if (scope.turnOnSlider) {
                        setSlider(scope.reference);
                        scope.latestBoxPlotData = SliderPresetService.requestPresetEvent(scope.realEstateContainer, scope.referenceStr);
                        $timeout(function() {
                            console.warn('turn on slider');
                            addMarks(scope.latestBoxPlotData.preset);
                            addInterval(scope.latestBoxPlotData.interval);
                        }, 500);
                    }
                });
            }
        };
    }

    AgvSliderInputCtrl.$inject = ['$scope', 'SmallBuildingAgvSliderPresetService', 'SmallBuildingService'];

    function AgvSliderInputCtrl($scope, SmallBuildingAgvSliderPresetService, SmallBuildingService) {

    }
})();
