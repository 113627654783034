(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('rebuildOperationalCostsTable', RebuildOperationalCostsTableDirective);

    RebuildOperationalCostsTableDirective.$inject = ['$rootScope'];

    function RebuildOperationalCostsTableDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                output : '=',
            },
            templateUrl: 'app/components/outputs/rebuild_operational_costs_table/rebuild-operational-costs-table.tpl.html',
            controller: RebuildOperationalCostsTableDirectiveController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                function formatValue(value) {
                    if (value === '0.-') return '';
                    else return value;
                }
                function formatRelativeValue(value) {
                    if (value === '0.00') return '';
                    else return value;
                }
                function isOwner(owner) {
                    return function(o) {
                        return o.owner === owner;
                    };
                }
                function combine(is, goal) {
                    var groupedIs = _.reduce(is , function(obj, entry) {
                        obj[entry.key] = entry;
                        return obj;
                    }, {});
                    var groupedGoal = _.reduce(goal , function(obj, entry) {
                        obj[entry.key] = entry;
                        return obj;
                    }, {});
                    return _.map(_.union(Object.keys(groupedIs), Object.keys(groupedGoal)), function (key) {
                        var hasIs = key in groupedIs;
                        var hasGoal = key in groupedGoal;
                        var obj = (hasIs) ? groupedIs : groupedGoal;
                        return {
                            key: obj[key].key,
                            isBold: obj[key].isBold,
                            translationId: obj[key].translationId,
                            translationKey: obj[key].translationKey,
                            isValue: (hasIs) ? formatValue(groupedIs[key].value) : '',
                            goalValue: (hasGoal) ? formatValue(groupedGoal[key].value) : '',
                            isRelativeValue: (hasIs) ? formatRelativeValue(groupedIs[key].relativeValue) : '',
                            goalRelativeValue: (hasGoal) ? formatRelativeValue(groupedGoal[key].relativeValue) : ''
                        };
                    });
                }
                function combineFor(owner) {
                    var is = _.filter(controller.output.isPomCosts, isOwner(owner));
                    var goal = _.filter(controller.output.pomCosts, isOwner(owner));
                    return combine(is, goal);
                }
                controller.output.VK = combineFor('VK');
                controller.output.EK = combineFor('EK');
                controller.output.NK = combineFor('NK');
            }
        };
    }

    RebuildOperationalCostsTableDirectiveController.$inject = ['$scope'];

    function RebuildOperationalCostsTableDirectiveController($scope) {
        var vm = this;
        vm.output = $scope.output;
    }

})();
