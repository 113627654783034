(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactTypeSelect', {
            restrict: 'E',
            bindings: {
                selectId: '<',
                rowid: '<',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '<?',
                imageF: '&?',
                imageDefault: '<?',
                mandatory: '@?',
                classes: '@?',
                defaultValue: '<?',
                value: '=',
                values: '<',
                onChange: '<?'
            },
            templateUrl: 'app/components/compact_inputs/compact_type_select/compact-type_select.tpl.html',
            controller: CompactTypeSelectController
        });

    CompactTypeSelectController.$inject = ['$scope', '$rootScope', '$log'];

    function CompactTypeSelectController($scope, $rootScope, $log) {
        var $ctrl = this;

        function ensureValue(newValue) {
            if (newValue === null) return null;
            return _.find($ctrl.values, function (value) {
                return newValue === value;
            });
        }

        $ctrl.onSelect = function(selectedItem) {
            $ctrl.valueUserDirty = true;
            $ctrl.updateDescriptionAndImage(selectedItem);
        };

        $ctrl.onOpenClose = function(isOpen) {
            if (isOpen) {
                $ctrl.updateDescriptionAndImage($ctrl.value);
            }
        };

        $ctrl.updateDescriptionAndImage = function(selectedItem) {
            function getImage() {
                return ($ctrl.image !== undefined)
                    ? $ctrl.image
                    : (selectedItem == null) ? $ctrl.imageDefault : $ctrl.imageF({value:selectedItem.value});
            }
            if (selectedItem) {
                $rootScope.$broadcast('updateImage', {
                    rowid: $ctrl.rowid,
                    imageSrc: getImage()
                });
                $rootScope.$broadcast('updateDescription', {
                    rowid: $ctrl.rowid,
                    descriptionBaseKey: $ctrl.translateDescriptionBaseKey + '.' + selectedItem.value
                });
            } else {
                $rootScope.$broadcast('updateImage', {
                    rowid: $ctrl.rowid,
                    imageSrc: getImage()
                });
                $rootScope.$broadcast('updateDescription', {
                    rowid: $ctrl.rowid,
                    descriptionBaseKey: $ctrl.translateDescriptionBaseKey
                });
            }
        };

        $scope.$watch('$ctrl.value', function(newValue, oldValue) {
            $ctrl.onChange(newValue, oldValue);
        });

        $scope.$watch('$ctrl.values', function(newValues, oldValues) {
            if ($ctrl.valueUserDirty) {
                $ctrl.value = ensureValue($ctrl.value);
            } else {
                $ctrl.value = ensureValue($ctrl.value || $ctrl.startValue || $ctrl.defaultValue);
            }
        });

        this.$onInit = function() {
            $ctrl.valueUserDirty = false;
            $ctrl.startValue = $ctrl.value;
            $ctrl.value = ensureValue($ctrl.startValue || $ctrl.defaultValue);
        };
    }
})();
