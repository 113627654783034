(function () {
    'use strict';
    angular.module('kennwerteApp')
        .factory('ChillerTypeDataService', ChillerTypeDataService);

    ChillerTypeDataService.$inject = [];

    function ChillerTypeDataService() {

        var service = {};

        var baseGroup = 'Lüftung-, Kälteanlagen';
        var sharedGroup = 'Bezug';

        service.NO     = {"group": baseGroup,   "value": "NO"};
        service.YES    = {"group": baseGroup,   "value": "YES"};

        service.SHARED_ANNEX            = {"group": sharedGroup, "value": "SHARED_ANNEX"};
        service.SHARED_ADDITION_STORY   = {"group": sharedGroup, "value": "SHARED_ADDITION_STORY"};
        service.SHARED_OVERHAUL         = {"group": sharedGroup, "value": "SHARED_OVERHAUL"};

        var baseVentilationTypes = [
            service.NO,
            service.YES
        ];

        var shared = [
            service.SHARED_ANNEX,
            service.SHARED_ADDITION_STORY,
            service.SHARED_OVERHAUL
        ];

        service.defaultData = baseVentilationTypes;
        service.rebuildIs = baseVentilationTypes;
        service.rebuildTarget = shared.concat(baseVentilationTypes);

        return service;
    }


})();
