(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildComponentSliderService', RebuildComponentSliderService);

    RebuildComponentSliderService.$inject = ['NormalDistributionService'];

    function RebuildComponentSliderService(NormalDistributionService) {

        function transformInterventionGrade(ig) {
            /*
            * Why is transform needed:
            * Intervention grade ranges from 0 to 100.
            * Measure class reanges from 1 to 5.
            * The measure slider shows the input as measure classes and the box plot is the underlying intervention grade.
            * However the intervention grade interval behind each measure class does not grow linearly with the measure class.
            * Therefore the intervention grade ig must be projected, so the intervention grade based box plot fits with the measure slider values.
            *
            * How is it done:
            * MK1 is on the far left (0%), MK2 is at 25%, MK3 is at 50%, MK4 is at 75% and MK5 is on the far right (100%).
            * MK1 is between 0 and 10 (but always on the far right) => at 0 intervention grade is the slider input
            * MK2 is between 10 and 25 intervention grade => at 17.5 intervention grade is the slider input
            * So we have to map intervention grades between MK1 and MK2 into the range 0 to 25%, so the two formulas:
            * 0 * x + y = 0
            * 17.5 * x + y = 25
            * must be solved, which results into
            * x = 10 / 7 and y = 0 (which is the second if statement)
            * I used this: https://www.wolframalpha.com/input/?i=systems+of+equations+calculator&assumption=%7B%22F%22%2C+%22SolveSystemOf2EquationsCalculator%22%2C+%22equation1%22%7D+-%3E%220+*+x+%2B+y+%3D+0%22&assumption=%22FSelect%22+-%3E+%7B%7B%22SolveSystemOf2EquationsCalculator%22%7D%7D&assumption=%7B%22F%22%2C+%22SolveSystemOf2EquationsCalculator%22%2C+%22equation2%22%7D+-%3E%2217.5+*+x+%2B+y+%3D+25%22
            *
            * This has to be repeated for all MKs, which is how those numbers where found.
            */
            if (ig === undefined) return ig;
            // Transform intervention grade ig into the position it takes along the measure slider.
            if (ig <= 0) return 0;
            if (ig <= 17.5) return ig * 10 / 7;
            else if (ig <= 32.5) return ig * 5 / 3 - 25 / 6;
            else if (ig <= 60) return ig * 10 / 11 + 225 / 11;
            else if (ig <= 100) return ig * 5 / 8 + 75 / 2;
            else return 100;
        }

        function transformRemainingValue(remainingValue) {
            var transformedIg = transformInterventionGrade(100 - remainingValue);
            return 100 - transformedIg;
        }

        function lookupQuantilesRemainingValue(cdfInverse) {
            return {
                'quantiles': [
                    cdfInverse( 0.05),
                    cdfInverse(0.10),
                    cdfInverse(0.25),
                    cdfInverse(0.50),
                    cdfInverse(0.75),
                    cdfInverse(0.90),
                    cdfInverse(0.95)
                ]
            };
        }

        function lookupQuantilesInterventionGrade(cdfInverse) {

            var totalInterventionGrade = cdfInverse(0.50);
            if (80 <= totalInterventionGrade) {
                totalInterventionGrade = 100;
            }

            return {
                'total_intervention_grade': totalInterventionGrade,
                'quantiles': [
                    cdfInverse(0.05),
                    cdfInverse(0.10),
                    cdfInverse(0.25),
                    totalInterventionGrade,
                    Math.max(totalInterventionGrade, cdfInverse(0.75)), // intervention grade maybe fixed, so box plot must be maybe fixed as well
                    Math.max(totalInterventionGrade, cdfInverse(0.90)),
                    Math.max(totalInterventionGrade, cdfInverse(0.95))
                ]
            };
        }

        function lookupQuantilesInterventionGradeWithGap(cdfInverse, cutFrom, cutTo) {
            var res = lookupQuantilesInterventionGrade(cdfInverse);
            res.gap = [
                cutFrom,
                cutTo
            ];
            return res;
        }

        function getConditionClassBasedOnRemainingValue(remainingValue) {
            var condition;
            if (remainingValue >= 90) condition = "ZK5";
            else if (remainingValue >= 75) condition = "ZK4";
            else if (remainingValue >= 60) condition = "ZK3";
            else if (remainingValue >= 20) condition = "ZK2";
            else condition = "ZK1";
            return condition;
        }

        function setRemainingValueInterval(component) {
            component.remainingValueInterval = this.lookupQuantilesRemainingValue(
                NormalDistributionService.cdfInverse(NormalDistributionService.get(component.remainingValueBackend, 15))
            ).quantiles;
        }

        return {
            lookupQuantilesRemainingValue: lookupQuantilesRemainingValue,
            lookupQuantilesInterventionGrade: lookupQuantilesInterventionGrade,
            lookupQuantilesInterventionGradeWithGap: lookupQuantilesInterventionGradeWithGap,
            transformInterventionGrade: transformInterventionGrade,
            transformRemainingValue: transformRemainingValue,
            getConditionClassBasedOnRemainingValue: getConditionClassBasedOnRemainingValue,
            setRemainingValueInterval: setRemainingValueInterval
        };
    }

})();
