(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactYearInput', {
            bindings: {
                element: '=',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                isDisabled: '=',
                minlength: '@',
                maxlength: '@',
                validationMessage: '@',
                mandatory: '=',
                calcButton: '<',
                openCalc: '&',
                isCalcValid: '&',
                openCalcInvalid: '&',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?'
            },
            templateUrl: 'app/components/compact_inputs/compact_year_input/compact-year-input.tpl.html',
            controller: CompactYearInputController
        });

    CompactYearInputController.$inject = ['$scope', '$rootScope', '$attrs', '$element', 'RebuildModernizationPresetService'];

    function CompactYearInputController($scope, $rootScope, $attrs, $element, RebuildModernizationPresetService) {
        var $ctrl = this;

        this.$onInit = function() {
            $scope.$watch('$ctrl.element.yearUserInput', function(newValue, oldValue) {
                $ctrl.model = newValue;
            });
        };

        $scope.$on("setYearPreset", function (event, args) {
            console.info("setYearPreset in compact-year-input");
            RebuildModernizationPresetService.presetYear($ctrl.element, args.year);
        });

        $ctrl.onFocus = function() {
            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $ctrl.descriptionBaseKey
            });
        };

        $ctrl.onBlur = function() {
        };

        $ctrl.onChange = function() {
            // Guarantee type-safety for yearUserInput (integer | null)
            var value = parseInt($ctrl.model);
            value = isNaN(value) ? null : value;

            if (value != null && value.toString().length > $ctrl.maxlength) {
                value = Math.floor(value  / 10);
                $ctrl.model = value; // Overwrite user input, so maxlength is respected
            }

            $ctrl.element.yearUserInput = value;
            $ctrl.element.year = Number($ctrl.element.yearUserInput || $ctrl.element.yearPlaceholder);
        };

        var inputField = $element.find("input")[0];

        if ($attrs.required) {
            inputField.required = true;
        }

        // Used for the validation
        $($element).attr("id", $attrs.element.year + ".formElement");
        $(inputField).attr("id", $attrs.element.year + ".input");


    }

})();
