(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$translate', 'JhiLanguageService', 'ProfileService', 'translationHandler', '$window',
        'Auth', 'Principal', 'GTMService', '$transitions'];

    function stateHandler($rootScope, $state, $sessionStorage, $translate, JhiLanguageService, ProfileService, translationHandler, $window,
        Auth, Principal, GTMService, $transitions) {
        return {
            initialize: initialize
        };

        function initialize() {
            // $rootScope.toState = {};
            // $rootScope.toStateParams = {};
            // $rootScope.fromState = {};

            ProfileService.getProfileInfo().then(function(response) {
                $rootScope.inProduction = response.inProduction;
            });

            Principal.identity().then(function(data) {
                if (data != null) {
                    $translate.use(data.langKey);
                }
            }, function(error) {
                console.warn(error);
            }).catch(function(e) {
                console.warn(e);
            });

            $transitions.onStart({}, function(transition) {
                var toState = transition.to();
                var fromState = transition.from();
                var toStateParams = transition.params('to');
                var fromParams = transition.params('from');

                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;
                if (toState.redirectTo) {
                    $state.go(toState.redirectTo, toStateParams);
                }

                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    $window.open(toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }
            });


            var startsWith = function(str, prefix) {
                return str.substr(0, prefix.length) === prefix;
            };

            $transitions.onSuccess({}, function(transition) {
                var toState = transition.to();
                if (!toState.name || !startsWith(toState.name, 'process-form.') || !startsWith(toState.name, 'saferpay-')) {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                // console.warn(toState, toStateParams, fromState, fromParams);

                var titleKey = 'global.title';

                // Set the page title key to the one configured in state or use default one
                if (toState.data && toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                try {
                    translationHandler.updateTitle(titleKey);
                } catch (e){
                    console.error('ok i tried:' +e);
                }

                if ($rootScope.inProduction) {
                    // google analytics
                    var stateName = $state.current.name;
                    var url = $state.current.url;
                    var path = stateName.replace(/\./g, '/'); // replaces dots to mimic regular path with slashes
                    GTMService.virtualPageview(url);
                    // send event of category State
                    GTMService.trackEvent({
                        action: stateName,
                        category: 'State',
                        label: path
                    });
                }
            });

            $transitions.onError({}, function(transition) {
                var toState = transition.to();
                var fromState = transition.from();
                var toStateParams = transition.params('to');
                var fromParams = transition.params('from');
                // eslint-disable-next-line no-console
                console.warn(fromState, toState, toStateParams, fromParams, transition.error());
            });
        }
    }
})();
