(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('BexioService', BexioService);

    BexioService.$inject = ['$http'];

    function BexioService($http) {
        return {
            sync: function() {
                return $http({
                    url: 'api/bexio/sync',
                    method: 'GET'
                });
            }
        }
    }
})();
