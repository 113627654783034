(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('DemolitionService', DemolitionService);

    DemolitionService.$inject = ['$http'];

    function DemolitionService($http) {
        return {
            estimateVolumeCost: function (volume, constructionKind) {
                return $http.get('api/demolition/volume_cost?volume='+volume+'&construction_kind='+constructionKind);
            },
            estimateSurroundingAreaCost: function(surrounding_area) {
                return $http.get('api/demolition/surrounding_area_cost?surrounding_area='+surrounding_area);
            }
        };
    }
})();
