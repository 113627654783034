(function () {
    'use strict';

    angular.module('kennwerteApp')
    .directive('referenceUsageTypeSelect', UsageTypeSelectDirective)
    .directive('referenceUiSelectFocuser', UiSelectFocuser);

    ReferenceUsageTypeSelectController.$inject = ['$scope', '$state', 'referenceUsageTypeGroupFactory', '$timeout', '$rootScope', '$translate'];

    function ReferenceUsageTypeSelectController($scope, $state, referenceUsageTypeGroupFactory, $timeout, $rootScope, $translate) {
        var vm = this;
        vm.translateKey = function (postfix) {
            try {
                return $translate.instant('realEstate.usages.' + postfix);
            } catch (e) {
                console.warn('translation error reference usages id:1',e);
            }
        };

        vm.showDisabledToast = showDisabledToast;

        vm.updateDescriptionAndImage = function (number) {
            var tempId;
            if (number) {
                tempId = number.id;
            } else {
                tempId = vm.model.id;
                if (!tempId) {
                    tempId = '00';
                }
            }
            $rootScope.$broadcast("updateImage", {
                rowid: $scope.rowid,
                imageSrc: $scope.image
            });

            var key = $scope.descriptionBaseKey;
            var concatenatedGroupIds = "";
            var groupId = tempId;
            do {
                var prevGroupId = referenceUsageTypeGroupFactory.getGroupIdById(Number(groupId));
                prevGroupId = Number(prevGroupId);
                if (prevGroupId !== 0) {
                    if (concatenatedGroupIds !== "") {
                        concatenatedGroupIds = prevGroupId + "." + concatenatedGroupIds;
                    } else {
                        concatenatedGroupIds = prevGroupId;
                    }
                }
                groupId = prevGroupId;
            } while (groupId !== 0);
            var k = key;
            if (concatenatedGroupIds !== "") {
                k = key + "." + concatenatedGroupIds;
            }

            $rootScope.$broadcast("updateDescription", {
                rowid: $scope.rowid,
                descriptionNumber: tempId,
                dynamicTranslations: true,
                descriptionBaseKey: k
            });
        };

        vm.saveSelection = function (selectedItem) {
            if (!selectedItem.parent) {
                $scope.reference = selectedItem;
                angular.copy(selectedItem, $scope.reference);
                vm.model = selectedItem;
                if ($scope.isMainUsage && $scope.usagesLength == 1) {
                    $scope.referenceArray.push({
                        percentage: null
                    });
                }
            } else {
                $scope.reference = null;
                vm.model = {
                    "id": "00",
                    "title": "All",
                    "parent": true,
                };
            }
        };

        vm.onSelect = function (selectedItem, $model) {
            vm.updateDescriptionAndImage(selectedItem);
            if (selectedItem.disabled) {
                showDisabledToast(selectedItem.translated);
                vm.model = referenceUsageTypeGroupFactory.getPreviousGroup(selectedItem.title);
                $scope.reference = null;
            } else {
                if (!selectedItem.parent) {
                    showPresetsMarkToast();
                    $scope.reference = selectedItem.title;
                    $scope.$broadcast('uiSelectClose');
                } else {
                    // vm.saveSelection(selectedItem);
                    // if (selectedItem.id.length < 3) {
                    //     $scope.reference = null;
                    // }
                    // $scope.reference = "";
                    vm.saveSelection(selectedItem);
                    $scope.loadChildGroupsOf(selectedItem);

                    var element = '#usageToast';
                    if ($(element).hasClass("tooltipstered")) {
                        $(element).tooltipster('destroy');
                    }
                }
            }
        };

        vm.onOpenClose = function (isOpen) {
            if (isOpen) {
                vm.updateDescriptionAndImage();
            }
        };

        function isCurrentStateEditRecord() {
            return $scope.isEdit;
        }

        function showToast(element, text) {
            if ($(element).hasClass("tooltipstered")) {
                var instance = $(element).tooltipster('instance');
                instance.content(text);
                instance.open();
            } else {
                $timeout(function () {
                    $(element).tooltipster({
                        contentAsHTML: true,
                        content: text,
                        theme: 'tooltipster-kw',
                        side: ['right'],
                        zIndex: 1080,
                        interactive: true,
                        selfDestruction: true,
                        animation: 'fade',
                        maxWidth: 350,
                        plugins: ['sideTip'],
                        distance: 0,
                        arrow: false,
                        timer: 10000,
                        repositionOnScroll: true,
                        triggerClose: {
                            click: true,
                            scroll: true
                        }
                    }).tooltipster('open');
                });
            }
        }


        function showPresetsMarkToast() {
            var translated = '';
            if ($scope.isMainUsage) {
                try {

                    if (isCurrentStateEditRecord()) {
                        translated = $translate.instant('realEstate.general.process-form.edit-record.presetsMarkTooltip');
                    } else {
                        translated = $translate.instant('realEstate.general.process-form.input.presetsMarkTooltip');
                    }
                } catch (e) {
                    console.warn('translation error preset Mark. ', e);
                }
                var element = '#usageToast';
                return showToast(element, translated);
            }
        }

        function showDisabledToast(usageTitle) {
            var element = '#usageToast';
            var translated = 'Aufgrund der Datenlage bieten wir die Nutzung "' + usageTitle + '" noch nicht an.';
            return showToast(element, translated);
        }

        vm.model = referenceUsageTypeGroupFactory.getByTitle($scope.reference);
        if (vm.model.id) {
            vm.model = {
                "id": vm.model.id,
                "title": vm.model.title,
                "parent": vm.model.parent,
                "translated": vm.translateKey(vm.model.title),
                "disabled": vm.model.disabled,
                "fU": vm.model.fU
            };
        } else {
            vm.model = {
                "id": "00",
                "title": "All",
                "parent": true
            };
        }

        /**
         * This watch checks if the reference changes and updates the whole component.
         * This can happen if we load data from the db async.
         */
        $scope.$watch('reference', function (newValue, oldValue) {
            if (newValue) {
                vm.loadReference();
            }
        });

        $scope.$on('showSliderPresetToast', function () {
            showPresetsMarkToast();
        });

        // This function returns the correct base translation strings
        // for the functionalUnits
        vm.getfUtranslateKey = function () {
            return $scope.isMainUsage ? $scope.fuTranslateBaseKey + '.main' : $scope.fuTranslateBaseKey + '.secondary';
        };
    }

    UsageTypeSelectDirective.$inject = ['referenceUsageTypeGroupFactory', '$templateCache', '$timeout', '$rootScope', '$translate'];

    function UsageTypeSelectDirective(referenceUsageTypeGroupFactory, $templateCache, $timeout, $rootScope, $translate) {
        return {
            restrict: 'E',
            scope: {
                isMainUsage: '=',
                isEdit: '=?',
                usagesLength: '@',
                reference: '=',
                percentage: '=',
                standard: '=',
                referenceArray: '=?',
                rowid: '=',
                image: '=image',
                required: '=required',
                isDisabled: '=',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                index: '@',
                tabIndex: '=',
                tabGroup: '=',
                fuTranslateBaseKey: '@?',
                fuReference: '=?fuReference',
                infotext: '@?'
            },
            templateUrl: 'app/components/reference_usage_type/reference-usage-type-select.tpl.html',
            controller: ReferenceUsageTypeSelectController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, ctrl) {

                /**
                 * There is no check if the reference exists. So make sure it is checked.
                 */
                ctrl.loadModel = function () {
                    ctrl.model = referenceUsageTypeGroupFactory.getByTitle(scope.reference);
                    ctrl.model.translated = scope.translateKey(ctrl.model.title);
                    ctrl.updateDescriptionAndImage(ctrl.model);
                };

                /**
                 * Loading breadcrumbs and also loads Items for (group) with enhancedGroupFn
                 */
                ctrl.loadBreadCrumbs = function () {
                    var groupId = referenceUsageTypeGroupFactory.getGroupIdById(ctrl.model.id);
                    var prevGroups = referenceUsageTypeGroupFactory.getAllPreviousGroups(ctrl.model.id);
                    scope.breadcrumbs = [{"id": "00", "title": "All", "parent": true}];
                    for (var prevGroupKey in prevGroups) {
                        var prevGroup = prevGroups[prevGroupKey];
                        scope.breadcrumbs.push(prevGroup);
                    }
                    enhanceGroupFn(referenceUsageTypeGroupFactory.load(groupId));
                };

                scope.translateKey = function (postfix) {
                    try {
                        return $translate.instant('realEstate.usages.' + postfix);
                    } catch (e) {
                        console.warn('translation error reference usages', e);
                    }
                };

                scope.breadcrumbs = [{"id": "00", "title": "All", "parent": true}];
                ctrl.model = referenceUsageTypeGroupFactory.getByTitle(scope.reference);

                var gs = [];

                /**
                 * Loading Groups which should be currently displayed.
                 * @param gs is an Array of Usages (e.g. from group.service)
                 */
                var enhanceGroupFn = function (gs) {
                    for (var i in scope.groups) {
                        var oldg = scope.groups[i];
                        oldg.noshow = true;
                    }

                    var enhancedGroups = [];
                    for (var j in gs) {
                        var group = gs[j];
                        if (!scope.isMainUsage || group.title !== "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN") {
                            enhancedGroups.push({
                                "id": group.id,
                                "title": group.title,
                                "parent": group.parent,
                                "translated": scope.translateKey(group.title),
                                "disabled": group.disabled,
                                "fU": group.fU
                            });
                        }
                    }
                    scope.groups = enhancedGroups;
                };

                /**
                 * loads Reference and can also be used to reload the reference
                 * which will result in a conistent state (model, groups and reference).
                 *
                 */
                ctrl.loadReference = function () {
                    if (scope.reference) {
                        ctrl.loadModel();
                        ctrl.loadBreadCrumbs();
                        if (scope.isMainUsage && scope.usagesLength == 1) {
                            scope.referenceArray.push({
                                type: null,
                                percentage: null,
                                standard: null
                            });
                        }
                    } else {
                        gs = referenceUsageTypeGroupFactory.load("00");
                        enhanceGroupFn(gs);
                    }
                };

                ctrl.loadReference();


                // add property translated to to each group in groups

                scope.loadChildGroupsOf = function (group) {
                    scope.breadcrumbs.push(group);
                    var gs = referenceUsageTypeGroupFactory.load(group.id);
                    enhanceGroupFn(gs);
                };

                scope.navigateBackTo = function (crumb) {
                    var index = _.findIndex(scope.breadcrumbs, {id: crumb.id});
                    scope.breadcrumbs.splice(index + 1, scope.breadcrumbs.length);
                    var gs = referenceUsageTypeGroupFactory.load(_.last(scope.breadcrumbs).id);
                    ctrl.saveSelection(crumb);
                    ctrl.updateDescriptionAndImage(crumb);
                    enhanceGroupFn(gs);
                };

                // Only allow numbers in the input field
                // The simpler 'type="number"' is causing the curser to jump
                // if a letter is entered.
                tElement.find(".percentageInputField").on('change keyup', function () {
                    // Remove invalid characters
                    var sanitized = $(this).val().replace(/[^0-9]/g, '');
                    // Update value
                    $(this).val(sanitized);
                });

                // Used for the validation
                var toggle = tElement.find(".drop2downUsageTypesOuter");
                ctrl.standardReferenceString = tAttrs.referenceArray + "[" + Number(scope.index) + "].standard";
                $(toggle).attr("id", tAttrs.referenceArray + "[" + Number(scope.index) + "].type.drop2down-toggle");
                var percentageElement = tElement.find(".percentageInputField");
                $(percentageElement).attr("id", tAttrs.referenceArray + "[" + Number(scope.index) + "].percentage.input");

            }
        };
    }

    UiSelectFocuser.$inject = ['$timeout'];

    function UiSelectFocuser($timeout) {
        return {
            restrict: 'A',
            require: '^uiSelect',
            link: function (scope, elem, attrs, uiSelect, ctrl) {
                scope.$on('uiSelectClose', function () {
                    uiSelect.close();
                });

                // resets the active Element always to the first Element
                scope.$on('uis:refresh', function () {
                    uiSelect.activeIndex = 0;

                });

                //this overwrites the function isActive in
                // ui-select
                // check select.js in bower_components (codeLine 615)
                uiSelect.isActive = function (item) {
                    if (!uiSelect.open) {
                        return false;
                    }
                    var itemIndex = uiSelect.items.indexOf(item[uiSelect.itemProperty]);
                    return itemIndex == uiSelect.activeIndex;

                };
            }
        };
    }

})
();
