/**
 * Mapping from room-height-limits.json generated by RoomHeightLimitsJsonGenerator.java
 */
(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RoomHeightLimitsService', RoomHeightLimitsService);

    RoomHeightLimitsService.$inject = [];

    function RoomHeightLimitsService() {
        var mapping = {
            "VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH": [
                2.8,
                3.0,
                3.6
            ],
            "FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER": [
                4.0,
                6.0,
                12.0
            ],
            "SCHULBAUTEN__KINDERGAERTEN_HORTE": [
                3.1,
                4.0,
                5.2
            ],
            "BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU": [
                2.9,
                3.5,
                3.8
            ],
            "KULTUR__SAAL": [
                6.0,
                10.0,
                12.0
            ],
            "LAGERBAUTEN_MEHRSTOECKIGE": [
                2.8,
                5.0,
                10.0
            ],
            "WOHNBAUTEN__TERRASSENHAEUSER_LOW": [
                2.7,
                3.05,
                3.4
            ],
            "SCHULBAUTEN__VOLKSSCHULEN": [
                3.1,
                4.0,
                5.2
            ],
            "BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER": [
                2.9,
                3.5,
                3.8
            ],
            "WOHNBAUTEN__EFH_REIHEN_HIGH": [
                2.7,
                3.05,
                3.6
            ],
            "WOHNBAUTEN__MFH": [
                2.7,
                3.05,
                3.4
            ],
            "BANKEN": [
                2.9,
                3.5,
                4.0
            ],
            "WOHNBAUTEN__MFH_LOW": [
                2.7,
                3.05,
                3.4
            ],
            "WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME": [
                2.8,
                3.4,
                3.8
            ],
            "WOHNBAUTEN__TERRASSENHAEUSER_MEDIUM": [
                2.7,
                3.05,
                3.4
            ],
            "WOHNBAUTEN__MFH_MEDIUM": [
                2.7,
                3.05,
                3.4
            ],
            "WOHNBAUTEN__EFH_REIHEN_LOW": [
                2.7,
                3.05,
                3.6
            ],
            "LAGERBAUTEN_PRODUKTIONSBAUTEN": [
                3.3,
                4.0,
                6.3
            ],
            "FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE": [
                3.0,
                4.0,
                5.0
            ],
            "LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL": [
                4.0,
                4.7,
                6.0
            ],
            "GESUNDHEIT__PFLEGEHEIME": [
                2.8,
                3.45,
                3.8
            ],
            "WOHNBAUTEN__STUDENTENWOHNHÄUSER": [
                2.8,
                3.4,
                3.8
            ],
            "WOHNBAUTEN__STUDENTENWOHNHAEUSER": [
                2.8,
                3.4,
                3.8
            ],
            "WOHNBAUTEN__ALTERSWOHNUNGEN": [
                2.7,
                3.05,
                3.4
            ],
            "SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN": [
                3.1,
                4.0,
                5.2
            ],
            "KULTUS__KIRCHGEMEINDEHAEUSER": [
                3.0,
                3.9,
                4.6
            ],
            "WOHNBAUTEN__EFH_REIHEN_MEDIUM": [
                2.7,
                3.05,
                3.6
            ],
            "WOHNBAUTEN__TERRASSENHAEUSER_HIGHEST": [
                2.7,
                3.05,
                3.4
            ],
            "WOHNBAUTEN__TERRASSENHAEUSER": [
                2.7,
                3.05,
                3.4
            ],
            "WOHNBAUTEN__ALTERSHEIME": [
                2.8,
                3.4,
                3.8
            ],
            "LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER": [
                2.9,
                3.5,
                3.8
            ],
            "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN": [
                2.8,
                3.0,
                3.6
            ],
            "SCHULBAUTEN__SPEZIELLE_FORSCHUNGSBAUTEN": [
                3.1,
                4.0,
                5.2
            ],
            "LAGERBAUTEN__INDUSTRIEHALLEN": [
                5.0,
                6.8,
                10.5
            ],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HOTELBAUTEN": [
                2.8,
                3.4,
                3.8
            ],
            "WOHNBAUTEN__MFH_HIGH": [
                2.7,
                3.05,
                3.4
            ],
            "KULTUR__KULTUR_GEMEINSCHAFTSZENTREN": [
                3.5,
                4.5,
                6.0
            ],
            "KULTUR__MUSEEN": [
                4.0,
                5.0,
                10.0
            ],
            "BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU": [
                2.9,
                3.5,
                3.8
            ],
            "WOHNBAUTEN__EFH_REIHEN": [
                2.7,
                3.05,
                3.6
            ],
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TURNHALLEN_SPORTHALLEN_MEHRZWECKHALLEN": [
                4.3,
                7.5,
                10.0
            ],
            "JUSTIZ__STRAFVOLLZUGSANSTALTEN": [
                2.8,
                3.3,
                3.8
            ]
        };

        return {
            getMapping: function () {
                return mapping;
            }
        }

    }




})();
