(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('HowtoEditRecordController', HowtoEditRecordController);

    HowtoEditRecordController.$inject = ['$scope', '$stateParams', '$state'];

    function HowtoEditRecordController($scope, $stateParams, $state) {
        $scope.data = $stateParams.data;
    }

})();

