(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('currencyInput', {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '=',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                tabIndex: '=',
                tabGroup: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                classes: '@?',
                infotext: '@?'

            },
            templateUrl: 'app/components/currency_input/currency-input.tpl.html',
            controller: CurrencyInputController
        });

    CurrencyInputController.$inject = ['$scope', '$rootScope', '$element', '$translate', '$attrs'];

    function CurrencyInputController($scope, $rootScope, $element, $translate, $attrs) {
        var $ctrl = this;
        // $ctrl.firstLoad = true;
        $ctrl.hasFocusOutFired = false;


        $ctrl.onOpenClose = function(isOpen) {
            if (isOpen) {
                $rootScope.$broadcast('updateDescription', {
                    rowid: $ctrl.rowid,
                    descriptionNumber: 0,
                    descriptionBaseKey: $ctrl.descriptionBaseKey
                });
                $rootScope.$broadcast('updateImage', {
                    rowid: $ctrl.rowid,
                    imageSrc: $ctrl.imageSrc
                });
            }
        };

        $ctrl.onFocus = function() {
            $ctrl.hasFocusOutFired = false;
            // Push event to CalculationRowController
            if ($ctrl.reference && $ctrl.reference !== undefined && $ctrl.reference !== '' && $ctrl.reference !== null) {
                var strValueOut = $ctrl.reference.toString();
                strValueOut = strValueOut.replace(/.-/g, '');
                $ctrl.reference = strValueOut;
            }
            var rowid = $ctrl.rowid;
            $rootScope.$broadcast('updateImage', {
                imageSrc: $ctrl.imageSrc,
                rowid: rowid
            });
            $rootScope.$broadcast('updateDescription', {
                rowid: $ctrl.rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $ctrl.descriptionBaseKey,
                dynamicTranslations: false
            });
        };

        $ctrl.onFocusOut = function() {
            if ($ctrl.hasFocusOutFired) {
                return;
            }
            $ctrl.hasFocusOutFired = true;
            if ($ctrl.reference && $ctrl.reference !== undefined && $ctrl.reference !== '' && $ctrl.reference !== null) {
                $ctrl.reference = $ctrl.reference + '.-';
            }
        };

        var startsWith = function(str, prefix) {
            return str.substr(0, prefix.length) === prefix;
        };

        $ctrl.onKeyUp = function() {
            try {
                if ($ctrl.reference && $ctrl.reference !== undefined && $ctrl.reference !== '' && $ctrl.reference !== null) {
                    var strValue = $ctrl.reference.toString();
                    var isMinus = startsWith(strValue, '-');
                    strValue = strValue.replace(/\D/g, '');
                    strValue = strValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\'');
                    if (isMinus) {
                        strValue = '-' + strValue;
                    }
                    $ctrl.reference = strValue;
                }
            } catch (err) {
                console.log(err);
            }
        };

        $ctrl.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');


            // Used for the validation
            var toggle = $element.find('.currencyInput');
            $(toggle).attr('id', $ctrl.referenceString);
        };
    }
})();
