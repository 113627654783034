(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('CompleteInputController', CompleteInputController);

    CompleteInputController.$inject = ["$scope", "$rootScope", "$stateParams", "$state", "WithdrawalService", "Principal", "$sessionStorage", "$filter", "usageTypeGroupFactory", "ValidationDataService", "ValidationFunctionsService", "SharedControllerFnService", 'POMIntegrationService', 'LCCIntegrationService', 'BillingModalService', 'RbUsageTypeGroupFactory'];

    function CompleteInputController($scope, $rootScope, $stateParams, $state, WithdrawalService, Principal, $sessionStorage, $filter, usageTypeGroupFactory, ValidationDataService, ValidationFunctionsService, SharedControllerFnService, POMIntegrationService, LCCIntegrationService, BillingModalService, RbUsageTypeGroupFactory) {
        $scope.elementsWithError = [];
        $scope.validationDataService = ValidationDataService;
        $scope.POMIntegrationService = POMIntegrationService;
        $scope.LCCIntegrationService = LCCIntegrationService;
        $scope.BillingModalService = BillingModalService;

        ValidationDataService.deregisterValidationFunctions();
        ValidationDataService.setZindex(1048);
        ValidationDataService.registerValidationFunctions([
            ValidationFunctionsService.validateBuildingName,
            ValidationFunctionsService.validateZipCode,
            ValidationFunctionsService.validateMainUsage,
            ValidationFunctionsService.validateRatioVolumeUnderTerrain,
            ValidationFunctionsService.validateAreaBuilding416,
            ValidationFunctionsService.validateUsages,
            ValidationFunctionsService.validateBuildingsInConstruction,
            ValidationFunctionsService.validateArea,
            ValidationFunctionsService.validateVolume,
            ValidationFunctionsService.validateBuiltUpArea,
            ValidationFunctionsService.validateLeMinergie,
            ValidationFunctionsService.validateOvergroundFloors,
            ValidationFunctionsService.validateUndergroundFloors,
            ValidationFunctionsService.validateRemark,
            ValidationFunctionsService.validateSumOfFloors,
            ValidationFunctionsService.validateFacadeType,
            ValidationFunctionsService.validateUndergroundParkplace,
            ValidationFunctionsService.validateElevators,
            ValidationFunctionsService.validateRoofTypes
        ]);

        if ($scope.realEstateContainer.metaData.withdrawalProductType === 'BUILDING_INSURANCE_VALUES') {
            ValidationDataService.registerValidationFunction(ValidationFunctionsService.validateYearOfConstruction);
            ValidationDataService.registerValidationFunction(ValidationFunctionsService.validateInvestments);
        }

        $scope.$on("changeUsages", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.$on("changeInvestments", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        POMIntegrationService.init($scope.realEstateContainer);
        LCCIntegrationService.init($scope.realEstateContainer);
        $scope.$watch('realEstateContainer.usages[0].type', function (newValue, oldValue) {
            POMIntegrationService.checkUsageChange(newValue, $scope.realEstateContainer);
        });
        $scope.$watch('realEstateContainer.usages[0]', function (newValue, oldValue) {
            $scope.realEstateContainer.lcc.rbMainUsage = RbUsageTypeGroupFactory.getRbUsage(newValue);
        }, true);
        // if POM not supported also lcc not supported!
        $scope.$watch('POMIntegrationService.isPomEnabled', function (newValue, oldValue) {
            if (newValue === false) {
                LCCIntegrationService.lccEnabledInBuildingCosts = false;
            }
        });

        $scope.validateAndProceed = function (onSuccessFn) {
            ValidationDataService.validateAndProceed($scope.realEstateContainer, onSuccessFn);
        };
    }
})();



