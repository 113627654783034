(function () {
    'use strict';

    angular.module('kennwerteApp')
    .factory('RealestateReferenceService', RealestateReferenceService)
    .factory('RealestatesReferenceUserAssignmentService', RealestatesReferenceUserAssignmentService);

    RealestateReferenceService.$inject = ['$resource'];

    function RealestateReferenceService($resource) {
        return $resource('api/realestates-reference/:id', {id: '@_id'}, {
            'query': {
                method: 'GET', isArray: true
            },
            'update': {
                method: 'PUT' // this method issues a PUT request
            },
            'access': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getEmpty': {
                method: 'GET',
                url: 'api/realestates-reference/empty'
            },
            'create': {
                method: 'POST'
            },
            'sync': {
                method: 'GET',
                url: 'api/realestates-reference/sync'
            },
            'spawnRealEstateEvent': {
                method: 'POST',
                url: 'api/realestates-reference/realestate-event'
            }
        });
    }

    RealestatesReferenceUserAssignmentService.$inject = ['$http'];

    function RealestatesReferenceUserAssignmentService($http) {
        return {
            'assign': function (data, responseFunction, errorFunction) {
                return $http.put('api/realestates-reference/user-assign', data)
                .then(function (response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                });
            }
        };
    }

})();
