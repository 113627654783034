(function () {
    'use strict';

    angular.module('kennwerteApp')
    .directive('imageGallery', ImageGalleryDirective);

    ImageGalleryDirective.$inject = ['$rootScope'];

    function ImageGalleryDirective($rootScope) {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: {
                userLogin: '=?',
                referenceId: '=?'
            },
            templateUrl: 'app/components/image_gallery/image-gallery.html',
            controller: ImageGalleryController,
            controllerAs: 'vm',
            link: function (scope, elem, attrs, controller, transclude) {

            }
        };
    }

    ImageGalleryController.$inject = ['$scope', '$window', 'imageService', 'ImageModalService', '$timeout'];

    function ImageGalleryController($scope, $window, imageService, ImageModalService, $timeout) {
        var vm = this;

        // view model variables
        vm.widthStyle = null;
        vm.hasInitialized = false;
        vm.slides = $scope.slides = [];
        vm.dataLoaded = false;
        $scope.myInterval = 5000;
        $scope.noWrapSlides = false;
        $scope.active = 0;

        // local ctrl variables
        var currIndex = 0;
        var marginPerImage = 3.66; // in px
        var maxWidth = 1585; //real estates-reference table width from 3rd column to last
        var fallBackHeight = 222;

        vm.slideStyle = {
            "display": "none;",
            "visibility": "hidden;"
        };


        // view model functions
        vm.showCarousel = function(index, slide) {
            return ImageModalService.open(index, vm.slides, slide, $scope.referenceId);
        };


        vm.onImageLoadError = onImageLoadError;


        function onImageLoadError(slide) {
            // slide.image = null;
            // slide.image = "assets/images/noImg.png";
            // on-error-src="'assets/images/real-estate-reference/noImage.jpg'"
            slide.imagelg = "assets/images/real-estate-reference/noImage.jpg";
            slide.height = 800;
            slide.width = 800;
            slide.imageError = true;
            //TODO load white Image

        };


        getImageForRefObject($scope.referenceId);

        function initCarousel() {
            vm.slickConfig2 = {
                enabled: true,
                autoplay: false,
                infinite: true,
                dots: false,
                prevArrow: "<a role=\"button\" style=\"position: relative;top:50%;\" href class=\"left\">\n" +
                    '        <span aria-hidden="true" style="position: inherit;"\n' +
                    '              class="glyphicon glyphicon-chevron-left kennwertered"></span>\n' +
                    '        <span class="sr-only">previous</span>\n' +
                    '    </a>',
                nextArrow: '<a role="button" style="position: relative;bottom:50%;left:15px;" href class="right">\n' +
                    '        <span aria-hidden="true" style="position: inherit;"\n' +
                    '              class="glyphicon glyphicon-chevron-right kennwertered"></span>\n' +
                    '        <span class="sr-only">next</span>\n' +
                    '    </a>',
                speed: 0,
                //slidesToShow: 4,
                slidesToScroll: 1,
                initialSlide: 0,
                lazyLoad: 'progressive',
                centerMode: false,
                variableWidth: true,
                draggable: false,
                useCSS: false,
                useTransform: false,
                method: {},
                event: {
                    // lazyLoaded: function (e, slick, img, src) {
                    // },
                    // afterChange: function (event, slick, currentSlide, nextSlide) {
                    // },
                    init: function (event, slick) {
                        slick.slickGoTo(0);
                        $timeout(function() {
                            vm.hasInitialized = true;
                        });
                        vm.SlideStyle = {
                            'display': 'block',
                            'visibility': 'visible'
                        };
                        vm.slickConfig2.speed = 150;
                    }
                }

            };
            vm.slickConfig2Loaded = true;
        }

        function getImageForRefObject(refObjId) {
            imageService.getAppendedImageIdsByRealEstateId(refObjId,
                function (response) {
                    var d = response.data;

                    var imgUrls = d.map(function (a) {
                        // return $scope.userLogin + '/' + a.id + '.' + a.type;
                        return $scope.userLogin + '/' + a.id + '.jpg';
                    });

                    var imageWidths = marginPerImage;
                    var numElements = 0;

                    for (var i in d) {
                        i = Number(i);
                        var scaled = scaleWidthToHeight222Px(d[i].width, d[i].height);
                        if (!scaled) {
                            // fallback to width 222
                            scaled = fallBackHeight;
                        }
                        vm.slides.push({
                            // image: imageLocations[i],
                            image: "image/gallery/" + imgUrls[i],
                            imageError: false,
                            imagelg: "image/hd/" + imgUrls[i],
                            text: d[i].name,
                            id: currIndex++,
                            width: d[i].width,
                            height: d[i].height,
                            scaledWidth: scaled,
                            type: d[i].type,
                            uuid: d[i].id,
                            user: $scope.userLogin
                        });
                        imageWidths += scaled;
                        numElements += 1;
                        imageWidths += marginPerImage * 2 * (numElements - 1);
                        imageWidths -= 7.5; // margin-left of #gallery_slick_container
                        vm.widthStyle = {
                            "width": Math.min(imageWidths, maxWidth) + "px"
                        };
                    }
                    vm.dataLoaded = true;
                    initCarousel();

                },
                function (errorResponse) {
                    // ignore error
                    console.warn(errorResponse);
                });
        }


        function scaleWidthToHeight222Px(width, height) {
            return width * fallBackHeight / height;
        }

    }

})();
