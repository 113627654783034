(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .directive('agvBuildStyleSelector', AgvBuildStyleSelectorDirective);

    AgvBuildStyleSelectorCtlr.$inject = ['$scope', '$rootScope', '$timeout', 'SmallBuildingService'];

    function AgvBuildStyleSelectorCtlr($scope, $rootScope, $timeout, SmallBuildingService) {

        $scope.firstLoad = true;

        var vm = this;

        vm.model = {};
        vm.answers = ['SOFT', 'MASSIVE'];
        // use polyfills for IE, TODO: Introduce a proper place for polyfills

        vm.isEmptyModel = function() {
            return angular.equals({}, vm.model);
        };

        vm.translateKey = function(answer) {
            return $scope.translateBaseKey + '.' + answer + '.label';
        };

        vm.onOpenClose = function(isOpen) {
            if (isOpen) {
                $rootScope.$broadcast('updateDescription', {
                    rowid: $scope.rowid,
                    descriptionBaseKey: $scope.descriptionBaseKey
                });
                $rootScope.$broadcast('updateImage', {
                    rowid: $scope.rowid,
                    imageSrc: $scope.image
                });
            }
        };

        $scope.$watch('vm.model', function(selectedItem) {
            if (!$scope.firstLoad && !angular.equals({}, selectedItem)) {
                $rootScope.$broadcast('updateImage', {
                    rowid: $scope.rowid,
                    imageSrc: $scope.image
                });

                $rootScope.$broadcast('updateDescription', {
                    rowid: $scope.rowid,
                    descriptionNumber: selectedItem,
                    descriptionBaseKey: $scope.descriptionBaseKey + '.' + selectedItem
                });
                $scope.reference = selectedItem;

                if ($scope.inputBlurFun != null) {
                    $scope.inputBlurFun();
                }
                $timeout(function() {
                    // we need timeout because model might not be updated yet (in on-broadcast-receiver-function)
                    $rootScope.$broadcast('getValuePreset');
                })
            } else {
                $scope.firstLoad = false;
            }
        });

        function initSelectInput() {
            if ($scope.reference != null) {
                if (vm.answers.includes($scope.reference)) {
                    vm.model = vm.answers[vm.answers.indexOf($scope.reference)];
                }
            }
        }

        initSelectInput();

        /**
         * calling the return of the watch will destroy it. (we are just making sure the value is set)
         * @type {(function())|angular.noop}
         * @private
         */
        // var _destroyReferenceWatch = $scope.$watch('reference', function (newVal,oldVal) {
        //     console.error($scope.reference,newVal,oldVal,vm.model)
        //    if($scope.reference != null){
        //        initSelectInput();
        //        // _destroyReferenceWatch();
        //    }
        // });
        //
    }

    AgvBuildStyleSelectorDirective.$inject = ['$rootScope'];

    function AgvBuildStyleSelectorDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                rowid: '=', // used to set the id of the row-image
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                inputBlurFun: '&?'

            },
            controller: AgvBuildStyleSelectorCtlr,
            templateUrl: 'app/small_building_agv_insurance_form/10_small_building_agv_insurance_input/30_small_building_agv_insurance_quality_form/agv_build_style_selector/agv-build-style-selector.tpl.html',
            controllerAs: 'vm',
            link: function(scope, tElement, tAttrs) {
                // Used for the validation
                var toggle = tElement.find('.selectInput');
                $(toggle).attr('id', tAttrs.reference + '.drop2down-toggle');
            }
        };
    }

})();
