(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('JhiHealthService', JhiHealthService);

    JhiHealthService.$inject = ['$rootScope', '$http', 'FileSaver'];

    function JhiHealthService($rootScope, $http, FileSaver) {
        var separator = '.';
        var service = {
            checkHealth: checkHealth,
            transformHealthData: transformHealthData,
            getBaseName: getBaseName,
            getSubSystemName: getSubSystemName,
            transferData: transferData,
            adminRealEstate: adminRealEstate,
            downloadBa: downloadBa,
            downloadBaWpt: downloadBaWpt,
            downloadPayments: downloadPayments,
            downloadValidLicenses: downloadValidLicenses,
            downloadCustomerRealestates: downloadCustomerRealestates
        };

        return service;

        function checkHealth() {
            return $http.get('management/health').then(function (response) {
                return response.data;
            });
        }

        /**
         * Syncs data between s3 and db
         * @returns {*}
         */
        function transferData() {
            return $http.get('api/admin/transfer_db_s3').then(function(response) {
                return response.data;
            }, function(response) {
                console.error('Error');
                return response.data;
            });
        }

        /**
         * Gets a real estate for admin, does not have to belong to admin
         * @param id
         * @returns {*}
         */
        function adminRealEstate(id) {
            return $http.get('api/admin/real_estate/' + id).then(function(response) {
                return response.data;
            });
        }

        function transformHealthData(data) {
            var response = [];
            flattenHealthData(response, null, data);
            return response;
        }

        function getBaseName(name) {
            if (name) {
                var split = name.split('.');
                return split[0];
            }
        }

        function getSubSystemName(name) {
            if (name) {
                var split = name.split('.');
                split.splice(0, 1);
                var remainder = split.join('.');
                return remainder ? ' - ' + remainder : '';
            }
        }

        /* private methods */
        function flattenHealthData(result, path, data) {
            angular.forEach(data, function (value, key) {
                if (isHealthObject(value)) {
                    if (hasSubSystem(value)) {
                        addHealthObject(result, false, value, getModuleName(path, key));
                        flattenHealthData(result, getModuleName(path, key), value);
                    } else {
                        addHealthObject(result, true, value, getModuleName(path, key));
                    }
                }
            });
            return result;
        }

        function addHealthObject(result, isLeaf, healthObject, name) {

            var healthData = {
                'name': name
            };
            var details = {};
            var hasDetails = false;

            angular.forEach(healthObject, function (value, key) {
                if (key === 'status' || key === 'error') {
                    healthData[key] = value;
                } else {
                    if (!isHealthObject(value)) {
                        details[key] = value;
                        hasDetails = true;
                    }
                }
            });

            // Add the of the details
            if (hasDetails) {
                angular.extend(healthData, {'details': details});
            }

            // Only add nodes if they provide additional information
            if (isLeaf || hasDetails || healthData.error) {
                result.push(healthData);
            }
            return healthData;
        }

        function getModuleName(path, name) {
            var result;
            if (path && name) {
                result = path + separator + name;
            } else if (path) {
                result = path;
            } else if (name) {
                result = name;
            } else {
                result = '';
            }
            return result;
        }

        function hasSubSystem(healthObject) {
            var result = false;
            angular.forEach(healthObject, function (value) {
                if (value && value.status) {
                    result = true;
                }
            });
            return result;
        }

        function isHealthObject(healthObject) {
            var result = false;
            angular.forEach(healthObject, function (value, key) {
                if (key === 'status') {
                    result = true;
                }
            });
            return result;
        }

        function downloadBa() {
            return $http.get('api/ba/download', {responseType: 'blob'})
                .then(function (response) {
                    var data = new Blob([response.data], {type: 'application/zip'});
                    var filename = 'ba.zip';
                    FileSaver.saveAs(data, filename);
                });
        }

        function downloadPayments(wpts, dateTimeFrom, dateTimeTo) {
            var prefixedWpts = wpts
                .map(function (wpt) {
                    return "wpt=" + wpt;
                });
            var wptString = prefixedWpts.join("&");
            if (!!wptString) {
                wptString = '&' + wptString
            }
            return $http.get('api/ba_payments/download?dateTimeFrom=' + dateTimeFrom + '&dateTimeTo=' + dateTimeTo + wptString,
                {responseType: 'blob'})
                .then(function(response) {
                    var data = new Blob([response.data], {type: 'application/zip'});
                    var filename = 'payments_' + wptString + '.zip';
                    FileSaver.saveAs(data, filename);
                });
        }

        function downloadValidLicenses(dateTimeDeadline) {
            return $http.get('api/ba_valid_licenses/download?dateTimeDeadline=' + dateTimeDeadline,
                {responseType: 'blob'})
                .then(function(response) {
                    var data = new Blob([response.data], {type: 'application/zip'});
                    var filename = 'valid_licenses_' + dateTimeDeadline + '.xlsx'
                    FileSaver.saveAs(data, filename);
                });
        }

        /**
         * Download business analytics for the passed withdrawal products, stored in the array wpts
         * @param wpts array of withdrawal products
         * @returns {*}
         */
        function downloadBaWpt(wpts) {
            var prefixedWpts = wpts
                .map(function (wpt) {
                    return "wpt=" + wpt;
                });
            var wptString = prefixedWpts.join("&");
            return $http.get('api/ba_wpt/download?' + wptString, {responseType: 'blob'})
                .then(function (response) {
                    var data = new Blob([response.data], {type: 'application/zip'});
                    var filename = 'ba_' + wptString + '.zip';
                    FileSaver.saveAs(data, filename);
                });
        }

        function downloadCustomerRealestates() {
            return $http.get('api/ba/customer_realestates/download', {responseType: 'blob'})
                .then(function (response) {
                    var data = new Blob([response.data], {type: 'text/csv'});
                    var filename = 'customer_realestates.csv';
                    FileSaver.saveAs(data, filename);
                });
        }

    }
})();
