(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('LoginRequiredController', LoginRequiredController);

    LoginRequiredController.$inject = ['$scope', '$state', 'Auth'];

    function LoginRequiredController($scope, $state, Auth) {
        $scope.login = {username: null, password: null};

        $scope.resendSuccess = null;

        $scope.login.login = function (event) {
            event.preventDefault();
            Auth.login({
                username: $scope.login.username,
                password: $scope.login.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params, {reload: true});
                }else{
                    $state.go('products', null, {reload: true});
                }
            }).catch(function (err) {
                $scope.authenticationError = true;
                if (err.data.AuthenticationException.match("was not activated$")) {
                    $scope.notActivatedError = true;
                }
            });
        };

        $scope.resendActivation = function() {
            Auth.resendActivation({mail: $scope.login.username})
                .then(function () {
                    $scope.resendSuccess = 'OK';
                }).catch(function () {
                    $scope.resendSuccess = null;
                });
        };
    }

})();


