(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RealestatesDeleteController', RealestatesDeleteController);

    RealestatesDeleteController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'RealestateService'];

    function RealestatesDeleteController($scope, $stateParams, $uibModalInstance, entity, RealestateService) {
        $scope.realEstateContainer = entity;

        var onDeleteSuccess = function (result) {
            $scope.isDeleting = false;
            $uibModalInstance.close(result);
        };

        var onDeleteError = function (result) {
            $scope.isDeleting = false;
            console.error("Failed to delete " + result);
        };

        $scope.delete = function () {
            $scope.isDeleting = true;
            RealestateService.api.delete({id: $scope.realEstateContainer.id}, onDeleteSuccess, onDeleteError);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
