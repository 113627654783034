(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('ReferenceGeneralFormController', ReferenceGeneralFormController);

    ReferenceGeneralFormController.$inject = ['$scope',
        '$rootScope',
        '$stateParams',
        '$window',
        '$timeout',
        'RealEstateReferenceDataService',
        'UsageSliderMappingService',
        'SliderIdentMappingService',
        'SliderPresetService',
        'RoomHeightLimitsWarningService'
    ];

    function ReferenceGeneralFormController($scope, $rootScope, $stateParams, $window, $timeout, RealEstateReferenceDataService,
                                            UsageSliderMappingService, SliderIdentMappingService, SliderPresetService, RoomHeightLimitsWarningService) {
        $scope.data = $stateParams.data;
        $scope.usageSliderMapping = UsageSliderMappingService.getMapping();

        function setCorrectSliderModelValues(usages) {
            var correctUsages = SliderPresetService.correctUsages(usages);
            var weightedArr = SliderPresetService.getWeightedPresetValues(correctUsages, $scope.realEstateContainer);
            if (angular.isDefined(weightedArr)) {
                var weightedIntervalArr = SliderPresetService.getWeightedPresetIntervals(correctUsages);
                SliderPresetService.setSliderModelValuesArr(weightedArr, weightedIntervalArr, $scope.realEstateContainer, $scope.isPresetSliderModelValuesEnabled);
            }
        }

        $scope.$watch('realEstateContainer.usages', function (usages) {
            RoomHeightLimitsWarningService.showWarningIfOutOfLimit($scope.realEstateContainer);
            // temp disabled, as there is no usage type for all usages defined
            // setCorrectSliderModelValues(usages);
        }, true);

        $scope.$on('requestPresetMark', function () {
            setCorrectSliderModelValues($scope.realEstateContainer.usages);
        });


    }

})();



