(function () {
    'use strict';
    angular.module('kennwerteApp')
        .controller('DamageFormController', DamageFormController);

    DamageFormController.$inject = ['$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$http',
        '$location',
        '$timeout',
        'Principal',
        'RealestateService',
        '$window',
        '$q',
        '$sessionStorage',
        'RebuildEstateDataService',
        'UsageSliderMappingService',
        'SliderIdentMappingService',
        'SliderPresetService',
        'RoomHeightLimitsWarningService',
        'ValidationDataService',
        'RebuildTemplateService',
        'RebuildModernizationPresetService',
        'RebuildInputMatrixService',
        'RebuildActionService',
        'RebuildGeometryService'
    ];

    function DamageFormController($rootScope,
                                   $scope,
                                   $state,
                                   $stateParams,
                                   $http,
                                   $location,
                                   $timeout,
                                   Principal,
                                   RealestateService,
                                   $window,
                                   $q,
                                   $sessionStorage,
                                   RebuildEstateDataService,
                                   UsageSliderMappingService,
                                   SliderIdentMappingService,
                                   SliderPresetService,
                                   RoomHeightLimitsWarningService,
                                   ValidationDataService,
                                   RebuildTemplateService,
                                   RebuildModernizationPresetService,
                                   RebuildInputMatrixService,
                                   RebuildActionService,
                                   RebuildGeometryService) {

        $scope.validationDataService = ValidationDataService;
        $scope.rebuildTemplateService = RebuildTemplateService;
        $scope.RebuildGeometryService = RebuildGeometryService;

        $scope.dataHasLoaded = false;
        $scope.formTabGroup = "0";

        $scope.isPresetSliderModelValuesEnabled = isCurrentStateInput() && !$stateParams.realEstate && !$stateParams.realEstateId;

        function isCurrentStateInput() {
            return $state.includes('damage-form.input');
        }

        function setCorrectSliderModelValues(usages) {
            var correctUsages = SliderPresetService.correctUsages(usages);
            var weightedArr = SliderPresetService.getWeightedPresetValues(correctUsages, $scope.realEstateContainer);
            if (angular.isDefined(weightedArr)) {
                var weightedIntervalArr = SliderPresetService.getWeightedPresetIntervals(correctUsages);
                SliderPresetService.setSliderModelValuesArr(weightedArr, weightedIntervalArr, $scope.realEstateContainer, $scope.isPresetSliderModelValuesEnabled);
            }
        }

        $scope.$watch('realEstateContainer.templateContainer.groundwork', function(newVal,oldVal) {
            if (newVal === oldVal) return;
            if ($scope.realEstateContainer.templateContainer.groundwork.indexOf('COMPLETE_RENOVATION') > -1 || $scope.realEstateContainer.templateContainer.groundwork.indexOf('UNKNOWN') > -1) {
                $scope.realEstateContainer.templateContainer.complete = ['ALL'];
            } else {
                $scope.realEstateContainer.templateContainer.complete = [];
            }
            if ($scope.realEstateContainer.templateContainer.groundwork.indexOf('EXTERNAL_RENOVATION') > -1) {
            } else {
                $scope.realEstateContainer.templateContainer.exterior = [];
            }
            if ($scope.realEstateContainer.templateContainer.groundwork.indexOf('INTERIOR_RENOVATION') > -1) {
            } else {
                $scope.realEstateContainer.templateContainer.interior = [];
            }

            if ($scope.realEstateContainer.templateContainer.groundwork.length === 0) {
                for (var componentKey in $scope.realEstateContainer.analysis.components) {
                    var component = $scope.realEstateContainer.analysis.components[componentKey];
                    component.active = false;
                }
                RebuildInputMatrixService.displayDefaultFields();

                // _.forEach(RebuildInputMatrixService.inputFields, function(value,key){
                //     value = false;
                // })
            }
        }, true);

        $scope.$watch('[' + [
            'realEstateContainer.templateContainer',//0
            'realEstateContainer.quality.ventilationType',//1
            'realEstateContainer.quality.chillerType',//2
            'realEstateContainer.geometry.elevatorInside',//3
            'realEstateContainer.geometry.elevatorOutside',//4
            'realEstateContainer.quality.facadeType',//5
            'realEstateContainer.quality.roofTypes',//6
            'realEstateContainer.quality.heatingTypes',//7
            'realEstateContainer.geometry.builtUpArea',//8
            'realEstateContainer.targetOverhaul.ventilationType',//9
            'realEstateContainer.targetOverhaul.chillerType',//10
            'realEstateContainer.targetOverhaul.elevatorInside',//11
            'realEstateContainer.targetOverhaul.elevatorOutside',//12
            'realEstateContainer.targetOverhaul.facadeType',//13
            'realEstateContainer.targetOverhaul.roofTypes',//14
            'realEstateContainer.targetOverhaul.heatingTypes',//15
            'realEstateContainer.targetOverhaul.builtUpArea',//16
            'realEstateContainer.usages',//17
            'realEstateContainer.targetOverhaul.usages',//18
            'RebuildGeometryService.isAdditionStory',//19
            'RebuildGeometryService.isAnnex'//20
        ].join(',') + ']', function(newVal, _) {
            var components = RebuildTemplateService.getComponents(
                newVal[0], newVal[20]
            );

            // handle special case chiller
            var temp = RebuildTemplateService.handleSpecialCaseChiller(components, newVal[17], newVal[18]);
            components = temp.components;

            // update input fields based on all components
            RebuildInputMatrixService.updateInputFields(
                components,
                temp.hasUsageChiller,
                newVal[19],
                newVal[20],
                false // No pom in damage
            );

            var activeComponentsInRealEstate = RebuildTemplateService.getActiveComponents(
                components,
                newVal[1], newVal[2], newVal[3], newVal[4], newVal[5], newVal[6], newVal[7], newVal[8]  // realEstate Params
            );

            var activeComponentsInTargetOverhaul = RebuildTemplateService.getActiveComponents(
                components,
                newVal[9], newVal[10], newVal[11], newVal[12], newVal[13], newVal[14], newVal[15], newVal[16]  // targetOverhaul Params
            );

            var nonChangingComponentsInRealEstate = RebuildTemplateService.getNonChangingComponents(
                components,
                newVal[1], newVal[2], newVal[3], newVal[4], newVal[5], newVal[6], newVal[7], newVal[8], // realEstate Params
                newVal[9], newVal[10], newVal[11], newVal[12], newVal[13], newVal[14], newVal[15], newVal[16]   // targetOverhaul Params
            );

            // update components list (to show only filtered components)
            try {
                if ($scope.realEstateContainer != null) {
                    for (var componentKey in $scope.realEstateContainer.analysis.components) {
                        var component = $scope.realEstateContainer.analysis.components[componentKey];
                        component.activeInTargetOverhaul = activeComponentsInTargetOverhaul.includes(component.rebuildEstateComponent);
                        component.activeInRealEstate = activeComponentsInRealEstate.includes(component.rebuildEstateComponent);
                        component.active = component.activeInTargetOverhaul || component.activeInRealEstate;
                        component.changing = !nonChangingComponentsInRealEstate.includes(component.rebuildEstateComponent);
                    }
                }
            } catch (error) {
                console.warn('component update error: ', error);
            }
        }, true);

        // TODO watch all fields that effect components and set $scope.realEstateContainer.components based on getComponents.

        $scope.$watch('realEstateContainer.targetOverhaul.usages', function (usages, oldUsages) {
            setCorrectSliderModelValues(usages);
            RoomHeightLimitsWarningService.showWarningIfOutOfLimit($scope.realEstateContainer);
        }, true);

        $scope.$on('requestPresetMark', function () {
            setCorrectSliderModelValues($scope.realEstateContainer.targetOverhaul.usages);
        });

        $scope.showDiv = function (divId) {
            return $state.current.data.showDivs.indexOf(divId) > -1;
        };

        $scope.isCurrentStateEditRecord = function () {
            return $state.includes('damage-form.edit-record');
        };

        // now all scope functions are prepared, initialize data
        if ($scope.isCurrentStateEditRecord() && !!$sessionStorage.output && $sessionStorage.output.realEstateId === $sessionStorage.realEstateContainer.id) {
            $scope.validationDataService.setIsSuccess(true);
        }
        if (isCurrentStateInput()) {
            if ($stateParams.realEstate == null || _.isEmpty($stateParams.realEstate)) {
                RebuildEstateDataService.newDamage().then(
                    function(rebuildEstate) {
                        console.info("RebuildFormController new", RebuildEstateDataService.rebuildEstate);
                        $scope.realEstateContainer = RebuildEstateDataService.rebuildEstate;
                        SliderPresetService.setDefaultSliderModelValues($scope.realEstateContainer);
                        RebuildModernizationPresetService.setDefaultValues($scope.realEstateContainer);
                        RebuildEstateDataService.saveToSession();
                        RebuildActionService.realEstateLoaded(RebuildEstateDataService.rebuildEstate);
                        $scope.dataHasLoaded = true;
                    }, function() {
                        console.error("Failed to load real estate");
                    });
            } else {
                $timeout(function() {
                    console.info('Admin special load init',RebuildEstateDataService.rebuildEstate);
                    $scope.realEstateContainer = RebuildEstateDataService.rebuildEstate;
                    setCorrectSliderModelValues($scope.realEstateContainer.targetOverhaul.usages);
                    RebuildEstateDataService.saveToSession();
                    RebuildActionService.realEstateLoaded(RebuildEstateDataService.rebuildEstate);
                    $scope.dataHasLoaded = true;
                }, 7100);
            }
        } else {
            if ($stateParams.realEstateId) {
                RebuildEstateDataService.load($stateParams.realEstateId).then(
                    function (rebuildEstate) {
                        console.info("RebuildFormController loaded", RebuildEstateDataService.rebuildEstate);
                        $scope.realEstateContainer = RebuildEstateDataService.rebuildEstate;
                        $scope.realEstateContainer = RebuildEstateDataService.postProcessForEditRecordById($scope.realEstateContainer);
                        setCorrectSliderModelValues($scope.realEstateContainer.targetOverhaul.usages);
                        RebuildEstateDataService.saveToSession();
                        RebuildActionService.realEstateLoaded(RebuildEstateDataService.rebuildEstate);
                        $scope.dataHasLoaded = true;
                    }, function () {
                    console.error("Failed to load real estate");
                });
            } else {
                RebuildEstateDataService.loadFromSession();
                console.info("RebuildFormController loaded from session", RebuildEstateDataService.rebuildEstate);
                $scope.realEstateContainer = RebuildEstateDataService.rebuildEstate;
                $scope.realEstateContainer = RebuildEstateDataService.postProcessForEditRecordById($scope.realEstateContainer);
                $timeout(function() {
                    // timeout is ugly, but necessary, because sliders might not be preesent yet
                    setCorrectSliderModelValues($scope.realEstateContainer.targetOverhaul.usages);
                }, 3100);
                RebuildActionService.realEstateLoaded(RebuildEstateDataService.rebuildEstate);
                $scope.dataHasLoaded = true;
            }
        }
    }

})();
