(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('functionalUnitInput', {
            bindings: {
                reference: '=?',
                rowid: '=',
                // this key contains also the information if it is main or secondary usage (for even more
                //detailed information display in the future.
                translateKey: '@',
                image: '=image',
                mandatory: '<?',
                required: '<?',
                //fuKey is needed to create the correct string for the placeholder
                // e.g. Anzahl xyz etc.
                fuKey: '@?',
                index: '<?',
                isDisabled: '=?',
                validationMessage: '@',
                descriptionBaseKey: '@',
                usage: '<',
                infotext: '@?'

            },
            templateUrl: 'app/components/functional_unit_input/functional-unit-input.tpl.html',
            controller: FunctionUnitController
        });

    FunctionUnitController.$inject = ['usageTypeGroupFactory', '$scope', '$rootScope'];

    function FunctionUnitController(usageTypeGroupFactory, $scope, $rootScope) {
        var $ctrl = this;

        $ctrl.updateDescriptionAndImage = function () {
            $rootScope.$broadcast("updateImage", {
                rowid: $ctrl.rowid,
                imageSrc: $ctrl.image
            });

            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionBaseKey: $ctrl.descriptionBaseKey
            });

        };

        $scope.$watch('$ctrl.fuKey', function (selectedItem) {
            if($ctrl.isDisabled){
                console.log($ctrl.reference);
                $ctrl.reference = null;
                console.log($ctrl.reference);
            }
        });

        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateKey.split('.').join('_');
        };
    }

})();
