(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('OrganisationManagementController', OrganisationManagementController);

    OrganisationManagementController.$inject = ['Organisation', 'pagingParams', 'paginationConstants',
        'ParseLinks', 'PaginationUtil', 'AlertService', '$scope', '$translate'];

    function OrganisationManagementController(Organisation, pagingParams, paginationConstants,
                                              ParseLinks, PaginationUtil, AlertService, $scope, $translate) {
        var vm = this;
        vm.Index = 1;
        vm.authorities = ['ROLE_ADMIN'];
        vm.loadAll = loadAll;
        vm.loadNumOrganisations = loadNumOrganisations;
        vm.loadPage1 = loadPage1;
        vm.loadPage = loadPage;
        vm.onFocusOutSearchName = onFocusOutSearchName;
        vm.onFocusOutSearchPlace = onFocusOutSearchPlace;
        vm.onFocusOutSearchInvoice = onFocusOutSearchInvoice;
        vm.refresh = refresh;

        vm.orgs = [];
        vm.dataHasLoaded = false;

        vm.page = pagingParams.page;
        vm.searchName = pagingParams.searchName;
        vm.searchPlace = pagingParams.searchPlace;
        vm.searchInvoice = pagingParams.searchInvoice;
        vm.withdrawalProductType = pagingParams.withdrawalProductType;
        vm.licenseState = pagingParams.licenseState;
        vm.filterOrg = pagingParams.filterOrg;
        vm.filterLicenseState = pagingParams.filterLicenseState;

        vm.totalItems = pagingParams.totalItems;
        vm.links = null;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.translateLicenseStatus = translateLicenseStatus;
        vm.translateInvoiceStatus = translateInvoiceStatus;

        vm.previousSearchName = "";
        vm.previousSearchPlace = "";
        vm.previousSearchInvoice = "";

        vm.previousIsSearchRequest = null;
        vm.previousPage = 0;
        vm.previousPredicate = null;
        vm.previousReverse = null;

        vm.numOrganisations = null;

        function translateLicenseStatus(status) {
            try {
                return $translate.instant('billing.licenseStatus.' + status + '.label');
            } catch (e) {
                console.warn('translate error transactionStatus', e);
            }
        }

        function translateInvoiceStatus(status) {
            try {
                return $translate.instant('billing.history.invoiceStatus.' + status);
            } catch (e) {
                console.warn('translate error invoiceStatus', e);
            }
        }

        function refresh() {
            loadPage1();
        }

        function loadPage1() {
            vm.page = 1;
            loadAll();
        }

        function loadAll() {
            return loadAllInner(false);
        }

        function loadNumOrganisations() {
            Organisation.numOrganisations().$promise.then(function(ret) {
                vm.numOrganisations = ret['cnt'];
            });
        }

        /**
         * Create the sort statement which is expected to be passed as state param to states inheriting from state 'realestates'.
         */
        function createSortStmt() {
            return vm.predicate + ';' + (vm.reverse ? 'asc' : 'desc');
        }

        function loadAllInner(isSearchRequest) {
            if (!angular.equals(vm.previousWithdrawalProductType, vm.withdrawalProductType)
                || !angular.equals(vm.previousSearchName, vm.searchName)
                || !angular.equals(vm.previousSearchPlace, vm.searchPlace)
                || !angular.equals(vm.previousSearchInvoice, vm.searchInvoice)
                || !angular.equals(vm.previousPredicate, vm.predicate)
                || !angular.equals(vm.previousReverse, vm.reverse)
                || !angular.equals(vm.previousPage, vm.page)
                || !angular.equals(vm.previousIsSearchRequest, isSearchRequest)) {
                vm.dataHasLoaded = false;
                vm.previousWithdrawalProductType = vm.withdrawalProductType;
                vm.previousSearchName = vm.searchName;
                vm.previousSearchPlace = vm.searchPlace;
                vm.previousSearchInvoice = vm.searchInvoice;
                vm.previousPredicate = vm.predicate;
                vm.previousPage = vm.page;
                vm.previousIsSearchRequest = isSearchRequest;
                if (vm.predicate) {
                    var tempSort = createSortStmt();
                    vm.predicate = PaginationUtil.parsePredicate(tempSort);
                    vm.reverse = PaginationUtil.parseAscending(tempSort);
                }
                Organisation.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    searchName: vm.searchName,
                    searchPlace: vm.searchPlace,
                    searchInvoice: vm.searchInvoice,
                    isSearchRequest: isSearchRequest,
                    filterOrg: vm.filterOrg,
                    filterLicenseState: vm.filterLicenseState
                }, onSuccess, onError);
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.orgs = data;
            vm.dataHasLoaded = true;
        }

        function onError(error) {
            vm.dataHasLoaded = true;
            AlertService.error(error.data.message);
        }

        function sort() {
            if (vm.predicate) {
                return [createSortStmt()];
            } else {
                return [];
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function onFocusOutSearchName() {
            if (!angular.equals(vm.previousSearchName, vm.searchName)) {
                vm.page = 1;
                loadAllInner(true);
            }
        }

        function onFocusOutSearchPlace() {
            if (!angular.equals(vm.previousSearchPlace, vm.searchPlace)) {
                vm.page = 1;
                loadAllInner(true);
            }
        }

        function onFocusOutSearchInvoice() {
            if (!angular.equals(vm.previousSearchInvoice, vm.searchInvoice)) {
                vm.page = 1;
                loadAllInner(true);
            }
        }

        $scope.$watch('vm.filterOrg', function(oldVal, newVal) {
            if (newVal !== oldVal) {
                onFocusOutFilter();
            }
        });

        $scope.$watch('vm.filterLicenseState', function(newVal, oldVal) {
            var invokeChange = false;
            if (newVal != null && oldVal != null) {
                if (newVal.length !== oldVal.length) {
                    onFocusOutFilter();
                    return;
                }
                invokeChange = _.difference(newVal, oldVal).length > 0;
            }
            if (invokeChange) {
                onFocusOutFilter();
            }
        },true);

        function onFocusOutFilter() {
            vm.page = 1;
            loadAllInner(!!vm.searchName || !!vm.searchPlace || !!vm.searchInvoice);
        }

        // invoke methods on start
        vm.loadAll();
        vm.loadNumOrganisations();

    }

})();
