function isInput(input) {
    var test = input || input === 0;
    //due to some values are set to "NaN" causes issues on the backend -> cant convert to Number.
    if (test === 'NaN') {
        return null;
    }
    return test;
}

function ComponentMeasures() {
    this.name = undefined;

    this.measure = undefined;
    this.measureBackend = undefined;
    this.measureClientOverwrite = undefined;
    this.measureUserInput = undefined;

    this.interventionGrade = undefined;
    this.interventionGradePlaceholder = undefined;
    this.interventionGradeBackend = undefined;
    this.interventionGradeUserInput = undefined;

    this.interventionGradeSigmaBackend = undefined;

    this.interventionGradeQuantity = undefined;
    this.interventionGradeQuantityPlaceholder = undefined;
    this.interventionGradeQuantityBackend = undefined;
    this.interventionGradeQuantityUserInput = undefined;

    this.interval = undefined;
    this.intervalGap = undefined;

}

ComponentMeasures.prototype.updateMeasure = function() {
    var measure = this.measure;
    if (isInput(this.measureBackend))
        measure = this.measureBackend;
    if (isInput(this.measureClientOverwrite))
        measure = this.measureClientOverwrite;
    if (isInput(this.measureUserInput))
        measure = this.measureUserInput;
    var hasChanged = measure !== this.measure;
    this.measure = measure;
    return hasChanged;
};

ComponentMeasures.prototype.setMeasureBackend = function(measure, interval, intervalGap) {
    this.measureBackend = measure;
    this.interval = interval;
    this.intervalGap = intervalGap;
    return this.updateMeasure();
};
/**
 * internal overwrite via javascript logic.
 * @param measure
 * @returns {boolean}
 */
ComponentMeasures.prototype.setMeasureClientOverwrite = function(measure) {
    this.measureClientOverwrite = measure;
    return this.updateMeasure();
};

ComponentMeasures.prototype.setMeasureUserInput = function(measure){
    this.measureUserInput = measure;
    if (measure !== null) {
        this.setInterventionGradeUserInput(null);
    }
    return this.updateMeasure();
};

ComponentMeasures.prototype.updateInterventionGrade = function() {
    var interventionGrade = this.interventionGrade;
    if (isInput(this.interventionGradePlaceholder))
        interventionGrade = this.interventionGradePlaceholder;
    if (isInput(this.interventionGradeBackend))
        interventionGrade = this.interventionGradeBackend;
    if (isInput(this.interventionGradeUserInput))
        interventionGrade = this.interventionGradeUserInput;
    var hasChanged = interventionGrade !== this.interventionGrade;
    this.interventionGrade = interventionGrade;
    return hasChanged;
};

ComponentMeasures.prototype.setInterventionGradeBackend = function(interventionGrade) {
    this.interventionGradeBackend = interventionGrade;
    this.interventionGradePlaceholder = '' + Math.round(interventionGrade);
    return this.updateInterventionGrade();
};

ComponentMeasures.prototype.setInterventionGradeSigmaBackend = function(interventionGradeSigma) {
    this.interventionGradeSigmaBackend = interventionGradeSigma;
};

ComponentMeasures.prototype.setInterventionGradeUserInput = function(interventionGrade) {
    this.interventionGradeUserInput = interventionGrade;
    if (interventionGrade != null) {
        var measure = this.getMeasureValuesBasedOnInterventionGrade(interventionGrade);
        if (measure !== this.measureUserInput) {
            this.setMeasureClientOverwrite(this.getMeasureValuesBasedOnInterventionGrade(interventionGrade));
            this.setMeasureUserInput(null);
        }
    } else {
        this.setMeasureClientOverwrite(null);
    }
    return this.updateInterventionGrade();
};

ComponentMeasures.prototype.updateInterventionGradeQuantity = function() {
    var interventionGradeQ = this.interventionGradeQuantity;
    if (isInput(this.interventionGradeQuantityPlaceholder))
        interventionGradeQ = this.interventionGradeQuantityPlaceholder;
    if (isInput(this.interventionGradeQuantityBackend))
        interventionGradeQ = this.interventionGradeQuantityBackend;
    if (isInput(this.interventionGradeQuantityUserInput))
        interventionGradeQ = this.interventionGradeQuantityUserInput;
    var hasChanged = interventionGradeQ !== this.interventionGradeQuantity;
    this.interventionGradeQuantity = interventionGradeQ;
    return hasChanged;
};

ComponentMeasures.prototype.setInterventionGradeQuantityBackend = function(interventionGradeQuantity) {
    this.interventionGradeQuantityBackend = interventionGradeQuantity;
    //should be better that we on null input we display empty?
    this.interventionGradeQuantityPlaceholder = '' + Math.round(interventionGradeQuantity);
    return this.updateInterventionGradeQuantity();
};

ComponentMeasures.prototype.setInterventionGradeQuantityPlaceholder = function(interventionGradeQuantityPlaceholder) {
    this.interventionGradeQuantityPlaceholder = '' + Math.round(interventionGradeQuantityPlaceholder);
    return this.updateInterventionGradeQuantity();
};

ComponentMeasures.prototype.setInterventionGradeQuantityUserInput = function(interventionGradeQuantity) {
    this.interventionGradeQuantityUserInput = interventionGradeQuantity;
    return this.updateInterventionGradeQuantity();
};

ComponentMeasures.prototype.getMeasureValuesBasedOnInterventionGrade = function(interventionGrade) {
    var measure;
    if (interventionGrade <= 10) measure = "MK1";
    else if (interventionGrade <= 25) measure = "MK2";
    else if (interventionGrade <= 40) measure = "MK3";
    else if (interventionGrade <= 80) measure = "MK4";
    else measure = "MK5";
    return measure;
};

ComponentMeasures.prototype.getIntervalFromMeasure = function(mk) {
    if (mk <= "MK1") return [0, 10];
    else if (mk <= "MK2") return [10, 25];
    else if (mk <= "MK3") return [25, 40];
    else if (mk <= "MK4") return [40, 80];
    return [80, 100];
};

