(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildInputMatrixService', RebuildInputMatrixService);

    RebuildInputMatrixService.$inject = ['RebuildGeometryService'];

    function RebuildInputMatrixService(RebuildGeometryService) {

        var defaultValues = {
            'totalFloorArea416': 3277,
            'totalVolume416': 11727,
            'ratioVolumeUnderTerrain': '0.17399999999999999',
            'builtUpArea': 2231,
            'buildings': 1,
            'overgroundFloors': 2,
            'undergroundFloors': 0,
            'undergroundParkingSpaces': 0,
            'roofTypes': 'FLATROOF',
            'facadeType': 'AUSSENDAEMMUNG_PUTZ_EINFACH',
            'heatingTypes': 'NO_HEATING',
            'ventilationType': 'SUPPLY_EXHAUST_AIR',
            'chiller': 'NO'
        };

        this.inputFieldsMapping = {
            'totalFloorArea416': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'SANITARY_APPARATUS', 'SANITARY_PIPES', 'KITCHEN', 'TRANSPORTATIONSYSTEM', 'INSTALLATION', 'FLOORING'],
            'totalVolume416': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'SANITARY_APPARATUS', 'SANITARY_PIPES', 'KITCHEN', 'TRANSPORTATIONSYSTEM', 'INSTALLATION', 'FLOORING'],
            'ratioVolumeUnderTerrain': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'SANITARY_APPARATUS', 'SANITARY_PIPES', 'KITCHEN', 'TRANSPORTATIONSYSTEM', 'INSTALLATION', 'FLOORING'],
            'builtUpArea': ['ENVIRONMENT'],
            'buildings': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'TRANSPORTATIONSYSTEM'],
            'overgroundFloorsUndergroundFloors': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'SANITARY_APPARATUS', 'SANITARY_PIPES', 'KITCHEN', 'TRANSPORTATIONSYSTEM', 'INSTALLATION', 'FLOORING'],
            'undergroundParkingSpaces': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'SANITARY_APPARATUS', 'SANITARY_PIPES', 'KITCHEN', 'TRANSPORTATIONSYSTEM', 'INSTALLATION', 'FLOORING'],
            'elevatorVertical': ['CONSTRUCTION', 'ELECTRIC_HIGH_CURRENT', 'TRANSPORTATIONSYSTEM'],
            'roofTypes': ['CONSTRUCTION', 'INCLINEDROOF', 'FLATROOF'],
            'facadeType': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL'],
            'heatingTypes': ['ELECTRIC_HIGH_CURRENT', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION'],
            'ventilation': ['ELECTRIC_HIGH_CURRENT', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION'],
            'chiller': ['ELECTRIC_HIGH_CURRENT', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION'],
            'qualitaetGrundstueck': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'SANITARY_APPARATUS', 'SANITARY_PIPES', 'KITCHEN', 'TRANSPORTATIONSYSTEM', 'INSTALLATION', 'FLOORING', 'ENVIRONMENT'],
            'qualitaetvorbereitungsarbeiten': [],
            'qualitaetVorfertigung': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'INSTALLATION', 'FLOORING'],
            'qualitaetDerGebaeudeform2': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'INCLINEDROOF', 'FLATROOF', 'HEAT_GENERATION', 'HEAT_DISTRIBUTION', 'VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION', 'CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION', 'SANITARY_PIPES', 'INSTALLATION', 'FLOORING'],
            'fensterAnteil': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'FLOORING'],
            'qualitaetElektroAnlage': ['ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT'],
            'qualitaetSanitaeranlage': ['SANITARY_APPARATUS', 'SANITARY_PIPES', 'KITCHEN'],
            'qualitaetAusbau1': ['INSTALLATION'],
            'qualitaetAusbau2': ['FLOORING'],
            'qualitaetUmgebung': ['ENVIRONMENT']
        };

        var self = this;

        function fillDefaultValues(realEstateContainer) {
            var nonUserInput = realEstateContainer.nonUserInput;
            var geometry = realEstateContainer.geometry;
            var targetOverhaul = realEstateContainer.targetOverhaul;
            if (!self.inputFields.totalFloorArea416) {
                nonUserInput.totalFloorArea416 = true;
                geometry.totalFloorArea416 = defaultValues['totalFloorArea416'];
                targetOverhaul.totalFloorArea416 = defaultValues['totalFloorArea416'];
            }
            if (!self.inputFields.totalVolume416) {
                nonUserInput.totalVolume416 = true;
                geometry.totalVolume416 = defaultValues['totalVolume416'];
                targetOverhaul.totalVolume416 = defaultValues['totalVolume416'];
            }
            if (!self.inputFields.builtUpArea) {
                nonUserInput.builtUpArea = true;
                geometry.builtUpArea = defaultValues['builtUpArea'];
                targetOverhaul.builtUpArea = defaultValues['builtUpArea'];
            }
            if (!self.inputFields.buildings) {
                nonUserInput.buildings = true;
                geometry.buildings = defaultValues['buildings'];
                targetOverhaul.buildings = defaultValues['buildings'];
            }
            if (!self.inputFields.overgroundFloorsUndergroundFloors) {
                nonUserInput.overgroundFloors = true;
                geometry.overgroundFloors = defaultValues['overgroundFloors'];
                targetOverhaul.overgroundFloors = defaultValues['overgroundFloors'];
            }
            if (!self.inputFields.overgroundFloorsUndergroundFloors) {
                nonUserInput.undergroundFloors = true;
                geometry.undergroundFloors = defaultValues['undergroundFloors'];
                targetOverhaul.undergroundFloors = defaultValues['undergroundFloors'];
            }
            if (!self.inputFields.undergroundParkingSpaces) {
                nonUserInput.undergroundParkingSpaces = true;
                geometry.undergroundParkingSpaces = defaultValues['undergroundParkingSpaces'];
                targetOverhaul.undergroundParkingSpaces = defaultValues['undergroundParkingSpaces'];
            }
            if (!self.inputFields.ratioVolumeUnderTerrain) {
                nonUserInput.ratioVolumeUnderTerrain = true;
                geometry.ratioVolumeUnderTerrain = defaultValues['ratioVolumeUnderTerrain'];
                targetOverhaul.ratioVolumeUnderTerrain = defaultValues['ratioVolumeUnderTerrain'];
            }
            var quality = realEstateContainer.quality;
            if (!self.inputFields.facadeType) {
                nonUserInput.facadeType = true;
                quality.facadeType = defaultValues['facadeType'];
                targetOverhaul.facadeType = defaultValues['facadeType'];
            }
            if (!self.inputFields.roofTypes) {
                nonUserInput.roofTypes = true;
                quality.roofTypes = [defaultValues['roofTypes']];
                targetOverhaul.roofTypes = [defaultValues['roofTypes']];
            }
            if (!self.inputFields.heatingTypes) {
                nonUserInput.heatingTypes = true;
                quality.heatingTypes = [defaultValues['heatingTypes']];
                targetOverhaul.heatingTypes = [defaultValues['heatingTypes']];
            }
            if (!self.inputFields.ventilation) {
                nonUserInput.ventilationType = true;
                quality.ventilationType = defaultValues['ventilationType'];
                targetOverhaul.ventilationType = defaultValues['ventilationType'];
            }
            if (!self.inputFields.chiller) {
                nonUserInput.chillerType = true;
                quality.chillerType = defaultValues['chiller'];
                targetOverhaul.chillerType = defaultValues['chiller'];
            }
            if (!self.inputFields.elevatorVertical) {
                nonUserInput.elevatorVertical = true;
            }
            if (!self.inputFields.qualitaetGrundstueck) {
                nonUserInput.qualitaetGrundstueck = true;
            }
            if (!self.inputFields.qualitaetvorbereitungsarbeiten) {
                nonUserInput.qualitaetvorbereitungsarbeiten = true;
            }
            if (!self.inputFields.qualitaetVorfertigung) {
                nonUserInput.qualitaetVorfertigung = true;
            }
            if (!self.inputFields.qualitaetDerGebaeudeform2) {
                nonUserInput.qualitaetDerGebaeudeform2 = true;
            }
            if (!self.inputFields.fensterAnteil) {
                nonUserInput.fensterAnteil = true;
            }
            if (!self.inputFields.qualitaetElektroAnlage) {
                nonUserInput.qualitaetElektroAnlage = true;
            }
            if (!self.inputFields.qualitaetSanitaeranlage) {
                nonUserInput.qualitaetSanitaeranlage = true;
            }
            if (!self.inputFields.qualitaetAusbau1) {
                nonUserInput.qualitaetAusbau1 = true;
            }
            if (!self.inputFields.qualitaetAusbau2) {
                nonUserInput.qualitaetAusbau2 = true;
            }
            if (!self.inputFields.qualitaetUmgebung) {
                nonUserInput.qualitaetUmgebung = true;
            }
        }

        /**
         * If Frontend validation fails we remove default values due to the fact that the user might change his inputs -> which then results in showing default values.
         * @param realEstateContainer
         */
        function removeDefaultValues(realEstateContainer) {
            var nonUserInput = realEstateContainer.nonUserInput;
            var geometry = realEstateContainer.geometry;
            var targetOverhaul = realEstateContainer.targetOverhaul;
            if (nonUserInput.totalFloorArea416) {
                geometry.totalFloorArea416 = null;
                targetOverhaul.totalFloorArea416 = null;
            }
            if (nonUserInput.totalVolume416) {
                geometry.totalVolume416 = null;
                targetOverhaul.totalVolume416 = null;
            }
            if (nonUserInput.builtUpArea) {
                geometry.builtUpArea = null;
                targetOverhaul.builtUpArea = null;
            }
            if (nonUserInput.buildings) {
                geometry.buildings = null;
                targetOverhaul.buildings = null;
            }
            if (nonUserInput.overgroundFloors) {
                geometry.overgroundFloors = null;
                targetOverhaul.overgroundFloors = null;
            }
            if (nonUserInput.undergroundFloors) {
                geometry.undergroundFloors = null;
                targetOverhaul.undergroundFloors = null;
            }
            if (nonUserInput.undergroundParkingSpaces) {
                geometry.undergroundParkingSpaces = null;
                targetOverhaul.undergroundParkingSpaces = null;
            }
            if (nonUserInput.ratioVolumeUnderTerrain) {
                geometry.ratioVolumeUnderTerrain = null;
                targetOverhaul.ratioVolumeUnderTerrain = null;
            }
            var quality = realEstateContainer.quality;
            if (nonUserInput.facadeType) {
                quality.facadeType = null;
                targetOverhaul.facadeType = null;
            }
            if (nonUserInput.roofTypes) {
                quality.roofTypes = [];
                targetOverhaul.roofTypes = [];
            }
            if (nonUserInput.heatingTypes) {
                quality.heatingTypes = [];
                targetOverhaul.heatingTypes = [];
            }
            if (nonUserInput.ventilationType) {
                quality.ventilationType = null;
                targetOverhaul.ventilationType = null;
            }
            if (nonUserInput.chillerType) {
                quality.chillerType = null;
                targetOverhaul.chillerType = null;
            }
            if(nonUserInput.elevatorVertical) {
                //noop
            }
        }

        /**
         * false means input is not shown in the gui. e.g. nonUserInput.
         * @type {{undergroundParkingSpaces: boolean, totalFloorArea416: boolean, fensterAnteil: boolean, qualitaetSanitaeranlage: boolean, ratioVolumeUnderTerrain: boolean, heatingTypes: boolean, ventilation: boolean, facadeType: boolean, chiller: boolean, qualitaetGrundstueck: boolean, qualitaetDerGebaeudeform2: boolean, qualitaetvorbereitungsarbeiten: boolean, totalVolume416: boolean, builtUpArea: boolean, roofTypes: boolean, qualitaetElektroAnlage: boolean, overgroundFloorsUndergroundFloors: boolean, qualitaetAusbau2: boolean, buildings: boolean, qualitaetAusbau1: boolean, qualitaetVorfertigung: boolean, qualitaetUmgebung: boolean, elevatorVertical: boolean}}
         */
        this.inputFields = {
            usages: false,
            totalFloorArea416: false,
            totalVolume416: false,
            ratioVolumeUnderTerrain: false,
            builtUpArea: false,
            buildings: false,
            overgroundFloorsUndergroundFloors: false,
            undergroundParkingSpaces: false,
            roofTypes: false,
            facadeType: false,
            heatingTypes: false,
            ventilation: false,
            chiller: false,
            elevatorVertical: false,
            qualitaetGrundstueck: false,
            qualitaetvorbereitungsarbeiten: false,
            qualitaetVorfertigung: false,
            qualitaetDerGebaeudeform2: false,
            fensterAnteil: false,
            qualitaetElektroAnlage: false,
            qualitaetSanitaeranlage: false,
            qualitaetAusbau1: false,
            qualitaetAusbau2: false,
            qualitaetUmgebung: false
        };

        function getComponentsFor(inputFieldName) {
            return self.inputFieldsMapping[inputFieldName];
        }

        function updateExtraFields() {
            self.inputFields.numberOfQuantity = [
                self.inputFields.usages,
                self.inputFields.totalFloorArea416,
                self.inputFields.totalVolume416,
                self.inputFields.ratioVolumeUnderTerrain,
                self.inputFields.builtUpArea,
                self.inputFields.buildings,
                self.inputFields.overgroundFloorsUndergroundFloors,
                self.inputFields.undergroundParkingSpaces,
                self.inputFields.elevatorVertical,
                self.inputFields.roofTypes,
                self.inputFields.facadeType,
                self.inputFields.heatingTypes,
                self.inputFields.ventilation,
                self.inputFields.chiller
            ].filter(Boolean).length;
            self.inputFields.numberOfQuality =  [
                self.inputFields.qualitaetGrundstueck,
                self.inputFields.qualitaetvorbereitungsarbeiten,
                self.inputFields.qualitaetVorfertigung,
                self.inputFields.qualitaetDerGebaeudeform2,
                self.inputFields.fensterAnteil,
                self.inputFields.qualitaetElektroAnlage,
                self.inputFields.qualitaetSanitaeranlage,
                self.inputFields.qualitaetAusbau1,
                self.inputFields.qualitaetAusbau2,
                self.inputFields.qualitaetUmgebung
            ].filter(Boolean).length;
        }

        function displayDefaultFields() {
            _.forEach(self.inputFields, function(value, key) {
                self.inputFields[key] = false;
            });
            updateExtraFields();
        }

        function updateInputFields(currentComponents, hasUsageChiller, isAdditionStory, isAnnex, hasPomData) {
            for (var inputFieldName in self.inputFields) {
                if (inputFieldName === 'chiller' && !hasUsageChiller) {
                    self.inputFields[inputFieldName] = false;
                } else if (getComponentsFor(inputFieldName) !== undefined) {
                    self.inputFields[inputFieldName] = currentComponents.some(function (r) {
                        return getComponentsFor(inputFieldName).includes(r);
                    });
                }
            }

            if (isAnnex) {
                self.inputFields.builtUpArea = true;
                self.inputFields.qualitaetUmgebung = true;
            }

            if (hasPomData) {
                self.inputFields.totalFloorArea416 = true;
                self.inputFields.facadeType = true;
                self.inputFields.roofTypes = true;
                self.inputFields.heatingTypes = true;
                self.inputFields.ventilation = true;
                self.inputFields.chiller = hasUsageChiller;
                self.inputFields.elevatorVertical = true;
                self.inputFields.builtUpArea = true;
            }

            if (isAnnex || isAdditionStory) {
                self.inputFields.qualitaetGrundstueck = true;
                self.inputFields.qualitaetvorbereitungsarbeiten = true;
                self.inputFields.qualitaetVorfertigung = true;
                self.inputFields.qualitaetDerGebaeudeform2 = true;
                self.inputFields.fensterAnteil = true;
                self.inputFields.qualitaetElektroAnlage = true;
                self.inputFields.qualitaetSanitaeranlage = true;
                self.inputFields.qualitaetAusbau1 = true;
                self.inputFields.qualitaetAusbau2 = true;
            }
            self.inputFields.usages = currentComponents.length > 0;
            updateExtraFields();
        }
        updateExtraFields();

        return {
            inputFields: this.inputFields,
            fillDefaultValues: fillDefaultValues,
            removeDefaultValues: removeDefaultValues,
            updateInputFields: updateInputFields,
            displayDefaultFields: displayDefaultFields
        };
    }

})();
