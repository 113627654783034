(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('POMIntegrationService', POMIntegrationService);

    POMIntegrationService.$inject = ['Principal', 'usageTypeGroupFactory'];

    function POMIntegrationService(Principal, usageTypeGroupFactory) {

        var isPomEnabled = false;

        var service = {
            isPomEnabled: isPomEnabled,
            checkUsageChange: checkUsageChange,
            init: init
        };

        function init(realEstateContainer) {
            console.info("init POMIntegrationService with: ", realEstateContainer, realEstateContainer.metaData.tasks);
            var idx = realEstateContainer.metaData.tasks.indexOf('OPERATING_COSTS');
            // if (idx >= 0 && Principal.hasModule("OPERATING_COSTS")) {
            if (idx >= 0) { // due to LCC beta operational costs can be active without having the module => Reset after LCC Beta.
                realEstateContainer.pom.hasPomData = true;
            }
        }

        /**
         * This watch checks if the usage type changes of an realEstate and handles the pom checkbox according to the usage,
         * as for some usages we might not offer pom functionality.
         */
        function checkUsageChange(newUsage, realEstateContainer) {
            if (newUsage) { // checking for defined is not enough, newUsage should also not be null
                var usage = usageTypeGroupFactory.getByTitle(newUsage);
                try {
                    var usageParentGroup = usage.id.substring(0, 2);
                } catch (e) {
                    usageParentGroup = 'error';
                }
                if (
                    usage.id != '130104' && usage.id != '130103' && (
                        usageParentGroup == '01' || usageParentGroup == '02' ||
                        usageParentGroup == '03' || usageParentGroup == '06' ||
                        usageParentGroup == '08' || usageParentGroup == '13')
                ) {
                    service.isPomEnabled = true;
                    // temporarly outcomment
                    // realEstateContainer.pom.hasPomData = true;
                } else {
                    service.isPomEnabled = false;
                    realEstateContainer.pom.hasPomData = false;
                }
            } else {
                service.isPomEnabled = false;
                realEstateContainer.pom.hasPomData = false;
            }
        }

        return service;
    }
})();
