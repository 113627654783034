(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('dynamicWebinar', {
            bindings: {},
            templateUrl: 'app/website_fragments/products/webinar/dynamic-webinar.html',
            controller: dynamicWebinarController
        });

    dynamicWebinarController.$inject = ['$scope', '$rootScope', '$element', '$translate', '$attrs', 'JhiLanguageService'];

    function dynamicWebinarController($scope, $rootScope, $element, $translate, $attrs, JhiLanguageService) {
        var $ctrl = this;

        var updateLangKey = function() {
            JhiLanguageService.getCurrent().then(function(language) {
                $ctrl.langKey = language;
            });
        };

        this.$onInit = function() {
            updateLangKey();
        };

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function(_) {
            updateLangKey();
        });

        this.$onDestroy = function() {
            translateSuccess();
        };
    }
})();
