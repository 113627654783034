(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('rebuild-form', {
                parent: 'app',
                abstract: true,
                url: '/rebuild-form',
                views: {
                    // Fill 'ui-view="content"' of 'index.html'
                    'content@': {
                        templateUrl: "app/rebuild_form/rebuild_form.html",
                        controller: 'RebuildFormController',
                        controllerAs: 'vm'
                    },
                    'input@rebuild-form': {
                        templateUrl: 'app/rebuild_form/10_input/rebuild_input.html',
                        controller: 'RebuildInputController',
                        controllerAs: 'vm'
                    },
                    'rebuild_calculating_error@rebuild-form': {
                        templateUrl: 'app/rebuild_form/20_output/10_calculating_error/rebuild_calculating_error.html',
                        controller: 'RebuildCalculatingErrorController',
                        controllerAs: 'vm'
                    },
                    'rebuild_calculating_success@rebuild-form': {
                        templateUrl: 'app/rebuild_form/20_output/11_calculating_success/rebuild_calculating_success.html',
                        controller: 'RebuildCalculatingSuccessController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    data: null,
                    withdrawalProductType: null,
                    realEstateId: null,
                    realEstate: null
                },
                //controller: 'RebuildFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.building-costs.input'
                },
                resolve: {
                    resetIsCompleteState: ['ValidationDataService', function (ValidationDataService) {
                        ValidationDataService.resetComplete();
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('rebuild_form')
                            .addPart('model_rebuild')
                            .addPart('process_form')
                            .addPart('realestates')
                            .addPart('model_geometry')
                            .addPart('operational-costs_form')
                            .addPart('model_quality')
                            .addPart('components_slider-values');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('rebuild-form.input', {
                url: '/input/',
                //controller: 'RebuildFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.rebuild-costs.input',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        '10_rebuild_howto_section',
                        '11_rebuild_general_form',
                        '20_rebuild_template_form',
                        '30_rebuild_geometry_form',
                        '40_rebuild_state_analysis_form',
                        '70_rebuild_additions_form',
                        '80_rebuild_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e) {
                            console.warn(e);
                        });
                    }],
                    check: ['$state', 'Principal', function($state, Principal) {
                        Principal.identity().then(function(e) {
                            if (!!e && !Principal.hasModule('REBUILD_COSTS')) {
                                $state.go('insufficient-rights.rebuild-costs');
                            }
                        }).catch(function(e) {
                            console.warn(e);
                        });
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('rebuild_form')
                            .addPart('model_rebuild')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('model_quality')
                            .addPart('process_form')
                            .addPart('operational-costs_form');
                        return $translate.refresh().catch(function(e) {
                            console.warn(e);
                        });
                    }]
                }
            })
            .state('rebuild-form.edit-record', {
                url: '/edit-record',
                //controller: 'RebuildFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'],
                    pageTitle: 'global.menu.rebuild-costs.edit',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        // '10_rebuild_howto_section',
                        '15_rebuild_howto_edit_record',
                        '11_rebuild_general_form',
                        '20_rebuild_template_form',
                        '30_rebuild_geometry_form',
                        '40_rebuild_state_analysis_form',
                        '70_rebuild_additions_form',
                        '80_rebuild_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e){console.warn(e)});
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('rebuild_form')
                            .addPart('model_rebuild')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('model_quality')
                            .addPart('process_form')
                            .addPart('operational-costs_form');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            });
    }
})();
