(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('securityLevelSelecter', {
            bindings: {
                reference: '=?',
                realEstate: '=?', // only needed in case isReference is not set
                referencePlaceholder: '=?', // only needed in case isReference is not set
                isReference: '@?', // indicates if this input is used in reference process form
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '=',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                validationMessage2: '@?',
                descriptionBaseKey: '@',
                usages: '<',
                infotext: '@?'
            },
            templateUrl: 'app/components/security_selecter/security_selecter.tpl.html',
            controller: SecuritySelecterController
        });

    SecuritySelecterController.$inject = ['WithdrawalService', '$scope', '$rootScope', '$attrs', '$element', '$translate'];

    function SecuritySelecterController(WithdrawalService, $scope, $rootScope, $attrs, $element, $translate) {
        var $ctrl = this;
        $scope.firstLoad = true;
        $ctrl.answers = ["IRRELEVANT", "PATROLS", "ACCESS_CONTROL", "PATROLS_AND_ACCESS_CONTROL"];

        // use polyfills for IE, TODO: Introduce a proper place for polyfills
        Number.isInteger = Number.isInteger || function (value) {
            return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
        };

        $ctrl.onBlur = function () {

        };

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        $ctrl.translateKey = function (answer) {
            return $ctrl.translateBaseKey +'.' + answer +".label";
        };

        $ctrl.onOpenClose = function (isOpen, type) {
            var descriptionKey;
            var imageKey;
            descriptionKey = $ctrl.descriptionBaseKey;
            imageKey = $ctrl.image;
            if (isOpen) {
                $rootScope.$broadcast("updateDescription", {
                    rowid: $ctrl.rowid,
                    descriptionBaseKey: descriptionKey
                });
                $rootScope.$broadcast("updateImage", {
                    rowid: $ctrl.rowid,
                    imageSrc: imageKey
                });
            }
        };

        $ctrl.updateDescriptionAndImage = function (selectedItem, model, string) {
            var tempDescriptionKey;
            var imageKey;
            if (angular.isDefined(selectedItem)) {
                tempDescriptionKey = $ctrl.descriptionBaseKey + '.' + selectedItem;
                imageKey = $ctrl.image;
            } else {
                tempDescriptionKey = $ctrl.descriptionBaseKey;
            }
            $rootScope.$broadcast("updateImage", {
                rowid: $ctrl.rowid,
                imageSrc: imageKey
            });

            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionNumber: selectedItem,
                descriptionBaseKey: tempDescriptionKey
            });
        };

        // This could also be written with a doCheck
        // which isn't really good for performance at it calls each digest.
        // that's we are using an deep watch.

        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');

            // Used for the validation
            var toggle = $element.find(".securityLevelDropdown");
            $(toggle).attr("id", $attrs.reference + ".drop2down-toggle");

        };
    }
})();
