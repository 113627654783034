(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildInterventionGradeModernizationService', RebuildInterventionGradeModernizationService);

    RebuildInterventionGradeModernizationService.$inject = ['$http'];

    function RebuildInterventionGradeModernizationService($http) {

        function fetch(
            maintenance_year,
            construction_year,
            component,
            usage,
            facade_type,
            heating_types,
            standard,
            maintenance_quality,
            monument_protection,
            condition_class,
            remaining_technical_lifetime,
            remaining_economic_lifetime,
            measurement_class
        ) {
            return $http({
                url: 'https://v2-rebuild-intervention-grade-modernization.kennwerte.ch',
                method: 'GET',
                params: {
                    maintenance_year: maintenance_year,
                    construction_year: construction_year,
                    component: component,
                    usage: usage,
                    facade_type: facade_type,
                    heating_types: heating_types,
                    standard: standard,
                    maintenance_quality: maintenance_quality,
                    monument_protection: monument_protection,
                    condition_class: condition_class,
                    remaining_technical_lifetime: remaining_technical_lifetime,
                    remaining_economic_lifetime: remaining_economic_lifetime,
                    measurement_class: measurement_class
                }
            });
        }

        function fetchData(
            maintenance_year,
            construction_year,
            component,
            usage,
            facade_type,
            heating_types,
            standard,
            maintenance_quality,
            monument_protection,
            remaining_economic_lifetime,
            condition_class,
            remaining_technical_lifetime,
            measurement_class,
            fError
        ) {
            fError = fError || function(res) { console.error(res); };
            return this.fetch(
                maintenance_year,
                construction_year,
                component,
                usage,
                facade_type,
                heating_types,
                standard,
                maintenance_quality,
                monument_protection,
                condition_class,
                remaining_technical_lifetime,
                remaining_economic_lifetime,
                measurement_class
            ).then(function(res) { return res.data; }, fError);
        }

        return {
            fetch: fetch,
            fetchData: fetchData
        };
    }

})();
