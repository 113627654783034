(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('cascadingUsageTypeSelect', CascadingUsageTypeSelectDirective);

    CascadingUsageTypeSelectController.$inject = ['$scope','$rootScope', 'usageTypeGroupFactory', '$translate'];

    function CascadingUsageTypeSelectController($scope,$rootScope, usageTypeGroupFactory, $translate) {

        var vm = this;

        vm.translateKey = function(postfix) {
            try {
                return $translate.instant('realEstate.usages.' + postfix);
            } catch (e) {
                console.warn('translation error - usageType', e);
            }
        };

        function loadGroup2Of(item) {
            if (item.parent) {
                hideGroup2();
                vm.model2.visible = true;
                vm.groups2 = enhanceGroupFn(usageTypeGroupFactory.load(item.id));
            }
        }

        function loadGroup3Of(item) {
            if (item.parent) {
                hideGroup3();
                vm.model3.visible = true;
                vm.groups3 = enhanceGroupFn(usageTypeGroupFactory.load(item.id));
            }
        }

        function hideGroup2() {
            vm.model2.visible = false;
            vm.model2.id = undefined;
            vm.model2.title = 'All';
            vm.model2.translated = vm.translateKey(vm.model2.title);
            hideGroup3();
        }

        function hideGroup3() {
            vm.model3.visible = false;
            vm.model3.id = undefined;
            vm.model3.title = 'All';
            vm.model3.translated = vm.translateKey(vm.model3.title);
        }

        function enhanceGroupFn(groups) {
            var enhancedGroups = [];
            enhancedGroups.push({
                "id": "00",
                "title": "All",
                "parent": false,
                "visible": true,
                "translated": vm.translateKey("All")
            });
            for (var i in groups) {
                var group = groups[i];
                enhancedGroups.push({
                    "id": group.id,
                    "title": group.title,
                    "parent": group.parent,
                    "visible": true,
                    "translated": vm.translateKey(group.title)
                });
            }
            return enhancedGroups;
        }

        vm.model1 = {
            "id": "00",
            "title": "All",
            "visible": true,
            "translated": vm.translateKey("All")
        };
        vm.groups1 = enhanceGroupFn(usageTypeGroupFactory.load("00"));

        vm.model2 = {
            "title": "All",
            "visible": false,
            "translated": vm.translateKey("All")
        };
        vm.groups2 = [];

        vm.model3 = {
            "title": "All",
            "visible": false,
            "translated": vm.translateKey("All")
        };
        vm.groups3 = [];

        vm.previousId = "00";

        function setVmModel1(item) {
            vm.model1.id = item.id;
            vm.model1.title = item.title;
            vm.model1.parent = item.parent;
            vm.model1.translated = vm.translateKey(item.title);
            vm.model1.visible = true;
            if (item.id === '00') {
                hideGroup2();
            } else {
                loadGroup2Of(item);
            }

        }

        function setVmModel2(item) {
            vm.model2.id = item.id;
            vm.model2.title = item.title;
            vm.model2.parent = item.parent;
            vm.model2.translated = vm.translateKey(item.title);
            vm.model2.visible = true;
            if (!item.parent) {
                hideGroup3();
            } else {
                loadGroup3Of(item);
            }
        }

        function setVmModel3(item) {
            vm.model3.id = item.id;
            vm.model3.title = item.title;
            vm.model3.parent = item.parent;
            vm.model3.translated = vm.translateKey(item.title);
            vm.model3.visible = true;
        }

        function setVmModels(cnt, item) {
            if (cnt === 0) {
                setVmModel1(item);
            } else if (cnt === 1) {
                setVmModel2(item);
            } else {
                setVmModel3(item);
            }
        }

        if ($scope.reference) {
            // if a reference is set, setup cascading usage types appropriatly
            // reference maps to a title
            var obj = usageTypeGroupFactory.getByTitle($scope.reference);
            var prevGroups = usageTypeGroupFactory.getAllPreviousGroups(obj.id);
            var prevGroupsLengthMinus = prevGroups.length - 1;
            var cnt = 0;
            // set previous items
            for (var i = prevGroupsLengthMinus; i >= 0; i--) {
                var prevGroup = prevGroups[i];
                setVmModels(cnt, prevGroup);
                cnt = cnt + 1;
            }
            // set this item
            setVmModels(cnt, obj);
            vm.previousId = obj.id;
        }

        // everything is set up, now watch for changes on viewmodel variables
        $scope.$watch('vm.model1', function (selectedItem, oldItem) {
            if (angular.equals(selectedItem, oldItem)) {
                // first call if reference is set on initialization
            } else if (selectedItem.id !== vm.previousId) {
                if (selectedItem.id === '00') {
                    hideGroup2();
                    $scope.reference = selectedItem.title;
                    vm.previousId = selectedItem.id;
                } else {
                    loadGroup2Of(selectedItem);
                    $scope.reference = selectedItem.title;
                    vm.previousId = selectedItem.id;
                }
            } else {
                hideGroup2();
            }
        });

        $scope.$watch('vm.model2', function (selectedItem, oldItem) {
            if (angular.equals(selectedItem, oldItem)) {
                // first call if reference is set on initialization
            } else if (selectedItem.id !== vm.previousId && selectedItem.visible) {
                if (selectedItem.id === '00') {
                    hideGroup3();
                    $scope.reference = vm.model1.title;
                    vm.previousId = vm.model1.id;
                } else {
                    loadGroup3Of(selectedItem);
                    $scope.reference = selectedItem.title;
                    vm.previousId = selectedItem.id;
                }
            } else {
                hideGroup3();
            }
        });

        $scope.$watch('vm.model3', function (selectedItem, oldItem) {
            if (angular.equals(selectedItem, oldItem)) {
                // first call if reference is set on initialization
            } else if (selectedItem.id !== vm.previousId && selectedItem.visible) {
                loadGroup3Of(selectedItem);
                if (selectedItem.title !== 'All') {
                    $scope.reference = selectedItem.title;
                    vm.previousId = selectedItem.id;
                } else {
                    $scope.reference = vm.model2.title;
                    vm.previousId = vm.model2.id;
                }
            } else {
                hideGroup3();
            }
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            if (vm.model != null) {
                vm.model.translated = vm.translateKey(vm.model.title);
            }
            for (var i = 0; i < vm.groups1.length; i++) {
                if (vm.groups1[i].title != null) {
                    vm.groups1[i].translated = vm.translateKey(vm.groups1[i].title);
                }
            }
            for (var i = 0; i < vm.groups2.length; i++) {
                if (vm.groups2[i].title != null) {
                    vm.groups2[i].translated = vm.translateKey(vm.groups2[i].title);
                }
            }
            for (var i = 0; i < vm.groups3.length; i++) {
                if (vm.groups3[i].title != null) {
                    vm.groups3[i].translated = vm.translateKey(vm.groups3[i].title);
                }
            }
            vm.model1.translated = vm.translateKey(vm.model1.title);
            vm.model2.translated = vm.translateKey(vm.model2.title);
            vm.model3.translated = vm.translateKey(vm.model3.title);
        });
        this.$onDestroy = function() {
            translateSuccess();
        };

    }

    CascadingUsageTypeSelectDirective.$inject = ['usageTypeGroupFactory'];

    function CascadingUsageTypeSelectDirective(usageTypeGroupFactory) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                tabIndex: '=',
                tabGroup: '='
            },
            templateUrl: 'app/components/usage_type/cascading-usage-type-select.tpl.html',
            controller: CascadingUsageTypeSelectController,
            controllerAs: 'vm',
            link: function ($scope, tElement, tAttrs) {
                $scope.translateKey = function (postfix) {
                    try {
                        return $translate.instant('realEstate.usages.' + postfix);
                    } catch (e) {
                        console.warn('cascading selecter ctr translation error',e);
                        return '';
                    }
                };
            }

        };
    }

})();
