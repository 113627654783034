(function () {
    'use strict';
    angular.module('kennwerteApp')
        .factory('VentilationTypeDataService', VentilationTypeDataService);

    VentilationTypeDataService.$inject = [];

    function VentilationTypeDataService() {

        var service = {};

        var baseGroup = 'Lüftung-, Kälteanlagen';
        var sharedGroup = 'Bezug';

        service.NONE                       = {"group": baseGroup,   "value": "NONE"};
        service.EXHAUST_AIR                = {"group": baseGroup,   "value": "EXHAUST_AIR"};
        service.SUPPLY_EXHAUST_AIR         = {"group": baseGroup,   "value": "SUPPLY_EXHAUST_AIR"};
        service.UNDERGROUND_PARKING_AIR    = {"group": baseGroup,   "value": "UNDERGROUND_PARKING_AIR"};
        service.CLIMA_PARTIAL              = {"group": baseGroup,   "value": "CLIMA_PARTIAL"};
        service.CLIMA_FULL                 = {"group": baseGroup,   "value": "CLIMA_FULL"};

        service.SHARED_ANNEX               = {"group": sharedGroup, "value": "SHARED_ANNEX"};
        service.SHARED_ADDITION_STORY      = {"group": sharedGroup, "value": "SHARED_ADDITION_STORY"};
        service.SHARED_OVERHAUL            = {"group": sharedGroup, "value": "SHARED_OVERHAUL"};

        var baseVentilationTypes = [
            service.NONE,
            service.EXHAUST_AIR,
            service.SUPPLY_EXHAUST_AIR,
            // service.UNDERGROUND_PARKING_AIR, // currently only in kennwerte-database
            service.CLIMA_PARTIAL,
            service.CLIMA_FULL
        ];

        var shared = [
            service.SHARED_ANNEX,
            service.SHARED_ADDITION_STORY,
            service.SHARED_OVERHAUL
        ];

        var unshareable = [ service.EXHAUST_AIR ];

        service.unshareable = unshareable;

        service.defaultData = baseVentilationTypes;
        service.rebuildIs = baseVentilationTypes;
        service.rebuildTarget = shared.concat(baseVentilationTypes);

        return service;
    }


})();
