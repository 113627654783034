(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('AccuracyOperatingCostsController', AccuracyOperatingCostsController);

    AccuracyOperatingCostsController.$inject = ['$scope', '$timeout', '$translate',
        'ResponsiveStateService'];

    function AccuracyOperatingCostsController($scope, $timeout, $translate,
                                              ResponsiveStateService) {
        var vm = this;

    }

})();
