(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('OrganisationManagementDialogController', OrganisationManagementDialogController);

    OrganisationManagementDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', '$location', 'entity',
        'Organisation', 'PaymentService', 'PaymentPdfService',
        'BaseLicenseService', 'WithdrawalProductService', 'WptFreeTrackingService',
        'BillingService', 'LuucyMgmtService',
        '$translate', 'moment', '$timeout'];

    function OrganisationManagementDialogController($scope, $stateParams, $uibModalInstance, $location, entity,
                                                    Organisation, PaymentService, PaymentPdfService,
                                                    BaseLicenseService, WithdrawalProductService, WptFreeTrackingService,
                                                    BillingService, LuucyMgmtService,
                                                    $translate, moment, $timeout) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.savePayment = savePayment;
        vm.prepareChargeBackPayment = prepareChargeBackPayment;
        vm.submitChargeBackPayment = submitChargeBackPayment;
        vm.deleteChargeBackPayment = deleteChargeBackPayment;

        vm.showDeletePayment = showDeletePayment;
        vm.deletePayment = deletePayment;

        vm.showCopyAsNewPayment = showCopyAsNewPayment;
        vm.copyAsNewPayment = copyAsNewPayment;

        vm.closePaymentAlert = closePaymentAlert;

        vm.deactiveLicense = deactiveLicense;

        vm.resignContract = resignContract;
        vm.undoResignContract = undoResignContract;

        vm.createInvoicePayment = createInvoicePayment;
        vm.updateLatestInvoicePayment = updateLatestInvoicePayment;
        vm.isEligibleForCreatingInvoicePayment = isEligibleForCreatingInvoicePayment;
        vm.isEligibleForUpdatingLatestInvoicePayment = isEligibleForUpdatingLatestInvoicePayment;
        vm.buySpecialOffer = buySpecialOffer;

        vm.reloadAll = reloadAllData;

        vm.createCustomLicense = createCustomLicense;
        vm.deleteCustomLicense = deleteCustomLicense;
        vm.validateCustomLicense = validateCustomLicense;

        vm.startCreateCustomLicense = startCreateCustomLicense;
        vm.startDeleteCustomLicense = startDeleteCustomLicense;

        vm.updateLicense = updateLicense;
        vm.updatePendingLicense = updatePendingLicense;
        vm.headerChooseLicense = headerChooseLicense;
        vm.createDifferentialInvoiceAdditionalUsers = createDifferentialInvoiceAdditionalUsers;
        vm.createRemoveUsers = createRemoveUsers;

        vm.showMails = showMails;
        vm.hideMails = hideMails;
        vm.sendPaymentEmail = sendPaymentEmail;
        vm.forceSetInvoiceState = forceSetInvoiceState;

        vm.translateStatus = translateStatus;
        vm.translateInvoiceStatus = translateInvoiceStatus;
        vm.translateCustomerSegment = translateCustomerSegment;

        vm.billOrganisationNow = billOrganisationNow;

        vm.customLicenseManipulationFailed = false;
        vm.customLicense = {};
        vm.isCreatingCustomLicense = false;
        vm.isDeletingCustomLicense = false;
        vm.billingContactEqualsOrgContact = true;

        vm.baseLicensesToDelete = [];
        vm.baseLicenseToDelete = null;

        vm.curWp = null;
        vm.pendingWp = null;

        vm.dateTimeInactive = null;
        vm.dateTimeFreeUntil = null;
        vm.dateTimeValidUntil = null;

        vm.pendingDateTimeFreeUntil = null;
        vm.pendingDateTimeValidUntil = null;
        vm.pendingLicenseState = null;
        vm.pendingBaseLicense = null;

        vm.masterTime = moment();
        vm.payments = null;
        vm.paymentAlerts = [];
        vm.numAdditionalUsers = null;
        vm.numAdditionalUsersProcess = 0;
        vm.numRemoveUsersProcess = 0;
        vm.invite = null;
        vm.saved = null;
        vm.organisationUserLogin = null;
        vm.baseLicense = null;

        vm.organisationLoaded = false;
        vm.organisation = {};
        vm.user = {};
        vm.resetLink = null;
        vm.licenseState = null;

        vm.masterTime.milliseconds(0);
        vm.masterTime.seconds(0);

        vm.baseLicenses = null;
        vm.licenseStates = ['FREE', 'PAYED', 'INVALID']; // removed 'PAYMENT_UNSUCCESSFUL' because credit cards are not supported anymore
        vm.transactionStati = ['OPEN', 'CAPTURED', 'FAIL'];
        vm.invoiceStati = [
            'INVOICE_SENDING',
            'INVOICE_NOT_SENT',
            'INVOICE_SENT',
            'FIRST_REMINDER_SENDING',
            'FIRST_REMINDER_NOT_SENT',
            'FIRST_REMINDER_SENT',
            'SECOND_REMINDER_SENDING',
            'SECOND_REMINDER_NOT_SENT',
            'SECOND_REMINDER_SENT',
            'OVERDUE'];
        vm.productTypes = ['BUILDING_COSTS_AND_DATES', 'BUILDING_INSURANCE_VALUES', 'OPERATING_COSTS', 'REBUILD_COSTS', 'SMALL_BUILDING_AGV','DAMAGE_COSTS', 'INSURANCE_VALUES_GVBS', 'LCC_PACKAGE', 'LCA', 'EFFORT_PLANNER'];
        vm.organisationCats = [];

        vm.customerSegmentOptions = ['PLANER', 'BAUHERREN', 'VERSICHERUNGEN', 'NA'];

        vm.paymentOptions = ['INVOICE', 'CREDIT_CARD'];
        vm.organisation.orgPayment = 'INVOICE';

        vm.keys = [];

        vm.authorities = ['ROLE_USER', ' '];

        vm.organisationRole = ['ORG_ADMIN'];

        vm.languages = ['de', 'en'];

        vm.requestFreeMonthUpdate = false;

        //used for special License 12 months + x.
        vm.smbDto = {
            freeMonths: 3,
            wpts: []
        };
        //used for special License 12 months + x.
        vm.freeMonthsDropDown = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// eslint-disable-next-line eqeqeq
        vm.isEdit = (entity.id != null);

        loadData();

        loadAllApiKeys();

        vm.downloadPaymentPdf = function(p) {
            var namePrefix;
            if ('INVOICE' === p.paymentMeans) {
                namePrefix = 'kennwerte Rechnung ';
            } else {
                namePrefix = 'kennwerte Kaufbeleg ';
            }
            return PaymentPdfService.downloadPaymentPdf(p.id, namePrefix + p.confirmationId + '.pdf');
        };

        function translateStatus(status) {
            try {
                return $translate.instant('billing.history.transactionStatus.' + status);
            } catch (e) {
                console.warn('translate error', e);
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.form2.$invalid) {
                angular.forEach(vm.form.$error.required, function (field) {
                    field.$setDirty();
                });
                return;
            }
            if (vm.billingContactEqualsOrgContact) {
                vm.organisation.billingContact = null;
            }

            if (angular.isUndefined(vm.organisation.id)) {
                // create a new organisation, which returns a new managed organisation
                vm.organisation.paymentMeans = 'INVOICE';
                var adminRegisterDTO = {
                    'organisation': {
                        'name': vm.organisation.name,
                        'withdrawalProductTypes': vm.organisationCats,
                        'street': vm.organisation.street,
                        'zipCode': vm.organisation.zipCode,
                        'place': vm.organisation.place,
                        'phone': vm.organisation.phone,
                        'numEmployees': vm.organisation.numEmployees,
                        'siaIndividualMember': vm.organisation.siaIndividualMember,
                        'siaCorporateMember': vm.organisation.siaCorporateMember,
                        'doAutoSendPaymentEmail': vm.organisation.doAutoSendPaymentEmail,
                        'billingContact': vm.organisation.billingContact
                    },
                    'account': {
                        'login': vm.organisationUserLogin,
                        'firstName': vm.user.firstName,
                        'lastName': vm.user.lastName,
                        'email': vm.user.email,
                        'activated': vm.user.activated,
                        'langKey': vm.user.langKey,
                        'organisationRole': vm.user.organisationRole,
                        'authorities': [vm.user.authority]
                    },
                    'numAdditionalUsers': vm.numAdditionalUsers
                };
                Organisation.save(adminRegisterDTO, onSaveSuccess, onSaveError);
            } else {
                var adminUpdateDTO = {
                    'id': vm.organisation.id,
                    'name': vm.organisation.name,
                    'withdrawalProductTypes': vm.organisationCats,
                    'street': vm.organisation.street,
                    'zipCode': vm.organisation.zipCode,
                    'place': vm.organisation.place,
                    'phone': vm.organisation.phone,
                    'numEmployees': vm.organisation.numEmployees,
                    'siaIndividualMember': vm.organisation.siaIndividualMember,
                    'siaCorporateMember': vm.organisation.siaCorporateMember,
                    'customerSegment': vm.organisation.customerSegment,

                    'contactFirstName': vm.user.firstName,
                    'contactLastName': vm.user.lastName,
                    'contactEmail': vm.user.email,

                    'billingContact': vm.organisation.billingContact,

                    'doAutoSendPaymentEmail': vm.organisation.doAutoSendPaymentEmail,

                    'paymentMeans': vm.organisation.paymentMeans,
                    'numAdditionalUsers': vm.numAdditionalUsers,
                    'dateTimeInactive': vm.dateTimeInactive,
                    'validUntil': vm.dateTimeValidUntil,
                    'freeUntil': vm.dateTimeFreeUntil.toISOString()
                };
                Organisation.adminUpdate({id: vm.organisation.id}, adminUpdateDTO, onSaveSuccess, onSaveError);
            }
        }

        function deactiveLicense() {
            //get current time for deactivation
            var now = vm.masterTime.toISOString();
            var adminUpdateLicense = {
                'orgId': vm.organisation.id,
                'validUntil': vm.dateTimeValidUntil.toISOString(),
                'freeUntil': now,
                'licenseState': 'INVALID',
                'numEmployees': vm.organisation.numEmployees,
                'withdrawalProductTypes': vm.curWp.withdrawalProductTypes
            };
            Organisation.adminUpdateLicense({}, adminUpdateLicense, function (result) {
                if (result.content === 'SUCCESS') {
                    addPaymentAlert('success', 'Lizenz wurde für ' + vm.organisation.name + ' wurde deaktiviert.');
                    reloadAllData();
                }
            }, function (error) {
                console.error(error);
            });

        }

        function resignContract() {
            Organisation.adminDisableLicenseRenewal({id: vm.organisation.id},
                function (result) {
                    if (result.content == 'SUCCESS')
                        vm.dateTimeInactive = vm.masterTime.toISOString();
                }, function (error) {
                    console.error(error);
                    addPaymentAlert('danger', error.content.defaultMessage);
                });
        }

        function undoResignContract() {
            Organisation.adminEnableLicenseRenewal({id: vm.organisation.id},
                function (result) {
                    if (result.content == 'SUCCESS')
                        vm.dateTimeInactive = null;
                }, function (error) {
                    console.error(error);

                });
        }

        function prepareChargeBackPayment(item, index) {
            var chargeback = {
                isNewChargeBack: true,
                chargeBackId: item.id,
                confirmationId: '',
                amount: null,
                date: vm.masterTime.toISOString(),
                paymentMeans: item.paymentMeans,
                purchaseReason: 'Rückbuchung'
            };
            vm.payments.splice(index + 1, 0, chargeback);
        }

        function submitChargeBackPayment(item, index) {
            if (item.isNewChargeBack) {
            }
        }

        function deleteChargeBackPayment(item, index) {
            if (angular.isDefined(item.id)) {
            } else {
                vm.payments.splice(index, 1);
            }
        }

        function showDeletePayment(payment) {
            payment.showDeletePayment = true;
        }

        function deletePayment(payment) {
            PaymentService.adminDelete({id: payment.id},
                function (result) {
                    payment.showDeletePayment = false;
                    reloadAllData();
                },
                function (error) {
                    payment.showDeletePayment = true;
                });
        }

        function showCopyAsNewPayment(payment) {
            payment.dateCopyAsNewPayment = moment.utc();
            payment.showCopyAsNewPayment = true;
        }

        function copyAsNewPayment(payment) {
            PaymentService.adminCopyAsNewPayment({paymentId: payment.id, date: payment.dateCopyAsNewPayment},
                function(result) {
                    payment.showCopyAsNewPayment = false;
                    reloadAllData();
                },
                function (error) {
                    payment.showCopyAsNewPayment = true;
                })
        }

        function isEligibleForCreatingInvoicePayment() {
            vm.disableCreatingCheckButton = true;
            PaymentService.isEligibleForCreatingInvoicePayment({id: vm.organisation.id},
                function (result) {
                    if (result.content == 'SUCCESS') {
                        vm.createNewInvoicePdf = true;
                        vm.disableCreatingCheckButton = true;
                    } else {
                        vm.createNewInvoicePdf = false;
                        vm.disableCreatingCheckButton = false;
                    }
                }, function (error) {
                    vm.disableCreatingCheckButton = false;
                });
        }

        function createInvoicePayment() {
            vm.createNewInvoicePdf = false;
            PaymentService.createInvoicePayment({id: vm.organisation.id}, {},
                function (result) {
                    addPaymentAlert('success', 'Neue Rechnung wird erstellt');
                    vm.createNewInvoicePdf = false;
                    vm.disableCreatingCheckButton = false;
                    reloadAllData();
                },
                function (error) {
                    addPaymentAlert('danger', 'Etwas ging schief bei der neuen Rechnung erstellen');
                    vm.createNewInvoicePdf = false;
                    vm.disableCreatingCheckButton = false;
                    reloadAllData();
                });
        }

        /**
         * This function only checks if we are able to update the last Invoice Payment pdf.
         */
        function isEligibleForUpdatingLatestInvoicePayment() {
            vm.disableUpdateCheckButton = true;
            PaymentService.isEligibleForUpdatingLatestInvoicePayment({id: vm.organisation.id},
                function (result) {
                    if (result.content == 'SUCCESS') {
                        vm.updateLatestInvoicePdf = true;
                        vm.disableUpdateCheckButton = true;
                    } else {
                        vm.updateLatestInvoicePdf = false;
                        vm.disableUpdateCheckButton = false;
                    }
                });

        }

        /**
         * Buys a special offer
         * @param freeMonths int number of free months
         * @param wpts array of withdrawal product types for free month. These withdrawal product types must not be already bought
         */
        function buySpecialOffer() {
            vm.buySpecialOfferRebuildActive = true;
            PaymentService.buySpecialOffer({ id: vm.organisation.id }, vm.smbDto ,
                function(result) {
                    addPaymentAlert('success', 'Neue Rechnung "Spezialangebot" wird erstellt');
                    vm.buySpecialOfferRebuildActive = false;
                    reloadAllData();
                },
                function(error) {
                    addPaymentAlert('danger', 'Etwas ging schief bei der neuen Rechnung "Spezialangebot" erstellen');
                    vm.buySpecialOfferRebuildActive = false;
                    reloadAllData();
                });
        }

        /**
         * Updating the latest Invoice Payment.
         */
        function updateLatestInvoicePayment() {
            vm.updateLatestInvoicePdf = false;
            PaymentService.updateLatestInvoicePayment({id: vm.organisation.id}, {},
                function (result) {
                    vm.updateLatestInvoicePdf = false;
                    vm.disableUpdateCheckButton = false;
                    vm.payments.filter(function (value, index, array) {
                        if (value.id == result.id) {
                            vm.payments[index] = result;
                        }
                    });
                    addPaymentAlert('success', 'Die letzte Rechnung wird updated!');
                    reloadAllData();
                }, function (error) {
                    addPaymentAlert('danger', 'Etwas ging schief bei der neuen Rechnung erstellen');
                    vm.updateLatestInvoicePdf = true;
                    vm.disableUpdateCheckButton = false;
                    reloadAllData();
                });
        }


        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.organisation.id = result.id;
            vm.user.resetKey = result.resetKey;
            createResetLink();
            vm.saved = true;
            reloadAllData();
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.saved = false;
        }

        function translateInvoiceStatus(status) {
            try {
                return $translate.instant('billing.history.invoiceStatus.' + status);
            } catch (e) {
                console.warn('translate error invoiceStatus', e);
            }
        }

        function translateCustomerSegment(customerSegment) {
            try {
                return $translate.instant('orgManagement.customerSegment.' + customerSegment + '.full');
            } catch (e) {
                console.warn('translate error customer segment', e);
            }
        }

        function savePayment(p) {
            PaymentService.adminUpdate({id: p.id, status: p.status},
                function (result) {
                    addPaymentAlert('success', 'Bezahl-Status gesichert! Zahlung ' + p.confirmationId + ' hat nun Status ' + p.status + ' resp. ' + translateStatus(p.status));
                },
                function () {
                    addPaymentAlert('danger', 'Sicherung Bezahl-Status fehlgeschlagen! Die Sicherung der Zahlung ' + p.confirmationId + ' auf Status ' + p.status + ' konnte nicht durchgeführt werden');
                });
        }

        function closePaymentAlert(index) {
            vm.paymentAlerts.splice(index, 1);
        }

        function addPaymentAlert(type, msg) {
            vm.paymentAlerts.push({type: type, msg: msg});
        }

        function createResetLink() {
            vm.resetLink = $location.$$absUrl.toString().split($location.$$url)[0];
            vm.resetLink += '/reset/finish?key=' + vm.user.resetKey;
        }

        function reloadAllData() {
            vm.requestFreeMonthUpdate = false;
            vm.saved = false;
            vm.masterTime = moment();
            Organisation.adminGet({id: vm.organisation.id}, function (result) {
                console.log(result);
                entity = result;
                loadData();
                loadAllApiKeys();
                vm.requestFreeMonthUpdate = true;
            }, function (error) {
                console.error(error);
            });
        }

        function loadData() {
            vm.createNewInvoicePdf = false;
            vm.updateLatestInvoicePdf = false;

            vm.disableCreatingCheckButton = false;
            vm.disableUpdateCheckButton = false;

            if (vm.isEdit) {
                entity.zipCode = parseInt(entity.zipCode);
                // console.log(entity)
                vm.organisation = entity;
                vm.billingContactEqualsOrgContact = vm.organisation.billingContact == null;
                vm.user.firstName = entity.contactFirstName;
                vm.user.lastName = entity.contactLastName;
                vm.user.email = entity.contactEmail;
                vm.user.resetKey = entity.resetKey;
                vm.organisation.paymentMeans = entity.paymentMeans;
                vm.numAdditionalUsers = entity.numAdditionalUsers;
                vm.licenseState = entity.licenseState;
                vm.organisationCats = entity.withdrawalProductTypes;
                vm.dateTimeValidUntil = moment(entity.validUntil);
                vm.dateTimeFreeUntil = moment(entity.freeUntil);

                if ((entity.dateTimeInactive !== null)) {
                    vm.dateTimeInactive = moment(entity.dateTimeInactive);
                } else {
                    vm.dateTimeInactive = null;
                }
                createResetLink();
                // fetch payments
                loadPayments();
                // fetch all base licenses
                loadBaseLicenses();
            } else {
                vm.organisation = entity;
                vm.user.authority = 'ROLE_USER';
                vm.user.organisationRole = 'ORG_ADMIN';
                vm.user.langKey = 'de';
                vm.user.activated = true;
                vm.licenseState = 'FREE';
            }
        }

        function loadAllApiKeys() {
            var reqDto = {
                orgId: vm.organisation.id
            };
            LuucyMgmtService.adminKeys(
                reqDto,
                function(response) {
                    vm.keys.push.apply(vm.keys, response.data.keys);
                },
                function() {
                    console.warn("Unable to get keys");
                }
            );
        }

        function loadPayments() {
            PaymentService.adminQuery({orgId: vm.organisation.id},
                function (response) {
                    vm.payments = [];
                    response.forEach(function(p) {
                        p.showMails = false; // default false
                        vm.payments.push(p);
                    });
                }, function (error) {
                    console.error("Failed to receive payments");
                });
        }

        function loadBaseLicenses() {
            BaseLicenseService.all().$promise.then(
                function (res) {
                    vm.baseLicenses = res;
                    loadCurrentLicense();
                }
            )

        }

        function loadCurrentLicense() {
            var dto = {
                orgId: vm.organisation.id
            };
            WithdrawalProductService.current_for_admin(dto).$promise.then(
                function (res) {
                    vm.curWp = res;
                    // postprocess datetimes
                    vm.curWp.dateTimeValidUntil = moment(vm.curWp.dateTimeValidUntil);
                    vm.curWp.dateTimeFreeUntil = moment(vm.curWp.dateTimeFreeUntil);

                    var arr = vm.baseLicenses.filter(function (v) {
                        return v.id === res.baseLicenseId;
                    });
                    vm.curWp.baseLicense = arr[0];
                }
            );
            WithdrawalProductService.pending_for_admin(dto).$promise.then(
                function (res) {
                    if (res.withdrawalProductTypes) {
                        vm.pendingWp = res;

                        vm.pendingWp.dateTimeValidUntil = vm.pendingWp.dateTimeValidUntil ? moment(vm.pendingWp.dateTimeValidUntil) : undefined;
                        vm.pendingWp.dateTimeFreeUntil = vm.pendingWp.dateTimeFreeUntil ? moment(vm.pendingWp.dateTimeFreeUntil) : undefined;
                        vm.pendingWp.dateTimeActive = vm.pendingWp.dateTimeActive ? moment(vm.pendingWp.dateTimeActive) : undefined;

                        vm.pendingLicenseState = res.licenseState;

                        var arr = vm.baseLicenses.filter(function(v) {
                            return v.id === res.baseLicenseId;
                        });
                        vm.pendingWp.baseLicense = arr[0];
                    } else {
                        vm.pendingWp = null;
                    }
                }
            );
        }

        function createCustomLicense() {
            vm.isCreatingCustomLicense = false;
            BaseLicenseService.createCustomLicense(vm.customLicense).$promise.then(
                function (res) {
                    vm.baseLicenses.push(res);
                    vm.curWp.baseLicense = res;
                    startCreateCustomLicense();
                },
                function (err) {
                    vm.customLicenseManipulationFailed = true;
                }
            );
        }

        function deleteCustomLicense() {
            if (vm.baseLicensesToDelete) {
                var dto = {id: vm.baseLicenseToDelete.id};
                BaseLicenseService.deleteCustomLicense(dto).$promise.then(
                    function (res) {
                        startDeleteCustomLicense();
                    },
                    function (err) {
                        vm.customLicenseManipulationFailed = true;
                    }
                );
            }
        }

        function startCreateCustomLicense() {
            vm.customLicenseManipulationFailed = false;
            vm.isDeletingCustomLicense = false;
            vm.isCreatingCustomLicense = !vm.isCreatingCustomLicense;
        }

        function startDeleteCustomLicense() {
            vm.customLicenseManipulationFailed = false;
            vm.isCreatingCustomLicense = false;
            vm.isDeletingCustomLicense = !vm.isDeletingCustomLicense;
            vm.baseLicensesToDelete = [];
            //vm.baseLicenseToDelete = null;
            // fetch orphaned base licenses
            BaseLicenseService.fetchOrphanedBaseLicenses().$promise.then(
                function (res) {
                    vm.baseLicensesToDelete = res;
                }
            );
        }

        function _standardizeWp(copy) {
            copy.dateTimeValidUntil = copy.dateTimeValidUntil ? copy.dateTimeValidUntil.toISOString() : undefined;
            copy.dateTimeFreeUntil = copy.dateTimeFreeUntil ? copy.dateTimeFreeUntil.toISOString() : undefined;
            copy.dateTimeActive = copy.dateTimeActive ? copy.dateTimeActive.toISOString() : undefined;

            var arr = vm.baseLicenses.filter(function (v) {
                return v.id === copy.baseLicense.id;
            });
            copy.baseLicenseId = arr[0].id;
            return copy;
        }

        function updateLicense() {
            if (!vm.curWp.numMaxUsers) {
                addPaymentAlert('danger', 'Lizenz Informationen (Aktuelle Periode) NICHT aktualisiert. Feld "Total Accounts" muss ausgefüllt sein.');
                return;
            }
            $timeout(function () {
                var copy = angular.copy(vm.curWp);
                copy = _standardizeWp(copy);

                WithdrawalProductService.adminUpdate({}, copy, function (result) {
                    vm.organisationCats = copy.withdrawalProductTypes;
                    vm.organisation.paymentMeans = copy.paymentMeans;
                    loadCurrentLicense();
                    addPaymentAlert('success', 'Lizenz Informationen (Aktuelle Periode) aktualisiert');
                });
            });
        }

        function updatePendingLicense() {
            var copy = angular.copy(vm.pendingWp);
            copy = _standardizeWp(copy);

            WithdrawalProductService.adminUpdate({}, copy, function (result) {
                loadCurrentLicense();
                addPaymentAlert('success', 'Lizenz Informationen (Nächste Periode) aktualisiert');
            });
        }

        function validateCustomLicense() {
            return !!vm.customLicense.numMaxEmployees && !!vm.customLicense.numMinEmployees &&
                !!vm.customLicense.monthlyBaseFee && !!vm.customLicense.name;
        }

        function headerChooseLicense() {
            if (!vm.isEdit) {
                return $translate.instant('orgManagement.header.chooseLicense');
            } else {
                return $translate.instant('orgManagement.header.editLicense');
            }
        }

        function createRemoveUsers() {
            var n = $location.$$absUrl.lastIndexOf($location.$$url);
            var contextPath = $location.$$absUrl.slice(0, n);

            var dto = {
                successUrl: contextPath + "/saferpay-success",
                failUrl: contextPath + "/saferpay-error",
                abortUrl: contextPath + "/saferpay-abort",
                payerLanguageCode: "DE",
                numUsersToRemove: vm.numRemoveUsersProcess,
                currentNumMaxUsers: vm.curWp.numMaxUsers
            };
            //TODO Payment2020
            BillingService.adminUpdateLicenseUsers(
                dto,
                vm.organisation.id,
                function (res) {
                    console.warn(res);
                },
                function (error) {
                    console.error(error);
                });
        }

        function createDifferentialInvoiceAdditionalUsers() {
            var n = $location.$$absUrl.lastIndexOf($location.$$url);
            var contextPath = $location.$$absUrl.slice(0, n);

            var dto = {
                successUrl: contextPath + "/saferpay-success",
                failUrl: contextPath + "/saferpay-error",
                abortUrl: contextPath + "/saferpay-abort",
                payerLanguageCode: "DE",
                numAdditionalUsers: vm.numAdditionalUsersProcess,
                currentNumMaxUsers: vm.curWp.numMaxUsers,
            };
            //TODO Payment2020
            BillingService.adminUpdateLicenseUsers(
                dto,
                vm.organisation.id,
                function (res) {
                    console.warn(res);
                },
                function (error) {
                    console.error(error);
                });
        }

        function showMails(payment) {
            payment.showMails = true;
        }

        function hideMails(payment) {
            payment.showMails = false;
        }

        function sendPaymentEmail(payment) {
            // TODO: backend call to send the proper email
            var forceSendDTO = {
                paymentId: payment.id,
                invoiceStatus: payment.invoiceStatus,
                ignoreSendEmail: false
            };
            PaymentService.forceSendPaymentEmail(
                forceSendDTO,
                function(result) {
                    vm.payments.filter(function(value, index, array) {
                        if (forceSendDTO.paymentId == result.id) {
                            // if(vm.payments[index].showMails) result.showMails = true;
                            vm.payments[index] = result;
                        }
                    });
                    if (forceSendDTO.invoiceStatus !== 'OVERDUE') {
                        addPaymentAlert('success', 'Rechnung wird versendet..');
                    }
                }, function(error) {
                    console.error(error);
                    addPaymentAlert('danger', 'Rechnung konnte nicht versendet werden');
                });
        }

        function forceSetInvoiceState(payment) {
            var forceSendDTO = {
                paymentId: payment.id,
                invoiceStatus: payment.invoiceStatus,
                ignoreSendEmail: true
            };
            PaymentService.forceSendPaymentEmail(
                forceSendDTO,
                function(result) {
                    vm.payments.filter(function(value, index, array) {
                        if (forceSendDTO.paymentId == result.id) {
                            // if(vm.payments[index].showMails) result.showMails = true;
                            vm.payments[index] = result;
                        }
                    });
                }, function(error) {
                    console.error(error);
                    addPaymentAlert('danger', 'Status konnte nicht updated werden');
                });
        }

        function billOrganisationNow(){
            BillingService.billLicense(vm.organisation.id, function (res) {
               console.log(res);
            });
        }

    }
})();



