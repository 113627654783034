(function() {
    'use strict';
    angular.module('kennwerteApp.agv')
        .service('SmallBuildingAgvSliderPresetService', SmallBuildingAgvSliderPresetService);

    SmallBuildingAgvSliderPresetService.$inject = ['$rootScope', '$timeout',
        'SmallBuildingAgvUsageSliderMappingService', 'SmallBuildingAgvUsageQuantilesService', 'SmallBuildingService', 'AgvSliderIdentMappingService'];

    /**
     * This service delivers functionality to update sliders depending on usage.
     * @returns {{test: {}, setDefaultSlider: (function(*): *), setSliderModelValuesArr: setSliderModelValuesArr, correctUsages: correctUsages}}
     * @constructor
     */
    function SmallBuildingAgvSliderPresetService($rootScope, $timeout,
        SmallBuildingAgvUsageSliderMappingService, SmallBuildingAgvUsageQuantilesService, SmallBuildingService, AgvSliderIdentMappingService) {

        var service = {
            setDefaultSliderModelValues: setDefaultSliderModelValues,
            setSliderModelValuesArr: setSliderModelValuesArr,
            correctUsages: correctUsages,
            getWeightedPresetValues: getWeightedPresetValues,
            getWeightedPresetIntervals: getWeightedPresetIntervals,
            requestPresetEvent: requestPresetEvent,
            valueClassPreset: 0,
        };

        /*
         * Set default values for Small Building (only WK slider)
         */
        function setDefaultSliderModelValues(realEstate) {

            if (realEstate.metaData.withdrawalProductType === 'SMALL_BUILDING_AGV') {
                realEstate.agv.wk = 'WK2';
            }

            return realEstate;
        }

        /**
         * This function sets value marks for the right preset for the sliders.
         * @param preset_val array containing wk positions (may be float [0, 7]) for each slider except Vorfertigung - sliders sorted by
         *  [Umgebung, Grundstueck, Vorbereitungsarbeiten, FensterAnteil, Gebaeudeform2, ElektroAnlage, Heizungsanlage,
         *  Sanitaeranlage, Ausbau1, Ausbau2]
         *  @param preset_i_arr array containing interval positions (array of floats) for each slider except Vorfertigung - sliders sorted like arr.
         * @param realEstateContainer real estate data
         * @param isPresetSliderModelValuesEnabled if true, the slider values will be set as well (to the same value as the value marks).
         */
        function setSliderModelValuesArr(preset_val, preset_i_arr, realEstateContainer, isPresetSliderModelValuesEnabled) {
            if (isPresetSliderModelValuesEnabled) {
                realEstateContainer.agv.wk = AgvSliderIdentMappingService.getSliderIdentByMore(preset_val);
            }

            $timeout(function() {
                setPresetMark('realEstateContainer.agv.wk', preset_val, preset_i_arr);
            });
        }

        function correctUsages(usages) {
            if (typeof usages !== 'undefined' && usages.length > 0 && usages[0].type) {
                if (usages.length === 1) {
                    return usages;
                } else if (usages.length === 2 && (angular.isUndefined(usages[1].type) || usages[1].type === null || !usages[1].type)) {
                    return [usages[0]];
                } else if (usages.length > 1 && isUsagesReady(usages)) {
                    $rootScope.$broadcast('showSliderPresetTooltip');
                    return usages;
                    // Use outcommented code below if you want to remove VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN from usages
                    /*
                    $rootScope.$broadcast('showSliderPresetTooltip');
                    var correctedUsages = [];
                    for (var usage in usages) {
                        var usageObj = usages[usage];
                        if (usageObj.type !== 'VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN') {
                            correctedUsages.push(usageObj);
                        }
                    }
                    return correctedUsages;
                     */
                }
            } else {
                return undefined;
            }
        }

        function setPresetMark(referenceStr, value, interval) {
            $rootScope.$broadcast('setPresetMarkAgv', {
                referenceStr: referenceStr,
                value: value,
                interval: interval
            });
        }

        function arr_applyWeight(weightedArrEntry, entry, weight, isMatrix) {
            if (isMatrix) {
                // case entry and weightedArrEntry are of type Float32Array and have same size
                return weightedArrEntry.map(function(wae, i) {
                    if (weight === 0) {
                        return wae;
                    } else {
                        return wae + weight * entry[i];
                    }
                });
            } else {
                // case entry and weightedArrEntry are of type float
                if (weight === 0) {
                    return weightedArrEntry;
                } else {
                    return weightedArrEntry + weight * entry;
                }
            }
        }

        function arr_div(weightedArrEntry, dividend, isMatrix) {
            if (isMatrix) {
                // case weightedArrEntry is of type Float32Array
                return weightedArrEntry.map(function(wae) {
                    return wae / dividend;
                });
            } else {
                return weightedArrEntry / dividend;
            }
        }

        function arr_createArr(size, isMatrix) {
            if (isMatrix) {
                // in case the return array contains Float32Array's as elements
                return new Array(size).fill(new Float32Array(7));
            } else {
                return new Float32Array(size);
            }
        }

        function getWeightedPresetValues(usages) {
            return getWeightedPresetValuesOrIntervals(usages, false);
        }

        function getWeightedPresetIntervals(usages) {
            return getWeightedPresetValuesOrIntervals(usages, true);
        }

        function getWeightedPresetValuesOrIntervals(usages, isIntervals) {
            // usages: array containing usage objects. Each usage object contains percentage and type.
            // isIntervals: Indicates whether intervals (FloatArrays := Intervals) or single values (Double := Non intervals) are preset entries.
            // If isIntervals is true => the preset entry is a FloatArray consisting of 5 entries [lower whisker, p25, p50, p75, upper whisker]
            var usageSliderMapping = undefined;
            if (isIntervals) {
                // deep copy
                usageSliderMapping = SmallBuildingAgvUsageQuantilesService.getMapping();
            } else {
                usageSliderMapping = SmallBuildingAgvUsageSliderMappingService.getMapping();
            }

            var arrs = [];
            var weights = [];
            for (var usage in usages) {
                var usageObj = usages[usage];
                var arr = usageSliderMapping[usageObj.type];
                if (arr) {
                    arrs.push(arr);
                    if (usageObj.percentage) {
                        weights.push(parseInt(usageObj.percentage));
                    }
                }
            }
            if (arrs.length > 0) {
                var weightedArr = arr_createArr(arrs[0].length, isIntervals);
                if (weights.length > 1) {
                    var sumWeightsArr = new Float32Array(arrs[0].length).fill(0);
                    for (var i = 0; i < weights.length; i++) {
                        var curArr = arrs[i];
                        var curWeight = weights[i] / 100;
                        if (angular.isUndefined(curWeight)) {
                            curWeight = 1;
                        }
                        for (var j = 0; j < curArr.length; j++) {
                            //weightedArr[j] = weightedArr[j] + curWeight * curArr[j];
                            var curWeightInner = curWeight;
                            if (isIntervals && (!Array.isArray(curArr[j]) || !curArr[j].length)) {
                                // curArr: Array does not exist, is not an array or is empty
                                // this is an expected case that occurs on usages the boxplot calculation should not be based on, e.g. Tiefgarage
                                curWeightInner = 0;
                                curArr[j] = new Float32Array(arrs[0].length).fill(0);
                            }
                            if (!isIntervals) {
                                var w = isNaN(curArr[j]) ? 0 : curWeightInner;
                                sumWeightsArr[j] += w;
                                weightedArr[j] = arr_applyWeight(weightedArr[j], curArr[j], w, isIntervals);
                            } else {
                                w = curWeightInner;
                                sumWeightsArr[j] += w;
                                weightedArr[j] = arr_applyWeight(weightedArr[j], curArr[j], w, isIntervals);
                            }

                        }
                    }
                    for (var i = 0; i < weightedArr.length; i++) {
                        weightedArr[i] = arr_div(weightedArr[i], sumWeightsArr[i], isIntervals);
                    }
                } else {
                    weightedArr = arrs[0];
                }
                return weightedArr;
            }
        }

        function isUsagesReady(usages) {
            try {
                var totalPercentage = 0;
                for (var usage in usages) {
                    var usageObj = usages[usage];
                    if (!angular.isUndefined(usageObj.type) && usageObj.type !== null && usageObj.type !== "") {
                        if (!usageObj.percentage) {
                            // usages set, but no percentage set => usages is not ready
                            return false;
                        } else {
                            totalPercentage += parseInt(usageObj.percentage);
                        }
                    }
                }
                return totalPercentage === 100;
            } catch (error) {
                return false;
            }
        }

        function requestPresetEvent(realEstateContainer, referenceStr) {
            var correctUsages = this.correctUsages(realEstateContainer.agv.usages);
            if (correctUsages !== undefined) {
                var preset_val = this.getWeightedPresetValues(correctUsages, realEstateContainer);
                var preset_i_arr = this.getWeightedPresetIntervals(correctUsages);
                switch (referenceStr) {
                //add other sliders here.
                case 'realEstateContainer.agv.wk':
                    var agvWK = AgvSliderIdentMappingService.getSliderIdentByMore(preset_val);
                    var i_agvWK = preset_i_arr[0];
                    return { preset: agvWK, interval: i_agvWK };
                default:
                    return { preset: null, interval: null };
                }
            } else {
                return { preset: null, interval: null };
            }
        }

        return service;
    }
})();
