(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('ecoStandardSelect', EcoStandardSelectDirective)
        .directive('uiCheckboxFocuser', UiCheckboxFocuser)
        .controller('EcoStandardController', EcoStandardController);

    EcoStandardController.$inject = ['$scope', '$rootScope', '$timeout', 'ecoStandardGroupFactory', '$translate'];

    function EcoStandardController($scope, $rootScope, $timeout, ecoStandardGroupFactory, $translate) {
        var vm = this;
            //this is for visual purpose so the client can show how much options are selected.
        vm.counter = 0;

        vm.selectedMINERG;
        vm.pv = false;
        vm.selectedENERG = [];

        vm.translateKey = function (postfix) {
            try {
                return $translate.instant('realEstate.complexity.energy-standard.' + postfix + '.label');
            } catch (e) {
                console.warn('energy-standard.',e);
            }
        };

        vm.updateDescriptionAndImage = function () {
            if (!$scope.firstLoad) {
                $scope.reference = vm.selectedENERG;
                $scope.minergieReference = vm.selectedMINERG;
                $scope.referencePv = vm.pv;

                $rootScope.$broadcast("updateDescription", {
                    rowid: $scope.rowid,
                    descriptionBaseKey: $scope.descriptionBaseKey
                });
                if (vm.model.id == 0 || vm.model.id > 100) {
                    $rootScope.$broadcast("updateImage", {
                        rowid: $scope.rowid,
                        imageSrc: $scope.image
                    });
                } else {
                    $rootScope.$broadcast("updateImage", {
                        rowid: $scope.rowid,
                        imageSrc: $scope.image
                    });
                }
            } else {
                $scope.firstLoad = false;
            }
        };

        vm.onOpenClose = function (isOpen) {
            if (isOpen) {
                vm.updateDescriptionAndImage();
            }
        };

        vm.onSelect = function (selectedItem) {
            selectedItem.isSelected = !selectedItem.isSelected;
            if (selectedItem.isSelected) {
                switch (selectedItem.ref) {
                case 'MINERG':
                    vm.selectedMINERG = selectedItem.title;
                    $scope.groups.map(function (current) {
                        if (selectedItem !== current && selectedItem.ref === current.ref) {
                            current.isSelected = false;
                        }
                    });
                    break;
                case 'ENERG':
                    vm.selectedENERG.push(selectedItem.title);
                    break;
                case 'PV':
                    vm.pv = true;
                    break;
                }
            } else {
                switch (selectedItem.ref) {
                case 'MINERG':
                    vm.selectedMINERG = null;
                    break;
                case 'ENERG':
                    vm.selectedENERG.splice(vm.selectedENERG.indexOf(selectedItem.title), 1);
                    break;

                case 'PV':
                    vm.pv = false;
                    break;
                }
            }
            vm.updateDescriptionAndImage();
            vm.updateCounter();
        };

        vm.model = ecoStandardGroupFactory.getByTitle($scope.reference);
        if (vm.model.id) {
            vm.model = {
                "id": vm.model.id,
                "title": vm.model.title,
                "parent": vm.model.parent,
                "translated": vm.translateKey(vm.model.title),
                "isSelected": false
            };
        } else {
            vm.model = {
                "id": 0
            };
        }

        vm.updateCounter = function () {
            vm.counter = vm.selectedENERG.length;
            vm.counter += vm.selectedMINERG != null ? 1 : 0;
        };

            /**
             * This watch checks if the reference changes and updates the whole component.
             * This can happen if we load data from the db async.
             */
        $scope.$watch('reference', function (newValue) {

                //not working is newvalue not defined
            if (newValue && angular.isDefined(newValue)) {
                vm.selectedENERG = $scope.reference;
                angular.forEach($scope.reference, function (value, key, array) {
                    var idx = $scope.groups.findIndex(function (current) {
                        return value == current.title;
                    });
                    $scope.groups[idx].isSelected = true;
                });
                vm.updateCounter();
            }
        });

        $scope.$watch('minergieReference', function (newValue) {
            if ($scope.minergieReference && newValue && angular.isDefined(newValue)) {
                vm.selectedMINERG = $scope.minergieReference;
                var idx = $scope.groups.findIndex(function (current) {
                    return $scope.minergieReference == current.title;
                });
                if (idx) {
                    $scope.groups[idx].isSelected = true;
                }
                vm.updateCounter();
            }
        });

        $scope.$watch('referencePv', function (newValue) {
            if (angular.isDefined(newValue)) {
                vm.pv = $scope.referencePv;
                var idx = $scope.groups.findIndex(function (current) {
                    return $scope.referencePv == current.title;
                });
                if (idx) {
                    $scope.groups[idx].isSelected = $scope.referencePv;
                }
                vm.updateCounter();
            }
        });
    }

    EcoStandardSelectDirective.$inject = ['$rootScope', '$timeout', 'ecoStandardGroupFactory', '$translate'];

    function EcoStandardSelectDirective($rootScope, $timeout, ecoStandardGroupFactory, $translate) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                minergieReference: '=',
                rowid: '=',
                image: '=image',
                required: '=required',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@?',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                isOperationalCosts: '@?',
                dynamicTranslations: '@?'
            },
            templateUrl: 'app/components/eco_standard/eco-standard-select.tpl.html',
            controller: EcoStandardController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, ctrl) {

                scope.translateKey = function (postfix) {
                    try{
                        return $translate.instant('realEstate.complexity.energy-standard.' + postfix + '.label');
                    } catch (e) {
                        console.warn('energy-standard.',e);
                    }
                };
                var enhanceGroupFn = function (gs) {
                    for (var i in scope.groups) {
                        var oldg = scope.groups[i];
                        oldg.noshow = true;
                    }
                    var enhancedGroups = [];
                    for (var j in gs) {
                        var group = gs[j];
                        enhancedGroups.push({
                            "id": group.id,
                            "title": group.title,
                            "parent": group.parent,
                            "translated": scope.translateKey(group.title),
                            "isSelected": group.isSelected,
                            "ref": group.ref
                        });
                    }
                    scope.groups = enhancedGroups;
                };


                    // add property translated to to each group in groups
                ctrl.resetGroups = function () {
                    var gs = ecoStandardGroupFactory.load(0);
                    enhanceGroupFn(gs);
                };

                ctrl.resetGroups();

                    // Used for the validation
                var toggle = tElement.find(".Energydrop2downOuter");
                $(toggle).attr("id", tAttrs.reference + ".drop2down-toggle");
            }
        };
    }

    UiCheckboxFocuser.$inject = ['$timeout'];

    function UiCheckboxFocuser($timeout) {
        return {
            restrict: 'A',
            require: '^uiSelect',
            link: function (scope, elem, attrs, uiSelect, ctrl) {
                scope.$on('uiSelectClose', function () {
                    uiSelect.close();
                });

                //this overwrites the function isActive in
                // ui-select
                // check select.js in bower_components (codeLine 615)
                uiSelect.isActive = function (item) {
                    return false;

                };
            }
        };
    }


}

)();
