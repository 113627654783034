(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams',
        'paginationConstants', 'JhiLanguageService', '$scope'];

    function UserManagementController(Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants,
                                      JhiLanguageService, $scope) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.users = [];
        vm.page = pagingParams.page;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.loadPage1 = loadPage1;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.isOrganisation = isOrganisation;
        vm.hasOrgOwner = hasOrgOwner;

        vm.userTypes = ["USER", "ORGANISATION"];
        vm.selectedUserTypes = vm.userTypes;

        vm.loadAll();
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });

        function setActive(user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function loadAll() {
            User.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                userTypes: vm.selectedUserTypes,
                sort: sort()
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.users = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear() {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                credits: null, creditsExpirationDate: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            //vm.transition();
        }

        function loadPage1() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ';' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function hasOrgOwner(user) {
            return user.organisationOwnerId;
        }

        function isOrganisation(user) {
            if (user.email.startsWith('org#') && user.email.endsWith("@org") && user.activated) {
                return true;
            } else {
                return false;
            }
        }

        $scope.$watch('vm.selectedUserTypes', function (userTypes) {
            loadAll();
        }, true);

        $scope.$watch('vm.selectedSaleTypes', function (saleTypes) {
            loadAll();
        }, true);
    }
})();
