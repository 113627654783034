(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('LCCIntegrationService', LCCIntegrationService);

    LCCIntegrationService.$inject = ['Principal'];

    function LCCIntegrationService(Principal) {

        var lccEnabledInBuildingCosts = false;
        var lccEnabledInRebuildCosts = false;
        var lccEnabledInOperatingCosts = false;

        // change modulesRequired during beta.
        var modulesRequiredForLCCFromBuildingCosts = ["BUILDING_COSTS_AND_DATES"]; // ["OPERATING_COSTS", "BUILDING_COSTS_AND_DATES", "REBUILD_COSTS"];
        var modulesRequiredForLCCFromRebuildCosts = ["REBUILD_COSTS"]; // ["OPERATING_COSTS", "REBUILD_COSTS"];
        var modulesRequiredForLCCFromOperatingCosts = ["OPERATING_COSTS"]; // ["OPERATING_COSTS", "REBUILD_COSTS"];

        var service = {
            lccEnabledInBuildingCosts: lccEnabledInBuildingCosts,
            lccEnabledInRebuildCosts: lccEnabledInRebuildCosts,
            lccEnabledInOperatingCosts: lccEnabledInOperatingCosts,
            init: init,
            modulesRequiredForLCCFromBuildingCosts: modulesRequiredForLCCFromBuildingCosts,
            hasModulesRequiredForLCCFromBuildingCosts: hasModulesRequiredForLCCFromBuildingCosts,
            hasModulesRequiredForLCCFromRebuildCosts: hasModulesRequiredForLCCFromRebuildCosts,
        };

        function init(realEstateContainer) {
            var realEstateContainerWithLCC = realEstateContainer.metaData.tasks.indexOf('LCC') >= 0;
            service.lccEnabledInBuildingCosts = realEstateContainerWithLCC && hasModulesRequiredForLCCFromBuildingCosts();
            service.lccEnabledInRebuildCosts = realEstateContainerWithLCC && hasModulesRequiredForLCCFromRebuildCosts();
            service.lccEnabledInOperatingCosts = realEstateContainerWithLCC && hasModulesRequiredForLCCFromOperatingCosts();
        }

        function hasModulesRequiredForLCCFromBuildingCosts() {
            return Principal.hasModules(modulesRequiredForLCCFromBuildingCosts);
        }

        function hasModulesRequiredForLCCFromRebuildCosts() {
            return Principal.hasModules(modulesRequiredForLCCFromRebuildCosts);
        }

        function hasModulesRequiredForLCCFromOperatingCosts() {
            return Principal.hasModules(modulesRequiredForLCCFromOperatingCosts);
        }

        return service;
    }

})();
