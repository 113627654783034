(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('SliderIdentMappingService', SliderIdentMappingService);

    SliderIdentMappingService.$inject = [];

    function SliderIdentMappingService() {
        var valuesMore = [0, 1, 2, 3, 4, 5, 6, 7];
        //[WK2,WK4,WK6]
        var valuesLess = [1, 3, 5];
        return {
            /**
             * Gets the slider ident (WK1, WK2, et.c) by the system considering "more" values (up to 8 wk)
             * @param value
             * @returns {string}
             */
            getSliderIdentByMore: function(value) {
                return valuesMore[Math.round(value)];
            },
            /**
             * Gets the slider ident (WK2, WK4, WK6) by the system considering "less" values (up to 3 wk)
             * @param value (index of value in array)
             * @returns {number} returning number of WK - creating the value would be: WK + {number}
             */
            getSliderIdentByLess: function (value) {
                return valuesLess[Math.round(value)];
            },
            getSliderValueIndexByMore: function (value) {
                var val = Number(value.slice(-1)) - 1;
                return valuesMore.indexOf(val);
            },
            getSliderValueIndexByLess: function (value) {
                var val = Number(value.slice(-1)) - 1;
                return valuesLess.indexOf(val);
            },
            getValuesByLess: function () {
                return valuesLess;
            },
            getValuesByMore: function() {
                return valuesMore;
            },
            setValuesByLess: function(values) {
                valuesLess = values;

            },
            setValuesByMore: function(values) {
                valuesMore = values;
            },
            /**
             * this returns the value but not the Ordinal position (E.G. WK1 -> 1 and not the ordinal val: 0)
             * @param value WK1-WK8.
             * @returns {number} WK1 -> 1 if it is the less ('min' slider with only 3 values) we add +1 value so it isn't exactly extracting the value.
             *
             */
            extractValue: function(value, isMaxValue) {
                if (isMaxValue) {
                    return Number(value.slice(-1));
                } else {
                    var val = Number(value.slice(-1)) - 1;
                    return valuesLess.indexOf(val)+1;
                }
            }
        };

    }

})();
