(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildEstateDataService', RebuildEstateDataService);

    RebuildEstateDataService.$inject = ['$q', '$rootScope', '$sessionStorage', 'RebuildEstateService', 'RealestateService', 'RebuildTotalMeasureService', 'RebuildComponentSliderService'];

    function RebuildEstateDataService($q, $rootScope, $sessionStorage, RebuildEstateService, RealestateService, RebuildTotalMeasureService, RebuildComponentSliderService) {

        var service = {
            rebuildEstate: {},
            new: createEmptyRebuildEstate,
            newDamage: createDamageEstate,
            get: getRebuildEstate,
            set: setRebuildEstate,
            save: saveRebuildEstate,
            load: loadRebuildEstate,
            saveToSession: saveRebuildEstateToSession,
            clearSession: clearRebuildEstateFromSessionStorage,
            loadFromSession: loadRebuildEstateFromSession,
            postProcessForEditRecordById: postProcessForEditRecordById
        };

        function getRebuildEstate() {
            return service.rebuildEstate;
        }

        /**
         * Set rebuildEstate outside due to different loading style e.g. admin special load.
         * @param dm domain model / dto from backend.
         * @param addPrototypes if dm is still raw from backend we need to add the prototypes so ComponentMeasures works correctly.
         */
        function setRebuildEstate(dm, addPrototypes) {
            if (addPrototypes) {
                var rebuildEstate = addJsPrototypes(dm);
                service.rebuildEstate = rebuildEstate;
            } else {
                service.rebuildEstate = dm;
            }
        }

        function saveRebuildEstate() {
            if (service.rebuildEstate.id) {
                RealestateService.api.update({id: service.rebuildEstate.id}, service.rebuildEstate,
                    function (updatedRebuildEstate) {
                        service.rebuildEstate = angular.copy(updatedRebuildEstate);
                    });
            } else {
                RealestateService.api.create({}, service.rebuildEstate, function (createdRebuildEstate) {
                    service.rebuildEstate = angular.copy(createdRebuildEstate);
                }, function (error) {
                    console.log(error);
                });
            }
        }

        function addJsPrototypes(rebuildEstate) {
            // set prototype for component measures.
            rebuildEstate.analysis.components.forEach(function(component) {
                if (component.totalMeasure != null)
                    Object.setPrototypeOf(component.totalMeasure, ComponentMeasures.prototype);
                if (component.mainModernizationMeasure != null)
                    Object.setPrototypeOf(component.mainModernizationMeasure, ComponentMeasures.prototype);
                if (component.modificationMeasure != null)
                    Object.setPrototypeOf(component.modificationMeasure, ComponentMeasures.prototype);
                RebuildComponentSliderService.setRemainingValueInterval(component);
            });
            // Recalculate total measure
            rebuildEstate.analysis.components.forEach(function (component) {
                component.totalMeasure = RebuildTotalMeasureService.calculateTotalMeasure(component, rebuildEstate);
            });
            return rebuildEstate;
        }

        function saveRebuildEstateToSession() {
            $sessionStorage.rebuildEstate = service.rebuildEstate;
        }

        function loadRebuildEstateFromSession() {
            console.info("loadRebuildEstateFromSession");
            service.rebuildEstate = addJsPrototypes(angular.copy($sessionStorage.rebuildEstate));
        }

        function clearRebuildEstateFromSessionStorage() {
            delete $sessionStorage.rebuildEstate;
        }

        function loadRebuildEstate(id) {
            console.info("loadRebuildEstate(" + id + ")");
            if (id || id === 0) {
                var deferred = $q.defer();
                RealestateService.api.get({id: id}, function (loadedRebuildEstate) {
                    service.rebuildEstate = addJsPrototypes(angular.copy(loadedRebuildEstate));
                    deferred.resolve(service.rebuildEstate);
                }, function (data) {
                    deferred.reject('Rejected: ' + data.status + ' RebuildEstate with ID:' + id);
                });
                return deferred.promise;
            } else {
                throw new Error('id must be provided');
            }
        }

        function createEmptyRebuildEstate() {
            console.info("createEmptyRebuildEstate");
            var deferred = $q.defer();
            RebuildEstateService.getEmpty({}, function (createdRebuildEstate) {
                service.rebuildEstate = angular.copy(createdRebuildEstate);
                service.rebuildEstate.usages.push({ type: null, percentage: null, standard: null });
                service.rebuildEstate.additions.push({});
                service.rebuildEstate.targetOverhaul.usages.push({ type: null, percentage: null, standard: null });
                deferred.resolve(service.rebuildEstate);
            }, function (data, status, headers, config) {
                deferred.rejected('Rejected :', +status);
            });
            return deferred.promise;
        }

        function createDamageEstate() {
            console.info("createEmptyDamageEstate");
            var deferred = $q.defer();
            RebuildEstateService.getEmptyDamage({}, function (createdRebuildEstate) {
                service.rebuildEstate = angular.copy(createdRebuildEstate);
                service.rebuildEstate.usages.push({ type: null, percentage: null, standard: null });
                service.rebuildEstate.additions.push({});
                service.rebuildEstate.targetOverhaul.usages.push({ type: null, percentage: null, standard: null });
                deferred.resolve(service.rebuildEstate);
            }, function (data, status, headers, config) {
                deferred.rejected('Rejected :', +status);
            });
            return deferred.promise;
        }


        function checkAdditions(additions) {
            angular.forEach(additions, function (addition, index) {
                if (addition.bkp == null && addition.value == null && addition.label == null) {
                    additions.splice(index, 1);
                }
            });
        }

        function endsWith(str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function postProcessForEditRecordById(realEstate) {
            /**
             * Helper to postprocess a provided real estate for edit. Initializes empty properties on the real estate such as arrays.
             * @param realEstate real estate object
             * @returns {*}
             */
            realEstate.metaData.parentRealEstateId = realEstate.metaData.parentRealEstateId ?
                realEstate.metaData.parentRealEstateId : realEstate.id;
            if (endsWith(realEstate.metaData.name, ")")) {
                var brBegin = realEstate.metaData.name.lastIndexOf("(");
                if (brBegin >= 0) {
                    var txtBetweenBr = realEstate.metaData.name.substring(brBegin + 1, realEstate.metaData.name.length - 1);
                    var parsedNumBetweenBr = parseInt(txtBetweenBr);
                    if (parsedNumBetweenBr) {
                        realEstate.metaData.name = realEstate.metaData.name.substring(0, brBegin - 1) + " (" + (realEstate.metaData.numEstimations + 1) + ")";
                    }
                }
            } else {
                realEstate.metaData.name = realEstate.metaData.name + " (" + (realEstate.metaData.numEstimations + 1) + ")";
            }
            realEstate.id = null;
            if (realEstate.additions.length === 0) {
                realEstate.additions = [];
                realEstate.additions.push({});
            }
            if (realEstate.investments.length === 0) {
                realEstate.investments = [];
                realEstate.investments.push({});
            }
            return realEstate;
        }

        return service;
    }
})();
