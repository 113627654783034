/*
lookup service for RebuildUsageClusterType.java
 */
(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RbUsageTypeGroupFactory', RbUsageTypeGroupFactory);

    RbUsageTypeGroupFactory.$inject = ['usageTypeGroupFactory'];

    function RbUsageTypeGroupFactory(UsageTypeGroupFactory) {
        var luData = {
            "WOHNBAUTEN__EFH_REIHEN": "RB_WOHNBAUTEN_EFH",
            "WOHNBAUTEN__E_RFH__EFH": "RB_WOHNBAUTEN_EFH",
            "WOHNBAUTEN_BEHELFSWOHNUNGEN": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__STUDENTENWOHNHAEUSER": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__PERSONALHAEUSER": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__MFH": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__TERRASSENHAEUSER": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__EFH_FERIENHAEUSER": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__ALTERSWOHNUNGEN": "RB_WOHNBAUTEN_OTHER",
            "WOHNBAUTEN__ALTERSHEIME": "RB_WOHNBAUTEN_OTHER",

            "SCHULBAUTEN__KINDERGAERTEN_HORTE": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__VOLKSSCHULEN": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__BERUFSSCHULEN": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__AULA": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__HOERSAALGEBAEUDE": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__LEHRE_OHNE_LABOR": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__BIBLIOTHEKEN": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR": "RB_SCHULBAUTEN_OHNE_LABOR",
            "SCHULBAUTEN__SPEZIELLE_FORSCHUNGSBAUTEN": "RB_SCHULBAUTEN_OHNE_LABOR",

            "LANDWIRTSCHAFT__TITLE": "RB_LANDWIRTSCHAFT",
            "LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL": "RB_HANDEL_EINZEL",
            "LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL": "RB_HANDEL_GROSS",
            "BUEROBAUTEN_VERWALTUNGSBAUTEN_DIENSTLEISTUNGSBAUTEN__TITLE": "RB_BUERO",
            "BUERO_HANDELSBAUTEN__BVB": "RB_BUERO",
            "JUSTIZBAUTEN__TITLE": "RB_JUSTIZ",
            "KULTUS__TITLE": "RB_KULTUS",
            "KULTUR__TITLE": "RB_KULTUR",
            "LAGERBAUTEN_MEHRSTOECKIGE": "RB_LAGERBAUTEN",
            "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN": "RB_LAGERBAUTEN_OTHER",
            "LAGERBAUTEN__VERTEILZENTRALEN": "RB_LAGERBAUTEN_OTHER",
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TITLE": "RB_LAGERBAUTEN_OTHER",
            "FREIZEIT_SPORT_ERHOLUNGSBAUTEN": "RB_FREIZEIT",
            "PRODUKTIONSBAUTEN__TITLE": "RB_PRODUKTION",
            "TECHNISCHE_ANLAGEN__TITLE": "RB_TECH_ANLAGEN",
            "GESUNDHEITSBAUTEN__TITLE": "RB_GESUNDHEIT",
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE": "RB_GASTGEWERBE",
            "VERKEHRSBAUTEN__TITLE": "RB_VERKEHRSBAUTEN",
            "SCHUTZBAUTEN__TITLE": "RB_MILITAER"
        };

        function getRbUsageType(usageType) {
            if (usageType in luData) return luData[usageType];
            else {
                var prevGroups = UsageTypeGroupFactory.getAllPreviousGroups(
                    UsageTypeGroupFactory.getByTitle(usageType).id
                );
                var key = prevGroups
                    .map(function(e) { return e.title; })
                    .find(function(e) { return e in luData; });
                return luData[key];
            }
        }

        return {
            getRbUsageType: function (usageType) {
                return getRbUsageType(usageType);
            },
            getRbUsage: function (usage) {
                return getRbUsageType(usage.type);
            }
        };
    }

})();
