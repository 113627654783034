(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('GeometryFormController', GeometryFormController);

    GeometryFormController.$inject = ['$scope', '$stateParams', 'DimensionSheetService', '$filter', 'RoomHeightLimitsWarningService','BuildingCostsActionService'];

    function GeometryFormController($scope, $stateParams, DimensionSheetService, $filter, RoomHeightLimitsWarningService, BuildingCostsActionService) {

        $scope.dimensionSheet = $scope.realEstateContainer.geometry.dimensionSheet;

        $scope.BuildingCostsActionService = BuildingCostsActionService;

        $scope.saveInput = function (data) {
            $scope.realEstateContainer.geometry.dimensionSheet = JSON.parse(JSON.stringify(data));
            $scope.dimensionSheet = $scope.realEstateContainer.geometry.dimensionSheet;
        };

        $scope.showWarningIfOutOfLimit = function() {
            RoomHeightLimitsWarningService.showWarningIfOutOfLimit($scope.realEstateContainer);
        };

        //Tooltip creator.
        var openDimSheetErrorTooltip = function () {
            var message = $filter('translate')("process-form.userFriendlyErrorMessages.NO_USAGE_DIMENSION_SHEET");
            var field = '#btn_calc_realEstate_geometry_totalFloorArea416';
            $(field).addClass("validationError");
            $(field).tooltipster({
                content: message,
                theme: 'tooltipster-kw',
                side: ['right'],
                zIndex: 10000,  //z-index of validationmessages.
                trigger: 'custom',
                plugins: ['sideTip'],
                interactive: true,
                selfDestruction: false,
                repositionOnScroll: true,
                timer: 10000,
                triggerClose: {
                    click: true,
                    scroll: false
                },
                functionAfter: closeDimSheetErrorTooltip('bla')
            }).tooltipster('open');
        };

        var closeDimSheetErrorTooltip = function(bla) {
            var field = '#btn_calc_realEstate_geometry_totalFloorArea416';
            $(field).removeClass("validationError");
            if (typeof field !== 'undefined' && $(field).hasClass("tooltipstered")) {
                $(field).unbind('click');
                $(field).tooltipster('destroy');
            }
        };

        $scope.openDimSheetCalc = function () {
            DimensionSheetService.open($scope);
        };

        $scope.isDimSheetCalcValid = function () {
            return $scope.realEstateContainer.usages && $scope.realEstateContainer.usages.length > 0 && $scope.realEstateContainer.usages[0] && $scope.realEstateContainer.usages[0].type;
        };

        $scope.openDimSheetCalcInvalid = function() {
            openDimSheetErrorTooltip();
        };

        $scope.$watch('realEstateContainer.usages', function (val) {
            if (val && val[0]) {
                closeDimSheetErrorTooltip();
            }
        }, true);
    }

})();
