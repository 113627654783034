(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('successDownloadTableComponent', {
            bindings: {
                realEstateContainer: '=?',
                hasDownloadXlsx: '=?'
            },
            templateUrl: 'app/components/success_download_table/success-download-table.html',
            controller: DownloadTableController
        });

    DownloadTableController.$inject = ['$scope', '$rootScope', '$translatePartialLoader', '$translate', 'PdfService', 'XlsxService'];

    function DownloadTableController($scope, $rootScope, $translatePartialLoader, $translate, PdfService, XlsxService) {
        var $ctrl = this;

        var hasClickedPdfDownload = false;
        var hasClickedExcelDownload = false;

        $ctrl.downloadPdf = function () {
            if (!hasClickedPdfDownload) {
                hasClickedPdfDownload = true;
                PdfService.downloadPdf($ctrl.realEstateContainer.id, $ctrl.realEstateContainer.metaData.name);
                hasClickedPdfDownload = false;
            }
        };

        $ctrl.downloadXlsx = function () {
            if (!hasClickedExcelDownload) {
                hasClickedExcelDownload = true;
                XlsxService.downloadXlsx($ctrl.realEstateContainer.id, $ctrl.realEstateContainer.metaData.name);
                hasClickedExcelDownload = false;
            }
        };

        this.$onInit = function() {
            $ctrl.selectableFavorites = ['NO_FAVORITE', 'FAVORITE', 'TOP_FAVORITE'];
            if (angular.isUndefined($ctrl.hasDownloadXlsx)) {
                $ctrl.hasDownloadXlsx = false;
            }
            $translatePartialLoader.addPart('favorite');
            $translate.refresh();
        }


    }


})();
