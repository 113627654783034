(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        function openModal($uibModal, $state, url, title) {
            var curState = $state.current.name;
            $uibModal.open({
                templateUrl: 'app/video/video-modal.tpl.html',
                controller: 'VideoModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    url: function () {
                        return url;
                    },
                    title: function() {
                        return title;
                    }
                }
            }).result.then(function () {
                $state.go(curState);
            }, function () {
                $state.go(curState);
            });
        }

        $stateProvider
            .state('products.video', {
                url: '/teaser_video',
                data: {
                    authorities: []
                },
                onEnter: ['$uibModal', '$state', function ($uibModal, $state) {
                    openModal($uibModal, $state, "https://www.youtube.com/embed/2Zm-uxpVjXE?VQ=HD1080", "Kennwerte in 2 Minuten");
                }]
            });

        $stateProvider
            .state('products.video-pom', {
                url: '/teaser_video_pom',
                data: {
                    authorities: []
                },
                onEnter: ['$uibModal', '$state', function ($uibModal, $state) {
                    openModal($uibModal, $state, "https://www.youtube.com/embed/vPi_G_Of2k8?VQ=HD1080", "Der Betriebskostenrechner in 2 Minuten");
                }]
            });

        $stateProvider
            .state('process-form.video', {
                url: '/tutorial_video',
                data: {
                    authorities: [],
                    pageTitle: 'process-form.title',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        '10_howto_section',
                        '20_general_form',
                        '30_geometry_form',
                        '40_complexity_form',
                        '50_quality_form',
                        '60_additions_form',
                        '70_complete_input'
                    ]
                },
                onEnter: ['$uibModal', '$state', function ($uibModal, $state) {
                    openModal($uibModal, $state, "https://www.youtube.com/embed/rZKq7WtghDQ?VQ=HD1080", "Tutorial-Video");
                }]
            });

        $stateProvider
            .state('insurance.video', {
                url: '/tutorial_video',
                data: {
                    authorities: [],
                    pageTitle: 'process-form.title',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        '10_howto_section',
                        '20_general_form',
                        '30_geometry_form',
                        '40_complexity_form',
                        '50_quality_form',
                        '60_additions_form',
                        '70_complete_input'
                    ]
                },
                onEnter: ['$uibModal', '$state', function ($uibModal, $state) {
                    openModal($uibModal, $state, "https://www.youtube.com/embed/rZKq7WtghDQ?VQ=HD1080", "Tutorial-Video");
                }]
            });
    }
})();
