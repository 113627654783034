(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account', 'JhiTrackerService'];

    function Principal($q, Account, JhiTrackerService) {
        var _identity,
            _authenticated = false;

        var _modules = [];

        var service = {
            reset: reset,
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            hasAnyInsuranceModule: hasAnyInsuranceModule,
            hasAnyBuildingCostsModule: hasAnyBuildingCostsModule,
            hasModule: hasModule,
            hasModules: hasModules,
            isPomShow: isPomShow,
            getModules: getModules
        };

        return service;

        /**
         * On logout reset principal
         */
        function reset() {
            _identity = false;
            _authenticated = false;

            _modules = [];
        }

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function () {
                return false;
            });
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                if (account.data) {
                    _identity = account.data;
                    _authenticated = true;
                    _modules = account.data.withdrawalProductTypes;

                    deferred.resolve(_identity);
                    //FIXME: web sockets are broken!
                    //JhiTrackerService.connect();
                } else {
                    getAccountCatch();
                }
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function hasModule(module) {
            return _modules.indexOf(module) !== -1;
        }

        function hasModules(modules) {
            return _.every(modules, hasModule);
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }

        function hasAnyInsuranceModule() {
            return hasModule('BUILDING_INSURANCE_VALUES') || hasModule('SMALL_BUILDING_AGV')|| hasModule('DAMAGE_COSTS');
        }

        function hasAnyBuildingCostsModule() {
            return hasModule('BUILDING_COSTS_AND_DATES') || hasModule('OPERATING_COSTS') || hasModule('REBUILD_COSTS');
        }

        function isPomShow() {
            return !!_identity.lastName && _identity.lastName.indexOf('swissbau') > -1;
        }

        function getModules(){
            return _modules;
        }

    }
})();
