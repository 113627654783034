(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .factory('SmallBuildingAgvUsageSliderMappingService', SmallBuildingAgvUsageSliderMappingService);

    SmallBuildingAgvUsageSliderMappingService.$inject = [];

    /**
     * Is used in kw-lambda-node-usage-preset (seperated repo) - microservice for kw-api!
     */
    function SmallBuildingAgvUsageSliderMappingService() {
        var mapping = {
            "50.1.1": [4.33526611328125],
            "50.1.2": [4.58306884765625],
            "50.1.3": [4.90179443359375],
            "50.1.4": [3.86187744140625],
            "50.1.5": [4.46856689453125],
            "50.1.6": [6.26300048828125],
            "50.1.7": [5.66827392578125],
            "50.2.1": [3.46112060546875],
            "50.2.2": [4.34637451171875],
            "50.2.3": [5.92803955078125],
            "50.2.4": [4.88299560546875],
            "50.2.5": [6.22540283203125],
            "50.2.6": [5.88189697265625],
            "50.2.7": [4.54290771484375],
            "50.2.8": [4.94281005859375],
            "50.2.9": [3.82855224609375],
            "50.3.1": [4.50360107421875],
            "50.3.2": [5.18890380859375],
            "50.3.3": [6.29547119140625],
            "50.3.4": [4.21649169921875],
            "50.4.1": [3.43890380859375],
            "50.4.2": [4.01312255859375],
            "50.4.3": [4.90606689453125],
            "50.4.4": [3.40728759765625],
            "50.4.5": [3.48675537109375],
            "50.4.6": [5.29229736328125],
            "50.4.7": [2.59295654296875],
            "50.5.1": [5.50250244140625],
            "50.5.2": [5.18463134765625],
            "50.5.3": [5.07696533203125],
            "50.5.4": [5.86566162109375],
            "50.6.1": [3.36712646484375],
            "50.6.2": [3.56793212890625],
            "50.6.3": [2.32635498046875],
            "50.6.4": [2.53656005859375],
            "50.6.5": [4.24212646484375],
            "50.6.6": [2.82281494140625],
            "50.7.1": [3.06890869140625],
            "50.7.2": [3.73626708984375],
            "50.7.3": [2.14093017578125],
            "50.7.4": [3.70721435546875],
            "50.7.5": [3.87982177734375],
            "50.8.1": [2.78521728515625],
            "50.8.2": [5.91351318359375],
            "50.8.3": [6.19976806640625],
            "50.8.4": [5.69049072265625],
            "50.8.5": [5.30511474609375],
            "50.8.6": [5.46575927734375],
            "50.8.7": [5.89642333984375],
            "50.8.8": [6.48089599609375],
            "50.8.9": [6.25531005859375]
        };

        return {
            getMapping: function () {
                return mapping;
            }
        };
    }
})();
