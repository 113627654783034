(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('booleanInput', BooleanInputDirective);

    BooleanInputDirective.$inject = ['$rootScope', '$translate'];
    function BooleanInputDirective($rootScope, $translate) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@',
                image: '=',
                required: '=',
                placeholder: '@?',
                isDisabled: '=',
                minlength: '@',
                maxlength: '@',
                validationMessage: '@',
                mandatory: '=',
                calcButton: '<',
                openCalc: '&',
                isCalcValid: '&',
                openCalcInvalid: '&',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                inputBlurFun: '&?',
                disabled: '<',
            },
            templateUrl: 'app/components/boolean_input/boolean-input.tpl.html',
            link: function ($scope, tElement, tAttrs, controller, transcludeFn) {

                function getModelTranslatKey() {
                    return $scope.translateBaseKey + '.answers.' + $scope.model;
                }

                function updateDescriptionAndImage(selectedItem) {
                    var tempDescriptionKey;
                    if (angular.isDefined(selectedItem)) {
                        tempDescriptionKey = $scope.descriptionBaseKey + '.' + selectedItem;
                    } else {
                        tempDescriptionKey = $scope.descriptionBaseKey;
                    }
                    // Push event to CalculationRowController
                    $rootScope.$broadcast("updateImage", {
                        rowid: $scope.rowid
                    });
                    $rootScope.$broadcast("updateDescription", {
                        rowid: $scope.rowid,
                        descriptionNumber: selectedItem,
                        descriptionBaseKey: tempDescriptionKey
                    });
                }

                $scope.answers = ["yes", "no"];

                if ($scope.reference == null)
                    $scope.reference = false;

                $scope.model = ($scope.reference) ? "yes" : "no";
                $scope.modelTranslatKey = getModelTranslatKey();

                $scope.$watch("reference", function(newValue, oldValue) {
                    if (newValue === oldValue) return;
                    $scope.model = ($scope.reference) ? "yes" : "no";
                });

                $scope.$watch("model", function(newValue, oldValue) {
                    if (newValue === oldValue) return;
                    $scope.modelTranslatKey = getModelTranslatKey();
                    $scope.reference = $scope.model === "yes";
                });

                $scope.onOpenClose = function (isOpen) {
                    if (isOpen) {
                        $rootScope.$broadcast("updateDescription", {
                            rowid: $scope.rowid,
                            descriptionBaseKey: $scope.descriptionBaseKey
                        });
                        $rootScope.$broadcast("updateImage", {
                            rowid: $scope.rowid,
                            imageSrc: $scope.image
                        });
                    }
                };

                $scope.isEmptyModel = function (model) {
                    return angular.equals({}, model);
                };

                $scope.translateKey = function (answer) {
                    return 'global.' + answer;
                };

                $scope.onSelect = function (selectedItem) {
                    updateDescriptionAndImage(selectedItem);
                    $scope.reference = selectedItem === "yes";
                };
            }
        };
    }
})();
