angular.module('kennwerteApp')
    .directive('kwMaxLength', KwMaxLengthDirective);
KwMaxLengthDirective.$inject = [];

function KwMaxLengthDirective() {
    return {
        require: 'ngModel',
        scope: {
            kwTargetLength: '=',
            lastPosCursor: '='
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            scope.lastValidValue = '';
            $(document).ready(function() {
                //this prevents from reverting the whole string to empty string if it was just loaded and the user pasts for instance a lot of info.
                scope.lastValidValue = ngModelCtrl.$modelValue;
            });

            function fromUser(text) {
                var el = element[0];
                var newLines = text.match(/\n\n/gi);
                if (newLines == null) {
                    newLines = [];
                }
                if (text.length > scope.kwTargetLength) {
                    //if length is to long we are rolling back to lastValidValue.
                    var transformedInput = scope.lastValidValue;
                    ngModelCtrl.$setViewValue(transformedInput);
                    ngModelCtrl.$render();
                    el.setSelectionRange(scope.lastPosCursor, scope.lastPosCursor);
                    scope.lastValidValue = transformedInput;
                    return transformedInput;
                }
                scope.lastValidValue = text;
                return text;
            }

            ngModelCtrl.$parsers.push(fromUser);
        }
    };
}
