(function() {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('product-management', {
                parent: 'admin',
                url: '/product-management',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'product-managment.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/product-management/product-management.html',
                        controller: 'ProductManagementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product-management');
                        return $translate.refresh();
                    }]
                }
            })
            .state('product-management.newDepositProduct', {
                url: '/deposit-product/new',
                data: {
                    authorites: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/product-management/deposit-product.html',
                        controller: 'DepositProductDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    creditAmount: null, moneyAmount: null, currencyCode: null,
                                    payerNote: null, paymentDescription: null, priceType: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('product-management', null, {reload: true});
                    }, function () {
                        $state.go('product-management');
                    });
                }]
            })
            .state('product-management.editDepositProduct', {
                url: '/deposit-product/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/product-management/deposit-product.html',
                        controller: 'DepositProductDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['DepositProductService', function (DepositProductService) {
                                return DepositProductService.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('product-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]

            })
            .state('product-management.deleteDepositProduct', {
                url: '/deposit-product/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/product-management/deposit-product-delete.html',
                        controller: 'DepositProductDeleteController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['DepositProductService', function (DepositProductService) {
                                return DepositProductService.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('product-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('product-management.newWithdrawalProduct', {
                url: '/withdrawal-product/new',
                data: {
                    authorites: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/product-management/withdrawal-product.html',
                        controller: 'WithdrawalProductDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    creditAmount: null, description: null, withdrawalProductType: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('product-management', null, {reload: true});
                    }, function () {
                        $state.go('product-management');
                    });
                }]
            })
            .state('product-management.editWithdrawalProduct', {
                url: '/withdrawal-product/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/product-management/withdrawal-product.html',
                        controller: 'WithdrawalProductDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['WithdrawalProductService', function (WithdrawalProductService) {
                                return WithdrawalProductService.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('product-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('product-management.deleteWithdrawalProduct', {
                url: '/withdrawal-product/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/product-management/withdrawal-product-delete.html',
                        controller: 'WithdrawalProductDeleteController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['WithdrawalProductService', function (WithdrawalProductService) {
                                return WithdrawalProductService.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('product-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
