(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('compactUsageTypeSelect', CompactUsageTypeSelectDirective);


    CompactUsageTypeSelectController.$inject = ['$scope', '$state', '$attrs', 'usageTypeGroupFactory', '$timeout', '$rootScope', '$translate'];

    function CompactUsageTypeSelectController($scope, $state, $attrs, usageTypeGroupFactory, $timeout, $rootScope, $translate) {
        var vm = this;
        vm.DataHasLoaded = false;

        vm.translateKey = function(postfix) {
            try {
                return $translate.instant('realEstate.usages.' + postfix);
            } catch (e) {
                console.warn('translation error usageTypeSelect ctr', e);
            }
        };


        vm.showDisabledToast = showDisabledToast;

        vm.updateDescriptionAndImage = function(selectedItem) {
            var tempId;
            if (selectedItem) {
                tempId = selectedItem.id;
            } else {
                tempId = vm.model.id;
            }
            $rootScope.$broadcast('updateImage', {
                rowid: $scope.rowid,
                imageSrc: $scope.image
            });

            var key = $scope.descriptionBaseKey;
            var concatenatedGroupIds = '';
            var groupId = tempId;
            do {
                var prevGroupId = usageTypeGroupFactory.getGroupIdById(Number(groupId));
                prevGroupId = Number(prevGroupId);
                if (prevGroupId !== 0) {
                    if (concatenatedGroupIds !== '') {
                        concatenatedGroupIds = prevGroupId + '.' + concatenatedGroupIds;
                    } else {
                        concatenatedGroupIds = prevGroupId;
                    }
                }
                groupId = prevGroupId;
            } while (groupId !== 0);
            var k = key;
            if (concatenatedGroupIds !== '') {
                k = key + '.' + concatenatedGroupIds;
            }

            $rootScope.$broadcast('updateDescription', {
                rowid: $scope.rowid,
                descriptionNumber: tempId,
                dynamicTranslations: true,
                descriptionBaseKey: k
            });
        };

        vm.saveSelection = function(selectedItem) {
            if (!selectedItem.parent) {
                var oldValue = $scope.reference;
                $scope.reference = selectedItem;
                vm.model = selectedItem;
                if ($scope.isMainUsage && $scope.usagesLength == 1) {
                    $scope.referenceArray.push({
                        type: null,
                        percentage: null,
                        standard: null
                    });
                }
                $scope.actionCallback({
                    newValue: $scope.reference,
                    oldValue: oldValue,
                    args: { origin: $attrs.reference, index: $scope.index }
                });
            } else {
                $scope.reference = null;
                vm.model = {
                    'id': '00',
                    'title': 'All',
                    'parent': true
                };
            }
        };

        vm.onSelect = function(selectedItem, $model) {
            vm.updateDescriptionAndImage(selectedItem);
            if (selectedItem.disabled) {
                showDisabledToast(selectedItem.translated);
                vm.model = usageTypeGroupFactory.getPreviousGroup(selectedItem.title);
                $scope.reference = null;
            } else {
                if (!selectedItem.parent) {
                    showPresetsMarkTooltip();
                    var oldValue = $scope.reference;
                    $scope.reference = selectedItem.title;
                    $scope.$broadcast('uiSelectClose');
                    if ($scope.isMainUsage && $scope.usagesLength == 1) {
                        $scope.referenceArray.push({
                            type: null,
                            percentage: null,
                            standard: null
                        });
                    }
                    $scope.actionCallback({
                        newValue: $scope.reference,
                        oldValue: oldValue,
                        args: { origin: $attrs.reference, index: $scope.index }
                    });
                } else {
                    vm.saveSelection(selectedItem);
                    $scope.loadChildGroupsOf(selectedItem);
                    var element = '#usageToast';
                    if ($(element).hasClass('tooltipstered')) {
                        $(element).tooltipster('destroy');
                    }
                }
            }
        };

        vm.onOpenClose = function(isOpen) {
            if (isOpen) {
                vm.updateDescriptionAndImage();
            }
        };

        function isCurrentStateEditRecord() {
            return $state.current.name.indexOf('edit') > 0;
        }

        function showToast(element, text) {
            if ($(element).hasClass('tooltipstered')) {
                $(element).tooltipster('content', text);
                $(element).tooltipster('show');
            } else {
                $(element).tooltipster({
                    contentAsHTML: true,
                    content: text,
                    theme: 'tooltipster-kw',
                    side: ['right'],
                    zIndex: 1048,// same as validation messages
                    interactive: true,
                    selfDestruction: true,
                    animation: 'fade',
                    maxWidth: 350,
                    plugins: ['sideTip'],
                    distance: 0,
                    arrow: false,
                    repositionOnScroll: true,
                    timer: 10000,
                    triggerClose: {
                        click: false,
                        scroll: true
                    }
                }).tooltipster('open');
            }
        }

        function showPresetsMarkTooltip() {
            var translated = '';
            if ($scope.isMainUsage) {
                try {
                    if (isCurrentStateEditRecord()) {
                        translated = $translate.instant('rebuild.general.usage.edit-record.presetsMarkTooltip');
                    } else {
                        translated = $translate.instant('rebuild.general.usage.input.presetsMarkTooltip');
                    }
                } catch (e) {
                    console.warn('translation error usage type presetMark', e);
                }
                var element = '#usageToast';
                return showToast(element, translated);
            }
        }

        function showDisabledToast(usageTitle) {
            var element = '#usageToast';
            var translated = 'Aufgrund der Datenlage bieten wir die Nutzung "' + usageTitle + '" noch nicht an.';
            return showToast(element, translated);
        }

        vm.model = usageTypeGroupFactory.getByTitle($scope.reference);
        if (vm.model.id) {
            vm.model = {
                'id': vm.model.id,
                'title': vm.model.title,
                'parent': vm.model.parent,
                'translated': vm.translateKey(vm.model.title),
                'disabled': vm.model.disabled
            };
        } else {
            vm.model = {
                'id': '00',
                'title': 'All',
                'parent': true
            };
        }

        /**
         * This watch checks if the reference changes and updates the whole component.
         * This can happen if we load data from the db async.
         */
        $scope.$watch('reference', function(newValue) {
            if (newValue && angular.isDefined(newValue)) {
                vm.model = usageTypeGroupFactory.getByTitle(newValue);
                if (vm.model.id) {
                    vm.model = {
                        'id': vm.model.id,
                        'title': vm.model.title,
                        'parent': vm.model.parent,
                        'translated': vm.translateKey(vm.model.title),
                        'disabled': vm.model.disabled
                    };
                    var groupId = usageTypeGroupFactory.getGroupIdById(vm.model.id);
                    if (groupId !== 0) {
                        $scope.breadcrumbs = [{ 'id': '00', 'title': 'All', 'parent': true }];
                        usageTypeGroupFactory.getAllPreviousGroups(vm.model.id)
                            .forEach(function(prevGroup) {
                                $scope.breadcrumbs.push(prevGroup);
                            });
                    }
                } else {
                    vm.model = {
                        'id': '00',
                        'title': 'All',
                        'parent': true
                    };
                    $scope.breadcrumbs = [{ 'id': '00', 'title': 'All', 'parent': true }];
                }
            } else {
                vm.model = {
                    'id': '00',
                    'title': '',
                    'parent': true
                };
                $scope.breadcrumbs = [{ 'id': '00', 'title': 'All', 'parent': true }];
            }
        });

        vm.clearReference = function() {
            $scope.reference = null;
        };

        $scope.$on('showSliderPresetTooltip', function() {
            showPresetsMarkTooltip();
        });
    }

    CompactUsageTypeSelectDirective.$inject = ['usageTypeGroupFactory', '$timeout', '$rootScope', '$translate'];

    function CompactUsageTypeSelectDirective(usageTypeGroupFactory, $timeout, $rootScope, $translate) {

        return {
            restrict: 'E',
            scope: {
                isMainUsage: '=',
                rowid: '=',
                image: '=',
                reference: '=',
                standard: '=',
                percentage: '=',
                referenceArray: '=',
                actionCallback: '&',
                usagesLength: '=',
                required: '=required',
                isDisabled: '=',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                index: '=',
                tabIndex: '=',
                tabGroup: '=',
                classes: '=?',
                infotext: '@?'

            },
            templateUrl: 'app/components/compact_inputs/compact_usage_selecter/compact-usage-selecter.tpl.html',
            controller: CompactUsageTypeSelectController,
            controllerAs: 'vm',
            link: function(scope, tElement, tAttrs, ctrl) {
                try {
                    scope.translateKey = function(postfix) {
                        return $translate.instant('realEstate.usages.' + postfix);
                    };
                } catch (e) {
                    console.warn('translation error usage type select', e);
                }

                scope.breadcrumbs = [{ 'id': '00', 'title': 'All', 'parent': true }];
                scope.model = usageTypeGroupFactory.getByTitle(scope.reference);

                var gs = [];
                if (scope.reference) {
                    var model = usageTypeGroupFactory.getByTitle(scope.reference);
                    var groupId = usageTypeGroupFactory.getGroupIdById(model.id);
                    if (groupId !== 0) {
                        usageTypeGroupFactory.getAllPreviousGroups(model.id)
                            .forEach(function(prevGroup) {
                                scope.breadcrumbs.push(prevGroup);
                            });
                    }
                    gs = usageTypeGroupFactory.load(groupId);
                    //adds the first usage on load.
                    if (scope.isMainUsage && scope.usagesLength == 1) {
                        scope.referenceArray.push({
                            type: null,
                            percentage: null,
                            standard: null
                        });
                    }
                    scope.actionCallback({
                        newValue: scope.referenceArray.length,
                        oldValue: null,
                        args: { origin: tAttrs.reference, index: scope.index }
                    });
                    scope.DataHasLoaded = true;
                } else {
                    gs = usageTypeGroupFactory.load('00');
                    scope.DataHasLoaded = true;
                    if (scope.reference === undefined) {
                        scope.reference = null;
                    }
                    if (scope.percentage === null) {
                        scope.percentage = null;
                    }
                }

                var enhanceGroupFn = function(gs) {
                    for (var i in scope.groups) {
                        var oldg = scope.groups[i];
                        oldg.noshow = true;
                    }

                    var enhancedGroups = [];
                    gs.forEach(function(group) {
                        if (!scope.isMainUsage || (group.title !== 'VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN' && group.title !== 'VERKEHRSBAUTEN__GARAGEN_FREISTEHEND')) {
                            // except for VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN and VERKEHRSBAUTEN__GARAGEN_FREISTEHEND don't enhance groups
                            enhancedGroups.push({
                                'id': group.id,
                                'title': group.title,
                                'parent': group.parent,
                                'translated': scope.translateKey(group.title),
                                'disabled': group.disabled
                            });
                        }
                    });
                    scope.groups = enhancedGroups;
                };

                // add property translated to to each group in groups
                enhanceGroupFn(gs);

                scope.loadChildGroupsOf = function(group) {
                    scope.breadcrumbs.push(group);
                    var gs = usageTypeGroupFactory.load(group.id);
                    enhanceGroupFn(gs);
                };

                scope.navigateBackTo = function(crumb) {
                    var index = _.findIndex(scope.breadcrumbs, { id: crumb.id });
                    scope.breadcrumbs.splice(index + 1, scope.breadcrumbs.length);
                    var gs = usageTypeGroupFactory.load(_.last(scope.breadcrumbs).id);
                    // ctrl.onSelect(crumb);
                    ctrl.saveSelection(crumb);
                    ctrl.updateDescriptionAndImage(crumb);
                    enhanceGroupFn(gs);
                };

                // Only allow numbers in the input field
                // The simpler 'type="number"' is causing the curser to jump
                // if a letter is entered.
                tElement.find('.percentageCompactUsageInputField').on('change keyup', function() {
                    // Remove invalid characters
                    var sanitized = $(this).val().replace(/[^0-9]/g, '');
                    // Update value

                    $(this).val(sanitized);
                });

                // Used for the validation
                var toggle = tElement.find('.compactUsageSelect');
                var idString = tAttrs.referenceArray.replace('$ctrl.', '');
                $(toggle).attr('id', idString + '[' + Number(scope.index) + '].type.drop2down-toggle');
                ctrl.standardReferenceString = idString + "[" + Number(scope.index) + "].standard";

                var percentageElement = tElement.find('.percentageCompactUsageInputField');
                $(percentageElement).attr('id', idString + '[' + Number(scope.index) + '].percentage.input');
            }
        };
    }

})();
