(function () {
    "use strict";

    angular.module("kennwerteApp.agv")
        .factory("SmallBuildingService", SmallBuildingService);

    SmallBuildingService.$inject = ["$resource", "$q"];

    function SmallBuildingService($resource, $q) {
        var smallBuilding = {};
        var valueClassPreset = 0;
        var service = {
            smallBuilding: smallBuilding,
            valueClassPreset: valueClassPreset,
            getByIdCached: getByIdCached,
            getByIdForceLoad: getByIdForceLoad,
            estimate: estimate,
            getValueClassPreset: getValueClassPreset
        };

        function getByIdCached(id) {
            if (id == null) {
                var defer = $q.defer();
                service.smallBuilding = service.api.get({ id: null });
                defer.resolve(service.smallBuilding);
                return service.smallBuilding.$promise;
            }
            if (id == service.smallBuilding.id) {
                var deferred = $q.defer();
                deferred.resolve(service.smallBuilding);
                return deferred.promise;
            } else {
                service.smallBuilding = service.api.get({ id: id });
                return service.smallBuilding.$promise;
            }
        }

        function getByIdForceLoad(id) {
            service.smallBuilding = service.api.get({ id: id });
            console.log(service.smallBuilding.$promise);
            return service.smallBuilding.$promise;
        }

        function estimate(realEstateContainer) {

            return service.api.estimate(realEstateContainer).$promise;

        }

        function getValueClassPreset(realEstateContainer) {
            if (realEstateContainer.agv.totalArea416 != null) {
                return service.api.getValueClassPreset(realEstateContainer).$promise;
            } else {
                var deferred = $q.defer();
                // console.warn('missing vals');
                deferred.reject('missing vals');
                return deferred.promise;
            }
        }

        service.api = $resource('api/smallBuilding/:id', { id: '@_id' }, {
            'query': {
                method: 'GET', isArray: true
            },
            'update': {
                method: 'PUT' // this method issues a PUT request
            },
            'access': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'createEmptySmallBuilding': {
                method: 'POST'
            },
            'estimate': {
                method: 'POST',
                url: 'api/withdrawal/small_building/estimate',
                transformResponse: function(data, headersGetter, status) {
                    return { data: angular.fromJson(data),status: status };
                }
            },
            'getValueClassPreset': {
                method: 'POST',
                url: 'api/smallBuilding/value_class_preset',
                transformResponse: function(data, headersGetter, status) {
                    return { data: angular.fromJson(data), status: status };
                }
            }
        });

    return service;

}
})();
