(function () {
    "use strict";

    angular.module("kennwerteApp")
        .component("compactDynamicSingleSelecter", {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                selectables: '=', // Array which has all selectable options inside.
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                defaultImage: '=?',
                image: '=',
                imageFormat: '=?',
                dynamicImage: '=?',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                classes: '@?',
                infotext: '@?',
                useShortStrings: '=?',
                inputBlurFun: '&?'
            },
            templateUrl: "app/components/dynamic_single_selecter/compact-dynamic-single-selecter.tpl.html",
            controller: CompactDynamicSingleSelecterController
        });

    CompactDynamicSingleSelecterController.$inject = ["$scope", "$rootScope", "$element", "$translate", "$attrs"];

    function CompactDynamicSingleSelecterController($scope, $rootScope, $element, $translate, $attrs) {
        var $ctrl = this;
        $ctrl.firstLoad = true;
        $ctrl.translateKey = function (postfix) {
            try {
                var instant = $translate.instant(
                    $ctrl.translateBaseKey + '.' + postfix + ".label"
                );
                return instant;
            } catch (e) {
                console.warn(e);
            }
        };

        function isSelected(type) {
            return $ctrl.reference === type;
        }

        $ctrl.createShortString = function() {
            $ctrl.displayShortString = '';
            if ($ctrl.reference != null) {
                $translate($ctrl.translateBaseKey + '.' + $ctrl.reference + '.short').then(function(translation) {
                    $ctrl.displayShortString = translation;
                }).catch(function(e) {
                    console.warn(e);
                });
            }

        };

        var enhanceGroupFn = function(gs) {
            var enhancedGroups = [];
            for (var j in gs) {
                var group = gs[j];
                enhancedGroups.push({
                    title: group,
                    translated: $ctrl.translateKey(group),
                    isSelected: isSelected(group)
                });
            }
            $ctrl.groups = enhancedGroups;
        };

        $ctrl.resetGroups = function () {
            //var gs;
            //angular.copy($ctrl.selectables, gs);
            enhanceGroupFn($ctrl.selectables);
        };

        $ctrl.resetGroups();
        // use polyfills for IE, TODO: Introduce a proper place for polyfills

        $ctrl.onBlur = function () {
        };

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        $ctrl.onOpenClose = function (isOpen, type) {
            if (isOpen) {
                $ctrl.updateDescriptionAndImage(undefined);
            }
        };

        $ctrl.onSelect = function (item) {
            item.isSelected = !item.isSelected;
            if (item.isSelected) {
                _.forEach($ctrl.groups, function (value, key) {
                    // sets all values on false excluding the currently selected and the items that are in the array (reference).
                    if (value.isSelected && value.title != item.title) {
                        value.isSelected = false;
                    }
                });
                $ctrl.reference = item.title;
            } else {
                _.forEach($ctrl.groups, function (value, key) {
                    if (value.isSelected) {
                        value.isSelected = false;
                    }
                });
                $ctrl.reference = null;
            }
            $ctrl.updateDescriptionAndImage((item.isSelected) ? item : undefined);
            $ctrl.inputBlurFun();
        };

        $scope.$watch('$ctrl.reference', function(newValue, oldValue) {
            function syncSelected() {
                _.forEach($ctrl.groups, function(group) {
                    group.isSelected = group.title === $ctrl.reference;
                });
            }
            if (newValue !== oldValue && angular.isDefined(newValue)) {
                syncSelected();
                if ($ctrl.useShortStrings) {
                    $ctrl.createShortString();
                }
                $ctrl.model = $ctrl.reference;//otherwise ui-select will not update.
            }
        });

        $ctrl.updateDescriptionAndImage = function(selectedItem) {
            if (selectedItem !== undefined) {
                var imgSrc = $ctrl.dynamicImage ? $ctrl.image + selectedItem.title + '.' + $ctrl.imageFormat : $ctrl.image;
                $rootScope.$broadcast('updateImage', {
                    rowid: $ctrl.rowid,
                    imageSrc: imgSrc
                });
                $rootScope.$broadcast('updateDescription', {
                    rowid: $ctrl.rowid,
                    descriptionNumber: selectedItem.id,
                    descriptionBaseKey:
                        $ctrl.descriptionBaseKey + '.' + selectedItem.title
                });
            } else {
                if ($ctrl.defaultImage != null) {
                    $rootScope.$broadcast('updateImage', {
                        rowid: $ctrl.rowid,
                        imageSrc: $ctrl.defaultImage
                    });
                }
                $rootScope.$broadcast('updateDescription', {
                    rowid: $ctrl.rowid,
                    // descriptionNumber: selectedItem.id,
                    descriptionBaseKey: $ctrl.descriptionBaseKey
                });
            }
        };

        $scope.$watch('$ctrl.selectables', function(newValue, oldValue) {
            if (!_.isEqual(newValue, oldValue)) {
                enhanceGroupFn($ctrl.selectables);
            }
        }, true);

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            // console.warn($scope.groups[0], $scope.groups[0].title, vm.translateKey($scope.groups[0].title));
            for (var i = 0; i < $ctrl.groups.length; i++) {
                if ($ctrl.groups[i].title != null) {
                    $ctrl.groups[i].translated = $ctrl.translateKey($ctrl.groups[i].title);
                }
            }
            if ($ctrl.useShortStrings) {
                $ctrl.createShortString();
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };

        this.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            if ($ctrl.dynamicImage == null) {
                $ctrl.dynamicImage = false;
            }
            if (!angular.isFunction($ctrl.inputBlurFun)) {
                $ctrl.inputBlurFun = angular.noop;
            }

            if (angular.isUndefined($ctrl.useShortStrings)) {
                $ctrl.useShortStrings = false;
            }

            // angular.copy($ctrl.selectables, $ctrl.groups);
            enhanceGroupFn($ctrl.selectables);
            // Used for the validation
            if ($ctrl.reference && angular.isDefined($ctrl.reference)) {
                $ctrl.model = $ctrl.reference;
            }
            if ($ctrl.useShortStrings) {
                $ctrl.createShortString();
            }
            var toggle = $element.find('.dynamicSingleDropdown');
            $(toggle).attr('id', $ctrl.referenceString + '.drop2down-toggle');
        };
    }
})();
