(function () {
    'use strict';

    angular.module('kennwerteApp')
    .component('fileUpload', {
        bindings: {
            reference: '=?', // acts as counter of valid files to become uploaded
            rowid: '=',
            translateDescriptionBaseKey: '@',
            translateBaseKey: '@',
            mandatory: '@?',
            placeholder: '@?',
            isDisabled: '=',
            validationMessage: '@',
            descriptionBaseKey: '@',
            usages: '<',
            optional: '<?',
            infotext: '@?',
            attachmentType: '@?'
        },
        templateUrl: 'app/components/file_upload/file-upload.tpl.html',
        controller: FileUploadController
    });

    FileUploadController.$inject = ['$scope', '$rootScope', '$window', 'AttachmentService', '$attrs', '$element'];

    function FileUploadController($scope, $rootScope, $window, AttachmentService, $attrs, $element) {
        //TODO remove console.logs
        var $ctrl = this;
        $ctrl.uploadStarted = false;

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        function createKey(file) {
            return file.name + '_' + file.$ngfWidth + '_' + file.$ngfHeight + '_' + file.lastModified;
        }

        $ctrl.selectFiles = function (files, newFiles, invalidFiles) {
            angular.forEach(newFiles, function (file) {
                var key = createKey(file);
                var isFileReplaced = false;
                $ctrl.files.forEach(function (f, i) {
                    if (angular.equals(createKey(f), key)) {
                        $ctrl.files[i] = f;
                        isFileReplaced = true;
                    }
                });
                if (!isFileReplaced) {
                    $ctrl.files.push(file);
                }
            });
            var cntValidFiles = 0;
            angular.forEach($ctrl.files, function(file) {
                if (!file.$error) {
                    cntValidFiles += 1;
                }
            });
            $ctrl.reference = cntValidFiles; // count valid selected files
        };

        $ctrl.deleteSelected = function (file, index) {
            $ctrl.files.splice(index, 1);
            $ctrl.reference.length -= 1; // a valid file is removed, thus also decrease reference
        };

        $ctrl.magicButton = function (file) {
            var fileURL = URL.createObjectURL(file);
            $window.open(fileURL, '_blank');
        };

        $ctrl.openImage = function (file) {
            $window.open(file.$ngfBlobUrl, '_blank');
        };

        $rootScope.$on('uploadAttachments', function () {
            $ctrl.uploadStarted = true;
            AttachmentService.add($ctrl.files, $ctrl.attachmentType);

        });

        $rootScope.$on('allAttachmentsUploaded', function () {
            $ctrl.uploadStarted = false;
            $ctrl.files.splice(0, $ctrl.files.length);
            $ctrl.reference = 0;
        });


        this.$onInit = function () {
            $ctrl.files = [];
            $ctrl.reference = 0;
            $ctrl.options = true;
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.translateButton = $ctrl.translateBaseKey + '.button';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            if (angular.isUndefined($ctrl.reference))
                $ctrl.reference = {};
        };

        var toggle = $element.find(".fileInputButton");
        $(toggle).attr("id", $attrs.reference + ".input");

    }
})();
