(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('calculationRow', CalculationRowDirective);

    CalculationRowDirective.$inject = [];

    function CalculationRowDirective() {
        return {
            restrict: 'A',
            transclude: true,
            scope: {
                superTitle: '@?',
                leftFloat: '=?',
                calculationRowTitle: '@',
                defaultimage: '@',
                defaultDescriptionBaseKey: '@',
                translateDescriptionBaseKey: '@', // used to define the full translate description key (field specification)
                translateDescriptionKeyNumber: '@', // used to define the full translate description key (wertklasse)
                sliderBaseKey: '@?',
                rowid: '=', // used to set the id of the row-image
                countUsages: '&',
                contentRowInnerStyle: '=',
                ctPartFormStyle: '=',
                ctPartImgStyle: '=',
                ctPartDescrStyle: '=',
                ctPartClearStyle: '=',
                contentRowStyle: '@?',
                hasSliders: '=?',
                infotext: '@?',
                dynamicTranslations: '<?',
                disableDescriptionBox: '@?',
                disableTitles: '@?',
                showImageGallery: '=?'
            },
            templateUrl: 'app/components/calculation_row/calculation-row.html',
            controller: CalculationRowDirectiveController,
            controllerAs: 'vm',
            link: function(scope, elem, attrs, controller, transclude) {
            }
        };
    }

    CalculationRowDirectiveController.$inject = ['$scope', '$timeout', '$translate', '$rootScope', '$element',
        'CalcRowImageService', 'CalcRowTextService', 'SimpleImageModalService', 'SmallBuildingGalleryDataService',
        'ResponsiveStateService', 'Principal'];

    function CalculationRowDirectiveController($scope, $timeout, $translate, $rootScope, $element, CalcRowImageService,
                                               CalcRowTextService, SimpleImageModalService, SmallBuildingGalleryDataService,
                                               ResponsiveStateService, Principal) {
        if (!$scope.contentRowStyle) {
            $scope.contentRowStyle = 'contentRowInner';
        }

        var vm = this;
        if (!$scope.translateDescriptionKeyNumber) {
            $scope.translateDescriptionKeyNumber = 0;
        }
        if (!$scope.translateDescriptionBaseKey) {
            $scope.translateDescriptionBaseKey = $scope.defaultDescriptionBaseKey;
        }
        if (!$scope.sliderBaseKey) {
            $scope.sliderBaseKey = 'process-form.slider';
        }
        if (!$scope.sliderNotPresent) {
            $scope.sliderNotPresent = false;
        }
        if (!$scope.miniSlider) {
            $scope.miniSlider = 5;
        }
        if (angular.isUndefined($scope.dynamicTranslations)) {
            $scope.dynamicTranslations = false;
        }

        $scope.key = null;

        $scope.isAdmin = null;

        Principal.hasAuthority('ROLE_ADMIN').then(function(res) {
            $scope.isAdmin = res;
        });

        // functions related to small building
        $scope.showHdImage = showHdImage;
        $scope.moveLeft = moveLeft;
        $scope.moveRight = moveRight;
        $scope.dots = dots;
        $scope.moveToDot = moveToDot;
        $scope.doShowImageGallery = doShowImageGallery;
        $scope.SmallBuildingGalleryDataService = SmallBuildingGalleryDataService;

        $scope.hasAnyResponsiveState = ResponsiveStateService.hasAnyContentRowResponsiveState;

        // on init clear data service
        SmallBuildingGalleryDataService.clear();

        function showHdImage() {
            var index = SmallBuildingGalleryDataService.galleryImagesIdx;
            var imgContainer = SmallBuildingGalleryDataService.galleryImagesContainer[index];
            var imgSrc = '/small_building_image/hd/' + imgContainer['name'];
            var imgWidth = imgContainer['width'];
            var imgHeight = imgContainer['height'];
            return SimpleImageModalService.open(index, imgSrc, imgWidth, imgHeight);
        }

        function moveLeft() {
            var imageUrlString = SmallBuildingGalleryDataService.moveLeft();
            if (!!imageUrlString) {
                $scope.imgSrc = imageUrlString;
            }
        }

        function moveRight() {
            var imageUrlString = SmallBuildingGalleryDataService.moveRight();
            if (!!imageUrlString) {
                $scope.imgSrc = imageUrlString;
            }
        }

        function dots() {
            return SmallBuildingGalleryDataService.dots;
        }

        function moveToDot(galleryImageName) {
            $scope.imgSrc = SmallBuildingGalleryDataService.moveToDot(galleryImageName);
        }

        function doShowImageGallery() {
            // TODO KR-463: only if admin, show image gallery, later we have to change that
            return $scope.showImageGallery;
        }

        function refreshImageBasedOnKey() {
            if (doShowImageGallery()) {
                CalcRowImageService.getImageURL($scope.key).then(function(imageUrlString) {
                    $scope.isSvg = $scope.isItASvg(imageUrlString);
                    $scope.imgSrc = imageUrlString;
                });
            }
        }

        var getBulletPoints = function() {
            var bulletPointTranslationStr = $scope.translateDescriptionBaseKey;
            bulletPointTranslationStr += $scope.translateDescriptionKeyNumber !== undefined ? '.' + $scope.translateDescriptionKeyNumber : '';
            bulletPointTranslationStr += '.' + 'bulletPointCounter';
            $translate(bulletPointTranslationStr).then(function(translation) {
                $scope.bulletPointCounter = parseInt(translation);
                vm.bulletPoints = _.range($scope.bulletPointCounter);
                if (vm.bulletPoints) {
                    $scope.elementHeight = $element.find('#bulletPoints').height();
                }
            }, function(translationId) {
                $scope.bulletPointCounter = 0;
                vm.bulletPoints = [];/*$scope.elementHeight = $element.find('#bulletPoints').height();*/
            }).catch(function(e) {
                console.info(e);
            });
        };
        getBulletPoints();
        $scope.$on('updateImage', function(event, args) {
            // set the new image for the image in the corresponding row
            if (args.rowid === $scope.rowid) {
                $scope.key = args.key;
                if ($scope.key != null) {
                    refreshImageBasedOnKey();
                } else if (args.imageSrc != null) {
                    $scope.isSvg = $scope.isItASvg(args.imageSrc);
                    $scope.imgSrc = args.imageSrc;
                }
            }
        });

        $rootScope.$on('refreshCalcRow', function(event, args) {
            if ($scope.key != null) {
                console.log('refreshCalcRow', $scope.key);
                refreshImageBasedOnKey();
            }
        });

        $scope.$on('updateDescription', function(event, args) {
            // set the new description of the corresponding row
            if (args.rowid === $scope.rowid) {
                $timeout(function() {
                    $scope.$apply();
                    if (args.key !== undefined && args.key !== null) {
                        // $scope.key = args.key;
                        $scope.translateDescriptionBaseKey = CalcRowTextService.getTextBase($scope.key, args.descriptionBaseKey);
                    } else {
                        $scope.translateDescriptionBaseKey = args.descriptionBaseKey;
                    }
                    $scope.translateDescriptionKeyNumber = args.descriptionNumber;
                    $scope.dynamicTranslations = !!args.dynamicTranslations;
                    $scope.remarks = !!args.remarks;
                    $scope.sliderNotPresent = args.sliderNotPresent;
                    getBulletPoints();
                });
            }
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            var bulletPointTranslationStr = $scope.translateDescriptionBaseKey + '.' + $scope.translateDescriptionKeyNumber + '.' + 'bulletPointCounter';
            $translate(bulletPointTranslationStr).then(function(translation) {
                $scope.bulletPointCounter = translation;
                getBulletPoints();
            }).catch(function(e) {
                console.warn(e);
            });
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
        $scope.isItASvg = function(imgSrc) {
            return imgSrc.endsWith('.svg');
        };

        $scope.imgSrc = $scope.defaultimage;
        $scope.isSvg = $scope.isItASvg($scope.imgSrc);
    }

})();
