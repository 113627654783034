(function() {
    'use strict';

    angular.module('kennwerteApp')
        .service('GlobalToastService', GlobalToastService);


    GlobalToastService.$inject = ['$translate', '$translatePartialLoader', '$timeout', '$state'];

    /**
     * Translations are in folder webapp/i18n/toasts
     * @param $translate
     * @param $translatePartialLoader
     * @param $timeout
     * @returns {{showSmallBuildingFloorAreaWarning: showSmallBuildingTotalAreaWarning}}
     * @constructor
     */
    function GlobalToastService($translate, $translatePartialLoader, $timeout, $state) {
        $translatePartialLoader.addPart('toasts_small_building');

        function showSmallBuildingTotalAreaWarning(realEstateContainer) {
            // first destroy element
            if (realEstateContainer && realEstateContainer.agv && realEstateContainer.agv.totalArea416) {
                var element = '#' + _escapeInSelector('realEstateContainer.agv.totalArea416.input');
                if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
                    $(element).tooltipster('close');
                }
                if (realEstateContainer.agv.totalArea416 > 100) {
                    var translation = getShowSmallBuildingTotalAreaWarningText();
                    var toast =
                        '<div>' +
                        '<label>' + translation + '</label>' +
                        '</div>';

                    showToast(element, toast);
                }
            }
        }

        function getShowSmallBuildingTotalAreaWarningText() {
            return $translate.instant('toasts.smallBuilding.showSmallBuildingTotalAreaWarning.tobig');
        }

        function getSmallBuildingHeatingDisabledWarningText() {
            return $translate('toasts.smallBuilding.showSmallBuildingHeatingDisabledWarning');
        }

        function showSmallBuildingHeatingDisabledWarningText() {
            var element = '#' + _escapeInSelector('realEstateContainer.agv.heating.drop2down-toggle');
            if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
                $(element).tooltipster('close');
            }
            getSmallBuildingHeatingDisabledWarningText().then(function(translation) {
                var toast =
                    '<div>' +
                    '<label>' + translation + '</label>' +
                    '</div>';

                showToast(element, toast);
            }, function(error) {
                console.error(error);
            }).catch(function(e) {
                console.warn(e);
            });
        }

        /**
         * Showing a Toast that all preset-able values are set. This is only used in the Building Costs.
         * @param elementId
         */
        function showPresetChangedToast(elementId) {
            var element = '#' + _escapeInSelector(elementId);
            if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
                $(element).tooltipster('close');
            }
            if ($state.includes('*.edit-record') || $state.includes('**.edit.**')) {
                var editText = 'realEstate.general.process-form.edit-record.generalPreset';
                $translate(editText).then(function(translation) {
                    showToast(element, translation, { hasArrow: false, width: 350 });
                }).catch(function(e) {
                    console.warn(e);
                });
            }
            if ($state.includes('*.input') ||  $state.includes('**.input.**')) {
                var inputText = 'realEstate.general.process-form.input.generalPreset';
                $translate(inputText).then(function(translation) {
                    showToast(element, translation, { hasArrow: false, width: 350 });
                }).catch(function(e) {
                    console.warn(e);
                });

            }
        }

        /**
         * show toast, similar to usage change
         * @param element
         * @param text
         */
        function showToast(element, text, options) {
            if (angular.isUndefined(options)) {
                options = {};
                options.hasArrow = true;
                options.width = 430;
            }
            if ($(element).hasClass('tooltipstered')) {
                var instance = $(element).tooltipster('instance');
                instance.content(text);
                instance.open();
            } else {
                $timeout(function() {
                    $(element).tooltipster({
                        contentAsHTML: true,
                        content: text,
                        theme: 'tooltipster-kw',
                        side: ['right'],
                        zIndex: 1048, // same as validation messages
                        maxWidth: options.width,
                        trigger: 'custom',
                        plugins: ['sideTip'],
                        interactive: true,
                        timer: 10000,
                        arrow: options.hasArrow,
                        repositionOnScroll: true,
                        functionReady: function(instance, helper) {
                            $(helper.tooltip).click(function() {
                                if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
                                    $(element).tooltipster('close');
                                }
                            });
                            $(helper.origin).click(function() {
                                if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
                                    $(element).tooltipster('close');
                                }
                            });
                        }
                    }).tooltipster('open');
                });
            }
        }

        return {
            showSmallBuildingFloorAreaWarning: showSmallBuildingTotalAreaWarning, //when above 100 square meters.
            getShowSmallBuildingTotalAreaWarningText: getShowSmallBuildingTotalAreaWarningText,
            showSmallBuildingHeatingDisabledWarningText: showSmallBuildingHeatingDisabledWarningText,//when open building is selected Property(agv.closed)
            getSmallBuildingHeatingDisabledWarningText: getSmallBuildingHeatingDisabledWarningText,
            showPresetChangedToast: showPresetChangedToast
        };

        function _escapeInSelector(selector) {
            return selector.replace(/(:|\.|\[|\]|,|=)/g, '\\$1');
        }
    }


})();
