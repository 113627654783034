(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .factory("LicenseReportService", LicenseReportService);

    LicenseReportService.$inject = ["$rootScope", "$http", "FileSaver"];

    function LicenseReportService($rootScope, $http, FileSaver) {
        var separator = ".";
        var service = {
            downloadValidLicenses: downloadValidLicenses,
            getLicenseReport: getLicenseReport
        };

        return service;

        function downloadValidLicenses(dateTimeDeadline) {
            return $http.get('api/ba_valid_licenses/download?dateTimeDeadline=' + dateTimeDeadline,
                {responseType: 'blob'})
                .then(function(response) {
                    var data = new Blob([response.data], {type: 'application/zip'});
                    var filename = 'valid_licenses_' + dateTimeDeadline + '.xlsx'
                    FileSaver.saveAs(data, filename);
                });
        }

        function getLicenseReport(dateTimeDeadline) {
            var dto = {
                'dateTimeDeadline': dateTimeDeadline
            }
            return $http
                .post("api/ba_valid_licences/report", dto)
                .then(function(response) {
                    return response.data;
                });
        }
    }
})();
