(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .service("BillingModalService", BillingModalService);

    BillingModalService.$inject = ['$uibModal', '$translate', '$translatePartialLoader'];

    function BillingModalService($uibModal, $translate, $translatePartialLoader) {
        function openBillingModal(modalClosedCallback, moduleNames) {
            console.debug("HALLO billing-modal.open", modalClosedCallback);
            $translatePartialLoader.addPart('billing');
            $translatePartialLoader.addPart('register');
            $translatePartialLoader.addPart('prices');
            $translate.refresh().then(function() {
                $uibModal.open({
                    templateUrl: 'app/account/billing/billing_modal/billing-modal.html',
                    controller: 'BillingModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        moduleNames: function() {
                            return moduleNames;
                        }
                    }
                }).result.then(function () {
                    modalClosedCallback();
                }, function () {
                    modalClosedCallback();
                });
            });
        }

        return {
            openBillingModal: openBillingModal
        };
    }

})();



