(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('BillingService', BillingService);

    BillingService.$inject = ['$http'];

    function BillingService($http) {
        return {
            authorizeCard: function (billingDTO, responseFunction, errorFunction) {
                return $http.post('api/billing/authorize_card', billingDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            authorizeCardAndPay: function (billingDTO, responseFunction, errorFunction) {
                return $http.post('api/billing/authorize_card_and_pay', billingDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            insertAlias: function (initTransactionDTO, responseFunction, errorFunction) {
                return $http.post('api/billing/insert_alias', initTransactionDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            assertAlias: function (assertAliasDTO, responseFunction, errorFunction) {
                return $http.post('api/billing/assert_alias', assertAliasDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            initTransaction: function (initTransactionDTO, responseFunction, errorFunction) {
                return $http.post('api/billing/init_transaction', initTransactionDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            removeLicenseRenewal: function (responseFunction, errorFunction) {
                return $http.post('api/billing/remove_license_renewal')
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            enableLicenseRenewal: function (responseFunction, errorFunction) {
                return $http.post('api/billing/enable_license_renewal')
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            updateLicense: function (updateLicenseReqDTO, responseFunction, errorFunction) {
                return $http.post('api/billing/update_license', updateLicenseReqDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            directPaymentFullModule2020: function(updateLicenseReqDTO, responseFunction, errorFunction) {
                return $http.post('api/billing/direct_payment_full_module_2020', updateLicenseReqDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            adminUpdateLicenseUsers: function (updateLicenseReqDTO, orgId, responseFunction, errorFunction) {
                return $http.post('api/billing/admin_update_license_users/' + orgId, updateLicenseReqDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            adminUpdateLicenseModules: function(updateLicenseReqDTO, orgId, responseFunction, errorFunction) {
                return $http.post('api/billing/admin_update_license_modules/' + orgId)
                    .then(function(response) {
                        return responseFunction(response);
                    }, function(response) {
                        return errorFunction(response);
                    });
            },
            fetchAdditionalPrice: function (numAdditionalUsers, additionalWpts, freeDays) {
                return $http({
                    url: 'api/billing/fetch_additional_price',
                    data: {
                        numAdditionalUsers: numAdditionalUsers,
                        additionalWpts: additionalWpts,
                        freeDays: freeDays
                    },
                    method: 'POST'
                });
            },
            fetchAdditionalPriceForeign: function(wpBefore, freePairsBefore,
                                                  wpAfter, freePairsAfter,
                                                  validUntilDate, mutationDate) {
                return $http({
                    url: 'api/billing/fetch_additional_price_foreign',
                    data: {
                        wpBefore: wpBefore,
                        freePairsBefore: freePairsBefore,
                        wpAfter: wpAfter,
                        freePairsAfter: freePairsAfter,
                        validUntilDate: validUntilDate,
                        mutationDate: mutationDate
                    },
                    method: 'POST'
                })
            },
            fetchPriceRelief: function (numUsersToRemove) {
                return $http({
                    url: 'api/billing/fetch_price_relief',
                    params: {
                        numUsersToRemove: numUsersToRemove
                    },
                    method: 'POST'
                });
            },
            fetchPrice: function (wpId) {
                return $http({
                    url: 'api/billing/fetch_price',
                    method: 'GET',
                    params: {
                        wpId: wpId
                    }
                });
            },
            fetchTentativePrice: function (registerDTO) {
                return $http({
                    url: 'api/billing/fetch_tentative_price',
                    method: 'POST',
                    data: registerDTO
                });
            },
            isAuthorizedAndNotExpired: function () {
                return $http({
                    url: 'api/billing/is_authorized_and_not_expired',
                    method: 'GET'
                });
            },
            licenseState: function () {
                return $http({
                    url: 'api/billing/license_state',
                    method: 'GET'
                });
            },
            billLicenses: function () {
                return $http({
                    url: 'api/billing/bill_licenses',
                    method: 'GET'
                });
            },
            billLicense: function (orgId) {
                return $http({
                    url: 'api/billing/bill_license',
                    method: 'GET',
                    params: {
                        orgId: orgId
                    }
                });
            }
        };
    }

})();
