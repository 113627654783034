(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('facadeTypeGroupFactory', FacadeTypeGroupFactory);

    FacadeTypeGroupFactory.$inject = ['$rootScope'];

    function FacadeTypeGroupFactory($rootScope) {

        function getAllPreviousIds(id, isRebuild) {
            var ret = [];
            while (id != 0) {
                id = getGroupIdById(id, isRebuild);
                ret.push(id);
            }
            return ret;
        }

        function getGroupIdById(id, isRebuild) {
            var currentData = service.data;
            if (isRebuild != null && isRebuild) {
                currentData = service.rebuildData;
            }
            for (var key in currentData) {
                var groupArr = currentData[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (id === obj.id) {
                        return key;
                    }
                }
            }
            return 0;
        }

        function getById(id, isRebuild) {
            var currentData = service.data;
            if (isRebuild != null && isRebuild) {
                currentData = service.rebuildData;
            }
            for (var key in currentData) {
                var groupArr = currentData[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (id == obj.id) {
                        return obj;
                    }
                }
            }
            return {};
        }

        var service = {
            load: function(id, isRebuild) {
                var currentData = service.data;
                if (isRebuild != null && isRebuild) {
                    currentData = service.rebuildData;
                }
                return currentData[id];
            },

            getByTitle: function(title, isRebuild) {
                var currentData = service.data;
                if (isRebuild != null && isRebuild) {
                    currentData = service.rebuildData;
                }
                for (var key in currentData) {
                    var groupArr = currentData[key];
                    for (var objKey in groupArr) {
                        var obj = groupArr[objKey];
                        if (title === obj.title) {
                            return obj;
                        }
                    }
                }
                return {};
            },
            getGroupIdById: function(id, isRebuild) {
                return getGroupIdById(id, isRebuild);
            },

            getAllPreviousGroups: function(groupId, isRebuild) {
                var prevGroups = [];
                var prevIds = getAllPreviousIds(groupId, isRebuild);
                prevIds.forEach(function(prevId) {
                    var prevGroup = getById(prevId, isRebuild);
                    if (!angular.equals({}, prevGroup)) {
                        prevGroups.push(getById(prevId, isRebuild));
                    }
                });
                return prevGroups;
            },
            data: {
                0: [{ 'id': 1, 'title': 'KEINE_FASSADE', 'parent': false },
                    { 'id': 1001, 'title': 'NICHT_ISOLIERT_TITLE', 'parent': true },
                    { 'id': 11, 'title': 'EINSTEINMAUERWERK_VERPUTZT', 'parent': false },
                    { 'id': 1003, 'title': 'ZWEISCHALENMAUERWERK_TITLE', 'parent': true },
                    { 'id': 1004, 'title': 'SICHTBETONFASSADE_TITLE', 'parent': true },
                    { 'id': 1005, 'title': 'AUSSENDAEMMUNG_TITLE', 'parent': true },
                    { 'id': 1006, 'title': 'MASSIVBAU_TITLE', 'parent': true },
                    { 'id': 1008, 'title': 'HOLZBAU_TITLE', 'parent': true },
                    { 'id': 1007, 'title': 'HOLZ_TITLE', 'parent': true },
                    { 'id': 10010, 'title': 'GLASSFASSADE_TITLE', 'parent': true }],
                1001: [
                    { 'id': 2, 'title': 'NICHT_ISOLIERT_HOLZ', 'parent': false },
                    // {"id": 3, "title": "NICHT_ISOLIERT_METALL", "parent": false},
                    { 'id': 4, 'title': 'NICHT_ISOLIERT_BETON', 'parent': false }
                ],
                1003: [
                    { 'id': 12, 'title': 'ZWEISCHALENMAUERWERK_VERPUTZT', 'parent': false },
                    { 'id': 13, 'title': 'ZWEISCHALENMAUERWERK_SICHTMAUERWERK', 'parent': false }
                ],
                1004: [
                    { 'id': 15, 'title': 'SICHTBETONFASSADE_GIPS', 'parent': false },
                    { 'id': 16, 'title': 'SICHTBETONFASSADE_BACKSTEIN', 'parent': false },

                    // {"id": 17, "title": "SICHTBETONFASSADE_SICHTMAUERWERK", "parent": false},

                    { 'id': 18, 'title': 'SICHTBETONFASSADE_INNEN_SICHTBETON', 'parent': false }
                    // ,
                    // {"id": 19, "title": "SICHTBETONFASSADE_MISAPOR_MISAPOR", "parent": false},
                    // {"id": 14, "title": "SICHTBETONFASSADE_BETONSANDWICHELEMENT", "parent": false},
                ],
                1005: [
                    { 'id': 5, 'title': 'AUSSENDAEMMUNG_PUTZ_EINFACH', 'parent': false },
                    { 'id': 6, 'title': 'AUSSENDAEMMUNG_PUTZ_EDEL', 'parent': false },
                    { 'id': 7, 'title': 'AUSSENDAEMMUNG_KLEID_EINFACH', 'parent': false },
                    { 'id': 8, 'title': 'AUSSENDAEMMUNG_KLEID_EDEL', 'parent': false }
                ],
                1006: [
                    { 'id': 9, 'title': 'MASSIVBAU_EINFACH', 'parent': false },
                    { 'id': 10, 'title': 'MASSIVBAU_EDEL', 'parent': false }
                ],
                1007: [
                    { 'id': 20, 'title': 'HOLZ_EINFACH', 'parent': false },
                    { 'id': 21, 'title': 'HOLZ_EDEL', 'parent': false }
                ],
                1008: [
                    { 'id': 22, 'title': 'HOLZBAU_EINFACH', 'parent': false },
                    { 'id': 23, 'title': 'HOLZBAU_MITHINTERLUFT', 'parent': false },
                    { 'id': 24, 'title': 'HOLZBAU_OHNE_MASSIVHOLZ', 'parent': false }
                    // {"id": 25, "title": "HOLZBAU_OHNE_FACHWERKBAU", "parent": false}
                ],
                10010: [
                    { 'id': 27, 'title': 'GLASSFASSADE_GLASSFASSADE', 'parent': false },
                    { 'id': 28, 'title': 'GLASSFASSADE_GLASSFASSADE_DOPPEL', 'parent': false },
                    { 'id': 10020, 'title': 'STAHL_KASSETENWANDFASSADE_TITLE', 'parent': true }
                ],
                10020: [
                    { 'id': 31, 'title': 'STAHL_KASSETENWANDFASSADE_EINFACH', 'parent': false },
                    { 'id': 32, 'title': 'STAHL_KASSETENWANDFASSADE', 'parent': false }
                ]
                // {"id": 26, "title": "STAHL_KASSETENWANDFASSADE", "parent": false},
            },
            rebuildData: {
                0: [{ 'id': 1, 'title': 'KEINE_FASSADE', 'parent': false },
                    { 'id': 1001, 'title': 'NICHT_ISOLIERT_TITLE', 'parent': true },
                    { 'id': 1002, 'title': 'EINSTEINMAUERWERK_TITLE', 'parent': true },

                    { 'id': 1003, 'title': 'ZWEISCHALENMAUERWERK_TITLE', 'parent': true },
                    { 'id': 1004, 'title': 'SICHTBETONFASSADE_TITLE', 'parent': true },
                    { 'id': 1005, 'title': 'AUSSENDAEMMUNG_TITLE', 'parent': true },
                    { 'id': 1006, 'title': 'MASSIVBAU_TITLE', 'parent': true },
                    { 'id': 1008, 'title': 'HOLZBAU_TITLE', 'parent': true },
                    { 'id': 1007, 'title': 'HOLZ_TITLE', 'parent': true },
                    { 'id': 10010, 'title': 'GLASSFASSADE_TITLE', 'parent': true }],
                1001: [
                    { 'id': 2, 'title': 'NICHT_ISOLIERT_HOLZ', 'parent': false },
                    // {"id": 3, "title": "NICHT_ISOLIERT_METALL", "parent": false},
                    { 'id': 4, 'title': 'NICHT_ISOLIERT_BETON', 'parent': false }
                ],
                1002: [
                    { 'id': 11, 'title': 'EINSTEINMAUERWERK_VERPUTZT', 'parent': false },
                    { 'id': 29, 'title': 'MAUERWERK', 'parent': false },
                    { 'id': 30, 'title': 'MAUERWERK_SICHT', 'parent': false }

                ],
                1003: [
                    { 'id': 12, 'title': 'ZWEISCHALENMAUERWERK_VERPUTZT', 'parent': false },
                    { 'id': 13, 'title': 'ZWEISCHALENMAUERWERK_SICHTMAUERWERK', 'parent': false }
                ],
                1004: [
                    { 'id': 15, 'title': 'SICHTBETONFASSADE_GIPS', 'parent': false },
                    { 'id': 16, 'title': 'SICHTBETONFASSADE_BACKSTEIN', 'parent': false },

                    // {"id": 17, "title": "SICHTBETONFASSADE_SICHTMAUERWERK", "parent": false},

                    { 'id': 18, 'title': 'SICHTBETONFASSADE_INNEN_SICHTBETON', 'parent': false }
                    // ,
                    // {"id": 19, "title": "SICHTBETONFASSADE_MISAPOR_MISAPOR", "parent": false},
                    // {"id": 14, "title": "SICHTBETONFASSADE_BETONSANDWICHELEMENT", "parent": false},
                ],
                1005: [
                    { 'id': 5, 'title': 'AUSSENDAEMMUNG_PUTZ_EINFACH', 'parent': false },
                    { 'id': 6, 'title': 'AUSSENDAEMMUNG_PUTZ_EDEL', 'parent': false },
                    { 'id': 7, 'title': 'AUSSENDAEMMUNG_KLEID_EINFACH', 'parent': false },
                    { 'id': 8, 'title': 'AUSSENDAEMMUNG_KLEID_EDEL', 'parent': false }
                ],
                1006: [
                    { 'id': 9, 'title': 'MASSIVBAU_EINFACH', 'parent': false },
                    { 'id': 10, 'title': 'MASSIVBAU_EDEL', 'parent': false }
                ],
                1007: [
                    { 'id': 20, 'title': 'HOLZ_EINFACH', 'parent': false },
                    { 'id': 21, 'title': 'HOLZ_EDEL', 'parent': false }
                ],
                1008: [
                    { 'id': 22, 'title': 'HOLZBAU_EINFACH', 'parent': false },
                    { 'id': 23, 'title': 'HOLZBAU_MITHINTERLUFT', 'parent': false },
                    { 'id': 24, 'title': 'HOLZBAU_OHNE_MASSIVHOLZ', 'parent': false }
                    // {"id": 25, "title": "HOLZBAU_OHNE_FACHWERKBAU", "parent": false}
                ],
                10010: [
                    { 'id': 27, 'title': 'GLASSFASSADE_GLASSFASSADE', 'parent': false },
                    { 'id': 28, 'title': 'GLASSFASSADE_GLASSFASSADE_DOPPEL', 'parent': false },
                    { 'id': 10020, 'title': 'STAHL_KASSETENWANDFASSADE_TITLE', 'parent': true }
                ],
                10020: [
                    { 'id': 31, 'title': 'STAHL_KASSETENWANDFASSADE_EINFACH', 'parent': false },
                    { 'id': 32, 'title': 'STAHL_KASSETENWANDFASSADE', 'parent': false }
                ]
                // {"id": 26, "title": "STAHL_KASSETENWANDFASSADE", "parent": false},
            }
        };
        return service;
    }
})();
