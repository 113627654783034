(function () {
    "use strict";

    angular
        .module("kennwerteApp")
        .controller("JhiLanguageController", JhiLanguageController);

    JhiLanguageController.$inject = [
        "$rootScope",
        "$translate",
        "JhiLanguageService",
        "tmhDynamicLocale",
        "Principal",
        "User",
        '$stateParams',
        '$urlService',
        '$state'

    ];

    function JhiLanguageController(
        $rootScope,
        $translate,
        JhiLanguageService,
        tmhDynamicLocale,
        Principal,
        User,
        $stateParams,
        $urlService,$state
    ) {
        var vm = this;
        console.warn($stateParams.lang);
        vm.changeLanguage = changeLanguage;
        vm.languages = [];

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        if($stateParams.lang != null){
            vm.langKey = $stateParams.lang;
            vm.changeLanguage(vm.langKey);
            $state.go($state.current, {}, {notify: false,inherit: false});
        } else {
            vm.langKey = $translate.storage().get("NG_TRANSLATE_LANG_KEY");
        }

        function changeLanguage(languageKey) {
            vm.langKey = $translate.storage().get("NG_TRANSLATE_LANG_KEY");
            var updateTranslationTable = function () {
                $translate
                    .refresh(languageKey)
                    .then(
                        function (data) {},
                        function (error) {
                            console.warn(error);
                            $translate.refresh();
                        }
                    )
                    .catch(function (e) {
                        console.warn(e);
                        $translate.refresh();
                    });
            };

            addLanguageKeyToBody(languageKey, vm.langKey);

            $translate.use(languageKey).then(
                function () {
                    tmhDynamicLocale.set(languageKey);
                    //we need to refresh afterwards otherwise it might show translations keys instead of value. (due to partial Loader).
                    updateTranslationTable();
                    if (Principal.isAuthenticated()) {
                        User.updateLanguage({}, languageKey).$promise.then(
                            function (response) {},
                            function (error) {
                                console.error(error);
                            }
                        );
                    }
                },
                function (error) {
                    console.warn(error);
                }
            );
        }

        var myEl = angular.element(document.querySelector("body"));
        addLanguageKeyToBody(vm.langKey);

        function addLanguageKeyToBody(langKey, removeLang) {
            console.warn(
                langKey,
                removeLang,
                $translate.getAvailableLanguageKeys()
            );
            var body = angular.element(document.querySelector("body"));
            if (removeLang != null) {
                body.removeClass(removeLang);
            }
            if (langKey != null) {
                body.addClass(langKey);
            }
        }

        var updateLangKey = function() {
            JhiLanguageService.getCurrent().then(function(language) {
                vm.langKey = language;
            });
        };

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function(_) {
            updateLangKey();
        });

        this.$onDestroy = function() {
            translateSuccess();
        };
    }
})();
