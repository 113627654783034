(function () {
   'use strict';

   angular.module('kennwerteApp')
       .component('compactStaticSingleSelector', {
           bindings: {
               reference: '=?',
               answers: '=',
               translateBaseKey: "@"
           },
           templateUrl: 'app/components/static_single_selector/compact-static-single-selector.tpl.html',
           controller: CompactStaticSingleSelectorController
       });

    CompactStaticSingleSelectorController.$inject = ['$scope', '$translate'];

    function CompactStaticSingleSelectorController($scope, $translate) {
        var $ctrl = this;
        $ctrl.firstLoad = true;

        $ctrl.translateKey = function (answer) {
            try {
                return $ctrl.translateBaseKey +'.' + answer +".label";
            } catch (e) {
                console.warn(e);
            }
        };

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        $ctrl.onInit = function () {
            console.log("init")
        }
    }

})();
