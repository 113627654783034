(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildGeometryService', RebuildGeometryService);

    RebuildGeometryService.$inject = ['RebuildTemplateService'];

    function RebuildGeometryService(RebuildTemplateService) {

        var service = {
            isNone: false,
            isAnnex: false,
            isAdditionStory: false,
            refresh: refresh
        };

        function refresh(selectedTemplates) {
            service.isNone = RebuildTemplateService.isNone(selectedTemplates);
            service.isAnnex = RebuildTemplateService.isAnnex(selectedTemplates);
            service.isAdditionStory = RebuildTemplateService.isAdditionStory(selectedTemplates);
        }

        return service;
    }

})();
