(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('conditionSliderInput', ConditionSliderInput);

    ConditionSliderInput.$inject = ['$rootScope', '$timeout', 'NormalDistributionService', 'RebuildComponentSliderService'];

    function ConditionSliderInput($rootScope, $timeout, NormalDistributionService, RebuildComponentSliderService) {

        return {
            restrict: 'E',
            scope: {
                rowid: '@', // used to determine in what row this directive is placed, so the correct image can be updated
                reference: '=',
                referenceStr: '@',
                component: '=?',
                realEstateContainer: '=?',
                presetValue: '=', // the value of the preset mark
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                image: '=',
                isDisabled: '=?',
                mandatory: '@?',
                lessValues: '@',
                tabindex: '@',
                tabgroup: '@',
                ownCondition: '@?',
                infotext: '@?'
            },
            templateUrl: 'app/components/sliders/condition_slider/condition-slider_input_partial.html',
            controller: function () {
            },
            link: function (scope, tElement, tAttrs, ctrl) {

                scope.firstLoad = true;
                scope.doShowInterval = false;
                scope.boxPlotData = [{}];
                scope.wkBoxPlotData = [{}];
                scope.whConfig = undefined;
                scope.additionalHtml = null;

                $(document).ready(function() {
                    if (scope.isDisabled == null) {
                        scope.isDisabled = false;
                    }
                });

                var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
                    // setLanguageForSlider();
                });
                this.$onDestroy = function() {
                    translateSuccess();
                };

                function updateDescriptionRow(condition, remainingValue, interval) {

                    function isRemainingValueOutsideInterval() {
                        return false; // not used currently
                        if (interval === undefined) return false;
                        var whiskerData = [interval[1], interval[5]];
                        return !(whiskerData[0] <= remainingValue && remainingValue <= whiskerData[1]);
                    }

                    if (scope.component.active) {
                        $rootScope.$broadcast('updateDescription', {
                            rowid: Number(tAttrs.rowid),
                            descriptionBaseKey: tAttrs.descriptionBaseKey,
                            classType: condition,
                            isOutsideSuggestion: isRemainingValueOutsideInterval(),
                            sliderRange: values.length,
                            sliderNotPresent: true
                        });
                    }
                }

                var values = ["ZK1", "ZK2", "ZK3", "ZK4", "ZK5"];
                if (angular.isUndefined(scope.reference)) {
                    // scope.reference = 3;
                }

                var indexFun = function (value) {
                    return values.indexOf(value);
                };

                if (tAttrs.class === 'subSlider') {
                    var inputElement = tElement.find(".cInputElement");
                    inputElement.addClass(tAttrs.class);
                }

                var inputField = tElement.find(".slider2Input");
                this.$label = inputField.data("ionRangeSlider");

                var setSlider = function (newValue) {
                    var valIndex = indexFun(newValue);
                    if (valIndex > -1) {
                        var slider = inputField.data("ionRangeSlider");
                        slider.update({
                            from: valIndex
                        });
                    }
                };

                var isCurrentSliderValue = function (value) {
                    var slider = inputField.data("ionRangeSlider");
                    return slider.result.from_value === value;
                };

                scope.$watch('[component.condition,component.remainingValueBackend,component.remainingValueInterval]', function(newValue, oldValue) {
                    if (newValue === undefined) return;
                    updateDescriptionRow(scope.component.condition, scope.component.remainingValueBackend, scope.component.remainingValueInterval);
                }, true);

                scope.$watch('reference', function(newValue, oldValue) {
                    if (newValue === undefined) return;
                    if (newValue !== oldValue && !isCurrentSliderValue(newValue)) {
                        setSlider(newValue);
                    }
                });

                scope.$watch('component.active', function(newValue, oldValue) {
                    if (newValue && !oldValue) {
                        updateSliderMarkByScopeData();
                    }
                    $timeout(function () {
                        // timout needed as slider is hidden and therefore sliderData.coords.p_handle is still infinite.
                        if (newValue && !oldValue) {
                            updateSliderBoxPlotByScopeData();
                        }
                    }, 500);
                });

                scope.$watch('component.conditionBackend', function(newValue, oldValue) {
                    if (newValue === undefined) return;
                    updateSliderMarkByScopeData();
                });

                scope.$watch('component.remainingValueBackend', function(newValue, oldValue) {
                    if (newValue === undefined) return;
                    updateSliderBoxPlotByScopeData();
                });

                scope.$watch('isDisabled', function(newValue, oldVal) {
                    // inputField.data('ionRangeSlider').update(ionRangeSliderOptions);
                    if (newValue === undefined) return;
                    ionRangeSliderOptions.disable = newValue;
                    var slider = inputField.data('ionRangeSlider');
                    slider.destroy();
                    inputField.ionRangeSlider(ionRangeSliderOptions);
                    if (scope.additionalHtml) {
                        slider = inputField.data('ionRangeSlider');
                        // $slider is a jquery function
                        var $slider = slider.result.slider;
                        $slider.append(scope.additionalHtml);
                    }
                });

                function convertToPercent(num, min, max) {
                    return (num - min) / (max - min) * 100;
                }

                function toFixed(num) {
                    num = num.toFixed(5);
                    return +num;
                }

                function updateSliderMarkByScopeData() {
                    if (scope.component.conditionBackend !== undefined) {
                        updateSliderMark(inputField.data('ionRangeSlider'), scope.component.conditionBackend);
                    }
                }

                function updateSliderMark(sliderData, condition) {
                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exists
                    if (sliderData) {
                        var min = sliderData.result.min;
                        var max = sliderData.result.max;

                        var real_width = 100 - sliderData.coords.p_handle;
                        var val = toFixed(scope.getIdx(condition) / 100 * real_width);
                        var left = convertToPercent(val, min, max);
                        var html;
                        if (_.inRange(left, 0, 100)) {
                            html = '<span class="sliderMark" style="left: ' + left + '%"></span>';
                        } else {
                            if (left < 0) {
                                html = '<span class="sliderMark" style="left: ' + 0 + '%"></span>';
                            }
                            if (left > 89) {
                                html = '<span class="sliderMark" style="left: ' + 89.00 + '%"></span>';
                            }
                        }
                        $timeout(function() {
                            if (_.isFinite(sliderData.coords.p_handle)) {
                                ctrl.update(html);
                            }
                        });
                    }
                }

                function updateSliderBoxPlotByScopeData() {
                    if (scope.component.remainingValueInterval !== undefined) {
                        updateSliderBoxPlot(inputField.data('ionRangeSlider'), scope.component.remainingValueInterval);
                    }
                }

                function updateSliderBoxPlot(sliderData, interval) {
                    if (sliderData && interval !== undefined) {
                        interval = interval.map(RebuildComponentSliderService.transformRemainingValue);
                        scope.whConfig = { width: sliderData.coords.w_rs, height: 10, barWidth: 10 - 2 };
                        var quartileData = interval.slice(2, 5);
                        var whiskerData = [interval[1], interval[5]];
                        var outlierData = [interval[0], interval[6]];
                        scope.boxPlotData[0] = {
                            'key': 0,
                            'quartile': convertArr(quartileData, sliderData.coords.p_handle),
                            'whiskers': convertArr(whiskerData, sliderData.coords.p_handle),
                            'outliers': convertArr(outlierData, sliderData.coords.p_handle),
                            'max': 1
                        };
                        var flatBoxPlotdata = _.flatMapDeep(scope.boxPlotData[0]);
                        if (_.every(flatBoxPlotdata, _.isFinite)) {
                            scope.doShowInterval = true;
                        }
                    }
                }

                scope.getIdx = function (value) {
                    return indexFun(value);
                };

                 function rescale(val, b_min, b_max, a_min, a_max) {
                    // b_min: before min
                    // b_max: before max
                    // a_min: after min
                    // a_max: after max
                    return (a_max - a_min) * (val - b_min) / (b_max - b_min) + a_min;
                }

                function convertArr(arr, p_handle) {
                    return arr.map(function (e) {
                        var min = p_handle/200;
                        var max = 1 - min;
                        return rescale(e/100, 0, 1, min, max);
                    });
                }

                var label;

                function Label(container) {
                    this.$label = $(container).find(".irs-single");
                    this.active = false;
                    this.ACTIVE = "irs-single--active";
                }

                Label.prototype = {
                    start: function () {
                        if (!this.active) {
                            this.active = true;
                            this.$label.addClass(this.ACTIVE);
                        }
                    },

                    end: function () {
                        this.$label.removeClass(this.ACTIVE);
                        this.active = false;
                    }
                };

                var ionRangeSliderOptions = {
                    type: 'single',
                    grid: false,
                    keyboard: true,
                    hide_min_max: true,
                    disable: scope.isDisabled,
                    keyboard_step: 100 / values.length + 1, /* In % ! */
                    from_value: scope.reference, /*Position of the value*/
                    from: scope.getIdx(scope.reference),
                    onStart: function(data) {
                        label = new Label(data.slider);
                    },
                    onChange: function(data) {
                        label.start();
                        // Change the model if the slider changes
                        $timeout(function () {
                            scope.component.conditionUserInput = data.from_value;
                            scope.reference = scope.component.conditionUserInput;
                        });
                    },
                    onFinish: function (data) {
                        $timeout(function () {
                            label.end();
                        }, 1000);
                    },
                    values: values
                };

                inputField.ionRangeSlider(ionRangeSliderOptions);

                ctrl.update = function (additionalHtml) {
                    scope.additionalHtml = additionalHtml;
                    var slider = inputField.data('ionRangeSlider');
                    slider.destroy();
                    inputField.ionRangeSlider(ionRangeSliderOptions);
                    if (additionalHtml) {
                        slider = inputField.data('ionRangeSlider');
                        // $slider is a jquery function
                        var $slider = slider.result.slider;
                        $slider.append(additionalHtml);
                    }
                };

            }
        };
    }

})();
