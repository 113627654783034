(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RebuildCalculatingErrorController', RebuildCalculatingErrorController);

    RebuildCalculatingErrorController.$inject = [];

    function RebuildCalculatingErrorController() {
    }

})();
