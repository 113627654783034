(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance'];

    function LoginController($rootScope, $state, $timeout, Auth, $uibModalInstance) {
        var vm = this;

        vm.authenticationError = false;
        vm.notActivatedError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.register = register;

        vm.requestResetPassword = requestResetPassword;
        vm.resendActivation = resendActivation;
        vm.username = null;
        vm.password = null;
        vm.rememberMe = true;

        $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.username = null;
            vm.password = null;
            vm.rememberMe = true;
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $uibModalInstance.close();
                $rootScope.$broadcast('authenticationSuccess');
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('products', null, {reload: true});
                } else {
                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params, {reload: true});
                    }
                    if ($state.current.name !== 'accessdenied') {
                        $state.go($state.current.name, null, {reload: true});
                    }
                }
            }).catch(function (err) {
                vm.authenticationError = true;
                if ('message' in err.headers() && err.headers().message.match("was not activated")) {
                    vm.notActivatedError = true;
                }
            });
        }

        function register() {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword() {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }

        function resendActivation() {
            Auth.resendActivation({mail: vm.username})
                .then(function () {
                    vm.resendSuccess = 'OK';
                }).catch(function () {
                    vm.resendSuccess = null;
                    vm.resendError = 'ERROR';
                });
        }
    }
})();
