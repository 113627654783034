(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageGeneralFormController', DamageGeneralFormController);

    DamageGeneralFormController.$inject = ['$scope', '$stateParams', 'RebuildModernizationPresetService', 'RebuildTemplateService', 'KwImageModelService'];

    function DamageGeneralFormController($scope, $stateParams, RebuildModernizationPresetService, RebuildTemplateService, KwImageModelService) {
        $scope.rebuildTemplateService = RebuildTemplateService;
        $scope.data = $stateParams.data;

        $scope.presetComponentYears = function() {
            RebuildModernizationPresetService.presetYearOnRebuildYear();
        };

    }

})();



