(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('DepositProductService', DepositProductService);

    DepositProductService.$inject = ['$resource'];

    function DepositProductService($resource) {
        return $resource('api/deposit_products/:id', {id: '@_id'}, {
            'query': {
                method: 'GET', isArray: true
            },
            'update': {
                method: 'PUT'
            },
            'access': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'dynamic': {
                url: 'api/deposit_products/dynamic',
                method: 'POST',
                isArray: false
            },
            'actives': {
                url: 'api/deposit_products/actives/:wpId/:efh/:options',
                params: {
                    wpId: '@wpId',
                    efh: '@efh',
                    options: '@options'
                },
                method: 'GET',
                isArray: true
            },
            'actives_fixed': {
                url: 'api/deposit_products/actives_fixed',
                method: 'GET',
                isArray: true
            },
            'actives_for_admin': {
                url: 'api/deposit_products/actives_for_admin',
                method: 'GET',
                isArray: true
            }
        });
    }

})();
