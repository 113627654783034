(function () {
    "use strict";

    angular.module("kennwerteApp")
        .controller("DamageTargetFormController", DamageTargetFormController);

    DamageTargetFormController.$inject = ["$scope", "RebuildTemplateService", "RebuildEstateDataService"];

    function DamageTargetFormController($scope, RebuildTemplateService, RebuildEstateDataService) {
        $scope.checkIfTemplateSelected = function() {
            return _.includes(RebuildTemplateService.getSelectedTemplates(), "ANNEX") || _.includes(RebuildTemplateService.getSelectedTemplates(), "ADDITION_STORY");
        };

    }

})();
