(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('BuyLicenseController', BuyLicenseController);

    BuyLicenseController.$inject = ['$scope', '$state', '$stateParams', '$location', 'BillingService', 'GTMService'];
    //FIXME RU-6 Logic to request new Invoice.
    function BuyLicenseController($scope, $state, $stateParams, $location, BillingService, GTMService) {
        var vm = this;

    }

})();
