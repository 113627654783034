(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('OperationalCostsComplexityFormController', OperationalCostsComplexityFormController);

    OperationalCostsComplexityFormController.$inject = ['$scope', '$stateParams', 'BuildingCostsActionService'];

    function OperationalCostsComplexityFormController($scope, $stateParams, BuildingCostsActionService) {
        $scope.data = $stateParams.data;
        $scope.isNotIE = false;
        $scope.BuildingCostsActionService = BuildingCostsActionService;
        if (angular.isUndefined(document.documentMode)) {
            $scope.isNotIE = true;
        } else {
            $scope.isNotIE = false;
        }
    }

})();
