(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('CalculatingErrorController', CalculatingErrorController);

    CalculatingErrorController.$inject = [];

    function CalculatingErrorController() {
    }

})();
