(function() {
    "use strict";

    angular.module("kennwerteApp")
        .directive("onErrorSrc", OnImageErrorDirective);

    OnImageErrorDirective.$inject = ['$q'];
    function OnImageErrorDirective($q) {
        return {
            restrict: "AE",
            scope: {
                resolverFn: "&",
                onErrorSrc: "@"
            },
            link: function(scope, element, attrs) {
                attrs.$observe('ngSrc', function (ngSrc) {
                    var deferred = $q.defer();
                    var image = new Image();
                    image.onerror = function () {
                        deferred.resolve(false);
                        element.attr('src', 'assets/images/real-estate-reference/noImage.jpg'); // set default image
                        scope.resolverFn();
                    };
                    image.onload = function () {
                        deferred.resolve(true);
                    };
                    image.src = ngSrc;
                    return deferred.promise;
                });
            }
        };
    }

})();
