(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('priceCalcInput', PriceCalcInputDirective)
        .controller('priceCalcInputCtrl', PriceCalcInputCtrl);

    PriceCalcInputDirective.$inject = [];

    function PriceCalcInputDirective() {
        return {
            restrict: "E",
            replace: "true",
            scope: {
                wp: "=",
                organisation: "=",
                baseLicenses: "=",
                withdrawalProducts: "="
            },
            controller: PriceCalcInputCtrl,
            controllerAs: 'ivm',
            templateUrl: 'app/admin/price-calculator/price_calc_input_directive/price_calc_input.tpl.html'
        };
    }

    PriceCalcInputCtrl.$inject = ['$scope'];

    function PriceCalcInputCtrl($scope) {
        var ivm = this;

        //ivm = $scope;


        ivm.wp = $scope.wp;
        ivm.organisation = $scope.organisation;
        ivm.baseLicenses = $scope.baseLicenses;
        ivm.withdrawalProducts = $scope.withdrawalProducts;

        ivm.colorizeLicense = colorizeLicense;
        ivm.printLicense = printLicense;
        ivm.isSelected = isSelected;

        function colorizeLicense(license, numEmployees) {
            if ((license.numMinEmployees !== -1 && numEmployees >= license.numMinEmployees)
                && (license.numMaxEmployees !== -1 && numEmployees <= license.numMaxEmployees)) {
                return "green";
            } else {
                return "red";
            }
        }

        function printLicense(baseLicense) {
            var strPrivatePrefix = baseLicense.isPrivate ? "PRIVATE" : "PUBLIC";
            return baseLicense.name + "\t" + strPrivatePrefix + " [ Monatl. Grundgebühr: " + baseLicense.monthlyBaseFee / 100 + " CHF ]";
        }

        function isSelected(wpIter) {
            return _.includes(ivm.wp.withdrawalProductTypes, wpIter.wpt);
        }

    }

})();
