(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('GeneralSliderValuesService', GeneralSliderValuesService);

    GeneralSliderValuesService.$inject = ['$translate', '$rootScope'];

    function GeneralSliderValuesService($translate, $rootScope) {
        var service = {
            getValues: getValues,
            getTranslations: getTranslations
        };

        var _translationCache = {
            defaultValuesTranslated: []
        };

        var _values = {
            default: ['NONE', 'FEW', 'SOME', 'EXTENSIVE', 'EXCEPTIONAL']
        };

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            _translationCache.defaultValuesTranslated = [];
        });

        this.$onDestroy = function() {
            translateSuccess();
        };

        return service;

        /**
         * Accessing values for Backend with fallback to default values.
         * @param type
         * @returns {string[]}
         */
        function getValues(type) {
            try {
                if (type == null || type === 'default') {
                    return _values.default;
                }
            } catch (e) {
                console.error(e);
            }
            //add here new types if needed
        }


        /**
         * Accessing Translations from given type for user.
         * @param type
         * @returns {[]}
         */
        function getTranslations(type) {
            try {
                if (type == null || type === 'default') {
                    if (_translationCache.defaultValuesTranslated.length === 0) {
                        _translationCache.defaultValuesTranslated = _.map(getValues(type), function(value) {
                            return $translate.instant('default.' + value + '.label');
                        });
                    }
                    return _translationCache.defaultValuesTranslated;
                }
                //add here new types if needed
            } catch (e) {
                console.error(e);
            }
        }


    }

})();
