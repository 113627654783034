(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('adminCalendar', AdminCalendarInput)
        .controller('adminCalendarInputCtrl', AdminCalendarInputCtrl);

    AdminCalendarInput.$inject = [];

    function AdminCalendarInput() {
        return {
            restrict: "E",
            replace: 'false',
            scope: {
                timeReference: '=',
                disableH: '@'
            },
            controller: AdminCalendarInputCtrl,
            controllerAs: 'ivm',
            templateUrl: 'app/admin/organisation-management/calendar_directive/calendar-input.tpl.html'
        };
    }


    AdminCalendarInputCtrl.$inject = ['$scope', 'moment','$mdDateLocale'];

    function AdminCalendarInputCtrl($scope, moment, $mdDateLocale) {
        var ivm = this;

        if ($scope.disableH === undefined) {
            $scope.disableH = false;
        }
        ivm.disableH = $scope.disableH;

        ivm.masterTime = $scope.timeReference.clone();
        ivm.time = ivm.masterTime.format('HH:mm');

        ivm.fixOverflow = function() {
            $(document.documentElement).css('overflow-y', 'hidden');
        };

        ivm.unfixOverflow = function() {
            $(document.documentElement).css('overflow-y', '');
        };

        ivm.dateChange = function (date) {
            ivm.masterTime = moment(ivm.masterTime);
            getCurrentTimeInput();
            $scope.timeReference = ivm.masterTime;
            ivm.time = ivm.masterTime.format('HH:mm');
        };

        ivm.plusOneHour = function () {
            getCurrentTimeInput();
            ivm.masterTime.add(1, 'h');
            ivm.time = ivm.masterTime.format('HH:mm');

        };

        ivm.setZeroMinutes = function () {
            getCurrentTimeInput();
            ivm.masterTime.minutes('00');
            ivm.masterTime.seconds('0');
            setDateFormat();
        };

        ivm.setMindnight = function () {
            getCurrentTimeInput();
            ivm.masterTime.hours('23');
            ivm.masterTime.minutes('59');
            setDateFormat();
        };

        ivm.setNoon = function () {
            getCurrentTimeInput();
            ivm.masterTime.hours('12');
            ivm.masterTime.minutes('00');
            setDateFormat();
        };

        ivm.minusOneHour = function () {
            getCurrentTimeInput();
            ivm.masterTime.subtract(1, 'hours');
            setDateFormat();
        };

        function getCurrentTimeInput() {
            var temp = ivm.time.split(':');
            ivm.masterTime.hours(temp[0]);
            ivm.masterTime.minutes(temp[1]);
        }

        function setDateFormat() {
            ivm.time = ivm.masterTime.format('HH:mm');
        }

        $scope.$watch('timeReference', function (newValue) {
            ivm.masterTime = $scope.timeReference.clone();
            ivm.time = ivm.masterTime.format('HH:mm');
        });

        $scope.$watch('ivm.time', function () {
            ivm.dateChange();
        });

        $mdDateLocale.formatDate = function(date) {
            return moment(date).format('DD.MM.YYYY');
        };

        $mdDateLocale.parseDate = function(dateString) {
            var dateFormat = ['D.M.YY','DD.MM.YYYY','D.M.YYYY'];
            var m = moment(dateString, dateFormat, true);
            return m.isValid() ? m.toDate() : new Date(NaN);
        };

    }

})();
