(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('minergieEnergiePvSelecter', {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                referenceMinergie: '=',
                referencePv: '=',
                referenceEnergie: '=',
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                rowKey: '=?',
                defaultImage: '=?',
                image: '=',
                imageFormat: '=?',
                dynamicImage: '=?',
                dynamicTranslations: '=?',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                classes: '@?',
                infotext: '@?',
                useShortStrings: '=?',
                actionCallback: '&',
                inputBlurFun: '&?',
                pvTiedToMinergie:'=?',
                isPresetSliderModelValuesEnabled: '=?'
            },
            templateUrl: 'app/components/minergie_energie_pv_selecter/minergie-energie-pv-selecter-selecter.tpl.html',
            controller: MinergieEnergiePvSelecterController
        });

    MinergieEnergiePvSelecterController.$inject = ['$scope', '$translate', '$attrs'];

    function MinergieEnergiePvSelecterController($scope, $translate, $attrs) {
        var $ctrl = this;


        this.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';

            if (!angular.isFunction($ctrl.actionCallback)) {
                $ctrl.actionCallback = angular.noop;
            }
            if($ctrl.pvTiedToMinergie == null) {
                $ctrl.pvTiedToMinergie = false;
            }
            // $ctrl.idForInfoField = $ctrl.translateBaseKey.split(".").join("_");

            // Used for the validation
            // $(toggle).attr("id", $attrs.reference + ".drop2down-toggle");
        };
    }
})();
