(function () {
    "use strict";

    angular.module("kennwerteApp")
        .component("modulBuy", {
            bindings: {
                hasFreeWeek: '<',
                currentLicenceValidUntil: '<',
                differentialPrice: '<',
                newLicencePrice: '<',
                organisation: '<',
                productTypes: '<',
                numAdditionalUsers: '<',
                baseLicense: '<',
                enableNumAddUsers: '<',
                orderProductsCallback: '<',
                buyProductsCallback: '<',
                numberOfCurrentWpts: '<?'
            },
            templateUrl: "app/account/billing/billing.edit/modul_buy.tpl.html",
            controller: ModulBuyController
        });

    ModulBuyController.$inject = ['$scope', 'moment'];

    function ModulBuyController($scope, moment) {
        var $ctrl = this;

        $ctrl.freeWeekFrom = moment.utc();

        $scope.$watch('$ctrl.freeWeekFrom', function() {
            $ctrl.freeWeekUntil = $ctrl.freeWeekFrom.clone().add(6, 'd');
        });

        $scope.$watch('[$ctrl.hasFreeWeek, $ctrl.freeWeekFrom, $ctrl.freeWeekUntil]', function() {
            $ctrl.currentLicenceValidFrom = ($ctrl.hasFreeWeek) ? $ctrl.freeWeekUntil.clone().add(1, 'd') : $ctrl.freeWeekFrom.clone();
            $ctrl.newLicenceValidFrom = ($ctrl.hasFreeWeek) ? $ctrl.freeWeekUntil.clone().add(1, 'd') : $ctrl.freeWeekFrom.clone();
            $ctrl.newLicenceValidUntil = $ctrl.newLicenceValidFrom.clone().add(1, 'y').subtract(1, 'd');
        });

        $ctrl.orderClicked = function() {
            if ($ctrl.hasFreeWeek) {
                $ctrl.orderProductsCallback($ctrl.productTypes);
            }
            else {
                $ctrl.buyProductsCallback($ctrl.productTypes);
            }
        };

    }
})();
