(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('luucy-connect', {
                parent: 'app',
                url: '/luucy-connect?requestId',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/luucy/luucy-connect.html',
                        controller: 'LuucyConnectController',
                        controllerAs: 'vm'
                    },
                    'logged-out@luucy-connect': {
                        templateUrl: 'app/luucy/logged-out/logged-out.html'
                    },
                    'logged-in@luucy-connect': {
                        templateUrl: 'app/luucy/logged-in/logged-in.html'
                    }
                },
                /*
                onExit:  ['$state', '$stateParams', 'LuucyRegisterService', function ($state, $stateParams, LuucyRegisterService) {
                    $state.transition.then(function(toState) {
                        if (toState.name !== 'luucy-connect') {
                            LuucyRegisterService.cancel({
                                    id: $stateParams.requestId
                                }, function() {},
                                function() {});
                        }
                    });
                }],
                 */
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('luucy-connect').addPart('settings');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
    }

})();
