(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('translationHandler', translationHandler);

    translationHandler.$inject = ['$rootScope', '$window', '$state', '$translate'];

    function translationHandler($rootScope, $window, $state, $translate) {
        return {
            initialize: initialize,
            updateTitle: updateTitle
        };

        function initialize() {
            // if the current translation changes, update the window title
            /*var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function() {
                // updateTitle();
            });*/

           /* $rootScope.$on('$destroy', function() {
                if (angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null) {
                    translateChangeSuccess();
                }
            });*/

            // $rootScope.$on('$translatePartialLoaderStructureChanged', function() {
            //     $translate.refresh().catch(function(e) {
            //         console.warn(e);
            //     });
            //
            // });

            /*$rootScope.$on('$translateChangeError', function() {
                    console.warn("$translateChangeError");
            });*/
        }

        // update the window title using params in the following
        // precedence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'
        function updateTitle(titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            if (titleKey == null) {
                titleKey = 'global.title';
            }
                $translate(titleKey).then(function(title) {
                    $window.document.title = title;
                }).catch(function(e) {
                    console.warn('global title translation issue', e);
                });
        }
    }
})();
