(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('UpdateLicenseModulesModalController', UpdateLicenseModulesModalController);

    UpdateLicenseModulesModalController.$inject = ['$uibModalInstance', 'additionalWpts', 'validUntil',
        '$scope', '$state', 'BillingService', '$location', '$timeout'];

    function UpdateLicenseModulesModalController($uibModalInstance, additionalWpts, validUntil,
                                          $scope, $state, BillingService, $location, $timeout) {
        var vm = this;

        // variables
        vm.errorPaymentFormError = null;
        vm.errorPaymentFormSystemError = null;
        vm.additionalWpts = additionalWpts;
        vm.validUntil = validUntil;
        vm.isAuthorized = null;
        vm.isClicked = false;

        vm.priceForCurrentWindow = null;
        vm.priceForCurrentWindowInclVat = null;
        vm.vat = null;
        vm.effectiveVat = null;
        vm.priceForNextWindow = null;

        // functions
        vm.cancel = cancel;
        vm.buyAdditionalModules = buyAdditionalModules;
        vm.authorizeUpdatePayment = authorizeUpdatePayment;

        BillingService.fetchAdditionalPrice(0, additionalWpts, 0).then(
            function (ret) {
                vm.priceForCurrentWindow = ret.data.priceForCurrentWindow;
                vm.priceForCurrentWindowInclVat = ret.data.priceForCurrentWindowInclVat;
                vm.vat = ret.data.vat;
                vm.effectiveVat = ret.data.effectiveVat;
                vm.priceForNextWindow = ret.data.priceForNextWindow;
            });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
            $state.go('billing', {
                paymentMeansSuccess: null,
                paymentFormSuccess: null
            }, {reload: true});
        }

        function authorizeUpdatePayment() {
            buyAdditionalModules();
        }

        function buyAdditionalModules() {
            if(vm.isClicked){
                return;
            }
            vm.isClicked = true;

            var n = $location.$$absUrl.lastIndexOf($location.$$url);
            var contextPath = $location.$$absUrl.slice(0, n);
            var dto = {
                successUrl: contextPath + "/saferpay-success",
                failUrl: contextPath + "/saferpay-error",
                abortUrl: contextPath + "/saferpay-abort",
                payerLanguageCode: "DE",
                numAdditionalUsers: 0,
                currentNumMaxUsers: 0,
                additionalWithdrawalProductTypes: vm.additionalWpts
            };
            BillingService.directPaymentFullModule2020(
                dto,
                function(response) {
                    vm.isAuthorized = response.data.isAuthorized;
                    vm.invalidCard = response.data.isInvalidCard;
                    if (!vm.isAuthorized) {
                        vm.errorPaymentFormError = null;
                    } else {
                        // SUCCESS
                        vm.errorPaymentFormError = null;
                        $timeout(function() {
                            cancel();
                        }, 2000);
                    }
                },
                function(errorResponse) {
                    vm.errorPaymentFormError = 'ERROR';
                });
        }

    }

})();
