(function () {
    "use strict";

    angular.module("kennwerteApp")
        .controller("BillingController", BillingController);

    BillingController.$inject = ["$state",
        "$stateParams", "$scope", "BillingService",
        "Organisation", "OrganisationUser", "OrganisationInvite", "WithdrawalProductService",
        "WptFreeTrackingService", "BaseLicenseService", "$uibModal", "ExistingWptService", "moment", "Principal"];

    function BillingController($state, $stateParams, $scope, BillingService,
                               Organisation, OrganisationUser, OrganisationInvite, WithdrawalProductService,
                               WptFreeTrackingService, BaseLicenseService, $uibModal, ExistingWptService, moment, Principal) {
        var vm = this;

        // license
        vm.baseLicense = null;
        vm.pendingBaseLicense = null;
        vm.activeWp = null;
        vm.pendingWp = null;
        vm.wptFreeTracking = null;
        vm.priceCurrentLicense = null;
        vm.numAvailableAccountsToAdd = null;
        // license edit properties
        vm.numAccountsToAdd = null;
        vm.numAccountsToRemove = null;
        vm.newAllWithdrawalProducts = null;

        vm.isBlankLicense = false;
        vm.isSchoolLicense = false;

        // the maximum amount of users which are allowed to remove
        vm.numMaxUsersToRemove = 0;

        vm.paymentFormSuccess = $stateParams.paymentFormSuccess;
        vm.paymentMeansSuccess = $stateParams.paymentMeansSuccess;

        // functions
        vm.fetchWithdrawalProduct = fetchWithdrawalProduct;
        vm.pendingWithdrawalProduct = pendingWithdrawalProduct;
        vm.renewLicense = renewLicense;
        vm.deleteLicense = deleteLicense;

        vm.showUpdateLicenseModal = showUpdateLicenseModal;
        vm.showUpdateLicenseRemoveModal = showUpdateLicenseRemoveModal;

        vm.getWptFreeTrackingDateTimeFreeUntilIfPresent = getWptFreeTrackingDateTimeFreeUntilIfPresent;

        vm.activateModule = useFreeMonth;
        vm.cancelFreeMonth = cancelFreeMonth;
        vm.isCurrentlyFree = isCurrentlyFree;
        vm.onManipulateLicenseSuccessFn = onManipulateLicenseSuccessFn;
        vm.isFutureDiffPayment = isFutureDiffPayment;
        vm.getDateTimeValidUntilForFutureDiffPayment = getDateTimeValidUntilForFutureDiffPayment;
        // init
        vm.fetchWithdrawalProduct();
        vm.pendingWithdrawalProduct();

        function endsWith(str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function fetchWptFreeTracking(orgId) {
            WptFreeTrackingService.queryByOrg({orgId: orgId}).$promise.then(
                function (wptTracking) {
                    vm.wptFreeTracking = wptTracking;
                    possibleFreeModule();
                    console.log(wptTracking);
                }
            );
        }

        function fetchWithdrawalProduct() {
            WithdrawalProductService.current_for_user().$promise.then(
                function (activeWp) {
                    BillingService.fetchPrice(activeWp.id).then(
                        function (res) {
                            vm.price = res.data;
                            vm.paymentMeans = activeWp.paymentMeans;
                            vm.activeWp = activeWp;
                            vm.freeMode = activeWp.licenseState === 'FREE';
                            console.warn(activeWp.licenseState);
                            vm.numAvailableAccountsToAdd = 100 - vm.activeWp.numMaxUsers;

                            vm.licenseState = activeWp.licenseState;

                            Organisation.current().$promise.then(function (org) {
                                vm.organisation = org;
                                fetchWptFreeTracking(org.id);
                                OrganisationUser.query_users({id: vm.organisation.id},
                                    function (data, headers) {
                                        var users = data
                                            .filter(function (u) {
                                                return !endsWith(u.login, ' orphaned') && !(_.startsWith(u.email, 'org#') && endsWith(u.email, "@org"));
                                            });
                                        vm.usersSize = users.length;
                                        if (vm.invitesSize || vm.invitesSize === 0) {
                                            vm.numMaxUsersToRemove = vm.activeWp.numMaxUsers - vm.usersSize - vm.invitesSize;
                                        }
                                    }, function (error) {
                                        $state.go('error');
                                    });
                                OrganisationInvite.query_all({ id: vm.organisation.id },
                                    function(response) {
                                        vm.invitesSize = response.data.length;
                                        if (vm.usersSize || vm.usersSize === 0) {
                                            vm.numMaxUsersToRemove = vm.activeWp.numMaxUsers - vm.usersSize - vm.invitesSize;
                                        }
                                    },
                                    function(error) {
                                        $state.go("error");
                                    });
                            });

                        });
                    BaseLicenseService.get({ id: activeWp.baseLicenseId }).$promise.then(
                        function(baseLicense) {
                            vm.baseLicense = baseLicense;
                            vm.discountInPercentIfSiaIndividual = baseLicense.discountInPercentIfSiaIndividual;
                            vm.discountInPercentIfSiaCorporate = baseLicense.discountInPercentIfSiaCorporate;
                            vm.isBlankLicense = _.trim(vm.baseLicense.name) === "Blank Lizenz";
                            vm.isSchoolLicense = _.trim(vm.baseLicense.name) === "Blank Lizenz Schule";
                            vm.isLuucyLicense = _.trim(vm.baseLicense.name) === "Blank Lizenz Luucy";
                            if (activeWp.dateTimeInactive || vm.licenseState === "INVALID") {
                                BaseLicenseService.actives().$promise.then(
                                    function(res) {
                                        vm.baseLicenses = res;
                                    });
                            }
                        });
                    Principal.identity(true);
                    },

                function (errorResponse) {
                    $state.go('error');
                }
            );
        }

        function pendingWithdrawalProduct() {
            WithdrawalProductService.current_pending_for_user().$promise.then(
                function (pendingWp) {
                    BillingService.fetchPrice(pendingWp.id).then(
                        function (res) {
                            vm.pendingPrice = res.data;
                            vm.pendingWp = pendingWp;
                            $scope.pendingWp = pendingWp;
                            vm.pendingFreeMode = pendingWp.licenseState === 'FREE';
                            vm.pendingNumAvailableAccountsToAdd = 100 - vm.pendingWp.numMaxUsers;

                            vm.pendingWp.licenseState = pendingWp.licenseState;

                            Organisation.current().$promise.then(function (org) {
                                vm.organisation = org;
                                OrganisationUser.query_users({id: vm.organisation.id},
                                    function (data, headers) {
                                        var users = data
                                            .filter(function (u) {
                                                return !endsWith(u.login, ' orphaned') && !(_.startsWith(u.email, 'org#') && endsWith(u.email, "@org"));
                                            });
                                        vm.pendingUsersSize = users.length;
                                        if (vm.pendingInvitesSize || vm.pendingInvitesSize === 0) {
                                            vm.pendingNumMaxUsersToRemove = vm.pendingWp.numMaxUsers - vm.pendingUsersSize - vm.invitesSize;
                                        }
                                    }, function (error) {
                                        $state.go('error');
                                    });

                                OrganisationInvite.query_all({id: vm.organisation.id},
                                    function (response) {
                                        vm.pendingInvitesSize = response.data.length;
                                        if (vm.pendingUsersSize || vm.usersSize === 0) {
                                            vm.pendingNumMaxUsersToRemove = vm.pendingWp.numMaxUsers - vm.pendingUsersSize - vm.invitesSize;
                                        }
                                    },
                                    function (error) {
                                        $state.go('error');
                                    });
                            });
                        });
                    BaseLicenseService.get({id: pendingWp.baseLicenseId}).$promise.then(
                        function (baseLicense) {
                            vm.pendingBaseLicense = baseLicense;
                            vm.pendingDiscountInPercentIfSiaIndividual = baseLicense.discountInPercentIfSiaIndividual;
                            vm.pendingDiscountInPercentIfSiaCorporate = baseLicense.discountInPercentIfSiaCorporate;
                            if (!pendingWp.dateTimeInactive || pendingWp.licenseState === 'INVALID') {
                                BaseLicenseService.actives().$promise.then(
                                    function (res) {
                                        vm.pendingBaseLicense = res;
                                    });
                            }
                        });
                },
                function (errorResponse) {
                    console.info('Failed to get pending license', errorResponse);
                }
            );
        }

        function renewLicense() {
            BillingService.enableLicenseRenewal(
                function (response) {
                    $state.go('billing', {
                        paymentMeansSuccess: null,
                        paymentFormSuccess: null
                    }, {reload: true});
                },
                function (error) {
                    console.error('Failed to renew license');
                }
            );
        }

        function deleteLicense() {
            BillingService.removeLicenseRenewal(
                function (ret) {
                    $state.go('billing', {
                        paymentMeansSuccess: null,
                        paymentFormSuccess: null
                    }, {reload: true});
                },
                function (error) {
                    console.error("Failed to remove license renewal");
                });
        }

        function showUpdateLicenseModal(numAdditionalUsers, currentNumMaxUsers, additionalWpts, freeMode, licenseState, validUntil) {
            $uibModal.open({
                templateUrl: 'app/account/billing/update_license_additional.modal.html',
                controller: 'UpdateLicenseModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    numAdditionalUsers: function () {
                        return numAdditionalUsers;
                    },
                    currentNumMaxUsers: function () {
                        return currentNumMaxUsers;
                    },
                    additionalWpts: function () {
                        return additionalWpts;
                    },
                    freeMode: function () {
                        return freeMode;
                    },
                    licenseState: function() {
                        return licenseState;
                    },
                    validUntil: function () {
                        return validUntil;
                    }
                }
            });
        }

        function showUpdateLicenseRemoveModal(numAccountsToRemove, currentNumMaxUsers, wptsToRemove, freeUntil, validUntil, freeMode) {
            $uibModal.open({
                templateUrl: 'app/account/billing/update_license_remove.modal.html',
                controller: 'UpdateLicenseRemoveModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    numUsersToRemove: function () {
                        return numAccountsToRemove;
                    },
                    currentNumMaxUsers: function () {
                        return currentNumMaxUsers;
                    },
                    wptsToRemove: function () {
                        return wptsToRemove;
                    },
                    freeUntil: function () {
                        return freeUntil;
                    },
                    validUntil: function() {
                        return validUntil;
                    },
                    freeMode: function () {
                        return freeMode;
                    }
                }
            });
        }

        function getWptFreeTrackingDateTimeFreeUntilIfPresent(wpt) {
            var result = vm.wptFreeTracking.filter(function(obj) {
                return obj.wpt === wpt;
            });

            if (result.length > 0) {
                return result[0]["dateTimeFreeUntil"];
            }
            return "";
        }

        function possibleFreeModule() {
            vm.freeWtp = ExistingWptService.getExistingWpt().filter(function(wpt) {
                var receivedModules = vm.wptFreeTracking.map(function(track) {
                    return track.wpt;
                });
                return !_.includes(receivedModules, wpt);
            });
        }

        function useFreeMonth(product) {
            //Todo Payment2020
            WptFreeTrackingService.activateModule(product)
                .$promise.then(
                function(res) {
                    onManipulateLicenseSuccessFn();
                    console.warn(res);

                },
                function(error) {
                    console.warn(error);

                });
        }

        function isCurrentlyFree(wpt) {
            var res = vm.wptFreeTracking.filter(function(res) {
                return res.wpt === wpt;
            });

            if (res && res.length > 0) {
                return moment.utc().isBefore(moment.parseZone(res[0]["dateTimeFreeUntil"]));
            }
            return "";

        }

        function cancelFreeMonth(product) {
            //Todo Payment2020
            WptFreeTrackingService.cancelModule(product).$promise.then(
                function(res) {
                    onManipulateLicenseSuccessFn();
                    console.warn(res);

                },
                function(error) {
                    console.warn(error);

                });
        }


        function updatePrincipal(){
            return Principal.identity(true);
        }

        function onManipulateLicenseSuccessFn() {
            fetchWithdrawalProduct();
            pendingWithdrawalProduct();
            updatePrincipal();
        }

        function isFutureDiffPayment(track) {
            return moment.utc().isBefore(track.dateTimeFreeUntil) && !track.dateTimeInactive;
        }

        function getDateAndModulesWithFutureDiffPayment(track) {
            if (!!vm.wptFreeTracking && !(vm.activeWp && vm.activeWp.licenseStillValid && vm.activeWp.dateTimeInactive)) {

            } else {
                return [];
            }
        }

        function getDateTimeValidUntilForFutureDiffPayment() {
            if (vm.activeWp.licenseState !== 'FREE') {
                return vm.activeWp.dateTimeValidUntil;
            } else {
                return moment(vm.activeWp.dateTimeValidUntil).add('years', 1).toISOString();
            }
        }
    }
})();
