(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('kwImageModelUpload', {
            bindings: {
                reference: '=?', // acts as counter of valid files to become uploaded
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@',
                descriptionBaseKey: '@',
                usages: '<',
                optional: '<?',
                infotext: '@?',
                attachmentType: '@?'
            },
            templateUrl: 'app/components/kw_image_model_upload/kw-image-model-upload.html',
            controller: FileUploadController
        });

    FileUploadController.$inject = ['$scope', '$rootScope', '$window', 'KwImageModelService', '$attrs', '$element'];

    function FileUploadController($scope, $rootScope, $window, KwImageModelService, $attrs, $element) {
        //TODO remove console.logs
        var $ctrl = this;

        $ctrl.weightsInside = {
            'Bathroom': 3,
            'Kitchen': 2,
            'Other room, furnished': 1,
            'Other room, unfurnished': 1,
            'Building plan': 1,
            'Staircase': 1
        };

        $ctrl.weightsOutside = {
            'Facade (outside)': 1
        };

        $ctrl.stdTotal = undefined;

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        function createKey(file) {
            return file.name + '_' + file.$ngfWidth + '_' + file.$ngfHeight + '_' + file.lastModified;
        }

        $ctrl.selectFiles = function (files, newFiles, invalidFiles) {
            angular.forEach(newFiles, function (file) {
                file.kwStd = undefined;
                file.kwType = undefined;
                var key = createKey(file);
                var isFileReplaced = false;
                $ctrl.files.forEach(function (f, i) {
                    if (angular.equals(createKey(f), key)) {
                        $ctrl.files[i] = f;
                        isFileReplaced = true;
                    }
                });
                if (!isFileReplaced) {
                    $ctrl.files.push(file);
                }
                KwImageModelService.evalImage(file, function (response) {
                    file.kwStd = Math.round(response.data.std * 10) / 10;
                    file.kwType = response.data.type;
                    if (!KwImageModelService.isEvalImageOnProgress()) {
                        // preset usage to new standard
                        var sumWeightsOutside = 0;
                        var sumWeightedStdsOutside = 0;
                        var sumWeightsInside = 0;
                        var sumWeightedStdInside = 0;
                        angular.forEach($ctrl.files, function(f) {
                            if (f.kwType in $ctrl.weightsInside) {
                                var weight = $ctrl.weightsInside[f.kwType];
                                sumWeightsInside += weight;
                                sumWeightedStdInside += weight * f.kwStd;
                            } else if (f.kwType in $ctrl.weightsOutside) {
                                var weight = $ctrl.weightsOutside[f.kwType];
                                sumWeightsOutside += weight;
                                sumWeightedStdsOutside += weight * f.kwStd;
                            }
                        });
                        $ctrl.stdTotal = 0;
                        if (sumWeightsOutside > 0) {
                            $ctrl.stdTotal += sumWeightedStdsOutside / sumWeightsOutside;
                        }
                        if (sumWeightsInside > 0) {
                            $ctrl.stdTotal += sumWeightedStdInside / sumWeightsInside;
                            if (sumWeightsOutside > 0) {
                                $ctrl.stdTotal = $ctrl.stdTotal / 2;
                            }
                        }
                        $ctrl.stdTotal = Math.round($ctrl.stdTotal * 10) / 10;
                        KwImageModelService.setStdAllSpecialUsages($ctrl.realEstateContainer, $ctrl.stdTotal);
                    }
                });
            });
            var cntValidFiles = 0;
            angular.forEach($ctrl.files, function (file) {
                if (!file.$error) {
                    cntValidFiles += 1;
                }
            });
            $ctrl.reference = cntValidFiles; // count valid selected files
        };

        $ctrl.deleteSelected = function (file, index) {
            $ctrl.files.splice(index, 1);
            if (!KwImageModelService.isEvalImageOnProgress()) {
                // preset usage to new standard
                var sumWeightsOutside = 0;
                var sumWeightedStdsOutside = 0;
                var sumWeightsInside = 0;
                var sumWeightedStdInside = 0;
                angular.forEach($ctrl.files, function(f) {
                    if (f.kwType in $ctrl.weightsInside) {
                        var weight = $ctrl.weightsInside[f.kwType];
                        sumWeightsInside += weight;
                        sumWeightedStdInside += weight * f.kwStd;
                    } else if (f.kwType in $ctrl.weightsOutside) {
                        var weight = $ctrl.weightsOutside[f.kwType];
                        sumWeightsOutside += weight;
                        sumWeightedStdsOutside += weight * f.kwStd;
                    }
                });
                $ctrl.stdTotal = 0;
                if (sumWeightsOutside > 0) {
                    $ctrl.stdTotal += sumWeightedStdsOutside / sumWeightsOutside;
                }
                if (sumWeightsInside > 0) {
                    $ctrl.stdTotal += sumWeightedStdInside / sumWeightsInside;
                    if (sumWeightsOutside > 0) {
                        $ctrl.stdTotal = $ctrl.stdTotal / 2;
                    }
                }
                $ctrl.stdTotal = Math.round($ctrl.stdTotal * 10) / 10;
                KwImageModelService.setStdAllSpecialUsages($ctrl.realEstateContainer, $ctrl.stdTotal);
            }
        };

        $ctrl.openImage = function (file) {
            $window.open(file.$ngfBlobUrl, '_blank');
        };

        $rootScope.$on('allAttachmentsUploaded', function () {
            $ctrl.files.splice(0, $ctrl.files.length);
            $ctrl.reference = 0;
        });


        this.$onInit = function () {
            $ctrl.files = [];
            $ctrl.reference = 0;
            $ctrl.options = true;
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.translateButton = $ctrl.translateBaseKey + '.button';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            if (angular.isUndefined($ctrl.reference))
                $ctrl.reference = {};
        };

        var toggle = $element.find(".fileInputButton");
        $(toggle).attr("id", $attrs.reference + ".input");

    }
})();
