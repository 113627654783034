(function () {
    'use struct';

    angular.module('kennwerteApp')
        .component('pictureUpload', {
            bindings: {
                reference: '=?',
                rowid: '=',
                translateBaseKey: '@',
                mandatory: '@?',
                descriptionBaseKey: '@',
                infotext: '@?',
                validationMessage: '@'
            },
            templateUrl: 'app/components/picture_upload/picture-upload.tpl.html',
            controller: PictureUploadController
        });

    PictureUploadController.$inject = ['$scope', '$rootScope', '$window', '$attrs', '$element', '$timeout', 'Upload'];

    function PictureUploadController($scope, $rootScope, $window, $attrs, $element, $timeout, Upload) {
        //TODO remove console.logs
        var $ctrl = this;
        $ctrl.maxImagePixels = 2048 * 1080; // ultra hd

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        function createKey(file) {
            return file.name + '_' + file.$ngfWidth + '_' + file.$ngfHeight + '_' + file.lastModified;
        }

        $ctrl.selectFiles = function (files, newFiles, invalidFiles) {
            angular.forEach(newFiles, function (file) {
                var key = createKey(file);
                var isFileReplaced = false;
                $ctrl.files.forEach(function (f, i) {
                    if (angular.equals(createKey(f), key)) {
                        $ctrl.files[i] = f;
                        isFileReplaced = true;
                    }
                });
                if (!isFileReplaced) {
                    $ctrl.files.push(file);
                }
            });
            var cntValidFiles = 0;
            angular.forEach($ctrl.files, function(file) {
                if (!file.$error) {
                    cntValidFiles += 1;
                }
            });

            $ctrl.uploadPictures();

            $ctrl.cnt = cntValidFiles; // count valid selected files
        };

        $ctrl.deleteSelected = function (file, index) {
            //$ctrl.files.splice(index, 1);
            _.remove($ctrl.reference, function(it) {
                return it.name === file.name;
            });
            //$ctrl.reference.splice(index, 1);
            $ctrl.cnt.length -= 1; // a valid file is removed, thus also decrease reference
        };

        $ctrl.magicButton = function (file) {
            var fileURL = URL.createObjectURL(file);
            $window.open(fileURL, '_blank');
        };

        $ctrl.openImage = function (file) {
            $window.open(file.thumbnailPath, '_blank');
        };

        function resizeOptions(file) {
            var width = file.$ngfWidth;
            var height = file.$ngfHeight;
            var wh = width * height;
            var factor = Math.sqrt($ctrl.maxImagePixels / wh);
            return {
                width: factor * width,
                height: factor * height,
                quality: 0.9
            };
        }

        $ctrl.uploadPictures = function () {
            $ctrl.progressCounter = 0;
            $ctrl.successCounter = 0;
            $ctrl.uploadStarted = true;
            $rootScope.$broadcast('uploadStartedEvent');
            if ($ctrl.files.length > 0) {
                for (var i = 0; i < $ctrl.files.length; i++) {
                    var f = $ctrl.files[i];
                    if (!!f.isUploaded) {
                        $ctrl.progressCounter++;
                        continue;
                    }
                    if (!f.$error) {
                        var data = {
                            file: f
                        };
                        if (f.$ngfWidth * f.$ngfHeight > $ctrl.maxImagePixels) {
                            f.upload = Upload.resize(f, resizeOptions(f))
                                .then(function (resizedFile) {
                                    data.file = resizedFile;
                                    return Upload.upload({
                                        url: '/api/small-building-attachments',
                                        data: data,
                                        headers: {'Content-Type': 'multipart/form-data'}
                                    });
                                });
                        } else {
                            f.upload = Upload.upload({
                                url: '/api/small-building-attachments',
                                data: data,
                                headers: {'Content-Type': 'multipart/form-data'}
                            });
                        }

                        f.upload.then(function (response) {
                            $timeout(function () {
                                $ctrl.progressCounter++;
                                $ctrl.successCounter++;
                                // success
                                $ctrl.reference.push({
                                    "uuid": response.data.uuid,
                                    "name": response.data.name,
                                    "thumbnailPath": response.data.thumbnailPath,
                                    "error": false
                                });
                                f.isUploaded = true;
                                /*
                                // remove from files
                                _.remove($ctrl.files, function(it) {
                                    return it.name === f.name;
                                });
                                 */
                                if ($ctrl.progressCounter === $ctrl.files.length) {
                                    allUploaded()
                                }
                                f.upload = null;
                            });
                        }, function (response) {
                            $ctrl.progressCounter++;
                            $ctrl.reference.push({
                                "uuid": null,
                                "name": response.data.name,
                                "thumbnailPath": null,
                                "error": true
                            });
                            if ($ctrl.progressCounter === $ctrl.files.length) {
                                allUploaded()
                                f.isUploaded = true;
                                /*
                                // remove from files
                                _.remove($ctrl.files, function(it) {
                                    return it.name === f.name;
                                });
                                 */
                            }
                            f.upload = null;
                        })
                    }
                }
            } else {
                allUploaded()
            }
        }

        function allUploaded() {
            $ctrl.progressCounter = 0;
            $ctrl.successCounter = 0;
            $ctrl.uploadStarted = false;
            $ctrl.files = [];
            $rootScope.$broadcast('uploadFinishedEvent');
        }

        this.$onInit = function () {
            $ctrl.files = [];
            $ctrl.cnt = 0;
            $ctrl.options = true;
            $ctrl.progressCounter = 0;
            $ctrl.successCounter = 0;
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.translateButton = $ctrl.translateBaseKey + '.button';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            $ctrl.uploadStarted = false;
            $rootScope.$broadcast('uploadFinishedEvent');
        };

        var toggle = $element.find(".fileInputButton");
        $(toggle).attr("id", $attrs.reference + ".input");

    }


})();
