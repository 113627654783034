(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .factory('smallBuildingAgvUsageTypeGroupFactory', smallBuildingAgvUsageTypeGroupFactory);

    smallBuildingAgvUsageTypeGroupFactory.$inject = [];

    function smallBuildingAgvUsageTypeGroupFactory() {
        var data = {
            '00': [
                { 'id': '50.1', 'parent': true },
                { 'id': '50.2', 'parent': true },
                { 'id': '50.3', 'parent': true },
                { 'id': '50.4', 'parent': true },
                { 'id': '50.5', 'parent': true },
                { 'id': '50.6', 'parent': true },
                { 'id': '50.7', 'parent': true },
                { 'id': '50.8', 'parent': true }
            ],
            '50.1': [
                { 'id': '50.1.4', 'parent': false },
                { 'id': '50.1.6', 'parent': false },
                { 'id': '50.1.2', 'parent': false },
                { 'id': '50.1.1', 'parent': false },
                { 'id': '50.1.7', 'parent': false },
                { 'id': '50.1.5', 'parent': false },
                { 'id': '50.1.3', 'parent': false }
            ],
            '50.2': [
                { 'id': '50.2.5', 'parent': false },
                { 'id': '50.2.7', 'parent': false },
                { 'id': '50.2.3', 'parent': false },
                { 'id': '50.2.1', 'parent': false },
                { 'id': '50.2.4', 'parent': false },
                { 'id': '50.2.2', 'parent': false },
                { 'id': '50.2.8', 'parent': false },
                { 'id': '50.2.6', 'parent': false },
                { 'id': '50.2.9', 'parent': false }
            ],
            '50.3': [
                { 'id': '50.3.1', 'parent': false },
                { 'id': '50.3.3', 'parent': false },
                { 'id': '50.3.4', 'parent': false },
                { 'id': '50.3.2', 'parent': false }
            ],
            '50.4': [
                { 'id': '50.4.1', 'parent': false },
                { 'id': '50.4.7', 'parent': false },
                { 'id': '50.4.6', 'parent': false },
                { 'id': '50.4.4', 'parent': false },
                { 'id': '50.4.3', 'parent': false },
                { 'id': '50.4.5', 'parent': false },
                { 'id': '50.4.2', 'parent': false }
            ],
            '50.5': [
                { 'id': '50.5.1', 'parent': false },
                { 'id': '50.5.2', 'parent': false },
                { 'id': '50.5.4', 'parent': false },
                { 'id': '50.5.3', 'parent': false }
            ],
            '50.6': [
                { 'id': '50.6.4', 'parent': false },
                { 'id': '50.6.3', 'parent': false },
                { 'id': '50.6.1', 'parent': false },
                { 'id': '50.6.2', 'parent': false },
                { 'id': '50.6.6', 'parent': false },
                { 'id': '50.6.5', 'parent': false }
            ],
            '50.7': [
                { 'id': '50.7.2', 'parent': false },
                { 'id': '50.7.4', 'parent': false },
                { 'id': '50.7.5', 'parent': false },
                { 'id': '50.7.3', 'parent': false },
                { 'id': '50.7.1', 'parent': false }
            ],
            '50.8': [
                { 'id': '50.8.1', 'parent': false },
                { 'id': '50.8.7', 'parent': false },
                { 'id': '50.8.8', 'parent': false },
                { 'id': '50.8.5', 'parent': false },
                { 'id': '50.8.4', 'parent': false },
                { 'id': '50.8.6', 'parent': false },
                { 'id': '50.8.9', 'parent': false },
                { 'id': '50.8.3', 'parent': false },
                { 'id': '50.8.2', 'parent': false }
            ]
        };


        function getAllPreviousIds(id) {
            var ret = [];
            while (!angular.equals(id, '00')) {
                id = getGroupIdById(id);
                ret.push(id);
            }
            return ret;
        }

        function getGroupIdById(id) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (id === obj.id) {
                        return key;
                    }
                }
            }
            return '00';
        }

        function getById(id) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (id == obj.id) {
                        return obj;
                    }
                }
            }
            return {};
        }

        return {
            load: function(id) {
                return data[id];
            },
            getById: function(id) {
                return getById(id);
            },
            getGroupIdById: function(id) {
                return getGroupIdById(id);
            },
            getAllPreviousGroups: function(groupId) {
                var prevGroups = [];
                var prevIds = getAllPreviousIds(groupId);
                prevIds.forEach(function(prevId) {
                    var prevGroup = getById(prevId);
                    if (!angular.equals({}, prevGroup)) {
                        prevGroups.push(getById(prevId));
                    }
                });
                return prevGroups;
            },
            // getPreviousGroup: function (title) {
            //     var obj = getByTitle(title);
            //     var groupId = getGroupIdById(obj.id);
            //     return getById(groupId);
            // },
            getTitleOfPrevious: function (title) {
                var obj = getById(title);
                var groupId = getGroupIdById(obj.id);
                return getById(groupId).title;
            }
        };
    }
})();
