function showDetailInfo(index) {
    var n = index.length;
    var toToggle = '#' + index.substring(0, n - 2);
    $(toToggle).toggle();
}

(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('process-form', {
                parent: 'app',
                abstract: true,
                url: '/process-form',
                views: {
                    // Fill 'ui-view="content"' of 'index.html'
                    'content@': {
                        templateUrl: "app/process_form/process_form.html",
                        controller: 'ProcessFormController',
                        controllerAs: 'vm'
                    },
                    'input@process-form': {
                        templateUrl: 'app/process_form/10_input/input.html',
                        controller: 'InputController',
                        controllerAs: 'vm'
                    },
                    'calculating_error@process-form': {
                        templateUrl: 'app/process_form/20_output/10_calculating_error/calculating_error.html',
                        controller: 'CalculatingErrorController',
                        controllerAs: 'vm'
                    },
                    'calculating_success@process-form': {
                        templateUrl: 'app/process_form/20_output/11_calculating_success/calculating_success.html',
                        controller: 'CalculatingSuccessController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    data: null,
                    withdrawalProductType: null,
                    realEstateId: null,
                    realEstate: null
                },
                //controller: 'ProcessFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.building-costs.input'
                },
                resolve: {
                    resetIsCompleteState: ['ValidationDataService', function (ValidationDataService) {
                        ValidationDataService.resetComplete();
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form');
                        $translatePartialLoader.addPart('realestates');
                        $translatePartialLoader.addPart('operational-costs_form');
                        $translatePartialLoader.addPart('model_complexity');
                        $translatePartialLoader.addPart('pom');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('process-form.input', {
                url: '/input/{withdrawalProductType}',
                //controller: 'ProcessFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.building-costs.input',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        '10_howto_section',
                        '20_general_form',
                        '30_geometry_form',
                        '40_complexity_form',
                        '50_quality_form',
                        '60_additions_form',
                        '70_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ["$state", "BillingService", function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === "INVALID") {
                                    $state.go("insufficient-rights.license");
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e){console.warn(e)});
                    }],
                    check: ["$state", "Principal", "$timeout", function ($state, Principal, $timeout) {
                        Principal.identity().then(function (e) {
                            if (!!e && !Principal.hasModule("BUILDING_COSTS_AND_DATES")) {
                                $state.go("insufficient-rights.building-costs");
                                $timeout(function () {
                                    // $state.go("products");
                                    $state.go("insufficient-rights.building-costs");
                                }, 0);
                            }
                        }).catch(function(e){console.warn(e)});
                    }],
                    mainTranslatePartialLoader: ["$translate", "$translatePartialLoader", function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart("process_form");
                        $translatePartialLoader.addPart("model_realEstate");
                        $translatePartialLoader.addPart("model_geometry");
                        $translatePartialLoader.addPart('model_complexity');
                        $translatePartialLoader.addPart("model_quality");
                        $translatePartialLoader.addPart("operational-costs_form");
                        $translatePartialLoader.addPart("favorite");

                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                },
                params: {
                    realEstate: null
                }
            })
            .state('process-form.edit-record', {
                url: '/edit-record',
                //controller: 'ProcessFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'],
                    pageTitle: 'global.menu.building-costs.edit',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        '15_howto_edit_record',
                        '20_general_form',
                        '30_geometry_form',
                        '40_complexity_form',
                        '50_quality_form',
                        '60_additions_form',
                        '70_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e){console.warn(e)});
                    }],
                    mainTranslatePartialLoader: ["$translate", "$translatePartialLoader", function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart("process_form")
                            .addPart("model_realEstate")
                            .addPart("model_geometry")
                            .addPart('model_complexity')
                            .addPart("operational-costs_form")
                            .addPart("model_quality")
                            .addPart("favorite");
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    resolveModuleLoad: ["RealestateService", "$q", "$state", "$stateParams", "$timeout", "Principal",
                        function (RealestateService, $q, $state, $stateParams, $timeout, Principal) {
                            // first check principal
                            Principal.identity().then(function (e) {
                                if (!!e && !Principal.hasModule("BUILDING_COSTS_AND_DATES")) {
                                    $state.go("insufficient-rights.building-costs");
                                }
                            });
                            var thisModule = "BUILDING_COSTS_AND_DATES";
                            var deferred = $q.defer();
                            // session cache lesen - da params empty.
                            if ($stateParams.realEstateId == null) {
                                console.error("no real estate id");
                                // deferred.resolve(); //works with resolve but doesnt work with reject.
                                $state.go("building-costs.realestates");
                                deferred.reject("no real estate id");

                            } else {
                                RealestateService.getByIdCached($stateParams.realEstateId).then(function (res) {
                                    if (Principal.hasModule(res.metaData.withdrawalProductType)) {
                                        deferred.resolve(res);
                                        return deferred.promise;
                                    }
                                    var intersection = _.intersection(res.metaData.tasks, Principal.getModules());
                                    if (intersection.length == 0) {
                                        deferred.reject(res);
                                        $state.go("insufficient-rights.license");
                                    }
                                    if (_.includes(intersection, "OPERATING_COSTS")) {
                                        deferred.resolve(res);
                                        res.metaData.withdrawalProductType = "OPERATING_COSTS";
                                        res.metaData.tasks = ["OPERATING_COSTS"];
                                        $state.go("operational-costs.edit-record", {
                                            realEstateId: res.id,
                                            withdrawalProductType: "OPERATING_COSTS".toLowerCase()
                                        });
                                    }
                                    deferred.resolve(res);
                                }).catch(function(e){console.warn(e)});
                            }
                            return deferred.promise;
                        }]
                }
            });
    }
})();
