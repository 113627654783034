(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(uiSelectConfig);

    uiSelectConfig.$inject = ['uiSelectConfig'];

    function uiSelectConfig(uiSelectConfig) {
        uiSelectConfig.resetSearchInput = true;
        uiSelectConfig.appendToBody = false;
        uiSelectConfig.dropdownPosition = 'auto';
    }


})();
