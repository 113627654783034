(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('investmentInput', InvestmentInputDirective)
        .controller('investmentInputCtrl', InvestmentInputController);

    InvestmentInputController.$inject = ['$scope', '$rootScope'];
    function InvestmentInputController($scope, $rootScope) {
        var vm = this;

        vm.hasFocusOutFired = false;
        vm.onFocus = function () {
            vm.hasFocusOutFired = false;
            // Push event to CalculationRowController
            if($scope.value !== undefined && $scope.value!== ""){
                var strValueOut = $scope.value.toString();
                strValueOut = strValueOut.replace(/.-/g,'');
                $scope.value = strValueOut;
            }

            $rootScope.$broadcast("updateImage", {
                imageSrc: $scope.image,
                rowid: $scope.rowid
            });
            $rootScope.$broadcast("updateDescription", {
                rowid: $scope.rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $scope.descriptionBaseKey
            });
        };

        vm.onFocusOut = function () {
            if (vm.hasFocusOutFired) {
                return;
            }
            vm.hasFocusOutFired = true;
            if($scope.value !== undefined && $scope.value !== "") {
                $scope.value = $scope.value + ".-";
            }
            if ($scope.value > $scope.maxValue || $scope.value < $scope.minValue) {
                $scope.value = "";
            }
        };

        vm.onKeyUp = function () {
            try {
                var strValue = $scope.value.toString();
                strValue = strValue.replace(/\D/g, '');
                strValue = strValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
                $scope.value = strValue;
            }catch (err){
                console.log(err);
            }
        };
    }

    InvestmentInputDirective.$inject = ['$rootScope'];
    function InvestmentInputDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                minValue: '@',
                maxValue: '@',
                arrIndex: '=',
                type: '@',
                value: '=',
                year: '=',
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                rowid: '=',
                required: '=required',
                isDisabled: '=',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                index: '@',
                numberRestrict: '@',
                amount: '@',
                infotext: '@?'
            },
            templateUrl: 'app/components/investment/investment-input.tpl.html',
            controller: InvestmentInputController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs) {

                if(scope.numberRestrict){
                    scope.type = "number";
                } else{
                    scope.type = "text";
                }

                var inputFieldArr = tElement.find("input");

                if (tAttrs.required) {
                    inputFieldArr[0].required = true;
                    inputFieldArr[1].required = true;
                }

                // Used for the validation
                $(inputFieldArr[1]).attr("id", "investments." + scope.index + ".value.input");
                $(inputFieldArr[0]).attr("id", "investments." + scope.index + ".year.input");

                inputFieldArr[0].addEventListener("focus", function () {

                    // Push event to CalculationRowController
                    $rootScope.$broadcast("updateImage", {
                        imageSrc: sanitizeUrl(tAttrs.image),
                        rowid: Number(tAttrs.rowid)
                    });
                    $rootScope.$broadcast("updateDescription", {
                        rowid: Number(tAttrs.rowid),
                        descriptionNumber: 0,
                        descriptionBaseKey: tAttrs.descriptionBaseKey
                    });
                }, true);
            }
        };
    }

})();


