(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('NormalDistributionService', NormalDistributionService);

    NormalDistributionService.$inject = [];

    function NormalDistributionService() {

        function pdf(nd, x) {
            return 1 / (nd.sigma * Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * Math.pow((x - nd.mean) / nd.sigma, 2));
        }

        function cdf(nd)
        {
            return function(to) {
                return (1/2)*(1+erf((to - nd.mean)/Math.sqrt(2*nd.sigma*nd.sigma)));
            };
        }

        function cdfCutInterval(nd, cutFrom, cutTo)
        {
            var cdfO = cdf(nd);
            var cdfFromCut = cdfO(cutFrom);
            var cdfToCut = cdfO(cutTo);
            var cdfCut = cdfToCut - cdfFromCut;
            return function(to) {
                if (to < cutFrom) return cdfO(to) / (1 - cdfCut);
                if (cutTo <= to) return (cdfO(to) - cdfToCut + cdfFromCut) / (1 - cdfCut);
                else return cdfFromCut / (1 - cdfCut);
            };
        }

        function erfcinv(p) {
            var j = 0;
            var x, err, t, pp;
            if (p >= 2)
                return -100;
            if (p <= 0)
                return 100;
            pp = (p < 1) ? p : 2 - p;
            t = Math.sqrt(-2 * Math.log(pp / 2));
            x = -0.70711 * ((2.30753 + t * 0.27061) /
                (1 + t * (0.99229 + t * 0.04481)) - t);
            for (; j < 2; j++) {
                err = erfc(x) - pp;
                x += err / (1.12837916709551257 * Math.exp(-x * x) - x * err);
            }
            return (p < 1) ? x : -x;
        }

        function erfc(x) {
            return 1 - erf(x);
        }

        function erf(x) {
            var cof = [-1.3026537197817094, 6.4196979235649026e-1, 1.9476473204185836e-2,
                -9.561514786808631e-3, -9.46595344482036e-4, 3.66839497852761e-4,
                4.2523324806907e-5, -2.0278578112534e-5, -1.624290004647e-6,
                1.303655835580e-6, 1.5626441722e-8, -8.5238095915e-8,
                6.529054439e-9, 5.059343495e-9, -9.91364156e-10,
                -2.27365122e-10, 9.6467911e-11, 2.394038e-12,
                -6.886027e-12, 8.94487e-13, 3.13092e-13,
                -1.12708e-13, 3.81e-16, 7.106e-15,
                -1.523e-15, -9.4e-17, 1.21e-16,
                -2.8e-17
            ];
            var j = cof.length - 1;
            var isneg = false;
            var d = 0;
            var dd = 0;
            var t, ty, tmp, res;

            if (x < 0) {
                x = -x;
                isneg = true;
            }

            t = 2 / (2 + x);
            ty = 4 * t - 2;

            for (; j > 0; j--) {
                tmp = d;
                d = ty * d - dd + cof[j];
                dd = tmp;
            }

            res = t * Math.exp(-x * x + 0.5 * (cof[0] + ty * d) - dd);
            return isneg ? res - 1 : 1 - res;
        }

        function cdfInverse(nd) {
            return function(p) {
                return -1.41421356237309505 * nd.sigma * erfcinv(2 * p) + nd.mean;
            };
        }

        function get(mean, sigma) {
            return {
                'mean': mean,
                'sigma': sigma
            };
        }

        function add(nd1, nd2) {
            return {
                'mean': nd1.mean + nd2.mean,
                'sigma': Math.sqrt(Math.pow(nd1.sigma, 2) + Math.pow(nd2.sigma, 2))
            };
        }

        function scale(nd, scale_by) {
            return {
                'mean': nd.mean * scale_by,
                'sigma': nd.sigma * Math.abs(scale_by)
            };
        }

        function invertCustomCdf(cdf) {
            return function (p) {
                for (var i = 0; i < 100; i++) {
                    var cdfI = cdf(i);
                    if (p <= cdfI) return i;
                }
                return 100;
            };
        }


        return {
            // normal distribution functions
            get: get,
            add: add,
            scale: scale,
            pdf: pdf,
            cdf: cdf,
            cdfInverse: cdfInverse,
            // extra functions for cutting intervals from normal distribution
            cdfCutInterval: cdfCutInterval,
            invertCustomCdf: invertCustomCdf,
            getAdditionIdentity: function() { return get(0, 0) }
        };
    }

})();
