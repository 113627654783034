(function () {
    "use strict";

    angular.module("kennwerteApp")
        .component("licenseTable", {
            bindings: {
                activeWp: "=?",
                baseContainer: "=?",
                baseLicense: "=?",
                freeWpt: "=?",
                organisation: "=?",
                price: "=?",
                wptFreeTracking: "=?",
                translateDescriptionBaseKey: "@"
            },
            templateUrl: "app/account/billing/billing.active/license_table.tpl.html",
            controller: LicenseTableController
        });

    LicenseTableController.$inject = ["$scope", "$rootScope", "$q", "$location", "$element", "$translate", "$attrs", "ExistingWptService", "BillingService", "Principal", "moment", "WptFreeTrackingService"];

    function LicenseTableController($scope, $rootScope, $q, $location, $element, $translate, $attrs, ExistingWptService, BillingService, Principal, moment, WptFreeTrackingService) {
        var $ctrl = this;
        $ctrl.firstLoad = true;
        $ctrl.showModuleSelction = false;

        this.$onInit = function () {
            $ctrl.activeWpts = fetchActiveWpts();
        };

        // $scope.$watch('$ctrl.baseContainer.activeWp', function (newValue, oldValue) {
        //     if (newValue != null) {
        //         $ctrl.activeWpts = fetchActiveWpts();
        //     }
        // },true);

        $scope.$watch('$ctrl.baseContainer.wptFreeTracking', function (newValue, oldValue) {
            if (newValue != null) {
                $ctrl.activeWpts = fetchActiveWpts();
            }
        },true);

        function fetchActiveWpts() {
            // exclude inactive (but probably still usable) modules
            var inactiveWpts = $ctrl.baseContainer.wptFreeTracking.filter(function (track) {
                return !!track.dateTimeInactive;
            }).map(function (track) {
                return track.wpt;
            });
            var ret = _.difference($ctrl.baseContainer.activeWp.withdrawalProductTypes, inactiveWpts);
            return ret;
        }

        // function updateExistingWtp() {
        //     $ctrl.existingWtp = _.xor($ctrl.activeWpt,$ctrl.slimWptFreeTracking, ExistingWptService.existingWpt());
        //     $ctrl.existingWtp = _.map($ctrl.existingWtp, function(v) {
        //         return _.assign({}, { name: v }, { isSelected: false });
        //     });
        // }
    }
})();
