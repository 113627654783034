(function () {
    'use strict';

    angular.module('kennwerteApp')
        .service('BreakfastRoadshowService', BreakfastRoadshowService);

    BreakfastRoadshowService.$inject = ['$http'];

    function BreakfastRoadshowService($http) {
        var service =  {
            registerParticipant: registerParticipant
        };
        return service;

        function registerParticipant(participantDTO, responseFunction, errorFunction) {
            return $http.post('api/breakfast_roadshow/register', participantDTO)
                .then(function(response) {
                    return responseFunction(response);
                }, function(errorResponse) {
                    return errorFunction(errorResponse);
                });
        }
    }



})();
