(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('InsuranceCompleteInputController', InsuranceCompleteInputController);

    InsuranceCompleteInputController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'WithdrawalService', 'Principal', '$sessionStorage', '$filter', 'usageTypeGroupFactory', 'ValidationDataService', 'ValidationFunctionsService', 'SharedControllerFnService'];

    function InsuranceCompleteInputController($scope, $rootScope, $stateParams, $state, WithdrawalService, Principal, $sessionStorage, $filter, usageTypeGroupFactory, ValidationDataService, ValidationFunctionsService, SharedControllerFnService) {

        $scope.elementsWithError = [];
        $scope.isPomEnabled = false;
        $scope.validationDataService = ValidationDataService;

        ValidationDataService.deregisterValidationFunctions();
        ValidationDataService.setZindex(1048);
        ValidationDataService.registerValidationFunctions([
            ValidationFunctionsService.validateBuildingName,
            ValidationFunctionsService.validateZipCode,
            ValidationFunctionsService.validateMainUsage,
            ValidationFunctionsService.validateRatioVolumeUnderTerrain,
            ValidationFunctionsService.validateAreaBuilding416,
            ValidationFunctionsService.validateUsages,
            ValidationFunctionsService.validateBuildingsInConstruction,
            ValidationFunctionsService.validateArea,
            ValidationFunctionsService.validateVolume,
            ValidationFunctionsService.validateBuiltUpArea,
            ValidationFunctionsService.validateLeMinergie,
            ValidationFunctionsService.validateOvergroundFloors,
            ValidationFunctionsService.validateUndergroundFloors,
            ValidationFunctionsService.validateRemark,
            ValidationFunctionsService.validateSumOfFloors,
            ValidationFunctionsService.validateFacadeType,
            ValidationFunctionsService.validateUndergroundParkplace,
            ValidationFunctionsService.validateElevators,
            ValidationFunctionsService.validateRoofTypes
        ]);

        if ($scope.realEstateContainer.metaData.withdrawalProductType === 'BUILDING_INSURANCE_VALUES') {
            ValidationDataService.registerValidationFunction(ValidationFunctionsService.validateYearOfConstruction);
            ValidationDataService.registerValidationFunction(ValidationFunctionsService.validateInvestments);
        }

        $scope.$on("changeUsages", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.$on("changeInvestments", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.validateAndProceed = function (onSuccessFn) {
            ValidationDataService.validateAndProceed($scope.realEstateContainer, onSuccessFn);
        };
    }
})();



