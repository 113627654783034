(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('extensionProcessLccForm', AggregProcessLCCFormDirective);

    AggregProcessLCCFormDirective.$inject = ['$rootScope',  '$translate', '$translatePartialLoader'];

    function AggregProcessLCCFormDirective($rootScope, $translate, $translatePartialLoader) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                realEstateContainer: '=',
                rowid: '='
            },
            templateUrl: 'app/process_form/30_extension_forms/32_lcc_costs/32_process_lcc_costs.html',
            controller: ProcessLCCFormController,
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                $translatePartialLoader.addPart('lcc_costs_form');
                $translate.refresh();
            }
        };
    }

    ProcessLCCFormController.$inject = [
        '$scope',
        'LCCIntegrationService',
        'InflationRateBoxPlotService',
        'UsageService',
        'ValidationDataService',
        'ValidationFunctionsService'
    ];

    function ProcessLCCFormController(
        $scope,
        LCCIntegrationService,
        InflationRateBoxPlotService,
        UsageService,
        ValidationDataService,
        ValidationFunctionsService
    ) {
        $scope.operationStartYearPlaceholder = null;
        $scope.buildingLifetimePlaceholder = null;
        $scope.dataHasLoaded = true;
        $scope.LCCIntegrationService = LCCIntegrationService;
        $scope.inflationRateService = InflationRateBoxPlotService;
        $scope.$watch('LCCIntegrationService.lccEnabledInBuildingCosts', function (newValue) {
            //removes duplicate entries in array.
            $scope.realEstateContainer.metaData.tasks = _.uniq($scope.realEstateContainer.metaData.tasks);
            if (newValue != null && newValue) {
                if (!_.includes($scope.realEstateContainer.metaData.tasks,'LCC')){
                    $scope.realEstateContainer.metaData.tasks.push('LCC');
                }
                addValidators();
            } else {
                removeValidators();
                var idx = $scope.realEstateContainer.metaData.tasks.indexOf('LCC');
                if (idx >= 0) {
                    $scope.realEstateContainer.metaData.tasks.splice(idx, 1);
                }
            }
        });

        // Set default values
        $scope.operationStartYearPlaceholder = new Date().getFullYear() + 2;
        if ($scope.realEstateContainer.lcc.operationStartYear == null) {
            $scope.realEstateContainer.lcc.operationStartYear = $scope.operationStartYearPlaceholder;
        }

        $scope.operateOnDemolitionPeriodPlaceholder = 10;
        if ($scope.realEstateContainer.lcc.operateOnDemolitionPeriod == null) {
            $scope.realEstateContainer.lcc.operateOnDemolitionPeriod = $scope.operateOnDemolitionPeriodPlaceholder;
        }

        $scope.nominalInterestRatePlaceholder = 3.5;
        if ($scope.realEstateContainer.lcc.realInterestRate == null) {
            $scope.realEstateContainer.lcc.realInterestRate = $scope.nominalInterestRatePlaceholder;
        }

        function refreshBuildingLifetimeAndPlaceholder(usage) {
            if (usage.type == null || usage.standard == null) return;
            UsageService.getUsageLifetime(usage.type, usage.standard).then(function(usageLifetimeDTO) {
                $scope.realEstateContainer.lcc.buildingLifetimeMin = usageLifetimeDTO.min;
                $scope.realEstateContainer.lcc.buildingLifetimeMax = usageLifetimeDTO.max;
                $scope.buildingLifetimePlaceholder = usageLifetimeDTO.median;
                if ($scope.realEstateContainer.lcc.buildingLifetime == null) {
                    $scope.realEstateContainer.lcc.buildingLifetime = usageLifetimeDTO.median;
                }
            });
        }

        this.$onInit = function() {
            console.info("lcc_costs.$onInit");
            if ($scope.realEstateContainer.usages != null) {
                // init placeholder if usage already present
                refreshBuildingLifetimeAndPlaceholder($scope.realEstateContainer.usages[0]);
            }
        };

        $scope.$watch('realEstateContainer.usages', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            refreshBuildingLifetimeAndPlaceholder($scope.realEstateContainer.usages[0]);
        }, true);

        $scope.$watch('realEstateContainer.lcc.observationPeriod', function() {
            $scope.inflationRateService.update_boxplot_data($scope.realEstateContainer.lcc.observationPeriod);
            if ($scope.realEstateContainer.lcc.buildingLifetime != null && $scope.realEstateContainer.lcc.buildingLifetime > $scope.realEstateContainer.lcc.observationPeriod) {
                $scope.realEstateContainer.lcc.withDemolition = false;
            }
        });

        $scope.$watch('realEstateContainer.lcc.buildingLifetime', function(newValue, oldValue) {
            if ($scope.realEstateContainer.lcc.observationPeriod == null || $scope.realEstateContainer.lcc.observationPeriod == oldValue) {
                $scope.realEstateContainer.lcc.observationPeriod = newValue;
            }
        });

        function addValidators() {
            ValidationDataService.registerValidationFunctions([
                ValidationFunctionsService.validateOperationStartYear,
                ValidationFunctionsService.validateBuildingLifetime,
                ValidationFunctionsService.validateObservationPeriod,
                ValidationFunctionsService.validateDemolition,
                ValidationFunctionsService.validateInflationConstruction,
                ValidationFunctionsService.validateInflationAdministration,
                ValidationFunctionsService.validateInflationSupplyAndDisposal,
                ValidationFunctionsService.validateInflationMaintenance,
                ValidationFunctionsService.validateInterestRate
            ]);
        }

        function removeValidators() {
            // TODO
        }
    }
})();
