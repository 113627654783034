(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .service('SharedControllerFnService', SharedControllerFnService);

    SharedControllerFnService.$inject = [];

    function SharedControllerFnService() {
        var service = {

            countUsages: countUsages,
            cleanRealEstateContainerUsages: cleanRealEstateContainerUsages,
            cleanUsages: cleanUsages,
            isLastUsageTypeEmpty: isLastUsageTypeEmpty,
            countInvestments: countInvestments,
            isLastInvestmentEmpty: isLastInvestmentEmpty,
            cleanDto: cleanDto,
            cleanRebuildDto: cleanRebuildDto,
            hasOwnDeepProperty: hasOwnDeepProperty,
            normalizeRebuildUsages: normalizeRebuildUsages,
            normalizeRebuildUsagesInProgress: false,

            /**
             * this is used if only one specific usageContainer should be checked. (e.g. Annex or overhaul).
             */
            hasUsageArrayWithChiller: hasUsageArrayWithChiller,
            /**
             * returns true when any usage on the whole realEstateContainer can have Chiller (conditioning / chiller).
             */
            hasUsageWithChiller: hasUsageWithChiller, //Rebuild & Damage.
            //defined together mimba & lfon
            usagesWithChiller: [
                'SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN',
                'SCHULBAUTEN__SPEZIELLE_FORSCHUNGSBAUTEN',
                'LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN',
                'LAGERBAUTEN_MEHRSTOECKIGE',
                'LAGERBAUTEN__VERTEILZENTRALEN',
                'LAGERBAUTEN__INDUSTRIEHALLEN',
                'LAGERBAUTEN_PRODUKTIONSBAUTEN',
                'LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL',
                'LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL',
                'GESUNDHEITSBAUTEN__KRANKENHAEUSER',
                'GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN',
                'GESUNDHEIT__PFLEGEHEIME']
        };

        function countUsages(usages) {
            if (usages === undefined) return 0;
            return usages.length;
        }

        /**
         * Removes Teilusages which don't have a type. (e.g. empty usages)
         */
        function cleanRealEstateContainerUsages(realEstateContainer) {
            //clean usage if there is no type set.
            if (realEstateContainer.usages != null && _.isArray(realEstateContainer.usages)) {
                realEstateContainer.usages = cleanUsages(realEstateContainer.usages);
            }
            if (realEstateContainer.metaData.withdrawalProductType === 'SMALL_BUILDING_AGV' || realEstateContainer.metaData.tasks.indexOf('SMALL_BUILDING_AGV') >= 0) {
                realEstateContainer.agv.usages = cleanUsages(realEstateContainer.agv.usages);
            }
            if (realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' || realEstateContainer.metaData.tasks.indexOf('REBUILD_COSTS') >= 0 || realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' || realEstateContainer.metaData.tasks.indexOf('DAMAGE_COSTS') >= 0) {
                if (realEstateContainer.usages != null && _.isArray(realEstateContainer.usages)) {
                    //realEstateContainer.usages.sort(sortUsages);
                    realEstateContainer.usages = cleanUsages(realEstateContainer.usages);
                }
                //realEstateContainer.targetOverhaul.usages.sort(sortUsages);
                realEstateContainer.targetOverhaul.usages = cleanUsages(realEstateContainer.targetOverhaul.usages);
                if (realEstateContainer.selectedTemplates.indexOf('ADDITION_STORY') > -1) {
                    //realEstateContainer.targetAdditionStory.usages.sort(sortUsages);
                    realEstateContainer.targetAdditionStory.usages = cleanUsages(realEstateContainer.targetAdditionStory.usages);
                } else {
                    realEstateContainer.targetAdditionStory.usages = [];
                }
                if (realEstateContainer.selectedTemplates.indexOf('ANNEX') > -1) {
                    //realEstateContainer.targetAnnex.usages.sort(sortUsages);
                    realEstateContainer.targetAnnex.usages = cleanUsages(realEstateContainer.targetAnnex.usages);
                } else {
                    realEstateContainer.targetAnnex.usages = [];
                }
            }
            return realEstateContainer;
        }

        /**
         * Removes Teilusages which don't have a type. (e.g. empty usages)
         */
        function cleanUsages(usages) {
            //clean usage if there is no type set.
            try{
                for (var i = usages.length - 1; i >= 0; i--) {
                    //if type is null,undefined or '' we remove the usage. https://dorey.github.io/JavaScript-Equality-Table/
                    if (!usages[i].type) {
                        usages.splice(i,1);
                    }
                }
                if (usages.length === 1) {
                    // remove percent
                    usages[0].percentage = null;
                }
            } catch (e) {
                console.warn(e);
            }
            return usages;
        }

        function sortUsages(a, b) {
            // equal items sort equally
            if (a.type === b.type) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.type === null || a.type === '') {
                return 1;
            } else if (b.type === null || b.type === '') {
                return -1;
            }
            if (!!a.percentage && !!b.percentage && a.percentage > b.percentage) {
                return -1;
            } else if (!!a.percentage && !b.percentage) {
                return -1
            } else {
                return 1;
            }
        }

        function isLastUsageTypeEmpty(usages) {
            if (usages === undefined) return true;
            var obj = usages[usages.length - 1].type;
            return (obj === null ||obj === '' || typeof obj === 'undefined');
        }

        function countInvestments(realEstateContainer) {
            if (realEstateContainer === undefined) return 0;
            return realEstateContainer.investments.length;
        }

        function isLastInvestmentEmpty(realEstateContainer) {
            if ($scope.realEstateContainer === undefined || $scope.realEstateContainer.investments[$scope.realEstateContainer.investments.length - 1] === undefined) return true;
            var obj = $scope.realEstateContainer.investments[$scope.realEstateContainer.investments.length - 1].value;
            return (obj === null || typeof obj === 'undefined' || obj === '');
        }

        function cleanDto(realEstateContainer) {
            try {
                if ((!_.includes(realEstateContainer.metaData.tasks, 'REBUILD_COSTS') && realEstateContainer.metaData.withdrawalProductType !== 'REBUILD_COSTS') || (!_.includes(realEstateContainer.metaData.tasks, 'DAMAGE_COSTS') && realEstateContainer.metaData.withdrawalProductType !== 'DAMAGE_COSTS')) {
                    delete realEstateContainer.analysis;
                    delete realEstateContainer.occupationType;
                    delete realEstateContainer.monumentProtectionType;
                }

                if (!_.includes(realEstateContainer.metaData.tasks, 'OPERATING_COSTS')
                    && !_.includes(realEstateContainer.metaData.tasks, 'BUILDING_INSURANCE_VALUES')
                    && _.includes(realEstateContainer.metaData.tasks, 'BUILDING_COSTS_AND_DATES')) {
                    delete realEstateContainer.constructionYear;
                    //delete realEstateContainer.quality.roofType;
                    //delete realEstateContainer.quality.pv;
                    delete realEstateContainer.pom;
                    //delete realEstateContainer.quality.minergieStandard;
                    delete realEstateContainer.quality.hasVentilation;
                    //NOR => ( Y = !(A+B)
                    if (!(!_.includes(realEstateContainer.metaData.tasks, 'REBUILD_COSTS') || !_.includes(realEstateContainer.metaData.tasks, 'DAMAGE_COSTS'))) {
                        delete realEstateContainer.quality.heatingTypes;
                    }
                }
                //TODO ALSO REMOVE INVESTMENTS?
            } catch (e) {
                console.warn(e);
            }
        }

        function cleanRebuildDto(realEstateContainer) {
            if (!service.hasUsageArrayWithChiller(realEstateContainer.targetAdditionStory.usages)) {
                realEstateContainer.targetAdditionStory.chillerType = null;
                console.info('AdditionStory chillerType will be set to null.');
            }
            if (!service.hasUsageArrayWithChiller(realEstateContainer.targetAnnex.usages)) {
                realEstateContainer.targetAnnex.chillerType = null;
                console.info('Annex chillerType will be set to null.');
            }
            if (!service.hasUsageArrayWithChiller(_.concat(
                realEstateContainer.usages,
                realEstateContainer.targetOverhaul.usages))) {
                realEstateContainer.quality.chillerType = null;
                realEstateContainer.targetOverhaul.chillerType = null;
                console.info('Quality and TargetOverhaul chillerType will be set to null.');
            }
        }

        /**
         * checks if object has the given property on first hit returns.
         * @param obj if null returns false.
         * @param prop should be a string.
         * @returns {boolean|*}
         */
        function hasOwnDeepProperty(obj, prop) {
            if (typeof obj === 'object' && obj !== null) { // only performs property checks on objects (taking care of the corner case for null as well)
                if (obj.hasOwnProperty(prop)) {              // if this object already contains the property, we are done
                    return obj[prop];
                }
                for (var p in obj) {                         // otherwise iterate on all the properties of this object.
                    if (obj.hasOwnProperty(p) &&               // and as soon as you find the property you are looking for, return true
                        hasOwnDeepProperty(obj[p], prop)) {
                        return obj[p];
                    }
                }
            }
            return false;
        }

        function hasUsageArrayWithChiller(usages) {
            var ret = false;
            _.forEach(usages, function(usage) {
                if (usage != null && _.includes(service.usagesWithChiller, usage.type)) {
                    ret = true;
                    return ret;
                }
            });
            return ret;
        }

        function hasUsageWithChiller(realEstate) {
            try {
                if (realEstate === undefined) return false;
                return hasUsageArrayWithChiller(_.concat(
                    realEstate.usages,
                    realEstate.targetOverhaul.usages,
                    realEstate.targetAnnex.usages,
                    realEstate.targetAdditionStory.usages)
                );
            } catch (e) {
                console.info(e);
                return false;
            }
        }

        /**
         * normalizes array length of usages to match the largest array. As we display the usages in matrix.
         * @param realEstateContainer
         */
        function normalizeRebuildUsages(realEstateContainer) {
            if (!service.normalizeRebuildUsagesInProgress) {
                service.normalizeRebuildUsagesInProgress = true;
                var max = _.max([realEstateContainer.usages.length, realEstateContainer.targetOverhaul.usages.length, realEstateContainer.targetAnnex.usages.length, realEstateContainer.targetAdditionStory.usages.length]);
                if (max != null) {
                    while (max > realEstateContainer.usages.length) {
                        realEstateContainer.usages.push({ type: null, percentage: null, standard: null });
                    }
                    while (max > realEstateContainer.targetOverhaul.usages.length) {
                        realEstateContainer.targetOverhaul.usages.push({ type: null, percentage: null, standard: null });
                    }
                    if (realEstateContainer.targetAnnex.usages == null) {
                        realEstateContainer.targetAnnex.usages = [];
                    }
                    while (max > realEstateContainer.targetAnnex.usages.length) {
                        realEstateContainer.targetAnnex.usages.push({ type: null, percentage: null, standard: null });
                    }
                    if (realEstateContainer.targetAdditionStory.usages == null) {
                        realEstateContainer.targetAdditionStory.usages = [];
                    }
                    while (max > realEstateContainer.targetAdditionStory.usages.length) {
                        realEstateContainer.targetAdditionStory.usages.push({ type: null, percentage: null, standard: null });
                    }
                }
                service.normalizeRebuildUsagesInProgress = false;
            }
        }

        return service;
    }

})();
