(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('dynamicNoOrMultiSelecter', {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                noOption: '=?',
                selectables: '=?', // Array which has all selectable options inside.
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                actionCallback: '&',
                translateBaseKey: '@',
                rowKey: '=?',
                defaultImage: '=?',
                image: '=',
                imageFormat: '=?',
                dynamicImage: '=?',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                classes: '@?',
                infotext: '@?',
                useShortStrings: '=?',
                inputBlurFun: '&?'
            },
            templateUrl: 'app/components/dynamic_no_or_multi_selecter/dynamic-no-or-multi-selecter.tpl.html',
            controller: DynamicNoOrMultiSelecterController
        });

    DynamicNoOrMultiSelecterController.$inject = ['$scope', '$translate', '$attrs'];

    function DynamicNoOrMultiSelecterController($scope, $translate, $attrs) {
        var $ctrl = this;


        this.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';

            if (!angular.isFunction($ctrl.actionCallback)) {
                $ctrl.actionCallback = angular.noop;
            }
            // $ctrl.idForInfoField = $ctrl.translateBaseKey.split(".").join("_");

            // Used for the validation
            // $(toggle).attr("id", $attrs.reference + ".drop2down-toggle");
        };
    }
})();
