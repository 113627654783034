(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RebuildStateAnalysisFormController', RebuildStateAnalysisFormController);

    RebuildStateAnalysisFormController.$inject = ['$scope', 'RebuildActionService', 'RebuildDemolitionService'];

    function RebuildStateAnalysisFormController($scope, RebuildActionService, RebuildDemolitionService) {
        this.$onInit = function () {
            $scope.RebuildDemolitionService = RebuildDemolitionService;
            $scope.dictComponentToNum = RebuildActionService.fetchComponentToNum();
        };
    }

})();
