(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('buildingCostsTable', BuildingCostsTableDirective);

    BuildingCostsTableDirective.$inject = ['$rootScope'];

    function BuildingCostsTableDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                output : '=',
            },
            templateUrl: 'app/components/outputs/building_costs_table/building_costs_table.tpl.html',
            controller: BuildingCostsTableDirectiveController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
            }
        };
    }

    BuildingCostsTableDirectiveController.$inject = ['$scope'];

    function BuildingCostsTableDirectiveController($scope) {
        var vm = this;
        vm.output = $scope.output;

        vm.checkForEmptyVals = function (cost) {
            vm.checkForBkp0();
            if (cost.key === 'BKP_0_GRUNDSTUECK' || cost.key === 'BKP_3_UTILITIES'
                || cost.key === 'BKP_4_ENVIRONMENT' || cost.key === 'BKP_6_RESERVE'
                || cost.key === 'BKP_7_RESERVE' || cost.key === 'BKP_8_RESERVE') {
                return cost.value.length > 4 && cost.key.length > 1;
            } else {
                return !cost.isAddition || cost.value.length > 4 && cost.key.length > 1;
            }
        };

        vm.checkForBkp0 = function () {
            vm.bkp0Present = _.find(vm.output.costs, function (cost) {
                return cost.key === 'BKP_0_GRUNDSTUECK';
            });
            if (angular.isUndefined(vm.bkp0Present)) {
                vm.bkp0Present = false;
            }
            return vm.bkp0Present;
        };
    }

})();
