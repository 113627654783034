(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('TermsOfUseController', TermsOfUseController);

    TermsOfUseController.$inject = ['$scope'];

    function TermsOfUseController($scope) {

    }


})();
