(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('WithdrawalProductDialogController', WithdrawalProductDialogController);

    WithdrawalProductDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'WithdrawalProductService',
        'User', 'BaseLicenseService'];

    function WithdrawalProductDialogController($scope, $stateParams, $uibModalInstance, entity, WithdrawalProductService,
                                               User, BaseLicenseService) {
        if (entity.$promise) {
            entity.$promise.then(function (w) {
                postProcess(w);
            });
        } else {
            postProcess(entity);
        }

        function postProcess(w) {
            var withdrawalProduct = w;
            withdrawalProduct.dateTimeValidUntil = w.dateTimeValidUntil ? new Date(w.dateTimeValidUntil) : w.dateTimeValidUntil;
            withdrawalProduct.dateTimeFreeUntil = w.dateTimeFreeUntil ? new Date(w.dateTimeFreeUntil) : w.dateTimeFreeUntil;
            withdrawalProduct.dateTimeActive = w.dateTimeActive ? new Date(w.dateTimeActive) : w.dateTimeActive;
            withdrawalProduct.dateTimeInactive = w.dateTimeInactive ? new Date(w.dateTimeInactive) : w.dateTimeInactive;
            withdrawalProduct.numAdditionalUsers = w.numMaxUsers ? w.numMaxUsers - 1 : null;
            $scope.withdrawalProduct = withdrawalProduct;
        }


        $scope.withdrawalProductTypes = ["BUILDING_COSTS_AND_DATES", "BUILDING_INSURANCE_VALUES"];

        BaseLicenseService.actives().$promise.then(
          function(res) {
              $scope.baseLicenses = res;
          }
        );

        // user search
        $scope.userSearch = {};
        $scope.userSearch.searchText = '';
        $scope.userSearch.userIds = [];
        $scope.userSearch.querySearch = function (query) {
            if (query) {
                var q = {
                    "searchString": query
                };
                return User.search_eligible_for_withdrawal_products_by_login(q).$promise.then(
                    function (res) {
                        return res;
                    });
            } else {
                return User.query_eligible_for_withdrawal_products().$promise.then(
                    function (res) {
                        return res;
                    });
            }
        };

        $scope.userSearch.selectedItemChange = function (text) {
            $scope.userSearch.searchText = text;
            var p = {
                'userLogin': text
            };
            $scope.withdrawalProduct.userLogin = text;
        };

        // options
        var optionsInsurance = [];
        var optionsBuildingCosts = ["NONE", "DATES"];

        $scope.$watch('withdrawalProduct.withdrawalProductType', function (val) {
            if (!val || val === 'BUILDING_INSURANCE_VALUES') {
                $scope.options = optionsInsurance;
                $scope.includedOptions = optionsInsurance;
                $scope.withdrawalProduct.includedOptions = [];
                $scope.withdrawalProduct.optionsToSelect = [];
            } else {
                $scope.options = optionsBuildingCosts;
                $scope.includedOptions = optionsBuildingCosts;
                $scope.withdrawalProduct.includedOptions = [];
                $scope.withdrawalProduct.optionsToSelect = [];

            }
        });

        $scope.$watch('withdrawalProduct.numAdditionalUsers', function(newVal) {
            $scope.withdrawalProduct.numMaxUsers =  newVal + 1;
        });

        $scope.$watch('withdrawalProduct.includedOptions', function (newVal) {
            $scope.withdrawalProduct.optionsToSelect = [];
            var newOptions = [];
            for (var k in newVal) {
                newOptions.push(newVal[k]);
            }
            var diffSet = $scope.options.filter(function (o) {
                return newOptions.indexOf(o) === -1;
            });

            for (var o in diffSet) {
                if (diffSet[o] !== "NONE") {
                    $scope.withdrawalProduct.optionsToSelect.push({option: diffSet[o], creditAmount: 0});
                }
            }
        }, true);

        $scope.now = function () {
            var now = new Date();
            $scope.withdrawalProduct.dateTimeActive = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
        };

        // listeners

        var onSaveSuccess = function (result) {
            $scope.isSaving = false;
            $uibModalInstance.close(result);
        };

        var onSaveError = function () {
            $scope.isSaving = false;
            $scope.errorMessage = 'Ungültige Eingabe - nicht gespeichert!';
        };

        $scope.save = function () {
            if ($scope.withdrawalProduct.baseLicenseId) {
                $scope.withdrawalProduct.baseLicenseId = $scope.withdrawalProduct.baseLicenseId[0].id;
            }
            $scope.withdrawalProduct.includedOptions = $scope.withdrawalProduct.includedOptions
                .filter(function (o) {
                    return o !== "NONE";
                });
            if ($scope.withdrawalProduct.id != null) {
                WithdrawalProductService.update($scope.withdrawalProduct, onSaveSuccess, onSaveError);
            } else {
                WithdrawalProductService.save($scope.withdrawalProduct, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

})();
