(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactLifetimeInput', {
            bindings: {
                rowid: '=',
                element: '=',
                realEstate: '=?',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                maxlength: '@',
                validationMessage: '@',
                tabIndex: '=',
                tabGroup: '='
            },
            templateUrl: 'app/components/compact_inputs/compact_lifetime_input/compact-lifetime-input.tpl.html',
            controller: CompactLifetimeInputController
        });

    CompactLifetimeInputController.$inject = ['$scope', '$rootScope', '$attrs', '$element', '$translate', '$timeout'];

    function CompactLifetimeInputController($scope, $rootScope, $attrs, $element, $translate, $timeout) {
        var $ctrl = this;

        this.$onInit = function() {
            $scope.$watch('$ctrl.element.residualLifetimeUserInput', function(newValue, oldValue) {
                $ctrl.model = newValue;
            });
        };

        $ctrl.onBlur = function () {
            if ($ctrl.element.residualLifetimeUserInput || $ctrl.element.residualLifetimeUserInput === 0) {
                $ctrl.element.residualLifetime = $ctrl.element.residualLifetimeUserInput;
            } else {
                $ctrl.element.residualLifetime = $ctrl.element.residualLifetimeBackend;
            }
        };

        $ctrl.onChange = function() {
            // Guarantee type-safety for residualLifetimeUserInput (integer | null)
            var value = parseInt($ctrl.model);
            value = isNaN(value) ? null : value;
            $ctrl.element.residualLifetimeUserInput = value;
        };

        $ctrl.onFocus = function () {
            updateDescription();
        };

        function updateDescription() {
            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $ctrl.translateDescriptionBaseKey
            });
        }

        var inputField = $element.find("input")[0];

        if ($attrs.required) {
            inputField.required = true;
        }

        // Used for the validation
        $($element).attr("id", $attrs.reference + ".formElement");
        $(inputField).attr("id", $attrs.reference + ".input");

    }


})();
