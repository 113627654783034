(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('OperationalCostsHowtoEditRecordController', OperationalCostsHowtoEditRecordController);

    OperationalCostsHowtoEditRecordController.$inject = ['$scope', '$stateParams', '$state'];

    function OperationalCostsHowtoEditRecordController($scope, $stateParams, $state) {
        $scope.data = $stateParams.data;
    }

})();

