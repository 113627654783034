(function () {
    "use strict";

    angular
        .module("kennwerteApp")
        .directive("randomBackground", RandomBackgroundDirective);

    RandomBackgroundDirective.$inject = ["$rootScope", "BannerImageService", "$interval", '$animate'];

    function RandomBackgroundDirective($rootScope, bannerImageService, $interval, $animate) {
        return {
            restrict: "A",
            replace: true,
            scope: {},
            templateUrl:
                "app/components/random_background/random-background.tpl.html",
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                $(document).ready(function () {
                    getRandomImage();
                    // $interval(getRandomImage2, 10000);
                });

                // var stopInterval = function () {
                //     $interval.cancel(randomImageInterval);
                // };


                function getRandomImage() {
                    try {
                        var imgContainer = bannerImageService.getRandomImage();
                        angular.element(document.getElementsByClassName("bannerImg")).css(imgContainer.image);
                        angular.element(document.getElementsByClassName("bannerTitle")).css(imgContainer.headerStyle);
                    } catch (e) {
                        console.warn(' issue with random image.'+e );
                    }
                }

                // var randomImageInterval = $interval(getRandomImage(), 5000);

                // scope.$on('$destroy', function () {
                //     // Make sure that the interval is destroyed too
                //     stopInterval();
                //     // tElement.find(".bannerImg").removeClass('fade-in');
                //
                // });
                //currently disabled
                // var getRandomImage2 = function (){
                //     var imgContainer = bannerImageService.getRandomImage();
                //     angular.element(document.getElementsByClassName("bannerImg")).css(imgContainer.image);
                //     angular.element(document.getElementsByClassName("bannerTitle")).css(imgContainer.headerStyle);
                //
                // }

            }
        };
    }
})();
