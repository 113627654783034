(function () {
    'use strict';
    angular.module('kennwerteApp')
        .directive('zipcodelocationInput', ZipcodeLocationInputDirective)
        .controller('ZipCodeLocationController', ZipCodeLocationController);

    ZipcodeLocationInputDirective.$inject = ['$timeout','$rootScope'];

    function ZipcodeLocationInputDirective($timeout,$rootScope) {
        return {
            restrict: "E",
            controller: ZipCodeLocationController,
            controllerAs: 'vm',
            scope: {
                reference: '=',
                referenceLocation: '=?',
                referenceZipcode: '=?',
                referenceGmdNr: '=?',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@',
                isDisabled: '=',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                numberRestrict: '@',
                cantonLimit: '@?',
                tabIndex: '=',
                tabGroup: '='
            },
            templateUrl: 'app/components/zipcode_location/zipcode_location_tpl.html',
            link: function (scope, tElement, tAttrs, ctrl) {
                var input = document.getElementById('inputid');
                var inputField = tElement.find("input")[0];
                if (tAttrs.required) {
                    inputField.required = true;
                }
                $(tElement).attr("id", tAttrs.referenceZipcode + ".formElement");
                $(inputField).attr("id", tAttrs.referenceZipcode + ".input");

                tElement.bind("focusin", function (e) {
                    clearLocation();
                    ctrl.enableDropdown();
                });

                tElement.bind("focusout", function (e) {
                    ctrl.closeDropdown();

                    $timeout(function() {
                        var errorFlag = false;
                        if (scope.reference !== null) {
                            var regEX = new RegExp('\\d{1,4}');
                            var refString = scope.reference;
                            var locationSplit = refString.split(" ");

                            if (scope.reference.length >= 1) {
                                if (regEX.test(locationSplit[0].toString())) {
                                    scope.referenceZipcode = locationSplit.shift();
                                } else if (locationSplit.length >= 0) {
                                    scope.referenceLocation = locationSplit.join(" ");
                                }
                            } else {
                                errorFlag = true;
                            }

                            if (!angular.isUndefined(scope.referenceZipcode)) {
                                try {
                                    var loc = ctrl.findLocation(scope.referenceZipcode, locationSplit.join(" "));
                                    scope.referenceLocation = loc.location;
                                    if(scope.referenceZipcode.length!==4){
                                        loc = ctrl.findPLZ(scope.referenceLocation);
                                        scope.referenceZipcode = loc.plz.toString();
                                    }
                                    scope.referenceGmdNr = loc.gmd_nr;
                                    concatLocation();
                                }
                                catch (e) {
                                    console.error(e);
                                    errorFlag = true;
                                    scope.referenceLocation = "";
                                    scope.referenceZipcode = "";
                                    scope.referenceGmdNr = "";
                                }
                            }

                            if (angular.isUndefined(scope.referenceZipcode) && !angular.isUndefined(scope.referenceLocation)) {
                                try {
                                    var autoCorrectedLocation = ctrl.autoCorrectLocation(scope.referenceLocation);
                                    scope.referenceLocation = autoCorrectedLocation.location;
                                    var loc = ctrl.findPLZ(scope.referenceLocation);
                                    scope.referenceZipcode = loc.plz.toString();
                                    scope.referenceGmdNr = loc.gmd_nr;
                                    concatLocation();
                                } catch (e) {
                                    console.error(e);
                                    errorFlag = true;
                                    scope.referenceLocation = "";
                                    scope.referenceZipcode = "";
                                    scope.referenceGmdNr = "";
                                }
                            }
                        }
                        if (errorFlag) {
                            openErrorTooltip();
                        } else {
                            closeErrorTooltip();
                        }
                        delete scope.filteredList;
                    }, 100);
                });

                var clearLocation = function () {
                    delete scope.referenceZipcode;
                    delete scope.referenceLocation;
                    delete scope.referenceGmdNr;
                };

                var concatLocation = function () {
                    scope.reference = scope.referenceZipcode + " " + scope.referenceLocation;
                };

                //Tooltip creator.
                var openErrorTooltip = function () {
                    $(inputField).addClass("validationError");
                    $(inputField).tooltipster({
                        content: scope.validationMessage,
                        theme: 'tooltipster-kw',
                        side: ['right'],
                        zIndex: 10000,  //z-index of validationmessages.
                        trigger: 'custom',
                        plugins: ['sideTip'],
                        interactive: false,
                        selfDestruction: true,
                        repositionOnScroll: true,
                        functionReady: function (instance, helper) {
                            $(helper.origin).click(function () {

                                $(helper.origin).removeClass("validationError");
                                if (typeof helper.origin !== 'undefined' && $(helper.origin).hasClass("tooltipstered")) {
                                    $(helper.origin).unbind('click');
                                    $(helper.origin).tooltipster('destroy');
                                }
                            });
                        }
                    }).tooltipster('open');
                };

                var closeErrorTooltip = function() {
                    $(inputField).removeClass("validationError");
                    if (typeof inputField !== 'undefined' && $(inputField).hasClass("tooltipstered")) {
                        $(inputField).unbind('click');
                        $(inputField).tooltipster('destroy');
                    }
                };

                inputField.addEventListener("focus", function () {
                    // Push event to CalculationRowController
                    $rootScope.$broadcast("updateImage", {
                        imageSrc: sanitizeUrl(tAttrs.image),
                        rowid: Number(tAttrs.rowid)
                    });
                    $rootScope.$broadcast("updateDescription", {
                        rowid: Number(tAttrs.rowid),
                        descriptionNumber: 0,
                        descriptionBaseKey: tAttrs.descriptionBaseKey
                    });
                }, true);
            }
        };
    }

    ZipCodeLocationController.$inject = ['$scope', 'ZipcodeLocationMappingService', 'TrieService', 'autoCompleteService'];

    function ZipCodeLocationController($scope, ZipcodeLocationMappingService, TrieService, autoCompleteService) {

        $scope.plzTrie = {};
        $scope.locationTrie = {};
        $scope.actual_JSON = {};
        $scope.plzItem = {};
        $scope.filteredList = {};

        $scope.onEnter = function (event) {
            event.preventDefault();
            return false;
        };
        this.$onInit = function () {
            this.Test = ZipcodeLocationMappingService.getMapping(
                $scope.cantonLimit,
                function (response) {
                    $scope.actual_JSON = response;
                    $scope.plzTrie = TrieService.createTrie(
                        function (obj) {
                            return obj['plz'].toString();
                        },
                        false);
                    $scope.locationTrie = TrieService.createTrie(
                        function (obj) {
                            return obj['location'].toString();
                        },
                        false);
                    Object.keys($scope.actual_JSON).forEach(function (key, index) {
                        var plz = $scope.actual_JSON[key]['plz'].toString();
                        var loc = $scope.actual_JSON[key]['location'].toString();

                        $scope.plzTrie.addObject(plz, $scope.actual_JSON[key]);
                        $scope.locationTrie.addObject(loc, $scope.actual_JSON[key]);
                    });
                    if ($scope.referenceZipcode != null && angular.isDefined($scope.referenceZipcode)) {
                        var term = $scope.referenceZipcode;
                        var locIn = $scope.referenceLocation;
                        var res = _.filter($scope.plzTrie.objectsWithPrefix(term), function (value) {
                            return _.startsWith(value.location.toUpperCase(), locIn.toUpperCase());
                        });
                        var ret = _.head(res);
                        $scope.referenceGmdNr = ret.gmd_nr;
                    }
                },
                function (errorResponse) {
                    console.error('Got invalid response for zipcode-location-mapping');
                    $state.go('error');
                }
            );
        };

        this.findLocation = function (term, loc) {
            var res;
            res = _.filter($scope.plzTrie.objectsWithPrefix(term), function (value) {
                return _.startsWith(value.location.toUpperCase(), loc.toUpperCase());
            });
            return _.head(res);
        };

        this.autoCorrectLocation = function (term) {
            var res;
            res = _.find($scope.locationTrie.objectsWithPrefix(term), function (o) {
                return o.location.toUpperCase() === term.toUpperCase();
            });

            if (angular.isUndefined(res)) {
                res = _.filter($scope.locationTrie.objectsWithPrefix(term), function (o) {
                    return _.startsWith(o.location.toUpperCase(), term.toUpperCase());
                });
                return _.head(res);
            }
            return res;
        };

        this.findPLZ = function (term) {
            var res;
            res = $scope.locationTrie.objectsWithPrefix(term);
            return _.head(res);
        };

        // enforces closer of dropdownresults. if user uses TAB.
        this.closeDropdown = function () {
            window.setTimeout(function () {
                $('.auto-complete-container').hide();
            }, 200);
        };

        this.enableDropdown = function () {
            window.setTimeout(function () {
                $('.auto-complete-container').show();
            }, 200);
        };

        $scope.autoCompleteOptions = {
            minimumChars: 0,
            maxItemsToRender: 25,
            activateOnFocus: false,
            dropdownHeight: '200px',
            data: function (term) {
                term = term.toUpperCase();
                var testSplit = term.split(" ");
                var plz1;
                var term2;

                if (term.length >= 1) {
                    if (Number(testSplit[0])) {
                        plz1 = testSplit.shift();
                        if (testSplit.length > 0) {
                            term2 = testSplit.join(" ");
                        }
                    } else {
                        term2 = testSplit.join(" ");
                    }
                }
                if (plz1 && term2) {
                    $scope.filteredList = $scope.plzTrie.objectsWithPrefix(plz1);
                    // TODO: Improve with trie - maybe not
                    $scope.filteredList = _.filter($scope.filteredList, function (value) {
                        return _.startsWith(value.location.toUpperCase(), term2);
                    });
                } else if (plz1) {
                    $scope.filteredList = $scope.plzTrie.objectsWithPrefix(term);
                } else if (term2) {
                    $scope.filteredList = $scope.locationTrie.objectsWithPrefix(term2);
                }
                return $scope.filteredList;
            },
            selectedTextAttr: 'location',
            renderItem: function (item) {
                return {
                    value: item.plz + " " + item.location,
                    label:
                    "<p class='auto-complete'>"
                    + item.plz + " " + item.location +
                    "</p>"
                };
            },
            itemSelected: function (e) {
                $scope.plzItem = e.item;
                $scope.referenceZipcode = e.item.plz.toString();
                $scope.referenceLocation = e.item.location;
                $scope.referenceGmdNr = e.item.gmdNr;
                $scope.reference = $scope.referenceZipcode + " " + $scope.referenceLocation;
            }
        };
    }


})();
