(function() {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('products', {
                parent: 'app',
                url: '/',
                reloadOnSearch: false,
                data: {
                    authorities: [],
                    pageTitle: 'global.title'
                },
                params: {
                    anchor: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/products/products.html',
                        controller: 'ProductsController',
                        controllerAs: 'vm'
                    },
                    'register@products': {
                        templateUrl: 'app/account/register_inner/register-inner.html',
                        controller: 'RegisterInnerController',
                        controllerAs: 'vm'
                    },
                    'about_us@products':{
                        templateUrl: 'app/website_fragments/about_us/about_us.html'
                    },
                    'advisory_board@products':{
                        templateUrl: 'app/website_fragments/advisory_board/advisory_board.html'

                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('seo')
                            .addPart('products')
                            .addPart('register')
                            .addPart('prices')
                            .addPart('settings');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    metaTags: ['MetaTagService', function(MetaTagService) {
                        MetaTagService.setTags({
                            'description': 'Die innovative Lösung zur Berechnung von Baukosten: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
                            'Keywords': 'Neubau Kosten berechnen',
                            // OpenGraph
                            'og:type': 'website',
                            'og:title': 'kennwerte',
                            'og:description': 'Die innovative Lösung zur Berechnung von Baukosten: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
                            // Twitter
                            'twitter:card': 'summary_large_image',
                            'twitter:site': '@kennwerte',
                            'twitter:title': 'kennwerte',
                            'twitter:description': 'Die innovative Lösung zur Berechnung von Baukosten: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!'
                        });
                    }]
                }
            });
    }

})();
