(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RebuildReplacementBuildingInformationController', RebuildReplacementBuildingInformationController);

    RebuildReplacementBuildingInformationController.$inject = ['$scope', '$sessionStorage', '$state', '$translate', '$window', 'Principal', 'BillingModalService'];

    function RebuildReplacementBuildingInformationController($scope, $sessionStorage, $state, $translate, $window, Principal, BillingModalService) {
        var vm = this;
        vm.rebuildReplacementBuildingInformation = $sessionStorage.rebuildReplacementBuildingInformation;

        console.info("Replacement building information", vm.rebuildReplacementBuildingInformation, vm);

        $scope.startBuildingCostsCalculator = startBuildingCostsCalculator;

        function createReplacementRealEstate(realEstateId, replacementRealEstate) {
            return {
                replacementOriginalRealEstateId: realEstateId,
                metaData: {
                    name: replacementRealEstate.metaData.name + " | " + $translate.instant('rebuild_form.12_replacement_building_information.replacement_for')
                },
                location: replacementRealEstate.location,
                geometry: replacementRealEstate.geometry,
                usages: replacementRealEstate.usages,
                quality: { }
            };
        }

        function startBuildingCostsCalculator() {
            function goToReplacementCalculator() {
                var replacementRealEstate = createReplacementRealEstate($scope.realEstateContainer.id, vm.rebuildReplacementBuildingInformation.replacementRealEstate);
                console.info("Go to replacement calculator with", replacementRealEstate);
                localStorage.setItem('replacementRealEstate', JSON.stringify(replacementRealEstate));
                var url = $state.href('process-form.input', {
                    withdrawalProductType: 'building_costs_and_dates',
                    realEstate: replacementRealEstate
                }, {absolute: true});
                $window.open(url,'_blank');
            }
            if (Principal.hasModule('BUILDING_COSTS_AND_DATES')) {
                goToReplacementCalculator();
            } else {
                BillingModalService.openBillingModal(
                    function() {
                        Principal.identity(true); // force refresh of rights
                        if (Principal.hasModule("BUILDING_COSTS_AND_DATES")) {
                            goToReplacementCalculator();
                        }
                    },
                    ['BUILDING_COSTS_AND_DATES']
                );
            }
        }
    }
})();
