'use strict';

angular.module('kennwerteApp')
    .controller('DepositProductDeleteController', ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'DepositProductService',
        function ($scope, $stateParams, $uibModalInstance, entity, DepositProductService) {
            var vm = this;
            vm.depositProduct = entity;
            vm.clear = clear;
            vm.confirmDelete = confirmDelete;
            vm.isDeleting = false;

            var onDeleteSuccess = function (result) {
                vm.isDeleting = false;
                $uibModalInstance.close(result);
            };

            var onDeleteError = function (result) {
                vm.isDeleting = false;
                console.error("Failed to delete " + result);
            };

            var confirmDelete = function () {
                vm.isDeleting = true;
                DepositProductService.delete({id: vm.depositProduct.id}, onDeleteSuccess, onDeleteError);
            };

            var clear = function() {
                $uibModalInstance.dismiss('cancel');
            };

        }]);
