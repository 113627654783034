(function () {
    'use strict';
    angular.module('kennwerteApp')
        .factory('MinergiePvGroupFactory', MinergiePvGroupFactory);

    MinergiePvGroupFactory.$inject = [];

    function MinergiePvGroupFactory() {
        var data = {
            0: [
                {"id": 1, "ref": "MINERG", "title": "NONE", "parent": false, "isSelected": false},
                {"id": 2, "ref": "MINERG", "title": "MINERGIE", "parent": false, "isSelected": false},
                {"id": 3, "ref": "MINERG", "title": "MINERGIE_P", "parent": false, "isSelected": false},
                {"id": 4, "ref": "MINERG", "title": "MINERGIE_A", "parent": false, "isSelected": false},
                {"id": 5, "ref": "PV", "title": "PV-ANLAGE", "parent": false, "isSelected": false},
            ]
        };

        return {
            load: function (id) {
                return data[id];
            },
            getByTitle: function (title) {
                for (var key in data) {
                    var groupArr = data[key];
                    for (var objKey in groupArr) {
                        var obj = groupArr[objKey];
                        if (title === obj.title) {
                            return obj;
                        }
                    }
                }
                return {};
            }
        };
    }

})();
