(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('InsuranceGeneralFormController', InsuranceGeneralFormController);

    InsuranceGeneralFormController.$inject = ['$scope', '$stateParams', '$window', 'KwImageModelService'];

    function InsuranceGeneralFormController($scope, $stateParams, $window, KwImageModelService) {
        $scope.data = $stateParams.data;

        $scope.isSpecialUsage = function() {
            return KwImageModelService.isSpecialUsage($scope.realEstateContainer);
        };

        $scope.isKwImageModelInProgress = function() {
            return KwImageModelService.isEvalImageOnProgress();
        };
    }

})();



