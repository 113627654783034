(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildEstateService', RebuildEstateService);

    RebuildEstateService.$inject = ['$resource', '$http'];

    function RebuildEstateService($resource, $http) {
        var service =
            $resource('api/rebuild_estate/:id', {id: '@_id'}, {
                'query': {
                    method: 'GET', isArray: true
                },
                'update': {
                    method: 'PUT' // this method issues a PUT request
                },
                'access': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'getEmpty': {
                    method: 'GET',
                    url: 'api/rebuild_estate/empty'
                },
                'getEmptyDamage': {
                    method: 'GET',
                    url: 'api/rebuild_estate/empty-damage'
                },
                'create': {
                    method: 'POST'
                },
                'estimate': {
                    method: 'POST',
                    url: 'api/rebuild_estate/estimate'
                }
            });

        return service;
    }

})();
