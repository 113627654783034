(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('insufficient-rights', {
                parent: 'app',
                abstract: true,
                url: '/insufficient-rights',
                resolve: {
                    mainTranslatePartialLoader: ["$translate", "$translatePartialLoader", function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('process_form')
                            .addPart('insufficient-rights')
                            .addPart('products')
                            .addPart('register')
                            .addPart('settings');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('insufficient-rights.license', {
                url: '/license',
                views: {
                    'content@': {
                        templateUrl: 'app/insufficient_rights/license.html'
                    }
                }
            })
            .state('insufficient-rights.insurance-values',  {
                url: '/insurance-values',
                views: {
                    'content@': {
                        templateUrl: 'app/insufficient_rights/insurance-values.html'
                    }
                }
            })
            .state('insufficient-rights.building-costs', {
                url: '/building-costs',
                views: {
                    'content@': {
                        templateUrl: 'app/insufficient_rights/building-costs.html'
                    }
                }
            })
            .state('insufficient-rights.operational-costs', {
                url: '/operating-costs',
                views: {
                    'content@': {
                        templateUrl: 'app/insufficient_rights/operational-costs.html'
                    }
                }
            })
            .state('insufficient-rights.rebuild-costs', {
                url: '/rebuild-costs',
                views: {
                    'content@': {
                        templateUrl: 'app/insufficient_rights/rebuild-costs.html'
                    }
                }
            })
            .state('insufficient-rights.small-building', {
                url: '/small-building',
                views: {
                    'content@': {
                        templateUrl: 'app/insufficient_rights/small-building.html'
                    }
                }
            });
    }

})();
