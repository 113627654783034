(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('WithdrawalProductService', WithdrawalProductService);

    WithdrawalProductService.$inject = ['$resource'];
    function WithdrawalProductService($resource) {
        return $resource('api/withdrawal_products/:id', {id: '@_id'}, {
            'update': {
                method: 'PUT'
            },
            'access': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'adminUpdate': {
                method: 'PUT',
                url: 'api/withdrawal_products/admin_update'
            },
            'actives_for_admin': {
                url: 'api/withdrawal_products/actives_for_admin/:userLogin',
                params: {userLogin: '@userLogin'},
                method: 'GET',
                isArray: true
            },
            'current_for_admin': {
                url: 'api/withdrawal_products/current_for_admin',
                params: {orgId: '@orgId'},
                method: 'GET'
            },
            'pending_for_admin': {
                url: 'api/withdrawal_products/pending_for_admin',
                params: {orgId: '@orgId'},
                method: 'GET'
            },
            'current_for_user': {
                url: 'api/withdrawal_products/current_for_user',
                method: 'GET'
            },
            'current_pending_for_user': {
                url: 'api/withdrawal_products/current_pending_for_user',
                method: 'GET'
            },
            'query_by_user': {
                url: 'api/withdrawal_products/query_by_user/:userLogin',
                params: {userLogin: '@userLogin'},
                method: 'GET',
                isArray: true
            },
            'query_pending': {
                url: 'api/withdrawal_products/pending',
                method: 'GET',
                isArray: true
            },
            'query_pending_by_user': {
                url: 'api/withdrawal_products/pending_by_user/:userLogin',
                params: {userLogin: '@userLogin'},
                method: 'GET',
                isArray: true
            }
        });
    }

})();
