(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('hlkAdvancedSliderInput', HlkAdvancedSliderInput);

    HlkAdvancedSliderInput.$inject = ['$rootScope'];

    function HlkAdvancedSliderInput($rootScope) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                realEstateContainer: '=',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@?',
                isDisabled: '=',
                minlength: '@',
                maxlength: '@',
                validationMessage: '@',
                mandatory: '=',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                turnOnSlider: '=?',
                infotext: '@?',
                groupedHeatingTypes: '<'
            },
            templateUrl: 'app/components/advanced_slider_inputs/hlk_advanced_slider_input/hlk_advanced_slider_input.tpl.html',
            controller: HlkAdvancedSliderInputController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {

                scope.doShowInterval = false;
                scope.boxPlotData = [{}];
                scope.wkBoxPlotData = [{}];
                scope.whConfig = undefined;

                var inputField = tElement.find("input")[0];

                if (tAttrs.required) {
                    inputField.required = true;
                }

                if(scope.turnOnSlider == undefined){
                    scope.turnOnSlider = true;
                }
            }
        };
    }

    HlkAdvancedSliderInputController.$inject = ['$scope', '$timeout', 'VentilationTypeDataService'];

    function HlkAdvancedSliderInputController($scope, $timeout, VentilationTypeDataService) {

        $scope.firstLoad1 = true;
        $scope.firstLoad2 = true;
        $scope.firstLoad3 = true;
        $scope.possibleVentilationTypes = VentilationTypeDataService.defaultData;
        $scope.ventilationType = null;

        this.$onInit = function () {
            $scope.ventilationType = _.find($scope.possibleVentilationTypes, function (v) {
                return v.value === $scope.realEstateContainer.quality.ventilationType;
            });
        };

        $scope.onVentilationTypeChange = function(value, _) {
            $scope.realEstateContainer.quality.ventilationType = (value == null) ? null : value.value;
        };

        console.log(VentilationTypeDataService.defaultData);
        var updateHlkSlider = function () {
            var newHlkWk = 0;
            var heatingVal = 0;
            _.forEach($scope.realEstateContainer.quality.heatingTypes, function (value, key) {
                switch (value) {
                case 'NO_HEATING':
                    if (heatingVal <= 1) {
                        heatingVal = 1;
                    }
                    break;
                case 'CONTRACTING':
                case 'DISTRICT_HEATING':
                    if (heatingVal <= 2) {
                        heatingVal = 2;
                    }
                    break;
                case 'NATURAL_GAS':
                case 'OIL':
                    if (heatingVal <= 3) {
                        heatingVal = 3;
                    }
                    break;
                case 'HEAT_PUMP_AIR':
                    if (heatingVal <= 4) {
                        heatingVal = 4;
                    }
                    break;
                case 'PELLET':
                case 'WOOD_CHIPS':
                case 'HEAT_PUMP_GEOTHERMAL':
                case 'HEAT_PUMP_GROUND_COLLECTOR':
                case 'HEAT_PUMP_GROUND_WATER':
                    if (heatingVal <= 5) {
                        heatingVal = 5;
                    }
                    break;

                default:
                    break;
                }
                if (heatingVal != undefined) {
                    newHlkWk = heatingVal;
                }
            });
            if(_.includes($scope.realEstateContainer.quality.heatingTypes,'SOLAR_COLLECTORS_THERMAL')){
                newHlkWk++;
            }
            if ($scope.realEstateContainer.quality.ventilationType != null && $scope.realEstateContainer.quality.ventilationType !== VentilationTypeDataService.NONE.value) {
                newHlkWk++;
            }
            if ($scope.realEstateContainer.quality.hasConditioning) {
                newHlkWk++;
            }
            //scope.turnOnSlider is needed otherwise we overwrite value.
            if (newHlkWk > 0 && $scope.turnOnSlider) {
                $scope.realEstateContainer.quality.qualitaetHeizungsanlage = 'WK' + newHlkWk;
            }
        };
        $scope.$watch('realEstateContainer.quality.heatingTypes', function (newValue) {
            $timeout(function () {
                if ($scope.firstLoad1) {
                    updateHlkSlider();
                } else {
                    $scope.firstLoad1 = false;
                }
            });
        },true);

        $scope.$watch('realEstateContainer.quality.ventilationType', function (newValue) {
            $timeout(function () {
                if ($scope.firstLoad2) {
                    updateHlkSlider();
                } else {
                    $scope.firstLoad2 = false;
                }
            });
        });
        $scope.$watch('realEstateContainer.quality.hasConditioning', function (newValue) {
            $timeout(function () {
                if ($scope.firstLoad3) {
                    updateHlkSlider();
                } else {
                    $scope.firstLoad3 = false;
                }
            });
        });
    }

})();
