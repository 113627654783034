(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('SmallBuildingAgvPValueFormController', SmallBuildingAgvPValueFormController);

    SmallBuildingAgvPValueFormController.$inject = ['$scope', '$stateParams'];

    function SmallBuildingAgvPValueFormController($scope, $stateParams) {
        $scope.data = $stateParams.data;
        if($scope.realEstateContainer.agv == null){
            $scope.realEstateContainer.agv = {};
        }
        if ($scope.realEstateContainer.agv.pValue == null) {
            $scope.realEstateContainer.agv.pValue = [{}];
        }
        //Set dynamicTranslations for additionsForm.
        $scope.noDynamicTranslations = false;

        $scope.isNotIE = false;
        if (angular.isUndefined(document.documentMode)) {
            $scope.isNotIE = true;
        } else {
            $scope.isNotIE = false;
        }
    }


})();
