(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('UpdateLicenseRemoveModalController', UpdateLicenseRemoveModalController);

    UpdateLicenseRemoveModalController.$inject = ['$uibModalInstance', 'numUsersToRemove', 'currentNumMaxUsers', 'freeUntil',
        'validUntil', 'freeMode', 'BillingService', '$scope', '$state', '$location', '$timeout'];

    function UpdateLicenseRemoveModalController($uibModalInstance, numUsersToRemove, currentNumMaxUsers, freeUntil,
                                                validUntil, freeMode, BillingService, $scope, $state, $location, $timeout) {
        var vm = this;

        // variables
        vm.numUsersToRemove = numUsersToRemove;
        vm.currentNumMaxUsers = currentNumMaxUsers;
        vm.freeMode = freeMode;
        vm.priceRelief = null;
        vm.vat = null;
        vm.reliefInclVat = null;
        vm.isClicked = false;
        if (validUntil) {
            vm.until = validUntil;
        } else if (freeUntil) {
            vm.until = freeUntil;
        }

        // functions
        vm.cancel = cancel;
        vm.removeAccounts = removeAccounts;

        BillingService.fetchPriceRelief(numUsersToRemove).then(
            function(ret) {
                vm.priceRelief = ret.data.relief;
                vm.vat = ret.data.vat;
                vm.effectiveVat = ret.data.effectiveVat;
                vm.reliefInclVat = ret.data.reliefInclVat;
            });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
            $state.go('billing', {
                paymentMeansSuccess: null,
                paymentFormSuccess: null
            }, {reload: true});
        }

        function removeAccounts() {
            if(vm.isClicked){
                return;
            }
            vm.isClicked = true;
            var n = $location.$$absUrl.lastIndexOf($location.$$url);
            var contextPath = $location.$$absUrl.slice(0, n);
            var dto = {
                successUrl: contextPath + "/saferpay-success",
                failUrl: contextPath + "/saferpay-error",
                abortUrl: contextPath + "/saferpay-abort",
                payerLanguageCode: "DE",
                numUsersToRemove: vm.numUsersToRemove,
                currentNumMaxUsers: vm.currentNumMaxUsers
            };
            BillingService.updateLicense(
                dto,
                function(response) {
                    vm.authorized = response.data.authorized;
                    vm.invalidCard = response.data.invalidCard;
                    if (!vm.authorized) {
                        vm.errorPaymentFormError = null;
                    } else {
                        // SUCCESS
                        vm.errorPaymentFormError = null;
                        $timeout(function() {
                            cancel();
                        }, 2000);
                    }
                },
                function(errorResponse) {
                    vm.errorPaymentFormError = 'ERROR';
                }
            );

        }



    }

})();
