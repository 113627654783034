(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('CamtService', CamtService);

    CamtService.$inject = ['$http', 'Upload'];

    function CamtService($http, Upload) {
        var service = {
            upload: uploadCamtFile,
            saveMatches: saveMatches
        };

        function uploadCamtFile(camtFile, invalidFiles, onSuccessFun, onErrorFun) {
            var data = {
                file: camtFile
            };

            Upload.upload({
                url: '/api/camt/upload_camt_054',
                data: data
            }).then(function (resp) {
                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                onSuccessFun(resp.data);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                onErrorFun(resp);
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                if (evt.config.data.file && evt.config.data.file.name) {
                    console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                }
            })
        }

        function saveMatches(camtMatchDto, responseFunction, errorFunction) {
            return $http.post('api/camt/save_matches', camtMatchDto)
                .then(function (response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                });
        }

        return service;
    }

})();
