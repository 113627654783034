(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('dynamicEverythingOrSubpartSelecter', {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                hasAllOption: '=?',
                selectables: '=?', // Array which has all selectable options inside.
                realEstateContainer: '=?',
                rowid: '=',
                translateBaseKey: '@',
                image: '=',
                imageFormat: '=?',
                dynamicImage: '=?',
                defaultImage: '=?',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                standardDescriptionPostfix: '@?',
                classes: '@?',
                infotext: '@?',
                inputBlurFun: '&?'
            },
            templateUrl: 'app/components/dynamic_everything_or_subpart_selecter/dynamic_everything_or_subpart_selecter.tpl.html',
            controller: DynamicEverythingOrSubPartMultiSelecterController
        });

    DynamicEverythingOrSubPartMultiSelecterController.$inject = ['$scope', '$translate', '$rootScope', '$attrs', '$element'];

    function DynamicEverythingOrSubPartMultiSelecterController($scope, $translate, $rootScope, $attrs, $element) {
        var $ctrl = this;
        $ctrl.hasLoaded = false;

        $ctrl.model = {};

        function isTemplateSelected(type) {
            return $ctrl.reference.indexOf(type) > -1;
        }

        $ctrl.translateKey = function(postfix) {
            try {
                return $translate.instant(
                    $ctrl.translateBaseKey + '.' + postfix + '.label'
                );
            } catch (e) {
                console.warn(e);
            }
        };

        function printSelectedOptions(choices) {
            var selectedChoicesStrArr = choices
                .filter(function(c) {
                    return c.isSelected;
                })
                .map(function(c) {
                    return c.translated;
                });
            return selectedChoicesStrArr.join(', ');
        }

        $ctrl.maintenancePrintSelectedOptions = function() {
            if ($ctrl.options[0] !== undefined && $ctrl.options[0].isSelected && $ctrl.hasAllOption) {
                return $ctrl.options[0].translated;
            }
            return printSelectedOptions($ctrl.options);
        };

        function handleOtherOptionsChoices(selectedItem) {
            _.forEach($ctrl.options, function(value) {
                value.isSelected = selectedItem.isSelected;
                var index = $ctrl.reference.indexOf(value.type);
                if (value.isSelected) {
                    if (index === -1) {
                        $ctrl.reference.push(value.type);
                    }
                } else {
                    if (index > -1) {
                        $ctrl.reference.splice(index, 1);
                    } else {
                        console.error('index not found: ', value);
                    }
                }
            });
        }

        $ctrl.onSelectMaintenance = function(selectedItem, externalChange) {
            selectedItem.isSelected = !selectedItem.isSelected;
            if ($ctrl.hasAllOption === selectedItem.type) {
                handleOtherOptionsChoices(selectedItem);
                $ctrl.updateDescriptionAndImage(selectedItem, externalChange);
            } else {
                if (selectedItem.isSelected) {
                    $ctrl.reference.push(selectedItem.type);
                    $ctrl.updateDescriptionAndImage(selectedItem, externalChange);
                } else {
                    var index = $ctrl.reference.indexOf(selectedItem.type);
                    if (index > -1) {
                        $ctrl.reference.splice(index, 1);
                    } else {
                        console.error('index not found: ', selectedItem);
                    }
                    //deselect all (would be wrong if all is still selected but not all of them are selected.)
                    var allOption = _.find($ctrl.options, ['type', $ctrl.hasAllOption]);
                    allOption.isSelected = selectedItem.isSelected;
                    var indexAllOptions = $ctrl.reference.indexOf(allOption.type);
                    if (indexAllOptions > -1) {
                        $ctrl.reference.splice(indexAllOptions, 1);
                    }
                    $ctrl.updateDescriptionAndImage(undefined, externalChange);
                }
            }
        };
        $ctrl.onOpenClose = function(isOpen) {
            if (isOpen) {
                var descrKey = $ctrl.standardDescriptionPostfix != null ? $ctrl.descriptionBaseKey+ '.' + $ctrl.standardDescriptionPostfix : $ctrl.descriptionBaseKey;
                $rootScope.$broadcast('updateDescription', {
                    rowid: $ctrl.rowid,
                    descriptionBaseKey: descrKey
                });
                if ($ctrl.defaultImage != null) {
                    $rootScope.$broadcast('updateImage', {
                        rowid: $ctrl.rowid,
                        imageSrc: $ctrl.defaultImage
                    });
                }
            }
        };

        /**
         *
         * @param selectedItem
         * @param externalChange if change comes from reference (e.g. other component or service we should not change image or description.
         */
        $ctrl.updateDescriptionAndImage = function(selectedItem, externalChange) {
            if ($ctrl.hasLoaded && !externalChange) {
                if (selectedItem) {
                    var imgSrc = $ctrl.dynamicImage ? $ctrl.image + selectedItem.type + '.' + $ctrl.imageFormat : $ctrl.image;

                    $rootScope.$broadcast('updateImage', {
                        rowid: $ctrl.rowid,
                        imageSrc: imgSrc
                    });
                    $rootScope.$broadcast('updateDescription', {
                        rowid: $ctrl.rowid,
                        // descriptionNumber: selectedItem.id,
                        descriptionBaseKey:
                            $ctrl.descriptionBaseKey + '.' + selectedItem.type
                    });
                } else {
                    $rootScope.$broadcast('updateDescription', {
                        rowid: $ctrl.rowid,
                        // descriptionNumber: selectedItem.id,
                        descriptionBaseKey: $ctrl.descriptionBaseKey
                    });
                }
                //Blur function will only be called after firstLoad
                if ($ctrl.inputBlurFun != null) {
                    $ctrl.inputBlurFun();
                }
            }
        };

        $ctrl.setupSelectables = function() {
            $ctrl.options = [];
            if ($ctrl.hasAllOption) {
                $ctrl.options.push({
                    'type': $ctrl.hasAllOption,
                    'translated': $ctrl.translateKey($ctrl.hasAllOption),
                    'isSelected': isTemplateSelected($ctrl.hasAllOption)
                });
            }
            _.forEach($ctrl.selectables, function(val) {
                $ctrl.options.push({
                    'type': val,
                    'translated': $ctrl.translateKey(val),
                    'isSelected': isTemplateSelected(val)
                });
            });
        };

        $scope.$watch("$ctrl.reference", function(newValue, oldValue) {
            var lengthSize = $ctrl.selectables.length;
            if ($ctrl.hasAllOption) {
                lengthSize++;
                if (newValue != null && newValue.indexOf($ctrl.hasAllOption) > -1) {
                    if ($ctrl.reference.length <= lengthSize) {
                        var allOption = _.find($ctrl.options, ['type', $ctrl.hasAllOption]);
                        allOption.isSelected = false; //this needed so we follow along the logic =).
                        $ctrl.onSelectMaintenance(allOption, true);
                    }
                }
            }
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            // console.warn($scope.groups[0], $scope.groups[0].title, vm.translateKey($scope.groups[0].title));
            for (var i = 0; i < $ctrl.options.length; i++) {
                if ($ctrl.options[i].type != null) {
                    $ctrl.options[i].translated = $ctrl.translateKey($ctrl.options[i].type);
                }
            }
        });

        this.$onDestroy = function() {
            translateSuccess();
        };

        this.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            if (angular.isUndefined($ctrl.reference)) {
                $ctrl.reference = [];
            }
            if (angular.isUndefined($ctrl.hasAllOption)) {
                $ctrl.hasAllOption = false;
            }
            if ($ctrl.dynamicImage == null) {
                $ctrl.dynamicImage = false;
            }

            $ctrl.setupSelectables();
            // $ctrl.idForInfoField = $ctrl.translateBaseKey.split(".").join("_");

            // Used for the validation
            var toggle = $element.find('.compactSelectInput');
            $(toggle).attr('id', $attrs.reference + '.drop2down-toggle');
            $ctrl.hasLoaded = true;
            $ctrl.firstLoad = true;
        };
    }
})();
