(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider
            .state('insurance-values', {
                parent: 'app',
                abstract: true,
                url: '/insurance',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER']
                }
            })
            .state('insurance-values.realestates', {
                url: '/realestates',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'global.menu.insurance-values.realestates'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/realestates/realestates.html',
                        controller: 'RealestatesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    deleteState: 'insurance-values.realestates.delete',
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'LAST_MODIFIED_DATE;desc',
                        squash: true
                    },
                    totalItems: null,
                    filterUsage: null,
                    searchName: null
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            });
                    }],
                    check: ['$state', 'Principal', function ($state, Principal) {
                        Principal.identity().then(function(e) {
                            if (!!e && !Principal.hasModule('BUILDING_INSURANCE_VALUES') && !Principal.hasModule('SMALL_BUILDING_AGV')&& !Principal.hasModule('DAMAGE_COSTS')) {
                                $state.go('insufficient-rights.insurance-values');
                            }
                        });
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: $stateParams.page ? PaginationUtil.parsePage($stateParams.page) : 1,
                            totalItems: $stateParams.totalItems ? $stateParams.totalItems : null,
                            sort: $stateParams.sort,
                            filterUsage: $stateParams.filterUsage ? $stateParams.filterUsage : null,
                            searchName: $stateParams.searchName ? $stateParams.searchName : "",
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('model_complexity')
                            .addPart('model_quality')
                            .addPart('realestates')
                            .addPart('reference_reference-creation')
                            .addPart('model_reference')
                            .addPart('model_agv');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('insurance-values.realestates-reference', {
                url: '/realestates-reference',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'global.menu.insurance-values.realestates-reference'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/realestates/realestates-reference.html',
                        controller: 'RealestatesReferenceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    deleteState: 'insurance-values.realestates-reference.delete',
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'NAME;desc',
                        squash: true
                    },
                    totalItems: null,
                    filterUsage: null,
                    searchName: null
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            });
                    }],
                    check: ['$state', 'Principal', function ($state, Principal) {
                        Principal.identity().then(function(e) {
                            if (!!e && !Principal.hasModule('BUILDING_INSURANCE_VALUES')) {
                                $state.go('insufficient-rights.insurance-values');
                            }
                        });
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: $stateParams.page ? PaginationUtil.parsePage($stateParams.page) : 1,
                            totalItems: $stateParams.totalItems ? $stateParams.totalItems : null,
                            sort: $stateParams.sort,
                            filterUsage: $stateParams.filterUsage ? $stateParams.filterUsage : null,
                            searchName: $stateParams.searchName ? $stateParams.searchName : "",
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form')
                            .addPart('realestates')
                            .addPart('reference_reference-creation')
                            .addPart('model_reference')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('model_complexity')
                            .addPart('model_quality');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('insurance-values.realestates-create', {
                url: '/realestates-create',
                data: {
                    authorities: ['ROLE_USER']
                },
                params: {},
                views: {
                    'content@': {
                        templateUrl: 'app/realestates/reference/realestate-reference-creation.html',
                        controller: 'RealestateReferenceCreationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form')
                            .addPart('realestates')
                            .addPart('reference_reference-creation')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('model_complexity')
                            .addPart('model_quality');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('insurance-values.realestates-reference.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                params: {
                    realEstateId: null,
                    previousStateName: null,
                    previousStateParams: null,
                    itemsOnPage: 0
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/realestates/realestates-delete.html',
                        controller: 'RealestatesDeleteController',
                        size: 'lg',
                        resolve: {
                            entity: ['RealestateService', function (RealestateService) {
                                return RealestateService.api.access({id: $stateParams.realEstateId});
                            }]
                        }
                    }).result.then(
                        function (result) {
                            var copy = {};
                            angular.copy($stateParams.previousStateParams, copy);
                            var totalItems = $stateParams.previousStateParams.totalItems;
                            totalItems = totalItems - 1;
                            var page = copy.page;
                            if ($stateParams.itemsOnPage === 1) {
                                page = page - 1;
                                if (page < 1) {
                                    page = 1;
                                }
                            }
                            copy.totalItems = totalItems;
                            copy.page = page;
                            $state.go($stateParams.previousStateName, copy, {reload: true});
                        }, function () {
                            $state.go('^');
                        }
                    );
                }]
            })
            .state('insurance-values.realestates.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                params: {
                    realEstateId: null,
                    previousStateName: null,
                    previousStateParams: null,
                    itemsOnPage: 0
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/realestates/realestates-delete.html',
                        controller: 'RealestatesDeleteController',
                        size: 'lg',
                        resolve: {
                            entity: ['RealestateService', function (RealestateService) {
                                return RealestateService.api.access({id: $stateParams.realEstateId});
                            }]
                        }
                    }).result.then(
                        function (result) {
                            var copy = {};
                            angular.copy($stateParams.previousStateParams, copy);
                            var totalItems = $stateParams.previousStateParams.totalItems;
                            totalItems = totalItems - 1;
                            var page = copy.page;
                            if ($stateParams.itemsOnPage === 1) {
                                page = page - 1;
                                if (page < 1) {
                                    page = 1;
                                }
                            }
                            copy.totalItems = totalItems;
                            copy.page = page;
                            $state.go($stateParams.previousStateName, copy, {reload: true});
                        }, function () {
                            $state.go('^');
                        }
                    );
                }]
            });
    }

})();
