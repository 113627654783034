(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('BuildingCostsActionService', BuildingCostsActionService);

    BuildingCostsActionService.$inject = ['$q', '$rootScope', '$log', '$timeout', '$sessionStorage', 'RealestateService', 'SliderPresetService', 'GlobalToastService'];

    function BuildingCostsActionService($q, $rootScope, $log, $timeout, $sessionStorage, RealestateService, SliderPresetService, GlobalToastService) {

        var service = {
            overgroundFloorsChanged: overgroundFloorsChanged,
            undergroundFloorsChanged: undergroundFloorsChanged,
            overOrUndergroundFloorsChanged: overOrUndergroundFloorsChanged,
            minergieEnergyPvChanged: minergieEnergyPvChanged
        };
        var realEstateContainer = {};

        function _fetchRealEstate() {
            realEstateContainer = RealestateService.getRealEstate();
        }

        function overgroundFloorsChanged(newValue, oldValue, args) {
            _fetchRealEstate();
            if (!!newValue && newValue.length > 0 && !realEstateContainer.geometry || newValue[0] !== realEstateContainer.geometry.overgroundFloors) {
                var additionalAmounts = [
                    "qualitaetvorbereitungsarbeiten",
                    "qualitaetGrundstueck",
                    "qualitaetDerGebaeudeform2",
                    "qualitaetSanitaeranlage"
                ];

                // only set affected sliders
                var correctUsages = SliderPresetService.correctUsages(realEstateContainer.usages);
                var newValueMinergieStandard = undefined;
                var newValuePv = undefined;
                var newValueOvergroundFloors = newValue[0];
                var newValueUndergroundFloors = undefined;

                var weightedArr = SliderPresetService.getWeightedPresetValues(correctUsages,
                    realEstateContainer,
                    newValueMinergieStandard, newValuePv,
                    newValueOvergroundFloors, newValueUndergroundFloors);

                if (angular.isDefined(weightedArr)) {
                    var weightedIntervalArr = SliderPresetService.getWeightedPresetIntervals(correctUsages);
                    SliderPresetService.setSliderModelValuesArr(
                        weightedArr, weightedIntervalArr,
                        realEstateContainer, args.isPresetSliderModelValuesEnabled, // FIXME: access of newValue seems wrong
                        additionalAmounts, newValueMinergieStandard, newValuePv,
                        newValueOvergroundFloors, newValueUndergroundFloors);
                    GlobalToastService.showPresetChangedToast(args.toastId);
                }
            }
        }

        function undergroundFloorsChanged(newValue, oldValue, args) {
            _fetchRealEstate();
            if (!!newValue && newValue.length > 0 && !realEstateContainer.geometry || newValue[0] !== realEstateContainer.geometry.undergroundFloors) {
                var additionalAmounts = [
                    "qualitaetvorbereitungsarbeiten",
                    "qualitaetGrundstueck",
                    "qualitaetDerGebaeudeform2",
                    "qualitaetSanitaeranlage"
                ];

                // only set affected sliders
                var correctUsages = SliderPresetService.correctUsages(realEstateContainer.usages);
                var newValueMinergieStandard = undefined;
                var newValuePv = undefined;
                var newValueOvergroundFloors = undefined;
                var newValueUndergroundFloors = newValue[0];

                var weightedArr = SliderPresetService.getWeightedPresetValues(correctUsages,
                    realEstateContainer,
                    newValueMinergieStandard, newValuePv,
                    newValueOvergroundFloors, newValueUndergroundFloors);

                if (angular.isDefined(weightedArr)) {
                    var weightedIntervalArr = SliderPresetService.getWeightedPresetIntervals(correctUsages);
                    SliderPresetService.setSliderModelValuesArr(
                        weightedArr, weightedIntervalArr,
                        realEstateContainer, args.isPresetSliderModelValuesEnabled, // FIXME: access of newValue seems wrong
                        additionalAmounts);
                    GlobalToastService.showPresetChangedToast(args.toastId);
                }
            }
        }

        function overOrUndergroundFloorsChanged(newValue, oldValue, args) {
            _fetchRealEstate();
            if (!!newValue && !!newValue[0] && (!realEstateContainer.geometry ||  realEstateContainer.geometry.overgroundFloors !== newValue[0])) {
                overgroundFloorsChanged([newValue[0]], oldValue, args);
            }
            else if (!!newValue && newValue.length > 1 && !!newValue[1] && (!realEstateContainer.geometry || realEstateContainer.geometry.undergroundFloors !== newValue[1])) {
                undergroundFloorsChanged([newValue[1]], oldValue, args);
            }

        }

        function minergieEnergyPvChanged(newValue, oldValue, args) {
            _fetchRealEstate();
            if (!!newValue && newValue.length > 1 && (!realEstateContainer.quality || realEstateContainer.quality.minergieStandard !== newValue[0] || realEstateContainer.quality.pv !== newValue[1])) {

                var additionalAmounts = [
                    "qualitaetElektroAnlage"
                ];

                // only set affected sliders
                var correctUsages = SliderPresetService.correctUsages(realEstateContainer.usages);
                var newValueMinergieStandard = newValue[0];
                var newValuePv = newValue[1];
                var newValueOvergroundFloors = undefined;
                var newValueUndergroundFloors = undefined;

                var weightedArr = SliderPresetService.getWeightedPresetValues(correctUsages,
                    realEstateContainer,
                    newValueMinergieStandard, newValuePv,
                    newValueOvergroundFloors, newValueUndergroundFloors);
                if (angular.isDefined(weightedArr)) {
                    var weightedIntervalArr = SliderPresetService.getWeightedPresetIntervals(correctUsages);
                    SliderPresetService.setSliderModelValuesArr(
                        weightedArr, weightedIntervalArr,
                        realEstateContainer, args.isPresetSliderModelValuesEnabled,
                        additionalAmounts);
                    GlobalToastService.showPresetChangedToast('minergieToast');
                }
            }
        }

        //PLEASE READ
        /** the call back must be passed down to the component with the exact same name (newValue,oldvalue,args) https://tech.europace.de/post/passing-functions-to-angularjs-directives/
         * Please use this duplicate this template function. on args always add an origin key with hints from who the issuer is. e.g. $attrs.reference
         * @param newValue
         * @param oldValue
         * @param args some additional info can be passed here always contains an origin property.
         */
        // eslint-disable-next-line no-unused-vars
        function templateFun(newValue, oldValue, args) {
            //do something meaningful. :)

        }

        return service;
    }
})();
