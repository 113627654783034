(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('priceTable', {
            bindings: {
                organisation: '<',
                selectedWithdrawalProductTypes: '<',
                numAdditionalUsers: "<",
                baseLicense: "<",
                enableNumAddUsers: "<",
                price: "<"
            },
            templateUrl: "app/components/billing/price_tables/price-table.tpl.html",
            controller: PriceTableInputController
        });

    PriceTableInputController.$inject = ["$scope", "$translatePartialLoader", "$translate"];

    function PriceTableInputController($scope, $translatePartialLoader, $translate) {

        var $ctrl = this;

        this.$onInit = function() {
            $translatePartialLoader.addPart('prices');
            $translate.refresh();
            if($ctrl.enableNumAddUsers == null) {
                $ctrl.enableNumAddUsers = true;
            }
        };
    }

})();
