(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .directive("decimalInput", DecimalInputDirective);

    DecimalInputDirective.$inject = ["$rootScope"];
    function DecimalInputDirective($rootScope) {
        return {
            restrict: "E",
            scope: {
                reference: '=reference', // normal reference
                reference2: '=?reference2', // if reference2 is present it will render a second input (Anzahl Geschosse über / unter Terrain)
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                translateBaseKey2: '@?',
                image: '=image',
                image2: '=?',
                required: '=required',
                placeholder: '@?',
                placeholder2: '@?',
                isDisabled: '=',
                min: '@',
                max: '@',
                validationMessage: '@',
                validationMessage2: '@?',
                mandatory: '@',
                descriptionBaseKey: '@',
                descriptionBaseKey2: '@?',
                tabIndex: '=',
                tabIndex2: '=',
                tabGroup: '=',
                infotext: '@?',
                dynamicTranslations: '@?',
                inputFocusFun: '&',
                actionCallback: '&',
                isPresetSliderModelValuesEnabled: '=?',
                inputBlurFun: '&'
            },
            templateUrl: "app/components/decimal_input/decimal-input.tpl.html",
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                //if there are 2 inputs - it will append the last part of the descriptionbaseKey2 to the entire descriptionbaseKey
                //to get a combined input label.
                if (scope.descriptionBaseKey2 != undefined) {
                    var descriptionSplit2 = scope.descriptionBaseKey2.split(".");
                    scope.translateLabelKey = scope.descriptionBaseKey + '-' + descriptionSplit2[descriptionSplit2.length - 1] + '.label';

                    //Add validation for 2 input
                    var inputField2 = tElement.find('input')[1];
                    // Used for the validation
                    $(inputField2).attr('id', tAttrs.reference2 + '.input');
                } else {
                    scope.translateLabelKey =
                        scope.descriptionBaseKey + '.label';
                }
                if (!angular.isFunction(scope.actionCallback)) {
                    scope.actionCallback = angular.noop;
                }

                scope.hasFocusOutFired = false;
                var input = document.getElementById('inputid');
                var inputField = tElement.find('input')[0];

                // Used for the validation
                $(tElement).attr('id', tAttrs.reference + '.formElement');
                $(inputField).attr('id', tAttrs.reference + '.input');
                //used for toast
                scope.toastId = tAttrs.reference +'.toast';

                if (tAttrs.required) {
                    inputField.required = true;
                    inputField2.required = true;
                }
                scope.focus = function(inputNumber) {
                    if (scope.inputFocusFun) {
                        scope.inputFocusFun();
                    }
                    scope.hasFocusOutFired = false;
                    // Push event to CalculationRowController
                    switch (inputNumber) {
                    case 1:
                        $rootScope.$broadcast('updateImage', {
                            imageSrc: sanitizeUrl(tAttrs.image),
                            rowid: Number(tAttrs.rowid)
                        });
                        $rootScope.$broadcast("updateDescription", {
                            rowid: Number(tAttrs.rowid),
                            descriptionNumber: 0,
                            dynamicTranslations: tAttrs.dynamicTranslations,
                            descriptionBaseKey: tAttrs.descriptionBaseKey
                        });
                        break;
                    case 2:
                        $rootScope.$broadcast("updateImage", {
                            imageSrc: sanitizeUrl(tAttrs.image2),
                            rowid: Number(tAttrs.rowid)
                        });
                        $rootScope.$broadcast("updateDescription", {
                            rowid: Number(tAttrs.rowid),
                            descriptionNumber: 0,
                            dynamicTranslations: tAttrs.dynamicTranslations,
                            descriptionBaseKey: tAttrs.descriptionBaseKey2
                        });
                        break;
                    }
                };
                scope.focusOut = function (inputNumber) {
                    if (scope.inputBlurFun) {
                        scope.inputBlurFun();
                    }
                    if (scope.hasFocusOutFired) {
                        return;
                    }
                    scope.hasFocusOutFired = true;
                    switch (inputNumber) {
                    case 1:
                        if (scope.reference && scope.reference.length > 2 && scope.reference.search(',') !== -1) {
                            scope.reference = scope.reference.replace(',', '.');
                        }
                        scope.reference = parseFloat(scope.reference);
                        break;
                    case 2:
                        if (scope.reference2 && scope.reference2.length > 2 && scope.reference2.search(',') !== -1) {
                            scope.reference2 = scope.reference2.replace(',', '.');
                        }
                        scope.reference2 = parseFloat(scope.reference2);
                        break;
                    }
                    scope.actionCallback({
                        newValue: [scope.reference,scope.reference2],
                        oldValue: undefined,
                        args: {
                            origin: tAttrs.reference,
                            rowid: scope.rowid,
                            descriptionBaseKey: scope.descriptionBaseKey,
                            isPresetSliderModelValuesEnabled: scope.isPresetSliderModelValuesEnabled,
                            toastId: scope.toastId
                        }
                    });
                };
            }
        };
    }
})();
