(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildTemplateService', RebuildTemplateService);

    RebuildTemplateService.$inject = ['$log', 'RebuildEstateDataService', 'SharedControllerFnService', 'HeatingTypeDataService', 'VentilationTypeDataService', 'ChillerTypeDataService'];

    function RebuildTemplateService($log, RebuildEstateDataService, SharedControllerFnService, HeatingTypeDataService, VentilationTypeDataService, ChillerTypeDataService) {
        var service = {
            selectedTemplates: getSelectedTemplates,
            // templates mapped to java enum TemplateType
            templates: [
                'NONE',
                'ALL',
                'CONSTRUCTION', 'SHELL', 'FITOUT',
                'ANNEX', 'ADDITION_STORY', 'ADDITION_HNF'
            ],
            presets: {
                'CONSTRUCTION': ['CONSTRUCTION', 'FLOORING'],
                'WALLCOVERING': ['WALLCOVERING'],
                'BALCONY': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'FLATROOF'],
                'COMPONENTS_EXTERNAL_WALL': ['COMPONENTS_EXTERNAL_WALL'],
                'ROOF': ['INCLINEDROOF','FLATROOF'],
                'ELECTRICITY': ['ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT'],
                'HEAT': ['HEAT_GENERATION', 'HEAT_DISTRIBUTION'],
                'VENTILATION': [
                    'VENTILATION_GENERATION',
                    'VENTILATION_DISTRIBUTION',
                    'CLIMA_CHILLER_GENERATION',
                    'CLIMA_CHILLER_DISTRIBUTION'
                ],
                'SANITARY': ['SANITARY_APPARATUS', 'SANITARY_PIPES', 'FLOORING'],
                'INSTALLATION__SURFACES': ['INSTALLATION', 'FLOORING'],
                'KITCHEN': [
                    'ELECTRIC_HIGH_CURRENT',
                    'ELECTRIC_LOW_CURRENT',
                    'SANITARY_APPARATUS',
                    'SANITARY_PIPES',
                    'KITCHEN',
                    'FLOORING'
                ],
                'TRANSPORT_INSIDE': ['CONSTRUCTION',  'ELECTRIC_HIGH_CURRENT', 'FLOORING', 'TRANSPORTATIONSYSTEM'],
                'TRANSPORT_OUTSIDE': ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'ELECTRIC_HIGH_CURRENT', 'FLATROOF', 'TRANSPORTATIONSYSTEM', 'ENVIRONMENT'],
                'UNDERGROUND_PARKING': ['CONSTRUCTION', 'FLOORING'],
                'SURROUNDINGS': ['ENVIRONMENT']
            },
            register: register,
            selectTemplate: selectTemplate,
            deselectTemplate: deselectTemplate,
            getSelectedTemplates: getSelectedTemplates,
            isOnlyTargetOverhaul: isOnlyTargetOverhaul,
            doShow: doShow,
            getComponents: getComponents,
            getActiveComponents: getActiveComponents,
            getNonChangingComponents: getNonChangingComponents,
            handleSpecialCaseChiller: handleSpecialCaseChiller,
            isNone: isNone,
            isAnnex: isAnnex,
            isAdditionStory: isAdditionStory
        };

        function isNone(selectedTemplates) {
            return _.includes(selectedTemplates || getSelectedTemplates(), 'NONE');
        }

        function isAnnex(selectedTemplates) {
            return _.includes(selectedTemplates || getSelectedTemplates(), 'ANNEX');
        }

        function isAdditionStory(selectedTemplates) {
            return _.includes(selectedTemplates || getSelectedTemplates(), 'ADDITION_STORY');
        }

        this.components = [];

        var dictTemplateToRegisteredIdents = {};
        var dictIdentToRegisteredTemplates = {};

        function isOnlyTargetOverhaul() {
            return !service.isAnnex() && !service.isAdditionStory();
        }

        // gets data from dataService
        function getSelectedTemplates(){
            return RebuildEstateDataService.get().selectedTemplates;
        }

        function addTemplateIdent(template, ident) {
            var idents = dictTemplateToRegisteredIdents[template];
            if (idents && idents.indexOf(ident) === -1) {
                idents.push(ident);
                dictTemplateToRegisteredIdents[template] = idents;
            } else {
                dictTemplateToRegisteredIdents[template] = [ident];
            }

            var templates = dictIdentToRegisteredTemplates[ident];
            if (templates && templates.indexOf(template) === -1) {
                templates.push(template);
                dictIdentToRegisteredTemplates[ident] = templates;
            } else {
                dictIdentToRegisteredTemplates[ident] = [template];
            }
        }

        function _merge(components, newComponents) {
            if (newComponents === undefined) return components;
            return newComponents.reduce(function(a, b) {
                if (a.indexOf(b) < 0) a.push(b);
                return a;
            }, components);
        }

        function getComponents(templateContainer, isAnnex) {
            var components = [];
            var realEstate = RebuildEstateDataService.get();
            if (realEstate == null) return components;
            var presetKeys = [];
            if (templateContainer == null || templateContainer.groundwork.length === 0) {
                presetKeys = [];
            } else if (templateContainer.groundwork.indexOf('COMPLETE_RENOVATION') > -1 || templateContainer.groundwork.indexOf('UNKNOWN') > -1) {
                presetKeys = templateContainer.complete;
            } else {
                presetKeys.push(templateContainer.interior);
                presetKeys.push(templateContainer.exterior);
                presetKeys = _.compact(presetKeys);
                presetKeys = _.flattenDeep(presetKeys);
            }

            if (isAnnex && !_.includes(presetKeys,'SURROUNDINGS')) {
                presetKeys.push('SURROUNDINGS');
            }

            if (presetKeys != null && presetKeys.length > 0) {
                _.forEach(presetKeys, function(value) {
                    components = _merge(components, service.presets[value]);
                });
            }

            return components;
        }

        function _filterComponents(components, componentsToFilter) {
            return components.filter(function(component) { return !componentsToFilter.includes(component); });
        }

        function getNonChangingComponents(
            components,
            ventilationType, chillerType, elevatorInside, elevatorOutside, facadeType, roofTypes, heatingTypes, builtUpArea,
            targetOverhaulVentilationType, targetOverhaulChillerType, targetOverhaulElevatorInside, targetOverhaulElevatorOutside, targetOverhaulFacadeType, targetOverhaulRoofTypes, targetOverhaulHeatingTypes, targetOverhaulBuiltUpArea
        ) {
            if (ventilationType !== targetOverhaulVentilationType)
                components = _filterComponents(components, ['VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION']);

            if (chillerType !== targetOverhaulChillerType)
                components = _filterComponents(components, ['CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION']);

            if (!_.isEqual(_.sortBy(heatingTypes), _.sortBy(targetOverhaulHeatingTypes)))
                components = _filterComponents(components, ['HEAT_GENERATION', 'HEAT_DISTRIBUTION']);

            if (facadeType !== targetOverhaulFacadeType)
                components = _filterComponents(components, ['WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL']);

            if ((!_.includes(roofTypes, 'INCLINEDROOF') && _.includes(targetOverhaulRoofTypes, 'INCLINEDROOF')) ||
                (_.includes(roofTypes, 'INCLINEDROOF') && !_.includes(targetOverhaulRoofTypes, 'INCLINEDROOF')))
                components = _filterComponents(components, ['INCLINEDROOF']);

            if ((!_.includes(roofTypes, 'FLATROOF') && _.includes(targetOverhaulRoofTypes, 'FLATROOF')) ||
                (_.includes(roofTypes, 'FLATROOF') && !_.includes(targetOverhaulRoofTypes, 'FLATROOF')))
                components = _filterComponents(components, ['FLATROOF']);

            if ((elevatorInside != null && targetOverhaulElevatorInside != null && elevatorInside.verticalDecision !== targetOverhaulElevatorInside.verticalDecision) &&
                (elevatorOutside != null && targetOverhaulElevatorOutside != null && elevatorOutside.verticalDecision !== targetOverhaulElevatorOutside.verticalDecision)
            )
                components = _filterComponents(components, ['TRANSPORTATIONSYSTEM']);

            return components;
        }

        function getActiveComponents(
            components,
            ventilationType, chillerType, elevatorInside, elevatorOutside, facadeType, roofTypes, heatingTypes, builtUpArea
        ) {
            if (facadeType === 'KEINE_FASSADE')
                components = _filterComponents(components, ['WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL']);

            if (!_.includes(roofTypes, 'INCLINEDROOF'))
                components = _filterComponents(components, ['INCLINEDROOF']);

            if (!_.includes(roofTypes, 'FLATROOF'))
                components = _filterComponents(components, ['FLATROOF']);

            if (_.includes(heatingTypes, HeatingTypeDataService.NO_HEATING.value))
                components = _filterComponents(components, ['HEAT_GENERATION', 'HEAT_DISTRIBUTION']);

            if (ventilationType === VentilationTypeDataService.NONE.value)
                components = _filterComponents(components, ['VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION']);

            if (chillerType === ChillerTypeDataService.NO.value)
                components = _filterComponents(components, ['CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION']);

            if ((elevatorInside != null && elevatorInside.verticalDecision !== 'yes') && (elevatorOutside != null && elevatorOutside.verticalDecision !== 'yes'))
                components = _filterComponents(components, ['TRANSPORTATIONSYSTEM']);

            if (builtUpArea === 0)
                components = _filterComponents(components, ['ENVIRONMENT']);

            return components;
        }

        function register(ident, templates) {
            // registers templates to an ident
            // at least one template must mach to the ident in order to make doShow return true
            // check inputs
            templates.forEach(function(template) {
                if (templates.indexOf(template) === -1) {
                    $log.error("Template " + template + " is unknown");
                }
            });
            templates.forEach(function(template) {
                addTemplateIdent(template, ident);
            });
        }

        function selectTemplate(newTemplate) {
            $log.info("selectTemplate: " + newTemplate);
            if (service.selectedTemplates().indexOf(newTemplate) === -1) {
                service.selectedTemplates().push(newTemplate);
            }
        }

        function deselectTemplate(oldTemplate) {
            $log.info("oldTemplate: " + oldTemplate);
            var index = service.selectedTemplates().indexOf(oldTemplate);
            if (index !== -1) {
                service.selectedTemplates().splice(index, 1);
            }
        }

        function handleSpecialCaseChiller(components, isUsages, targetOverhaulUsages) {
            var hasUsageChiller = SharedControllerFnService.hasUsageArrayWithChiller(_.concat(
                isUsages,
                targetOverhaulUsages
            ));
            if (!hasUsageChiller) {
                components = components.filter(function(component) {
                    return !['CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION'].includes(component);
                });
            }
            return {
                hasUsageChiller: hasUsageChiller,
                components: components
            };

        }

        function doShow(ident) {
            // indicates whether to show the provided ident and it's html
            // shows if one of the registered templates matches to the provided ident
            var templates = dictIdentToRegisteredTemplates[ident];

            // one of these templates has to be selected
            if (templates) {
                for (var i = 0; i < templates.length; i++) {
                    if (service.getSelectedTemplates().indexOf(templates[i]) > -1) {
                        return true;
                    }
                }
            }
            return false;
        }
        return service;
    }

})();
