(function () {
    'use strict';

    angular.module('kennwerteApp')
    .component('commentInput', {
        bindings: {
            reference: '=?',
            rowid: '=',
            translateDescriptionBaseKey: '@',
            translateBaseKey: '@',
            image: '=',
            mandatory: '<?',
            placeholder: '@?',
            isDisabled: '=',
            validationMessage: '@',
            descriptionBaseKey: '@',
            infotext: '@?'

        },
        templateUrl: 'app/components/comment/comment-input.tpl.html',
        controller: CommentInputController
    });

    CommentInputController.$inject = ['$scope', '$rootScope'];

    function CommentInputController($scope, $rootScope) {
        var $ctrl = this;
        $scope.firstLoad = true;

        $ctrl.updateDescriptionAndImage = function (selectedItem, model, string) {
            $rootScope.$broadcast("updateImage", {
                rowid: $ctrl.rowid,
                imageSrc: $ctrl.image
            });

            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionNumber: selectedItem,
                descriptionBaseKey: $ctrl.descriptionBaseKey
            });
        };

        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');

        };
    }


})();
