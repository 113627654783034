(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageHowtoEditRecordController', DamageHowtoEditRecordController);

    DamageHowtoEditRecordController.$inject = ['$scope', '$stateParams', '$state'];

    function DamageHowtoEditRecordController($scope, $stateParams, $state) {
        $scope.data = $stateParams.data;
    }

})();

