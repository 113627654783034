(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .directive('kwBoxPlot', KwBoxPlotDirective);

    KwBoxPlotDirective.$inject = ['d3Service'];

    /**
     * Boxplot which is aware of outliers.
     * Always expects to have input data scaled between min=0 and max=1.
     * @param d3Service
     * @returns {{template: string, scope: {margin: string, data: string, doDrawAxis: string, whConfig: string}, link: link, restrict: string}}
     * @constructor
     */
    function KwBoxPlotDirective(d3Service) {
        return {
            restrict: 'EA',
            scope: {
                data: '=?',
                doDrawAxis: '=?',
                margin: '=?',
                whConfig: '=?'
            },
            template: '',
            link: function (scope, element, attrs) {

                var origSvg = undefined;

                function draw(d3) {

                    var width, height, barWidth, margin;
                    if (scope.whConfig && scope.margin) {
                        width = scope.whConfig['width'];
                        height = scope.whConfig['height'];
                        barWidth = scope.whConfig['barWidth'];
                        margin = scope.margin;
                    } else {
                        width = 402;
                        height = 130;
                        barWidth = 60;
                        margin = {top: 5, right: 10, bottom: 10, left: 10};
                    }

                    width = width - margin.left - margin.right;
                    height = height - margin.top - margin.bottom;

                    var totalWidth = width + margin.left + margin.right;
                    var totalHeight = height + margin.top + margin.bottom;

                    var strokeWidth = 1;

                    // Prepare the data for the box plots
                    var boxPlotData = scope.data;
                    // Compute an ordinal xScale for the keys in boxPlotData
                    var xScale = d3.scaleLinear()
                        .domain([0, 1])
                        .range([0, height]);

                    var min = 0;
                    var max = boxPlotData[0]["max"];
                    if (!max) {
                        max = boxPlotData[0]["whiskers"][1];
                    }
                    var yScale = d3.scaleLinear()
                        .domain([min, max])
                        .range([0, width]);

                    // Setup the svg and group we will draw the box plot in
                    origSvg = d3.select(element[0]).append('svg');
                    var svg = origSvg
                        .attr("width", totalWidth)
                        .attr("height", totalHeight)
                        .append("g")
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                    if (scope.doDrawAxis) {
                        // Move the top axis 80 pixels along y
                        var axisTopG = svg.append("g").attr("transform", "translate(0,100)");
                    }

                    // Setup the group the box plot elements will render in
                    var g = svg.append("g")
                        .attr("transform", "translate(0,0)");


                    // Draw the outlier dots
                    var horizontalLines = g.selectAll(".horizontalLines")
                        .data(boxPlotData)
                        .enter()
                        .append("line")
                        .attr("y1", function (datum) {
                            //return xScale(datum.key) + barWidth / 2;
                            return xScale(datum.key) + barWidth / 2;
                        })
                        .attr("x1", function (datum) {
                            var whisker = datum.outliers[0];
                            return yScale(whisker);
                        })
                        .attr("y2", function (datum) {
                            //return xScale(datum.key) + barWidth / 2;
                            return xScale(datum.key) + barWidth / 2;
                        })
                        .attr("x2", function (datum) {
                            var whisker = datum.outliers[1];
                            return yScale(whisker);
                        })
                        .style("stroke-dasharray", ("0.3, 9"))
                        .attr("stroke", "#f2cfa2")//#f7e1c5
                        .attr("stroke-width", 6)
                        .attr("stroke-linecap", "round")
                        .attr("fill", "none");


                    // Draw the boxes of the box plot, filled in white and on top of vertical lines
                    var nodes = svg.selectAll(".rect")
                        .data(boxPlotData)
                        .enter()
                        .append("g")
                        .classed('rect', true);

                    nodes.append("rect")
                        .attr("height", barWidth)
                        .attr("width", function (datum) {
                            var whiskers = datum.whiskers;
                            var height = yScale(whiskers[1]) - yScale(whiskers[0]);
                            return height;
                        })
                        .attr("y", function (datum) {
                            return xScale(datum.key);
                        })
                        .attr("x", function (datum) {
                            return yScale(datum.whiskers[0]);
                        })
                        .attr("fill", "#f1ca98");
                        //.attr("stroke", "#000")
                        //.attr("stroke-width", strokeWidth);

                    nodes.append("rect")
                        .attr("height", barWidth)
                        .attr("width", function (datum) {
                            var quartiles = datum.quartile;
                            var height = yScale(quartiles[2]) - yScale(quartiles[0]);
                            return height;
                        })
                        .attr("y", function (datum) {
                            return xScale(datum.key);
                        })
                        .attr("x", function (datum) {
                            return yScale(datum.quartile[0]);
                        })
                        .attr("fill", "#e8a854");
                        //.attr("stroke", "#000")
                        //.attr("stroke-width", strokeWidth);

                    // Now render all the horizontal lines at once - the whiskers and the median
                    var verticalLineConfigs = [
                        /*
                        // Lower outlier
                        {
                            x1: function (datum) {
                                return xScale(datum.key) + barWidth / 4;
                            },
                            y1: function (datum) {
                                return yScale(datum.outliers[0]);
                            },
                            x2: function (datum) {
                                return xScale(datum.key) + barWidth / 4 + barWidth / 2;
                            },
                            y2: function (datum) {
                                return yScale(datum.outliers[0]);
                            },
                            stroke: function (datum) {
                                return "#000";
                            },
                            strokeWidth: function (datum) {
                                return strokeWidth;
                            }
                        },
                         */
                        // Median line
                        {
                            x1: function (datum) {
                                return xScale(datum.key);
                            },
                            y1: function (datum) {
                                return yScale(datum.quartile[1]);
                            },
                            x2: function (datum) {
                                return xScale(datum.key) + barWidth;
                            },
                            y2: function (datum) {
                                return yScale(datum.quartile[1]);
                            },
                            stroke: function (datum) {
                                return "#bb7c7a";
                            },
                            strokeWidth: function (datum) {
                                return 4 * strokeWidth;
                            }
                        }
                        // Upper outlier
                        /*
                        {
                            x1: function (datum) {
                                return xScale(datum.key) + barWidth / 4;
                            },
                            y1: function (datum) {
                                return yScale(datum.outliers[1]);
                            },
                            x2: function (datum) {
                                return xScale(datum.key) + barWidth / 4 + barWidth / 2;
                            },
                            y2: function (datum) {
                                return yScale(datum.outliers[1]);
                            },
                            stroke: function (datum) {
                                return "#000";
                            },
                            strokeWidth: function (datum) {
                                return strokeWidth;
                            }
                        }
                         */
                    ];

                    for (var i = 0; i < verticalLineConfigs.length; i++) {
                        var lineConfig = verticalLineConfigs[i];

                        // Draw the whiskers at the min for this series
                        var verticalLine = nodes
                            .append("line")
                            .attr("y1", lineConfig.x1)
                            .attr("x1", lineConfig.y1)
                            .attr("y2", lineConfig.x2)
                            .attr("x2", lineConfig.y2)
                            .attr("stroke", lineConfig.stroke)
                            .attr("stroke-width", lineConfig.strokeWidth)
                            .attr("fill", "none");
                    }

                    if (scope.doDrawAxis) {
                        // Setup a series axis on the top
                        var axis = d3.axisBottom(yScale)
                            .ticks(5)
                            .tickFormat(d3.format(".0%"));
                        axisTopG.append("g")
                            .call(axis);
                    }
                }

                function remove(d3) {
                    origSvg.remove();
                }

                d3Service.load().then(function (d3) {
                    draw(d3);
                });

                scope.$watch('data', function(newVal, oldVal) {
                    if (newVal != undefined && newVal !== oldVal && newVal) {
                        d3Service.load().then(function (d3) {
                            // TODO: remove old svg
                            remove(d3);
                            draw(d3);
                        });
                    }
                }, true);

            }
        };
    }

})();
