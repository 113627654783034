(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('price-calculator', {
                parent: 'admin',
                url: '/price-calculator',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/price-calculator/price-calculator.html',
                        controller: 'PriceCalculatorController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('process_form')
                            .addPart('realestates')
                            .addPart('settings')
                            .addPart('organisation-management')
                            .addPart('billing')
                            .addPart('prices');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
