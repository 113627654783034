(function () {
    "use strict";

    angular.module("kennwerteApp")
        .component("modulOverviewTable", {
            bindings: {
                activeWpt: "=?",
                activeWp: "=?",
                organisation: "=?",
                baseContainer: "=?",
                wptFreeTracking: "=?",
                freeWpt: "=?",
                modules: "<?",
                translateDescriptionBaseKey: "@",
                onManipulateLicenseSuccessFn: "&"
            },
            templateUrl: "app/account/billing/billing.edit/modul_overview.tpl.html",
            controller: ModulOverviewController
        });

    ModulOverviewController.$inject = ["$scope", "$rootScope", "$q", "$location", "$element", "$translate", "$attrs", "ExistingWptService", "BillingService", "Principal", "moment", "WptFreeTrackingService", "$uibModal"];

    function ModulOverviewController($scope, $rootScope, $q, $location, $element, $translate, $attrs, ExistingWptService, BillingService, Principal, moment, WptFreeTrackingService, $uibModal) {
        var $ctrl = this;
        $ctrl.firstLoad = true;
        $ctrl.showModuleSelction = false;

        $ctrl.selectedWithdrawalProductTypes = [];
        $ctrl.selectedFreeWithdrawalProductTypes = [];

        $ctrl.selectedWptToCancel = "";
        $ctrl.selectedFreeWptToCancel = "";
        $ctrl.successMessage = null;

        var now = new Date();
        var nowPlus7days = new Date(now);
        nowPlus7days.setDate(now.getDate() + 7);
        $ctrl.dateTimeFreeFrom = now;
        $ctrl.dateTimeFreeUntil = new Date(nowPlus7days.getFullYear(), nowPlus7days.getMonth(), nowPlus7days.getDate(), nowPlus7days.getHours(), nowPlus7days.getMinutes(), nowPlus7days.getSeconds());

        $ctrl.getNewPriceFree = function (wpts) {
            $ctrl.organisation.withdrawalProductTypes = wpts;
            Principal.identity().then(function (e) {
                var registerDTO = {
                    "account": e,
                    "organisation": $ctrl.organisation,
                    "numAdditionalUsers": $ctrl.activeWp.numMaxUsers - 1
                };
                BillingService.fetchTentativePrice(registerDTO).then(
                    function(res) {
                        $ctrl.priceFree = res.data;
                    }, function(error) {
                        console.error('bill: ', error);
                    });
            });
        };

        $ctrl.getNewDiffPrice = function (wpts, freeDays) {
            $ctrl.organisation.withdrawalProductTypes = wpts;
            BillingService.fetchAdditionalPrice(0, wpts, freeDays)
                .then(
                    function (res) {
                        console.log(res);
                        $ctrl.additionalPrice = res.data;
                    }, function (error) {
                        console.error('fetch add price: ',error);
                    });
        };

        $ctrl.selectFreeWpt = function (wpt) {
            $ctrl.selectedWptToCancel = "";
            if (!_.includes($ctrl.selectedFreeWithdrawalProductTypes, wpt)) {
                $ctrl.selectedFreeWithdrawalProductTypes.push(wpt);
            } else {
                var index = $ctrl.selectedFreeWithdrawalProductTypes.indexOf(wpt);
                $ctrl.selectedFreeWithdrawalProductTypes.splice(index, 1);
            }
            $ctrl.selectedWptToCancel = ""; // remove cancelation (if selected previously)
            $ctrl.selectedFreeWptToCancel = ""; // remove cancelation (if selected previously)
            $ctrl.selectedWithdrawalProductTypes.splice(0, $ctrl.selectedWithdrawalProductTypes.length); // remove non-free products (if selected previously)
            _.uniq($ctrl.selectedFreeWithdrawalProductTypes);
            if ($ctrl.activeWp.licenseState === 'FREE' || $ctrl.activeWp.licenseState === 'INVALID') {
                $ctrl.getNewPriceFree($ctrl.selectedFreeWithdrawalProductTypes, 7);  // TODO check if 7 is right
            } else {
                $ctrl.getNewDiffPrice($ctrl.selectedFreeWithdrawalProductTypes, 7);  // TODO check if 7 is right
            }
        };

        $ctrl.checkFilter = function (wpt) {
            if ($ctrl.modules != null) {
                return _.includes($ctrl.modules, wpt);
            } else {
                return true;
            }
        };

        $ctrl.checkNonFreeWpt = function (wpt) {
            var freeWpt = _.find($ctrl.wptFreeTracking, {"wpt": wpt});
            if (freeWpt != null) {
                return (moment.utc().isAfter(freeWpt.dateTimeFreeUntil));
            } else {
                return false;
            }
        };

        $ctrl.checkNonFreeAndActiveWpt = function (freeTrack) {
            return $ctrl.checkNonFreeWpt(freeTrack.wpt) && !freeTrack.dateTimeInactive;
        };

        $ctrl.checkNonFreeAndInactiveWpt = function (freeTrack) {
            return $ctrl.checkNonFreeWpt(freeTrack.wpt) && !!freeTrack.dateTimeInactive;
        };

        $ctrl.checkFreeAndActiveWpt = function (freeTrack) {
            return moment.utc().isBefore(freeTrack.dateTimeFreeUntil) && !freeTrack.dateTimeInactive;
        };

        $ctrl.checkFreeAndInactiveWpt = function (freeTrack) {
            return moment.utc().isBefore(freeTrack.dateTimeFreeUntil) && !!freeTrack.dateTimeInactive;
        };

        $ctrl.togglePrepareCancelModul = function (wpt, isFree) {
            $ctrl.selectedWithdrawalProductTypes = [];
            $ctrl.selectedFreeWithdrawalProductTypes = [];
            $ctrl.priceFree = null;
            if (isFree) {
                $ctrl.selectedWptToCancel = "";
                if ($ctrl.selectedFreeWptToCancel === wpt) {
                    $ctrl.selectedFreeWptToCancel = "";
                } else {
                    $ctrl.selectedFreeWptToCancel = wpt;
                }
            } else {
                $ctrl.selectedFreeWptToCancel = "";
                if ($ctrl.selectedWptToCancel === wpt) {
                    $ctrl.selectedWptToCancel = "";
                } else {
                    $ctrl.selectedWptToCancel = wpt;
                }
            }
        };

        $ctrl.cancelModul = function (wpt) {
            WptFreeTrackingService.cancelModule({}, {wpt: wpt}, function (res) {
                console.warn(res);
                $ctrl.successMessage = "MODULE_CANCELED";
                $ctrl.selectedFreeWptToCancel = "";
                $ctrl.selectedWptToCancel = "";
                $ctrl.onManipulateLicenseSuccessFn();
            }, function (error) {
                console.warn(error);
            });
        };

        $ctrl.reactivateFreeModule = function (wpt) {
            WptFreeTrackingService.activateModule({}, {wpt: wpt.wpt})
                .$promise.then(
                    function (res) {
                        console.warn(res);
                        $ctrl.successMessage = "MODULE_ACTIVATED";
                        $ctrl.onManipulateLicenseSuccessFn();
                    },
                    function (error) {
                        console.warn(error);
                    }
                );
        };

        function _promiseResolve(promises) {
            return $q.all(promises)
                .then(
                    function (res) {
                        console.warn(res);
                        $ctrl.selectedWithdrawalProductTypes = [];
                        $ctrl.selectedFreeWithdrawalProductTypes = [];
                        $ctrl.onManipulateLicenseSuccessFn();
                    },
                    function (error) {
                        console.warn(error);
                        $ctrl.selectedWithdrawalProductTypes = [];
                        $ctrl.selectedFreeWithdrawalProductTypes = [];
                        $ctrl.onManipulateLicenseSuccessFn();
                    }
                );
        }

        $ctrl.activateFreeModuleIndirect = function () {
            console.info("active free modules", $ctrl.selectedFreeWithdrawalProductTypes);
            // additional price
            var promises = $ctrl.selectedFreeWithdrawalProductTypes.map(function (freeWpt) {
                var deferred = $q.defer();
                WptFreeTrackingService.activateModule({}, { wpt: freeWpt }, function(res) {
                    console.warn(res);
                    $ctrl.successMessage = "MODULE_ORDERED";
                    deferred.resolve(res);
                }, function(error) {
                    console.error(error);
                    deferred.reject(error.status + " ");
                });
                return deferred.promise;
            });
            _promiseResolve(promises);
        };

        $ctrl.activateNonFreeModuleIndirectInvoice = function() {
            var promises = $ctrl.selectedWithdrawalProductTypes.map(function (wpt) {
                var deferred = $q.defer();
                WptFreeTrackingService.activateModule({}, {wpt: wpt}, function(res) {
                    $ctrl.successMessage = "MODULE_BOUGHT";
                    deferred.resolve(res);
                }, function(error) {
                    deferred.reject(error.status + " ");
                });
                return deferred.promise;
            });
            _promiseResolve(promises);
        };

        $ctrl.toggleModuleToBuy = function(wpt, licence) {
            $ctrl.selectModuleToBuyOTHER(wpt);
        };

        $ctrl.selectModuleToBuyOTHER = function (wpt) {
            // direct payment in states FREE
            // indirect payment in states PAYED, PAYMENT_UNSUCCESSFUL
            $ctrl.initPaymentProcess(wpt);
        };

        $ctrl.isContainedInActiveWpts = function(activeFreeWpt) {
            return _.includes($ctrl.activeWp.withdrawalProductTypes, activeFreeWpt.wpt);
        };

        $ctrl.initPaymentProcess = function (wpt) {
            if (!_.includes($ctrl.selectedWithdrawalProductTypes, wpt)) {
                $ctrl.selectedWithdrawalProductTypes.push(wpt);
            } else {
                var index = $ctrl.selectedWithdrawalProductTypes.indexOf(wpt);
                $ctrl.selectedWithdrawalProductTypes.splice(index, 1);
            }
            $ctrl.selectedWptToCancel = ""; // remove cancelation (if selected previously)
            $ctrl.selectedFreeWptToCancel = ""; // remove cancelation (if selected previously)
            $ctrl.selectedFreeWithdrawalProductTypes.splice(0, $ctrl.selectedFreeWithdrawalProductTypes.length); // remove free products (if selected previously)
            $ctrl.showModuleSelction = true;
            if ($ctrl.activeWp.licenseState === 'FREE') {
                $ctrl.getNewPriceFree($ctrl.selectedWithdrawalProductTypes);
            } else {
                $ctrl.getNewDiffPrice($ctrl.selectedWithdrawalProductTypes, 0);
            }
        };

    }
})();
