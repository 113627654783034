(function() {
    'use strict';
    /*      angular
            .module('app', ['appLoader']);*/

    /*
        Raven
            .config('https://f1dac8d2da6b42f4a5243166a8245bec@o423824.ingest.sentry.io/5354778')
            .addPlugin(Raven.Plugins.Angular)
            .install();
        */

    /*Sentry.init({
  dsn: __YOUR_DSN__,
  integrations: [new AngularIntegration({ angular })]
})
*/

    angular
        .module('kennwerteApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            // 'ngTouch',
            'slickCarousel',
            'ngAnimate',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // 'ngRaven',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ngMaterial',
            'smoothScroll',
            '$q-spread',
            'ngFileSaver',
            'ui.select',
            'ngSanitize',
            'ngPatternRestrict',
            'autoCompleteModule',
            'star-rating',
            'angularMoment',
            'countUpModule',
            'angular-inview',
            'kennwerteApp.agv'
        ])
        .run(run);

    angular
        .module('kennwerteApp.agv', ['ui.router', 'kennwerteApp']);

    /*  angular
          .module('kennwerteApp.agv', ['ui.router','kennwerteApp']);
      /!**
         * Lets create a application loader
         * This component is going to load sub domain specific application
         *!/
        angular
          .module('appLoader', ['kennwerteApp', 'kennwerteApp.agv'])
          .directive("loadApplication", ['$location', function($location) {
            function getDomain() {
                console.warn('yolo');
              console.log($location.host());
              //get domain name here
              return $location.host();
            }
            return {
              restrict: 'E',
              controller: function($scope, $element, $attrs, $transclude) {},
              template: function() {
                var domainName = getDomain(),
                  templateName = '';
                var strings = domainName.split('.');
                console.log(strings);
                switch (strings[0]) {
                  case 'agv':
                    templateName = '<kennwerte-agv></kennwerte-agv>';
                    break;
                default:
                    templateName = '<kennwerte-app></kennwerte-app>';
                    break;
                }
                console.log(templateName);
                return templateName;
              },
              link: function($scope, element, attrs) {
                console.info('loader application');
              }
            };

          }]);

        angular
          .module('kennwerteApp')
          .directive("kennwerteApp", ['$location', function($location) {

            return {
              restrict: 'E',
              template: '<page-ribbon></page-ribbon>\n' +
                        '<div ui-view="navbar" ng-cloak></div>\n' +
                        '<div class="container">\n' +
                        '    <div class="well" ui-view="content"></div>\n' +
                        '</div>\n' +
                        '<div class="footer" ui-view="footer"></div>',
              link: function($scope, element, attrs) {
                $scope.applicationName = 'Application Administrator';
              }
            };

          }]);

        angular
          .module('kennwerteApp.agv',['kennwerteApp'])
          .directive("kennwerteAgv", ['$location', function($location) {

            return {
              restrict: 'E',
              template: '<h2>{{applicationName}}+Wieso michi</h2>' +
                  '<page-ribbon></page-ribbon>\n' +
                        '<div ui-view="navbar" ng-cloak></div>\n' +
                        '<div class="container">\n' +
                        '    <div class="well" ui-view="content"></div>\n' +
                        '</div>\n' +
                        '<div class="footer" ui-view="footer"></div>',
              link: function(scope, element, attrs) {
                  scope.applicationName = 'Application Employee';
              }
            };

          }]);
  */

    run.$inject = ['stateHandler', 'translationHandler', 'amMoment', '$rootScope', '$uibModalStack', 'CONST_BUILD', 'MetaTagService', '$trace'];

    function run(stateHandler, translationHandler, amMoment, $rootScope, $uibModalStack, CONST_BUILD, MetaTagService, $trace) {
        /* Currently not used FIXME SE-762
                if(CONST_BUILD === 'local'){
                    Raven.uninstall();
                } else {
                    // Raven.setDSN('https://f1dac8d2da6b42f4a5243166a8245bec@o423824.ingest.sentry.io/5354778');
                    Raven.setEnvironment({ environment:CONST_BUILD});
                }*/
        stateHandler.initialize();
        //used to check state changes e.g. debugging state issues.
        // $trace.enable('TRANSITION');
        translationHandler.initialize();
        amMoment.changeLocale('de');
        // https://github.com/angular-ui/bootstrap/issues/5810
        $.fn.disableScroll = function() {
            window.oldScrollPos = $(window).scrollTop();

            $(window).on('scroll.scrolldisabler', function(event) {
                $(window).scrollTop(window.oldScrollPos);
                event.preventDefault();
            });
        };

        $.fn.enableScroll = function() {
            $(window).off('scroll.scrolldisabler');
        };
        $rootScope.$watch(function() {
            return document.querySelectorAll('.modal').length;
        }, function(val) {
            if (val === 0) {
                $(document.documentElement).enableScroll();
            } else {
                $(document.documentElement).disableScroll();
            }
            document.querySelectorAll('.modal').forEach(function(modal) {
            /*
                    We probalby have to watch scroll height and then set document offset to hidden
                    $rootScope.$watch(function () {
                        return modal.scrollHeight > modal.clientHeight;
                    }, function (newVal) {
                        if (newVal) {
                            // has scroll bar -> disable scroll bar of root
                            $(document.documentElement).css('overflow-y', 'hidden');
                        }
                    });

                     */
                if ($uibModalStack.getTop().value.backdrop !== 'static') { // Testing if the modal is supposed to be static before attaching the event
                    modal.addEventListener('mousedown', function(e) {
                        if (e.which === 1) {
                            $uibModalStack.getTop().key.dismiss();
                        }
                    });
                    modal.querySelector('.modal-content').addEventListener('mousedown', function(e) {
                        e.stopPropagation();
                    });
                }
            });
            if (val > 0) {
                $uibModalStack.getTop().value.backdrop = 'static';
            }
        }
        );

        MetaTagService.setDefaultTags({
            // General SEO
            'title': 'Baukostenschätzung & Baukostenberechnung Schweiz | Für Architekten, Bauherren & Co.',
            'description': 'Die innovative Lösung zur Baukostenschätzung und Baukostenberechnung: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
            'Keywords': 'Baukostenschätzung & Baukostenberechnung',
            'viewport': 'width=device-width, initial-scale=1',
            //google
            'google': 'notranslate',
            // Indexing / Spiders
            // 'googlebot': 'all',
            // 'bingbot': 'all',
            // 'robots': 'all',
            // OpenGraph
            'og:site_name': 'kennwerte',
            'og:image': 'https://www.keevalue.ch/assets/images/background/og/opengraph_bg_1000.jpg',
            'og:image:width': '1000',
            'og:image:height': '1000',
            // Twitter
            'twitter:card': 'summary_large_image',
            'twitter:site': '@kennwerte',
            'twitter:image': 'https://www.keevalue.ch/assets/images/background/og/opengraph_bg_1000.jpg'

        });
    }
})
();
