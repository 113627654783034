(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageTemplateFormController', DamageTemplateFormController);

    DamageTemplateFormController.$inject = ['$scope', '$rootScope', '$stateParams', 'RebuildTemplateService', 'RebuildActionService'];

    function DamageTemplateFormController($scope, $rootScope, $stateParams, RebuildTemplateService, RebuildActionService) {
        var vm = this;
        $scope.dataHasLoaded = false;
        $scope.data = $stateParams.data;

        vm.realEstateContainer = $scope.realEstateContainer;

        $scope.RebuildActionService = RebuildActionService;

        $scope.layoutChangesTypeMarkFromEarthquakeMeasure = {
            'NONE': 'NONE',
            'FEW': 'FEW',
            'SOME': 'SOME',
            'EXTENSIVE': 'SOME',
            'EXCEPTIONAL': 'EXTENSIVE'
        };

        $scope.layoutChangesTypeIntervalFromEarthquakeMeasure = {
            "NONE": {
                "quartile": [0.00, 0.25, 0.55],
                "whisker": [0.00, 0.80]
            },
            "FEW": {
                "quartile": [0.20, 0.25, 0.55],
                "whisker": [0.00, 0.80]
            },
            "SOME": {
                "quartile": [0.45, 0.50, 0.55],
                "whisker": [0.20, 0.80]
            },
            "EXTENSIVE": {
                "quartile": [0.45, 0.50, 0.55],
                "whisker": [0.20, 1.0]
            },
            "EXCEPTIONAL": {
                "quartile": [0.70, 0.75, 0.80],
                "whisker": [0.45, 1.0]
            }
        };

        $scope.structuralInterventionTypeMarkFromLayoutChangesType = {
            "NONE": "NONE",
            "FEW": "NONE",
            "SOME": "FEW",
            "EXTENSIVE": "SOME",
            "EXCEPTIONAL": "EXTENSIVE"
        };

        $scope.structuralInterventionTypeIntervalFromLayoutChangesType = {
            "NONE": {
                "quartile": [0.00, 0.00, 0.05],
                "whisker": [0.00, 0.30]
            },
            "FEW": {
                "quartile": [0.00, 0.00, 0.05],
                "whisker": [0.00, 0.30]
            },
            "SOME": {
                "quartile": [0.20, 0.25, 0.30],
                "whisker": [0.00, 0.55]
            },
            "EXTENSIVE": {
                "quartile": [0.45, 0.50, 0.55],
                "whisker": [0.20, 0.80]
            },
            "EXCEPTIONAL": {
                "quartile": [0.70, 0.75, 0.80],
                "whisker": [0.45, 1.0]
            }
        };

        $scope.dataHasLoaded = true;

    }

})();
