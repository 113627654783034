(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('organisationInviteComponent', {
            bindings: {
                organisationId: '=',
                numAdditionalUsers: '='
            },
            templateUrl: 'app/components/organisation_invite/organisation_invite.html',
            controller:OrganisationInviteComponentController
        });

    OrganisationInviteComponentController.$inject = ["$scope", "$translatePartialLoader", "$translate", "OrganisationInvite", "OrganisationUser"];

    function OrganisationInviteComponentController($scope, $translatePartialLoader, $translate, OrganisationInvite, OrganisationUser) {
        var $ctrl = this;

        this.$onInit = function() {
            $translatePartialLoader.addPart('settings')
                .addPart('register')
                .addPart('prices');
            $translate.refresh();

            $ctrl.users = null;
            $ctrl.invitedUserAlreadyExists = false;
            $ctrl.invites = null;

            loadAll($ctrl.organisationId);

        };

        $ctrl.addUser = function() {
            $ctrl.invitedUserAlreadyExists = false;
            OrganisationInvite.invite({id: $ctrl.organisationId, email: $ctrl.email}, onPutSuccess, onPutError);
        };

        $ctrl.deleteUser = function(user) {
            OrganisationUser.delete({ id: $ctrl.organisationId, email: user.email }, onPutSuccess, onError);
            $ctrl.delete = null;
        };

        $ctrl.remove = function (user) {
            $ctrl.delete = user;
        };

        $ctrl.deleteInvite = function(user) {
            OrganisationInvite.delete_invite({ id: $ctrl.organisationId, email: user.email }, onInviteDelete, onError);
        };

        $ctrl.makeAdmin = function(user){
            OrganisationUser.change_role({id: $ctrl.organisationId},  {'email': user.email,  'organisationRole': 'ORG_ADMIN'}, onPutSuccess, onError);
        };

        $ctrl.makeUser = function(user){
            OrganisationUser.change_role({id: $ctrl.organisationId},  {'email': user.email,  'organisationRole': 'ORG_USER'}, onPutSuccess, onError);
        };

        function onLoadOrgUserSuccess(data) {
            $ctrl.users = data
                .filter(function(u){
                    return !endsWith(u.login, ' orphaned') && !(_.startsWith(u.email, 'org#') && endsWith(u.email, "@org"));
                });
            $ctrl.dataHasLoaded = true;
        }

        function endsWith(str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function onLoadOrgInviteSuccess(response) {
            $ctrl.invites = response.data;
        }

        function onError(error) {
            $ctrl.dataHasLoaded = true;
        }

        function onInviteDelete(){
            loadAll($ctrl.organisationId);
        }

        function onPutSuccess(data, headers) {
            $ctrl.email = "";
            loadAll($ctrl.organisationId);
        }

        function onPutError(error) {
            $ctrl.invitedUserAlreadyExists = true;
            $ctrl.dataHasLoaded = true;
        }

        function loadAll(orgId) {
            if (!!orgId) {
                OrganisationUser.query_users({id: orgId}, onLoadOrgUserSuccess, onError);
                OrganisationInvite.query_all({id: orgId}, onLoadOrgInviteSuccess, onError);
            }
        }

    }


})();
