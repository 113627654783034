(function() {

    'use strict';

    angular.module('kennwerteApp')
        .factory('PdfService', PdfService);

    PdfService.$inject = ['$http', 'FileSaver','Blob'];

    function PdfService($http, FileSaver, Blob) {
        return {
            getLatestPdfIdByRealEstateId: function (realEstateId, responseFunction, errorFunction) {
                return $http.get('api/pdf/latest_id/' + realEstateId)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            downloadPdf: function (realEstateId, pdfName) {
                pdfName = pdfName+'.pdf';
                return $http.get('api/pdf/download?id=' + realEstateId, {responseType: 'blob'})
                    .then(function(response) {
                        var data = new Blob([response.data], { type: 'application/pdf' });
                        FileSaver.saveAs(data, pdfName);
                    },function(errorResponse){
                        console.warn(errorResponse);
                        return errorResponse;
                });
            }
        };
    }
})();
