(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('SmallBuildingAgvGeneralFormController', SmallBuildingAgvGeneralFormController);

    SmallBuildingAgvGeneralFormController.$inject = ['$scope', '$rootScope', '$timeout', '$stateParams', '$window', 'GlobalToastService'];

    function SmallBuildingAgvGeneralFormController($scope, $rootScope, $timeout, $stateParams, $window, GlobalToastService) {
        $scope.data = $stateParams.data;

        $scope.$watch('realEstateContainer.agv.totalArea416', function(value) {
            GlobalToastService.showSmallBuildingFloorAreaWarning($scope.realEstateContainer);

        });
        $scope.agvTotalAreaBlurFn = function() {
            $timeout(function() {
                // we need timeout because model might not be updated yet (in on-broadcast-receiver-function)
                $rootScope.$broadcast('getValuePreset');
            });
        };

    }

})();



