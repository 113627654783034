(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('favoriteTableComponent', {
            bindings: {
                reference: '=?',
                realEstateContainer: '=?',
                selectables: '=',
                translateBaseKey: '@',
                doInitAsNoFavorite: '=?'
            },
            templateUrl: 'app/components/favorite/favorite-table.tpl.html',
            controller: FavoriteTableController
        });

    FavoriteTableController.$inject = ["$scope", "$rootScope", "$element", "$translatePartialLoader", "$translate", "RealestateService"];

    function FavoriteTableController($scope, $rootScope, $element, $translatePartialLoader, $translate, RealestateService) {
        var $ctrl = this;

        $ctrl.onSelect = function (selectable) {
            if ($ctrl.reference !== selectable) {
                $ctrl.reference = selectable;
                RealestateService.api.setFavorite(
                    {},
                    {
                        realEstateId: $ctrl.realEstateContainer.id,
                        favorite: $ctrl.reference
                    })
                    .$promise.then(
                    function (res) {
                    },
                    function (error) {
                        console.warn(error)
                    });
            }

        };

        this.$onInit = function () {
            if (angular.isUndefined($ctrl.reference) || !!$ctrl.doInitAsNoFavorite) {
                $ctrl.reference = 'NO_FAVORITE';
            }
        };

    }

})();
