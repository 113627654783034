(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .service("ExistingWptService", ExistingWptService);

    ExistingWptService.$inject = [];

    function ExistingWptService() {
        var service = {
            existingWpt: [],
            allWpts: [],
            getExistingWpt: getExistingWpt, // Note, here wpt = packages
            getAllExistingWpts: getAllExistingWpts // Note, here wpt = products
        };
        /**
         * Those are Public Wpts
         */
        //FIXME LCC-9 once LCC is live
        // service.existingWpt.push('LCC_PACKAGE');
        service.existingWpt.push('BUILDING_COSTS_AND_DATES');
        service.existingWpt.push('OPERATING_COSTS');
        service.existingWpt.push('REBUILD_COSTS');
        /**
         * Those are all wpts including private.
         */
        service.allWpts.push('LCC');
        service.allWpts.push('BUILDING_COSTS_AND_DATES');
        service.allWpts.push('BUILDING_INSURANCE_VALUES');
        service.allWpts.push('OPERATING_COSTS');
        service.allWpts.push('REBUILD_COSTS');
        service.allWpts.push('DAMAGE_COSTS');

        function getExistingWpt(){
            return service.existingWpt;
        }

        function getAllExistingWpts() {
            return angular.copy(service.allWpts);
        }

        return service;
    }

})();



