(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('ReferenceDocumentsFormController', ReferenceDocumentsFormController);

    ReferenceDocumentsFormController.$inject = ['$scope', '$stateParams'];

    function ReferenceDocumentsFormController($scope, $stateParams) {
        $scope.data = $stateParams.data;
        //Set dynamicTranslations for additionsForm.
        $scope.noDynamicTranslations = false;
    }

})();
