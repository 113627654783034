(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('HowtoSectionController', HowtoSectionController);

    HowtoSectionController.$inject = ['$scope', '$stateParams', '$state'];

    function HowtoSectionController($scope, $stateParams, $state) {
        $scope.data = $stateParams.data;

        $scope.isCurrentStateInput = function() {
            return $state.includes('process-form.input');
        };
    }


})();

