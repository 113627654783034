(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('KwImageModelService', KwImageModelService);

    KwImageModelService.$inject = ['$http', '$q', 'Upload', '$timeout'];

    function KwImageModelService($http, $q, Upload, $timeout) {
        var service = {
            evalImage: evalImage,
            isEvalImageOnProgress: isEvalImageOnProgress,
            isSpecialUsage: isSpecialUsage,
            setStdAllSpecialUsages: setStdAllSpecialUsages
        };

        var maxImagePixels = 4096 * 2160;

        var listFilesOnProgress = [];

        function removeFile(file) {
            var fileKey = createKey(file);
            for(var i = listFilesOnProgress.length - 1; i >= 0; i--) {
                if(angular.equals(listFilesOnProgress[i], fileKey)) {
                    listFilesOnProgress.splice(i, 1);
                }
            }
        }

        function addFile(file) {
            var fileKey = createKey(file);
            var isPresent = false;
            angular.forEach(listFilesOnProgress, function(f) {
                if (angular.equals(fileKey, f)) {
                    isPresent = true;
                }
            });
            if (!isPresent) {
                listFilesOnProgress.push(fileKey);
            }
        }

        function createKey(file) {
            return file.name + '_' + file.$ngfWidth + '_' + file.$ngfHeight + '_' + file.lastModified;
        }

        function evalImage(file, onSuccessFn) {
            if (!file.$error) {
                var data = {
                    file: file
                };

                addFile(file);

                var uploadFn = undefined;

                if (data.file.$ngfWidth * file.$ngfHeight > maxImagePixels) {
                    uploadFn = Upload.resize(file, resizeOptions(file))
                        .then(function(resizedFile) {
                            data.file = resizedFile;
                            return Upload.upload({
                                url: '/api/kw_image_model',
                                data: data,
                                headers: {'Content-Type': 'multipart/form-data'}
                            });
                        });
                } else {
                    uploadFn = Upload.upload({
                        url: '/api/kw_image_model',
                        data: data,
                        headers: {'Content-Type': 'multipart/form-data'}
                    });
                }

                uploadFn.then(function(response) {
                    removeFile(file);
                    onSuccessFn(response);
                }, function(errorResponse) {
                    removeFile(file);
                });
            }
        }

        function isEvalImageOnProgress() {
            return listFilesOnProgress.length > 0;
        }

        function isSpecialUsage(realEstateContainer) {
            for (var i = 0; i < realEstateContainer.usages.length; i++) {
                if (!!realEstateContainer.usages[i].type) {
                    if (startsWith(realEstateContainer.usages[i].type, "WOHNBAUTEN__MFH")
                        || startsWith(realEstateContainer.usages[i].type, "WOHNBAUTEN__EFH")
                        || startsWith(realEstateContainer.usages[i].type, "WOHNBAUTEN__TERRASSENHAEUSER")) {
                        return true;
                    }
                }
            }
            return false;
        }

        function setStdAllSpecialUsages(realEstateContainer, std) {
            if (std < 1) {
                return;
            }
            var low = 2.2;
            var medium = 3.8;
            for (var i = 0; i < realEstateContainer.usages.length; i++) {
                if (!!realEstateContainer.usages[i].type) {
                    if (startsWith(realEstateContainer.usages[i].type, "WOHNBAUTEN__MFH")) {
                        if (std < low) {
                            realEstateContainer.usages[i].standard = "LOW";
                        } else if (std >= low && std < medium) {
                            realEstateContainer.usages[i].standard = "MEDIUM";
                        } else {
                            realEstateContainer.usages[i].standard = "HIGH";
                        }
                    } else if (startsWith(realEstateContainer.usages[i].type,"WOHNBAUTEN__EFH")) {
                        if (std < low) {
                            realEstateContainer.usages[i].standard = "LOW";
                        } else if (std >= low && std < medium) {
                            realEstateContainer.usages[i].standard = "MEDIUM";
                        } else {
                            realEstateContainer.usages[i].standard = "HIGH";
                        }
                    } else if (startsWith(realEstateContainer.usages[i].type, "WOHNBAUTEN__TERRASSENHAEUSER")) {
                        if (std < low) {
                            realEstateContainer.usages[i].standard = "LOW";
                        } else if (std >= low && std < medium) {
                            realEstateContainer.usages[i].standard = "MEDIUM";
                        } else {
                            realEstateContainer.usages[i].standard = "HIGHEST";
                        }
                    }
                }
            }
        }

        function startsWith(str, prefix) {
            return str.substr(0, prefix.length) === prefix;
        }

        return service;

    }


})();
