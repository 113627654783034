(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .factory('SmallBuildingDataService', SmallBuildingDataService);

    SmallBuildingDataService.$inject = ['$q', '$rootScope', '$sessionStorage', 'SmallBuildingService'];

    function SmallBuildingDataService($q, $rootScope, $sessionStorage, SmallBuildingService) {

        var service = {
            smallBuilding: {},
            new: createEmptySmallBuilding,
            get: getSmallBuilding,
            save: saveSmallBuilding,
            load: loadSmallBuilding,
            saveToSession: saveSmallBuildingToSession,
            clearSession: clearSmallBuildingFromSessionStorage,
            loadFromSession: loadSmallBuildingFromSession,
        };

        function getSmallBuilding() {
            return service.smallBuilding;
        }

        function createEmptySmallBuilding() {
            return service.smallBuilding;
        }

        //TODO Implement proper call. (Error Handling as well.)
        function saveSmallBuilding() {
            checkAdditions(service.smallBuilding.metaData.finalized.additions);
            if (service.smallBuilding.id) {
                SmallBuildingService.update({id: service.smallBuilding.id}, service.smallBuilding,
                    function (updatedRealEstateReference) {
                        angular.copy(updatedRealEstateReference, service.smallBuilding);
                        $rootScope.$broadcast('uploadPictures');
                    });
            } else {
                SmallBuildingService.create({}, service.smallBuilding, function (createdRealEstateReference) {
                    angular.copy(createdRealEstateReference, service.smallBuilding);
                    $rootScope.$broadcast('uploadPictures');
                }, function (error) {
                    console.log(error);
                });
            }
        }

        function saveSmallBuildingToSession() {
            $sessionStorage.smallBuilding = service.smallBuilding;
        }

        function loadSmallBuildingFromSession() {
            angular.copy($sessionStorage.smallBuilding, service.smallBuilding);

        }

        function clearSmallBuildingFromSessionStorage() {
            delete $sessionStorage.referenceObject;
        }

        function loadSmallBuilding(id, withdrawalProductType) {
            if (id) {
                var deferred = $q.defer();
                SmallBuildingService.get({id: id}, function (loadedRealEstateReference) {
                    angular.copy(loadedRealEstateReference, service.smallBuilding);
                    deferred.resolve(service.smallBuilding);
                }, function (data) {
                    deferred.reject('Rejected: ' + data.status + ' RealEstate Reference with ID:' + id);
                });
                return deferred.promise;
            } else {
                return createEmptySmallBuilding(withdrawalProductType);
            }
        }

        function createEmptySmallBuilding(wdpt) {
            var deferred = $q.defer();
            SmallBuildingService.getEmpty({}, function (createdRealEstateReference) {
                angular.copy(createdRealEstateReference, service.smallBuilding);
                deferred.resolve(service.smallBuilding);
            }, function (data, status, headers, config) {
                deferred.reject('Rejected :', +status);
            });
            return deferred.promise;
        }

        function checkAdditions(additions) {
            angular.forEach(additions, function (addition, index) {
                if (addition.bkp == null && addition.value == null && addition.label == null) {
                    additions.splice(index, 1);
                }
            });
        }


        return service;
    }


})();
