(function () {
    'use strict';

    angular.module('kennwerteApp')
    .directive('addReferenceUsageTypeButton', AddReferenceUsageTypeButtonDirective)
    .directive('addReferenceUsageType', AddReferenceUsageTypeDirective)
    .directive('removeReferenceUsageTypeButton', RemoveReferenceUsageTypeButtonDirective)
    .directive('removeReferenceUsageType', RemoveReferenceUsageTypeDirective);

    AddReferenceUsageTypeButtonDirective.$inject = [];

    function AddReferenceUsageTypeButtonDirective() {
        return {
            restrict: "E",
            scope: false,
            template: "<button type='button' id='addUsageTypeButton' class='btn btn-elegant-black' add-reference-usage-type ng-disabled='isLastReferenceUsageTypeEmpty() || countReferenceUsages() > 4' translate='process-form.addUsageType'>Click to add Teil-Nutzung</button>"
        };
    }

    AddReferenceUsageTypeDirective.$inject = ['$rootScope', '$timeout','RealEstateReferenceDataService'];

    function AddReferenceUsageTypeDirective($rootScope, $timeout, RealEstateReferenceDataService) {
        return {
            scope: false,
            link: function (scope, element, attrs) {
                element.bind("click", function () {
                    scope.realEstateContainer.usages.push({
                        type: null,
                        percentage: null,
                        standard: null
                    });
                    $rootScope.$broadcast("changeUsages", {});

                    scope.countReferenceUsages = function () {
                        if (scope.realEstateContainer === undefined) return 0;
                        return scope.realEstateContainer.usages.length;
                    };

                    scope.isLastReferenceUsageTypeEmpty = function () {
                        if (scope.realEstateContainer === undefined) return true;
                        var obj = scope.realEstateContainer.usages[scope.realEstateContainer.usages.length - 1].type;
                        return (obj === null || obj === '' || typeof obj === 'undefined');
                    };

                    $rootScope.$broadcast("updateDescription", {
                        rowid: 1020,
                        descriptionNumber: 1,
                        descriptionBaseKey: 'realEstate.usages.teilNutzung'
                    });
                    // update calculation row
                    $timeout(function () {
                        scope.$apply();
                        $rootScope.$broadcast("windowSizeChange", {
                            width: $(document).width()
                        });
                    }, 100);
                });
            }
        };
    }

    function RemoveReferenceUsageTypeButtonDirective() {
        return {
            restrict: "E",
            scope: false,
            template: "<button type='button' class='btn btn-elegant-black' remove-usage-type ng-disabled='realEstateContainer.usages.length < 2' translate='process-form.removeUsageType'>Click to remove last Teil-Nutzung</button>"
        };
    }

    RemoveReferenceUsageTypeDirective.$inject = ['$rootScope', '$timeout','RealEstateReferenceDataService'];

    function RemoveReferenceUsageTypeDirective($rootScope, $timeout, RealEstateReferenceDataService) {
        return {
            scope: false,
            link: function (scope, element, attrs) {
                element.bind("click", function () {
                    scope.realEstateContainer.usages.pop();
                    if(scope.realEstateContainer.usages.length === 1)
                        scope.realEstateContainer.usages[0].percentage = null;

                    $rootScope.$broadcast("changeUsages", {});

                    // update calculation row
                    $timeout(function () {
                        scope.$apply();
                        $rootScope.$broadcast("windowSizeChange", {
                            width: $(document).width()
                        });
                    }, 100);

                });
            }
        };
    }

})();



