(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('LuucyModalService', LuucyModalService);

    LuucyModalService.$inject = ['$uibModal'];

    function LuucyModalService($uibModal) {
        var service = {
            open: open
        };

        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };

        return service;

        function open() {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                backdrop: 'static',
                keyboard: false,
                size: "xxlg",
                templateUrl: 'app/components/luucy/luucy-modal.tpl.html',
                controller: 'LuucyModalController',
                controllerAs: 'vm',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('luucy-modal');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );
        }
    }

})();
