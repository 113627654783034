(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactTransportationInput', {
            bindings: {
                reference: '=?',
                realEstate: '=?', // only needed in case isReference is not set
                referencePlaceholder: '=?', // only needed in case isReference is not set
                isReference: '@?', // indicates if this input is used in reference process form
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '=',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                validationMessage2: '@?',
                descriptionBaseKey: '@',
                defaultValue: '<?',
                estimatePlaceholder: '<',
                usages: '<',
                infotext: '@?'
            },
            templateUrl: 'app/components/transportation_input/compact-transportation-input.tpl.html',
            controller: CompactTransportationInputController
        });

    CompactTransportationInputController.$inject = ['referenceUsageTypeGroupFactory', 'WithdrawalService', '$scope', '$rootScope', '$attrs', '$element', '$translate'];

    function CompactTransportationInputController(referenceUsageTypeGroupFactory, WithdrawalService, $scope, $rootScope, $attrs, $element, $translate) {
        var $ctrl = this;

        var placeholderStringPostfix = " (Modellwert)";

        $scope.firstLoad = true;
        $ctrl.hasLoaded = false;
        $ctrl.model = {};
        $ctrl.referencePlaceholderString = '';
        $ctrl.modelInclined = {};
        $ctrl.answers = ["yes", "no"];

        // use polyfills for IE, TODO: Introduce a proper place for polyfills
        Number.isInteger = Number.isInteger || function (value) {
            return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
        };

        var createPlaceholderVertical = function () {
            if ($ctrl.isReference) {
                try {
                    $ctrl.referencePlaceholderString = $translate.instant($ctrl.translateBaseKey + '.vertical.placeholder');
                } catch (e) {
                    console.warn('translate error transportation input', e);
                }
            } else if ($ctrl.estimatePlaceholder) {
                // Modellwert-Schätzung
                $ctrl.referencePlaceholderString = "Modellwert wird berechnet...";
                WithdrawalService.estimateNumElevators($ctrl.realEstate,
                    function (response) {
                        $ctrl.referencePlaceholder = response.data.numElevators;
                        $ctrl.referencePlaceholderString = $ctrl.referencePlaceholder + placeholderStringPostfix;
                    },
                    function (errorResponse) {
                        console.error(errorResponse);
                    });
                //return $translate.instant($ctrl.translateBaseKey+'.vertical.placeholder');
            }
        };

        var removePlaceholderVertical = function () {
            if (!$ctrl.isReference) {
                $ctrl.referencePlaceholderString = "";
                $ctrl.referencePlaceholder = 0;
            }
        };

        $ctrl.onBlur = function () {
            if ($ctrl.estimatePlaceholder && !$ctrl.isReference && $ctrl.model === "yes") {
                // Modellwert-Schätzung
                WithdrawalService.estimateNumElevators(
                    $ctrl.realEstate,
                    function (response) {
                        $ctrl.referencePlaceholder = response.data.numElevators;
                        $ctrl.referencePlaceholderString = $ctrl.referencePlaceholder + placeholderStringPostfix;
                    },
                    function (errorResponse) {
                        console.error(errorResponse);
                    });
            }
        };

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        $ctrl.translateKey = function (answer) {
            return 'global.' + answer;
        };

        $ctrl.onOpenClose = function (isOpen, type) {
            // var descriptionKey = type === 'vertical' ? '.vertical' : '.inclined';
            var descriptionKey;
            var imageKey;
            if (type === 'inclined') {
                descriptionKey = $ctrl.descriptionBaseKey + '.inclined';
                imageKey = 'assets/images/non-rev/quantitaet/transportanlagen/schraeglift.png';
            } else {
                descriptionKey = $ctrl.descriptionBaseKey + '.vertical';
                imageKey = $ctrl.image;
            }
            if (isOpen) {
                $rootScope.$broadcast("updateDescription", {
                    rowid: $ctrl.rowid,
                    descriptionBaseKey: descriptionKey
                });
                $rootScope.$broadcast("updateImage", {
                    rowid: $ctrl.rowid,
                    imageSrc: imageKey
                });
            }
        };

        $scope.$watch('$ctrl.reference.verticalDecision', function(newValue, oldValue) {
            if (newValue === oldValue) return;
            $ctrl.model = newValue;
        });

        $scope.$watch('$ctrl.model', function (selectedItem) {
            if (!$scope.firstLoad && !angular.equals({}, selectedItem)) {
                $rootScope.$broadcast("updateImage", {
                    rowid: $ctrl.rowid,
                    imageSrc: $ctrl.image
                });

                $rootScope.$broadcast("updateDescription", {
                    rowid: $ctrl.rowid,
                    descriptionNumber: selectedItem,
                    descriptionBaseKey: $ctrl.descriptionBaseKey + '.vertical.' + selectedItem
                });
            } else {
                $scope.firstLoad = false;
            }
            if (angular.equals("yes", selectedItem)) {
                createPlaceholderVertical();
                if (!$ctrl.isReference) {
                    $ctrl.reference.verticalDecision = selectedItem;
                }
            } else {
                removePlaceholderVertical();
                if (!$ctrl.isReference && selectedItem === $ctrl.answers[1]) {
                    $ctrl.reference.vertical = undefined;
                    $ctrl.reference.verticalDecision = selectedItem;
                }
            }
        });

        $scope.$watch('$ctrl.modelInclined', function (selectedItem) {
            if (!$scope.firstLoad && !angular.equals({}, selectedItem)) {
                $rootScope.$broadcast("updateImage", {
                    rowid: $ctrl.rowid,
                    imageSrc: 'assets/images/non-rev/quantitaet/transportanlagen/schraeglift.png'
                });

                $rootScope.$broadcast("updateDescription", {
                    rowid: $ctrl.rowid,
                    descriptionNumber: selectedItem,
                    descriptionBaseKey: $ctrl.descriptionBaseKey + '.inclined.' + selectedItem
                });
            } else {
                $scope.firstLoad = false;
            }
        });

        $ctrl.updateDescriptionAndImage = function (selectedItem, model, string) {
            var tempDescriptionKey;
            var imageKey;
            if (angular.isDefined(selectedItem)) {
                if (string === 'inclined') {
                    tempDescriptionKey = $ctrl.descriptionBaseKey + '.inclined.' + selectedItem;
                    imageKey = 'assets/images/non-rev/quantitaet/transportanlagen/schraeglift.png';
                } else {
                    tempDescriptionKey = $ctrl.descriptionBaseKey + '.vertical.' + selectedItem;
                    imageKey = $ctrl.image;
                }
            } else {
                tempDescriptionKey = $ctrl.descriptionBaseKey;
            }
            $rootScope.$broadcast("updateImage", {
                rowid: $ctrl.rowid,
                imageSrc: imageKey
            });

            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionNumber: selectedItem,
                descriptionBaseKey: tempDescriptionKey
            });
        };

        function resetInclinedCount() {
            $ctrl.modelInclined = {};
        }

        $scope.$watch('$ctrl.reference', function (newValue,oldValue) {
            if (newValue) {
                if (newValue.vertical && $ctrl.model !== 'yes') {
                    $ctrl.model = 'yes';
                }
                if (newValue.inclined) {
                    $ctrl.modelInclined = 'yes';
                }
            }
        });

        // This could also be written with a doCheck
        // which isn't really good for performance at it calls each digest.
        // that's why we are using an deep watch.
        $scope.$watch('$ctrl.usages',
            function (newValue, oldValue) {
                if (angular.isDefined(newValue) && newValue.length > 0) {
                    if (newValue[0].parent) {
                        return;
                    }
                    if ($ctrl.isReference) {
                        var tempBool = false;
                        angular.forEach(newValue, function (value, key) {
                            if (value.type !== '' && value.type !== null && angular.isDefined(value.type)) {
                                var id = referenceUsageTypeGroupFactory.getByTitle(value.type).id;
                                if (angular.isDefined(id)) {
                                    var parentId = id.slice(0, -2);
                                    if (parentId == '0102') {
                                        tempBool = true;
                                    }
                                }
                            }
                        });
                        $ctrl.askForInclincedLifts = tempBool;
                        tempBool ? angular.noop() : resetInclinedCount();
                    }
                }
            }, true);

        function setModelValue(value) {
            console.info("TPA", value);
            if (value == null) return;
            if (value === 0) {
                $ctrl.model = 'no';
            } else {
                $ctrl.model = 'yes';
                $ctrl.reference.vertical = value;
            }
        }

        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            if (angular.isDefined($ctrl.reference)) {
                if (_.isFinite($ctrl.reference.vertical)) {
                    if ($ctrl.reference.vertical > 0) {
                        $ctrl.model = $ctrl.answers[0];
                    } else {
                        $ctrl.model = $ctrl.answers[1];
                    }
                } else {
                    setModelValue($ctrl.defaultValue);
                }
                if (Number.isInteger($ctrl.reference.inclined)) {
                    if ($ctrl.reference.inclined > 0) {
                        $ctrl.modelInclined = $ctrl.answers[0];
                    } else {
                        $ctrl.modelInclined = $ctrl.answers[1];
                    }
                }
            }


            // Used for the validation
            var toggle = $element.find(".verticalLifsDropdown");
            $(toggle).attr("id", $attrs.reference + ".vertical.drop2down-toggle");

            toggle = $element.find(".verificationInput");
            $(toggle).attr("id", $attrs.reference + ".vertical.verificationInput");

            toggle = $element.find(".inclinedLiftsDropdown");
            $(toggle).attr("id", $attrs.reference + ".inclined.drop2down-toggle");
            $ctrl.hasLoaded = true;
        };
    }


})();
