(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('organisation-management', {
                parent: 'admin',
                url: '/organisation-management',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'orgManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/organisation-management/organisation-management.html',
                        controller: 'OrganisationManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'ID;desc',
                        squash: true
                    },
                    totalItems: null,
                    searchName: null,
                    searchPlace: null,
                    filterOrg: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: $stateParams.page ? PaginationUtil.parsePage($stateParams.page) : 1,
                            totalItems: $stateParams.totalItems ? $stateParams.totalItems : null,
                            sort: $stateParams.so,
                            searchName: $stateParams.searchName ? $stateParams.searchName : "",
                            searchPlace: $stateParams.searchPlace ? $stateParams.searchPlace : "",
                            filterOrg: $stateParams.filterOrg ? $stateParams.filterOrg: null,
                            filterLicenseState: $stateParams.filterLicenseState ? $stateParams.filterLicenseState: null,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('process_form')
                            .addPart('realestates')
                            .addPart('settings')
                            .addPart('organisation-management')
                            .addPart('register')
                            .addPart('billing');
                        return $translate.refresh();
                    }]
                }
            })
            .state('organisation-management.newOrg', {
                url: '/neworg',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/organisation-management/organisation-management-dialog.html',
                        controller: 'OrganisationManagementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'xlg',
                        resolve: {
                            entity: function () {
                                return {
                                    organisation: {id: null}
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('organisation-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('organisation-management.editOrg', {
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/organisation-management/organisation-management-dialog.html',
                        controller: 'OrganisationManagementDialogController',
                        controllerAs: 'vm',
                        //backdrop: 'static',
                        size: 'xlg',
                        resolve: {
                            entity: ['Organisation', function (Organisation) {
                                //with $promise we are able to access the data inside the modal.
                                var entity = Organisation.adminGet({id: $stateParams.id}).$promise;
                                return entity;
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('organisation-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
