(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(ngMaterialConfig);

    ngMaterialConfig.$inject = ['$mdThemingProvider'];

    function ngMaterialConfig($mdThemingProvider) {
        var orangePalette = $mdThemingProvider.extendPalette('orange', {
            '50': 'bb7c7a',
            '100': 'bb7c7a',
            '200': 'bb7c7a',
            '300': 'bb7c7a',
            '400': 'bb7c7a',
            '500': 'bb7c7a',
            '600': 'bb7c7a',
            '700': 'bb7c7a',
            '800': 'bb7c7a',
            '900': 'bb7c7a',
            'A100': 'bb7c7a',
            'A200': 'bb7c7a',
            'A400': 'bb7c7a',
            'A700': 'bb7c7a'
        });
        // Register the new color palette map
        $mdThemingProvider.definePalette('custom-orange', orangePalette);

        $mdThemingProvider.theme('default')
            .primaryPalette('custom-orange')
            .accentPalette('custom-orange');
    }


})();
