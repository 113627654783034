(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('ResponsiveStateService', ResponsiveStateService);

    ResponsiveStateService.$inject = ['$window'];

    function ResponsiveStateService($window) {
        var service = {
            // returns the current responsive state, one of ['XS', 'S', 'M', 'L', 'XL']
            getContentRowResponsiveState: getContentRowResponsiveState,
            hasAnyContentRowResponsiveState: hasAnyContentRowResponsiveState
        };
        return service;

        function getContentRowResponsiveState() {
            if ($window.innerWidth <= 599) {
                return "XS";
            } else if ($window.innerWidth <= 1023) {
                return "S";
            } else if ($window.innerWidth <= 1350) {
                return "M";
            } else if ($window.innerWidth <= 1549) {
                return "L";
            } else {
                return "XL";
            }
        }

        function hasAnyContentRowResponsiveState(contentRowStateArr) {
            var rState = getContentRowResponsiveState();
            return contentRowStateArr.indexOf(rState) !== -1;
        }
    }

})();
