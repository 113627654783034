(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RealestatesReferenceController', RealestatesReferenceController);

    RealestatesReferenceController.$inject = ['Principal', '$scope', '$window', '$timeout', '$state', '$sessionStorage', 'RealestateReferenceService',
        'RealestatesReferenceUserAssignmentService', 'User',
        'pagingParams', 'paginationConstants', 'PdfService', 'ParseLinks', 'JhiLanguageService',
        'AlertService', 'PaginationUtil', 'referenceUsageTypeGroupFactory', 'SliderIdentMappingService', '$translate', 'Organisation',
        'imageService', 'RealEstateReferenceCreationDialogService'];


    function RealestatesReferenceController(Principal, $scope, $window, $timeout, $state, $sessionStorage,
        RealestateReferenceService, RealestatesReferenceUserAssignmentService, User,
        pagingParams, paginationConstants, PdfService, ParseLinks, JhiLanguageService,
        AlertService, PaginationUtil, referenceUsageTypeGroupFactory,
        SliderIdentMappingService, $translate, Organisation, ImageService, RealEstateReferenceCreationDialogService) {
        var vm = this;


        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.isAdmin = false;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.loadPage1 = loadPage1;
        vm.onFocusOutSearchName = onFocusOutSearchName;
        vm.realestates = [];
        vm.dataHasLoaded = false;
        vm.translateMainUsage = translateMainUsage;
        vm.createThumbnailPath = createThumbnailPath;
        vm.fetchImageSrc = fetchImageSrc;
        vm.assignToUser = assignToUser;
        vm.page = pagingParams.page;
        vm.usages = ['WOHNBAUTEN__TITLE', 'WOHNBAUTEN__MFH', 'WOHNBAUTEN__TERRASSENHAEUSER'];
        vm.filterUsage = pagingParams.filterUsage;
        vm.searchName = pagingParams.searchName;
        vm.totalItems = pagingParams.totalItems;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.editRowModal = RealEstateReferenceCreationDialogService.open;
        vm.healAttachmentsOfRealEstate = healAttachmentsOfRealEstate;
        vm.disableHealing = false;
        vm.setPublicRefObject = setPublicRefObject;
        vm.setPrivateRefObject = setPrivateRefObject;
        vm.showPublicRefObjects;
        vm.showPrivateRefObjects;

        vm.rowStyle = rowStyle;
        vm.isNotVerified = isNotVerified;
        vm.greyScaleImageIfNotVerified = greyScaleImageIfNotVerified;

        vm.previousFilterUsage = null;
        vm.previousSearchName = '';
        vm.previousIsSearchRequest = null;
        vm.previousPage = 0;
        vm.previousPredicate = null;
        vm.previousReverse = null;

        vm.withdrawalProductType = $state.includes('building-costs') ? 'BUILDING_COSTS_AND_DATES' : 'BUILDING_INSURANCE_VALUES';

        vm.Accordion = false;

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
            vm.showPublicRefObjects = vm.currentAccount.showSysRefObject;
            vm.showPrivateRefObjects = vm.currentAccount.showPrivRefObject;

            Organisation.current().$promise.then(function(org) {
                vm.currentAccount.organisationName = org.name;
            });
            Principal.hasAuthority('ROLE_ADMIN')
                .then(function(result) {
                    console.warn(result);
                    vm.isAdmin = result;
                    if (vm.isAdmin) {
                        vm.assignment = {};
                        vm.assignment.userLogin = '';
                        vm.assignment.realEstateId = {};
                    }
                });
            vm.loadAll();
        });

        function setPublicRefObject() {
            User.set_public_ref_settings({ id: vm.currentAccount.id }, vm.showPublicRefObjects);
        }

        function setPrivateRefObject() {
            User.set_private_ref_settings({ id: vm.currentAccount.id }, vm.showPrivateRefObjects);
        }


        vm.LoadThumbnails = function LoadThumbnails(id) {
            if (vm.Accordion === id) {
                vm.Accordion = null;
            } else {
                vm.Accordion = id;
            }
        };

        function isInStateBuildingCosts() {
            return $state.includes('building-costs');
        }

        vm.isInStateBuildingCosts = isInStateBuildingCosts();

        function isInStateInsuranceValues() {
            return $state.includes('insurance-values');
        }

        vm.isInStateInsuranceValues = isInStateInsuranceValues();

        // user search
        vm.userSearch = {};
        vm.userSearch.searchText = '';
        vm.userSearch.userIds = [];
        vm.userSearch.querySearch = function(query) {
            if (query) {
                var q = {
                    'searchString': query
                };
                return User.search_actives_by_login(q).$promise.then(
                    function(res) {
                        return res;
                    });
            } else {
                return User.query_actives().$promise.then(
                    function(res) {
                        return res;
                    });
            }
        };
        vm.userSearch.selectedItemChange = function(text) {
            vm.userSearch.searchText = text;
            var p = {
                'userLogin': text
            };
            vm.assignment.userLogin = text;
        };

        // selection
        vm.selected = {};
        vm.numSelectedObjects = undefined;
        vm.medianOrdinalLot = undefined;
        vm.medianOrdinalPreparatoryWork = undefined;
        vm.medianOrdinalPrefabrication = undefined;
        vm.medianOrdinalBuildingForm = undefined;
        vm.medianOrdinalWindows = undefined;
        vm.medianOrdinalElectricalSystem = undefined;
        vm.medianOrdinalHeatingSystem = undefined;
        vm.medianOrdinalSanitary = undefined;
        vm.medianOrdinalFitout1 = undefined;
        vm.medianOrdinalFitout2 = undefined;
        vm.medianOrdinalEnvironment = undefined;
        vm.deleteSelection = deleteSelection;

        var calcMedianOrdinalLot = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetGrundstueck;
            });
        var calcMedianOrdinalPreparatoryWork = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetvorbereitungsarbeiten;
            });
        var calcMedianOrdinalPrefabrication = calcOrdinalMedianByLessWithExtractor(
            function(r) {
                return r.quality.qualitaetVorfertigung;
            });
        var calcMedianOrdinalBuildingForm = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetDerGebaeudeform2;
            });
        var calcMedianOrdinalWindows = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.fensterAnteil;
            });
        var calcMedianOrdinalElectricalSystem = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetElektroAnlage;
            });
        var calcMedianOrdinalHeatingSystem = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetHeizungsanlage;
            });
        var calcMedianOrdinalSanitary = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetSanitaeranlage;
            });
        var calcMedianOrdinalFitout1 = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetAusbau1;
            });
        var calcMedianOrdinalFitout2 = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetAusbau2;
            });
        var calcMedianOrdinalEnvironment = calcOrdinalMedianWithExtractor(
            function(r) {
                return r.quality.qualitaetUmgebung;
            });

        vm.gotoStateProcessFormInputWithAggregatedRealEstate = gotoStateProcessFormInputWithAggregatedRealEstate;
        vm.gotoStateRealestatesDelete = gotoStateRealestatesDelete;
        vm.gotoStateProcessFormInput = gotoStateProcessFormInput;

        vm.selectedRealestates = [];
        vm.aggregatedRealEstate = undefined;

        JhiLanguageService.getAll().then(function(languages) {
            vm.languages = languages;
        });

        function assignToUser() {
            if (angular.equals('', vm.assignment.user)) {
                console.log('User may not be empty');
                return;
            }
            if (angular.equals({}, vm.assignment.realEstateId)) {
                console.log('Real Estate may not be empty');
                return;
            }
            var data = {
                'userLogin': vm.assignment.userLogin,
                'realEstateId': vm.assignment.realEstateId
            };
            RealestatesReferenceUserAssignmentService.assign(
                data,
                function(response) {
                    console.log('Successfully assigned real estate to user');
                    loadAll();
                }, function(errorResponse) {
                    console.error('Failed to assign real estate to user. ErrorResponse:' + errorResponse);
                });
        }

        function loadPage1() {
            vm.page = 1;
            loadAll();
        }

        function loadAll() {
            return loadAllInner(false);
        }

        function createThumbnailPath(realEstate) {
            if (realEstate.metaData.mainImageId) {
                return 'thumbnail/' + realEstate.metaData.userLogin + '/' + realEstate.metaData.mainImageId + '.jpg';
            } else {
                return 'assets/images/noImg.png';
            }
        }

        /**
         * Create the sort statement which is expected to be passed as state param to states inheriting from state 'realestates'.
         */
        function createSortStmt() {
            return vm.predicate + ';' + (vm.reverse ? 'asc' : 'desc');
        }

        function loadAllInner(isSearchRequest) {
            if (!angular.equals(vm.previousFilterUsage, vm.filterUsage)
                || !angular.equals(vm.previousSearchName, vm.searchName)
                || !angular.equals(vm.previousPredicate, vm.predicate)
                || !angular.equals(vm.previousReverse, vm.reverse)
                || !angular.equals(vm.previousPage, vm.page)
                || !angular.equals(vm.previousIsSearchRequest, isSearchRequest)) {
                vm.dataHasLoaded = false;
                vm.previousSearchName = vm.searchName;
                vm.previousFilterUsage = vm.filterUsage;
                vm.previousPredicate = vm.predicate;
                vm.previousPage = vm.page;
                vm.previousIsSearchRequest = isSearchRequest;
                if (vm.predicate) {
                    var tempSort = createSortStmt();
                    vm.predicate = PaginationUtil.parsePredicate(tempSort);
                    vm.reverse = PaginationUtil.parseAscending(tempSort);
                }
                RealestateReferenceService.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    searchName: vm.searchName,
                    filterUsage: vm.filterUsage,
                    isSearchRequest: isSearchRequest,
                    showDataSameOrg: vm.showPrivateRefObjects,
                    showPublicObject: vm.showPublicRefObjects,
                    withdrawalProductType: vm.withdrawalProductType
                }, onSuccess, onError);
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            var tempRealestates = data;
            vm.realestates = [];
            tempRealestates.forEach(function(realestate) {
                realestate.translated = vm.translateMainUsage(realestate);
                vm.realestates.push(realestate);
            });
            vm.dataHasLoaded = true;
        }

        function onError(error) {
            console.log(error);
            vm.dataHasLoaded = true;
            AlertService.error(error.data.message);
        }

        function sort() {
            if (vm.predicate) {
                return [createSortStmt()];
            } else {
                return [];
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function onFocusOutSearchName() {
            if (!angular.equals(vm.previousSearchName, vm.searchName)) {
                vm.page = 1;
                loadAllInner(true);
            }
        }

        function translateMainUsage(realEstate) {
            var translateFirst;
            var mainUsageTitle = 'NONE';
            try {

                if (realEstate.usages.length > 0) {
                    mainUsageTitle = realEstate.usages[0].type;
                    var previousUsageTitle = referenceUsageTypeGroupFactory.getTitleOfPrevious(mainUsageTitle);
                    if (previousUsageTitle !== undefined) {
                        translateFirst = $translate.instant('realEstate.usages.' + previousUsageTitle) + ' - ';
                    } else {
                        translateFirst = '';
                    }
                    var translateSecond = $translate.instant('realEstate.usages.' + mainUsageTitle);
                    return translateFirst + translateSecond;
                } else {
                    return $translate.instant('realEstate.usages.' + mainUsageTitle);
                }
            } catch (e) {
                console.warn('translation error realEstates-ref', e);
            }
        }

        function fetchImageSrc(realEstate) {
            return 'data:image/' + realEstate.imageType + ';base64,{{' + realEstate.imageSrc + '}}';
        }

        function deleteSelection() {
            vm.selected = {};
            vm.selectedRealestates = [];
        }

        function countNumSelectedObjects() {
            return Object.keys(vm.selected).length;
        }

        function listExtractor(realestates, valueExtractor) {
            var length = realestates.length;
            var ret = [];
            for (var i = 0; i < length; i++) {
                var r = realestates[i];
                ret.push(valueExtractor(r));
            }
            return ret;
        }

        function calcMedian(values) {
            values.sort(function(a, b) {
                return a - b;
            });

            if (values.length === 0) return 0;

            var half = Math.floor(values.length / 2);

            if (values.length % 2)
                return values[half];
            else
                return (values[half - 1] + values[half]) / 2.0;
        }

        function calcOrdinalMedian(sliderIdentArr) {
            var values = [];
            for (var i = 0; i < sliderIdentArr.length; i++) {
                values.push(SliderIdentMappingService.getSliderValueIndexByMore(sliderIdentArr[i]));
            }
            return SliderIdentMappingService.getSliderIdentByMore(calcMedian(values));
        }

        function calcOrdinalMedianByLess(sliderIdentArr) {
            var values = [];
            for (var i = 0; i < sliderIdentArr.length; i++) {
                values.push(SliderIdentMappingService.getSliderValueIndexByLess(sliderIdentArr[i]));
            }
            return SliderIdentMappingService.getSliderIdentByLess(calcMedian(values));
        }

        function calcOrdinalMedianByLessWithExtractor(extractor) {
            return function() {
                var arr = listExtractor(vm.selectedRealestates, extractor);
                return calcOrdinalMedianByLess(arr);
            };
        }

        function calcOrdinalMedianWithExtractor(extractor) {
            return function() {
                var arr = listExtractor(vm.selectedRealestates, extractor);
                return calcOrdinalMedian(arr);
            };
        }

        function arrayObjectIndexOf(array, searchTerm, property) {
            for (var i = 0, len = array.length; i < len; i++) {
                if (array[i][property] === searchTerm) return i;
            }
            return -1;
        }

        function gotoStateProcessFormInputWithAggregatedRealEstate() {
            switch (vm.withdrawalProductType) {
            case 'BUILDING_COSTS_AND_DATES':
                $state.go('process-form.input', {
                    realEstate: vm.aggregatedRealEstate,
                    withdrawalProductType: vm.withdrawalProductType.toLowerCase()
                });
                break;
            case 'BUILDING_INSURANCE_VALUES':
                $state.go('insurance.input', {
                    realEstate: vm.aggregatedRealEstate,
                    withdrawalProductType: vm.withdrawalProductType.toLowerCase()
                });
                break;
            }
        }

        function gotoStateProcessFormInput(realEstate) {
            var q = realEstate.quality;
            var cp = {};
            cp.quality = {
                qualitaetGrundstueck: q.qualitaetGrundstueck,
                qualitaetvorbereitungsarbeiten: q.qualitaetvorbereitungsarbeiten,
                qualitaetVorfertigung: q.qualitaetVorfertigung,
                qualitaetDerGebaeudeform2: q.qualitaetDerGebaeudeform2,
                fensterAnteil: q.fensterAnteil,
                qualitaetElektroAnlage: q.qualitaetElektroAnlage,
                qualitaetHeizungsanlage: q.qualitaetHeizungsanlage,
                qualitaetSanitaeranlage: q.qualitaetSanitaeranlage,
                qualitaetAusbau1: q.qualitaetAusbau1,
                qualitaetAusbau2: q.qualitaetAusbau2,
                qualitaetUmgebung: q.qualitaetUmgebung
            };
            delete $sessionStorage.realEstateContainer;
            delete $sessionStorage.output;
            switch (vm.withdrawalProductType) {
            case 'BUILDING_COSTS_AND_DATES':
                $state.go('process-form.input', {
                    realEstate: cp,
                    withdrawalProductType: vm.withdrawalProductType.toLowerCase()
                });
                break;
            case 'BUILDING_INSURANCE_VALUES':
                $state.go('insurance.input', {
                    realEstate: cp,
                    withdrawalProductType: vm.withdrawalProductType.toLowerCase()
                });
                break;
            }

        }

        function gotoStateRealestatesDelete(realEstateId) {
            var stateParamsOfCurrentSearch = {
                page: vm.page,
                totalItems: Number(vm.totalItems),
                sort: createSortStmt(),
                filterUsage: vm.filterUsage,
                searchName: vm.searchName
            };
            var itemsOnPage = vm.realestates.length;
            $state.go(
                $state.params.deleteState,
                {
                    realEstateId: realEstateId,
                    itemsOnPage: itemsOnPage,
                    previousStateName: $state.current.name,
                    previousStateParams: stateParamsOfCurrentSearch
                });
        }

        function calcAggregations(selected) {
            if (selected && !angular.equals({}, selected)) {
                var lookup = {};
                for (var i = 0, len = vm.realestates.length; i < len; i++) {
                    lookup[vm.realestates[i].id] = vm.realestates[i];
                }
                // introduce a temporary list that holds all selected real estates on this page
                var selectedRealestatesOnThisPage = [];
                for (var key in selected) {
                    // it may be unselected
                    if (selected[key]) {
                        var realestate = lookup[key];
                        if (realestate) {
                            selectedRealestatesOnThisPage.push(realestate);
                        }
                    } else {
                        realestate = lookup[key];
                        if (realestate) {
                            // remove this realestate from the list holding all selected realestates
                            var index = arrayObjectIndexOf(vm.selectedRealestates, realestate.id, 'id');
                            if (index > -1) {
                                vm.selectedRealestates.splice(index, 1);
                            }
                        }
                    }
                }
                // now we have all selected realestates on this page stored in the variable selectedRealestatesOnThisPage
                for (var i = 0, len = selectedRealestatesOnThisPage.length; i < len; i++) {
                    var realestateOnThisPage = selectedRealestatesOnThisPage[i];
                    // add this realestate to the list of all selected real estates if not yet present
                    var index = arrayObjectIndexOf(vm.selectedRealestates, realestateOnThisPage.id, 'id');
                    if (index === -1) {
                        vm.selectedRealestates.push(realestateOnThisPage);
                    }
                }
            }
            if (vm.selectedRealestates.length === 0) {
                vm.numSelectedObjects = undefined;
                vm.medianOrdinalLot = undefined;
                vm.medianOrdinalPreparatoryWork = undefined;
                vm.medianOrdinalPrefabrication = undefined;
                vm.medianOrdinalBuildingForm = undefined;
                vm.medianOrdinalWindows = undefined;
                vm.medianOrdinalElectricalSystem = undefined;
                vm.medianOrdinalHeatingSystem = undefined;
                vm.medianOrdinalSanitary = undefined;
                vm.medianOrdinalFitout1 = undefined;
                vm.medianOrdinalFitout2 = undefined;
                vm.medianOrdinalEnvironment = undefined;
                vm.aggregatedRealEstate = undefined;
            } else {
                vm.numSelectedObjects = vm.selectedRealestates.length;
                vm.medianOrdinalLot = calcMedianOrdinalLot();
                vm.medianOrdinalPreparatoryWork = calcMedianOrdinalPreparatoryWork();
                vm.medianOrdinalPrefabrication = calcMedianOrdinalPrefabrication();
                vm.medianOrdinalBuildingForm = calcMedianOrdinalBuildingForm();
                vm.medianOrdinalWindows = calcMedianOrdinalWindows();
                vm.medianOrdinalElectricalSystem = calcMedianOrdinalElectricalSystem();
                vm.medianOrdinalHeatingSystem = calcMedianOrdinalHeatingSystem();
                vm.medianOrdinalSanitary = calcMedianOrdinalSanitary();
                vm.medianOrdinalFitout1 = calcMedianOrdinalFitout1();
                vm.medianOrdinalFitout2 = calcMedianOrdinalFitout2();
                vm.medianOrdinalEnvironment = calcMedianOrdinalEnvironment();
                vm.aggregatedRealEstate = {
                    quality: {
                        qualitaetGrundstueck: vm.medianOrdinalLot,
                        qualitaetvorbereitungsarbeiten: vm.medianOrdinalPreparatoryWork,
                        qualitaetVorfertigung: vm.medianOrdinalPrefabrication,
                        qualitaetDerGebaeudeform2: vm.medianOrdinalBuildingForm,
                        fensterAnteil: vm.medianOrdinalWindows,
                        qualitaetElektroAnlage: vm.medianOrdinalElectricalSystem,
                        qualitaetHeizungsanlage: vm.medianOrdinalHeatingSystem,
                        qualitaetSanitaeranlage: vm.medianOrdinalSanitary,
                        qualitaetAusbau1: vm.medianOrdinalFitout1,
                        qualitaetAusbau2: vm.medianOrdinalFitout2,
                        qualitaetUmgebung: vm.medianOrdinalEnvironment
                    }
                };
            }
        }

        function rowStyle(item, idx) {
            if (item.metaData.finalized.verificationStatus == 'NOT_VERIFIED' || item.metaData.finalized.verificationStatus == null) {
                if (idx % 2 === 0) {
                    return { 'background-color': 'rgba(229, 240, 249, 0.5)' };
                } else {
                    return { 'background-color': 'rgba(255, 255, 255,0.6)' };
                }
            } else if (idx % 2 === 0) {
                return { 'background-color': '#E5F0F9' };
            } else {
                return { 'background-color': 'white' };
            }
        }

        function isNotVerified(item) {
            if (item.metaData.finalized.verificationStatus == 'NOT_VERIFIED') {
                return 'notVerified';
            } else {
                return '';
            }
        }

        function greyScaleImageIfNotVerified(item) {
            if (item.metaData.finalized.verificationStatus == 'NOT_VERIFIED') {
                return 'greyScale';
            } else {
                return '';
            }
        }

        function healAttachmentsOfRealEstate(id) {
            vm.disableHealing = true;
            ImageService.healAttachmentsOfRealEstate(id, function(res) {
                vm.disableHealing = false;
                console.log(res);
            });
        }

        $scope.$watch('vm.selected', function(selected) {
            calcAggregations(selected);
        }, true);

        $scope.$watch('vm.filterUsage', function(selectedItem) {
            if (selectedItem && !angular.equals(vm.filterUsage, vm.previousFilterUsage)) {
                vm.page = 1;
                loadAllInner(true);
            }
        });
        // invoke methods on start
    }
})();
