(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('generalSliderInput', GeneralSliderInput);

    GeneralSliderInput.$inject = ['$rootScope', '$timeout', '$translate', 'GeneralSliderValuesService'];

    function GeneralSliderInput($rootScope, $timeout, $translate, GeneralSliderValuesService) {

        return {
            restrict: 'E',
            scope: {
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                valueLoader: '=',
                onChange: '=?',
                onChangeMarkMapping: '=?',
                onChangeIntervalMapping: '=?',
                realEstateContainer: '=',
                reference: '=',
                referenceStr: '@',
                translateBaseKey: '@translateBaseKey',
                image: '=',
                staticImage: '=?',
                imageFormat: '=?',
                mandatory: '@?',
                descriptionBaseKey: '@',
                tabindex: '@',
                tabgroup: '@',
                isLast: '@?',
                turnOnSlider: '=?',
                infotext: '@?'
            },
            templateUrl: 'app/components/sliders/general_slider/general-slider_input_partial.html',
            controller: function () {
            },
            link: function (scope, tElement, tAttrs, ctrl) {
                if (scope.valueLoader) {
                    scope.values = GeneralSliderValuesService.getValues(scope.valueLoader);
                } else {
                    scope.values = GeneralSliderValuesService.getValues();
                    console.error('valueLoader not defined ' + scope.translateBaseKey);
                }
                if (scope.onChange !== undefined) {
                    scope.$watch('onChange', function(nVal) {
                        if (nVal === undefined) return;
                        if (scope.onChangeMarkMapping !== undefined) {
                            if (!scope.referenceDirty) {
                                scope.reference = scope.onChangeMarkMapping[nVal];
                            }
                            addMarks(scope.onChangeMarkMapping[nVal]);
                        }
                        if (scope.onChangeIntervalMapping !== undefined && scope.onChangeIntervalMapping[nVal] !== undefined) {
                            var intervalMapping = scope.onChangeIntervalMapping[nVal];
                            addInterval(intervalMapping['quartile'], intervalMapping['whisker'], intervalMapping['outlier']);
                        }
                    });
                }

                scope.referenceDirty = null;
                scope.firstLoad = true;
                scope.sliderPresetMark = null;
                var sliderActive = false;

                if(scope.turnOnSlider == undefined){
                    scope.turnOnSlider = true;
                }
                if(scope.staticImage == undefined){
                    scope.staticImage = false;
                }

                scope.doShowInterval = false;
                scope.boxPlotData = [{}];
                scope.whConfig = undefined;

                var inputField = tElement.find(".normalSlider2Input");
                this.$label = inputField.data("ionRangeSlider");

                scope.referenceDirty = scope.reference != null;
                if (!scope.referenceDirty) {
                    scope.reference = scope.values[0];
                }

                var indexFun = function (value) {
                    return scope.values.indexOf(value);
                };
                if (tAttrs.class === 'subSlider') {
                    var inputElement = tElement.find(".cInputElement");
                    inputElement.addClass(tAttrs.class);
                }

                var pushEvent = function (nominalValue, numericValue) {
                    // firstLoad is to make sure that default image and Description are not overwritten.
                    if (!scope.firstLoad  && sliderActive) {
                        $rootScope.$broadcast("updateImage", {
                            rowid: scope.rowid,
                            imageSrc: !scope.staticImage ? sanitizeUrl(tAttrs.image) + nominalValue + ".png" : scope.image
                        });
                        $rootScope.$broadcast("updateDescription", {
                            rowid: scope.rowid,
                            descriptionNumber: nominalValue,
                            descriptionBaseKey: tAttrs.descriptionBaseKey,
                            sliderRange: scope.values.length,
                            sliderNotPresent: false,
                            dynamicTranslations: true
                        });
                    } else {
                        scope.firstLoad = false;
                    }
                };

                var setSlider = function (newValue) {
                    var valIndex = indexFun(newValue);
                    if (valIndex > -1) {
                        var slider = inputField.data("ionRangeSlider");
                        slider.update({
                            from: valIndex
                        });

                        if (scope.sliderPresetMark != null) {
                            slider = inputField.data("ionRangeSlider");
                            // $slider is a jquery function
                            var $slider = slider.result.slider;
                            $slider.append(scope.sliderPresetMark);
                        }
                    }
                };

                var isCurrentSliderValue = function (value) {
                    var slider = inputField.data("ionRangeSlider");
                    return slider.result.from_value === value;
                };

                scope.$watch('reference', function(newValue, oldValue) {
                    // console.warn(scope.reference,'new '+newValue,'old ' +oldValue,scope.turnOnSlider,isCurrentSliderValue(newValue))
                    if (newValue !== oldValue && !isCurrentSliderValue(newValue) && scope.turnOnSlider) {
                        $timeout(function() {
                            setSlider(newValue);
                        });
                    }
                }, true);


                function convertToPercent(num, min, max) {
                    return (num - min) / (max - min) * 100;
                }

                function toFixed(num) {
                    num = num.toFixed(5);
                    return +num;
                }

                function addMarks(value) {
                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exists
                    var sliderData = inputField.data("ionRangeSlider");
                    if (sliderData) {
                        var min = sliderData.result.min;
                        var max = sliderData.result.max;

                        var real_width = 100 - sliderData.coords.p_handle;
                        var val = toFixed(scope.getIdx(value) / 100 * real_width);
                        var left = convertToPercent(val, min, max);
                        if (left >= 0 && left <= 100) {
                            scope.sliderPresetMark = '<span class="sliderMark" style="left: ' + left + '%"></span>';
                        } else {
                            scope.sliderPresetMark = null;
                        }
                        if (scope.turnOnSlider) {
                            ctrl.update(scope.sliderPresetMark);
                        }
                    }
                }

                function rescale(val, b_min, b_max, a_min, a_max) {
                    // b_min: before min
                    // b_max: before max
                    // a_min: after min
                    // a_max: after max
                    return (a_max - a_min) * (val - b_min) / (b_max - b_min) + a_min;
                }

                function convertArr(arr, p_handle) {
                    return arr.map(function (e) {
                        var min = p_handle/200;
                        var max = 1 - min;
                        return rescale(e/7, 0, 1, min, max);
                    });
                }

                function addInterval(quartileData, whiskerData, outlierData) {
                    var sliderData = inputField.data("ionRangeSlider");
                    if (sliderData) {
                        scope.whConfig = {width: sliderData.coords.w_rs, height: 10, barWidth: 10-2};
                        scope.boxPlotData[0] = {
                            'key': 0,
                            'quartile': quartileData || [],
                            'whiskers': whiskerData || [],
                            'outliers': outlierData || [],
                            'max': 1
                        };
                        if (scope.turnOnSlider) {
                            scope.doShowInterval = true;
                        }
                    }
                }

                scope.getIdx = function (value) {
                    return indexFun(value);
                };

                var label;

                function Label(container) {
                    this.$label = $(container).find(".irs-single");
                    this.active = false;
                    this.ACTIVE = "irs-single--active";
                }

                Label.prototype = {
                    start: function () {
                        if (!this.active) {
                            this.active = true;
                            this.$label.addClass(this.ACTIVE);
                        }
                    },
                    end: function () {
                        this.$label.removeClass(this.ACTIVE);
                        this.active = false;
                    }
                };

                var ionRangeSliderOptions = {
                    type: "single",
                    grid: false,
                    keyboard: true,
                    hide_min_max: true,
                    keyboard_step: 100 / scope.values.length + 1, /* In % ! */
                    // from_value: scope.reference, /*Position of the value*/
                    from: scope.getIdx(scope.reference),
                    onStart: function (data) {
                        label = new Label(data.slider);
                    },
                    onChange: function (data) {
                        label.start();
                        sliderActive = true;
                        $timeout(function () {
                            pushEvent(scope.values[data.from], data.from);
                        });
                    },
                    onFinish: function (data) {
                        // Change the model if the slider changes
                        $timeout(function () {
                            scope.referenceDirty = true;
                            scope.reference = scope.values[data.from];
                        });
                        $timeout(function () {
                            sliderActive = false;
                            label.end();
                        }, 1000);
                    },
                    onUpdate: function (data) {
                        $timeout(function () {
                            pushEvent(scope.values[data.from], data.from);
                        });
                    },
                    values: GeneralSliderValuesService.getTranslations()
                };
                inputField.ionRangeSlider(ionRangeSliderOptions);

                ctrl.update = function (additionalHtml) {
                    var slider = inputField.data("ionRangeSlider");
                    slider.destroy();
                    inputField.ionRangeSlider(ionRangeSliderOptions);
                    if (additionalHtml) {
                        slider = inputField.data('ionRangeSlider');
                        // $slider is a jquery function
                        var $slider = slider.result.slider;
                        $slider.append(additionalHtml);
                    }
                };

                if (scope.isLast) {
                    $rootScope.$broadcast('requestPresetMark');
                }

                var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
                    ionRangeSliderOptions.values = GeneralSliderValuesService.getTranslations();
                    if (scope.sliderPresetMark != null) {
                        ctrl.update(scope.sliderPresetMark);
                    } else {
                        ctrl.update();
                    }
                });
                scope.$on('$destroy', function(val) {
                    translateSuccess();
                    var slider = inputField.data("ionRangeSlider");
                    slider.destroy();
                });
            }
        };
    }

})();
