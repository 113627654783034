(function () {
    "use strict";

    angular
        .module("kennwerteApp")
        .directive("infoBoxButton", InfoBoxButtonDirective)
        .controller("infoBoxButton", InfoBoxButtonController);

    InfoBoxButtonDirective.$inject = [];

    function InfoBoxButtonDirective() {
        return {
            restrict: "E",
            replace: false,
            scope: {
                alternative: "@?",
                isSlider: "=?",
                secondTranslation: "@?", // if second translation is set it will be added below the normal translation
                realEstateContainer: "=?",
                boxPlotData: "=?",
                wkBoxPlotData: "=?",
            },
            transclude: true,
            controllerAs: "ivm",
            controller: InfoBoxButtonController,
            templateUrl: "app/components/info_box/info-box-button.tpl.html",
        };
    }

    InfoBoxButtonController.$inject = [
        "$rootScope",
        "$scope",
        "$uibModal",
        "SharedControllerFnService",
    ];

    function InfoBoxButtonController(
        $rootScope,
        $scope,
        $uibModal,
        SharedControllerFnService
    ) {
        var ivm = this;
        ivm.hideButton = true;
        ivm.alternativeText = angular.isDefined($scope.alternative);
        ivm.directiveBasedScope = angular.isDefined($scope.$parent.infotext);
        ivm.componentBasedScope = false;
        if (ivm.alternativeText) {
            $scope.translationBase = $scope.alternative;
        } else {
            if (angular.isDefined($scope.$parent.$ctrl)) {
                if ($scope.$parent.$ctrl.infotext == "") {
                    $scope.translationBase =
                        $scope.$parent.$ctrl.translateBaseKey;
                    ivm.componentBasedScope = true;
                } else {
                    ivm.componentBasedScope = false;
                }
            }
            if (ivm.directiveBasedScope) {
                $scope.translationBase = $scope.$parent.translateBaseKey;
            }
        }

        if (
            ivm.componentBasedScope ||
            ivm.alternativeText ||
            ivm.directiveBasedScope
        ) {
            ivm.hideButton = false;
        }

        ivm.usageText = function () {
            var usages = angular.copy($scope.realEstateContainer.usages);
            usages = SharedControllerFnService.cleanUsages(usages);
            if (usages.length > 1) {
                return "entity.info-button-chosen-usage.plural";
            } else {
                return "entity.info-button-chosen-usage.single";
            }
        };

        ivm.isSpecialUsage = function () {
            for (var i = 0; i < $scope.realEstateContainer.usages.length; i++) {
                if (
                    !!$scope.realEstateContainer.usages[i].type &&
                    !!$scope.realEstateContainer.usages[i].standard
                ) {
                    if (
                        startsWith(
                            $scope.realEstateContainer.usages[i].type,
                            "WOHNBAUTEN__MFH"
                        )
                    ) {
                        ivm.specialUsage =
                            $scope.realEstateContainer.usages[i].type;
                    } else if (
                        startsWith(
                            $scope.realEstateContainer.usages[i].type,
                            "WOHNBAUTEN__EFH"
                        )
                    ) {
                        ivm.specialUsage =
                            $scope.realEstateContainer.usages[i].type;
                    } else if (
                        startsWith(
                            $scope.realEstateContainer.usages[i].type,
                            "WOHNBAUTEN__TERRASSENHAEUSER"
                        )
                    ) {
                        ivm.specialUsage =
                            $scope.realEstateContainer.usages[i].type;
                    }
                    return true;
                }
            }
            return false;
        };

        function startsWith(str, prefix) {
            return str.substr(0, prefix.length) === prefix;
        }

        ivm.showAdvanced = function () {
            var modalInstance = $uibModal.open({
                controller: ModalController,
                animation: true,
                controllerAs: "ctrl",
                bindToController: true,
                scope: $scope,
                clickOutsideToClose: true,
                windowClass: "info-box-z-index",
                templateUrl: "app/components/info_box/info-box.tpl.html",
            });
        };
        ModalController.$inject = ["$scope", "$uibModalInstance"];

        function ModalController($scope, $uibModalInstance) {
            $scope.cancel = function () {
                $uibModalInstance.close();
            };
        }
    }
})();
