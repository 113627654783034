(function() {
    "use strict";

    angular.module("kennwerteApp")
        .component("differentialPriceTable", {
            bindings: {
                organisation: "<",
                isDueNow: "=",
                additionalWpts: "=?",
                numberOfCurrentWpts: '<?',
                additionalAccounts: "=?",
                differentialPrice: "=?"
            },
            templateUrl: "app/components/billing/price_tables/differential_price-table.tpl.html",
            controller: DifferentialPriceTableInputController
        });

    DifferentialPriceTableInputController.$inject = ["$scope", "$translatePartialLoader", "$translate"];

    function DifferentialPriceTableInputController($scope, $translatePartialLoader, $translate) {

        var $ctrl = this;

        if ($ctrl.numberOfCurrentWpts == null) {
            $ctrl.numberOfCurrentWpts = 0;
        }

        console.log("$ctrl.organisation", $ctrl.organisation);

        this.$onInit = function() {
            $translatePartialLoader
                .addPart("prices")
                .addPart("billing");
            $translate.refresh();
        };
    }

})();
