(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactQuantityInput', {
            bindings: {
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                reference: '=',
                referenceString: '@?',
                referenceInput: '=',
                referencePlaceholder: '=',
                element: '=?',
                realEstate: '=?',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                minlength: '@',
                maxlength: '@',
                validationMessage: '@',
                tabIndex: '=',
                rowKey: '=?',
                image: '=?',
                tabGroup: '=',
                mandatory: '=?',
                isDisabled: '=?',
                index: '<',
                infotext: '@?',
                background: '@?',
                focusFun: '&?',
                blurFun: '&?',
                actionCallback: '&',
                isLast: '='
            },
            templateUrl: 'app/components/compact_inputs/compact_quantity_input/compact-quantity-input-partial.html',
            controller: CompactQuantityInputController
        });

    CompactQuantityInputController.$inject = ['$scope', '$rootScope', '$attrs', '$element'];

    function CompactQuantityInputController($scope, $rootScope, $attrs, $element) {
        var $ctrl = this;

        this.$onInit = function() {
            $ctrl.hasFocusOutFired = false;
            if ($ctrl.element && $ctrl.element.totalMeasure.interventionGradeQuantityUserInput) {
                $ctrl.element.totalMeasure.interventionGradeQuantity = $ctrl.element.totalMeasure.interventionGradeQuantityUserInput;
            }
            var inputField = $element.find('input')[0];
            if ($attrs.required) {
                inputField.required = true;
            }
            // Used for the validation
            if ($ctrl.referenceString == null) {
                $($element).attr('id', $attrs.element + '.interventionGradeQuantity.formElement');
                $(inputField).attr('id', $attrs.element + '.interventionGradeQuantity.input');
            } else {
                $($element).attr('id', $ctrl.referenceString + '.formElement');
                $(inputField).attr('id', $ctrl.referenceString + '.input');
            }
            if ($ctrl.isDisabled == null) {
                $ctrl.isDisabled = false;
            }

            if (!angular.isFunction($ctrl.actionCallback)) {
                $ctrl.actionCallback = angular.noop;
            }
        };

        $ctrl.onFocus = function () {
            $ctrl.hasFocusOutFired = false;
            var image = '/assets/images/calculation/bg/objektdaten.svg';
            if ($ctrl.image != null) {
                image = $ctrl.image;
            }
            if ($ctrl.rowKey != null) {
                $rootScope.$broadcast('updateImage', {
                    rowid: $ctrl.rowid,
                    key: $ctrl.rowKey
                });
            } else {
                $rootScope.$broadcast('updateImage', {
                    rowid: $ctrl.rowid,
                    imageSrc: image
                });
            }
            $rootScope.$broadcast('updateDescription', {
                rowid: $ctrl.rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $ctrl.translateDescriptionBaseKey
            });
            if ($ctrl.focusFun != null) {
                $ctrl.focusFun();
            }
        };

        $ctrl.onBlur = function () {
            var oldValue = $ctrl.referenceInput;
            if ($ctrl.hasFocusOutFired) {
                return;
            }
            $ctrl.hasFocusOutFired = true;
            if ($ctrl.element) {

                if ($ctrl.element.totalMeasure.interventionGradeQuantityUserInput && $ctrl.element.totalMeasure.interventionGradeQuantityUserInput.length > 2 && $ctrl.element.totalMeasure.interventionGradeQuantityUserInput.search(',') !== -1) {
                    $ctrl.element.totalMeasure.interventionGradeQuantityUserInput = $ctrl.element.totalMeasure.interventionGradeQuantityUserInput.replace(',', '.');
                }
                $ctrl.element.totalMeasure.interventionGradeQuantityUserInput = parseFloat($ctrl.element.totalMeasure.interventionGradeQuantityUserInput);
                if ($ctrl.element.totalMeasure.interventionGradeQuantityUserInput || $ctrl.element.totalMeasure.interventionGradeQuantityUserInput === 0) {
                    $ctrl.element.totalMeasure.interventionGradeQuantity = $ctrl.element.totalMeasure.interventionGradeQuantityUserInput;
                } else {
                    $ctrl.element.totalMeasure.interventionGradeQuantity = Number($ctrl.element.totalMeasure.interventionGradeQuantityPlaceholder);
                }
            } else {
                if ($ctrl.referenceInput == null || $ctrl.referenceInput === '') {
                    $ctrl.referenceInput = null;
                } else {
                    $ctrl.referenceInput = Number($ctrl.referenceInput);
                }
            }
            if ($ctrl.blurFun != null) {
                $ctrl.blurFun();
            }
            $ctrl.actionCallback({
                newValue: $ctrl.referenceInput,
                oldValue: oldValue,
                args: { origin: $attrs.referenceInput, rowid: $ctrl.rowid, descriptionBaseKey: $ctrl.translateDescriptionBaseKey }
            });
        };

        this.$postLink = function () {
            if ($ctrl.element && $ctrl.isLast && $ctrl.realEstate.constructionYear) {
                // invoke enabling of preset-slider-model-values
                $rootScope.$broadcast("enablePresetSliderModelValues", {});
            }
        };
    }

})();
