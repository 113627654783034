(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageCalculatingErrorController', DamageCalculatingErrorController);

    DamageCalculatingErrorController.$inject = [];

    function DamageCalculatingErrorController() {
    }

})();
