(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('rebuildUsageRowSelect', {
            bindings: {
                rowid: '=',
                elemWidth: '<',
                referenceName: '@?',
                realEstateContainer: '=?',
                descriptionBaseKey: '@?',
                translateBaseKey: '@?',
                image: '=?',
                mandatory: '@?',
                current: '=?',
                annex: '=?',
                overhaul: '=?',
                additionStory: '=?',
                tabIndex: '=?',
                tabGroup: '=?',
                validationMessage: '@?',
                infotext: '@?',
                index: '@'
            },
            templateUrl: 'app/components/rebuild_usage_row_select/rebuild-usage-row-select.tpl.html',
            controller: RebuildUsageRowController
        });

    RebuildUsageRowController.$inject = ['$scope', '$timeout', '$translate', '$rootScope', 'RebuildGeometryService', 'SharedControllerFnService', 'RebuildActionService'];

    function RebuildUsageRowController($scope, $timeout, $translate, $rootScope, RebuildGeometryService, SharedControllerFnService, RebuildActionService) {
        var $ctrl = this;
        $ctrl.dataHasLoaded = false;
        $ctrl.RebuildActionService = RebuildActionService;
        $ctrl.RebuildGeometryService = RebuildGeometryService;

        this.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.currentReferenceString = 'realEstateContainer.targetOverhaul.' + $ctrl.referenceName;
            $ctrl.targetOverhaulReferenceString = 'realEstateContainer.targetOverhaul.' + $ctrl.referenceName;
            $ctrl.targetAdditionStoryReferenceString = 'realEstateContainer.targetAdditionStory.' + $ctrl.referenceName;
            $ctrl.targertAnnexReferenceString = 'realEstateContainer.targetAnnex.' + $ctrl.referenceName;

            var checkProperty;
            if ($ctrl.realEstateContainer.targetAnnex.usages == null) {
                $ctrl.realEstateContainer.targetAnnex.usages = [{ type: null, percentage: null, standard: null }];
            } else {
                if (!_.isArray($ctrl.realEstateContainer.targetAnnex.usages)) {
                    checkProperty = SharedControllerFnService.hasOwnDeepProperty($ctrl.realEstateContainer.targetAnnex.usages, 'type');
                    if (checkProperty) {
                        $ctrl.realEstateContainer.targetAnnex.usages = [{
                            type: checkProperty.type,
                            percentage: null
                        }];
                    } else {
                        $ctrl.realEstateContainer.targetAnnex.usages = [{ type: null, percentage: null, standard: null }];
                    }
                }
            }
            // checking if usages is an array.
            if ($ctrl.realEstateContainer.targetAdditionStory.usages == null) {
                $ctrl.realEstateContainer.targetAdditionStory.usages = [{ type: null, percentage: null, standard: null }];
            } else {
                if (!_.isArray($ctrl.realEstateContainer.targetAdditionStory.usages)) {
                    //if usages is not an array we check if there is a usage object saved in usages. if yes we extract the type.
                    checkProperty = SharedControllerFnService.hasOwnDeepProperty($ctrl.realEstateContainer.targetAdditionStory.usages, 'type');
                    if (checkProperty) {
                        $ctrl.realEstateContainer.targetAdditionStory.usages = [{
                            type: checkProperty.type,
                            percentage: null
                        }];
                    } else {
                        $ctrl.realEstateContainer.targetAdditionStory.usages = [{ type: null, percentage: null, standard: null }];
                    }
                }
            }
            SharedControllerFnService.normalizeRebuildUsages($ctrl.realEstateContainer);
            $ctrl.dataHasLoaded = true;
        };

        $ctrl.addUsages = function() {
            if ($ctrl.realEstateContainer.usages.length < 5) {
                $ctrl.realEstateContainer.usages.push({ type: null, percentage: null, standard: null });
                $ctrl.realEstateContainer.targetOverhaul.usages.push({ type: null, percentage: null, standard: null });
                if (RebuildGeometryService.isAnnex) {
                    if (!_.isArray($ctrl.realEstateContainer.targetAnnex.usages)) {
                        $ctrl.realEstateContainer.targetAnnex.usages = [{ type: null, percentage: null, standard: null }];
                    }
                    $ctrl.realEstateContainer.targetAnnex.usages.push({ type: null, percentage: null, standard: null });
                }
                if (RebuildGeometryService.isAdditionStory) {
                    if (!_.isArray($ctrl.realEstateContainer.targetAdditionStory.usages)) {
                        $ctrl.realEstateContainer.targetAdditionStory.usages = [{ type: null, percentage: null, standard: null }];
                    }
                    $ctrl.realEstateContainer.targetAdditionStory.usages.push({ type: null, percentage: null, standard: null });
                }
            }
        };

        $ctrl.removeUsages = function(index) {
            $ctrl.realEstateContainer.usages.splice(index,1);
            $ctrl.realEstateContainer.rebuildUsages.splice(index,1);
            $ctrl.realEstateContainer.targetOverhaul.usages.splice(index,1);
            if ($ctrl.RebuildGeometryService.isAnnex) {
                $ctrl.realEstateContainer.targetAnnex.usages.splice(index,1);
            }
            if ($ctrl.RebuildGeometryService.isAdditionStory) {
                $ctrl.realEstateContainer.targetAdditionStory.usages.splice(index,1);
            }

        };

    }
})();
