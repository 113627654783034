(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('DimensionSheetService', DimensionSheetService);

    DimensionSheetService.$inject = ['$uibModal'];

    function DimensionSheetService($uibModal) {
        var service = {
            open: open
        };

        var modalInstance = null;

        var resetModal = function () {
            modalInstance = null;
        };

        return service;

        function open($scope) {

            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/dimension_sheet/dimension-sheet.tpl.html',
                controller: 'DimensionSheetController',
                scope: $scope,
                controllerAs: 'vm',
                windowClass: 'calc-modal',
                backdrop: 'static',
                keyboard: false

            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );

        }
    }
})();
