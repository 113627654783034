(function () {
    'use strict';


    angular.module('kennwerteApp')
    .directive('imageUpload', ImageUploadDirective)
    .controller('ImageUploadController', ImageUploadController);


    ImageUploadDirective.$inject = [];

    function ImageUploadDirective() {
        return {
            restrict: "E",
            scope: {
                rowid: '=',
                realEstateContainer: "=", // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@',
                isDisabled: '=',
                min: '@',
                max: '@',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                inputFocusFun: '&',
                inputBlurFun: '&',
                tabIndex: '=',
                tabGroup: '='
            },
            controller: ImageUploadController,
            controllerAs: 'vm',
            templateUrl: 'app/components/image_upload/image-upload.tpl.html'
        };
    }

    ImageUploadController.$inject = ['$rootScope', '$scope', 'Upload', '$timeout'];

    function ImageUploadController($rootScope, $scope, Upload, $timeout) {
        var vm = this;

        vm.rowid = $scope.rowid;
        vm.isUploading = false;

        vm.selectFiles = function (file, errFiles) {
            vm.f = file;
            vm.errFile = errFiles && errFiles[0];
        };
        vm.submit = function () {
            if ($scope.form.file.$valid && $scope.file) {
                $scope.upload($scope.file);
            }
        };
        vm.uploadFiles = function (files, errFiles) {
            vm.f = files;
            vm.errFile = errFiles && errFiles[0];
            if (vm.f) {
                vm.isUploading = true;
                angular.forEach(files, function (file, key) {
                    console.log(file);
                    file.upload = Upload.upload({
                        url: '/api/attachmentupload',
                        data: {
                            file: file,
                            type: file.type,
                            name: file.name
                        }
                    });

                    file.upload.then(function (response) {
                        $timeout(function () {
                            console.log(response);
                            vm.f[key].result = response.data;
                            vm.f[key].imageId = vm.f[key].result.id;
                            vm.f[key].imageType = vm.f[key].result.type;
                            vm.f[key].imageName = vm.f[key].result.name;
                            vm.f[key].path = "/image/hd/" + vm.f[key].result.userLogin + "/" + vm.imageId + '.' + vm.imageType;
                            // $scope.realEstateContainer.metaData.mainImageId = vm.imageId;
                            vm.isUploading = false;
                        });
                    }, function (response) {
                        vm.isUploading = false;
                        if (response.status > 0)
                            vm.errorMsg = response.status + ': ' + response.data;
                    }, function (evt) {
                        file.progress = Math.min(100, parseInt(100.0 *
                            evt.loaded / evt.total));
                    });
                });
            }
        };
    }

})();
