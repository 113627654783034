(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('CalcRowImageService', CalcRowImageService);

    CalcRowImageService.$inject = ['RebuildEstateDataService', 'RebuildGeometryService', 'SmallBuildingGalleryDataService'];

    function CalcRowImageService(RebuildEstateDataService, RebuildGeometryService, SmallBuildingGalleryDataService) {
        var service = {
            getImageURL: getImageURL
        };

        var mapImages = {
            extension: {
                resolveFun: function() {
                    var temp = [];
                    if (RebuildGeometryService.isNone) temp.push('NONE');
                    else {
                        if (RebuildGeometryService.isAnnex) temp.push('ANNEX');
                        if (RebuildGeometryService.isAdditionStory) temp.push('ADDITION_STORY');
                    }
                    return (temp.length === 0)
                        ? this.defaultImage
                        : 'assets/images/non-rev/extension/' + temp.join('_') + '.' + 'png';
                },
                defaultImage: '/assets/images/calculation/bg/empty.svg'
            },
            //totalVolume 416
            totalVolume416: {
                resolveFun: getDefaultResolveFun(
                    'assets/images/non-rev/quantitaet/gebaeudevolumen',
                    'gebaeudevolumen',
                    'png'
                )
            },
            //totalFloorArea416
            totalFloorArea416: {
                resolveFun: getDefaultResolveFun(
                    'assets/images/non-rev/quantitaet/geschossflaeche',
                    'geschossflaeche',
                    'png'
                )
            }
        };

        function getImageURL(key) {
            if (_.startsWith(key, 'agvWkSlider_')) {
                var splitted = key.split('_')
                var nomUsageId = splitted[1];
                var wk = splitted[2];
                var gf = splitted[3];
                return getSmallBuildingImage(nomUsageId, wk, gf);
            } else {
                var value = mapImages[key];
                return value.resolveFun();
            }
        }

        function getDefaultResolveFun(base, suffix, fileExtension) {
            return function() {
                var temp = base + '/';
                if (RebuildGeometryService.isAnnex) {
                    temp = temp.concat('annex_');
                }
                if (RebuildGeometryService.isAdditionStory) {
                    temp = temp.concat('additional_story_');
                }
                return temp + suffix + '.' + fileExtension;
            };
        }

        function getSmallBuildingImage(nomUsageId, wk, gf) {
            var dto = {
                'nomUsageId': nomUsageId,
                'wk': wk,
                'gf': gf
            }
            return SmallBuildingGalleryDataService.fetchAndSetGalleryImage(dto);
        }

        return service;
    }
})();
