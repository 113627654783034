(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('rebuildGeometryRowInput', {
            bindings: {
                rowid: '=',
                referenceName: '@?',
                realEstateContainer: '=?',
                translateBaseKey: '@?',
                descriptionBaseKey: '@?',
                overhaulTranslateBaseKey: '@?',
                annexTranslateBaseKey: '@?',
                additionalStoryTranslateBaseKey: '@?',
                rowKey: '=?',
                image: '=?',
                imageAnnex: '=?',
                imageAdditionalStory: '=?',
                mandatory: '@?',
                isDisabled: '=?',
                current: '=?',
                currentRefString: '@?',
                annex: '=?',
                minlength: '=?',
                maxlength:'=?',
                overhaul: '=?',
                showOverhaul: '=',
                additionStory: '=?',
                tabIndex: '=?',
                tabGroup: '=?',
                validationMessage: '@?',
                placeholder: '@?',
                inputType: '@?',
                infotext: '@?',
                index: '@',
                focusFun: '&?',
                blurFun: '&?',
                actionCallback: '&'
            },
            templateUrl: 'app/components/rebuild_geometry/rebuild-geometry-row-input.component/rebuild-geometry-row-input.tpl.html',
            controller: RebuildGeometryRowController
        });

    RebuildGeometryRowController.$inject = ['$scope', '$timeout', '$translate', '$rootScope', 'RebuildGeometryService'];

    function RebuildGeometryRowController($scope, $timeout, $translate, $rootScope, RebuildGeometryService) {
        var $ctrl = this;
        $ctrl.rebuildGeometryService = RebuildGeometryService;

        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.currentReferenceString = $ctrl.currentRefString + '.' + $ctrl.referenceName;
            $ctrl.targetOverhaulReferenceString = 'realEstateContainer.targetOverhaul.' + $ctrl.referenceName;
            $ctrl.targetAdditionStoryReferenceString = 'realEstateContainer.targetAdditionStory.' + $ctrl.referenceName;
            $ctrl.targertAnnexReferenceString = 'realEstateContainer.targetAnnex.' + $ctrl.referenceName;
            $ctrl.dataHasLoaded = true;

            if ($ctrl.isDisabled == null) {
                $ctrl.isDisabled = false;
            }
            if ($ctrl.isDisabled && $ctrl.mandatory === 'true') {
                console.error($ctrl.referenceName + ' disabled and mandatory - this cant work out!');
            }
            if($ctrl.minlength == undefined){
                $ctrl.minlength = 0;
            }
            if($ctrl.maxlength == undefined){
                $ctrl.maxlength = 10;
            }
            if($ctrl.imageAnnex == null){
                $ctrl.imageAnnex = $ctrl.image;
            }
            if($ctrl.imageAdditionalStory == null){
                $ctrl.imageAdditionalStory = $ctrl.image;
            }
            if ($ctrl.overhaulTranslateBaseKey == null) {
                $ctrl.overhaulTranslateBaseKey = $ctrl.translateBaseKey;
            }
            if ($ctrl.annexTranslateBaseKey == null) {
                $ctrl.annexTranslateBaseKey = $ctrl.translateBaseKey;
            }
            if ($ctrl.additionalStoryTranslateBaseKey == null) {
                $ctrl.additionalStoryTranslateBaseKey = $ctrl.translateBaseKey;
            }
            if (!angular.isFunction($ctrl.actionCallback)) {
                $ctrl.actionCallback = angular.noop;
            }
        };

        $ctrl.ElemWidth = 0;

        $ctrl.ngStyle;

        $ctrl.checkIfIsAnnex = function () {
            $ctrl.widthCalc();
            return RebuildGeometryService.isAnnex;
        };
        $ctrl.checkIfIsAdditionalStory = function () {
            $ctrl.widthCalc();
            return RebuildGeometryService.isAdditionStory;
        };

        $ctrl.widthCalc = function () {
            if ((RebuildGeometryService.isAnnex && !RebuildGeometryService.isAdditionStory) ||
                (!RebuildGeometryService.isAnnex && RebuildGeometryService.isAdditionStory)) {
                $ctrl.ElemWidth = 117;
            } else {
                if (RebuildGeometryService.isAnnex && RebuildGeometryService.isAdditionStory){
                    $ctrl.ElemWidth = 88;
                } else if (RebuildGeometryService.isAnnex|| RebuildGeometryService.isAdditionStory) {
                    $ctrl.ElemWidth = 117;
                } else {
                    $ctrl.ElemWidth = 177.5;
                }
            }
            $ctrl.ngStyle ={'width': $ctrl.ElemWidth +'px'};
        };

    }
})();
