(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('User', User)
        .factory('CreditAmount', CreditAmount);

    User.$inject = ['$resource'];

    function User($resource) {
        var service = $resource('api/users/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'updateLanguage': {
                method: 'POST',
                url: 'api/users/language'

            },
            "search_actives_by_login": {
                method: 'GET',
                url: 'api/users/search_actives_by_login/:searchString',
                params: {
                    searchString: '@searchString'
                },
                isArray: true
            },
            "query_actives": {
                method: 'GET',
                url: 'api/users/query_actives',
                isArray: true
            },
            "search_by_owner_login": {
                method: 'GET',
                url: 'api/users/search_by_owner_login/:searchString',
                params: {
                    searchString: '@searchString'
                },
                isArray: true
            },
            "query_owner_logins": {
                method: 'GET',
                url: 'api/users/query_owner_logins',
                isArray: true
            },
            "search_by_non_owner_login": {
                method: 'GET',
                url: 'api/users/search_by_non_owner_login/:searchString',
                params: {
                    searchString: '@searchString'
                },
                isArray: true
            },
            "query_non_owner_logins": {
                method: 'GET',
                url: 'api/users/query_non_owner_logins',
                isArray: true
            },
            'search_eligible_for_withdrawal_products_by_login': {
                method: 'GET',
                url: 'api/users/search_eligible_for_withdrawal_products_by_login/:searchString',
                params: {
                    searchString: '@searchString'
                },
                isArray: true
            },
            'query_eligible_for_withdrawal_products': {
                method: 'GET',
                url: 'api/users/query_eligible_for_withdrawal_products',
                isArray: true
            },
            'set_public_ref_settings': {
                method: 'POST',
                url: 'api/users/settings/show_public_ref/:id'
            },
            'set_private_ref_settings': {
                method: 'POST',
                url: 'api/users/settings/show_priv_ref/:id'
            }
        });
        return service;
    }

    CreditAmount.$inject = ['$http'];
    function CreditAmount($http) {
        return {
            'current_user': function () {
                return $http.get('api/users/credit_amount');
            },
            'credits_expiration_date': function () {
                return $http.get('api/users/credits_expiration_date');
            },
            'is_flat_rate': function () {
                return $http.get('api/users/is_flat_rate');
            },
            'org_role': function(){
                return $http.get('api/users/org_role');
            }
        };

    }

})();
