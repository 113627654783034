(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('AttachmentService', AttachmentService);

    AttachmentService.$inject = ['$http', '$q', 'Upload', '$timeout', 'RealEstateReferenceDataService', 'AlertService', '$rootScope'];

    function AttachmentService($http, $q, Upload, $timeout, RealEstateReferenceDataService, AlertService, $rootScope) {
        var service = {
            attachments: [],
            upload: uploadAttachments,
            delete: deleteAttachment,
            add: addAttachments,
            reset: resetAttachments
        };

        var counter = 0;
        var maxImagePixels = 4096 * 2160; // ultra hd

        function addAttachments(files, attachmentType) {
            counter++;

            if (files.length > 0) {
                angular.forEach(files, function (file) {
                    service.attachments.push({file: file, attachmentType: attachmentType});
                });
            }
            // simple counter to make sure we got all data together from all the upload directives.
            if (counter == 6) {
                uploadAttachments();
                counter = 0;
            }
        }

        function resizeOptions(attachment) {
            var width = attachment.file.$ngfWidth;
            var height = attachment.file.$ngfHeight;
            var wh = width * height;
            var factor = Math.sqrt(maxImagePixels / wh);
            return {
                width: factor * width,
                height: factor * height,
                quality: 0.9
            };
        }

        function uploadAttachments() {
            var progressCounter = 0;
            var successCounter = 0;

            if (service.attachments.length > 0) {
                angular.forEach(service.attachments, function (attachment, key) {
                    if (!attachment.file.$error) {

                        var data = {
                            file: attachment.file,
                            attachmentType: attachment.attachmentType,
                            referenceId: RealEstateReferenceDataService.get().id,
                            sortIdx: key
                        };

                        /*
                        attachment.upload = Upload.upload({
                            url: '/api/attachments',
                            data: data,
                            headers: {'Content-Type': 'multipart/form-data'}
                        });
                        */

                        if (attachment.file.$ngfWidth * attachment.file.$ngfHeight > maxImagePixels) {
                            attachment.upload = Upload.resize(attachment.file, resizeOptions(attachment))
                                .then(function (resizedFile) {
                                    data.file = resizedFile;
                                    return Upload.upload({
                                        url: '/api/attachments',
                                        data: data,
                                        headers: {'Content-Type': 'multipart/form-data'}
                                    });
                                });
                        } else {
                            attachment.upload = Upload.upload({
                                url: '/api/attachments',
                                data: data,
                                headers: {'Content-Type': 'multipart/form-data'}
                            });
                        }

                        attachment.upload.then(function (response) {
                            $timeout(function () {
                                progressCounter++;
                                successCounter++;
                                $rootScope.$broadcast('uploadFileFinished', {
                                    progress: progressCounter,
                                    length: service.attachments.length
                                });
                                if (progressCounter === service.attachments.length) {
                                    resetAttachments(successCounter);
                                    progressCounter = 0;
                                    successCounter = 0;
                                }
                                attachment.upload = null;
                            });
                        }, function (response) {
                            progressCounter++;
                            if (progressCounter === service.attachments.length) {
                                resetAttachments(successCounter);
                                progressCounter = 0;
                                successCounter = 0;
                            }
                            attachment.upload = null;
                        }, function (evt) {
                        });
                    }
                });
            } else {
                resetAttachments(successCounter);
            }
        }

        function resetAttachments(successCounter) {
            console.log('allAttachmentsUploaded', service.attachments.length);
            counter = 0;
            $rootScope.$broadcast('allAttachmentsUploaded', {
                counter: successCounter,
                length: service.attachments.length
            });
            service.attachments.splice(0, service.attachments.length);
        }

        //TODO implement it!
        function deleteAttachment(attachment, referenceId) {
        }

        return service;
    }


})();
