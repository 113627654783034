(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('AgvSmallBuildingInsuranceCompleteInputController', AgvSmallBuildingInsuranceCompleteInputController);

    AgvSmallBuildingInsuranceCompleteInputController.$inject = ["$scope", "$rootScope", "$stateParams", "$state", "SmallBuildingService", "Principal", "$sessionStorage", "$filter", "usageTypeGroupFactory", "ValidationDataService", "ValidationFunctionsService"];

    function AgvSmallBuildingInsuranceCompleteInputController($scope, $rootScope, $stateParams, $state, SmallBuildingService, Principal, $sessionStorage, $filter, usageTypeGroupFactory, ValidationDataService, ValidationFunctionsService) {
        $scope.elementsWithError = [];
        $scope.isPomEnabled = false;
        $scope.validationDataService = ValidationDataService;
        $scope.uploadStarted = false;

        ValidationDataService.deregisterValidationFunctions();
        ValidationDataService.setZindex(1048);
        ValidationDataService.registerValidationFunctions([
            ValidationFunctionsService.validationSmallBuildingExNumber,
            ValidationFunctionsService.validateZipCode,
            ValidationFunctionsService.validateSmallBuildingStandard,
            ValidationFunctionsService.validateSmallBuildingPrefabrication,
            ValidationFunctionsService.validateYearOfConstruction,
            ValidationFunctionsService.validateSmallBuildingCanopy,
            ValidationFunctionsService.validationSmallBuildingArea,
            ValidationFunctionsService.validateSmallBuildingLocation,
            ValidationFunctionsService.validateSmallBuildingClosed,
            ValidationFunctionsService.validateSmallBuildingBuildStyle,
            ValidationFunctionsService.validateSmallBuildingRoof,
            ValidationFunctionsService.validateSmallBuildingHeating,
            ValidationFunctionsService.validateSmallBuildingUsages,
            ValidationFunctionsService.validateSmallBuildingMainUsage
        ]);

        $scope.$on("changeUsages", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.$on("changeInvestments", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.$on("uploadStartedEvent", function(event, args) {
            $scope.uploadStarted = true;
        });

        $scope.$on("uploadFinishedEvent", function(event, args) {
            $scope.uploadStarted = false;
        });

        $scope.validateAndProceed = function (onSuccessFn) {
            ValidationDataService.validateAndProceed($scope.realEstateContainer, onSuccessFn);
        };
    }
})();
