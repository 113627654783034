(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('VideoModalController', VideoModalController);

    VideoModalController.$inject = ['$uibModalInstance', '$sce', 'url', 'title'];

    function VideoModalController($uibModalInstance, $sce, url, title) {
        var vm = this;

        vm.embedUrl = $sce.trustAsResourceUrl(url);
        vm.title = title;

        vm.close = close;

        function close() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
