(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('minergiePvSelecter', MinergiePvSelecterDirective)
        .controller('MinergiePvController', MinergiePvController);

    MinergiePvController.$inject = ['$scope', '$rootScope', '$timeout', 'MinergiePvGroupFactory', '$translate'];

    function MinergiePvController($scope, $rootScope, $timeout, MinergiePvGroupFactory, $translate) {
        var vm = this;
            //this is for visual purpose so the client can show how much options are selected.
        vm.counter = 0;
        vm.selectedMINERG;
        vm.pv = false;

        vm.translateKey = function (postfix) {
            try {
                return $translate.instant('realEstate.complexity.energy-standard.' + postfix +'.label');
            } catch (e) {
                console.warn('minergie-pv.',e);
            }
        };

        vm.updateDescriptionAndImage = function (selectedItem) {
            if (!$scope.firstLoad) {
                $scope.reference = vm.pv;
                $scope.minergieReference = vm.selectedMINERG;
                if (selectedItem != undefined) {
                    $rootScope.$broadcast("updateDescription", {
                        rowid: $scope.rowid,
                        descriptionNumber: selectedItem.title,
                        descriptionBaseKey: $scope.descriptionBaseKey,
                        dynamicTranslations: true,
                    });
                    $rootScope.$broadcast("updateImage", {
                        rowid: $scope.rowid,
                        imageSrc: $scope.image
                    });
                } else {
                    $rootScope.$broadcast("updateImage", {
                        rowid: $scope.rowid,
                        imageSrc: $scope.image
                    });
                    $rootScope.$broadcast("updateDescription", {
                        rowid: $scope.rowid,
                        descriptionBaseKey: $scope.descriptionBaseKey
                    });
                }
            } else {
                $scope.firstLoad = false;
            }
        };

        vm.onOpenClose = function (isOpen) {
            if (isOpen) {
                vm.updateDescriptionAndImage(undefined);
            }
        };

        vm.onSelect = function (selectedItem) {
            selectedItem.isSelected = !selectedItem.isSelected;
            vm.model = selectedItem;
            if (selectedItem.isSelected) {
                switch (selectedItem.ref) {
                case 'MINERG':
                    vm.selectedMINERG = selectedItem.title;
                    $scope.groups.map(function (current) {
                        if (selectedItem !== current && selectedItem.ref === current.ref) {
                            current.isSelected = false;
                        }
                    });
                    break;
                case 'PV':
                    vm.pv = selectedItem.isSelected;
                    break;
                }
            } else {
                switch (selectedItem.ref) {
                case 'MINERG':
                    vm.selectedMINERG = null;
                    break;
                case 'PV':
                    vm.pv = selectedItem.isSelected;
                    break;
                }
            }
            vm.updateDescriptionAndImage(selectedItem);
            vm.updateCounter();
        };
        vm.model = {};
        vm.updateCounter = function () {
            vm.counter = vm.pv ? 1 : 0;
            vm.counter += vm.selectedMINERG != null ? 1 : 0;
        };

            /**
             * This watch checks if the reference changes and updates the whole component.
             * This can happen if we load data from the db async.
             */
        $scope.$watch('reference', function (newValue) {
                //not working is newvalue not defined
            if (angular.isDefined(newValue)) {
                vm.pv = $scope.reference;
                var idx = $scope.groups.findIndex(function (current) {
                    return 'PV-ANLAGE' === current.title;
                });
                $scope.groups[idx].isSelected = $scope.reference;
                vm.model = $scope.groups[idx];
                vm.updateCounter();
            } else {
                vm.pv = $scope.reference;
                var idx = $scope.groups.findIndex(function (current) {
                    return 'PV-ANLAGE' === current.title;
                });
                $scope.groups[idx].isSelected = $scope.reference;
                vm.model = $scope.groups[idx];
                vm.updateCounter();

            }
        });

        $scope.$watch('minergieReference', function (newValue) {
            if (newValue && angular.isDefined(newValue)) {
                vm.selectedMINERG = $scope.minergieReference;
                var idx = $scope.groups.findIndex(function (current) {
                    return $scope.minergieReference === current.title;
                });
                if (idx) {
                    $scope.groups[idx].isSelected = true;
                    vm.model = $scope.groups[idx];
                }
                vm.updateCounter();
            }
        });
        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function(_) {
            if ($scope.firstLoad) return;
            if (vm.model != null) {
                vm.model.translated = vm.translateKey(vm.model.title);
            }
            // console.warn($scope.groups[0], $scope.groups[0].title, vm.translateKey($scope.groups[0].title));
            for (var i = 0; i < $scope.groups.length; i++) {
                if ($scope.groups[i].title != null) {
                    $scope.groups[i].translated = vm.translateKey($scope.groups[i].title);
                }
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
    }

    MinergiePvSelecterDirective.$inject = ['$rootScope', '$timeout', 'MinergiePvGroupFactory', '$translate'];

    function MinergiePvSelecterDirective($rootScope, $timeout, MinergiePvGroupFactory, $translate) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                minergieReference: '=',
                rowid: '=',
                image: '=image',
                required: '=required',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@?',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                isOperationalCosts: '@?',
                dynamicTranslations: '@?'
            },
            templateUrl: 'app/components/minergie_pv_selecter/minergie-pv-selecter.tpl.html',
            controller: MinergiePvController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, ctrl) {

                scope.translateKey = function (postfix) {
                    try {
                        return $translate.instant('realEstate.complexity.energy-standard.' + postfix +'.label');
                    } catch (e) {
                        console.warn('minergie-pv',e);
                    }
                };
                var enhanceGroupFn = function (gs) {
                    for (var i in scope.groups) {
                        var oldg = scope.groups[i];
                        oldg.noshow = true;
                    }
                    var enhancedGroups = [];
                    for (var j in gs) {
                        var group = gs[j];
                        enhancedGroups.push({
                            "id": group.id,
                            "title": group.title,
                            "parent": group.parent,
                            "translated": scope.translateKey(group.title),
                            "isSelected": group.isSelected,
                            "ref": group.ref
                        });
                    }
                    scope.groups = enhancedGroups;
                };

                    // add property translated to to each group in groups
                ctrl.resetGroups = function () {
                    var gs = MinergiePvGroupFactory.load(0);
                    enhanceGroupFn(gs);
                };

                ctrl.resetGroups();

                    // Used for the validation
                var toggle = tElement.find(".minergiePvDrop2downOuter");
                scope.idPrefix = tAttrs.minergieReference;
                $(toggle).attr("id", tAttrs.minergieReference + ".drop2down-toggle");
            }
        };
    }
}

)();
