(function() {

    'use strict';

    angular.module('kennwerteApp')
        .factory('PaymentPdfService', PaymentPdfService);

    PaymentPdfService.$inject = ['$http', 'FileSaver'];

    function PaymentPdfService($http, FileSaver) {
        return {
            downloadPaymentPdf: function (paymentId, pdfName) {
                return $http.get('api/billing/pdf/download?id=' + paymentId, {responseType: 'blob'})
                    .then(function(response) {
                        var data = new Blob([response.data], {type: 'application/pdf;charset=UTF-8'});
                        FileSaver.saveAs(data, pdfName);
                    });
            }
        };
    }
})();
