(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('luucyButton', {
            bindings: {},
            templateUrl: 'app/components/luucy/luucy-button.tpl.html',
            controller: LuucyButtonController
        });

    LuucyButtonController.$inject = ['$rootScope', '$scope', '$uibModal'];

    function LuucyButtonController($rootScope, $scope, $uibModal) {
        var $ctrl = this;

        $ctrl.showModal = function () {
            $uibModal.open({
                controller: ModalController,
                animation: false,
                size: 'lg',
                controllerAs: 'ctrl',
                bindToController: true,
                scope: $scope,
                clickOutsideToClose: true,
                templateUrl: 'app/components/luucy/luucy.tpl.html'
            })
        };
        ModalController.$inject = ['$scope', '$uibModalInstance'];

        function ModalController($scope, $uibModalInstance) {
            $scope.cancel = function () {
                $uibModalInstance.close();
            };
        }
    }


})();
