(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RealEstateReferenceDataService', RealEstateReferenceDataService);

    RealEstateReferenceDataService.$inject = ['$q', '$rootScope', '$sessionStorage', 'RealestateReferenceService'];

    function RealEstateReferenceDataService($q, $rootScope, $sessionStorage, RealestateReferenceService) {

        var service = {
            realEstateReference: {},
            new: createEmptyRealEstateReference,
            get: getRealEstateReference,
            save: saveRealEstateReference,
            load: loadRealEstateReference,
            saveToSession: saveRealEstateReferenceToSession,
            clearSession: clearRealEstateReferenceFromSessionStorage,
            loadFromSession: loadRealEstateReferenceFromSession,
            spawnRealEstateEvent: spawnRealEstateEvent
        };

        function getRealEstateReference() {
            return service.realEstateReference;
        }

        //TODO Implement proper call. (Error Handling as well.)
        function saveRealEstateReference() {
            checkAdditions(service.realEstateReference.metaData.finalized.additions);
            if (service.realEstateReference.id) {
                RealestateReferenceService.update({id: service.realEstateReference.id}, service.realEstateReference,
                    function (updatedRealEstateReference) {
                        angular.copy(updatedRealEstateReference, service.realEstateReference);
                        $rootScope.$broadcast('uploadAttachments');
                    });
            } else {
                RealestateReferenceService.create({}, service.realEstateReference, function (createdRealEstateReference) {
                    angular.copy(createdRealEstateReference, service.realEstateReference);
                    $rootScope.$broadcast('uploadAttachments');
                }, function (error) {
                    console.log(error);
                });
            }
        }

        function saveRealEstateReferenceToSession() {
            $sessionStorage.realEstateReference = service.realEstateReference;
        }

        function loadRealEstateReferenceFromSession() {
            angular.copy($sessionStorage.realEstateReference, service.realEstateReference);

        }

        function clearRealEstateReferenceFromSessionStorage() {
            delete $sessionStorage.referenceObject;
        }

        function loadRealEstateReference(id, withdrawalProductType) {
            if (id) {
                var deferred = $q.defer();
                RealestateReferenceService.get({id: id}, function (loadedRealEstateReference) {
                    angular.copy(loadedRealEstateReference, service.realEstateReference);
                    deferred.resolve(service.realEstateReference);
                }, function (data) {
                    deferred.reject('Rejected: ' + data.status + ' RealEstate Reference with ID:' + id);
                });
                return deferred.promise;
            } else {
                return createEmptyRealEstateReference(withdrawalProductType);
            }
        }

        function createEmptyRealEstateReference(wdpt) {
            var deferred = $q.defer();
            RealestateReferenceService.getEmpty({}, function (createdRealEstateReference) {
                angular.copy(createdRealEstateReference, service.realEstateReference);
                deferred.resolve(service.realEstateReference);
            }, function (data, status, headers, config) {
                deferred.reject('Rejected :', +status);
            });
            return deferred.promise;
        }

        function checkAdditions(additions) {
            angular.forEach(additions, function (addition, index) {
                if (addition.bkp == null && addition.value == null && addition.label == null) {
                    additions.splice(index, 1);
                }
            });
        }

        function spawnRealEstateEvent(callback) {
            var cb = callback || angular.noop;

            return RealestateReferenceService.spawnRealEstateEvent({realEstateId: service.realEstateReference.id}, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        return service;
    }


})();
