/**
 * SmallBuildingGalleryDataService.
 * Holds the current galleryImages and currently selected galleryImage (defined by galleryImagesIdx).
 * This data service works only if exactly one gallery is present in app (as singleton).
 */
(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .factory('SmallBuildingGalleryDataService', SmallBuildingGalleryDataService);

    SmallBuildingGalleryDataService.$inject = ['$http', '$q'];

    function SmallBuildingGalleryDataService($http, $q) {
        var service = {
            curWk: undefined,
            curNomUsageId: undefined,
            curGf: undefined,
            galleryImagesContainer: [],
            galleryImagesIdx: 0,
            dots: {},
            nameObjIdx: nameObjIdx,
            nameImageIdx: nameImageIdx,
            fetchAndSetGalleryImage: fetchAndSetGalleryImage,
            moveLeft: moveLeft,
            moveRight: moveRight,
            moveToDot: moveToDot,
            clear: clear
        };

        return service;

        function defaultUrl() {
            return '/assets/images/calculation/bg/quantitaet.svg';
        }

        function prefixUrl() {
            return '/small_building_image/gallery/';
        }

        function _calc_tol(gf, tol_factor) {
            var tol_small_obj = 0.5; // gf <= 6
            var tol_big_obj = 0.2; // gf >= 30
            var small_limit = 6;
            var big_limit = 30;
            var tol = null;
            if (gf <= small_limit) {
                tol = tol_small_obj;
            } else if (gf >= big_limit) {
                tol = tol_big_obj;
            } else {
                tol = (gf-small_limit) * (tol_big_obj-tol_small_obj)/(big_limit-small_limit) + tol_small_obj
            }
            return tol * tol_factor;
        }

        function _is_in_tolerance_range(obj_gf, target_gf, tol_factor) {
            var tol = _calc_tol(target_gf, tol_factor);
            if (obj_gf <= target_gf) {
                return obj_gf >= target_gf / (1+tol);
            } else {
                return obj_gf < target_gf * (1+tol);
            }
        }

        function nameObjIdx() {
            if (!!service.dots) {
                var numObjects = _.keys(service.dots).length;

                if (numObjects > 0) {
                    var curObjectIdx = 1;
                    var chosenDot = null;

                    _.forEach(service.dots, function(d) {
                        if (!!d.isActive) {
                            chosenDot = d[0];
                            return false;
                        } else {
                            curObjectIdx += 1;
                        }
                    });

                    return curObjectIdx + "/" + numObjects;
                }
            } else {
                return "";
            }

        }

        function nameImageIdx() {
            if (!!service.dots) {
                var numObjects = _.keys(service.dots).length;

                if (numObjects > 0) {
                    var chosenDot = null;
                    var imagesOfSameObject = null;

                    _.forEach(service.dots, function(d) {
                        if (!!d.isActive) {
                            chosenDot = d[0];
                            imagesOfSameObject = d;
                            return false;
                        }
                    });

                    var curImageIdx = 1;
                    _.forEach(imagesOfSameObject, function(d) {
                        if (d['name'] === service.galleryImagesContainer[service.galleryImagesIdx]['name']) {
                            return false;
                        } else {
                            curImageIdx += 1;
                        }
                    })

                    return curImageIdx + "/" + imagesOfSameObject.length;
                }
            } else {
                return "";
            }
        }

        function fetchAndSetGalleryImage(dto) {
            // only update if curNomUsageId and curWk are different
            if (!service.curWk || !service.curNomUsageId || !service.curGf || service.curWk !== dto.wk
                || service.curNomUsageId !== dto.nomUsageId || service.curGf !== dto.gf) {
                service.curWk = dto.wk;
                service.curNomUsageId = dto.nomUsageId;
                service.curGf = dto.gf;
                // dto contains wk and nomUsageId
                return $http.get('api/small_building_images/' + dto.nomUsageId + '/' + dto.wk).then(function(response) {
                    if (response.data.length > 0) {
                        var loadedObjs = response.data;
                        if (!!dto.gf && dto.gf > 0) {
                            var loadedObjsFirst = _.filter(loadedObjs, function(o) {
                                return _is_in_tolerance_range(o.obj_gf, Number(dto.gf), 1);
                            });
                            if (loadedObjsFirst.length > 0) {
                                loadedObjs = loadedObjsFirst;
                            } else {
                                var loadedObjsSecond = _.filter(loadedObjs, function(o) {
                                    return _is_in_tolerance_range(o.obj_gf, Number(dto.gf), 1.5);
                                });
                                loadedObjs = loadedObjsSecond;
                            }
                        }
                        service.galleryImagesContainer = _.sortBy(loadedObjs, [function(o) {return o.sortIdx}]);
                        service.galleryImagesIdx = 0;
                        var imageDtoFirst = service.galleryImagesContainer[0];
                        updateDots();
                        if (service.galleryImagesContainer.length > 0) {
                            return prefixUrl() + imageDtoFirst['name'];
                        } else {
                            return defaultUrl();
                        }
                    } else {
                        service.galleryImagesContainer = [];
                        service.galleryImagesIdx = 0;
                        return  defaultUrl();
                    }
                });
            } else {
                // return current image
                if (service.galleryImagesContainer.length > 0) {
                    var imageDto = service.galleryImagesContainer[service.galleryImagesIdx];
                    return $q.when(prefixUrl() + imageDto['name']);
                } else {
                    return $q.when(defaultUrl());
                }

            }

        }

        function moveLeft() {
            if (service.galleryImagesContainer.length > 0) {
                var curIdx = service.galleryImagesIdx;
                var nextIdx = curIdx > 0 ? curIdx-1 : service.galleryImagesContainer.length-1;
                service.galleryImagesIdx = nextIdx;
                updateDots();
                return prefixUrl() + service.galleryImagesContainer[nextIdx]['name'];
            } else {
                return undefined;
            }
        }

        function moveRight() {
            if (service.galleryImagesContainer.length > 0) {
                var curIdx = service.galleryImagesIdx;
                var nextIdx = curIdx < service.galleryImagesContainer.length-1 ? curIdx + 1 : 0;
                service.galleryImagesIdx = nextIdx;
                updateDots();
                return prefixUrl() + service.galleryImagesContainer[nextIdx]['name'];
            } else {
                return undefined;
            }

        }

        function _extractObjName(galleryImageName) {
            var splitted = galleryImageName.split('_');
            return splitted[1];
        }

        function updateDots() {
            if (service.galleryImagesContainer.length > 0) {
                var groupedByObj = _.groupBy(service.galleryImagesContainer, function(o) {return _extractObjName(o['name'])});
                var curGalleryImageName= service.galleryImagesContainer[service.galleryImagesIdx]['name'];
                // for each group add isActive = true or else isActive = false
                _.forOwn(groupedByObj, function(values, key) {
                    var matched = _.filter(values, _.matches({'name': curGalleryImageName}));
                    values['isActive'] = !!matched && matched.length > 0;
                });
                service.dots = groupedByObj;
            } else {
                service.dots = {};
            }

        }

        function moveToDot(galleryImageName) {
            var idxByGalleryImageName = _.findIndex(service.galleryImagesContainer, function(o){return o['name']===galleryImageName});
            service.galleryImagesIdx = idxByGalleryImageName;
            updateDots();
            return prefixUrl() + service.galleryImagesContainer[service.galleryImagesIdx]['name'];
        }

        function clear() {
            service.galleryImagesContainer = [];
            service.galleryImagesIdx = 0;
            service.dots = {};
        }

    }

})();
