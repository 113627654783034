(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .directive('luucyApiKeys', LuucyKeysDirective)
        .controller('luucyApiKeysCtrl', LuucyKeysCtrl);

    LuucyKeysDirective.$inject = [];

    function LuucyKeysDirective() {
        return {
            restrict: 'E',
            replace: 'true',
            scope: {
                keys: '='
            },
            controller: LuucyKeysCtrl,
            controllerAs: 'ivm',
            templateUrl: 'app/admin/organisation-management/luucy_keys/luucy-keys.tpl.html'
        };
    }

    LuucyKeysCtrl.$inject = ['$scope'];

    function LuucyKeysCtrl($scope) {
        var ivm = this;
        ivm.keys = $scope.keys;
    }
})();
