(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildInterventionGradeModificationService', RebuildInterventionGradeModificationService);

    RebuildInterventionGradeModificationService.$inject = ['$http'];

    function RebuildInterventionGradeModificationService($http) {

        function fetch(
            layout_changes_type,
            structural_intervention_type,
            balcony_type,
            elevator_type,
            number_of_current_elevators_inside,
            number_of_new_elevators_inside,
            number_of_current_elevators_outside,
            number_of_new_elevators_outside,
            cancelToken
        ) {
            return $http({
                url: 'https://v2-rebuild-intervention-grade-modification.kennwerte.ch',
                method: 'GET',
                timeout: cancelToken.promise,
                params: {
                    layout_changes_type: layout_changes_type,
                    structural_intervention_type: structural_intervention_type,
                    balcony_type: balcony_type,
                    elevator_type: elevator_type,
                    number_of_current_elevators_inside: number_of_current_elevators_inside,
                    number_of_new_elevators_inside: Math.max(number_of_new_elevators_inside, 0),
                    number_of_current_elevators_outside: number_of_current_elevators_outside,
                    number_of_new_elevators_outside: Math.max(number_of_new_elevators_outside, 0)
                }
            });
        }

        function fetchData(
            layout_changes_type,
            structural_intervention_type,
            balcony_type,
            elevator_type,
            number_of_current_elevators_inside,
            number_of_new_elevators_inside,
            number_of_current_elevators_outside,
            number_of_new_elevators_outside,
            cancelToken,
            fError
        ) {
            fError = fError || function(res) { console.error(res); };
            return this.fetch(
                layout_changes_type,
                structural_intervention_type,
                balcony_type,
                elevator_type,
                number_of_current_elevators_inside,
                number_of_new_elevators_inside,
                number_of_current_elevators_outside,
                number_of_new_elevators_outside,
                cancelToken
            ).then(function(res) { return res.data; }, fError);
        }

        return {
            fetch: fetch,
            fetchData: fetchData
        };
    }

})();
