(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('translationErrorHandler', translationErrorHandler);

    translationErrorHandler.$inject = ['$q', '$log'];

    function translationErrorHandler($q, $log) {
        return handle;

        //dont change arguments otherwise handler will not work!
        function handle(part, lang, response) {
            $log.error('The "' + part + '/' + lang + '" part was not loaded.');
            return $q.when({});
        }
    }

})();
