(function() {
   'use strict';

   angular
       .module('kennwerteApp')
       .factory('LuucyRegisterService', LuucyRegisterService);

   LuucyRegisterService.$inject = ['$http'];

   function LuucyRegisterService($http) {
        var service = {
            getLuucyInit: getLuucyInit,
            checkTemporaryId: checkTemporaryId,
            checkOrgNameAvailable: checkOrgNameAvailable,
            checkEmailAvailable: checkEmailAvailable,
            cancel: cancel,
            assignOrgToLuucy: assignOrgToLuucy,
            registerOrgToLuucy: registerOrgToLuucy
        };

        function checkTemporaryId(checkDto, responseFunction, errorFunction) {
            return $http.post('api/luucy/v2/register/check_temporary_id', checkDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                })
        }

        function checkOrgNameAvailable(checkDto, responseFunction, errorFunction) {
            return $http.post('api/luucy/v2/register/check_org_name_available', checkDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                })
        }

        function checkEmailAvailable(checkDto, responseFunction, errorFunction) {
            return $http.post('api/luucy/v2/register/check_email_available', checkDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                })
        }

        function cancel(checkDto, responseFunction, errorFunction) {
            return $http.post('api/luucy/v2/register/cancel', checkDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                })
        }

        function assignOrgToLuucy(assignOrgToLuucyDto, responseFunction, errorFunction) {
            return $http.post('api/luucy-secured/v2/register/assign_org_luucy', assignOrgToLuucyDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                })
        }

        function registerOrgToLuucy(registerOrgForLuucyRequestDto, responseFunction, errorFunction) {
            return $http.post('api/luucy/v2/register/register_org_luucy', registerOrgForLuucyRequestDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                })
        }

        function getLuucyInit(checkRequestDto, responseFunction, errorFunction) {
            return $http.post('api/luucy/v2/register/luucy_init', checkRequestDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                })
        }

        return service;
   }

})();
