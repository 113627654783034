(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('addInvestmentButton', AddInvestmentButtonDirective)
        .directive('addInvestment', AddInvestmentDirective);

    AddInvestmentButtonDirective.$inject = [];
    function AddInvestmentButtonDirective() {
        return {
            restrict: "E",
            scope: false,
            template: "<button type='button' class='btn btn-elegant-black kw-btn-grid' add-investment ng-disabled='isLastInvestmentEmpty(realEstateContainer) || countInvestments(realEstateContainer) > 2' translate='process-form.addInvestment'>Add Investment</button>"
        };
    }

    AddInvestmentDirective.$inject = ['$rootScope', '$timeout', 'SharedControllerFnService'];

    function AddInvestmentDirective($rootScope, $timeout, SharedControllerFnService) {
        return {
            scope: false,
            link: function (scope, element, attrs) {
                element.bind("click", function () {
                    scope.realEstateContainer.investments.push({});

                    $rootScope.$broadcast("changeInvestments", {});

                    $timeout(function () {
                        scope.$apply();
                        $rootScope.$broadcast("windowSizeChange", {
                            width: $(document).width
                        });
                    }, 100);
                    scope.isLastInvestmentEmpty = function (realEstateContainer) {
                        return SharedControllerFnService.isLastInvestmentEmpty(realEstateContainer);
                    };
                    scope.countInvestments = function (realEstateContainer) {
                        return SharedControllerFnService.countInvestments(realEstateContainer);
                    };
                });
            }
        };
    }

})();
