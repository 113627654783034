(function () {
    'use strict';

    angular.module('kennwerteApp')
    .controller('ImageModalController', ImageModalController)
    .factory('ImageModalService', ImageModalService);

    ImageModalService.$inject = ['$uibModal', '$window'];

    function ImageModalService($uibModal, $window) {
        var service = {
            open: open
        };
        var modalInstance = null;

        var resetModal = function () {
            modalInstance = null;
        };
        return service;

        function open(id, slides, slide, referenceId) {
            if (modalInstance !== null) return;

            modalInstance = $uibModal.open({
                animation: false,
                //don't change this makes the background of the image transparent so during load time there is no
                //white background!
                size: "transparent",
                clickOutsideToClose: true,
                controller: ImageModalController,
                templateUrl: "app/components/image_gallery/image_gallery_dialog/image-modal.html",
                controllerAs: "vm",
                resolve: {
                    mainTranslatePartialLoader: ["$translate", "$translatePartialLoader", function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart("process_form")
                            .addPart("model_reference");
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    slides: function() {
                        return slides;
                    },
                    idx: function() {
                        return id;
                    },
                    referenceId: function() {
                        return referenceId;
                    }
                }
            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );
        }
    }

    ImageModalController.$inject = ["$scope", "$uibModalInstance", "$window", "$timeout", "slides", "idx", "referenceId", "$http", "$document", "FileSaver", "Blob", "imageService"];

    function ImageModalController($scope, $uibModalInstance, $window, $timeout, slides, idx, referenceId, $http, $document, FileSaver, Blob, imageService) {
        var vm = this;
        vm.referenceId = referenceId;
        vm.slides = slides;
        vm.idx = idx;
        vm.clear = clear;

        vm.slide = slides[idx];

        vm.text = vm.slide.text + "." + vm.slide.type;

        vm.ready = false;

        var aspectRatio = $window.innerWidth / $window.innerHeight;

        var heightBorderRatio = 0.065;
        var height = (1-heightBorderRatio)*$window.innerHeight;
        var width = (1-heightBorderRatio/aspectRatio)*$window.innerWidth;

        vm.updateImageSize = function(){
            vm.style = {
                'width': 800 + 'px',
                'height': 800 + 'px',
                // 'margin':'0 auto;'
            };
        }

        if (aspectRatio*vm.slide.height >= vm.slide.width) {
            vm.style = {
                'width': vm.slide.width *  height / vm.slide.height + 'px',
                'height': height + 'px'
            };

        } else {
            vm.style = {
                'width': width + 'px',
                'height': vm.slide.height * width / vm.slide.width + 'px',
                'margin':'0 auto;'
            };
        }
        vm.button = 'imageModalDownloadButtonHeight';

        $timeout(function() {
            $('.modal-dialog').css("width", vm.style.width);
            $('.modal-dialog').css("height", vm.style.height);
            $('.modal').css("overflow-y", "hidden");
            vm.ready = true;
        });

        vm.downloadFile = function () {
            var fileURL = 'original/' + vm.slide.user + '/' + vm.slide.uuid +'.' + vm.slide.type;
            $http({
                method: 'GET',
                url: fileURL,
                responseType: 'arraybuffer'
            }).then(function successCallback(response) {
                var type;
                switch (vm.slide.type) {
                case 'pdf':
                    type = {type: 'application/pdf'};
                    break;

                case 'jpg':
                    type = {type: 'image/jpeg'};
                    break;

                case 'png':
                    type = {type: 'image/png'};
                    break;

                case 'gif':
                    type = {type: 'image/gif'};
                    break;
                }

                var blob = new Blob([response.data], type);
                FileSaver.saveAs(blob, vm.slide.text + "." + vm.slide.type);

            }, function errorCallback(response) {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
                console.log(response);
            });
        };

        vm.makeItAsThumbnailImage = function() {
            console.warn(vm.slide, vm.referenceId);

            imageService.setNewMainImage(
                {
                    realEstateId: vm.referenceId,
                    attachmentId: vm.slide.uuid
                },
                function(res) {
                    console.log(res);
                });
        };

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
    }


})();
