(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('billing', {
            parent: 'account',
            url: '/billing',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.menu.account.billing'
            },
            params: {
                paymentFormSuccess: null,
                paymentMeansSuccess: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/billing/billing.html',
                    controller: 'BillingController',
                    controllerAs: 'vm'
                },
                'free@billing': {
                    templateUrl: 'app/account/billing/substate/billing.free.html'
                },
                'payed@billing': {
                    templateUrl: 'app/account/billing/substate/billing.payed.html'
                },
                'hibernate@billing': {
                    templateUrl: 'app/account/billing/substate/billing.payed.html'
                },
                'payment_unsuccessful@billing': {
                    templateUrl: 'app/account/billing/substate/billing.payment_unsuccessful.html'
                },
                'hibernate_unsuccessful@billing': {
                    templateUrl: 'app/account/billing/substate/billing.payment_unsuccessful.html'
                },
                'invalid@billing': {
                    templateUrl: 'app/account/billing/substate/billing.invalid.html'
                },
                'active_information@billing': {
                    templateUrl: 'app/account/billing/billing.information_active.html',
                    params: {
                        paymentFormSuccess: null,
                        paymentMeansSuccess: null
                    }
                },
                'history@billing': {
                    templateUrl: 'app/account/billing/billing.history.html',
                    controller: 'BillingHistoryController',
                    controllerAs: 'vm'
                },
                'history_free@billing': {
                    templateUrl: 'app/account/billing/billing.history_free.html',
                    controller: 'BillingHistoryController',
                    controllerAs: 'vm'
                },
                'edit_license@billing': {
                    templateUrl: 'app/account/billing/billing.edit_license.html'
                },
                'resign_license@billing': {
                    templateUrl: 'app/account/billing/billing.resign_license.html'
                },
                'buy_license@billing': {
                    templateUrl: 'app/account/billing/billing.buy_license.html',
                    controller: 'BuyLicenseController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('billing');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('prices');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
