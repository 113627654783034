(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope', '$state', 'Auth', 'Principal', 'Account', 'ProfileService', 'LoginService','GlobalModalService', 'LuucyModalService'];

    function NavbarController($rootScope, $state, Auth, Principal, Account, ProfileService, LoginService,GlobalModalService, LuucyModalService) {
        var vm = this;

        vm.isAdminBool = false;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });


        vm.login = login;
        vm.logout = logout;
        vm.openLuucy = openLuucy;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.initShowProductNavElements = initShowProductNavElements;
        vm.isInStateOfAnyModule = isInStateOfAnyModule;
        vm.isInStateBuildingCostsRealestates = isInStateBuildingCostsRealestates;
        vm.isInStateBuildingCostsRealestatesReference = isInStateBuildingCostsRealestatesReference;
        vm.isInStateInsuranceValues = isInStateInsuranceValues;
        vm.isInStateDamageCosts = isInStateDamageCosts;
        vm.isInStateInsuranceValuesRealestates = isInStateInsuranceValuesRealestates;
        vm.isInStateInsuranceValuesRealestatesReference = isInStateInsuranceValuesRealestatesReference;
        vm.showInsuranceValuesFn = showInsuranceValuesFn;
        vm.showSmallBuildingFn = showSmallBuildingFn;
        vm.showDamageCostsFn = showDamageCostsFn;
        vm.isInStateProcessFormBuildingCosts = isInStateProcessFormBuildingCosts;
        vm.isInStateRebuildForm = isInStateRebuildForm;
        vm.isInStateOperatingCostsForm = isInStateOperatingCostsForm;
        vm.isInStateProcessFormInsuranceValues = isInStateProcessFormInsuranceValues;
        vm.isInStateAccuracy = isInStateAccuracy;
        vm.isInStateAccuracyOperatingCosts = isInStateAccuracyOperatingCosts;
        vm.isInStateSmallBuilding = isInStateSmallBuilding;
        vm.isInStateSmallBuildingValues = isInStateSmallBuildingValues;
        vm.correctUiRoute = correctUiRoute;

        vm.contactDTmModal = function(modul) {
            GlobalModalService.contactDtmModal(modul);
        };

        vm.$state = $state;

        $rootScope.$on('updateNavbar', function() {
            vm.initShowProductNavElements();
        });

        $rootScope.$on('authenticationSuccess', function() {
            console.warn('authenticationSuccess');
            vm.initShowProductNavElements();
        });

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            Principal.reset();
            $state.go('products', null, {reload: true});
        }

        function openLuucy() {
            collapseNavbar();
            LuucyModalService.open();
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function initShowProductNavElements() {
            if (vm.isAuthenticated()) {
                adminFn();
                vm.hasAnyInsuranceModule = function() {
                    return Principal.hasAnyInsuranceModule();
                };
                vm.hasAnyBuildingCostsModule = function() {
                    return Principal.hasAnyBuildingCostsModule();
                };
                vm.showBuildingCosts = function() {
                    return Principal.hasModule('BUILDING_COSTS_AND_DATES');
                };
                vm.showInsuranceValues = function() {
                    return Principal.hasModule('BUILDING_INSURANCE_VALUES');
                };
                vm.showOperatingCosts = function() {
                    return Principal.hasModule('OPERATING_COSTS');
                };
                vm.showRebuildCosts = function() {
                    return Principal.hasModule('REBUILD_COSTS');
                };
                vm.showSmallBuilding = function() {
                    return Principal.hasModule('SMALL_BUILDING_AGV');
                };
                vm.showDamageCosts = function() {
                    return Principal.hasModule('DAMAGE_COSTS');
                };
                vm.isAdminFn = function() {
                    return vm.isAdminBool;
                };
            } else {
                vm.showBuildingCosts = function() {
                    return false;
                };
                vm.showInsuranceValues = function() {
                    return false;
                };
                vm.showOperatingCosts = function() {
                    return false;
                };
                vm.showRebuildCosts = function() {
                    return false;
                };
                vm.showSmallBuilding = function() {
                    return false;
                };
                vm.showDamageCosts = function() {
                    return false;
                };
                vm.isAdminFn = function() {
                    return vm.isAdminBool;
                };
            }

        }

        vm.initShowProductNavElements();

        function adminFn() {
            if (vm.isAuthenticated()) {
                Principal.hasAuthority('ROLE_ADMIN').then(function(res) {
                    vm.isAdminBool = res;
                });
            } else {
                vm.isAdminBool = false;
            }
        }

        function isInStateOfAnyModule() {
            return isInStateProcessFormBuildingCosts() || isInStateRebuildForm()
                || isInStateOperatingCostsForm() || isInStateBuildingCostsRealestates()
                || isInStateBuildingCostsRealestatesReference();
        }

        function isInStateOperatingCostsForm() {
            return vm.$state.includes('operational-costs');
        }

        function isInStateProcessFormBuildingCosts() {
            return vm.$state.includes('process-form') && vm.$state.params.withdrawalProductType
                && vm.$state.params.withdrawalProductType === 'building_costs_and_dates';
        }

        function isInStateRebuildForm() {
            return vm.$state.includes('rebuild-form');
        }

        function isInStateBuildingCostsRealestates() {
            return vm.$state.includes('building-costs.realestates');
        }

        function isInStateBuildingCostsRealestatesReference() {
            return vm.$state.includes('building-costs.realestates-reference');
        }

        function isInStateInsuranceValues() {
            return isInStateInsuranceValuesRealestates() || isInStateInsuranceValuesRealestatesReference() || isInStateProcessFormInsuranceValues();
        }
        function isInStateDamageCosts() {
            return isInStateInsuranceValuesRealestates() || isInStateInsuranceValuesRealestatesReference() || isInStateDamageForm();
        }

        function isInStateInsuranceValuesRealestates() {
            return vm.$state.includes('insurance-values.realestates');
        }

        function isInStateInsuranceValuesRealestatesReference() {
            return vm.$state.includes('insurance-values.realestates-reference');
        }
        function isInStateSmallBuilding() {
            return vm.$state.includes('landing_page_agv');
        }
        function isInStateSmallBuildingValues() {
            return vm.$state.includes('small-building-form');
        }

        function showInsuranceValuesFn() {
            return !vm.showInsuranceValues();
        }

        function showSmallBuildingFn() {
            return !vm.showSmallBuilding();
        }

        function showDamageCostsFn() {
            return !vm.showDamageCosts();
        }

        function isInStateProcessFormInsuranceValues() {
            return vm.$state.includes('insurance');
        }

        function isInStateDamageForm() {
            return vm.$state.includes('damage');
        }

        function isInStateAccuracy() {
            return vm.$state.includes('accuracy');
        }

        function isInStateAccuracyOperatingCosts() {
            return vm.$state.includes('accuracy_operating_costs');
        }

        function correctUiRoute(part) {
            vm.collapseNavbar();
            var state = 'products';
            if (Principal.hasModule('SMALL_BUILDING_AGV')) {
                state = 'landing_page_agv';
            }
            vm.$state.go(state, {
                anchor: part
            }, { reload: true });
        }
    }
})();
