(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('BaseLicenseService', BaseLicenseService);

    BaseLicenseService.$inject = ['$resource'];

    function BaseLicenseService($resource) {
        return $resource('api/base_license/:id', {id: '@_id'}, {
            'update': {
                method: 'PUT'
            },
            'access': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'all': {
                url: 'api/base_license/all',
                method: 'GET',
                isArray: true
            },
            'actives': {
                url: 'api/base_license/actives',
                method: 'GET',
                isArray: true
            },
            'deprecated_publics': {
                url: 'api/base_license/deprecated_publics',
                method: 'GET',
                isArray: true
            },
            'privates': {
                url: 'api/base_license/privates',
                method: 'GET',
                isArray: true
            },
            'deprecated_privates': {
                url: 'api/base_license/deprecated_privates',
                method: 'GET',
                isArray: true
            },
            'fetchLicense': {
                url: 'api/base_license/fetch/:numEmployees',
                params: {
                    numEmployees: '@numEmployees'
                },
                method: 'GET'
            },
            'createCustomLicense': {
                url: 'api/base_license/create_custom',
                method: 'POST'
            },
            'fetchOrphanedBaseLicenses': {
                url: 'api/base_license/fetch_orphans',
                method: 'GET',
                isArray: true
            },
            'deleteCustomLicense': {
                url: 'api/base_license/delete_custom/:id',
                params: {
                    id: '@id'
                },
                method: 'DELETE'
            }
        });
    }

})();
