(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('LuucyMgmtService', LuucyMgmtService);

    LuucyMgmtService.$inject = ['$http'];

    function LuucyMgmtService($http) {

        var service = {
            adminKeys: adminKeys,
            keys: keys
        };

        function adminKeys(keysRequestDto, responseFunction, errorFunction) {
            return $http.post('api/luucy-mgmt/admin_keys', keysRequestDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function(response) {
                    return errorFunction(response);
                });
        }

        function keys(keysRequestDto, responseFunction, errorFunction) {
            return $http.post('api/luucy-mgmt/keys', keysRequestDto)
                .then(function(response) {
                    return responseFunction(response);
                }, function(response) {
                    return errorFunction(response);
                });
        }

        return service;

    }

})();
