(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('analysisInput', {
            bindings: {
                reference: '=',
                referenceString: '@?',
                referenceArray: '=?',
                rebuildEstateComponent: '@?',
                realEstate: '=?',
                dictComponentToNum: '=',
                enablePresetFun: '=',
                disablePresetFun: '=',
                rowid: '<?',
                mandatory: '<?',
                required: '<?',
                isDisabled: '=?',
                // validationMessage: '@',
                isLast: '<?',
                isChild: '<?',
                infotext: '@?',
                conditionTranslate:'=?',
                index: '<'
            },
            scope: {
            },
            templateUrl: 'app/components/analysis_input/analysis-input.tpl.html',
            controller: AnalysisInputController
        });

    AnalysisInputController.$inject = ['$scope', 'RebuildComponentSliderService', 'RebuildActionService'];

    function AnalysisInputController($scope, RebuildComponentSliderService, RebuildActionService) {
        var $ctrl = this;

        $ctrl.isFirst = false;

        this.$onInit = function() {
            $ctrl.translateKey = 'rebuild.analysis.elements';
            $ctrl.translateBaseKey = $ctrl.translateKey + '.' + $ctrl.rebuildEstateComponent;
            $ctrl.infoBoxButtonAlternativeKey = $ctrl.translateBaseKey;
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            $ctrl.idForYearField = $ctrl.idForInfoField + '_Year';
            if($ctrl.conditionTranslate == null){
                $ctrl.translateConditionBaseKey = $ctrl.translateKey;
            } else {
                $ctrl.translateConditionBaseKey = $ctrl.conditionTranslate;
            }
            $ctrl.RebuildActionService = RebuildActionService;

            // $ctrl.index change => update parent relationship (are relative to index)
            // $ctrl.dictComponentToNum.c counts the number of elements for the component c, if changed => update parent relationships.
            $scope.$watch('[$ctrl.index,$ctrl.dictComponentToNum[\'' + $ctrl.rebuildEstateComponent + '\']]', function(newValue, oldValue) {
                $ctrl.updateParenting();
                // stop watching old children for changes
                dropCurrentParentResponsibility();
                // start watching new children for changes
                if ($ctrl.reference.parent) {
                    addNewParentResponsibility();
                }

                if (newValue === 1 && oldValue !== 1) {
                    $ctrl.reference.totalMeasure.setInterventionGradeQuantityBackend(100.0);
                }
            });

        };

        $ctrl.isAddComponentValid = function () {
            return $ctrl.dictComponentToNum[$ctrl.rebuildEstateComponent] < 5;
        };

        $ctrl.addComponent = function() {
            $ctrl.disablePresetFun();
            RebuildActionService.addAnalysisComponent($ctrl.rebuildEstateComponent, $ctrl.reference, $ctrl.index);
            $ctrl.enablePresetFun();
        };

        $ctrl.isRemoveComponentValid = function () {
            return $ctrl.dictComponentToNum[$ctrl.rebuildEstateComponent] > 1;
        };

        $ctrl.removeComponent = function() {
            $ctrl.disablePresetFun();
            RebuildActionService.removeAnalysisComponent($ctrl.rebuildEstateComponent, $ctrl.reference, $ctrl.index);
            $ctrl.enablePresetFun();
        };

        $ctrl.showCorrectComponent = function() {
            return $ctrl.doShow();
        };

        $ctrl.doShow = function() {
            return $ctrl.reference.active;
        };

        $ctrl.getLastChildIndex = function(){
            if (!$ctrl.reference.parent) return -1;
            var lastChildIndex = $ctrl.index + 1;
            while($ctrl.referenceArray[lastChildIndex + 1].rebuildEstateComponent === $ctrl.rebuildEstateComponent) lastChildIndex++;
            return lastChildIndex;
        };


        function addNewParentResponsibility() {
            function getChildrenWatch() {
                var lastChildIndex = $ctrl.getLastChildIndex();
                var childrenWatch = [];
                for (var i = $ctrl.index + 1; i <= lastChildIndex; i++) {
                    childrenWatch.push('$ctrl.realEstate.analysis.components[' + i + ']');
                }
                return childrenWatch;

            }

            // start watching new children for changes
            $ctrl.deregisterWatchChildren = $scope.$watch('[' + getChildrenWatch().join(',') + ']', function (newValue, oldValue) {
                if (newValue === undefined) return;
                $ctrl.reference.totalMeasure = combineMeasures(newValue.map(function(c) { return c.totalMeasure; }));
                var combinedConditionValues = combineConditions(newValue);
                $ctrl.reference.condition = combinedConditionValues.condition;
                $ctrl.reference.remainingValueBackend = combinedConditionValues.remainingValueBackend;
                $ctrl.reference.remainingValueInterval = combinedConditionValues.remainingValueInterval;
            }, true);
        };

        function dropCurrentParentResponsibility() {
            if ($ctrl.deregisterWatchChildren != null) {
                $ctrl.deregisterWatchChildren();
                $ctrl.deregisterWatchChildren = null;
            }
        };

        $ctrl.updateParenting = function() {
            var couldBeParent = $ctrl.index + 1 < $ctrl.referenceArray.length && $ctrl.referenceArray[$ctrl.index + 1].rebuildEstateComponent === $ctrl.rebuildEstateComponent;
            var isChild = $ctrl.index > 0 && $ctrl.referenceArray[$ctrl.index - 1].rebuildEstateComponent === $ctrl.rebuildEstateComponent;
            $ctrl.reference.parent = couldBeParent && !isChild;
            $ctrl.isFirst = $ctrl.reference.parent || $ctrl.dictComponentToNum[$ctrl.rebuildEstateComponent] === 1;
        };

        function combineMeasures(measures) {
            var res = new ComponentMeasures();
            var interventionGradeQuantity = measures.reduce(function(acc, measure) { return acc + parseFloat(measure.interventionGradeQuantity); }, 0);
            var interventionGradeQuantityNormalizer = 100 / interventionGradeQuantity;  // we have to normalize to 100% total intervention grade quantity (otherwise it will be applied twice for parent)
            var interventionGrade = measures.reduce(function(acc, measure) { return acc + Math.round(measure.interventionGrade * measure.interventionGradeQuantity / 100); }, 0) * interventionGradeQuantityNormalizer;
            var interval = measures.reduce(function(acc, measure) { return measure.interval.map(function(e, i) { return acc[i] + e * measure.interventionGradeQuantity / 100;}); }, [0, 0, 0, 0, 0, 0, 0])
                .map(function(e) { return e * interventionGradeQuantityNormalizer; });

            res.setInterventionGradeBackend(interventionGrade);
            res.setInterventionGradeQuantityBackend(interventionGradeQuantity);

            res.setMeasureBackend(
                res.getMeasureValuesBasedOnInterventionGrade(interventionGrade),
                interval
            );

            return res;
        }

        function combineConditions(components) {
            var res = {
                condition: undefined,
                remainingValueBackend: undefined,
                remainingValueInterval: undefined
            };
            var interventionGradeQuantityNormalizer = (100 / components.reduce(function(acc, c) { return acc + parseFloat(c.totalMeasure.interventionGradeQuantity); }, 0)) || 0;  // we have to normalize to 100% total intervention grade quantity (otherwise it will be applied twice for parent)
            res.remainingValueBackend = components.reduce(function(acc, c) {
                return acc + c.remainingValueBackend * c.totalMeasure.interventionGradeQuantity / 100;
            }, 0) * interventionGradeQuantityNormalizer;
            res.remainingValueInterval = components.reduce(function(acc, c) { return c.remainingValueInterval.map(function(e, i) { return acc[i] + e * c.totalMeasure.interventionGradeQuantity / 100;}); }, [0, 0, 0, 0, 0, 0, 0])
                .map(function(e) { return e * interventionGradeQuantityNormalizer; });
            res.condition = RebuildComponentSliderService.getConditionClassBasedOnRemainingValue(res.remainingValueBackend);
            return res;
        }

        // elements may be loaded asynchronously
        function areElementsResolved(changes) {
            return changes.index && Number.isInteger(changes.index.currentValue);
        }

        $ctrl.setInterventionGradeQuantity = function(newValue) {
            if (newValue > 100) newValue = 100;
            try {
                $ctrl.reference.totalMeasure.setInterventionGradeQuantityUserInput(newValue);
            } catch (e) {
                console.error(e);
                // Object.setPrototypeOf($ctrl.reference.totalMeasure, ComponentMeasures.prototype);
                // $ctrl.reference.totalMeasure.setInterventionGradeQuantityUserInput(newValue);
            }
        };

        $ctrl.setInterventionGrade = function(newValue) {
            if (newValue && newValue.length > 2 && newValue.search(",") !== -1) {
                newValue = newValue.replace(",", ".");
            }
            newValue = parseFloat(newValue);
            if (newValue || newValue === 0) {
                if (newValue >= 100) newValue = 100;
                $ctrl.reference.totalMeasure.setInterventionGradeUserInput(newValue);
            } else {
                $ctrl.reference.totalMeasure.setInterventionGradeUserInput(null);
            }
        };

        $ctrl.calcTheIntervention = function() {
            if ($ctrl.reference.totalMeasure === undefined) return '%';
            var interventionGrade = _.toNumber($ctrl.reference.totalMeasure.interventionGrade);
            var interventionGradeQ = _.toNumber($ctrl.reference.totalMeasure.interventionGradeQuantity);
            if (interventionGrade === 0 || interventionGradeQ === 0) {
                return '0 %';
            }
            if (Number.isFinite(interventionGrade) && Number.isFinite(interventionGradeQ)) {
                var val = ((interventionGrade / 100) * (interventionGradeQ / 100) * 100);
                if (Math.sign(val) === 1) {
                    if (val < 1) {
                        val = _.round(val, 2);
                    } else {
                        val = _.round(val, 0);
                    }
                    return val + ' %';
                } else {
                    if (Math.sign(val) === -1) {
                        return '0 %';
                    } else {
                        return '%';
                    }
                }
            } else {
                return '%';
            }
        };

    }

})();
