(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('addAdditionButton', AddAdditionButtonDirective)
        .directive('removeAdditionButton', RemoveAdditionButtonDirective)
        .controller('addAddition', AddAdditionCtrl);

    AddAdditionButtonDirective.$inject = [];
    function AddAdditionButtonDirective() {
        return {
            restrict: "E",
            scope: {
                reference: '='
            },
            controller: AddAdditionCtrl,
            controllerAs: 'vm',
            template: "<button type='button' class='btn btn-elegant-black kw-btn-grid' ng-click='vm.onClickAdd()' ng-disabled='vm.isAddDisabled()' translate='process-form.addAddition'>Click to add addition</button>"
        };
    }

    function RemoveAdditionButtonDirective() {
        return {
            restrict: "E",
            scope: {
                reference: '='
            },
            controller: AddAdditionCtrl,
            controllerAs: 'vm',
            template: "<button type='button' class='btn btn-elegant-black kw-btn-grid' ng-click='vm.onClickRemove()' ng-disabled='vm.isRemoveDisabled()' translate='process-form.removeAddition'>Click to remove last addition</button>"
        };
    }

    AddAdditionCtrl.$inject = ['$scope'];
    function AddAdditionCtrl($scope) {
        var vm = this;
        vm.onClickAdd = function () {
            $scope.reference.push({});
        };
        vm.isAddDisabled = function () {
            return $scope.reference && $scope.reference.length >= 15;
        };
        vm.onClickRemove = function() {
            $scope.reference.splice($scope.reference.length - 1, 1);
        };
        vm.isRemoveDisabled = function() {
            return $scope.reference && $scope.reference.length <= 1;
        };
    }

})();
