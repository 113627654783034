(function () {
    'use strict';

    angular.module('kennwerteApp')
    .component('referenceAdditionInput', {
        bindings: {
            reference: '=?',
            rowid: '=',
            translateDescriptionBaseKey: '@',
            translateBaseKey: '@',
            mandatory: '@?',
            placeholder: '@?',
            minlength: '@',
            maxlength: '@',
            baseTabIndex: '=',
            tabGroup: '=',
            validationMessage: '@',
            descriptionBaseKey: '@',
            infotext: '@?',
            disabled: '@?'
        },
        templateUrl: 'app/components/reference_addition_input/reference-addition-input.tpl.html',
        controller: ReferenceAdditionInputController
    });

    ReferenceAdditionInputController.$inject = ['$scope', '$rootScope', '$window'];

    function ReferenceAdditionInputController($scope, $rootScope, $window) {
        var $ctrl = this;

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        $ctrl.onFocus = function (index) {
            $ctrl.hasFocusOutFired = false;
            // Push event to CalculationRowController
            if ($ctrl.reference && $ctrl.reference[index].value !== undefined && $ctrl.reference[index].value !== "" && $ctrl.reference[index].value !== null) {
                var strValueOut = $ctrl.reference[index].value.toString();
                strValueOut = strValueOut.replace(/.-/g, '');
                $ctrl.reference[index].value = strValueOut;
            }

            $ctrl.onFocusOut = function (index) {
                if ($ctrl.hasFocusOutFired) {
                    return;
                }
                $ctrl.hasFocusOutFired = true;
                if ($ctrl.reference && $ctrl.reference[index].value !== undefined && $ctrl.reference[index].value !== "" && $ctrl.reference[index].value !== null) {
                    $ctrl.reference[index].value += ".-";
                }
            };

            var rowid = $scope.rowid;

            $rootScope.$broadcast("updateImage", {
                imageSrc: $scope.imageSrc,
                rowid: rowid
            });
            $rootScope.$broadcast("updateDescription", {
                rowid: $scope.rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $scope.descriptionBaseKey,
                dynamicTranslations: false
            });
        };

        $ctrl.addEntry = function () {
            $ctrl.reference.push({});
        };

        $ctrl.removeLastEntry = function () {
            $ctrl.reference.pop();
        };

        $ctrl.disabledRemove = function () {
            return $ctrl.reference && $ctrl.reference.length <= 1;
        };

        $ctrl.disableAddEntry = function () {
            return $ctrl.reference && $ctrl.reference.length >= 15;
        };

        $ctrl.getPlaceholder = function (index) {
            console.log('test', index);
            return null;
        };


        $ctrl.onKeyUp = function (index) {
            try {
                if ($ctrl.reference && $ctrl.reference[index].value !== undefined && $ctrl.reference[index].value !== "" && $ctrl.reference[index].value !== null) {
                    var strValue = $ctrl.reference[index].value.toString();
                    var isMinus = startsWith(strValue, '-');
                    strValue = strValue.replace(/\D/g, '');
                    strValue = strValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
                    if (isMinus) {
                        strValue = '-' + strValue;
                    }
                    $ctrl.reference[index].value = strValue;
                }
            } catch (err) {
                console.log(err);
            }
        };

        var startsWith = function (str, prefix) {
            return str.substr(0, prefix.length) === prefix;
        };

        $scope.$watch('$ctrl.reference', function (newValue) {
            if (newValue) {
                $ctrl.reference = newValue;
                while ($ctrl.reference.length < 3) {
                    $ctrl.reference.push({});
                }
            }
        });


        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.translateButton = $ctrl.translateBaseKey + '.button';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            if (angular.isUndefined($ctrl.reference)) {
                $ctrl.reference = {};
            }

        }
        ;
    }
})
();
