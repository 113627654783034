(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('ProductsController', ProductsController);

    ProductsController.$inject = ['$scope', '$stateParams', 'Principal', '$sce', '$location', '$anchorScroll', 'BillingModalService'];

    function ProductsController($scope, $stateParams, Principal, $sce, $location, $anchorScroll, BillingModalService) {
        var vm = this;

        vm.isAuthenticated = false;
        vm.showBuildingCosts = true;
        vm.showInsuranceValues = false;
        vm.isAdmin = false;

        if ($stateParams.anchor) {
            $location.hash($stateParams.anchor);
            $anchorScroll.yOffset = 66;
            $anchorScroll();
        }

        function updateRightFlags() {
            vm.showBuildingCosts = Principal.hasAnyBuildingCostsModule();
            vm.showInsuranceValues = Principal.hasAnyInsuranceModule();
            vm.hasBuildingCosts = Principal.hasModule("BUILDING_COSTS_AND_DATES");
            vm.hasOperationalCosts = Principal.hasModule('OPERATING_COSTS');
            vm.hasRebuildCosts = Principal.hasModule('REBUILD_COSTS');
        }

        vm.isAuthenticated = Principal.isAuthenticated();
        if (vm.isAuthenticated) {
            updateRightFlags();
        }

        $scope.$on('authenticationSuccess', function () {
            vm.showBuildingCosts = Principal.hasAnyBuildingCostsModule();
            vm.showInsuranceValues = Principal.hasAnyInsuranceModule();
            vm.hasBuildingCosts = Principal.hasModule('BUILDING_COSTS_AND_DATES');
            vm.hasOperationalCosts = Principal.hasModule('OPERATING_COSTS');
            vm.hasRebuildCosts = Principal.hasModule('REBUILD_COSTS');

        });

        function updateRightFlagsFromServer() {
            Principal.identity(true); // force refresh of rights
            updateRightFlags();
        }

        vm.openBillingModalForBuild = function() {
            BillingModalService.openBillingModal(updateRightFlagsFromServer, ['BUILDING_COSTS_AND_DATES']);
        };

        vm.openBillingModalForBewk = function() {
            BillingModalService.openBillingModal(updateRightFlagsFromServer, ['OPERATING_COSTS']);
        };

        vm.openBillingModalForRebuild = function() {
            BillingModalService.openBillingModal(updateRightFlagsFromServer, ['REBUILD_COSTS']);
        };

    }

})();


