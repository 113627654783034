(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('rebuildCostsTable', RebuildCostsTableDirective);

    RebuildCostsTableDirective.$inject = ['$rootScope'];

    function RebuildCostsTableDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                output : '=',
            },
            templateUrl: 'app/components/outputs/rebuild_costs_table/rebuild_costs_table.tpl.html',
            controller: RebuildCostsTableDirectiveController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
            }
        };
    }

    RebuildCostsTableDirectiveController.$inject = ['$scope'];

    function RebuildCostsTableDirectiveController($scope) {
        var vm = this;
        vm.output = $scope.output;
    }

})();
