(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('accuracy', {
                parent: 'app',
                url: '/info',
                data: {
                    authorities: [],
                    pageTitle: 'info.pageTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/accuracy/accuracy.html',
                        controller: 'AccuracyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('accuracy')
                            .addPart('seo')
                            .addPart('prices')
                            .addPart('process_form')
                            .addPart('model_realEstate');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    metaTags: ['MetaTagService', function(MetaTagService) {
                        MetaTagService.setTags({
                            'description': 'Die innovative Lösung zur Berechnung von für Baukosten. Für Einfamilien- &  Mehrfamilienhäuser. Schnell die benötigte Schätzgenauigkeit erzielen. 7 Tage gratis testen.',
                            'Keywords': 'Baukostenschätzung & Baukostenberechnung',
                            // OpenGraph
                            'og:type': 'website',
                            'og:title': 'kennwerte',
                            'og:description': 'Die innovative Lösung zur Baukostenschätzung und Baukostenberechnung: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
                            // Twitter
                            'twitter:card': 'summary_large_image',
                            'twitter:site': '@kennwerte',
                            'twitter:title': 'kennwerte',
                            'twitter:description': 'Die innovative Lösung zur Baukostenschätzung und Baukostenberechnung: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!'
                        });
                    }]
                }
            });
    }

})();
