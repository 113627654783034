(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('PaymentService', PaymentService);

    PaymentService.$inject = ['$resource'];

    function PaymentService($resource) {
        return $resource('api/payment/:id', {id: '@_id'}, {
            'query': {
                method: 'GET', isArray: true
            },
            'adminQuery': {
                method: 'GET',
                url: 'api/payment/admin/:orgId',
                isArray: true
            },
            'adminUpdate': {
                method: 'PUT',
                url: 'api/payment/admin'
            },
            'adminDelete': {
                method: 'DELETE',
                url: 'api/payment/admin/:id',
                params: {id: '@id'}
            },
            'adminCopyAsNewPayment': {
                method: 'POST',
                url: 'api/payment/admin/copy_as_new_payment'
            },
            'isEligibleForCreatingInvoicePayment': {
                method: 'GET',
                url: 'api/payment/admin/is_eligible_create_invoice_payment/:id',
                transformResponse: function(data, headersGetter, status) {
                    return {content: data, status: status};
                }
            },
            'isEligibleForUpdatingLatestInvoicePayment': {
                method: 'GET',
                url: 'api/payment/admin/is_eligible_update_latest_invoice_payment/:id',
                transformResponse: function(data, headersGetter, status) {
                    return {content: data, status: status};
                }
            },
            'createInvoicePayment': {
                method: 'POST',
                url: 'api/payment/admin/create_invoice_payment/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'updateLatestInvoicePayment': {
                method: 'POST',
                url: 'api/payment/admin/update_latest_invoice_payment/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'buySpecialOffer': {
                method: 'POST',
                url: 'api/payment/admin/buy_special_offer/:id',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'forceSendPaymentEmail': {
                method: 'POST',
                url: 'api/payment/admin/force_send_payment_email/:paymentId',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'isPaymentDateInLastQuarter': {
                method: 'GET',
                url: 'api/payment/admin/is_payment_date_in_last_quarter/:paymentId',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }

})();
