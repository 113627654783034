(function () {
    'use strict';
    angular.module('kennwerteApp')
        .factory('HeatingTypeDataService', HeatingTypeDataService);

    HeatingTypeDataService.$inject = [];

    function HeatingTypeDataService() {

        var service = {};

        service.BASE_GROUP = 'Wärmeerzeugung';
        service.SHARED_GROUP = 'Bezug';
        service.EXTRA_GROUP = 'Zusätzlich';

        service.NO_HEATING                 = {"group": service.BASE_GROUP,   "value": "NO_HEATING"};
        service.CONTRACTING                = {"group": service.BASE_GROUP,   "value": "CONTRACTING"};
        service.DISTRICT_HEATING           = {"group": service.BASE_GROUP,   "value": "DISTRICT_HEATING"};
        service.NATURAL_GAS                = {"group": service.BASE_GROUP,   "value": "NATURAL_GAS"};
        service.OIL                        = {"group": service.BASE_GROUP,   "value": "OIL"};
        service.PELLET                     = {"group": service.BASE_GROUP,   "value": "PELLET"};
        service.WOOD_CHIPS                 = {"group": service.BASE_GROUP,   "value": "WOOD_CHIPS"};
        service.HEAT_PUMP_AIR              = {"group": service.BASE_GROUP,   "value": "HEAT_PUMP_AIR"};
        service.HEAT_PUMP_GEOTHERMAL       = {"group": service.BASE_GROUP,   "value": "HEAT_PUMP_GEOTHERMAL"};
        service.HEAT_PUMP_GROUND_COLLECTOR = {"group": service.BASE_GROUP,   "value": "HEAT_PUMP_GROUND_COLLECTOR"};
        service.HEAT_PUMP_GROUND_WATER     = {"group": service.BASE_GROUP,   "value": "HEAT_PUMP_GROUND_WATER"};
        service.OTHER                      = {"group": service.BASE_GROUP,   "value": "OTHER"};
        service.SOLAR_COLLECTORS_THERMAL   = {"group": service.EXTRA_GROUP,  "value": "SOLAR_COLLECTORS_THERMAL"};

        service.SHARED_ANNEX               = {"group": service.SHARED_GROUP, "value": "SHARED_ANNEX"};
        service.SHARED_ADDITION_STORY      = {"group": service.SHARED_GROUP, "value": "SHARED_ADDITION_STORY"};
        service.SHARED_OVERHAUL            = {"group": service.SHARED_GROUP, "value": "SHARED_OVERHAUL"};

        var baseHeatingTypes = [
            service.NO_HEATING,
            service.CONTRACTING,
            service.DISTRICT_HEATING,
            service.NATURAL_GAS,
            service.OIL,
            service.PELLET,
            service.WOOD_CHIPS,
            service.HEAT_PUMP_AIR,
            service.HEAT_PUMP_GEOTHERMAL,
            service.HEAT_PUMP_GROUND_COLLECTOR,
            service.HEAT_PUMP_GROUND_WATER
        ];

        var extras = [
            service.SOLAR_COLLECTORS_THERMAL
        ];

        var shared = [
            service.SHARED_ANNEX,
            service.SHARED_ADDITION_STORY,
            service.SHARED_OVERHAUL
        ];

        service.default = baseHeatingTypes;
        service.extras = extras;
        service.rebuildIs = baseHeatingTypes.concat([service.OTHER]);
        service.rebuildTarget = shared.concat(baseHeatingTypes);

        return service;
    }


})();
