// remove \' character at the first and last index, so the url works properly, useful in directive's link functions
var sanitizeUrl = function (url) {
    return url.substring(1, url.length - 1);
};

(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('InsuranceFormController', InsuranceFormController)
        .filter('startFrom', StartFromFilter);

    InsuranceFormController.$inject = ['$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$http',
        '$location',
        '$timeout',
        'Principal',
        'RealestateService',
        '$window',
        '$q',
        '$sessionStorage',
        'UsageSliderMappingService',
        'SliderPresetService',
        'ValidationDataService',
        'RoomHeightLimitsWarningService'
    ];

    function InsuranceFormController($rootScope,
                                   $scope,
                                   $state,
                                   $stateParams,
                                   $http,
                                   $location,
                                   $timeout,
                                   Principal,
                                   RealestateService,
                                   $window,
                                   $q,
                                   $sessionStorage,
                                   UsageSliderMappingService,
                                   SliderPresetService,
                                   ValidationDataService,
                                   RoomHeightLimitsWarningService
    ) {

        $scope.validationDataService = ValidationDataService;

        $scope.dataHasLoaded = false;

        $scope.isPresetSliderModelValuesEnabled = isCurrentStateInput() && !$stateParams.realEstate && !$stateParams.realEstateId;


        function isCurrentStateInput () {
            return $state.includes('insurance.input');
        }

        function setCorrectSliderModelValues(usages) {
            var correctUsages = SliderPresetService.correctUsages(usages);
            var weightedArr = SliderPresetService.getWeightedPresetValues(correctUsages, $scope.realEstateContainer);
            if (angular.isDefined(weightedArr)) {
                var weightedIntervalArr = SliderPresetService.getWeightedPresetIntervals(correctUsages);
                SliderPresetService.setSliderModelValuesArr(weightedArr, weightedIntervalArr, $scope.realEstateContainer, $scope.isPresetSliderModelValuesEnabled);
            }
        }

        $scope.$watch('realEstateContainer.usages', function (usages, oldValue) {
            if (!ValidationDataService.getIsCalculating()) {
                setCorrectSliderModelValues(usages);
                RoomHeightLimitsWarningService.showWarningIfOutOfLimit($scope.realEstateContainer);
            }
        }, true);

        $scope.$on('requestPresetMark', function () {
            setCorrectSliderModelValues($scope.realEstateContainer.usages);
        });

        $scope.isCurrentStateEditRecord = function () {
            return $state.includes('insurance.edit-record');
        };

        var init = function () {
            // initialize some variables that have to be shared in all child states of process-form.
            if ($scope.isCurrentStateEditRecord() && !!$sessionStorage.output && $sessionStorage.output.realEstateId === $sessionStorage.realEstateContainer.id) {
                $scope.validationDataService.setIsSuccess(true);
            }

            // holds the real estate
            $scope.realEstateContainer = {};

            $scope.formTabGroup = "0";

            // $scope.isPresetSliderModelValuesEnabled = $scope.isCurrentStateInput() && !$stateParams.realEstate && !$stateParams.realEstateId;
            $scope.loadRealEstate($stateParams.realEstateId, $stateParams.realEstate)
                .then(function (realEstateContainer) {
                    $scope.realEstateContainer = realEstateContainer;
                    $sessionStorage.realEstateContainer = realEstateContainer;

                    $scope.estimations = '';

                    $scope.dataHasLoaded = true;

                    $scope.hasRealEstateParent = $scope.hasRealEstateParent();
                });
        };

        $scope.isCurrentStateLoggedInOrNotLoggedIn = function () {
            return $state.current.name == 'process-form.not-logged-in' || $state.current.name == 'process-form.logged-in';
        };

        $scope.endsWith = function (str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        };

        $scope.loadRealEstate = function (realEstateIdByStateParam, realEstateByStateParam) {
            if (realEstateIdByStateParam && realEstateByStateParam) {
                console.error("Illegal state, requested both realEstateIdByStateParam and realEstateByStateParam");
                $state.go('error');
            }
            // load the real estate, either defined by state param realEstateId or realEstate
            if (realEstateIdByStateParam !== null && $scope.isAuthenticated) {
                if ($scope.isCurrentStateEditRecord()) {
                    // create a copy of the real estate given the id
                    return RealestateService.api.get({id: realEstateIdByStateParam},
                        angular.identity,
                        function (error) {
                            console.error("Error while trying to retrieve real estate with id " + realEstateIdByStateParam, error);
                            $state.go('error');
                        }
                    ).$promise.then(function (realestate) {
                        return postProcessForEditRecordById(realestate);
                    });
                } else {
                    return RealestateService.api.get({id: realEstateIdByStateParam},
                        angular.identity,
                        function (error) {
                            console.error("Error while trying to retrieve real estate with id " + realEstateIdByStateParam, error);
                            $state.go('error');
                        }
                    ).$promise;
                }
            } else {
                var r = null;
                if (realEstateByStateParam) {
                    return $q(function (resolve) {
                        r = postProcessStateParamRealEstate(realEstateByStateParam);
                        resolve(r);
                    });
                } else if (!isCurrentStateInput()) {
                    // load from session storage
                    r = $sessionStorage.realEstateContainer;
                    if (angular.isUndefined(r)) {
                        $state.go('products', null, {reload: true});
                    }
                }
                if (r !== null && angular.isDefined(r)) {
                    return $q(function (resolve) {
                        // TODO: set values according to loaded realestate
                        r = SliderPresetService.setDefaultSliderModelValues(r);
                        resolve(r);
                    });
                } else {
                    return $scope.createNewRealEstate();
                }
            }
        };

        /**
         * Helper to prepare an empty real estate. Initializes empty properties on the real estate such as arrays.
         * @param realEstate
         */
        function prepareEmpty(realEstate) {
            realEstate.usages.push({ type: null, percentage: null, standard: null });
            realEstate.investments.push({});
            realEstate.additions.push({});
            return realEstate;
        }

        /**
         * Helper to postprocess a provided real estate. Initializes empty properties on the real estate such as arrays.
         * @param realEstate real estate object
         */
        function postProcessStateParamRealEstate(realEstate) {
            if (!realEstate.quality) {
                realEstate.quality = {};
            }
            if (!realEstate.pom) {
                realEstate.pom = {};
            }
            if (!realEstate.lcc) {
                realEstate.lcc = {};
            }
            if (!realEstate.geometry) {
                realEstate.geometry = {};
            }
            if (!realEstate.usages) {
                realEstate.usages = [];
                realEstate.usages.push({ type: null, percentage: null, standard: null });
            }
            if (!realEstate.location) {
                realEstate.location = {};
            }
            if (!realEstate.metaData) {
                realEstate.metaData = {};
            }
            if (!realEstate.additions || realEstate.additions.length === 0) {
                realEstate.additions = [];
                realEstate.additions.push({});
            }
            if (!realEstate.investments || realEstate.investments.length === 0) {
                realEstate.investments = [];
                realEstate.investments.push({});
            }
            realEstate.metaData.withdrawalProductType = 'BUILDING_INSURANCE_VALUES';
            realEstate.metaData.tasks = [];
            realEstate.metaData.tasks.push(realEstate.metaData.withdrawalProductType);
            realEstate.metaData.parentRealEstateId = realEstate.metaData && realEstate.metaData.parentRealEstateId ?
                realEstate.metaData.parentRealEstateId : realEstate.id;
            realEstate.id = null;
            return realEstate;
        }

        /**
         * Helper to postprocess a provided real estate for edit. Initializes empty properties on the real estate such as arrays.
         * @param realEstate real estate object
         * @returns {*}
         */
        function postProcessForEditRecordById(realEstate) {
            realEstate.metaData.parentRealEstateId = realEstate.metaData.parentRealEstateId ?
                realEstate.metaData.parentRealEstateId : realEstate.id;
            if ($scope.endsWith(realEstate.metaData.name, ")")) {
                var brBegin = realEstate.metaData.name.lastIndexOf("(");
                if (brBegin >= 0) {
                    var txtBetweenBr = realEstate.metaData.name.substring(brBegin + 1, realEstate.metaData.name.length - 1);
                    var parsedNumBetweenBr = parseInt(txtBetweenBr);
                    if (parsedNumBetweenBr) {
                        realEstate.metaData.name = realEstate.metaData.name.substring(0, brBegin - 1) + " (" + (realEstate.metaData.numEstimations + 1) + ")";
                    }
                }
            } else {
                realEstate.metaData.name = realEstate.metaData.name + " (" + (realEstate.metaData.numEstimations + 1) + ")";
            }
            realEstate.id = null;
            if (realEstate.additions.length === 0) {
                realEstate.additions = [];
                realEstate.additions.push({});
            }
            if (realEstate.investments.length === 0) {
                realEstate.investments = [];
                realEstate.investments.push({});
            }
            return realEstate;
        }

        $scope.createNewRealEstate = function () {
            var withdrawalProductType = 'BUILDING_INSURANCE_VALUES';
            var newRealEstateDto = {
                "parentRealEstateId": null,
                "name": null, // we should load this value from translation table
                "withdrawalProductType": withdrawalProductType
            };
            return RealestateService.createEmptyRealEstate(newRealEstateDto)
                .then(function(realestate) {
                    prepareEmpty(realestate);
                    return SliderPresetService.setDefaultSliderModelValues(realestate);
                });
        };

        $scope.isInStateBuildingCosts = function () {
            return realEstate.metaData.withdrawalProductType === 'BUILDING_COSTS_AND_DATES';
        };

        $scope.hasRealEstateParent = function () {
            return angular.isDefined($scope.realEstateContainer.metaData) && $scope.realEstateContainer.metaData.parentRealEstateId != null;
        };

        $scope.usageSliderMapping = UsageSliderMappingService.getMapping();


        // waits for angularjs digest cycle http://tech.endeepak.com/blog/2014/05/03/waiting-for-angularjs-digest-cycle/
        var waitForRender = function (callbackFun) {
            if ($http.pendingRequests.length > 0) {
                $timeout(waitForRender(callbackFun));
            } else {
                callbackFun();
            }
        };

        $scope.showDiv = function (divId) {
            return $state.current.data.showDivs.indexOf(divId) > -1;
        };

        // Recognize change in window size
        var w = angular.element($window);
        $scope.$watch(
            function () {
                return $window.innerWidth;
            },
            function (value) {
                $rootScope.$broadcast("windowSizeChange", {
                    width: value
                });
                //$scope.windowWidth = value;
            },
            true
        );
        w.bind('resize', function () {
            $scope.$apply();
        });

        $scope.$on('authenticationSuccess', function () {
            Principal.identity().then(function (account) {
                $scope.account = account;
                $scope.isAuthenticated = Principal.isAuthenticated();
            });
        });

        // Call init at the end that all methods are available by then
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated();
        }).then(function () {
            init();
        });
    }

    StartFromFilter.$inject = [];

    function StartFromFilter() {
        return function (input, start) {
            if (input) {
                start = +start; //parse to int
                return input.slice(start);
            }
            return [];
        };
    }


})();
