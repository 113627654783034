function showDetailInfo(index) {
    var n = index.length;
    var toToggle = '#' + index.substring(0, n - 2);
    $(toToggle).toggle();
}

(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$urlRouterProvider'];

    function stateConfig($stateProvider,$urlRouterProvider) {
        $urlRouterProvider.otherwise("/");
        $stateProvider
            .state('small-building-form', {
                parent: 'app',
                abstract: true,
                url: '/agv-small-building-form',
                views: {
                    // Fill 'ui-view="content"' of 'index.html'
                    'content@': {
                        templateUrl: "app/small_building_agv_insurance_form/insurance_agv_small_building_form.html",
                        controller: 'InsuranceAgvSmallBuildingFormController',
                        controllerAs: 'vm'
                    },
                    'input@small-building-form': {
                        templateUrl: 'app/small_building_agv_insurance_form/10_small_building_agv_insurance_input/input.html',
                        controller: 'InputAgvController',
                        controllerAs: 'vm'
                    },
                    'calculating_error@small-building-form': {
                        templateUrl: 'app/small_building_agv_insurance_form/20_insurance_agv_small_building_output/10_insurance_agv_small_building_calculating_error/10_insurance_agv_small_building_calculating_error.html',
                        controller: 'SmallBuildingAgvInsuranceCalculatingErrorController',
                        controllerAs: 'vm'
                    },
                    'calculating_success@small-building-form': {
                        templateUrl: 'app/small_building_agv_insurance_form/20_insurance_agv_small_building_output/11_insurance_agv_small_building_calculating_success/11_insurance_agv_small_building_calculating_success.html',
                        controller: 'SmallBuildingAgvInsuranceCalculatingSuccessController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    data: null,
                    withdrawalProductType: null,
                    realEstateId: null,
                    realEstate: null
                },
                //controller: 'InsuranceAgvSmallBuildingFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.building-costs.input'
                },
                resolve: {
                    resetIsCompleteState: ['ValidationDataService', function(ValidationDataService) {
                        ValidationDataService.resetComplete();
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form');
                        $translatePartialLoader.addPart('realestates');
                        $translatePartialLoader.addPart('pom');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('small-building-form.input', {
                url: '/input/{withdrawalProductType}',
                //controller: 'InsuranceAgvSmallBuildingFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.building-costs.input',
                    showDivs: [
                        // Group
                        '10_insurance_insurance_input',
                        // Children
                        '10_small_building_agv_insurance_howto_section',
                        '20_small_building_agv_insurance_general_form',
                        '30_small_building_agv_insurance_quality_form',
                        '31_small_building_agv_insurance_costclass_form',
                        '40_small_building_agv_insurance_p_value_form',
                        '50_small_building_agv_insurance_complete_input'
                    ]
                },

                resolve: {
                    check: ['$state', 'Principal', function ($state, Principal) {
                        Principal.identity().then(function(e) {
                            if (!!e && !Principal.hasModule('SMALL_BUILDING_AGV')) {
                                $state.go('insufficient-rights.insurance-values');
                            }
                        }).catch(function(e){console.warn(e)});
                    }],
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e){console.warn(e)});
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form');
                        $translatePartialLoader.addPart('model_realEstate');
                        $translatePartialLoader.addPart('model_geometry');
                        $translatePartialLoader.addPart('model_agv');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('small-building-form.edit', {
                url: '/edit',
                //controller: 'InsuranceAgvSmallBuildingFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'],
                    pageTitle: 'global.menu.building-costs.edit',
                    showDivs: [
                        // Group
                        '10_insurance_insurance_input',
                        // Children
                        '10_small_building_agv_insurance_howto_section',
                        '20_small_building_agv_insurance_general_form',
                        '30_small_building_agv_insurance_quality_form',
                        '31_small_building_agv_insurance_costclass_form',
                        '40_small_building_agv_insurance_p_value_form',
                        '50_small_building_agv_insurance_complete_input'
                    ]
                },
                resolve: {
                    check: ['$state', 'Principal', function($state, Principal) {
                        Principal.identity().then(function(e) {
                            if (!!e && !Principal.hasModule('SMALL_BUILDING_AGV')) {
                                $state.go('insufficient-rights.insurance-values');
                            }
                        }).catch(function(e){console.warn(e)});
                    }],
                    checkLicense: ['$state', 'BillingService', function($state, BillingService) {
                        BillingService.licenseState()
                            .then(function(res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e){console.warn(e)});
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('process_form')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('model_agv');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            });
    }
})();
