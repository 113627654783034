(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('compactSelectCustomValueInput', CompactSelectCustomValueInputDirective);

    CompactSelectCustomValueInputCtr.$inject = ['$scope', '$rootScope'];

    function CompactSelectCustomValueInputCtr($scope, $rootScope) {

        $scope.firstLoad = true;

        var vm = this;

        vm.model = {};
        vm.answers = ['yes', 'no'];
        // use polyfills for IE, TODO: Introduce a proper place for polyfills
        Number.isInteger = Number.isInteger || function(value) {
            return typeof value === 'number' &&
                isFinite(value) &&
                Math.floor(value) === value;
        };
        if ($scope.reference != null) {
            if (angular.isDefined($scope.customValueTrue) && angular.isDefined($scope.customValueFalse)) {
                if ($scope.reference === $scope.customValueTrue) {
                    vm.model = vm.answers[0];
                } else {
                    vm.model = vm.answers[1];
                }
            } else {
                console.error('missing custom values.');
            }
        }

        vm.isEmptyModel = function() {
            return angular.equals({}, vm.model);
        };

        vm.translateKey = function(answer) {
            return 'global.' + answer;
        };

        vm.onOpenClose = function(isOpen) {
            if (isOpen) {
                $rootScope.$broadcast('updateDescription', {
                    rowid: $scope.rowid,
                    descriptionBaseKey: $scope.descriptionBaseKey
                });
                $rootScope.$broadcast('updateImage', {
                    rowid: $scope.rowid,
                    imageSrc: $scope.image
                });
            }
        };

        $scope.$watch('vm.model', function(selectedItem) {
            if (!angular.equals({}, selectedItem)) {
                if ($scope.firstLoad) {
                    $rootScope.$broadcast('updateImage', {
                        rowid: $scope.rowid,
                        imageSrc: $scope.image
                    });
                    var descrBkey = $scope.descriptionBaseKey + '.';
                    descrBkey += selectedItem === 'yes' ? $scope.customValueTrue : $scope.customValueFalse;
                    $rootScope.$broadcast('updateDescription', {
                        rowid: $scope.rowid,
                        descriptionNumber: selectedItem,
                        descriptionBaseKey: descrBkey
                    });
                }

                if (selectedItem === 'yes') {
                    $scope.reference = $scope.customValueTrue;
                } else {
                    $scope.reference = $scope.customValueFalse;
                }
            }
        });

        $scope.$watch('reference', function(newValue, oldValue) {
            if (newValue == null) {
                vm.model = {};
            } else if (newValue != oldValue) {
                if ($scope.reference === $scope.customValueTrue) {
                    vm.model = vm.answers[0];
                } else {
                    vm.model = vm.answers[1];
                }
            }
        });

    }

    CompactSelectCustomValueInputDirective.$inject = ['$rootScope'];

    function CompactSelectCustomValueInputDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                customValueTrue: '=',
                customValueFalse: '=',
                realEstateContainer: '=',
                rowid: '=', // used to set the id of the row-image
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                classes: '@?'
            },
            controller: CompactSelectCustomValueInputCtr,
            templateUrl: 'app/components/compact_inputs/compact_select_custom_value/compact-select-input.tpl.html',
            controllerAs: 'vm',
            link: function(scope, tElement, tAttrs) {

                // Used for the validation
                var toggle = tElement.find('.compactSelectInput');
                $(toggle).attr('id', tAttrs.reference + '.drop2down-toggle');
            }
        };
    }

})();
