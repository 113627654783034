(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('BreakfastRoadshowController', BreakfastRoadshowController);

    BreakfastRoadshowController.$inject = ['$scope', '$element', 'BreakfastRoadshowService', 'GTMService', '$timeout', '$window'];

    function BreakfastRoadshowController($scope, $element, BreakfastRoadshowService, GTMService, $timeout, $window) {
        var vm = this;

        vm.participant = null;

        vm.errorMissing = false;
        vm.isError = false;
        vm.isSuccess = false;

        vm.hasClicked = false;
        var origWidth = 1920;
        var origHeight = 1081;

        //var imgHeight = 1081;
        var w = Math.max(origWidth, $window.innerWidth);
        var h = w > origWidth ? w/origWidth*origHeight : origHeight;

        vm.registerParticipant = registerParticipant;
        vm.height = heightValueFun() + 'px';


        vm.onResize = function () {
            $timeout(function() {
                vm.height = heightValueFun() + 'px';
            });
        };

        function heightValueFun() {
            var imgWidth = $('.bannerImg').width();
            var wRatio = imgWidth / origWidth;
            return origHeight * wRatio;
        }

        function registerParticipant() {
            if (vm.form.$invalid) {
                angular.forEach(vm.form.$error.required, function (field) {
                    field.$setDirty();
                });
                setMissing();
                GTMService.trackEvent({
                    action: 'breakfast-roadshow.register-missing',
                    category: 'breakfast-roadshow',
                    label: vm.participant.email
                });
            } else {
                GTMService.trackEvent({
                    action: 'breakfast-roadshow.register-try',
                    category: 'breakfast-roadshow',
                    label: vm.participant.email
                });
                vm.hasClicked = true;
                BreakfastRoadshowService.registerParticipant(
                    vm.participant,
                    function (response) {
                        setSuccess();
                        GTMService.trackEvent({
                            action: 'breakfast-roadshow.register-success',
                            category: 'breakfast-roadshow',
                            label: vm.participant.email
                        });
                    },
                    function (errorResponse) {
                        setError();
                        vm.hasClicked = false;
                        GTMService.trackEvent({
                            action: 'breakfast-roadshow.register-fail',
                            category: 'breakfast-roadshow',
                            label: vm.participant.email
                        });
                    });
            }
        }

        function setSuccess() {
            vm.isSuccess = true;
            vm.isError = false;
            vm.errorMissing = false;
        }

        function setError() {
            vm.isSuccess = false;
            vm.isError = true;
            vm.errorMissing = false;
        }

        function setMissing() {
            vm.isSuccess = false;
            vm.isError = false;
            vm.errorMissing = true;
        }

    }


})();
