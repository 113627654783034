(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('landing_page_pom', {
                parent: 'app',
                url: '/pom',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/products/products.html',
                        controller: 'ProductsController',
                        controllerAs: 'vm'
                    },
                    'register@landing_page_pom': {
                        templateUrl: 'app/account/register_inner/register-inner.html',
                        controller: 'RegisterInnerController',
                        controllerAs: 'vm'
                    },
                    'about_us@landing_page_pom':{
                        templateUrl: 'app/website_fragments/about_us/about_us.html'
                    },
                    'advisory_board@landing_page_pom':{
                        templateUrl: 'app/website_fragments/advisory_board/advisory_board.html'

                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('products')
                            .addPart('register')
                            .addPart('settings');

                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            });
    }

})();
