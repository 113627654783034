(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('AccuracyService', AccuracyService);

    AccuracyService.$inject = ['$rootScope', '$http'];

    function AccuracyService($rootScope, $http) {
        var service = {
            fetchAccuracyTable: fetchAccuracyTable
        };
        return service;

        function fetchAccuracyTable() {
            return $http({
                method: 'GET',
                url: 'api/accuracy/table'
            }).then(function (response) {
                return response.data;
            });
        }
    }


})();
