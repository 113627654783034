(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RealestatesController', RealestatesController);

    RealestatesController.$inject = ['Principal', '$scope', '$window', '$state', '$sessionStorage', 'RealestateService',
        'pagingParams', 'paginationConstants', 'PdfService', 'XlsxService', 'ParseLinks', 'DepositService', 'JhiLanguageService',
        'AlertService', 'WithdrawalProductService', 'PaginationUtil', 'usageTypeGroupFactory', '$translate', '$translatePartialLoader'];

    function RealestatesController(Principal, $scope, $window, $state, $sessionStorage, RealestateService, pagingParams,
        paginationConstants, PdfService, XlsxService, ParseLinks, DepositService, JhiLanguageService,
        AlertService, WithdrawalProductService, PaginationUtil, usageTypeGroupFactory, $translate, $translatePartialLoader) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.loadPage1 = loadPage1;
        vm.loadPage = loadPage;
        vm.onFocusOutSearchName = onFocusOutSearchName;
        vm.gotoStateEdit = gotoStateEdit;
        vm.stripOrphaned = stripOrphaned;
        vm.realestates = [];
        vm.dataHasLoaded = false;
        vm.translateMainUsage = translateMainUsage;
        vm.page = pagingParams.page;
        vm.usages = ["WOHNBAUTEN__TITLE", "WOHNBAUTEN__MFH", "WOHNBAUTEN__TERRASSENHAEUSER"];
        vm.filterUsage = pagingParams.filterUsage;
        vm.searchName = pagingParams.searchName;
        vm.filterOwner = "me"; // default, filter objects for myself in sync with vm.previousFilterOwner
        vm.filterModule = pagingParams.filterModule;
        vm.filterFavorite = pagingParams.filterFavorite;

        vm.totalItems = pagingParams.totalItems;
        vm.links = null;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.previousFilterUsage = null;
        vm.previousFilterOwner = 'me'; // default, filter objects for myself in sync with vm.filterOwner
        vm.previousFilterModule = null;
        vm.previousSearchName = '';
        vm.previousIsSearchRequest = null;
        vm.previousPage = 0;
        vm.previousPredicate = null;
        vm.previousReverse = null;
        vm.hasMultiUsers = null;

        vm.isSmallBuildingObject = function(realEstate) {
            return realEstate.metaData.withdrawalProductType === 'SMALL_BUILDING_AGV';
        };
        vm.isDamageCostsObject = function(realEstate) {
            return realEstate.metaData.withdrawalProductType === 'DAMAGE_COSTS';
        };
        vm.isRebuildCostsObject = function(realEstate) {
            return realEstate.metaData.withdrawalProductType === 'REBUILD_COSTS';
        };
        vm.hasNoExcel = function(realEstate){
            return realEstate.metaData.withdrawalProductType === 'SMALL_BUILDING_AGV';
        }

        vm.withdrawalProductTypes = $state.includes('building-costs') ? ['BUILDING_COSTS_AND_DATES', 'OPERATING_COSTS', 'REBUILD_COSTS'] : ['BUILDING_INSURANCE_VALUES', 'SMALL_BUILDING_AGV','DAMAGE_COSTS'];

        //for ui selecter
        vm.selectables = [];
        if ($state.includes('building-costs')) {
            if (Principal.hasModule('BUILDING_COSTS_AND_DATES')) {
                vm.selectables.push('BUILDING_COSTS_AND_DATES');
            }
            if (Principal.hasModule('OPERATING_COSTS')) {
                vm.selectables.push('OPERATING_COSTS');
            }
            if (Principal.hasModule("REBUILD_COSTS")) {
                vm.selectables.push("REBUILD_COSTS");
            }
        } else {
            if (Principal.hasModule('BUILDING_INSURANCE_VALUES')) {
                vm.selectables.push('BUILDING_INSURANCE_VALUES');
            }
            if (Principal.hasModule('DAMAGE_COSTS')) {
                vm.selectables.push('DAMAGE_COSTS');
            }
            if (Principal.hasModule('SMALL_BUILDING_AGV')) {
                vm.selectables.push('SMALL_BUILDING_AGV');
            }
        }

        vm.selectableFavorites = ["NO_FAVORITE", "FAVORITE", "TOP_FAVORITE"];

        function isInStateBuildingCosts() {
            return $state.includes("building-costs");
        }

        vm.isInStateBuildingCosts = isInStateBuildingCosts();

        function isInStateInsuranceValues() {
            return $state.includes("insurance-values");
        }

        vm.isInStateInsuranceValues = isInStateInsuranceValues();

        vm.gotoStateRealestatesDelete = gotoStateRealestatesDelete;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });

        WithdrawalProductService.current_for_user().$promise.then(
            function (activeWp) {
                vm.hasMultiUsers = activeWp.numMaxUsers > 1;
            });

        function loadPage1() {
            vm.page = 1;
            loadAll();
        }

        function loadAll() {
            return loadAllInner(false);
        }

        /**
         * Create the sort statement which is expected to be passed as state param to states inheriting from state 'realestates'.
         */
        function createSortStmt() {
            return vm.predicate + ';' + (vm.reverse ? 'asc' : 'desc');
        }

        function loadAllInner(isSearchRequest) {
            if (!angular.equals(vm.previousFilterUsage, vm.filterUsage)
                || !angular.equals(vm.previousSearchName, vm.searchName)
                || !angular.equals(vm.previousPredicate, vm.predicate)
                || !angular.equals(vm.previousReverse, vm.reverse)
                || !angular.equals(vm.previousPage, vm.page)
                || !angular.equals(vm.previousFilterModule, vm.filterModule)
                || !angular.equals(vm.previousFilterFavorite, vm.filterFavorite)
                || !angular.equals(vm.previousFilterOwner, vm.filterOwner)
                || !angular.equals(vm.previousIsSearchRequest, isSearchRequest)) {
                vm.dataHasLoaded = false;
                vm.previousSearchName = vm.searchName;
                vm.previousFilterUsage = vm.filterUsage;
                vm.previousFilterOwner = vm.filterOwner;
                vm.previousFilterModule = vm.filterModule;
                vm.previousFilterFavorite = vm.filterFavorite;
                vm.previousPredicate = vm.predicate;
                vm.previousPage = vm.page;
                vm.previousIsSearchRequest = isSearchRequest;
                if (vm.predicate) {
                    var tempSort = createSortStmt();
                    vm.predicate = PaginationUtil.parsePredicate(tempSort);
                    vm.reverse = PaginationUtil.parseAscending(tempSort);
                }


                RealestateService.api.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    searchName: vm.searchName,
                    filterUsage: vm.filterUsage,
                    showDataSameOrg: vm.filterOwner === "all",
                    showPublicObject: false, // this is only used for referenceDB
                    isSearchRequest: isSearchRequest,
                    withdrawalProductTypes: vm.filterModule == null ? vm.withdrawalProductTypes : [vm.filterModule],
                    favorites: vm.filterFavorite
                }, onSuccess, onError);

            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            var tempRealestates = data;
            vm.realestates = [];
            tempRealestates.forEach(function (realestate) {
                realestate.translated = vm.translateMainUsage(realestate);
                vm.realestates.push(realestate);
            });
            vm.dataHasLoaded = true;
        }

        function onError(error) {
            vm.dataHasLoaded = true;
            AlertService.error(error.data.message);
        }

        function sort() {
            if (vm.predicate) {
                return [createSortStmt()];
            } else {
                return [];
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function onFocusOutSearchName() {
            if (!angular.equals(vm.previousSearchName, vm.searchName)) {
                vm.page = 1;
                loadAllInner(true);
            }
        }

        function gotoStateEdit(params) {
            console.warn(params);
            delete $sessionStorage.realEstateContainer;
            delete $sessionStorage.output;
            //ValidationDataService.resetComplete();
            switch (params.withdrawalProductType) {
            case 'building_costs_and_dates':
                $state.go('process-form.edit-record', params);
                break;
            case 'building_insurance_values':
                $state.go('insurance.edit-record', params);
                break;
            case 'small_building_agv':
                $state.go('small-building-form.edit', params);
                break;
            case 'operating_costs':
                $state.go('operational-costs.edit-record', params);
                break;
            case 'rebuild_costs':
                $state.go('rebuild-form.edit-record', params);
                break;
            case 'damage_costs':
                $state.go('damage-form.edit-record', params);
                break;
            }
        }

        function stripOrphaned(email) {
            if (_.endsWith(email, '_orphaned')) {
                var newEmail = email.substring(0, email.length - '_orphaned'.length);
                var lastIdx = newEmail.lastIndexOf("_");
                return newEmail.substring(0, lastIdx);
            }
            return email
        }

        function translateMainUsage(realEstate) {
            if (realEstate.metaData.withdrawalProductType === 'SMALL_BUILDING_AGV') {
                $translatePartialLoader.addPart('model_agv');
                $translate.refresh();
                var smallBuildingMainUsageTitle = realEstate.agv.usages[0].type;
                return smallBuildingMainUsageTitle + ': ' + $translate.instant('realEstate.agv.usages.' + smallBuildingMainUsageTitle);
                // return "TODO: NUTZUNG ANZEIGEN"
            }
            try {
                var mainUsageTitle = realEstate.usages[0].type;
                var previousUsageTitle = usageTypeGroupFactory.getTitleOfPrevious(mainUsageTitle);
                var translateFirst = $translate.instant('realEstate.usages.' + previousUsageTitle);
                var translateSecond = $translate.instant('realEstate.usages.' + mainUsageTitle);
            } catch (e) {
                console.warn('translation error main usages realestates ctr', realEstate.usages, e);
            }
            return translateFirst + ' - ' + translateSecond;
        }

        function arrayObjectIndexOf(array, searchTerm, property) {
            for (var i = 0, len = array.length; i < len; i++) {
                if (array[i][property] === searchTerm) return i;
            }
            return -1;
        }

        function gotoStateRealestatesDelete(realEstateId) {
            var stateParamsOfCurrentSearch = {
                page: vm.page,
                totalItems: Number(vm.totalItems),
                sort: createSortStmt(),
                filterUsage: vm.filterUsage,
                searchName: vm.searchName
            };
            var itemsOnPage = vm.realestates.length;
            $state.go(
                $state.params.deleteState,
                {
                    realEstateId: realEstateId,
                    itemsOnPage: itemsOnPage,
                    previousStateName: $state.current.name,
                    previousStateParams: stateParamsOfCurrentSearch
                });
        }

        $scope.$watch('vm.filterUsage', function (selectedItem) {
            if (selectedItem && !angular.equals(vm.filterUsage, vm.previousFilterUsage)) {
                vm.page = 1;
                loadAllInner(true);
            }
        });

        $scope.$watch('vm.filterOwner', function(selectedItem) {
           if (selectedItem && !angular.equals(vm.filterOwner, vm.previousFilterOwner)) {
               vm.page = 1;
               loadAllInner(true);
           }
        });

        $scope.$watch('vm.filterModule', function (selectedItem) {
            if (selectedItem && !angular.equals(vm.filterModule, vm.previousFilterModule)) {
                vm.page = 1;
                loadAllInner(true);
            }
            if(vm.filterModule == null){
                loadAllInner(true);
            }
        });

        $scope.$watch('vm.filterFavorite', function (selectedItem) {
            if (selectedItem && !angular.equals(vm.filterFavorite, vm.previousFilterFavorite)) {
                vm.page = 1;
                loadAllInner(true);
            } else if (vm.filterFavorite == null) {
                loadAllInner(true);
            }
        });

        $scope.isOwner = function (realEstate) {
            return realEstate.metaData.userLogin === vm.currentAccount.login;
        };

        $scope.downloadPdf = function (realEstate) {
            PdfService.downloadPdf(realEstate.id, realEstate.metaData.name);
        };

        $scope.downloadXlsx = function (realEstate) {
            XlsxService.downloadXlsx(realEstate.id, realEstate.metaData.name);
        };

        $scope.openPdf = function (realEstateMetaData) {
            PdfService.getLatestPdfIdByRealEstateId(realEstateMetaData.id,
                function (response) {
                    var pdfId = response.data;
                    if (angular.equals("", pdfId)) {
                        console.error('Error occured while trying to retrieve pdf id, empty pdfId');
                        $state.go('error');
                    }
                    $window.open("pdf/pdfview?id=" + pdfId, '_blank');
                    $window.focus();
                }, function (errorResponse) {
                    console.error('Error occurred while trying to retrieve pdf id, response: ' + errorResponse);
                    $state.go('error');
                });
        };

        // invoke methods on start
        vm.loadAll();

    }

})();
