(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('LicenseReportController', LicenseReportController);

    LicenseReportController.$inject = ['LicenseReportService'];

    function LicenseReportController(LicenseReportService) {
        var vm = this;

        var now = moment.utc();
        vm.dateTimeDeadline = now;

        vm.dateTimeDeadlineUsed = null;
        vm.dateTimeDeadlineUsedMinus1Year = null;

        vm.downloadReport = downloadReport;
        vm.getReport = getReport;

        vm.isCalculating = false;
        vm.reportedData = null;

        getReport();


        function downloadReport() {
            LicenseReportService.downloadValidLicenses(vm.dateTimeDeadline.toISOString()).then(function(response) {
                console.log(response);
            });
        }

        function getReport() {
            vm.isCalculating = true;
           LicenseReportService.getLicenseReport(vm.dateTimeDeadline).then(function(response) {
               vm.dateTimeDeadlineUsed = angular.copy(vm.dateTimeDeadline).format("YYYY-MM-DD HH:mm:ss");
               vm.dateTimeDeadlineUsedMinus1Year = angular.copy(vm.dateTimeDeadline).subtract(1, "years").format("YYYY-MM-DD HH:mm:ss");
               vm.reportedData = response;
                vm.isCalculating = false;
            });
        }


    }

})();
