(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('ProductManagementController', ProductManagementController);

    ProductManagementController.$inject = ['$scope', '$filter', 'DepositProductService', 'WithdrawalProductService', 'User'];

    function ProductManagementController($scope, $filter, DepositProductService, WithdrawalProductService, User) {
        $scope.showHistoryDepositProducts = false;
        $scope.showHistoryWithdrawalProducts = false;
        $scope.depositProducts = [];
        $scope.withdrawalProducts = [];
        $scope.pendingWithdrawalProducts = [];
        $scope.authorities = ["ROLE_ADMIN"];

        $scope.userSearch = {};
        $scope.userSearch.searchText = '';
        $scope.userSearch.userIds = [];
        $scope.userSearch.querySearch = function (query) {
            if (query) {
                var q = {
                    "searchString": query
                };
                return User.search_by_owner_login(q).$promise.then(function(res) {
                    return res;
                });
            } else {
                return  User.query_owner_logins().$promise.then(function(res) {
                    return res;
                });
            }
        };

        $scope.userSearch.selectedItemChange = function (text) {
            $scope.userSearch.searchText = text;
            var p = {
                'userLogin': text
            };
            $scope.pendingWithdrawalProducts = WithdrawalProductService.query_pending_by_user(p);
            if (!$scope.showHistoryWithdrawalProducts) {
                $scope.withdrawalProducts = WithdrawalProductService.actives_for_admin(p);
            } else {
                $scope.withdrawalProducts = WithdrawalProductService.query_by_user(p);
            }
        };

        $scope.loadAllWithdrawalProducts = function () {
            var p = {
                'userLogin': $scope.userSearch.searchText
            };
            $scope.pendingWithdrawalProducts = WithdrawalProductService.query_pending_by_user(p);
            if (!$scope.showHistoryWithdrawalProducts) {
                $scope.withdrawalProducts = WithdrawalProductService.actives_for_admin(p);
            } else {
                $scope.withdrawalProducts = WithdrawalProductService.query_by_user(p);
            }
        };

        $scope.dateTimeInactiveEmptyOrNull = function (item) {
            return !(item.dateTimeInactive != null);
        };

        $scope.loadAllDepositProducts = function () {
            if (!$scope.showHistoryDepositProducts) {
                $scope.depositProducts = DepositProductService.actives_for_admin();
                /*
                 return DepositProductService.query(function(allDepositProducts) {
                 $scope.depositProducts = $filter('filter')(allDepositProducts, $scope.dateTimeInactiveEmptyOrNull)
                 });
                 */
            } else {
                $scope.depositProducts = DepositProductService.query();
            }
        };


        $scope.isDeletableDp = function (depositProduct) {
            return !depositProduct.dateTimeInactive && depositProduct.productType !== 'DYNAMIC_USER_DIFFERENCE';
        };

        $scope.isDeletableWp = function (withdrawalProduct) {
            return !withdrawalProduct.dateTimeInactive && withdrawalProduct.userLogin;
        };

        $scope.loadAllDepositProducts();
        $scope.loadAllWithdrawalProducts();
    }

})();
