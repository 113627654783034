(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('removeInvestmentButton', RemoveInvestmentButtonDirective)
        .directive('removeInvestment', RemoveInvestmentDirective);


    RemoveInvestmentButtonDirective.$inject = [];
    function RemoveInvestmentButtonDirective() {
        return {
            restrict: "E",
            scope: false,
            template: "<button type='button' class='btn btn-elegant-black kw-btn-grid' remove-investment ng-disabled='realEstateContainer.investments.length < 2' translate='process-form.removeInvestment'>Remove last Investment</button>"
        };
    }

    RemoveInvestmentDirective.$inject = ['$rootScope', '$timeout'];
    function RemoveInvestmentDirective($rootScope, $timeout) {
        return {
            scope: false,
            link: function (scope, element, attrs) {
                element.bind("click", function() {
                    scope.realEstateContainer.investments.pop();

                    $rootScope.$broadcast("changeInvestments", {});

                    $timeout(function() {
                        scope.$apply();
                        $rootScope.$broadcast("windowSizeChange", {
                            width: $(document).width
                        });
                    }, 100);
                });
            }
        };
    }

})();
