(function() {
    'use strict';
    angular.module('kennwerteApp.agv')
        .directive('smallUsageAutocompleteInput', SmallBuildingAgvUsageAutocompleteDirective)
        .controller('SmallBuildingAgvUsageAutocompleteCtrl', SmallBuildingAgvUsageAutocompleteCtrl);

    SmallBuildingAgvUsageAutocompleteDirective.$inject = ['$timeout', '$rootScope'];

    function SmallBuildingAgvUsageAutocompleteDirective($timeout, $rootScope) {
        return {
            restrict: 'E',
            controller: SmallBuildingAgvUsageAutocompleteCtrl,
            controllerAs: 'vm',
            scope: {
                reference: '=',
                referenceString: '@?',
                referenceArray: '=?',
                realEstate: '=?',
                usageId: '=',
                usage: '=',
                disablePresetFun: '=?',
                translateBaseKey: '=?',
                rowid: '<?',
                mandatory: '<?',
                required: '<?',
                isDisabled: '=?',
                // validationMessage: '@',
                infotext: '@?',
                index: '@?',
                tabIndex: '=',
                tabGroup: '='
            },
            templateUrl: 'app/small_building_agv_insurance_form/10_small_building_agv_insurance_input/20_small_building_agv_insurance_general_form/small_building_agv_usage/small_building_agv_usage_autocomplete/small-building-agv-usage-autocomplete.tpl.html',
            link: function(scope, tElement, tAttrs, ctrl) {

                tElement.bind('focusin', function(e) {
                    ctrl.enableDropdown();
                });

                tElement.bind('focusout', function(e) {
                    ctrl.closeDropdown();
                });
            }


        };
    }


    SmallBuildingAgvUsageAutocompleteCtrl.$inject = ['$scope', '$rootScope', '$element', '$attrs', '$translate', 'SmallBuildingUsageAutocompleteDataService'];

    function SmallBuildingAgvUsageAutocompleteCtrl($scope, $rootScope, $element, $attrs, $translate, SmallBuildingUsageAutocompleteDataService) {

        var $ctrl = this;

        $scope.dataHasLoaded = false;
        $scope.trieStem = {};
        $scope.trieToken = {};
        $scope.trieFilter = {};
        this.$onInit = function() {

            var trie = SmallBuildingUsageAutocompleteDataService.getTrie();
            trie.then(function(data) {
                // console.log(data);
                $scope.trieStem = data.stem;
                $scope.trieToken = data.token;
                $scope.dataHasLoaded = true;
            });
            $scope.filteredList = {};
        };

        this.findUsage = function(term) {
            var res;
            res = _.filter($scope.trieStem.objectsWithPrefix(term), function(value) {
                return _.startsWith(value.stem.toUpperCase());
            });
            return _.head(res);
        };

        this.closeDropdown = function() {
            window.setTimeout(function() {
                $('.auto-complete-container').hide();
            }, 200);
        };

        $ctrl.enableDropdown = function () {
            var rowid = $scope.rowid;

            $rootScope.$broadcast('updateDescription', {
                rowid: rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $scope.translateBaseKey
            });
            window.setTimeout(function () {
                $('.auto-complete-container').show();
            }, 200);
        };

        $scope.autoCompleteOptions = {
            minimumChars: 0,
            maxItemsToRender: 30,
            activateOnFocus: true,
            dropdownHeight: '300px',
            data: function(term) {
                term = term.toUpperCase();
                $scope.filteredList = _.concat( [],$scope.trieStem.objectsWithPrefix(term),$scope.trieToken.objectsWithPrefix(term));
                $scope.filteredList = _.uniqWith($scope.filteredList, function(a, b) {
                    return a.token === b.token;
                });
                return $scope.filteredList;
            },
            selectedTextAttr: 'location',
            renderItem: function(item) {
                return {
                    value: item.token,
                    label:
                        '<p class=\'auto-complete\'>'
                        + item.token +
                        '</p>'
                };
            },
            itemSelected: function(e) {
                $scope.usage = e.item.usage_id;
            }
        }
        ;
    }
})
();
