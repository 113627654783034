(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('selectInput', SelectInputDirective);

    SelectInputCtr.$inject = ['$scope', '$rootScope'];
    function SelectInputCtr($scope, $rootScope) {

        $scope.firstLoad = true;

        var vm = this;

        vm.model = {};
        vm.answers = ["yes", "no"];
        // use polyfills for IE, TODO: Introduce a proper place for polyfills
        Number.isInteger = Number.isInteger || function(value) {
            return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
        };


        vm.isEmptyModel = function () {
            return angular.equals({}, vm.model);
        };

        vm.translateKey = function (answer) {
            return 'global.' + answer;
        };

        vm.onOpenClose = function (isOpen) {
            if (isOpen) {
                $rootScope.$broadcast("updateDescription", {
                    rowid: $scope.rowid,
                    descriptionBaseKey: $scope.descriptionBaseKey
                });
                $rootScope.$broadcast("updateImage", {
                    rowid: $scope.rowid,
                    imageSrc: $scope.image
                });
            }
        };

        $scope.$watch('vm.model', function (selectedItem) {
            if (!$scope.firstLoad && !angular.equals({}, selectedItem)) {
                $rootScope.$broadcast("updateImage", {
                    rowid: $scope.rowid,
                    imageSrc: $scope.image
                });

                $rootScope.$broadcast("updateDescription", {
                    rowid: $scope.rowid,
                    descriptionNumber: selectedItem,
                    descriptionBaseKey: $scope.descriptionBaseKey + "." + selectedItem
                });

                if (selectedItem === "yes") {
                    $scope.reference = 1;
                } else {
                    $scope.reference = 0;
                }
                if ($scope.inputBlurFun != null) {
                    $scope.inputBlurFun();
                }
            } else {
                $scope.firstLoad = false;
            }
        });

        function initSelectInput() {
            if (Number.isInteger($scope.reference)) {
                if ($scope.reference === 1) {
                    vm.model = vm.answers[0];
                } else {
                    vm.model = vm.answers[1];
                }
            } else if (_.isBoolean($scope.reference)) {
                if ($scope.reference) {
                    vm.model = vm.answers[0];
                } else {
                    vm.model = vm.answers[1];
                }
            }
        }

        initSelectInput();

        /**
         * calling the return of the watch will destroy it. (we are just making sure the value is set)
         * @type {(function())|angular.noop}
         * @private
         */
        // var _destroyReferenceWatch = $scope.$watch('reference', function (newVal,oldVal) {
        //     console.error($scope.reference,newVal,oldVal,vm.model)
        //    if($scope.reference != null){
        //        initSelectInput();
        //        // _destroyReferenceWatch();
        //    }
        // });
        //
    }

    SelectInputDirective.$inject = ['$rootScope'];
    function SelectInputDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                rowid: "=", // used to set the id of the row-image
                translateBaseKey: "@translateBaseKey",
                image: "=image",
                required: "=required",
                placeholder: "@",
                validationMessage: "@",
                mandatory: "@",
                descriptionBaseKey: "@",
                tabIndex: "=",
                tabGroup: "=",
                infotext: "@?",
                inputBlurFun: "&?"

            },
            controller: SelectInputCtr,
            templateUrl: 'app/components/select_input/select-input.tpl.html',
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs) {

                // Used for the validation
                var toggle = tElement.find(".selectInput");
                $(toggle).attr("id", tAttrs.reference+".drop2down-toggle");

                // Prevent document to scroll if using arrows key in open uiSelect
                /*
                $(document).keydown(function(e) {
                    var uiSelectElement = tElement[0].getElementsByClassName('ui-select-container')[0];
                    var uiSelect = angular.element(uiSelectElement).controller('uiSelect');
                    if (uiSelect.open && (e.which === 40 || e.which === 38)) {
                        e.preventDefault();
                    }
                });
                */


            },
        };
    }

})();
