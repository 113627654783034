(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .directive('hasModule', hasModule);

    hasModule.$inject = ['Principal'];

    function hasModule(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var module = attrs.hasModule.replace(/\s+/g, '');

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    if (Principal.hasModule(module)) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                };

            if (module.length > 0) {
                defineVisibility(true);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(true);
                });
                scope.$watch(function() {
                    return Principal.getModules();
                }, function(res) {
                    defineVisibility(false);
                }, true);
            }
        }
    }
})();
