(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageCalculatingSuccessController', DamageCalculatingSuccessController);

    DamageCalculatingSuccessController.$inject = ['$scope', '$state', '$location', '$anchorScroll',
        'smoothScroll', '$sessionStorage', 'PdfService', 'XlsxService', 'ValidationDataService', '$uibModal'];

    function DamageCalculatingSuccessController($scope, $state, $location, $anchorScroll, smoothScroll,
                                          $sessionStorage, PdfService, XlsxService, ValidationDataService, $uibModal) {

        var vm = this;

        var hasClickedPdfDownload = false;
        var hasClickedExcelDownload = false;

        vm.output = $sessionStorage.output;
        vm.pdfId = $sessionStorage.pdfId;
        vm.pdfIdOfReleaseCandidate = $sessionStorage.pdfIdOfReleaseCandidate;
        vm.bkp0Present = false;

        vm.downloadPdf = function () {
            if (!hasClickedPdfDownload) {
                hasClickedPdfDownload = true;
                PdfService.downloadPdf($scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
                hasClickedPdfDownload = false;
            }
        };

        vm.gotoStateEdit = function() {
            delete $sessionStorage.output;
            //ValidationDataService.resetComplete();
            $state.go('damage-form.edit-record', {
                realEstateId: $scope.realEstateContainer.id
            });
        };

        vm.checkForEmptyVals = function (cost) {
            if ($scope.realEstateContainer.metaData.withdrawalProductType == 'BUILDING_COSTS_AND_DATES') {
                vm.checkForBkp0();
            }
            if (cost.key === 'BKP_0' || cost.key === 'BKP_3'
                || cost.key === 'BKP_4' || cost.key === 'BKP_6'
                || cost.key === 'BKP_7' || cost.key === 'BKP_8') {
                return cost.value.length > 4 && cost.key.length > 1;
            } else {
                return !cost.isAddition || cost.value.length > 4 && cost.key.length > 1;
            }
        };

        vm.checkForBkp0 = function () {
            vm.bkp0Present = _.find(vm.output.costs, function (cost) {
                return cost.key === 'BKP_0_GRUNDSTUECK';
            });
            if (angular.isUndefined(vm.bkp0Present)) {
                vm.bkp0Present = false;
            }
            return vm.bkp0Present;
        };

        vm.downloadPdfRc = function () {
            PdfService.downloadPdf(-$scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
        };

        vm.downloadXlsx = function () {
            if (!hasClickedExcelDownload) {
                hasClickedExcelDownload = true;
                XlsxService.downloadXlsx($scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
                hasClickedExcelDownload = false;
            }
        };

        vm.downloadXlsxRc = function () {
            XlsxService.downloadXlsx($scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
        };


        vm.showSankeyInModalIfNotEmpty = function(rows) {
            console.log(rows);
            if (rows.length > 0) {
                $uibModal.open({
                    templateUrl: 'app/admin/sankey-modal/sankey.modal.html',
                    controller: 'SankeyModalController',
                    controllerAs: 'vm',
                    size: 'xlg',
                    resolve: {
                        title: function () {
                            return "Rechnungsschritte";
                        },
                        rows: function () {
                            return rows;
                        },

                    }
                });
            }
        };
    }
})();
