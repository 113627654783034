(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('CamtFileProcessingDialogController', CamtFileProcessingDialogController);

    CamtFileProcessingDialogController.$inject = ['$scope', '$uibModalInstance', 'CamtService', 'matchedCamtDto', 'fileName', '$translate'];

    function CamtFileProcessingDialogController($scope, $uibModalInstance, CamtService, matchedCamtDto, fileName, $translate) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.translateCamtEntryOkType = translateCamtEntryOkType;
        vm.translateCamtEntryErrorType = translateCamtEntryErrorType;
        vm.translateStatus = translateStatus;
        vm.translateInvoiceStatus = translateInvoiceStatus;
        vm.printMatchStatus = printMatchStatus;
        vm.translateReportedMatchStatus = translateReportedMatchStatus;

        vm.matchedCamtDto = matchedCamtDto;
        vm.fileName = fileName;
        vm.isSaving = false;
        vm.retSuccess = false;
        vm.retError = false;
        vm.matchStates = ['MATCH', 'NO_MATCH'];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.retSuccess = false;
            vm.retError = false;
            if (vm.camtForm.$invalid) {
                angular.forEach(vm.camtForm.$error.required, function (field) {
                    field.$setDirty();
                });
            } else {
                var copyMatchedCamtDto = angular.copy(vm.matchedCamtDto);
                CamtService.saveMatches(
                    copyMatchedCamtDto,
                    function (resp) {
                        console.log("Success");
                        // parse resp.data (MatchedCamtReportDto) and update matchedCamtDto
                        angular.forEach(resp.data.success, function(e){
                            // try to find in oks
                            angular.forEach(matchedCamtDto.camtEntryOks, function(ca) {
                                if (ca.parsedCamtEntry.id === e.camtEntryId) {
                                    ca.reportedMatchStatus = e.matchStatus;
                                    ca.payment.status = e.paymentStatus;
                                }
                            });
                        });
                        angular.forEach(resp.data.error, function (e) {
                            // try to find in oks
                            angular.forEach(matchedCamtDto.camtEntryOks, function(ca) {
                                if (ca.camtEntry.id === e.camtEntryId) {
                                    ca.reportedMatchStatus = e.matchStatus;
                                    ca.payment.status = e.paymentStatus;
                                }
                            });
                        });
                        vm.isSaving = false;
                        vm.retSuccess = true;
                    },
                    function (error) {
                        console.error("Fail");
                        vm.isSaving = false;
                        vm.retError = true;
                    });
            }
        }

        function translateCamtEntryOkType(camtEntryOkType) {
            return $translate.instant('orgManagement.camt.ok.' + camtEntryOkType);
        }

        function translateCamtEntryErrorType(camtEntryErrorType) {
            return $translate.instant('orgManagement.camt.error.' + camtEntryErrorType);
        }

        function printMatchStatus(matchStatus) {
            return $translate.instant('orgManagement.camt.matchStatus.' + matchStatus);
        }

        function translateReportedMatchStatus(matchStatus) {
            return $translate.instant('orgManagement.camt.reportedMatchStatus.' + matchStatus);
        }

        function translateStatus(status) {
            try {
                return $translate.instant('billing.history.transactionStatus.' + status);
            } catch (e) {
                console.warn('translate error transactionStatus', e);
            }
        }

        function translateInvoiceStatus(status) {
            try {
                return $translate.instant('billing.history.invoiceStatus.' + status);
            } catch (e) {
                console.warn('translate error invoiceStatus', e);
            }
        }

    }

})();
