(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('OperationalCostsQualityFormController', OperationalCostsQualityFormController);

    OperationalCostsQualityFormController.$inject = ['$scope', '$stateParams', 'HeatingTypeDataService'];

    function OperationalCostsQualityFormController($scope, $stateParams, HeatingTypeDataService) {
        $scope.data = $stateParams.data;
        $scope.isNotIE = false;
        if (angular.isUndefined(document.documentMode)) {
            $scope.isNotIE = true;
        } else {
            $scope.isNotIE = false;
        }
        $scope.groupedHeatingTypes = [ HeatingTypeDataService.default, HeatingTypeDataService.extras ];
    }

})();

