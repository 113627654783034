(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('InsuranceCalculatingErrorController', InsuranceCalculatingErrorController);

    InsuranceCalculatingErrorController.$inject = [];

    function InsuranceCalculatingErrorController() {
    }

})();
