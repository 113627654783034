(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('register', {
            parent: 'account',
            url: '/7tage',
            data: {
                authorities: [],
                pageTitle: 'register.pageTitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/register/register.html'
                },
                'inner@register': {
                    templateUrl: 'app/account/register_inner/register-inner.html',
                    controller: 'RegisterInnerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader
                        .addPart('register')
                        .addPart('model_realEstate')
                        .addPart('prices')
                        .addPart('seo')
                        .addPart('settings');
                    return $translate.refresh();
                }],
                metaTags: ['MetaTagService', function(MetaTagService) {
                    MetaTagService.setTags({
                        'description': 'Jetzt 7 Tage gratis testen. Das Schweizer Tool zur Kostenberechnung für Architekten, Bauherren und Projektentwickler. Einfach, praktisch und transparent.',
                        'Keywords': 'Mix',
                        // OpenGraph
                        'og:type': 'website',
                        'og:title': 'kennwerte',
                        'og:description': 'Jetzt 7 Tage gratis testen. Das Schweizer Tool zur Kostenberechnung für Architekten, Bauherren und Projektentwickler. Einfach, praktisch und transparent.',
                        // Twitter
                        'twitter:card': 'summary_large_image',
                        'twitter:site': '@kennwerte',
                        'twitter:title': 'kennwerte',
                        'twitter:description': 'Jetzt 7 Tage gratis testen. Das Schweizer Tool zur Kostenberechnung für Architekten, Bauherren und Projektentwickler. Einfach, praktisch und transparent.'
                    });
                }]
            }
        });
    }
})();
