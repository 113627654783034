(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('SettingsOrganisationController', SettingsOrganisationController)
        .factory('SettingOrganisationService', SettingOrganisationService);

    SettingOrganisationService.$inject = ['$uibModal', '$state'];

    function SettingOrganisationService($uibModal, $state) {
        var service = {
            open: open
        };

        var modalInstance = null;

        var resetModal = function () {
            modalInstance = null;
            $state.reload();
        };

        return service;

        function open($scope) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/account/settings/settings-organisation.html',
                controller: 'SettingsOrganisationController',
                scope: $scope,
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );
        }
    }

    SettingsOrganisationController.$inject = ['$scope', '$state', '$uibModalInstance', 'User', 'JhiLanguageService',
        'Organisation', 'OrganisationUser', 'OrganisationInvite', 'WithdrawalProductService'];

    function SettingsOrganisationController($scope, $state, $uibModalInstance, User, JhiLanguageService,
                                            Organisation, OrganisationUser, OrganisationInvite, WithdrawalProductService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.currentUser = $scope.currentLogin;
        vm.orgId = $scope.orgId;
        vm.delete = null;
        vm.changeNameSuccess = false;
        vm.dataHasLoaded = false;
        vm.billingContactEqualsOrgContact = true;

        vm.hasBillingContact = false;


        vm.numAdditionalUsers = null;

        vm.deleteUser = deleteUser;
        vm.loadAll = loadAll;
        vm.addUser = addUser;
        vm.remove = remove;
        vm.makeAdmin = makeAdmin;
        vm.makeUser = makeUser;
        vm.deleteInvite = deleteInvite;
        vm.changeOrg = changeOrg;
        init();
        fetchWithdrawalProduct();

        function init() {
            Organisation.get({'id': vm.orgId}, onGetSuccess, onGetError);
        }

        function onGetSuccess(result) {
            vm.organisation = result;
            vm.oldName = vm.organisation.name;
            vm.billingContactEqualsOrgContact = vm.organisation.billingContact == null;
            //loadAll();
        }

        function onGetError() {
            vm.error = 'ERROR';
        }

        function fetchWithdrawalProduct() {
            WithdrawalProductService.current_for_user().$promise.then(
                function (activeWp) {
                    vm.numAdditionalUsers = activeWp.numMaxUsers - 1;
                });
        }

        function loadAll() {
            //TODO only load User of organisation
            OrganisationUser.query_users({id: vm.organisation.id}, onLoadOrgUserSuccess, onError);
            OrganisationInvite.query_all({id: vm.organisation.id}, onLoadOrgInviteSuccess, onError);
        }

        function onLoadOrgUserSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.users = data
                .filter(function(u){
                    return !endsWith(u.login, ' orphaned') && !(_.startsWith(u.email, 'org#') && endsWith(u.email, "@org"));
                });
            vm.dataHasLoaded = true;
        }

        function endsWith(str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function onLoadOrgInviteSuccess(response) {
            vm.totalItems = response.headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.invites = response.data;
        }

        function onInviteDelete(){
            loadAll();
        }

        function onPutSuccess(data, headers) {
            vm.email = "";
            loadAll();
        }

        function onPutError(error) {
            vm.invitedUserAlreadyExists = true;
            vm.dataHasLoaded = true;
        }

        function onError(error) {
            vm.dataHasLoaded = true;
        }

        function addUser() {
            vm.invitedUserAlreadyExists = false;
            OrganisationInvite.invite({id: vm.organisation.id, email: vm.email}, onPutSuccess, onPutError);
        }

        function onChangeOrgSuccess(data) {
            vm.changeNameSuccess = true;
            vm.oldName = vm.organisation.name;
        }

        function onChangeOrgError(data) {
            vm.organisation.name = vm.oldName;
        }

        function changeOrg() {
            if (vm.billingContactEqualsOrgContact) {
                vm.organisation.billingContact = null;
            }
            if (vm.form.$invalid) {
                angular.forEach(vm.form.$error.required, function(field) {
                    field.$setDirty();
                });
            } else {
                Organisation.update({ id: vm.organisation.id }, vm.organisation, onChangeOrgSuccess, onChangeOrgError);
            }
        }


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function remove(user) {
            vm.delete = user;
        }

        function deleteUser(user) {
            OrganisationUser.delete({ id: vm.organisation.id, email: user.email }, onPutSuccess, onError);
            vm.delete = null;
        }

        function deleteInvite(user) {
            OrganisationInvite.delete_invite({ id: vm.organisation.id, email: user.email }, onInviteDelete, onError);
        }

        function makeAdmin(user){
            OrganisationUser.change_role({id: vm.organisation.id},  {'email': user.email,  'organisationRole': 'ORG_ADMIN'}, onPutSuccess, onError);
        }

        function makeUser(user){
            OrganisationUser.change_role({id: vm.organisation.id},  {'email': user.email,  'organisationRole': 'ORG_USER'}, onPutSuccess, onError);
        }

        function onDeleteSuccess() {
            $state.go('settings',  null, {reload: true});
        }

        $scope.$watch('vm.organisation.siaIndividualMember', function (newVal) {
            if (newVal) {
                vm.organisation.siaCorporateMember = false;
            }
        });

        $scope.$watch('vm.organisation.siaCorporateMember', function (newVal) {
            if (newVal) {
                vm.organisation.siaIndividualMember = false;
            }
        });
    }
})();
