(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .factory("MinergieTypePresetService", MinergieTypePresetService);

    MinergieTypePresetService.$inject = ['UsageToEnumResolverService'];

    /**
     * Created by KR-20-5-preset-dict-final
     * - from kw-bkp-model data/export/KR-20/preset-final/minergie-presets-final.json
     *
     * Is used in kw-lambda-node-usage-preset (seperated repo) - microservice for kw-api!
     */
    function MinergieTypePresetService(UsageToEnumResolverService) {
        var service = {
            presetMinergieType: presetMinergieType,
            getMapping: function() {
                return mapping;
            }
        };

        function presetMinergieType(usages, realEstateContainer) {
            var minergiePreset = UsageToEnumResolverService.resolve(usages, service.getMapping());
            if (minergiePreset != null) {
                realEstateContainer.quality.minergieStandard = minergiePreset;
            }
        }

        var mapping = {
            "WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "WOHNBAUTEN__STUDENTENWOHNHAEUSER": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "WOHNBAUTEN__PERSONALHAEUSER": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "WOHNBAUTEN__MFH": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "WOHNBAUTEN__TERRASSENHAEUSER": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "WOHNBAUTEN__EFH_REIHEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "WOHNBAUTEN__ALTERSWOHNUNGEN": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "WOHNBAUTEN__ALTERSHEIME": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "KULTUR__AUSSTELLUNGEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "KULTUR__SAAL": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "KULTUR__MUSEEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "KULTUR__WOHLFAHRT": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "KULTUR__KULTUR_GEMEINSCHAFTSZENTREN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__RESTAURANT": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__SCHIRMHUETTEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HOTELBAUTEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HERBERGEN": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__FREIZEITZENTREN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TURNHALLEN_SPORTHALLEN_MEHRZWECKHALLEN": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__SPORTPLAETZE": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__FREIBAD": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "VERKEHRSBAUTEN__STRASSENVERKEHR": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "VERKEHRSBAUTEN__WERKHOEFE": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "VERKEHRSBAUTEN__ZOLL": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "VERKEHRSBAUTEN__BAHNHOEFE": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "VERKEHRSBAUTEN__VERKEHRSPAVILLONS": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "VERKEHRSBAUTEN__UEBERDACHUNGEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "SCHUTZBAUTEN__SCHULUNGSBAUTEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "SCHUTZBAUTEN__KASERNEN": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "SCHUTZBAUTEN__FEUERWEHRGEBAEUDE": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "SCHUTZBAUTEN__AUSBILDUNGSZENTREN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "SCHULBAUTEN__KINDERGAERTEN_HORTE": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "SCHULBAUTEN__VOLKSSCHULEN": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "SCHULBAUTEN__BERUFSSCHULEN": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LAGERBAUTEN__VERTEILZENTRALEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LAGERBAUTEN__INDUSTRIEHALLEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LAGERBAUTEN_PRODUKTIONSBAUTEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LANDWIRTSCHAFT__SCHEUNEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LANDWIRTSCHAFT_STALLUNGEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__ZOO_TIERHAEUSER": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "TECHNISCHE_ANLAGEN__UNTERWERK": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "TECHNISCHE_ANLAGEN__HEIZZENTRALE": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "TECHNISCHE_ANLAGEN__KEHRICHTVERBRENNUNGSANLAGEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LADENBAUTEN_HANDELSBAUTEN__MARKTHALLEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "BUEROBAUTEN__EDELROHBAU": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "MINERGIE"
            },
            "BUEROBAUTEN__VOLLAUSBAU": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "BANKEN": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "VERWALTUNGSBAUTEN": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE_P",
                "HIGH": "MINERGIE_P"
            },
            "BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "JUSTIZ__STRAFVOLLZUGSANSTALTEN": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "JUSTIZ__WIEDEREINGLIEDERUNGSSTAETTEN": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE_P"
            },
            "JUSTIZ__POLIZEI": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "GESUNDHEITSBAUTEN__TAGESHEIME": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "GESUNDHEITSBAUTEN__KRANKENHAEUSER_BETTENTRAKT": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "GESUNDHEITSBAUTEN__KRANKENHAEUSER_Untersuchung": {
                "LOW": "MINERGIE",
                "MEDIUM": "MINERGIE",
                "HIGH": "NONE"
            },
            "GESUNDHEIT__PFLEGEHEIME": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "KULTUS__KIRCHE": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "KULTUS__KIRCHGEMEINDEHAEUSER": {
                "LOW": "NONE",
                "MEDIUM": "MINERGIE",
                "HIGH": "MINERGIE"
            },
            "KULTUS__KREMATORIUM": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "SCHULBAUTEN__BIBLIOTHEKEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "SCHULBAUTEN__FORSCHUNGSBAUTEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LAGERBAUTEN_MEHRSTOECKIGE": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LANDWIRTSCHAFT__LANDWIRTSCHAFTLICHE_ANLAGEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "BUEROBAUTEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "GESUNDHEITSBAUTEN__KRANKENHAEUSER": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__KANTINEN": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            },
            "VERKEHRSBAUTEN__GARAGEN_FREISTEHEND": {
                "LOW": "NONE",
                "MEDIUM": "NONE",
                "HIGH": "NONE"
            }
        };

        return service;
    }
})();
