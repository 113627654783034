(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .directive('smallBuildingAgvUsageTypeSelect', SmallBuildingAgvUsageTypeSelectDirective);

    SmallBuildingAgvUsageTypeSelectCtrl.$inject = ['$scope', '$state', 'smallBuildingAgvUsageTypeGroupFactory', '$timeout', '$rootScope', '$translate'];

    function SmallBuildingAgvUsageTypeSelectCtrl($scope, $state, smallBuildingAgvUsageTypeGroupFactory, $timeout, $rootScope, $translate) {
        var vm = this;
        vm.autcomplete = {};

        vm.translateKey = function(postfix) {
            try {
                return $translate.instant('realEstate.agv.usages.' + postfix);
            } catch (e) {
                console.warn('translation error usageTypeSelect ctr', e);
            }
        };

        vm.showDisabledToast = showDisabledToast;

        vm.updateDescriptionAndImage = function(number) {
            $rootScope.$broadcast('updateImage', {
                rowid: $scope.rowid,
                imageSrc: $scope.image
            });

            $rootScope.$broadcast('updateDescription', {
                rowid: $scope.rowid,
                descriptionNumber: 0,
                descriptionBaseKey: $scope.descriptionBaseKey
            });
        };
        vm.saveSelection = function(selectedItem) {
            if (!selectedItem.parent) {
                $scope.reference = selectedItem;
                vm.model = selectedItem;
                $timeout(function() {
                    // we need timeout because model might not be updated yet (in on-broadcast-receiver-function)
                    $rootScope.$broadcast('getValuePreset');
                })
                if ($scope.isMainUsage && $scope.usagesLength == 1) {
                    $scope.referenceArray.push({
                        percentage: null
                    });
                }
            } else {
                $scope.reference = null;
                vm.model = {
                    'id': '00',
                    'title': 'All',
                    'parent': true
                };
            }
        };

        vm.onSelect = function(selectedItem, $model) {
            vm.updateDescriptionAndImage(selectedItem);
            if (selectedItem.disabled) {
                showDisabledToast(selectedItem.translated);
                vm.model = smallBuildingAgvUsageTypeGroupFactory.getPreviousGroup(selectedItem.title);
                $scope.reference = null;
            } else {
                if (!selectedItem.parent) {
                    showPresetsMarkTooltip();
                    $scope.reference = selectedItem.title;
                    $scope.$broadcast('uiSelectClose');
                    if ($scope.isMainUsage && $scope.usagesLength == 1) {
                        $scope.referenceArray.push({
                            percentage: null
                        });
                    }
                } else {
                    // if (selectedItem.id.length < 3) {
                    //     $scope.reference = null;
                    // }
                    // $scope.reference = "";
                    vm.saveSelection(selectedItem);
                    $scope.loadChildGroupsOf(selectedItem);

                    var element = '#usageToast';
                    if ($(element).hasClass('tooltipstered')) {
                        $(element).tooltipster('destroy');
                    }
                }
            }
        };

        vm.onOpenClose = function(isOpen) {
            if (isOpen) {
                vm.updateDescriptionAndImage();
            }
        };

        function isCurrentStateEditRecord() {
            console.log($state.current.name);
            return $state.current.name === 'process-form.edit-record';
        }

        function showToast(element, text) {
            if ($(element).hasClass('tooltipstered')) {
                var instance = $(element).tooltipster('instance');
                instance.content(text);
                instance.open();
            } else {
                $timeout(function() {
                    $(element).tooltipster({
                        contentAsHTML: true,
                        content: text,
                        theme: 'tooltipster-kw',
                        side: ['right'],
                        zIndex: 3,
                        interactive: true,
                        selfDestruction: true,
                        animation: 'fade',
                        maxWidth: 350,
                        plugins: ['sideTip'],
                        distance: 0,
                        arrow: false,
                        repositionOnScroll: true,
                        timer: 10000,
                        triggerClose: {
                            click: true,
                            scroll: true
                        }
                    }).tooltipster('open');
                });
            }
        }


        function showPresetsMarkTooltip() {
            var translated = '';
            if ($scope.isMainUsage) {
                try {
                    if (isCurrentStateEditRecord()) {

                        translated = $translate.instant('realEstate.agv.form.20_small_building_agv_insurance_general_form.edit-record.presetsMarkTooltip');
                    } else {
                        translated = $translate.instant('realEstate.agv.form.20_small_building_agv_insurance_general_form.input.presetsMarkTooltip');
                    }
                } catch (e) {
                    console.warn('translation error usage type presetMark', e);
                }
                var element = '#usageToast';
                return showToast(element, translated);
            }
        }

        function showDisabledToast(usageTitle) {
            var element = '#usageToast';
            var translated = 'Aufgrund der Datenlage bieten wir die Nutzung "' + usageTitle + '" noch nicht an.';
            return showToast(element, translated);
        }

        vm.model = smallBuildingAgvUsageTypeGroupFactory.getById($scope.reference);
        if (vm.model.id) {
            vm.model = {
                'id': vm.model.id,
                'title': vm.model.id,
                'parent': vm.model.parent,
                'translated': vm.translateKey(vm.model.id),
                'disabled': vm.model.disabled
            };
        } else {
            vm.model = {
                'id': '00',
                'title': 'All',
                'parent': true
            };
        }

        /**
         * This watch checks if the reference changes and updates the whole component.
         * This can happen if we load data from the db async.
         */
        $scope.$watch('reference', function(newValue, oldValue) {
            if (newValue && angular.isDefined(newValue)) {
                vm.model = smallBuildingAgvUsageTypeGroupFactory.getById(newValue);
                if (vm.model.id) {
                    vm.model = {
                        'id': vm.model.id,
                        'title': vm.model.id,
                        'parent': vm.model.parent,
                        'translated': vm.translateKey(vm.model.id),
                        'disabled': false
                    };
                    $scope.breadcrumbs = [{ 'id': '00', 'title': '00', 'parent': true }];
                    var groupId = smallBuildingAgvUsageTypeGroupFactory.getGroupIdById(vm.model.id);
                    if (groupId !== 0) {
                        smallBuildingAgvUsageTypeGroupFactory.getAllPreviousGroups(vm.model.id)
                            .forEach(function(prevGroup) {
                                $scope.breadcrumbs.push({
                                    'id': prevGroup.id,
                                    'title': prevGroup.id,
                                    'parent': prevGroup.parent,
                                    'translated': vm.translateKey(prevGroup.id),
                                    'disabled': prevGroup.disabled
                                });
                            });
                        var group = smallBuildingAgvUsageTypeGroupFactory.getById(groupId)
                        $scope.loadChildGroupsOf(group);
                    //    $select.isActive(this) we need to set the correct isActive. otherwise red will stay on position [0] of current loaded group...
                    }
                    var gs = smallBuildingAgvUsageTypeGroupFactory.load(groupId);
                    $timeout(function() {
                        $rootScope.$broadcast('getValuePreset');
                    })
                } else {
                    vm.model = {
                        'id': '00',
                        'title': 'All',
                        'parent': true
                    };
                }
            }
        },true);

        vm.clearReference = function() {
            $scope.reference = null;
        };

        $scope.$on('showSliderPresetTooltip', function() {
            showPresetsMarkTooltip();
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            if (vm.model != null) {
                vm.model.translated = vm.translateKey(vm.model.title);
            }
            for (var i = 0; i < $scope.groups.length; i++) {
                if ($scope.groups[i].title != null) {
                    $scope.groups[i].translated = vm.translateKey($scope.groups[i].title);
                }
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
    }


    SmallBuildingAgvUsageTypeSelectDirective.$inject = ['smallBuildingAgvUsageTypeGroupFactory', '$timeout', '$rootScope', '$translate'];

    function SmallBuildingAgvUsageTypeSelectDirective(smallBuildingAgvUsageTypeGroupFactory, $timeout, $rootScope, $translate) {
        return {
            restrict: 'E',
            scope: {
                isMainUsage: '=',
                usagesLength: '@',
                reference: '=',
                percentage: '=',
                referenceArray: '=',
                rowid: '=',
                image: '=image',
                required: '=required',
                isDisabled: '=',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                index: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?'
            },
            templateUrl: 'app/small_building_agv_insurance_form/10_small_building_agv_insurance_input/20_small_building_agv_insurance_general_form/small_building_agv_usage/small-building-agv-usage.tpl.html',
            controller: SmallBuildingAgvUsageTypeSelectCtrl,
            controllerAs: 'vm',
            link: function(scope, tElement, tAttrs, ctrl) {
                try {
                    scope.translateKey = function(postfix) {
                        return $translate.instant('realEstate.agv.usages.' + postfix);
                    };
                } catch (e) {
                    console.warn('translation error usage type select', e);
                }

                scope.breadcrumbs = [{ 'id': '00', 'title': '00', 'parent': true }];
                scope.model = smallBuildingAgvUsageTypeGroupFactory.getById(scope.reference);

                var gs = [];
                if (scope.reference) {
                    var model = smallBuildingAgvUsageTypeGroupFactory.getById(scope.reference);
                    var groupId = smallBuildingAgvUsageTypeGroupFactory.getGroupIdById(model.id);
                    if (groupId !== 0) {
                        smallBuildingAgvUsageTypeGroupFactory.getAllPreviousGroups(model.id)
                            .forEach(function(prevGroup) {
                                scope.breadcrumbs.push({
                                    'id': prevGroup.id,
                                    'title': prevGroup.id,
                                    'parent': prevGroup.parent,
                                    'translated': scope.translateKey(prevGroup.id),
                                    'disabled': prevGroup.disabled
                                });
                            });
                    }
                    gs = smallBuildingAgvUsageTypeGroupFactory.load(groupId);
                    //adds the first usage on load.
                    if (scope.isMainUsage && scope.usagesLength == 1) {
                        scope.referenceArray.push({
                            percentage: null
                        });
                    }
                } else {
                    gs = smallBuildingAgvUsageTypeGroupFactory.load('00');
                }

                var enhanceGroupFn = function(gs) {
                    for (var i in scope.groups) {
                        var oldg = scope.groups[i];
                        oldg.noshow = true;
                    }

                    var enhancedGroups = [];
                    gs.forEach(function(group) {
                        if (!scope.isMainUsage || (group.title !== 'VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN' && group.title !== 'VERKEHRSBAUTEN__GARAGEN_FREISTEHEND')) {
                            // except for VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN and VERKEHRSBAUTEN__GARAGEN_FREISTEHEND don't enhance groups
                            enhancedGroups.push({
                                'id': group.id,
                                'title': group.id,
                                'parent': group.parent,
                                'translated': scope.translateKey(group.id),
                                'disabled': group.disabled
                            });
                        }
                    });
                    scope.groups = enhancedGroups;
                };

                // add property translated to to each group in groups
                enhanceGroupFn(gs);
                // for breadcrumbs check selectize-choices-template-cache.js
                scope.loadChildGroupsOf = function(group) {
                    console.log(group)
                    scope.breadcrumbs.push(group);
                    var gs = smallBuildingAgvUsageTypeGroupFactory.load(group.id);
                    enhanceGroupFn(gs);
                };

                scope.navigateBackTo = function(crumb) {
                    var index = _.findIndex(scope.breadcrumbs, { id: crumb.id });
                    scope.breadcrumbs.splice(index + 1, scope.breadcrumbs.length);
                    var gs = smallBuildingAgvUsageTypeGroupFactory.load(_.last(scope.breadcrumbs).id);
                    // ctrl.onSelect(crumb);
                    ctrl.saveSelection(crumb);
                    ctrl.updateDescriptionAndImage(crumb);
                    enhanceGroupFn(gs);
                };

                // Only allow numbers in the input field
                // The simpler 'type="number"' is causing the curser to jump
                // if a letter is entered.
                tElement.find('.percentageInputField').on('change keyup', function() {
                    // Remove invalid characters
                    var sanitized = $(this).val().replace(/[^0-9]/g, '');
                    // Update value

                    $(this).val(sanitized);
                });

                // Used for the validation
                var toggle = tElement.find('.drop2downUsageTypesOuter');
                $(toggle).attr('id', tAttrs.referenceArray + '[' + Number(scope.index) + '].type.drop2down-toggle');
                var percentageElement = tElement.find('.percentageInputField');
                $(percentageElement).attr('id', tAttrs.referenceArray + '[' + Number(scope.index) + '].percentage.input');
            }

        };
    }

})();
