(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RebuildHowtoEditRecordController', RebuildHowtoEditRecordController);

    RebuildHowtoEditRecordController.$inject = ['$scope', '$stateParams', '$state'];

    function RebuildHowtoEditRecordController($scope, $stateParams, $state) {
        $scope.data = $stateParams.data;
    }

})();

