(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('referenceUsageTypeGroupFactory', ReferenceUsageTypeGroupFactory);

    ReferenceUsageTypeGroupFactory.$inject = [];

    function ReferenceUsageTypeGroupFactory() {
        var data = {
            "00": [
                //fu = funcionalUnit this is used by functionalUnit Component
                {"id": "01", "title": "WOHNBAUTEN__TITLE", "parent": true},
                {"id": "02", "title": "SCHULBAUTEN__TITLE", "parent": true},
                {"id": "03", "title": "LAGERBAUTEN__TITLE", "parent": true},
                {"id": "03A", "title": "PRODUKTIONSBAUTEN__TITLE", "parent": true},
                {"id": "04", "title": "LANDWIRTSCHAFT__TITLE", "parent": true},
                {"id": "05", "title": "TECHNISCHE_ANLAGEN__TITLE", "parent": true},
                {"id": "06", "title": "LADENBAUTEN_HANDELSBAUTEN__TITLE", "parent": true},
                {"id": "06A", "title": "BUEROBAUTEN_VERWALTUNGSBAUTEN_DIENSTLEISTUNGSBAUTEN__TITLE", "parent": true},
                {"id": "07", "title": "JUSTIZBAUTEN__TITLE", "parent": true},
                {"id": "08", "title": "GESUNDHEITSBAUTEN__TITLE", "parent": true},
                {"id": "09", "title": "KULTUS__TITLE", "parent": true},
                {"id": "10", "title": "KULTUR__TITLE", "parent": true},
                {"id": "11", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE", "parent": true},
                {"id": "12", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TITLE", "parent": true},
                {"id": "13", "title": "VERKEHRSBAUTEN__TITLE", "parent": true},
                {"id": "14", "title": "SCHUTZBAUTEN__TITLE", "parent": true}
            ],
            "01": [
                {"id": "0101", "title": "WOHNBAUTEN__MFH", "parent": false,  "fU": "10"},
                {"id": "0102", "title": "WOHNBAUTEN__TERRASSENHAEUSER", "parent": false,  "fU": "10"},
                {"id": "0103", "title": "WOHNBAUTEN__EFH_REIHEN", "parent": false,  "fU": "10"},
                {"id": "0104", "title": "WOHNBAUTEN__ALTERSWOHNUNGEN", "parent": false, "fU": "10"},
                {"id": "0105", "title": "WOHNBAUTEN__ALTERSHEIME", "parent": false, "fU": "12"},
                {"id": "0106", "title": "WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME",
                    "parent": false, "fU": "12"
                },
                {"id": "0107", "title": "WOHNBAUTEN__STUDENTENWOHNHAEUSER", "parent": false, "fU": "12"},
                {"id": "0108", "title": "WOHNBAUTEN__PERSONALHAEUSER", "parent": false, "fU": "12"},
                {"id": "0109", "title": "WOHNBAUTEN_BEHELFSWOHNUNGEN", "parent": false, "fU": "10"}
            ],
            "02": [
                {"id": "0201", "title": "SCHULBAUTEN__KINDERGAERTEN_HORTE", "parent": false, "fU": "13"},
                {"id": "0202", "title": "SCHULBAUTEN__VOLKSSCHULEN", "parent": false, "fU": "14"},
                {"id": "0203", "title": "SCHULBAUTEN__BERUFSSCHULEN", "parent": false, "fU": "14"},
                {"id": "0204", "title": "SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN", "parent": false, "fU": "14"},
                {"id": "0205", "title": "SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN", "parent": false, "fU": "15"},
                {"id": "0206", "title": "SCHULBAUTEN__BIBLIOTHEKEN", "parent": false, "fU": "HNF"},
                {"id": "0207", "title": "SCHULBAUTEN__SPEZIELLE_FORSCHUNGSBAUTEN", "parent": true}
            ],
            "0207": [
                {"id": "020701", "title": "SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR", "parent": false, "fU": "16"},
                {"id": "020702", "title": "SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR", "parent": false, "fU": "16"}
            ],
            "03": [
                {"id": "0301", "title": "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN", "parent": true},
                {"id": "0302", "title": "LAGERBAUTEN_MEHRSTOECKIGE", "parent": false, "fU": "HNF"},
                {"id": "0303", "title": "LAGERBAUTEN__MECHANISIERTE_LAGER_KUEHLLAGER", "parent": false, "fU": "HNF" },
                {"id": "0304", "title": "LAGERBAUTEN__SILOBAUTEN", "parent": false, "fU": "HNF"},
                {"id": "0305", "title": "LAGERBAUTEN__VERTEILZENTRALEN", "parent": true, "fU": "HNF"}
            ],
            "0301": [
                {"id": "030101", "title": "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_UNBEHEIZT", "parent": false,"fu":"HNF"},
                {"id": "030102", "title": "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_BEHEIZT", "parent": false, "fu":"HNF"}
            ],
            "0305":[
                {"id": "030501", "title": "LAGERBAUTEN__VERTEILZENTRALEN_NONFOOD", "parent": false,"fu":"HNF"},
                {"id": "030502", "title": "LAGERBAUTEN__VERTEILZENTRALEN_FOOD", "parent": false,"fu":"HNF"}
            ],
            "03A": [
                {"id": "0306", "title": "LAGERBAUTEN__INDUSTRIEHALLEN", "parent": false, "fU": "HNF"},
                {"id": "0307", "title": "LAGERBAUTEN_PRODUKTIONSBAUTEN", "parent": false, "fU": "HNF"}],
            "04": [
                {"id": "0402", "title": "LANDWIRTSCHAFT__SCHEUNEN", "parent": false, "fU": "HNF"},
                {"id": "0403", "title": "LANDWIRTSCHAFT_STALLUNGEN", "parent": false, "fU": "HNF"},
                {"id": "0404", "title": "LANDWIRTSCHAFT__LANDWIRTSCHAFTLICHE_ANLAGEN", "parent": false, "fU": "HNF"},
                {"id": "0405", "title": "LANDWIRTSCHAFT__TIERHEIME", "parent": false, "fU": "HNF"},
                {"id": "0406", "title": "LANDWIRTSCHAFT__TIERSPITAELER", "parent": false, "fU": "HNF"}
            ],
            "05":[
                {"id": "050101", "title": "TECHNISCHE_ANLAGEN__HEIZZENTRALE", "parent": false, "fU": "NONE"},
                {"id": "050102", "title": "TECHNISCHE_ANLAGEN__UNTERWERK", "parent": false, "fU": "NONE"},
                {"id": "0503", "title": "TECHNISCHE_ANLAGEN__WASSERAUFBEREITUNGSANLAGEN", "parent": false, "fU": "NONE"},
                {"id": "0504", "title": "TECHNISCHE_ANLAGEN__KEHRICHTVERBRENNUNGSANLAGEN", "parent": false, "fU": "NONE"},
                {"id": "0505", "title": "TECHNISCHE_ANLAGEN__TANKANLAGEN", "parent": false, "fU": "NONE"},
                {"id": "0506", "title": "TECHNISCHE_ANLAGEN__SONDERABFALLSAMMELSTELLE", "parent": false, "fU": "NONE"}
            ],
            "06": [
                {"id": "0601", "title": "LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL", "parent": false, "fU": "HNF"},
                {"id": "060201", "title": "LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL", "parent": false, "fU": "HNF"},
                {"id": "060202", "title": "LADENBAUTEN_HANDELSBAUTEN__GROSSMAERKTE", "parent": false, "fU": "HNF"},
                {"id": "060301", "title": "LADENBAUTEN_HANDELSBAUTEN__MARKTHALLEN", "parent": false, "fU": "HNF"},
                {"id": "060302", "title": "KULTUR__MESSE", "parent": false, "fU": "HNF"}
            ],
            "06A": [
                {"id": "0605", "title": "BUEROBAUTEN", "parent": true},
                {"id": "060602", "title": "BANKEN", "parent": false, "fU": "16"},
                {"id": "060601", "title": "VERWALTUNGSBAUTEN", "parent": false, "fU": "16"},
                {"id": "0607", "title": "BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER", "parent": false, "fU": "16"},
                {"id": "0608", "title": "LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER", "parent": false, "fU": "16"}
            ],
            "0605": [
                {"id": "060501", "title": "BUEROBAUTEN__EDELROHBAU", "parent": false, "fU": "16"},
                {"id": "060502", "title": "BUEROBAUTEN__VOLLAUSBAU", "parent": false, "fU": "16"}],
            "07": [
                {"id": "0701", "title": "JUSTIZ__GERICHT", "parent": false, "fU": "NONE"},
                {"id": "0702", "title": "JUSTIZ__POLIZEI", "parent": false, "fU": "NONE"},
                {"id": "0703", "title": "JUSTIZ__STRAFVOLLZUGSANSTALTEN", "parent": false, "fU": "17"},
                {"id": "0704", "title": "JUSTIZ__WIEDEREINGLIEDERUNGSSTAETTEN", "parent": false, "fU": "NONE"}
            ],
            "08": [
                {"id": "0801", "title": "GESUNDHEITSBAUTEN__TAGESHEIME", "parent": false, "fU": "NONE"},
                {"id": "0803", "title": "GESUNDHEITSBAUTEN__ARZTPRAXEN", "parent": false, "fU": "HNF"},
                {"id": "0804", "title": "GESUNDHEITSBAUTEN__KRANKENHAEUSER", "parent": true },
                // {"id": "0805", "title": "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN", "parent": true},
                {"id": "0806", "title": "GESUNDHEIT__PFLEGEHEIME", "parent": false, "fU": "19"}
            ],
            "0804":[
                {"id": "080401", "title": "GESUNDHEITSBAUTEN__KRANKENHAEUSER_BETTENTRAKT", "parent": false, "fU": "18"}
                // {"id": "080402", "title": "GESUNDHEITSBAUTEN__KRANKENHAEUSER_Untersuchung", "parent": false, "fU": "NONE"},
                // {"id": "080403", "title": "GESUNDHEITSBAUTEN__KRANKENHAEUSER_Logistik", "parent": false, "fU": "NONE"}
            ],
            // "0805":[
            //     {"id": "080501", "title": "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN_BETTENTRAKT", "parent": false, "fU": "18"},
            //     {"id": "080502", "title": "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN_UNTERSUCHUNG", "parent": false, "fU": "NONE"},
            //     {"id": "080503", "title": "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN_LOGISTIK", "parent": false, "fU": "NONE"},
            //     {"id": "080503", "title": "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN_FORSCHUNG", "parent": false, "fU": "NONE"}
            // ],
            // "0806":[
            //     {"id": "080601", "title": "GESUNDHEIT__PFLEGEHEIME", "parent": false,"fU": "18"},
            //     {"id": "080602", "title": "GESUNDHEIT__REHABILITATIONSZENTREN", "parent": false, "fU": "18"},
            //     {"id": "080603", "title": "GESUNDHEIT__SANATORIEN", "parent": false,"fU": "18"}
            // ],
            "09": [
                {"id": "0901", "title": "KULTUS__KAPELLE", "parent": false, "fU": "HNF"},
                {"id": "0902", "title": "KULTUS__KIRCHE", "parent": false, "fU": "HNF"},
                {"id": "0903", "title": "KULTUS__KIRCHGEMEINDEHAEUSER", "parent": false, "fU": "NONE"},
                {"id": "0904", "title": "KULTUS__FRIEDHOF", "parent": false, "fU": "NONE"},
                {"id": "0905", "title": "KULTUS__ABDANKUNG", "parent": false, "fU": "NONE"},
                {"id": "0906", "title": "KULTUS__KREMATORIUM", "parent": false, "fU": "NONE"},
                {"id": "0907", "title": "KULTUS__KLOESTER", "parent": false, "fU": "NONE"}
            ],
            "10": [
                {"id": "1001", "title": "KULTUR__AUSSTELLUNGEN", "parent": false, "fU": "HNF"},
                {"id": "1002", "title": "KULTUR__MUSEEN", "parent": false, "fU": "HNF"},
                {"id": "1004", "title": "KULTUR__KULTUR_GEMEINSCHAFTSZENTREN", "parent": false, "fU": "20"},
                {"id": "1011", "title": "KULTUR__SAAL", "parent": false, "fU": "20"},

                {"id": "1006", "title": "KULTUR__KONZERTE", "parent": false, "fU": "21"},
                {"id": "1007", "title": "KULTUR__PAVILLONS", "parent": false, "fU": "HNF"},
                {"id": "1008", "title": "KULTUR__KINO", "parent": false, "fU": "21"},
                {"id": "1009", "title": "KULTUR__DISCO", "parent": false, "fU": "NONE"},
                {"id": "1010", "title": "KULTUR__KONGRESS", "parent": false, "fU": "20"},
                {"id": "1012", "title": "KULTUR__FESTHALLEN", "parent": false, "fU": "20"},
                {"id": "1013", "title": "KULTUR__RADIO_FERNSEH_FILM", "parent": false, "fU": "21"}
            ],
            "11": [
                {"id": "1101", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__RESTAURANT", "parent": false, "fU": "23"},
                {"id": "1102", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HOTELBAUTEN", "parent": false, "fU": "12"},
                {"id": "1104", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__KANTINEN", "parent": false, "fU": "23"},
                {"id": "1105", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HERBERGEN", "parent": false, "fU": "18"},
                {"id": "1107", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__SCHIRMHUETTEN", "parent": false, "fU": "18"}
            ],
            "12": [
                {"id": "1201", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TURNHALLEN_SPORTHALLEN_MEHRZWECKHALLEN", "parent": false, "fU": "22"},
                {"id": "1202", "title": "FREIZEITBAUTEN_SPORTBAUTEN__STADIONANLAGEN", "parent": false, "fU": "NONE"},
                {"id": "1203", "title": "FREIZEITBAUTEN_SPORTBAUTEN__SPORTPLAETZE", "parent": false, "fU": "NONE"},

                {"id": "120401", "title": "FREIZEITBAUTEN_SPORTBAUTEN__TRIBUENENBAUTEN", "parent": false, "fU": "NONE"},
                {"id": "120402", "title": "FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE", "parent": false, "fU": "NONE"},
                {"id": "120501", "title": "FREIZEITBAUTEN_SPORTBAUTEN__KUNSTEISBAHN_FREIBAD", "parent": false, "fU": "HNF"},
                {"id": "120502", "title": "FREIZEITBAUTEN_SPORTBAUTEN__FREIBAD", "parent": false, "fU": "NONE"},
                {"id": "1206", "title": "FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER", "parent": false, "fU": "HNF"},
                {"id": "1207", "title": "FREIZEITBAUTEN_SPORTBAUTEN__REITHALLEN", "parent": false, "fU": "22"},
                {"id": "1209", "title": "FREIZEITBAUTEN_SPORTBAUTEN__FREIZEITZENTREN", "parent": false, "fU": "NONE"},

                {"id": "121001", "title": "FREIZEITBAUTEN_SPORTBAUTEN__KINDERSPIELPLAETZE", "parent": false, "fU": "NONE"},
                {"id": "121002", "title": "FREIZEITBAUTEN_SPORTBAUTEN__SKATEPARKS", "parent": false, "fU": "NONE"},

                {"id": "1212", "title": "FREIZEITBAUTEN_SPORTBAUTEN__ZOO", "parent": true},
                {"id": "1213", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GAERTEN", "parent": true},
                {"id": "1214", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__PAVILLONS", "parent": true}
            ],
            "1212": [
                {"id": "121201", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GARTENANLAGE", "parent": false, "fU": "NONE"},
                 {"id": "121202", "title": "FREIZEITBAUTEN_SPORTBAUTEN__ZOO_TIERHAEUSER", "parent": false, "fU": "NONE"}
            ],
            "1213":[
                {"id": "121301", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GARTENANLAGE", "parent": false, "fU": "NONE"},
                {"id": "121302", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GEWAECHSHAEUSER", "parent": false, "fU": "HNF"}
            ],
            "13": [
                { "id": "130101", "title": "VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH", "parent": false, "fU": "23" },
                { "id": "130103", "title": "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN", "parent": false, "fU": "23" },
                { "id": "130104", "title": "VERKEHRSBAUTEN__GARAGEN_FREISTEHEND", "parent": false, "fU": "23" },
                { "id": "1304", "title": "VERKEHRSBAUTEN__BUSBAHNHOF", "parent": false, "fU": "23" },
                { "id": "1303", "title": "VERKEHRSBAUTEN__WERKHOEFE", "parent": false, "fU": "NONE" },
                { "id": "1305", "title": "VERKEHRSBAUTEN__ZOLL", "parent": false, "fU": "NONE" }
            ],
            "14": [
                {"id": "1401", "title": "SCHUTZBAUTEN__KASERNEN", "parent": false, "fU": "HNF"},
                {"id": "1402", "title": "SCHUTZBAUTEN__ZEUGHAEUSER", "parent": false, "fU": "HNF"},
                {"id": "1403", "title": "SCHUTZBAUTEN__ZIVILSCHUTZANLAGEN", "parent": false, "fU": "HNF"},
                {"id": "1404", "title": "SCHUTZBAUTEN__SANITAET", "parent": false, "fU": "NONE"},
                {"id": "1405", "title": "SCHUTZBAUTEN__GESCHUETZTE_OPERATIONSSTELLEN", "parent": false, "fU": "HNF"},
                {"id": "140601", "title": "SCHUTZBAUTEN__AUSBILDUNGSZENTREN", "parent": false, "fU": "HNF"},
                {"id": "140602", "title": "SCHUTZBAUTEN__BRAND_ZIVILSCHUTZSIMULATION", "parent": false, "fU": "NONE"},
                {"id": "1407", "title": "SCHUTZBAUTEN__FEUERWEHRGEBAEUDE", "parent": false, "fU": "HNF"},
                {"id": "1408", "title": "SCHUTZBAUTEN__SCHULUNGSBAUTEN", "parent": false, "fU": "HNF"}
            ]
        };

        function getAllPreviousIds(id) {
            var ret = [];
            while (!angular.equals(id, '00')) {
                id = getGroupIdById(id);
                ret.push(id);
            }
            return ret;
        }

        function getGroupIdById(id) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (id === obj.id) {
                        return key;
                    }
                }
            }
            return "00";
        }

        function getById(id) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    // eslint-disable-next-line eqeqeq
                    if (id == obj.id) {
                        return obj;
                    }
                }
            }
            return {};
        }

        function getByTitle(title) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (title === obj.title) {
                        return obj;
                    }
                }
            }
            return {};
        }

        return {
            load: function (id) {
                return data[id];
            },
            getByTitle: function (title) {
                return getByTitle(title);
            },
            getGroupIdById: function (id) {
                return getGroupIdById(id);
            },
            getById: function (id) {
                return getById(id);
            },
            getAllPreviousGroups: function (groupId) {
                var prevGroups = [];
                var prevIds = getAllPreviousIds(groupId);
                prevIds.forEach(function (prevId) {
                    var prevGroup = getById(prevId);
                    if (!angular.equals({}, prevGroup)) {
                        prevGroups.push(getById(prevId));
                    }
                });
                return prevGroups;
            },
            getPreviousGroup: function (title) {
                var obj = getByTitle(title);
                var groupId = getGroupIdById(obj.id);
                return getById(groupId);
            },
            getTitleOfPrevious: function (title) {
                var obj = getByTitle(title);
                var groupId = getGroupIdById(obj.id);
                return getById(groupId).title;
            }
        };
    }
})();
