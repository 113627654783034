(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .directive('iframeOnload', IframeOnloadDirective);

    IframeOnloadDirective.$inject = [];

    function IframeOnloadDirective() {
        return {
            scope: {
                callBack: '&iframeOnload'
            },
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                tElement.on('load', function() {
                    return scope.callBack();
                })
            }
        };
    }

})();
