(function () {
    'use strict';

    // TODO: Very similar to kw-box-plot => Make a common abstraction.

    angular
        .module('kennwerteApp')
        .directive('kwBoxGapPlot', KwBoxPlotDirective);

    KwBoxPlotDirective.$inject = ['d3Service'];

    /**
     * @param d3Service
     * @returns {{template: string, scope: {margin: string, data: string, doDrawAxis: string, whConfig: string}, link: link, restrict: string}}
     * @constructor
     */
    function KwBoxPlotDirective(d3Service) {
        return {
            restrict: 'EA',
            scope: {
                data: '=?',
                doDrawAxis: '=?',
                margin: '=?',
                whConfig: '=?'
            },
            template: '',
            link: function (scope, element, attrs) {

                var origSvg = undefined;

                function addOutliers(outliers, from, to, fromY, height) {
                    if (from === to) return;
                    outliers
                        .append("line")
                        .attr("y1", function (_) {
                            return fromY + height / 2;
                        })
                        .attr("x1", function (_) {
                            return from;
                        })
                        .attr("y2", function (_) {
                            return fromY + height / 2;
                        })
                        .attr("x2", function (_) {
                            return to;
                        })
                        .style("stroke-dasharray", ("0.3, 9"))
                        .attr("stroke", "#f2cfa2")//#f7e1c5
                        .attr("stroke-width", 6)
                        .attr("stroke-linecap", "round")
                        .attr("fill", "none");

                }

                function addWhisker(nodes, from, to, fromY, height) {
                    if (from === to) return;
                    nodes.append("rect")
                        .attr("height", height)
                        .attr("width", function (_) {
                            return to - from;
                        })
                        .attr("y", function (_) {
                            return fromY;
                        })
                        .attr("x", function (_) {
                            return from;
                        })
                        .attr("fill", "#f1ca98");
                }

                function addQuartile(nodes, from, to, fromY, height) {
                    if (from === to) return;
                    nodes.append("rect")
                        .attr("height", height)
                        .attr("width", function (_) {
                            return to - from;
                        })
                        .attr("y", function (_) {
                            return fromY;
                        })
                        .attr("x", function (_) {
                            return from;
                        })
                        .attr("fill", "#e8a854");
                }

                function addMedian(nodes, from, to, fromY, height) {
                    nodes
                        .append("line")
                        .attr("y1", fromY)
                        .attr("x1", from)
                        .attr("x2", to)
                        .attr("y2", fromY + height)
                        .attr("stroke", "#bb7c7a")
                        .attr("stroke-width", 4)
                        .attr("fill", "none");
                }

                function draw(d3) {

                    var width, height, barWidth, margin;
                    if (scope.whConfig && scope.margin) {
                        width = scope.whConfig['width'];
                        height = scope.whConfig['height'];
                        barWidth = scope.whConfig['barWidth'];
                        margin = scope.margin;
                    } else {
                        width = 402;
                        height = 130;
                        barWidth = 60;
                        margin = {top: 5, right: 10, bottom: 10, left: 10};
                    }

                    width = width - margin.left - margin.right;
                    height = height - margin.top - margin.bottom;

                    var totalWidth = width + margin.left + margin.right;
                    var totalHeight = height + margin.top + margin.bottom;

                    // Prepare the data for the box plots
                    var boxPlotData = scope.data;
                    // Compute an ordinal xScale for the keys in boxPlotData
                    var xScale = d3.scaleLinear()
                        .domain([0, 1])
                        .range([0, height]);

                    var gapFrom = (boxPlotData[0].gap) ? boxPlotData[0].gap[0] : undefined;
                    var gapTo = (boxPlotData[0].gap) ? boxPlotData[0].gap[1] : undefined;

                    var min = boxPlotData[0]["min"] != null ? boxPlotData[0]["min"] : 0;
                    var max = boxPlotData[0]["max"];
                    if (!max) {
                        max = boxPlotData[0]["whiskers"][1];
                    }
                    var yScale = d3.scaleLinear()
                        .domain([min, max])
                        .range([0, width]);

                    // Setup the svg and group we will draw the box plot in
                    origSvg = d3.select(element[0]).append('svg');
                    var svg = origSvg
                        .attr("width", totalWidth)
                        .attr("height", totalHeight)
                        .append("g")
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                    if (scope.doDrawAxis) {
                        // Move the top axis 80 pixels along y
                        var axisTopG = svg.append("g").attr("transform", "translate(0,100)");
                    }

                    // Setup the group the box plot elements will render in
                    var g = svg.append("g")
                        .attr("transform", "translate(0,0)");


                    // Draw the outlier dots
                    var outliers = g.selectAll(".horizontalLines")
                        .data(boxPlotData)
                        .enter();

                    if ((boxPlotData[0].outliers[0] <= gapFrom && gapTo <= boxPlotData[0].outliers[1])) {
                        addOutliers(outliers, yScale(boxPlotData[0].outliers[0]), yScale(gapFrom), xScale(boxPlotData[0].key), barWidth);
                        addOutliers(outliers, yScale(gapTo), yScale(boxPlotData[0].outliers[1]), xScale(boxPlotData[0].key), barWidth);
                    } else {
                        addOutliers(outliers, yScale(boxPlotData[0].outliers[0]), yScale(boxPlotData[0].outliers[1]), xScale(boxPlotData[0].key), barWidth);
                    }

                    // Draw the boxes of the box plot, filled in white and on top of vertical lines
                    var nodes = svg.selectAll(".rect")
                        .data(boxPlotData)
                        .enter()
                        .append("g")
                        .classed('rect', true);

                    if ((boxPlotData[0]['whiskers'][0] <= gapFrom && gapTo <= boxPlotData[0]['whiskers'][1])) {
                        addWhisker(nodes, yScale(boxPlotData[0]['whiskers'][0]), yScale(gapFrom), xScale(boxPlotData[0].key), barWidth);
                        addWhisker(nodes, yScale(gapTo), yScale(boxPlotData[0]['whiskers'][1]), xScale(boxPlotData[0].key), barWidth);
                    } else {
                        addWhisker(nodes, yScale(boxPlotData[0]['whiskers'][0]), yScale(boxPlotData[0]['whiskers'][1]), xScale(boxPlotData[0].key), barWidth);
                    }

                    if ((boxPlotData[0]['quartile'][0] <= gapFrom && gapTo <= boxPlotData[0]['quartile'][2])) {
                        addQuartile(nodes, yScale(boxPlotData[0]['quartile'][0]), yScale(gapFrom), xScale(boxPlotData[0].key), barWidth);
                        addQuartile(nodes, yScale(gapTo), yScale(boxPlotData[0]['quartile'][2]), xScale(boxPlotData[0].key), barWidth);
                    } else {
                        addQuartile(nodes, yScale(boxPlotData[0]['quartile'][0]), yScale(boxPlotData[0]['quartile'][2]), xScale(boxPlotData[0].key), barWidth);
                    }

                    addMedian(nodes, yScale(boxPlotData[0]['quartile'][1]), yScale(boxPlotData[0]['quartile'][1]), xScale(boxPlotData[0].key), barWidth);

                    if (scope.doDrawAxis) {
                        // Setup a series axis on the top
                        var axis = d3.axisBottom(yScale)
                            .ticks(5)
                            .tickFormat(d3.format(".0%"));
                        axisTopG.append("g")
                            .call(axis);
                    }
                }

                function remove(d3) {
                    origSvg.remove();
                }

                d3Service.load().then(function (d3) {
                    draw(d3);
                });

                scope.$watch('data', function(newVal, oldVal) {
                    if (newVal !== undefined && newVal !== oldVal && newVal) {
                        d3Service.load().then(function (d3) {
                            // TODO: remove old svg
                            remove(d3);
                            draw(d3);
                        });
                    }
                }, true);

            }
        };
    }

})();
