(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('DepositService', DepositService);

    DepositService.$inject = ['$http'];

    function DepositService($http) {
        return {
            initAnonymousDeposit: function (initAnonymousDepositDTO, responseFunction, errorFunction) {
                return $http.post('api/deposit/anonymous/init', initAnonymousDepositDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            initDeposit: function (initDepositDTO, responseFunction, errorFunction) {
                return $http.post('api/deposit/init', initDepositDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            finishDeposit: function (transactionId, responseFunction, errorFunction) {
                return $http.get('api/deposit/finish/' + transactionId)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            finishAnonymousDeposit: function (transactionId, responseFunction, errorFunction) {
                return $http.get('api/deposit/anonymous/finish/' + transactionId)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            getState: function (transactionId, responseFunction, errorFunction) {
                return $http.get('api/deposit/state/' + transactionId)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            checkOpenTransactions: function (responseFunction, errorFunction) {
                return $http.post('api/deposit/check_open_transactions')
                    .then(function(response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            }
        };
    }
})();
