(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RebuildAdditionsFormController', RebuildAdditionsFormController);

    RebuildAdditionsFormController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$translate', 'JhiLanguageService', 'RebuildTargetTypeDataService'];

    function RebuildAdditionsFormController($rootScope, $scope, $stateParams, $state, $translate, JhiLanguageService, RebuildTargetTypeDataService) {
        $scope.data = $stateParams.data;
        //Set dynamicTranslations for additionsForm.
        $scope.noDynamicTranslations = false;


        $scope.hasReserve = null;
        $scope.hasContaminant = null;


        var contaminantTranslateKey = 'rebuild.additions.contaminant-cleanup';
        var reserveTranslateKey = 'rebuild.additions.reserve';

        JhiLanguageService.getAll().then(function(values) {
            angular.forEach(values, function(lang, key) {
                $translate([reserveTranslateKey, contaminantTranslateKey],
                    {}, undefined, undefined, lang)
                    .then(function(trans) {
                        if ($scope.hasReserve == null) {
                            $scope.hasReserve = _.find($scope.realEstateContainer.additions, {
                                'label': trans[reserveTranslateKey],
                                'bkp': 'BKP_6_RESERVE'
                            });
                            if ($scope.hasReserve != null) {
                                $scope.hasReserve.label = $translate.instant(reserveTranslateKey);
                            }
                        }
                        if ($scope.hasContaminant == null) {
                            $scope.hasContaminant = _.find($scope.realEstateContainer.additions, {
                                'label': trans[contaminantTranslateKey],
                                'bkp': 'BKP_1_PREPARATIONS'
                            });
                            if ($scope.hasContaminant != null) {
                                $scope.hasContaminant.label = $translate.instant(contaminantTranslateKey);
                            }
                        }
                    }).catch(function(e) {
                    console.warn(e);
                });
            });
        }).finally(function(val) {
            $translate([reserveTranslateKey, contaminantTranslateKey]).then(function(translations) {
                if ($scope.hasReserve == null) {
                    $scope.hasReserve = {
                        label: translations[reserveTranslateKey],
                        value: null,
                        bkp: 'BKP_6_RESERVE',
                        targetType: RebuildTargetTypeDataService.OVERHAUL
                    };
                    $scope.realEstateContainer.additions.unshift($scope.hasReserve);
                }
            }).catch(function(e) {
                console.warn(e);
            });
        }).catch(function(e) {
            console.warn(e);
        });


        var astbestWatch = $scope.$watch('realEstateContainer.constructionYear', function(newVal, oldVal) {
            if ($state.includes('rebuild-form.input')) {
                if (newVal != null && newVal < 1990) {
                    $scope.hasContaminant = _.find($scope.realEstateContainer.additions, {
                        'label': $translate.instant(contaminantTranslateKey),
                        'bkp': 'BKP_1_PREPARATIONS'
                    });
                    if ($scope.hasContaminant == null) {
                        $scope.hasContaminant = {
                            label: $translate.instant(contaminantTranslateKey),
                            value: null,
                            bkp: 'BKP_1_PREPARATIONS',
                            targetType: RebuildTargetTypeDataService.OVERHAUL
                        };
                        $scope.realEstateContainer.additions.unshift($scope.hasContaminant);
                    }
                }
            } else {
                astbestWatch();
            }
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            if ($scope.hasReserve != null) {
                $translate(reserveTranslateKey).then(function(trans) {
                    $scope.hasReserve.label = trans;
                }).catch(function(e) {
                    console.warn(e);
                });
            }
            if ($scope.hasContaminant != null) {
                $translate(contaminantTranslateKey).then(function(trans) {
                    $scope.hasContaminant.label = trans;
                }).catch(function(e) {
                    console.warn(e);
                });
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
    }

})();
