(function() {
    'use strict';

    angular.module('kennwerteApp' )
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('contact', {
                parent: 'app',
                url: '/contact',
                data: {
                    authorities: [],
                    pageTitle: 'contact.pageTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/contact/contact.html',
                        controller: 'ContactController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('contact');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    metaTags: ['MetaTagService', function(MetaTagService) {
                        MetaTagService.setTags({
                            'description': 'Die schweizerische & innovative Lösung zur Ermittlung von Neubau-, Betriebs- und Umbaukosten.',
                            'Keywords': 'Kontakt',
                            // OpenGraph
                            'og:type': 'website',
                            'og:title': 'kennwerte',
                            'og:description': 'Die schweizerische & innovative Lösung zur Ermittlung von Neubau-, Betriebs- und Umbaukosten.',
                            // Twitter
                            'twitter:card': 'summary_large_image',
                            'twitter:site': '@kennwerte',
                            'twitter:title': 'kennwerte',
                            'twitter:description': 'Die schweizerische & innovative Lösung zur Ermittlung von Neubau-, Betriebs- und Umbaukosten.'
                    });
                }]
                }
            });
    }
})();
