(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('BannerImageService', BannerImageService);

    BannerImageService.$inject = ['Principal', '$sessionStorage', '$state'];

    function BannerImageService(Principal, $sessionStorage, $state) {

        var data = [];
        var defaultData = [];
        var agvData = [];
        if ($sessionStorage.bannerImg == null) {
            var bannerImg = {
                lastArchIndex: -1,
                lastImgIndex: -1
            };
            $sessionStorage.bannerImg = bannerImg;
        }

        var isDefaultData = true;
        var isAgv = false;

        setUpData();
        setUpDefaultImages();
        setUpAgvImage();

        var service = {
            getArchData: getARchData,
            getRandomImage: getRandomImage
        };

        return service;

        /**
         * If the user is on the landing page agv or the small building form, return the agvData. Otherwise, return the data
         * @returns the data object.
         */
        function getARchData() {
            if ($state.includes('landing_page_agv') || $state.includes('small-building-form')) {
                isAgv = true;
                return agvData;
            }
            // if (Principal.showBuildingCosts()) {
            isDefaultData = false;
            return data;
            // } else {
            //     isDefaultData = true;
            //     return defaultData;
            // }
        }

        /**
         *  Get a random image from the data, but don't get the same image twice in a row
         * @returns An object with two properties: image and headerStyle.
         */
        function getRandomImage() {
            var data = getARchData();

            var randomNumber = Math.floor(Math.random() * data.length);
            var wasSameOffice = randomNumber === $sessionStorage.bannerImg.lastArchIndex;
            while (!isDefaultData && data.length > 1 && randomNumber === $sessionStorage.bannerImg.lastArchIndex) {
                randomNumber = Math.floor(Math.random() * data.length);
            }
            var selectedOffice = data[randomNumber];
            var randomImageNumber = Math.floor(Math.random() * selectedOffice.images.length);

            while (selectedOffice.images.length > 1 && wasSameOffice && randomImageNumber === $sessionStorage.bannerImg.lastImgIndex && data.length > 1) {
                randomImageNumber = Math.floor(Math.random() * selectedOffice.images.length);
            }

            var bannerImg = {
                lastArchIndex: randomNumber,
                lastImgIndex: randomImageNumber
            };
            $sessionStorage.bannerImg = bannerImg;

            return {
                image: selectedOffice.images[randomImageNumber],
                headerStyle: selectedOffice.headerStyle[randomImageNumber]
            };
        }

        function setUpData() {
            var bellwald = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/bellwald/background1_descr.svg), url(../assets/images/background/architecture/bellwald/logo_1.png),url(../assets/images/background/architecture/bellwald/background1_1.jpg)',
                        'background-position': 'right 20px bottom 20px,right 20px bottom 57px, center',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '17%, auto 27px, cover'
                    }
                ],
                'headerStyle': [
                    {
                        // "color":"black"
                    }
                ]
            };
            var freia = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/freia/background2_descr.png), url(../assets/images/background/architecture/freia/logo_1.png),url(../assets/images/background/architecture/freia/background2.jpg)',
                        'background-position': 'right 20px bottom 20px,right 20px bottom 57px, center bottom 30%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': 'auto,auto 21px,cover'
                    },
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/freia/background3_descr.png), url(../assets/images/background/architecture/freia/logo_1.png),url(../assets/images/background/architecture/freia/background3.jpg)',
                        'background-position': 'right 20px bottom 20px,right 20px bottom 57px, center top',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': 'auto,auto 21px,cover'
                    }
                ],
                'headerStyle': [
                    {
                        // "color":"black"
                    },
                    {
                        // "color":"black"
                    }
                ]


            };

            var leimg = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/leimg/background1_descr.svg), url(../assets/images/background/architecture/leimg/logo_1.svg),url(../assets/images/background/architecture/leimg/background1.jpg)',
                        'background-position': 'right 20px bottom 20px, right 23px bottom 57px, center top 34%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '14%, auto 10%, cover'
                    }
                ],
                'headerStyle': []
            };

            var mba = {
                'images': [{
                    'background-image':
                        'url(../assets/images/background/architecture/mba/background1_descr.png),url(../assets/images/background/architecture/mba/logo_1.png), url(../assets/images/background/architecture/mba/background1.jpg)',
                    'background-position': 'right 20px bottom 20px,right 20px bottom 57px, center bottom',
                    'background-repeat': 'no-repeat, no-repeat, no-repeat',
                    'background-size': 'auto,auto 21px,cover'
                }],
                'headerStyle': [
                    {
                        // "color": "black"
                    }
                ]
            };
            var m3 = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/m3/background1_descr.svg), url(../assets/images/background/architecture/m3/logo_1.svg),url(../assets/images/background/architecture/m3/background1.jpg)',
                        'background-position': 'right 20px bottom 20px,right 20px bottom 51px, center bottom 10%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '10%,auto 25px,cover'
                    },
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/m3/background2_descr.svg), url(../assets/images/background/architecture/m3/logo_1.svg),url(../assets/images/background/architecture/m3/background2.jpg)',
                        'background-position': 'right 20px bottom 20px,right 20px bottom 51px, center bottom 12%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '10%,auto 25px,cover'
                    }
                ],
                'headerStyle': [
                    {
                        // "color":"black"
                    },
                    {
                        // "color":"black"
                    }

                ]

            };

            var rapp = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/rapp/background1_descr.svg), url(../assets/images/background/architecture/rapp/logo_1.svg),url(../assets/images/background/architecture/rapp/background1.jpg)',
                        'background-position': 'right 20px bottom 20px,right 22px bottom 40px, center top 34%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '9%,auto 4%,cover'
                    }
                ],
                'headerStyle': []
            };
            var shb = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/shb/background1_descr.svg), url(../assets/images/background/architecture/shb/logo_1.svg),url(../assets/images/background/architecture/shb/background1.jpg)',
                        'background-position': 'right 20px bottom 20px,right 9px bottom 44px, center top 34%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '9%,auto 7%,cover'
                    }
                ],
                'headerStyle': []
            };
            var ssa = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/ssa/background3_descr.svg), url(../assets/images/background/architecture/ssa/logo_1.svg),url(../assets/images/background/architecture/ssa/background3_1.jpg)',
                        'background-position': 'right 23px bottom 20px,right 15px bottom 55px, center bottom',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '9%,auto 4%,cover'
                    },
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/ssa/background4_descr.svg), url(../assets/images/background/architecture/ssa/logo_1.svg),url(../assets/images/background/architecture/ssa/background4_1.jpg)',
                        'background-position': 'right 21px bottom 20px,right 15px bottom 55px, center bottom',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '13%,auto 4%,cover'
                    }
                ],
                'headerStyle': []
            };
            var steigerconcept = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/sc/background1_descr.svg), url(../assets/images/background/architecture/sc/logo_1.png),url(../assets/images/background/architecture/sc/background1_6.jpg)',
                        'background-position': 'right 20px bottom 20px,right 19px bottom 52px, center bottom',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '6%,auto 1.5em,cover'
                    }
                ],
                'headerStyle': []
            };


            var unit = {
                'images': [{
                    'background-image':
                        'url(../assets/images/background/architecture/unit/background1_descr.svg), url(../assets/images/background/architecture/unit/logo1.png),url(../assets/images/background/architecture/unit/background1.jpg)',
                    'background-position': 'right 20px bottom 20px,right 20px bottom 56px, center bottom 0%',
                    'background-repeat': 'no-repeat, no-repeat, no-repeat',
                    'background-size': 'auto 4%,auto 4%,cover'
                }
                ],
                'headerStyle': []
            };

            var undend = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/undend/background1_descr.svg), url(../assets/images/background/architecture/undend/logo1.svg),url(../assets/images/background/architecture/undend/background1.jpg)',
                        'background-position': 'right 20px bottom 20px,right 6px bottom 7px, center bottom 0%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '19%,auto 25%,cover'
                    },
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/undend/background1_descr.svg), url(../assets/images/background/architecture/undend/logo1.svg),url(../assets/images/background/architecture/undend/background2.jpg)',
                        'background-position': 'right 20px bottom 20px,right 6px bottom 7px, center bottom 0%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '19%,auto 25%,cover'
                    }
                ],
                'headerStyle': [
                    {
                        // "color":"black"
                    },
                    {
                        // "color":"black"
                    }

                ]
            };

            var walker = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/walker/background1_descr.svg), url(../assets/images/background/architecture/walker/logo1.svg),url(../assets/images/background/architecture/walker/background1.jpg)',
                        'background-position': 'right 20px bottom 20px,right -10px bottom 10px, center bottom 5%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '12%,auto 25%,cover'
                    }
                ],
                'headerStyle': [
                    {
                        // "color":"black"
                    }

                ]


            };

            var weberbrunner = {
                'images': [
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/weberbrunner/background1_descr.svg), url(../assets/images/background/architecture/weberbrunner/logo1.svg),url(../assets/images/background/architecture/weberbrunner/background1.jpg)',
                        'background-position': 'right 20px bottom 20px,right +20px bottom 8%, center bottom 5%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '10%,auto 7%,cover'
                    },
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/weberbrunner/background2_descr.svg), url(../assets/images/background/architecture/weberbrunner/logo1.svg),url(../assets/images/background/architecture/weberbrunner/background2.jpg)',
                        'background-position': 'right 20px bottom 20px,right 20px bottom 8%, center bottom 5%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '8%,auto 7%,cover'
                    },
                    {
                        'background-image':
                            'url(../assets/images/background/architecture/weberbrunner/background3_descr.svg), url(../assets/images/background/architecture/weberbrunner/logo1.svg),url(../assets/images/background/architecture/weberbrunner/background3.jpg)',
                        'background-position': 'right 20px bottom 20px,right 20px bottom 8%, center bottom 5%',
                        'background-repeat': 'no-repeat, no-repeat, no-repeat',
                        'background-size': '10%,auto 7%,cover'
                    }
                ],
                'headerStyle': [
                    {
                        'opacity': '0.8'
                    },
                    {
                        'opacity': '0.8'
                    },
                    {
                        'opacity': '1'
                    }

                ]
            };

            var znz = {
                'images': [{
                    'background-image':
                        'url(../assets/images/background/architecture/znz/background1_descr.png), url(../assets/images/background/architecture/znz/logo_1.png), url(../assets/images/background/architecture/znz/background1.jpg)',
                    'background-position': 'right 20px bottom 20px, right 20px bottom 57px, bottom center',
                    'background-repeat': 'no-repeat, no-repeat,no-repeat',
                    'background-size': 'auto, auto,cover'

                }],
                'headerStyle': [
                    {
                        // "color":"black"
                    }
                ]
            };
            // data.push(znz);
            data.push(bellwald, freia, mba, m3, rapp, shb, ssa, steigerconcept, undend,unit, walker, weberbrunner, znz);
        }

        function setUpDefaultImages() {
            var cityImages = {
                'images': [
                    {
                        'background': 'url(\'../assets/images/background/zurich_chain_12.jpg\') no-repeat center center',
                        'background-size': 'cover'
                    },
                    {
                        'background': 'url(\'../assets/images/background/background_genf_2.jpg\') no-repeat center center',
                        'background-size': 'cover'
                    },
                    {
                        'background': 'url(\'../assets/images/background/background_bern_2.jpg\') no-repeat center center',
                        'background-size': 'cover'
                    },
                    {
                        'background': 'url(\'../assets/images/background/background_locarno_2.jpg\') no-repeat center center',
                        'background-size': 'cover'
                    },
                    {
                        'background': 'url(\'../assets/images/background/background_brugg_2.jpg\') no-repeat center center',
                        'background-size': 'cover'
                    },

                    {
                        'background': 'url(\'../assets/images/background/background_neuchatel_2.jpg\') no-repeat center center',
                        'background-size': 'cover'
                    }
                ],
                'headerStyle': [
                    {
                        // "color":"black"
                    },
                    {
                        // "color":"black"
                    },
                    {
                        // "color": "black"
                    }
                    ,
                    {
                        // "color": "black"
                    },
                    {
                        // "color": "black"
                    },
                    {
                        // "color": "black"
                    }
                ]

            };
            defaultData.push(cityImages);
        }

        function setUpAgvImage() {
            var data = {
                'images': [
                    {
                        'background-image': 'url(../assets/images/background/aarau.png)',
                        'background-position': 'center bottom 15%',
                        'background-repeat': 'no-repeat',
                        'background-size': 'cover'
                    }
                ],
                'headerStyle': [
                    {
                        // "color":"black"
                    }
                ]
            };
            agvData.push(data);
        }
    }
})();
