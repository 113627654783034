(function () {
    "use strict";

    angular.module("kennwerteApp")
        .component("dynamicMultiSelecter", {
            bindings: {
                reference: "=?",
                referenceString: "@?",
                selectables: '=?', // Array which has all selectable options inside.
                realEstateContainer: "=?",
                rowid: "=",
                translateDescriptionBaseKey: "@",
                translateBaseKey: "@",
                image: "=",
                mandatory: "@?",
                placeholder: "@?",
                isDisabled: "=",
                validationMessage: "@?",
                descriptionBaseKey: "@",
                classes: '@?',
                infotext: '@?',
                useShortStrings: '=?',
                inputBlurFun: '&?'
            },
            templateUrl: "app/components/dynamic_multi_selecter/dynamic-multi-selecter.tpl.html",
            controller: DynamicMultiSelecterController
        });

    DynamicMultiSelecterController.$inject = ["$scope", "$translate"];

    function DynamicMultiSelecterController($scope, $translate) {
        var $ctrl = this;
        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + ".label";
            // $ctrl.idForInfoField = $ctrl.translateBaseKey.split(".").join("_");

            // Used for the validation
            // $(toggle).attr("id", $attrs.reference + ".drop2down-toggle");
        };
    }
})();
