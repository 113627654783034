(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('ValidationDataService', ValidationDataService);

    ValidationDataService.$inject = ['$translate', '$filter', '$timeout', 'WithdrawalService', 'SharedControllerFnService'];

    function ValidationDataService($translate, $filter, $timeout, WithdrawalService, SharedControllerFnService) {

        var completeInput = {
            isComplete: false,
            isSuccess: false,
            isCalculating: false
        };
        var elementsWithError = [];
        var validationFunctions = [];
        var zIndex = 1048; // lower than navbar & lower than modal

        return {
            setZindex: setZindex,
            registerValidationFunction: registerValidationFunction,
            registerValidationFunctions: registerValidationFunctions,
            deregisterValidationFunctions: deregisterValidationFunctions,
            deregisterValidationFunction: deregisterValidationFunction,
            clientSideRevalidation: clientSideRevalidation,
            validateAndProceed: validateAndProceed,
            setIsSuccess: setIsSuccess,
            getIsSuccess: getIsSuccess,
            setCalculatingFalse: setCalculatingFalse,
            getIsCalculating: getIsCalculating,
            getIsComplete: getIsComplete,
            resetComplete: resetComplete,
            showValidationError: showValidationError
        };

        /**
         * Deregisters all provided validation functions.
         */
        function deregisterValidationFunctions(validationFun) {
            validationFunctions = [];
        }

        function deregisterValidationFunction(validationFun){
            var index = validationFunctions.indexOf(validationFun);
            if(index >= 0){
                validationFunctions.splice(index,1);
            }
        }

        /**
         * Registers the provided validation function to this data service.
         * The validation functions are applied in the order this register function is called.
         * @param validationFun
         */
        function registerValidationFunction(validationFun) {
            validationFunctions.push(validationFun);
        }

        function setZindex(zi) {
            zIndex = zi;
        }

        /**
         * Registers the provided validation functions to this data service.
         * The validation function are applied in the order as the array defines.
         * @param validationFunArr
         */
        function registerValidationFunctions(validationFunArr) {
            validationFunArr.forEach(function (vf) {
                registerValidationFunction(vf);
            });
        }

        function clientSideRevalidation(realEstateContainer) {
            if (elementsWithError.length > 0) {
                removeValidationErrors();
                clientSideValidation(realEstateContainer);
            }
        }

        function clientSideValidation(realEstate) {
            console.info("Start client side validation.");
            console.warn(realEstate);
            removeValidationErrors();
            // ret: BOOLEAN
            var ret = validationFunctions
                .map(function (vf) {
                    try {
                        var retObj = vf(realEstate);
                        if (!retObj.isValid) {
                            for (var i = 0; i < retObj.elements.length; i++) {
                                showValidationError(retObj.elements[i], retObj.messages[i]);
                            }
                        }
                        return retObj.isValid;
                    } catch (error) {
                        console.error("Failed to execute validation function ", error);
                        return false;
                    }
                })
                .reduce(function (previousValue, currentValue) {
                    return previousValue & currentValue;
                });

            if (!ret) {
                var element = '#validateAndProceed';
                var message = $filter('translate')("process-form.userFriendlyErrorMessages.FAIL");
                showValidationErrorDetails(element, message, false);
            }
            return ret;
        }

        function serverSideValidation(realEstate) {
            var realEstateContainerCopy = angular.copy(realEstate);
            SharedControllerFnService.cleanDto(realEstateContainerCopy);
            // Returns promise (TRUE, FALSE or error code).
            return WithdrawalService.checkRealEstate(realEstateContainerCopy,
                function (response) {
                    if (response.data !== 'OK') {
                        console.warn("Server side validation failed. Not able to apply a model to provided values.");
                    } else {
                        return true;
                    }
                    return response.data;
                },
                function (error) {
                    console.error(error);
                    return false;
                });
        }

        function removeValidationErrors() {
            for (var i = elementsWithError.length - 1; i >= 0; i--) {
                removeValidationError(elementsWithError[i]);
            }
        }

        function removeValidationError(element) {
            $(element).removeClass("validationError");
            if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
                try {
                    $(element).tooltipster('close');
                } catch (e) {
                    console.error('validation remove error: ', e, $(element).tooltipster, $(element));
                }
            }
            var pos = elementsWithError.indexOf(element);
            elementsWithError.splice(pos, 1);
        }

        function showValidationError(element, message) {
            return showValidationErrorDetails(element, message, true);
        }

        function showValidationErrors(elementsArr, message) {
            var elementsArrLength = elementsArr.length;
            for (var i = 0; i < elementsArrLength; i++) {
                var element = elementsArr[i];
                showValidationErrorDetails(element, message, false);
            }
        }

        // element: Remember to escape the dots in the css id selector "." --> "\\."
        function showValidationErrorDetails(element, message, removeOnElementClick) {
            elementsWithError.push(element);
            console.info('Add validation error ' + message, element);
            $(element).addClass('validationError');
            //1048 default
            var dynamicZIndex = getRebuildZindex(element);

            function getRebuildZindex(element) {
                if (_.includes(element, 'targetOverhaul'))
                    return zIndex - 1;
                if (_.includes(element, 'targetAdditionStory'))
                    return zIndex - 2;
                if (_.includes(element, 'targetAnnex'))
                    return zIndex - 3;
                return zIndex;
            }

            if ($(element).hasClass('tooltipstered')) {
                var instance = $(element).tooltipster('instance');
                instance.content(message);
                instance.open();
            } else {
                $timeout(function() {
                    $(element).tooltipster({
                        content: message,
                        theme: 'tooltipster-kw',
                        side: ['right'],
                        zIndex: dynamicZIndex,  //z-index of validationmessages
                        trigger: 'custom',
                        plugins: ['sideTip'],
                        interactive: true,
                        repositionOnScroll: true,
                        functionReady: function(instance, helper) {
                            $(helper.tooltip).click(function() {
                                removeValidationError(helper.origin);
                            });
                            if (removeOnElementClick) {
                                $(helper.origin).click(function() {
                                    removeValidationError(helper.origin);
                                });
                            }
                        }
                    }).tooltipster('open');
                });
            }
        }

        function _escapeInSelector(selector) {
            return selector.replace(/(:|\.|\[|\]|,|=)/g, "\\$1");
        }

        function setCalculatingFalse() {
            completeInput.isCalculating = false;
        }

        function validateAndProceed(realEstateContainer, onSuccessFn, onErrorFn) {
            if (completeInput.isCalculating) {
                return;
            }
            completeInput.isCalculating = true;
            var clientSuccess = clientSideValidation(realEstateContainer);
            if (clientSuccess) {
                console.info('Client side validation successful. Running server side validation.');
            } else {
                // We just show the client side validation errors and do nothing else.
                console.warn('Client side validation failed. Not performing server side validation.');
                if (_.isFunction(onErrorFn)) {
                    onErrorFn(realEstateContainer);
                }
                setCalculatingFalse();
                return;
            }

            var message;
            var element = '#validateAndProceed';

            console.info('cleaning usages for server validation.');
            var realEstateContainerCopy = angular.copy(realEstateContainer);
            //we must clean usages.
            realEstateContainerCopy = SharedControllerFnService.cleanRealEstateContainerUsages(realEstateContainerCopy);

            serverSideValidation(realEstateContainerCopy).then(function (success) {
                if (typeof (success) === "boolean" && success) {
                    onSuccessFn();
                    // If a server side error occurred but we don't know the details.
                } else if (typeof (success) === "boolean" && !success) {
                    console.warn("Server side validation failed. Stop.");
                    message = $filter('translate')("process-form.userFriendlyErrorMessages.FAIL");
                    showValidationErrorDetails(element, message, false);
                    // If a server side error occurred and we don't know the details
                    setCalculatingFalse();
                } else {
                    console.warn("Server side validation failed with code: '" + success + "'. Stop.");
                    message = $filter('translate')("process-form.userFriendlyErrorMessages." + success);
                    switch (success) {
                    case "NAME_ALREADY_EXISTS":
                        element = "#" + _escapeInSelector("realEstateContainer.metaData.name.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "EXCEEDS_MAX_NUM_RE_ESTIMATIONS":
                        showValidationErrorDetails('#validateAndProceed', message, false);
                        break;
                    case "INVALID_NAME_FOR_RE_ESTIMATION":
                        showValidationErrorDetails('#validateAndProceed', message, false);
                        break;
                    case "INVALID_REAL_ESTATE":
                        showValidationErrorDetails('#validateAndProceed', message, false);
                        break;
                    case "INVALID_PARENT_FOR_RE_ESTIMATION":
                        showValidationErrorDetails('#validateAndProceed', message, false);
                        break;
                    case "INVALID_LOCATION_FOR_RE_ESTIMATION":
                        element = "#" + _escapeInSelector("realEstateContainer.metaData.name.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "INVALID_MAIN_USAGE_FOR_RE_ESTIMATION":
                        element = "#" + _escapeInSelector("realEstateContainer.usages[0].type.drop2down-toggle");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "INVALID_GEOMETRY_FOR_RE_ESTIMATION":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.totalFloorArea416.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "IS_ALREAD_ESTIMATED":
                        element = "#" + _escapeInSelector("realEstateContainer.metaData.name.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "NO_SIZE_PROVIDED":
                    case "SIZE_TOO_SMALL":
                    case "SIZE_TOO_BIG":
                    case "SIZE_DEATHSTAR":
                    case "SIZE_416_116_WRONG":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.totalFloorArea416.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "SIZE_ROOMHEIGHT":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.roomHeight.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "SIZE_ROOMHEIGHT_TG":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.ratioVolumeUnderTerrain.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "SIZE_ROOMHEIGHT_TG_PP":
                        var element1 = "#" + _escapeInSelector("realEstateContainer.geometry.ratioVolumeUnderTerrain.input");
                        var element2 = "#" + _escapeInSelector("realEstateContainer.geometry.undergroundParkingSpaces.input");
                        showValidationErrors([element1, element2, '#validateAndProceed'], message);
                        break;
                    case "TOO_MANY_OVERGROUND_FLOORS":
                    case "NO_OVERGROUND_FLOORS_PROVIDED":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.overgroundFloors.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "TOO_MANY_UNDERGROUND_FLOORS":
                    case "NO_UNDERGROUND_FLOORS_PROVIDED":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.undergroundFloors.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "TOO_MANY_PARKING_SPACES":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.undergroundParkingSpaces.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "TOO_MANY_BUILDINGS":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.buildings.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "TOO_MANY_ELEVATORS":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.elevatorInside.vertical.verificationInput");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "GGF_GREATER_THAN_GF":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.areaBuilding416.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "GGF_ZERO":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.areaBuilding416.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "INVESTMENT_OLDER_THAN_CONSTRUCTION":
                    case "NO_CONSTRUCTION_YEAR_PROVIDED":
                        element = "#" + _escapeInSelector("realEstateContainer.geometry.constructionYear.input");
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case "IS_OUTLIER":
                        message = $filter('translate')('process-form.userFriendlyErrorMessages.IS_OUTLIER');
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    case 'SYSTEM_ERROR':
                        message = message = $filter('translate')('process-form.userFriendlyErrorMessages.SYSTEM_ERROR');
                        showValidationErrors([element, '#validateAndProceed'], message);
                        break;
                    default:
                        break;
                    }
                    if (_.isFunction(onErrorFn)) {
                        onErrorFn(realEstateContainer);
                    }
                    setCalculatingFalse();
                }
            });
        }

        function setIsSuccess(isSuccess) {
            completeInput.isComplete = true;
            completeInput.isSuccess = isSuccess;
        }

        function getIsSuccess() {
            return completeInput.isSuccess;
        }

        function getIsCalculating() {
            return completeInput.isCalculating;
        }

        function getIsComplete() {
            return completeInput.isComplete;
        }

        function resetComplete() {
            completeInput.isComplete = false;
            completeInput.isSuccess = false;
            completeInput.isCalculating = false;
        }

    }


})();
