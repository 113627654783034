(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('BillingHistoryController', BillingHistoryController);

    BillingHistoryController.$inject = ['$scope', '$translate', 'PaymentPdfService', 'PaymentService'];

    function BillingHistoryController($scope, $translate, PaymentPdfService, PaymentService) {
        var vm = this;

        vm.payments = null;

        vm.downloadPaymentPdf = function (payment) {
            var namePrefix;
            if ('INVOICE' === payment.paymentMeans) {
                namePrefix = 'kennwerte Rechnung ';
            } else {
                namePrefix = 'kennwerte Kaufbeleg ';
            }
            PaymentPdfService.downloadPaymentPdf(payment.id, namePrefix + payment.confirmationNr + '.pdf');
        };

        vm.translateStatus = function(status) {
            try {
                return $translate.instant('billing.history.transactionStatus.' + status);
            } catch (e) {
                console.warn('transactionStatus',e);
            }
        };

        PaymentService.query({},
            function (response) {
                response.forEach(function (p) {
                    if (vm.payments == null) {
                        vm.payments = [];
                    }
                    p.translatedStatus = vm.translateStatus(p.status);
                    vm.payments.push(p);
                });
            }, function (error) {
                console.error("Failed to receive payments");
            });



    }

})();
