(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('GTMService', GTMService);

    GTMService.$inject = ['$window'];

    function GTMService($window) {

        var dataLayer = $window.dataLayer = $window.dataLayer || [];

        var service = {
            trackEvent : trackEvent,
            virtualPageview: virtualPageview
        };

        return service;

        function trackEvent (obj) {
            dataLayer.push({
                'event': 'TrackEvent',
                'attributes': {
                    'category': obj.category,
                    'action': obj.action,
                    'label': obj.label,
                    'value': obj.value,
                    'nonInteraction': obj.nonInteraction
                }
            });
        }

        function virtualPageview (url) {
            dataLayer.push({
                'event': 'VirtualPageview',
                'action': url
            });
        }
    }
})();
