(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildModificationPresetService', RebuildModificationPresetService);

    RebuildModificationPresetService.$inject = ['$q', '$rootScope', '$timeout','RebuildInterventionGradeModificationService'];

    function RebuildModificationPresetService($q, $rootScope, $timeout, RebuildInterventionGradeModificationService) {

        function areValuesDefined(layout_changes_type, structural_intervention_type, balcony_type, elevator_type, number_of_current_elevators_inside, number_of_new_elevators_inside, number_of_current_elevators_outside, number_of_new_elevators_outside) {
            if (layout_changes_type == null) {
                console.info("RebuildModificationPresetService: layout_changes_type not defined.");
                return false;
            }
            if (balcony_type == null) {
                console.info("RebuildModificationPresetService: balcony_type not defined.");
                return false;
            }
            if (elevator_type == null) {
                console.info("RebuildModificationPresetService: elevator_type not defined.");
                return false;
            }
            if (structural_intervention_type == null) {
                console.info("RebuildModificationPresetService: structural_intervention_type not defined.");
                return false;
            }
            if (number_of_current_elevators_inside == null || Number.isNaN(number_of_current_elevators_inside)) {
                console.info("RebuildModificationPresetService: number_of_current_elevators_inside not defined.");
                return false;
            }
            if (number_of_new_elevators_inside == null || Number.isNaN(number_of_new_elevators_inside)) {
                console.info("RebuildModificationPresetService: number_of_new_elevators_inside not defined.");
                return false;
            }
            if (number_of_current_elevators_outside == null || Number.isNaN(number_of_current_elevators_outside)) {
                console.info("RebuildModificationPresetService: number_of_current_elevators_outside not defined.");
                return false;
            }
            if (number_of_new_elevators_outside == null || Number.isNaN(number_of_new_elevators_outside)) {
                console.info("RebuildModificationPresetService: number_of_new_elevators_outside not defined.");
                return false;
            }
            return true;
        }

        function refresh(realEstate, layout_changes_type, structural_intervention_type, balcony_type, elevator_type, number_of_current_elevators_inside, number_of_new_elevators_inside, number_of_current_elevators_outside, number_of_new_elevators_outside) {
            if (areValuesDefined(layout_changes_type, structural_intervention_type, balcony_type, elevator_type, number_of_current_elevators_inside, number_of_new_elevators_inside, number_of_current_elevators_outside, number_of_new_elevators_outside)) {
                var cancelToken = $q.defer();
                var request = RebuildInterventionGradeModificationService.fetchData(
                    layout_changes_type,
                    structural_intervention_type,
                    balcony_type,
                    elevator_type,
                    number_of_current_elevators_inside,
                    number_of_new_elevators_inside,
                    number_of_current_elevators_outside,
                    number_of_new_elevators_outside,
                    cancelToken
                );
                request.then(function (data) {
                    if (data === undefined) return;
                    var components = realEstate.analysis.components;
                    Object.keys(data).forEach(function(componentName) {
                        var componentData = data[componentName];
                        var index = components.findIndex(function(component) {
                            return component.rebuildEstateComponent === componentName;
                        });
                        if (componentData.intervention_grade === 0) {
                            components[index].modificationMeasure = null;
                        } else {
                            components[index].modificationMeasure = components[index].modificationMeasure || new ComponentMeasures();
                            components[index].modificationMeasure.setMeasureBackend('MK5');
                            components[index].modificationMeasure.setInterventionGradeBackend(100);
                            components[index].modificationMeasure.setInterventionGradeSigmaBackend(componentData.intervention_grade_sigma * 100);
                            components[index].modificationMeasure.setInterventionGradeQuantityBackend(componentData.intervention_grade * 100);
                        }
                    });
                });
                return cancelToken;
            }
            return null;
        }

        return {
            refresh: function (realEstate, layout_changes_type, structural_intervention_type, balcony_type, elevator_type, number_of_current_elevators_inside, number_of_new_elevators_inside, number_of_current_elevators_outside, number_of_new_elevators_outside, isPresetSliderModelValuesDisabled) {
                if (isPresetSliderModelValuesDisabled) return;
                return refresh(realEstate, layout_changes_type, structural_intervention_type, balcony_type, elevator_type, number_of_current_elevators_inside, number_of_new_elevators_inside, number_of_current_elevators_outside, number_of_new_elevators_outside);
            }
        };

    }

})();
