(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactPercentInput', {
            bindings: {
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                reference: '=',
                referenceInput: '=',
                referenceCustomInput: '<?', //this is only used in combination with customSetter otherwise wont work!
                referencePlaceholder: '=',
                referenceString: '<?',
                rebuildEstateComponent: '<?',
                element: '=?',
                realEstate: '=?',
                actionCallback: '&',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                minlength: '@',
                maxlength: '@',
                validationMessage: '@',
                tabIndex: '=',
                image: '=?',
                tabGroup: '=',
                mandatory: '=',
                isDisabled: '=?',
                index: '<',
                infotext: '@?',
                background: '@?',
                customSetter: '<',
                focusFun: '=?',
                blurFun: '=?',
                isLast: '='
            },
            templateUrl: 'app/components/compact_inputs/compact_percent_input/compact-percent_input_partial.html',
            controller: CompactPercentInputController
        });

    CompactPercentInputController.$inject = ['$scope', '$rootScope', '$attrs', '$element', '$document'];

    function CompactPercentInputController($scope, $rootScope, $attrs, $element, $document) {
        var $ctrl = this;

        this.$onInit = function() {
            $ctrl.hasFocusOutFired = false;

            if ($ctrl.isDisabled == null) {
                $ctrl.isDisabled = false;
            }
            if(!angular.isFunction($ctrl.actionCallback)){
                $ctrl.actionCallback = angular.noop;

            }
        };

        function updateDescriptionRow() {
            var image = '/assets/images/calculation/bg/objektdaten.svg';
            if ($ctrl.image != null) {
                image = $ctrl.image;
            }
            $rootScope.$broadcast('updateImage', {
                imageSrc: image,
                rowid: $ctrl.rowid
            });
            if ($ctrl.translateDescriptionBaseKey) {
                $rootScope.$broadcast('updateDescription', {
                    rowid: $ctrl.rowid,
                    descriptionNumber: 0,
                    descriptionBaseKey: $ctrl.translateDescriptionBaseKey
                });
            }
        }

        $ctrl.onBlur = function() {
            if ($ctrl.hasFocusOutFired) {
                return;
            }
            $ctrl.hasFocusOutFired = true;
            if ($ctrl.blurFn != null) {
                $ctrl.blurFn();
            }
        };

        $ctrl.onFocus = function() {
            $ctrl.hasFocusOutFired = false;
            updateDescriptionRow();
            if ($ctrl.focusFun != null) {
                $ctrl.focusFun();
            }
        };

        $ctrl.onChange = function(newValue) {
            if (newValue == null || newValue === '') {
                newValue = null;
            }
            // update value based on referenceInput or customSetter
            if ($ctrl.customSetter === undefined) {
                if (newValue != null) {
                    $ctrl.referenceInput = Number(newValue);
                } else {
                    $ctrl.referenceInput = null;
                }
            } else {
                $ctrl.customSetter(newValue);
            }
            $ctrl.actionCallback({rebuildEstateComponent: $ctrl.rebuildEstateComponent, args: {}});
            // console.warn($ctrl.referenceInput,$ctrl.realEstate);
            // if input got deleted, show description row
            if (newValue === null) {
                updateDescriptionRow();
            }
        };

        this.$postLink = function() {
            if ($ctrl.element && $ctrl.isLast && $ctrl.realEstate.constructionYear) {
                // invoke enabling of preset-slider-model-values
                $rootScope.$broadcast('enablePresetSliderModelValues', {});
            }
        };
        //due to ng-if in template we cant set ids on init
        // (linking is not ready yet and template is not resolved (https://stackoverflow.com/questions/46343076/execute-a-function-after-component-is-fully-ready)
        //FYI: $postLink did not work.
        angular.element(document).ready(function() {
            //content here
            var inputField = $element.find('input')[0];
            if ($attrs.required || $attrs.mandatory) {
                inputField.required = true;
            }
            // Used for the validation
            // TODO Is the formElement class needed anywhere?
            if ($ctrl.referenceString == null) {
                $($element).attr('id', $attrs.element + '.interventionGrade.formElement');
            } else {
                $($element).attr('id', $ctrl.referenceString + '.formElement');
            }
        });
    }

})();
