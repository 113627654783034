(function () {
    'use strict';

    angular.module('kennwerteApp')
            .directive('svgImg', SvgImgDirective)
            .directive("svgToggle", SvgToggleDirective);

    SvgImgDirective.$inject = [];

    function SvgImgDirective() {
        return {
            restrict: "E",
            transclude: true,
            replace: true,
            scope: {
                href: '@',
            },
            template:
                    "<ng-container> \
                      <object type='image/svg+xml' ng-show='vm.show' id='svgImg' class='' \
                        ></object> \
                      <ng-container ng-transclude></ng-container> \
                    </ng-container>",
            controller: SvgImgDirectiveController,
            bindToController: true,
            controllerAs:'vm',
            link: function(scope, element, attrs, ctrl) {
                ctrl.show = false;
                var obj = element.children().eq(0);
                obj.attr("data", attrs.href);
                obj.on("load", function() { ctrl.ready(obj); });

                scope.$watch('vm.href',function (newValue) {
                    obj.attr("data", attrs.href);
                    ctrl.ready(obj);
                });

            }
        };
    }

    SvgImgDirectiveController.$inject = ['$scope', '$element', '$state', '$timeout', '$q', 'Principal'];

    function SvgImgDirectiveController($scope, $element, $state, $timeout, $q, Principal) {
        var vm = this;
        vm.$state = $state;
        var svg = null;
        var deferred = [];
        this.init = function(fn) {
            if (svg) {
                fn($scope);
            } else {
                deferred.push(fn);
            }
        };

        this.ready = function(obj,fnction) {
            svg = obj[0].getSVGDocument();
            if (vm.$state.includes('operational-costs') && Principal.isPomShow()) {
                $timeout(function () {
                    $('.kennwertered').addClass('pomWhite');
                    $('.kennwertered').removeClass('kennwertered');
                    $('#sectionId_howto').addClass('pom-background');
                    $('#sectionId_howto .btn-elegant-orange').addClass('btn-elegant-white');
                    $('#sectionId_completeInput').addClass('pom-background');
                    $('#sectionId_completeInput .btn-elegant-orange').addClass('btn-elegant-white');
                    $('.cIcon').removeClass('pomWhite');
                    $('.cIcon').addClass('pomBlack');
                    $('#sectionId_howto .brightfont').addClass('pomBlack');
                    $('#sectionId_howto .brightfont').removeClass('brightfont');


                    $('.btn-elegant-orange').addClass('btn-elegant-black');
                    $('.btn-elegant-orange').addClass('pom-black');
                    $('.btn-elegant-orange').removeClass('btn-elegant-orange');
                    $('.ui-select-container').addClass('pom');
                    $('.ui-select-choices-row').addClass('pom');
                    $('.btn-transparent-orange').addClass('pom');
                    for (var i = 0; i < 5; i++) {
                        var svgClass = $(".st" + i, svg);
                        if (svgClass.css('fill') != undefined && svgClass.css('fill') == 'rgb(187, 124, 122)') {
                                        //make kennwerte Red - pom green!
                            svgClass.attr('style', "fill:#13d96b");
                        }
                    }
                },200);
            }
            deferred.forEach(function(fn) {
                fn($scope);
            });
            vm.show = true;
        };
    }

    SvgToggleDirective.$inject = [];
    function SvgToggleDirective(){
        return {
            restrict: "E",
            require: "^svgImg",
            link: function(scope, element, attrs, ctrl) {
                var toggle = function() {
                    ctrl.resolve(attrs.href);
                };
                ctrl.init(function() {
                    toggle();
                    scope.$on("$destroy", toggle);
                });
            }
        };
    }
}
)();
