(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .factory('SmallBuildingAgvUsageQuantilesService', SmallBuildingAgvUsageQuantilesService);

    SmallBuildingAgvUsageQuantilesService.$inject = [];

    /**
     * Is used in kw-lambda-node-usage-preset (seperated repo) - microservice for kw-api!
     */
    function SmallBuildingAgvUsageQuantilesService() {

        var mapping = {
            "50.1.1": [[1.41461181640625, 1.41461181640625, 3.05865478515625, 4.33526611328125, 5.48968505859375, 6.58599853515625, 6.924386584476889]],
            "50.1.2": [[1.85552978515625, 1.85552978515625, 3.49017333984375, 4.58306884765625, 5.48797607421875, 6.48516845703125, 6.723685000068257]],
            "50.1.3": [[2.41436767578125, 2.41436767578125, 3.94903564453125, 4.90179443359375, 5.68792724609375, 6.56378173828125, 6.7039434107813936]],
            "50.1.4": [[1.3749655941081809, 1.79656982421875, 2.93988037109375, 3.86187744140625, 4.78643798828125, 5.88104248046875, 5.88104248046875]],
            "50.1.5": [[2.50836181640625, 2.50836181640625, 3.83453369140625, 4.46856689453125, 4.98980712890625, 5.70843505859375, 5.70843505859375]],
            "50.1.6": [[4.71295166015625, 4.71295166015625, 5.75628662109375, 6.26300048828125, 6.63897705078125, 6.93890380859375, 7.7357585658038035]],
            "50.1.7": [[3.525179343258494, 3.65423583984375, 5.01458740234375, 5.66827392578125, 6.18524169921875, 6.76715087890625, 6.807860494412558]],
            "50.2.1": [[1.02325439453125, 1.02325439453125, 2.64849853515625, 3.46112060546875, 4.49505615234375, 6.13739013671875, 6.262876541998206]],
            "50.2.2": [[1.73590087890625, 1.73590087890625, 3.30645751953125, 4.34637451171875, 5.29998779296875, 6.38690185546875, 6.5138826089741055]],
            "50.2.3": [[3.77984619140625, 3.77984619140625, 5.34527587890625, 5.92803955078125, 6.39459228515625, 6.87310791015625, 7.317918342129514]],
            "50.2.4": [[2.45452880859375, 2.45452880859375, 4.09259033203125, 4.88299560546875, 5.90838623046875, 6.80133056640625, 7.024313746714172]],
            "50.2.5": [[2.8716195527153943, 3.07916259765625, 5.56573486328125, 6.22540283203125, 6.63726806640625, 6.94232177734375, 8.0]],
            "50.2.6": [[4.08062744140625, 4.08062744140625, 5.25469970703125, 5.88189697265625, 6.34588623046875, 6.83807373046875, 6.83807373046875]],
            "50.2.7": [[1.66583251953125, 1.66583251953125, 3.36029052734375, 4.54290771484375, 5.50592041015625, 6.50567626953125, 6.564359581061751]],
            "50.2.8": [[2.10675048828125, 2.10675048828125, 3.88836669921875, 4.94281005859375, 5.94342041015625, 6.75860595703125, 6.834095900655112]],
            "50.2.9": [[1.97943115234375, 1.97943115234375, 2.99456787109375, 3.82855224609375, 4.58905029296875, 5.61273193359375, 5.61273193359375]],
            "50.3.1": [[1.32232666015625, 1.32232666015625, 3.01251220703125, 4.50360107421875, 5.71441650390625, 6.77740478515625, 7.268283488313647]],
            "50.3.2": [[2.51947021484375, 2.51947021484375, 4.17034912109375, 5.18890380859375, 5.93829345703125, 6.73553466796875, 7.060458029655693]],
            "50.3.3": [[4.2576726342711, 4.67535400390625, 5.80242919921875, 6.29547119140625, 6.65350341796875, 6.94146728515625, 7.533666165357546]],
            "50.3.4": [[1.36163330078125, 1.36163330078125, 2.89288330078125, 4.21649169921875, 5.31793212890625, 6.44500732421875, 6.448124522289613]],
            "50.4.1": [[1.67694091796875, 1.67694091796875, 2.68695068359375, 3.43890380859375, 4.32073974609375, 5.69818115234375, 5.9273144605967865]],
            "50.4.2": [[1.70684814453125, 1.70684814453125, 3.18426513671875, 4.01312255859375, 4.82745361328125, 6.05706787109375, 6.178210744591257]],
            "50.4.3": [[2.42034912109375, 2.42034912109375, 4.02252197265625, 4.90606689453125, 5.61358642578125, 6.45953369140625, 6.484858350023709]],
            "50.4.4": [[1.1415774705638253, 1.36248779296875, 2.54852294921875, 3.40728759765625, 4.35662841796875, 5.73834228515625, 6.032188075337708]],
            "50.4.5": [[0.4071527894227669, 0.51141357421875, 2.25543212890625, 3.48675537109375, 4.83001708984375, 6.34075927734375, 6.560695929861842]],
            "50.4.6": [[2.58013916015625, 2.58013916015625, 4.29766845703125, 5.29229736328125, 6.01519775390625, 6.74151611328125, 7.024802520118415]],
            "50.4.7": [[1.69317626953125, 1.69317626953125, 2.30413818359375, 2.59295654296875, 2.87750244140625, 3.40386962890625, 3.40386962890625]],
            "50.5.1": [[3.95672607421875, 3.95672607421875, 4.96588134765625, 5.50250244140625, 5.95794677734375, 6.58258056640625, 6.58258056640625]],
            "50.5.2": [[1.91790771484375, 1.91790771484375, 4.16522216796875, 5.18463134765625, 6.00238037109375, 6.71929931640625, 6.71929931640625]],
            "50.5.3": [[2.35198974609375, 2.35198974609375, 4.10540771484375, 5.07696533203125, 5.75628662109375, 6.55438232421875, 6.55438232421875]],
            "50.5.4": [[3.53460693359375, 3.53460693359375, 5.13421630859375, 5.86566162109375, 6.43218994140625, 6.90472412109375, 8.0]],
            "50.6.1": [[1.13092041015625, 1.13092041015625, 2.42547607421875, 3.36712646484375, 4.42926025390625, 5.89044189453125, 6.1075050310579595]],
            "50.6.2": [[1.90509033203125, 1.90509033203125, 2.86383056640625, 3.56793212890625, 4.35150146484375, 5.51873779296875, 5.681801192758267]],
            "50.6.3": [[0.78057861328125, 0.78057861328125, 1.70684814453125, 2.32635498046875, 2.99713134765625, 4.07720947265625, 4.144975203196033]],
            "50.6.4": [[0.86688232421875, 0.86688232421875, 1.85894775390625, 2.53656005859375, 3.27911376953125, 4.97869873046875, 5.868984458786552]],
            "50.6.5": [[1.68035888671875, 1.68035888671875, 3.18597412109375, 4.24212646484375, 5.17779541015625, 6.19378662109375, 6.83677298292476]],
            "50.6.6": [[0.56268310546875, 0.56268310546875, 1.60943603515625, 2.82281494140625, 4.25665283203125, 6.01263427734375, 6.249761852525496]],
            "50.7.1": [[0.8660216736421842, 0.94720458984375, 2.17510986328125, 3.06890869140625, 4.11737060546875, 5.81439208984375, 6.193936262818562]],
            "50.7.2": [[1.5537370426291266, 1.72137451171875, 2.85784912109375, 3.73626708984375, 4.71124267578125, 5.97760009765625, 6.146572499586322]],
            "50.7.3": [[0.68743896484375, 0.68743896484375, 1.55303955078125, 2.14093017578125, 2.75103759765625, 3.89434814453125, 4.658168977693568]],
            "50.7.4": [[1.3048268382989923, 1.58807373046875, 2.80657958984375, 3.70721435546875, 4.72064208984375, 6.10235595703125, 6.254237051322187]],
            "50.7.5": [[0.74810791015625, 0.74810791015625, 1.96234130859375, 3.87982177734375, 5.74945068359375, 6.85516357421875, 7.094698091391271]],
            "50.8.1": [[0.96258544921875, 0.96258544921875, 1.96490478515625, 2.78521728515625, 3.35858154296875, 4.10711669921875, 4.10711669921875]],
            "50.8.2": [[3.50128173828125, 3.50128173828125, 5.09918212890625, 5.91351318359375, 6.46124267578125, 6.90386962890625, 7.388509636833687]],
            "50.8.3": [[3.9182015719602754, 4.21990966796875, 5.59222412109375, 6.19976806640625, 6.61676025390625, 6.93719482421875, 7.553436353179121]],
            "50.8.4": [[3.39788818359375, 3.39788818359375, 4.85223388671875, 5.69049072265625, 6.30828857421875, 6.85687255859375, 7.105893497166431]],
            "50.8.5": [[2.857024830377915, 2.91595458984375, 4.55145263671875, 5.30511474609375, 5.93658447265625, 6.70050048828125, 6.81913186585548]],
            "50.8.6": [[4.77703857421875, 4.77703857421875, 5.35552978515625, 5.46575927734375, 5.54693603515625, 6.18695068359375, 6.18695068359375]],
            "50.8.7": [[3.7237921807575227, 3.74310302734375, 5.19915771484375, 5.89642333984375, 6.41680908203125, 6.88848876953125, 7.424813604758315]],
            "50.8.8": [[5.25042724609375, 5.25042724609375, 6.06988525390625, 6.48089599609375, 6.76715087890625, 6.96453857421875, 7.48509885230795]],
            "50.8.9": [[4.83599853515625, 4.83599853515625, 5.78790283203125, 6.25531005859375, 6.61676025390625, 6.93121337890625, 7.543268828013739]]
        };


        return {
            getMapping: function () {
                return mapping;
            }
        };
    }

})();
