(function () {
    'use strict';

    angular.module('kennwerteApp')
    .component('datePickerInput', {
        bindings: {
            reference: '=',
            rowid: '=',
            translateDescriptionBaseKey: '@',
            translateBaseKey: '@',
            image: '=',
            mandatory: '@?',
            placeholder: '@?',
            isDisabled: '=',
            validationMessage: '@',
            descriptionBaseKey: '@',
            usages: '<',
            optional: '<?',
            infotext: '@?'

        },
        templateUrl: 'app/components/date_picker/date-picker.tpl.html',
        controller: DatePickerInputController
    });

    DatePickerInputController.$inject = ['moment', '$scope', '$rootScope', '$mdDateLocale', 'RealEstateReferenceDataService', '$element', '$attrs'];

    function DatePickerInputController(moment, $scope, $rootScope, $mdDateLocale, RealEstateReferenceDataService, $element, $attrs) {
        var $ctrl = this;
        $scope.firstLoad = true;
        $ctrl.model = {};
        $ctrl.answers = ["yes", "no"];
        $ctrl.isOpen = true;

        $ctrl.pingPong = true;

        $ctrl.dateFormat = ['MM.YYYY', 'M.YYYY', 'DD.MM.YYYY'];


        /**
         * Sets the final format of the date.
         * @param date the input which the user gives.
         * @returns {string} empty string if the date is not valid or a formatted date.
         */
        $mdDateLocale.formatDate = function (date) {
            var tempDate = moment(date);
            return tempDate.isValid() ? tempDate.format('MM.YYYY') : '';
        };

        // Allow only a month and year to be specified.
        $mdDateLocale.isDateComplete = function (dateString) {
            dateString = dateString.trim();
            var re = /(0?[1-9]|1[0-2])\.[1-2][0|9]\d\d/;
            return re.test(dateString);
        };


        /**
         * Overwrites the parseDate function of datePicker. DateFormats are the accepted formats.
         * @param dateString
         * @returns {Date}
         */
        $mdDateLocale.parseDate = function (dateString) {
            var dateFormat = ['MM.YYYY', 'M.YYYY', 'DD.MM.YYYY'];
            var m = moment(dateString, dateFormat, true);
            return m.isValid() ? m.toDate() : new Date(NaN);
        };

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        $ctrl.translateKey = function (answer) {
            return 'global.' + answer;
        };

        $ctrl.onOpenClose = function (isOpen) {
           // prevents from accidential blocking the whole window.
            $ctrl.unBlockScroll();
            if (isOpen) {
                $rootScope.$broadcast("updateDescription", {
                    rowid: $ctrl.rowid,
                    descriptionBaseKey: $ctrl.descriptionBaseKey
                });
                $rootScope.$broadcast("updateImage", {
                    rowid: $ctrl.rowid,
                    imageSrc: $ctrl.image
                });
            }
        };

        $scope.$watch('$ctrl.model', function (selectedItem) {
            if (!$scope.firstLoad && !angular.equals({}, selectedItem)) {
                $ctrl.updateDescriptionAndImage(selectedItem);
                if (selectedItem === "no") {
                    $ctrl.reference = null;
                    $ctrl.date = null;
                }
            } else {
                $scope.firstLoad = false;
            }
        });

        $ctrl.dateChanger = function () {
            if (angular.isDefined($ctrl.date)) {
                var tempDate = new Date($ctrl.date.valueOf());
                tempDate = moment(tempDate, 'DD.MM.YYYY');
                //This is super hacky to make sure we don't have issues with timezones
                // (go to the middle of the month. so we are sure).
                tempDate.add(15, 'd');
                $ctrl.reference = tempDate.utc();
                $ctrl.pingPong = false;
            } else {
                $ctrl.reference = null;
            }
        };

        $scope.$watch('$ctrl.reference', function (newValue, oldValue) {
            //Pingpong is used to prevent a pingpong effect between dateChanger and this watch.
            if (newValue && $ctrl.pingPong) {
                if ($ctrl.optional) {
                    $ctrl.model = 'yes';
                }
                if ($ctrl.reference !== $ctrl.date) {
                    var temp = moment($ctrl.reference);
                    if (temp.isValid) {
                        $ctrl.date = temp.toDate();
                    } else {
                        $ctrl.date = new Date(NaN);
                    }
                }
            } else {
                $ctrl.pingPong = true;
            }

        });

        $ctrl.updateDescriptionAndImage = function (selectedItem, $model) {
            $ctrl.blockScroll();
            var tempDescriptionKey;
            if (angular.isDefined(selectedItem)) {
                tempDescriptionKey = $ctrl.descriptionBaseKey + '.' + selectedItem;
            } else {
                tempDescriptionKey = $ctrl.descriptionBaseKey;
            }
            $rootScope.$broadcast("updateImage", {
                rowid: $ctrl.rowid,
                imageSrc: $ctrl.image
            });

            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionNumber: selectedItem,
                descriptionBaseKey: tempDescriptionKey
            });
        };

        $.fn.disableScroll = function() {
            window.oldScrollPos = $(window).scrollTop();

            $(window).on('scroll.scrolldisabler',function ( event ) {
                $(window).scrollTop( window.oldScrollPos );
                event.preventDefault();
            });
        };

        $.fn.enableScroll  = function() {
            $(window).off('scroll.scrolldisabler');
        };

        //This is plain jquery - does the job but can be eventually made better with angular.
        $ctrl.blockScroll = function () {
            $('.modal-xlg').on('wheel.datepicker', function (e) {
                return false;
            });
            $(document.documentElement).disableScroll();
            //$('.modal-open .modal').css('overflow-y', 'hidden');
            $('.modal-open .modal').disableScroll();
            var windowWidth = $(window).width();
            var inputContainer = $('.md-datepicker-open .md-datepicker-input-container');
            var calendarContainer = $('.md-datepicker-calendar-pane');
            var p = inputContainer.position();
            if (342 + inputContainer.offset().left < windowWidth) {
                calendarContainer.css('left', (inputContainer.offset().left -21) + 'px');
            }
        };

        $ctrl.unBlockScroll = function () {
            $('.modal-xlg').off('wheel.datepicker');
            $(document.documentElement).enableScroll();
            //$('.modal-open .modal').css('overflow-y', 'auto');
            $('.modal-open .modal').enableScroll();
        };




        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            if (!angular.isUndefined($ctrl.reference)) {
                if (!moment($ctrl.reference).isValid()) {
                    // $ctrl.reference = moment();
                    if ($ctrl.optional) {
                        $ctrl.model = 'no';
                    }
                } else {
                    if ($ctrl.optional) {
                        $ctrl.model = 'yes';
                    }
                    $ctrl.date = moment($ctrl.reference, 'DD.MM.YYYY').toDate();
                }
            } else {
                $ctrl.model = 'yes';
                $ctrl.date = null;
            }

        };

        this.$onDestroy = function () {
            $ctrl.blockScroll();
        };
    }
})();
