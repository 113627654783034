(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('PriceCalculatorController', PriceCalculatorController);

    PriceCalculatorController.$inject = ['moment', 'BaseLicenseService', 'BillingService', 'Organisation',
        'WithdrawalProductService', 'WptFreeTrackingService', 'Principal',
        '$scope', '$timeout'];

    function PriceCalculatorController(moment, BaseLicenseService, BillingService, Organisation,
                                       WithdrawalProductService, WptFreeTrackingService, Principal,
                                       $scope, $timeout) {
        var vm = this;

        vm.organisationBefore = null;
        vm.wpBefore = null;
        vm.organisationAfter = null;
        vm.wpAfter = null;

        vm.validUntilDate = null;
        vm.mutationDate = null;

        vm.onChangeAfter = onChangeAfter;
        vm.onChangeBefore = onChangeBefore;
        vm.spawnNew = loadData;
        vm.loadLicenseFromOrg = loadLicenseFromOrg;

        function _postProcessOrg(org, baseLicense, wpts, dateTimeMutation) {
            org['phone'] = '0';
            org['street'] = 'a';
            org['zipCode'] = '8000';
            org['place'] = 'Zurich';

            Principal.identity().then(function (account) {
                account['email'] = 'admin@keevalue.ch';

                vm.wpBefore = {
                    'withdrawalProductTypes': wpts,
                    'baseLicense': baseLicense,
                    'numMaxUsers': org['numMaxUsers'],
                    'dateTimeValidUntil': org['validUntil'],
                    'dateTimeFreeUntil': org['freeUntil']
                };
                // now load other data
                vm.organisationBefore = angular.copy(org);
                vm.organisationBefore['numEmployees'] = org['numEmployees'];
                vm.organisationBefore['withdrawalProductTypes'] = vm.wpBefore.withdrawalProductTypes;
                vm.accountBefore = angular.copy(account);
                // vm.accountBefore['withdrawalProductTypes'] = vm.wpBefore.withdrawalProductTypes;

                vm.wpAfter = {
                    'withdrawalProductTypes': wpts,
                    'baseLicense': baseLicense,
                    'numMaxUsers': org['numMaxUsers'],
                    'dateTimeValidUntil': org['validUntil'],
                    'dateTimeFreeUntil': org['freeUntil']
                };
                vm.organisationAfter = angular.copy(org);
                vm.organisationAfter['numEmployees'] = org['numEmployees'];
                vm.organisationAfter['withdrawalProductTypes'] = vm.wpAfter.withdrawalProductTypes;
                vm.accountAfter = angular.copy(account);
                // vm.accountAfter['withdrawalProductTypes'] = vm.wpAfter.withdrawalProductTypes;

                vm.validUntilDate = org['validUntil'];
                vm.mutationDate = dateTimeMutation;

                $scope.$watch('vm.wpBefore',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            onChangeBefore();
                        }
                    }, true);

                $scope.$watch('vm.organisationBefore',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            onChangeBefore();
                        }
                    }, true);

                $scope.$watch('vm.wpAfter',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            onChangeAfter();
                        }
                    }, true);

                $scope.$watch('vm.organisationAfter',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            onChangeAfter();
                        }
                    }, true);

                $scope.$watch('vm.validUntilDate',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            fetchAdditionalPrice();
                        }
                    }, true);

                $scope.$watch('vm.mutationDate',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            fetchAdditionalPrice();
                        }
                    }, true);

                $scope.$watch('vm.productTypesBefore',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            onChangeBefore();
                        }
                    }, true);

                $scope.$watch('vm.productTypesAfter',
                    function (newVal, oldVal) {
                        if (!!newVal) {
                            onChangeAfter();
                        }
                    }, true);

                fetchPriceBefore();
                fetchPriceAfter();
                fetchAdditionalPrice();
            })
        }

        function loadData() {
            var dateTimeNow = moment.utc();
            var dateTime1Year = moment.utc().add(1, 'y');
            var dateTimeMutation = moment.utc().add(31, 'd');
            vm.productTypesBefore = [
                {'wpt': 'LCC_PACKAGE', 'dateTimeFreeUntil': dateTimeNow},
                {'wpt': 'BUILDING_COSTS_AND_DATES', 'dateTimeFreeUntil': dateTimeNow},
                {'wpt': 'BUILDING_INSURANCE_VALUES', 'dateTimeFreeUntil': dateTimeNow},
                {'wpt': 'OPERATING_COSTS', 'dateTimeFreeUntil': dateTimeNow},
                {'wpt': 'REBUILD_COSTS', 'dateTimeFreeUntil': dateTimeNow}
            ];

            vm.productTypesAfter = angular.copy(vm.productTypesBefore);

            BaseLicenseService.actives().$promise.then(
                function (baseLicenses) {
                    vm.baseLicenses = baseLicenses;
                    Organisation.current().$promise.then(function (org) {
                        org['numMaxUsers'] = 1;
                        org['numEmployees'] = 1;
                        org['validUntil'] = dateTime1Year;
                        org['freeUntil'] = dateTimeNow;
                        _postProcessOrg(org, baseLicenses[0], ['BUILDING_COSTS_AND_DATES'], dateTimeMutation);
                    });
                }
            );


        }

        function loadLicenseFromOrg() {
            BaseLicenseService.actives().$promise.then(
                function (baseLicenses) {
                    vm.baseLicenses = baseLicenses;
                    Organisation.get({'id': vm.orgId}, function (org) {
                        var dto = {
                            orgId: vm.orgId
                        };
                        if (!org['numEmployees']) {
                            org['numEmployees'] = 1;
                        }
                        WithdrawalProductService.current_for_admin(dto).$promise.then(
                            function (curWp) {
                                var dateTimeNow = moment.utc();

                                var dateTimeMutation = moment.utc().add(31, 'd');
                                var arr = vm.baseLicenses.filter(function (v) {
                                    return v.id === curWp.baseLicenseId;
                                });
                                curWp.baseLicense = arr[0];
                                org['numMaxUsers'] = curWp['numMaxUsers'];
                                org['siaIndividualMember'] = curWp['hasSiaDiscountIndividual'];
                                org['siaCorporateMember'] = curWp['hasSiaDiscountCorporate'];
                                org['validUntil'] = moment(curWp['dateTimeValidUntil']);
                                org['freeUntil'] = moment(curWp['dateTimeFreeUntil']);

                                vm.productTypesBefore = [
                                    {'wpt': 'LCC_PACKAGE', 'dateTimeFreeUntil': dateTimeNow},
                                    {'wpt': 'BUILDING_COSTS_AND_DATES', 'dateTimeFreeUntil': dateTimeNow},
                                    {'wpt': 'BUILDING_INSURANCE_VALUES', 'dateTimeFreeUntil': dateTimeNow},
                                    {'wpt': 'OPERATING_COSTS', 'dateTimeFreeUntil': dateTimeNow},
                                    {'wpt': 'REBUILD_COSTS', 'dateTimeFreeUntil': dateTimeNow}
                                ];


                                WptFreeTrackingService.queryByOrg({orgId: vm.orgId}).$promise.then(
                                    function (wptTracking) {
                                        wptTracking
                                            .forEach(function (track) {
                                                var ptBefore = vm.productTypesBefore.find(function (pt) {
                                                    return pt['wpt'] === track['wpt'];
                                                });
                                                ptBefore['dateTimeFreeUntil'] = moment(track['dateTimeFreeUntil']);
                                            });
                                        vm.productTypesAfter = angular.copy(vm.productTypesBefore);

                                        _postProcessOrg(org, curWp.baseLicense, curWp.withdrawalProductTypes, dateTimeMutation);
                                    }
                                );

                            });
                    }, function (response) {
                        console.error("Failed to load org");
                    })
                }
            );
        }

        function _getAdditionalWpts() {
            return vm.wpAfter.withdrawalProductTypes;
        }

        function onChangeBefore() {
            vm.organisationBefore['withdrawalProductTypes'] = vm.wpBefore.withdrawalProductTypes;
            // vm.accountBefore['withdrawalProductTypes'] = vm.wpBefore.withdrawalProductTypes;
            vm.wpBefore['hasSiaDiscountIndividual'] = vm.organisationBefore.siaIndividualMember;
            vm.wpBefore['hasSiaDiscountCorporate'] = vm.organisationBefore.siaCorporateMember;
            fetchPriceBefore();
            fetchAdditionalPrice();
        }

        function onChangeAfter() {
            vm.organisationAfter['withdrawalProductTypes'] = vm.wpAfter.withdrawalProductTypes;
            // vm.accountAfter['withdrawalProductTypes'] = vm.wpAfter.withdrawalProductTypes;
            vm.wpAfter['hasSiaDiscountIndividual'] = vm.organisationAfter.siaIndividualMember;
            vm.wpAfter['hasSiaDiscountCorporate'] = vm.organisationAfter.siaCorporateMember;
            fetchPriceAfter();
            fetchAdditionalPrice();
        }

        function fetchPriceBefore() {
            var registerBeforeDTO = {
                "account": vm.accountBefore,
                "organisation": vm.organisationBefore,
                "numAdditionalUsers": vm.wpBefore.numMaxUsers - 1,
                "baseLicenseId": vm.wpBefore.baseLicense.id
            };
            BillingService.fetchTentativePrice(registerBeforeDTO).then(
                function (res) {
                    vm.priceBefore = res.data;
                },
                    function(err){
                        console.error('fetchPriceBefore: ', err);
                    });
        }

        function fetchPriceAfter() {
            var registerAfterDTO = {
                "account": vm.accountAfter,
                "organisation": vm.organisationAfter,
                "numAdditionalUsers": vm.wpAfter.numMaxUsers - 1,
                "baseLicenseId": vm.wpAfter.baseLicense.id
            };
            BillingService.fetchTentativePrice(registerAfterDTO).then(
                function (res) {
                    vm.priceAfter = res.data;
                },
                    function(err){
                        console.error('fetchPriceAfter:', err);
                    }
                );
        }

        function fetchAdditionalPrice() {
            // additional users
            vm.wpBefore.baseLicenseId = vm.wpBefore.baseLicense.id;
            vm.wpAfter.baseLicenseId = vm.wpAfter.baseLicense.id;
            var wpBefore = vm.wpBefore;
            var freePairsBefore = vm.productTypesBefore;
            var wpAfter = vm.wpAfter;
            var freePairsAfter = vm.productTypesAfter;
            var validUntilDate = vm.validUntilDate;
            var mutationDate = vm.mutationDate;
            vm.additionalWpts = _.difference(vm.wpAfter.withdrawalProductTypes, vm.wpBefore.withdrawalProductTypes);
            vm.additionalAccounts = Math.max(vm.wpAfter.numMaxUsers - vm.wpBefore.numMaxUsers, 0);
            BillingService.fetchAdditionalPriceForeign(
                wpBefore, freePairsBefore,
                wpAfter, freePairsAfter,
                validUntilDate, mutationDate)
                .then(function (ret) {
                    vm.additionalPrice = ret.data;
                })
                .catch(function (response) {
                    vm.additionalPrice = null;
                });
        }

    }

})
();
