(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('accept-organisation', {
            parent: 'app',
            url: '/register-accept?key',
            data: {
                authorities: [],
                pageTitle: 'accept-organisation.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/accept-organisation/accept-organisation.html',
                    controller: 'AcceptOrganisationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                check: ['$state', '$stateParams', 'Principal', function ($state, $stateParams, Principal) {
                    Principal.identity()
                        .then(function (account) {
                            if (account != null) {
                                $state.go('settings');
                            }
                        });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accept-organisation');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
