(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('AccuracyController', AccuracyController);

    AccuracyController.$inject = ['$scope','$rootScope', '$timeout', 'AccuracyService', '$translate',
        'usageTypeGroupFactory', 'ResponsiveStateService'];

    function AccuracyController($scope,$rootScope, $timeout, AccuracyService, $translate,
                                usageTypeGroupFactory, ResponsiveStateService) {
        var vm = this;

        vm.accuracyTable = null;
        vm.accuracyBkp2 = null;
        vm.boxPlotData = null;

        vm.modelUsageSelect = {
            "id": "00",
            "title": "WOHNBAUTEN__TITLE",
            "translated": translateUsageType("WOHNBAUTEN__TITLE")
        };
        vm.groups1 = enhanceGroupFn(usageTypeGroupFactory.load("00"));

        vm.legendRows = [
            {
                "stars": 4,
                'text': $translate.instant('accuracy.median-deviation-bkp-2-4star')
            },
            {
                "stars": 3,
                'text': $translate.instant('accuracy.median-deviation-bkp-2-3star')
            },
            {
                'stars': 2,
                'text': $translate.instant('accuracy.median-deviation-bkp-2-2star')
            },
            {
                'stars': 1,
                'text': $translate.instant('accuracy.one-star')
            }
        ];
        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            vm.legendRows = [
                {
                    'stars': 4,
                    'text': $translate.instant('accuracy.median-deviation-bkp-2-4star')
                },
                {
                    'stars': 3,
                    'text': $translate.instant('accuracy.median-deviation-bkp-2-3star')
                },
                {
                    'stars': 2,
                    'text': $translate.instant('accuracy.median-deviation-bkp-2-2star')
                },
                {
                    'stars': 1,
                    'text': $translate.instant('accuracy.one-star')
                }
            ];
            vm.groups1 = enhanceGroupFn(usageTypeGroupFactory.load("00"));
            vm.modelUsageSelect.translated =  translateUsageType( vm.modelUsageSelect.title);
             updateAccuracyTable(vm.accuracyTable);
        });
        this.$onDestroy = function() {
            translateSuccess();
        };

        vm.responsiveBoxplotStyle = {};

        var hiddenUsageTypes = [
            'WOHNBAUTEN__PERSONALHAEUSER',
            'KULTUS__KAPELLE',
            'KULTUS__KIRCHE',
            'KULTUR__KONGRESS',
            'FREIZEITBAUTEN_SPORTBAUTEN__STADIONANLAGEN',
            'FREIZEITBAUTEN_SPORTBAUTEN__SPORTPLAETZE',
            'FREIZEITBAUTEN_SPORTBAUTEN__FREIBAD',
            'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE',
            'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE'
        ];

        vm.hasAnyResponsiveState = hasAnyResponsiveState;
        vm.isDisabled = isDisabled;

        function updateAccuracyTable(accuracyTable) {
            for (var i in accuracyTable) {
                var row = accuracyTable[i];
                row.translatedUsage = translateUsageType(row.usage);
                var titleOfPrevious = usageTypeGroupFactory.getTitleOfPrevious(row.usage);
                row.isTitle = angular.isUndefined(titleOfPrevious);
                row.isHidden = hiddenUsageTypes.indexOf(row.usage) !== -1 || titleOfPrevious !== vm.modelUsageSelect.title;
                row.stars = isDisabled(row.usage) ? '1' : row.stars;
            }
            vm.accuracyTable = accuracyTable;
        }

        function fetchRows() {
            AccuracyService.fetchAccuracyTable().then(function (response) {
                vm.accuracyTable = response['table'];
                updateAccuracyTable(vm.accuracyTable);
                vm.medianApe2 = vm.accuracyTable[0].w_median_ape_2;
                vm.totMedianApe = vm.accuracyTable[0].tot_w_median_ape;
                vm.twoDigitMedianApe = vm.accuracyTable[0].two_digit_w_median_ape;
                var boxplotObject = response['boxplot'][0];
                vm.boxPlotData = [{
                    "key": "0",
                    "quartile": [boxplotObject.p25/100, boxplotObject.p50/100, boxplotObject.p75/100],
                    "whiskers": [boxplotObject.w_low/100, boxplotObject.w_high/100]
                }];
            });
        }

        function translateUsageType(usageKey) {
            if (usageKey) {
                try {
                    return $translate.instant('realEstate.usages.' + usageKey);
                }catch (e) {
                    console.warn('translate error in usages', e);
                }
            } else {
                return "";
            }
        }

        function enhanceGroupFn(groups) {
            var enhancedGroups = [];
            for (var i in groups) {
                var group = groups[i];
                enhancedGroups.push({
                    "id": group.id,
                    "title": group.title,
                    "parent": group.parent,
                    "translated": translateUsageType(group.title)
                });
            }
            return enhancedGroups;
        }

        fetchRows();

        $scope.$watch('vm.modelUsageSelect', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                updateAccuracyTable(vm.accuracyTable);
            }
        });

        function hasAnyResponsiveState(responsiveStateArr) {
            return ResponsiveStateService.hasAnyContentRowResponsiveState(responsiveStateArr);
        }

        function isDisabled(usageTitle) {
            return usageTypeGroupFactory.getByTitle(usageTitle).disabled;
        }
    }

})();
