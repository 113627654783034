(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactDynamicNoOrMultiSelecter', {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                noOption: '=',
                selectables: '=', // Array which has all selectable options inside.
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                actionCallback: '&',
                translateBaseKey: '@',
                defaultImage: '=?',
                rowKey: '=?',
                image: '=',
                imageFormat: '=?',
                dynamicImage: '=?',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                classes: '@?',
                infotext: '@?',
                useShortStrings: '=?',
                inputBlurFun: '&?'

            },
            templateUrl: 'app/components/dynamic_no_or_multi_selecter/compact-dynamic-no-or-multi-selecter.tpl.html',
            controller: CompactDynamicNoOrMultiSelecterController
        });

    CompactDynamicNoOrMultiSelecterController.$inject = ['$scope', '$rootScope', '$element', '$translate', '$attrs'];

    function CompactDynamicNoOrMultiSelecterController($scope, $rootScope, $element, $translate, $attrs) {
        var $ctrl = this;
        $ctrl.firstLoad = true;
        $ctrl.selected = [];
        $ctrl.counter = 0;
        $ctrl.displayShortString = '';
        $ctrl.model = {};

        function isSelected(type) {
            return $ctrl.reference != null &&
                $ctrl.reference.indexOf(type) > -1;
        }

        if (angular.isUndefined($ctrl.useShortStrings)) {
            $ctrl.useShortStrings = false;
        }

        $ctrl.translateKey = function(postfix) {
            try {
                var instant = $translate.instant(
                    $ctrl.translateBaseKey + '.' + postfix + '.label'
                );
                return instant;
            } catch (e) {
                console.warn(e);
            }
        };

        function printSelectedOptions(choices) {
            var selectedChoicesStrArr = choices
                .filter(function(c) {
                    return c.isSelected;
                })
                .map(function(c) {
                    return c.translated;
                });
            return selectedChoicesStrArr.join(', ');
        }

        $ctrl.printSelectedOptions = function() {
            if ($ctrl.groups[0] !== undefined && $ctrl.groups[0].isSelected) {
                return $ctrl.groups[0].translated;
            }
            return printSelectedOptions($ctrl.groups);
        };


        $ctrl.createShortString = function() {
            $ctrl.displayShortString = '';
            var translateMe = [];
            _.forEach($ctrl.selected, function(element) {
                translateMe.push($ctrl.translateBaseKey + '.' + element + '.short');
                try {
                    if ($ctrl.displayShortString.length === 0) {
                        var initTemp = null;
                        try {
                            initTemp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.short');
                            if ($ctrl.translateBaseKey + '.' + element + '.short' === initTemp) {
                                throw $ctrl.translateBaseKey + '.' + element + '.short';
                            }
                        } catch (e) {
                            console.warn(e);
                            initTemp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.label');
                        }
                        $ctrl.displayShortString = initTemp;
                    } else {
                        var temp = null;
                        try {
                            temp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.short');
                            if ($ctrl.translateBaseKey + '.' + element + '.short' === temp) {
                                throw $ctrl.translateBaseKey + '.' + element + '.short';
                            }

                        } catch (e) {
                            console.warn(e);
                            temp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.label');
                        }
                        $ctrl.displayShortString = $ctrl.displayShortString + ', ' + temp;
                    }
                } catch (e) {
                    console.warn(e);
                }
            });
        };

        var enhanceGroupFn = function(gs) {
            var enhancedGroups = [];
            for (var j in gs) {
                var group = gs[j];
                enhancedGroups.push({
                    title: group,
                    translated: $ctrl.translateKey(group),
                    isSelected: isSelected(group)
                });
            }
            $ctrl.groups = enhancedGroups;
        };

        $ctrl.resetGroups = function() {
            var gs;
            angular.copy($ctrl.selectables, gs);
            enhanceGroupFn(gs);
        };

        $ctrl.resetGroups();

        $ctrl.onBlur = function() {
        };

        $ctrl.isEmptyModel = function(model) {
            return angular.equals({}, model);
        };

        $ctrl.onOpenClose = function(isOpen, type) {
            if (isOpen) {
                $ctrl.updateDescriptionAndImage(undefined);
            }
        };

        $ctrl.onSelect = function(item) {
            item.isSelected = !item.isSelected;
            if (item.title === $ctrl.noOption) {
                // deselect all items except noOption
                $ctrl.reference = (item.isSelected)
                    ? [ $ctrl.noOption ]
                    : [];
            } else {
                if (item.isSelected) {
                    // remove no option
                    $ctrl.reference = _.filter($ctrl.reference, function(value) {
                        return value !== $ctrl.noOption;
                    });
                    // add selected item
                    $ctrl.reference.push(item.title);
                } else {
                    // add unselected item
                    $ctrl.reference.splice($ctrl.selected.indexOf(item.title), 1);
                }
            }
            $ctrl.updateDescriptionAndImage((item.isSelected) ? item : undefined);
        };


        $scope.$watch('$ctrl.model', function(selectedItem) {
            if (!$scope.firstLoad) {
                //Blur function will only be called after firstLoad
                if ($ctrl.inputBlurFun != null) {
                    $ctrl.inputBlurFun();
                }
            } else {
                $scope.firstLoad = false;
            }
        });

        $ctrl.updateDescriptionAndImage = function(selectedItem) {
            function getUpdateImageArgs(selectedItem) {
                if ($ctrl.rowKey != null) return {
                    rowid: $ctrl.rowid,
                    key: $ctrl.rowKey
                };
                else return {
                    rowid: $ctrl.rowid,
                    imageSrc:
                        (selectedItem != null)
                            ? $ctrl.dynamicImage ? $ctrl.image + selectedItem.title + '.' + $ctrl.imageFormat : $ctrl.image
                            : $ctrl.defaultImage || $ctrl.dynamicImage ? $ctrl.image + $ctrl.noOption + '.' + $ctrl.imageFormat : $ctrl.image
                };
            }
            function getUpdateDescriptionArgs(selectedItem) {
                return {
                    rowid: $ctrl.rowid,
                    key: $ctrl.rowKey,
                    descriptionBaseKey: $ctrl.descriptionBaseKey,
                    selectedTitle: (selectedItem != null)
                        ? selectedItem.title
                        : null
                };
            }
            $rootScope.$broadcast('updateImage', getUpdateImageArgs(selectedItem));
            $rootScope.$broadcast('updateDescription', getUpdateDescriptionArgs(selectedItem));

        };

        $scope.$watch('$ctrl.reference', function(newValue, oldValue) {
            function syncSelected() {
                _.forEach($ctrl.groups, function(group) {
                    group.isSelected = _.some($ctrl.selected, function(value, key, array) {
                        return group.title === value;
                    });
                });
            }
            if (newValue && angular.isDefined(newValue)) {
                $ctrl.selected = $ctrl.reference;
                syncSelected();
                $ctrl.actionCallback({
                    newValue: newValue,
                    oldValue: oldValue,
                    args: { origin: $attrs.reference, rowid: $ctrl.rowid, descriptionBaseKey: $ctrl.descriptionBaseKey }
                });
                if ($ctrl.useShortStrings) {
                    $ctrl.createShortString();
                }
            }
        }, true);

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function(_) {
            // console.warn($scope.groups[0], $scope.groups[0].title, vm.translateKey($scope.groups[0].title));
            for (var i = 0; i < $ctrl.groups.length; i++) {
                if ($ctrl.groups[i].title != null) {
                    $ctrl.groups[i].translated = $ctrl.translateKey($ctrl.groups[i].title);
                }
            }
            if ($ctrl.useShortStrings) {
                $ctrl.createShortString();
            }
        });

        this.$onDestroy = function() {
            translateSuccess();
        };


        this.$onInit = function() {
            if ($ctrl.dynamicImage == null) {
                $ctrl.dynamicImage = false;
            }
            console.error(!angular.isFunction($ctrl.actionCallback))
            if (!angular.isFunction($ctrl.actionCallback)) {
                $ctrl.actionCallback = angular.noop;
            }

            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            // angular.copy($ctrl.selectables, $ctrl.model);
            $ctrl.selectables.unshift($ctrl.noOption);
            enhanceGroupFn($ctrl.selectables);
            $ctrl.model = $ctrl.selectables;

            if ($ctrl.reference && angular.isDefined($ctrl.reference) && $ctrl.groups.length > 0) {
                try {
                    $ctrl.selected = $ctrl.reference;
                    _.forEach($ctrl.reference, function(value) {
                        var idx = $ctrl.groups.findIndex(function(current) {
                            return value == current.title;
                        });
                        if (idx >= 0) {
                            $ctrl.groups[idx].isSelected = true;
                        }
                    });
                    if ($ctrl.useShortStrings) {
                        $ctrl.createShortString();
                    }
                } catch (e) {
                    console.error('error with: ', $ctrl.reference, $ctrl.groups);
                }
            }
            if ($ctrl.useShortStrings) {
                $ctrl.createShortString();
            }
            // Used for the validation
            var toggle = $element.find('.dynamicMultiDropdown');
            $(toggle).attr('id', $ctrl.referenceString + '.drop2down-toggle');

        };
    }
})();
