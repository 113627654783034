(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('OperationalCostsCalculatingErrorController', OperationalCostsCalculatingErrorController);

    OperationalCostsCalculatingErrorController.$inject = [];

    function OperationalCostsCalculatingErrorController() {
    }

})();
