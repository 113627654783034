(function () {
    "use strict";

    angular
        .module("kennwerteApp")
        .factory("UsageSliderMappingService", UsageSliderMappingService);

    UsageSliderMappingService.$inject = [];

    /**
     * Contains sliders encoded as arrays in this order:
     * ['Umgebung', 'Grundstück', 'Vorbereitung', 'Fensteranteil', 'Gebäudeform', 'Elektroanlage', 'Heizungsanlage', 'Sanitäranlage', 'Ausbau 1', 'Ausbau 2']
     * Is used in kw-lambda-node-usage-preset (seperated repo) - microservice for kw-api!
     */
    function UsageSliderMappingService() {
        var mapping = {
            WOHNBAUTEN__MFH: {
                LOW: [3, 3, 3, 3, 3, 3, 3, 3, 2, 2],
                MEDIUM: [3, 3, 3, 3, 4, 3, 4, 4, 3, 3],
                HIGH: [4, 3, 3, 4, 5, 4, 5, 5, 4, 3]
            },
            WOHNBAUTEN__TERRASSENHAEUSER: {
                LOW: [3, 5, 3, 3, 5, 3, 3, 4, 2, 2],
                MEDIUM: [4, 5, 3, 3, 5, 3, 4, 5, 3, 3],
                HIGH: [4, 5, 3, 4, 5, 4, 5, 5, 4, 4]
            },
            WOHNBAUTEN__EFH_REIHEN: {
                LOW: [3, 3, 3, 2, 3, 3, 3, 4, 2, 2],
                MEDIUM: [3, 3, 3, 3, 4, 3, 4, 4, 3, 3],
                HIGH: [4, 3, 3, 4, 5, 4, 5, 5, 4, 4]
            },
            WOHNBAUTEN__ALTERSWOHNUNGEN: {
                LOW: [3, 3, 3, 3, 3, 3, 3, 4, 3, 2],
                MEDIUM: [3, 3, 3, 3, 4, 4, 4, 5, 3, 3],
                HIGH: [4, 3, 3, 4, 4, 4, 5, 5, 4, 3]
            },
            WOHNBAUTEN__ALTERSHEIME: {
                LOW: [4, 3, 3, 3, 3, 4, 4, 4, 3, 3],
                MEDIUM: [5, 3, 3, 3, 4, 5, 5, 5, 4, 4],
                HIGH: [5, 3, 3, 4, 4, 5, 5, 5, 5, 4]
            },
            WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: {
                LOW: [3, 3, 3, 3, 3, 4, 4, 4, 3, 3],
                MEDIUM: [4, 3, 3, 3, 4, 5, 5, 5, 4, 4],
                HIGH: [4, 3, 3, 3, 4, 5, 5, 5, 5, 4]
            },
            WOHNBAUTEN__STUDENTENWOHNHAEUSER: {
                LOW: [3, 3, 3, 3, 3, 3, 3, 4, 3, 2],
                MEDIUM: [4, 4, 3, 3, 4, 4, 4, 4, 4, 3],
                HIGH: [4, 4, 3, 3, 4, 4, 5, 5, 5, 3]
            },
            WOHNBAUTEN__PERSONALHAEUSER: {
                LOW: [3, 3, 3, 2, 2, 3, 3, 4, 2, 2],
                MEDIUM: [3, 3, 3, 3, 3, 3, 4, 4, 2, 2],
                HIGH: [4, 3, 3, 3, 4, 4, 5, 5, 3, 3]
            },
            SCHULBAUTEN__KINDERGAERTEN_HORTE: {
                LOW: [4, 3, 3, 3, 3, 4, 3, 2, 4, 3],
                MEDIUM: [4, 3, 3, 3, 4, 4, 5, 3, 4, 4],
                HIGH: [4, 3, 3, 4, 4, 5, 5, 3, 5, 5]
            },
            SCHULBAUTEN__VOLKSSCHULEN: {
                LOW: [3, 3, 3, 4, 3, 4, 4, 2, 3, 3],
                MEDIUM: [4, 3, 3, 4, 3, 4, 5, 2, 4, 4],
                HIGH: [4, 3, 4, 4, 4, 5, 5, 3, 5, 5]
            },
            SCHULBAUTEN__BERUFSSCHULEN: {
                LOW: [3, 3, 3, 4, 3, 4, 5, 2, 3, 3],
                MEDIUM: [3, 3, 3, 4, 4, 5, 5, 2, 4, 4],
                HIGH: [4, 3, 4, 5, 4, 5, 5, 3, 4, 5]
            },
            SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: {
                LOW: [4, 3, 3, 4, 3, 4, 5, 2, 4, 3],
                MEDIUM: [5, 3, 3, 4, 4, 4, 5, 3, 4, 4],
                HIGH: [5, 3, 4, 4, 4, 5, 5, 3, 5, 5]
            },
            SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: {
                LOW: [3, 3, 3, 4, 3, 5, 5, 2, 3, 3],
                MEDIUM: [3, 3, 3, 4, 4, 5, 5, 2, 4, 4],
                HIGH: [4, 3, 4, 5, 4, 6, 5, 3, 5, 5]
            },
            SCHULBAUTEN__BIBLIOTHEKEN: {
                LOW: [3, 2, 3, 3, 1, 4, 5, 2, 2, 2],
                MEDIUM: [3, 3, 4, 4, 1, 4, 5, 2, 2, 3],
                HIGH: [3, 3, 4, 5, 1, 5, 5, 3, 3, 4]
            },
            SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: {
                LOW: [3, 3, 2, 3, 1, 5, 6, 4, 3, 5],
                MEDIUM: [3, 3, 3, 3, 2, 6, 6, 5, 3, 5],
                HIGH: [3, 3, 3, 3, 3, 6, 6, 5, 4, 6]
            },
            SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: {
                LOW: [3, 3, 2, 3, 1, 4, 4, 2, 2, 2],
                MEDIUM: [3, 2, 3, 3, 2, 5, 5, 2, 2, 2],
                HIGH: [3, 2, 3, 3, 3, 4, 5, 2, 3, 2]
            },
            LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: {
                LOW: [2, 1, 0, 0, 0, 2, 0, 1, 0, 0],
                MEDIUM: [3, 2, 1, 1, 0, 3, 0, 1, 1, 0],
                HIGH: [3, 2, 2, 1, 1, 3, 0, 1, 1, 0]
            },
            LAGERBAUTEN_MEHRSTOECKIGE: {
                LOW: [2, 1, 0, 0, 1, 2, 1, 1, 1, 1],
                MEDIUM: [4, 2, 1, 1, 1, 3, 1, 1, 1, 2],
                HIGH: [5, 2, 2, 1, 2, 3, 2, 1, 2, 2]
            },
            LAGERBAUTEN__VERTEILZENTRALEN: {
                LOW: [3, 1, 0, 1, 1, 2, 1, 1, 1, 1],
                MEDIUM: [4, 2, 1, 2, 1, 3, 1, 1, 1, 2],
                HIGH: [4, 2, 2, 2, 2, 3, 1, 2, 2, 2]
            },
            LAGERBAUTEN__INDUSTRIEHALLEN: {
                LOW: [3, 1, 1, 1, 1, 3, 1, 1, 1, 0],
                MEDIUM: [3, 2, 2, 1, 2, 3, 2, 1, 2, 1],
                HIGH: [3, 2, 3, 1, 2, 4, 2, 2, 2, 1]
            },
            LAGERBAUTEN_PRODUKTIONSBAUTEN: {
                LOW: [3, 2, 2, 3, 1, 4, 3, 1, 1, 2],
                MEDIUM: [3, 2, 2, 4, 2, 4, 5, 2, 2, 2],
                HIGH: [4, 3, 3, 5, 2, 5, 5, 2, 2, 2]
            },
            LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL: {
                LOW: [4, 3, 3, 3, 2, 3, 3, 2, 2, 2],
                MEDIUM: [4, 3, 3, 3, 3, 3, 4, 2, 2, 3],
                HIGH: [4, 3, 3, 4, 3, 4, 5, 3, 3, 3]
            },
            LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL: {
                LOW: [4, 3, 3, 3, 2, 4, 3, 2, 1, 1],
                MEDIUM: [4, 4, 3, 4, 2, 4, 3, 2, 2, 2],
                HIGH: [5, 4, 3, 5, 2, 4, 4, 2, 2, 2]
            },
            LADENBAUTEN_HANDELSBAUTEN__MARKTHALLEN: {
                LOW: [4, 4, 3, 1, 1, 1, 0, 1, 1, 1],
                MEDIUM: [4, 4, 3, 1, 3, 3, 2, 2, 2, 1],
                HIGH: [5, 4, 3, 2, 4, 5, 2, 3, 2, 2]
            },
            VERWALTUNGSBAUTEN: {
                LOW: [4, 2, 3, 4, 2, 5, 5, 2, 3, 3],
                MEDIUM: [5, 3, 4, 5, 3, 6, 5, 2, 4, 4],
                HIGH: [5, 4, 4, 4, 4, 6, 6, 3, 5, 5]
            },
            BANKEN: {
                LOW: [4, 3, 3, 4, 2, 5, 4, 2, 4, 3],
                MEDIUM: [5, 4, 4, 4, 3, 5, 5, 3, 4, 4],
                HIGH: [5, 5, 4, 4, 4, 6, 6, 3, 5, 5]
            },
            BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER: {
                LOW: [4, 2, 3, 3, 2, 4, 5, 2, 3, 2],
                MEDIUM: [5, 3, 3, 4, 3, 5, 5, 2, 4, 4],
                HIGH: [5, 3, 4, 4, 4, 5, 6, 2, 5, 5]
            },
            LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER: {
                LOW: [5, 4, 3, 4, 3, 5, 5, 2, 4, 3],
                MEDIUM: [6, 4, 4, 4, 4, 6, 5, 2, 5, 4],
                HIGH: [7, 5, 4, 4, 4, 6, 6, 3, 5, 6]
            },
            BUEROBAUTEN__EDELROHBAU: {
                LOW: [4, 2, 3, 3, 2, 4, 3, 2, 1, 1],
                MEDIUM: [4, 3, 3, 4, 3, 4, 4, 2, 2, 2],
                HIGH: [4, 3, 4, 5, 4, 5, 4, 2, 3, 3]
            },
            BUEROBAUTEN__VOLLAUSBAU: {
                LOW: [4, 2, 3, 3, 2, 5, 4, 2, 3, 2],
                MEDIUM: [4, 3, 3, 4, 3, 5, 5, 2, 3, 4],
                HIGH: [4, 3, 4, 5, 4, 6, 5, 2, 4, 5]
            },
            JUSTIZ__STRAFVOLLZUGSANSTALTEN: {
                LOW: [4, 3, 4, 2, 2, 4, 4, 4, 4, 3],
                MEDIUM: [4, 3, 5, 2, 3, 5, 4, 4, 4, 3],
                HIGH: [4, 3, 5, 2, 4, 5, 4, 5, 5, 4]
            },
            GESUNDHEITSBAUTEN__TAGESHEIME: {
                LOW: [3, 3, 3, 3, 1, 4, 3, 2, 3, 3],
                MEDIUM: [4, 3, 3, 4, 2, 4, 4, 3, 4, 4],
                HIGH: [4, 3, 4, 4, 3, 5, 5, 3, 5, 5]
            },
            GESUNDHEITSBAUTEN__KRANKENHAEUSER: {
                LOW: [4, 3, 3, 4, 3, 6, 6, 5, 4, 4],
                MEDIUM: [4, 3, 3, 4, 4, 6, 6, 6, 5, 5],
                HIGH: [5, 3, 4, 4, 5, 6, 6, 6, 6, 6]
            },
            GESUNDHEIT__PFLEGEHEIME: {
                LOW: [4, 3, 3, 3, 4, 5, 4, 4, 4, 3],
                MEDIUM: [5, 3, 3, 4, 4, 5, 5, 5, 5, 4],
                HIGH: [6, 3, 3, 4, 5, 6, 5, 5, 5, 5]
            },
            KULTUS__KIRCHGEMEINDEHAEUSER: {
                LOW: [4, 2, 3, 3, 2, 4, 3, 2, 3, 3],
                MEDIUM: [4, 3, 3, 3, 3, 4, 4, 3, 3, 4],
                HIGH: [4, 3, 3, 3, 4, 4, 5, 4, 4, 5]
            },
            KULTUR__AUSSTELLUNGEN: {
                LOW: [4, 3, 2, 3, 3, 4, 4, 2, 2, 2],
                MEDIUM: [4, 3, 3, 4, 4, 5, 5, 2, 3, 3],
                HIGH: [5, 3, 3, 4, 4, 6, 5, 3, 5, 5]
            },
            KULTUR__MUSEEN: {
                LOW: [5, 4, 2, 3, 3, 5, 4, 2, 3, 4],
                MEDIUM: [6, 5, 4, 3, 4, 6, 6, 3, 4, 5],
                HIGH: [6, 5, 6, 3, 4, 7, 6, 3, 5, 6]
            },
            KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: {
                LOW: [3, 1, 3, 3, 2, 4, 3, 2, 1, 2],
                MEDIUM: [4, 2, 3, 4, 4, 4, 4, 3, 3, 3],
                HIGH: [4, 2, 3, 4, 5, 5, 5, 3, 5, 5]
            },
            KULTUR__SAAL: {
                LOW: [4, 2, 2, 4, 3, 4, 4, 2, 1, 2],
                MEDIUM: [5, 3, 2, 4, 4, 5, 5, 2, 3, 3],
                HIGH: [5, 3, 3, 4, 4, 5, 5, 2, 4, 4]
            },
            GASTGEWERBE_FREMDENVERKEHRSBAUTEN__RESTAURANT: {
                LOW: [3, 4, 3, 5, 4, 5, 4, 5, 4, 4],
                MEDIUM: [4, 4, 3, 5, 5, 5, 5, 5, 5, 5],
                HIGH: [5, 4, 3, 5, 5, 6, 5, 5, 6, 6]
            },
            GASTGEWERBE_FREMDENVERKEHRSBAUTEN__KANTINEN: {
                LOW: [3, 4, 3, 5, 3, 5, 4, 4, 3, 3],
                MEDIUM: [3, 4, 3, 5, 3, 5, 5, 4, 3, 4],
                HIGH: [4, 4, 3, 5, 4, 6, 5, 4, 4, 4]
            },
            GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HOTELBAUTEN: {
                LOW: [4, 3, 3, 3, 2, 4, 4, 4, 4, 3],
                MEDIUM: [4, 3, 3, 3, 4, 5, 5, 5, 4, 4],
                HIGH: [5, 4, 3, 4, 5, 5, 5, 6, 5, 5]
            },
            GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HERBERGEN: {
                LOW: [
                    2,
                    3,
                    3,
                    3,
                    2,
                    4,
                    4,
                    4,
                    3,
                    3
                ],
                MEDIUM: [
                    3,
                    4,
                    3,
                    3,
                    4,
                    4,
                    5,
                    4,
                    4,
                    4
                ],
                HIGH: [
                    4,
                    4,
                    3,
                    4,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5
                ]
            },
            FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TURNHALLEN_SPORTHALLEN_MEHRZWECKHALLEN: {
                LOW: [4, 2, 3, 3, 2, 4, 5, 3, 2, 4],
                MEDIUM: [4, 2, 3, 3, 3, 4, 5, 3, 3, 5],
                HIGH: [5, 3, 4, 3, 4, 5, 5, 4, 3, 5]
            },
            FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE: {
                LOW: [3, 2, 3, 2, 2, 4, 4, 6, 2, 2],
                MEDIUM: [3, 2, 3, 2, 3, 4, 5, 6, 4, 4],
                HIGH: [3, 2, 4, 2, 4, 5, 5, 6, 5, 5]
            },
            FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER: {
                LOW: [4, 2, 3, 4, 2, 4, 7, 3, 2, 3],
                MEDIUM: [4, 2, 3, 5, 2, 4, 7, 3, 3, 4],
                HIGH: [5, 3, 4, 5, 3, 5, 7, 4, 5, 5]
            },
            VERKEHRSBAUTEN__GARAGEN_FREISTEHEND: {
                LOW: [4, 0, 3, 0, 0, 1, 0, 0, 1, 1],
                MEDIUM: [4, 1, 3, 0, 1, 2, 0, 0, 1, 1],
                HIGH: [4, 2, 3, 0, 1, 2, 0, 0, 1, 1]
            },
            VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN: {
                LOW: [4, 3, 3, 0, 2, 2, 1, 1, 1, 1],
                MEDIUM: [4, 3, 3, 0, 2, 2, 1, 1, 1, 1],
                HIGH: [4, 3, 3, 0, 3, 3, 2, 1, 2, 2]
            },
            VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH: {
                LOW: [5, 5, 5, 0, 2, 3, 1, 1, 1, 2],
                MEDIUM: [5, 5, 6, 0, 2, 3, 2, 2, 2, 2],
                HIGH: [5, 5, 6, 0, 3, 3, 2, 2, 2, 2]
            },
            VERKEHRSBAUTEN__WERKHOEFE: {
                LOW: [4, 2, 3, 3, 2, 3, 2, 2, 2, 2],
                MEDIUM: [5, 2, 3, 3, 2, 3, 2, 2, 2, 2],
                HIGH: [5, 2, 3, 3, 3, 4, 3, 3, 2, 3]
            },
            VERKEHRSBAUTEN__ZOLL: {
                LOW: [5, 3, 3, 3, 2, 4, 5, 2, 3, 3],
                MEDIUM: [5, 3, 3, 3, 3, 5, 6, 3, 4, 4],
                HIGH: [5, 3, 3, 3, 4, 6, 6, 3, 4, 4]
            },
            SCHUTZBAUTEN__KASERNEN: {
                LOW: [5, 3, 3, 3, 2, 3, 3, 3, 2, 2],
                MEDIUM: [5, 3, 3, 3, 3, 4, 3, 4, 2, 3],
                HIGH: [5, 3, 3, 3, 3, 4, 3, 4, 3, 4]
            },
            SCHUTZBAUTEN__FEUERWEHRGEBAEUDE: {
                LOW: [4, 2, 3, 4, 2, 5, 3, 2, 2, 2],
                MEDIUM: [5, 3, 3, 4, 2, 5, 4, 3, 3, 2],
                HIGH: [5, 3, 3, 4, 3, 4, 4, 3, 3, 3]
            },
            SCHUTZBAUTEN__SCHULUNGSBAUTEN: {
                LOW: [3, 3, 3, 4, 3, 4, 3, 2, 3, 3],
                MEDIUM: [4, 3, 3, 4, 3, 4, 3, 2, 4, 4],
                HIGH: [5, 3, 4, 4, 4, 5, 3, 3, 4, 5]
            },
            LANDWIRTSCHAFT__SCHEUNEN: {
                LOW: [1, 2, 1, 0, 0, 1, 0, 0, 0, 0],
                MEDIUM: [2, 2, 2, 1, 1, 1, 0, 0, 1, 0],
                HIGH: [2, 2, 2, 1, 1, 2, 0, 1, 1, 0]
            },
            LANDWIRTSCHAFT_STALLUNGEN: {
                LOW: [1, 2, 1, 0, 1, 1, 0, 0, 0, 0],
                MEDIUM: [2, 2, 2, 1, 1, 2, 0, 1, 1, 0],
                HIGH: [2, 2, 2, 1, 2, 2, 0, 2, 1, 1]
            },
            LANDWIRTSCHAFT__LANDWIRTSCHAFTLICHE_ANLAGEN: {
                LOW: [1, 2, 1, 0, 1, 1, 0, 1, 0, 0],
                MEDIUM: [2, 2, 2, 1, 1, 2, 0, 1, 1, 0],
                HIGH: [2, 2, 2, 1, 2, 2, 0, 2, 1, 0]
            }
        };

        return {
            getMapping: function () {
                return mapping;
            }
        };
    }
})();
