(function () {
    'use strict';

    angular.module('kennwerteApp')
    .directive('descriptionRow', DescriptionRowDirective);

    DescriptionRowDirective.$inject = [];

    function DescriptionRowDirective() {
        return {
            restrict: 'A',
            transclude: {
                'header': '?descriptionBoxHeader',
                'footer': '?descriptionBoxFooter'
            },
            scope: {
                superTitle: '@?',
                leftFloat: '@?',
                calculationRowTitle: '@',
                defaultimage: '@',
                defaultDescriptionBaseKey: '@',
                translateDescriptionBaseKey: '@', // used to define the full translate description key (field specification)
                rowid: '=', // used to set the id of the row-image
                countUsages: '&', //function that counts usages
                contentRowInnerStyle: '=',
                titleAlinedContent: '=?',
                ctPartFormStyle: '=',
                ctPartImgStyle: '=',
                ctPartDescrStyle: '=',
                ctPartClearStyle: '=',
                hasSliders: '=?',
                infotext: '@?',
                dynamicTranslations: '<?',
                disableDescriptionBox: '@?'
            },
            templateUrl: 'app/components/description_row/description-row.html',
            controller: DescriptionRowDirectiveController,
            controllerAs: 'vm',
            link: function (scope, elem, attrs, controller, transclude) {
            }
        };
    }

    DescriptionRowDirectiveController.$inject = ['$scope', '$timeout', '$translate', '$rootScope', '$element'];

    function DescriptionRowDirectiveController($scope, $timeout, $translate, $rootScope, $element) {

        var vm = this;
        if (!$scope.translateDescriptionBaseKey) {
            $scope.translateDescriptionBaseKey = $scope.defaultDescriptionBaseKey;
        }
        if (!$scope.sliderNotPresent) {
            $scope.sliderNotPresent = false;
        }
        if (!$scope.miniSlider) {
            $scope.miniSlider = 5;
        }
        if (angular.isUndefined($scope.dynamicTranslations)) {
            $scope.dynamicTranslations = false;
        }
        if (angular.isUndefined($scope.titleAlinedContent)) {
            $scope.titleAlinedContent = false;
        }

        var getBulletPoints = function () {
            var bulletPointTranslationStr = $scope.translateDescriptionBaseKey + '.' + $scope.classType + '.' + 'bulletPointCounter';
            $translate(bulletPointTranslationStr).then(function(translation) {
                $scope.bulletPointCounter = parseInt(translation);
                vm.bulletPoints = _.range($scope.bulletPointCounter);
                if (vm.bulletPoints) {
                    $scope.elementHeight = $element.find('#bulletPoints').height();
                }
            }, function(translationId) {
                $scope.bulletPointCounter = 0;
                vm.bulletPoints = [];
                //$scope.elementHeight = $element.find('#bulletPoints').height();
            }).catch(function(e) {
                console.error(e);
            });
        };
        getBulletPoints();
        $scope.$on("updateImage", function (event, args) {
            // set the new image for the image in the corresponding row
            if (args.rowid == $scope.rowid && args != null) {
                var rowImage = parent.document.getElementById('row_image_' + args.rowid);
                if (rowImage != null) {
                    if (rowImage.src != null) {
                        rowImage.src = args.imageSrc;
                    }
                }
            }
        });

        $scope.$on("updateDescription", function (event, args) {
            // set the new description of the correspondig row
            if (args.rowid == $scope.rowid) {
                $timeout(function () {
                    $scope.$apply();
                    $scope.dynamicTranslations = !!args.dynamicTranslations;
                    $scope.translateDescriptionBaseKey = args.descriptionBaseKey;
                    $scope.classType = args.classType;
                    $scope.extras = args.extras;
                    $scope.isNew = args.isNew;
                    $scope.isChanging = args.isChanging;
                    $scope.isOutsideSuggestion = args.isOutsideSuggestion;
                    $scope.isInSuggestionGap = args.inSuggestionGap;
                    $scope.sliderNotPresent = args.sliderNotPresent;
                    $scope.sliderRange = args.sliderRange;
                    getBulletPoints();
                });
            }
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function () {
            if ($scope.classType != null) {
                var bulletPointTranslationStr = $scope.translateDescriptionBaseKey + '.' + $scope.classType + '.' + 'bulletPointCounter';
                $translate(bulletPointTranslationStr).then(function(translation) {
                    $scope.bulletPointCounter = translation;
                    getBulletPoints();
                }).catch(function(e) {
                    console.error(e);
                });
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
    }

})();
