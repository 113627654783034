(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('textInput', TextInputDirective);

    TextInputDirective.$inject = ['$rootScope'];
    function TextInputDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                reference: '=reference',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@',
                isDisabled: '=',
                minlength: '@',
                maxlength: '@',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '='
            },
            templateUrl: 'app/components/text_input/text-input.tpl.html',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {

                var input = document.getElementById('inputid');

                var inputField = tElement.find("input")[0];

                if (tAttrs.required) {
                    inputField.required = true;
                }

                // Used for the validation
                $(tElement).attr("id", tAttrs.reference + ".formElement");
                $(inputField).attr("id", tAttrs.reference + ".input");


                inputField.addEventListener("focus", function () {

                    // Push event to CalculationRowController
                    $rootScope.$broadcast("updateImage", {
                        imageSrc: sanitizeUrl(tAttrs.image),
                        rowid: Number(tAttrs.rowid)
                    });
                    $rootScope.$broadcast("updateDescription", {
                        rowid: Number(tAttrs.rowid),
                        descriptionNumber: 0,
                        descriptionBaseKey: tAttrs.descriptionBaseKey
                    });
                }, true);

            }
        };
    }

})();
