(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('RegisterInnerController', RegisterInnerController);


    RegisterInnerController.$inject = ['$scope', '$state', '$translate', '$timeout', 'Auth', 'LoginService', 'Organisation', 'BaseLicenseService',
        'BillingService', '$location', 'Principal', 'ProfileService', 'GTMService', '$sessionStorage', 'ContactMeService'];

    function RegisterInnerController($scope, state, $translate, $timeout, Auth, LoginService, Organisation, BaseLicenseService,
                                     BillingService, $location, Principal, ProfileService, GTMService, $sessionStorage, ContactMeService) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.errorPaymentFormError = null;
        vm.errorPaymentFormAbort = null;
        vm.errorPaymentFormSystemError = null;
        vm.errorOrgNameExists = null;
        vm.errorAcceptConditions = null;
        vm.errorAcceptNoAnonymous = null;
        vm.errorMissing = null;
        vm.token = null;
        vm.login = LoginService.open;
        vm.organisation = {};
        vm.organisation.billingContact = {};
        if ($sessionStorage.register) {
            vm.registerAccount = $sessionStorage.register.registerAccount;
            vm.organisation = $sessionStorage.register.organisation;
            vm.numAdditionalUsers = $sessionStorage.register.numAdditionalUsers;
        }
        vm.success = null;
        vm.checkSuccess = null;
        vm.hasStartFreeMonth = false;
        vm.hasAcceptedConditions = false;
        vm.hasAcceptedNoAnonymous = false;
        vm.billingContactEqualsOrgContact = false;
        vm.isAuthenticated = false;
        vm.disableContactButton = false;

        vm.chooseBuildingCosts = false;
        vm.chooseOperatingCosts = false;
        vm.chooseRebuildCosts = false;

        vm.selectedWithdrawalProductTypes = [];

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
        });

        vm.isAuthenticated = Principal.isAuthenticated();

        vm.forward = forward;
        vm.register = register;
        vm.startFreeMonth = startFreeMonth;
        vm.showPrice = showPrice;

        $scope.$on('authenticationSuccess', function () {
            vm.isAuthenticated = Principal.isAuthenticated();
        });

        function handleSelectedWp(newVal, wp) {
            if (!!vm.organisation) {
                if (newVal) {
                    if (vm.selectedWithdrawalProductTypes.indexOf(wp) === -1) {
                        vm.selectedWithdrawalProductTypes.push(wp);
                    }
                } else {
                    if (vm.selectedWithdrawalProductTypes.indexOf(wp) !== -1) {
                        vm.selectedWithdrawalProductTypes.splice(vm.selectedWithdrawalProductTypes.indexOf(wp), 1);
                    }
                }
                if (!vm.numAdditionalUsers) {
                    vm.numAdditionalUsers = 0;
                }
                vm.organisation.withdrawalProductTypes = vm.selectedWithdrawalProductTypes;
                var registerDTO = {
                    'account': vm.registerAccount,
                    'organisation': vm.organisation,
                    'numAdditionalUsers': vm.numAdditionalUsers
                };

                if (vm.organisation != null && vm.registerAccount != null) {
                    BillingService.fetchTentativePrice(registerDTO).then(
                        function(res) {
                            vm.price = res.data;
                        }
                    ).catch(
                        function(err) {
                            console.error('fetchTentativePrice: ', err);
                        });
                }
            }
        }

        $scope.$watch('vm.chooseBuildingCosts', function (newVal) {
            var wp = 'BUILDING_COSTS_AND_DATES';
            handleSelectedWp(newVal, wp);
        });

        $scope.$watch('vm.chooseOperatingCosts', function (newVal) {
            var wp = 'OPERATING_COSTS';
            handleSelectedWp(newVal, wp);
        });

        $scope.$watch('vm.chooseRebuildCosts', function (newVal) {
            var wp = 'REBUILD_COSTS';
            handleSelectedWp(newVal, wp);
        });

        $scope.$watch('vm.hasAcceptedConditions', function (newVal) {
            if (newVal) {
                vm.errorAcceptConditions = null;
            }
        });

        $scope.$watch('vm.hasAcceptedNoAnonymous', function (newVal) {
            if (newVal) {
                vm.errorAcceptNoAnonymous = null;
            }
        });

        $scope.$watch('vm.organisation.siaIndividualMember', function (newVal) {
            if (newVal) {
                vm.organisation.siaCorporateMember = false;
            }
        });

        $scope.$watch('vm.organisation.siaCorporateMember', function (newVal) {
            if (newVal) {
                vm.organisation.siaIndividualMember = false;
            }
        });

        BaseLicenseService.actives().$promise.then(
            function(res) {
                vm.baseLicenses = res;
                vm.discountInPercentIfSiaIndividual = res[0].discountInPercentIfSiaIndividual;
                vm.discountInPercentIfSiaCorporate = res[0].discountInPercentIfSiaCorporate;
            }
        );

        $timeout(function () {
            angular.element('#login').focus();
        });

        function prepare(fun) {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.langKey = $translate.use();
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.errorInvalidEmail = null;
                vm.errorOrgNameExists = null;
                if (vm.numAdditionalUsers == null) {
                    vm.numAdditionalUsers = 0;
                }
                if (vm.billingContactEqualsOrgContact) {
                    vm.organisation.billingContact = null;
                }
                var registerDTO = {
                    'account': vm.registerAccount,
                    'organisation': vm.organisation,
                    'numAdditionalUsers': vm.numAdditionalUsers
                };
                fun(registerDTO);
            }
        }

        function forward() {
            var registerAccountCopy = {};
                        angular.copy(vm.registerAccount, registerAccountCopy);
            $sessionStorage.register = {
                            registerAccount: registerAccountCopy,
                            numAdditionalUsers: vm.numAdditionalUsers,
                            organisation: vm.organisation
                        };
            if (vm.form.$invalid) {
                angular.forEach(vm.form.$error.required, function(field) {
                    field.$setDirty();
                });
                vm.errorMissing = 'ERROR';
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.errorInvalidEmail = null;
                vm.errorOrgNameExists = null;
            } else {
                vm.errorMissing = null;
                if (!vm.hasAcceptedConditions) {
                    if (vm.inProduction) {
                        GTMService.trackEvent({
                            action: 'start-free-month.conditions-fail',
                            category: 'Register'
                        });
                    }
                    vm.errorAcceptConditions = 'ERROR';
                    return;
                } else {
                    vm.errorAcceptConditions = null;
                }
                if (!vm.hasAcceptedNoAnonymous) {
                    if (vm.inProduction) {
                        GTMService.trackEvent({
                            action: 'start-free-month.conditions-fail',
                            category: 'Register'
                        });
                    }
                    vm.errorAcceptNoAnonymous = 'ERROR';
                    return;
                } else {
                    vm.errorAcceptNoAnonymous = null;
                }

                var fun = function(registerDTO) {
                    Auth.checkAccountCreation(registerDTO).then(function() {
                        var registerAccountCopy = {};
                        angular.copy(vm.registerAccount, registerAccountCopy);
                        registerAccountCopy.password = undefined;

                        $sessionStorage.register = {
                            registerAccount: registerAccountCopy,
                            numAdditionalUsers: vm.numAdditionalUsers,
                            organisation: vm.organisation
                        };
                        vm.showPrice();
                        vm.checkSuccess = 'OK';
                        /* FIXME credit card related.
                        BillingService.insertAlias(initTransactionDTO,
                            function (billingFormDTO) {
                                $scope.paymentUrl = billingFormDTO.data.redirectUrl;
                                vm.token = billingFormDTO.data.token;
                                vm.errorPaymentFormError = null;
                                if (vm.inProduction) {
                                    GTMService.trackEvent({
                                        action: 'start-free-month.card-presentation-success',
                                        category: 'Register'
                                    });
                                }
                            },
                            function (billingFormDTO) {
                                if (vm.inProduction) {
                                    GTMService.trackEvent({
                                        action: 'start-free-month.card-presentation-fail',
                                        category: 'Register'
                                    });
                                }
                                vm.errorPaymentFormError = 'ERROR';
                            });*/
                        vm.checkSuccess = 'OK';
                    }).catch(function (response) {
                        vm.checkSuccess = null;
                        if (response.status === 400 && response.data === 'login already in use') {
                            vm.errorUserExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'email address already in use') {
                            vm.errorEmailExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'organisation name already in use') {
                            vm.errorOrgNameExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'invalid email') {
                            vm.errorInvalidEmail = 'ERROR';
                        } else {
                            vm.error = 'ERROR';
                        }
                        if (vm.inProduction) {
                            GTMService.trackEvent({
                                action: 'start-free-month.account-fail',
                                category: 'Register',
                                label: response.data
                            });
                        }
                    });
                };
                prepare(fun);
            }
        }

        function register() {
            function fun(registerDTO) {
                registerDTO.token = vm.token;
                Auth.createAccount(registerDTO).then(function () {
                    vm.success = 'OK';
                    if (vm.inProduction) {
                        GTMService.trackEvent({
                            action: 'start-free-month.card-success',
                            category: 'Register'
                        });
                    }
                    delete $sessionStorage.register;
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'organisation name already in use') {
                        vm.errorOrgNameExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'invalid email') {
                        vm.errorInvalidEmail = 'ERROR';
                    } else {
                        vm.errorPaymentFormError = 'ERROR';
                    }
                    if (vm.inProduction) {
                        GTMService.trackEvent({
                            action: 'start-free-month.account-fail',
                            category: 'Register',
                            label: response.data
                        });
                    }
                });
            }

            prepare(fun);
        }

        function startFreeMonth() {
            if (vm.inProduction) {
                GTMService.trackEvent({
                    action: 'start-free-month',
                    category: 'Register'
                });
            }
            vm.hasStartFreeMonth = true;

            if ($sessionStorage.register) {
                $timeout(function () {
                    angular.forEach(vm.form.$error.required, function (field) {
                        field.$setDirty();
                    });
                }, 1);
            }
        }

        function showPrice() {
            var fetchLicenseDTO = {
                "numEmployees": vm.organisation.numEmployees
            };
            BaseLicenseService.fetchLicense(fetchLicenseDTO).$promise.then(
                function(res) {
                    vm.baseLicense = res;
                    var now = new Date();
                    var nowPlus7days = new Date(now);
                    nowPlus7days.setDate(now.getDate() + 7);
                    vm.dateTimeFreeUntil = new Date(nowPlus7days.getFullYear(), nowPlus7days.getMonth(), nowPlus7days.getDate(), nowPlus7days.getHours(), nowPlus7days.getMinutes(), nowPlus7days.getSeconds());
                }
            );
            vm.organisation.withdrawalProductTypes = vm.selectedWithdrawalProductTypes;
            vm.numAdditionalUsers = vm.numAdditionalUsers != null ? vm.numAdditionalUsers : 0;
            var registerDTO = {
                "account": vm.registerAccount,
                "organisation": vm.organisation,
                "numAdditionalUsers": vm.numAdditionalUsers
            };
            BillingService.fetchTentativePrice(registerDTO).then(
                function(res) {
                    vm.price = res.data;
                },
                function(err) {
                    console.error('reg inner ten price 2: ', err);
                }
            );
        }

        /**
         * This function handles contact information for bigger Organisations.
         */
        vm.sendContact = function () {

            vm.disableContactButton = true;
            delete $sessionStorage.register;
            var account = angular.copy(vm.registerAccount);
            delete account.password;
            // vm.numAdditionalUsers = vm.numAdditionalUsers != null ? vm.numAdditionalUsers : 0;
            var contactMeDTO = {
                account: account,
                organisation: vm.organisation,
                numAdditionalUsers: vm.numAdditionalUsers,
                message: vm.comment
            };
            ContactMeService.save(contactMeDTO,
                function (result) {
                    vm.contactSuccess = true;
                }, function (error) {
                    vm.contactSuccess = false;
                });
        };

    }
})();
