(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('usageTypeGroupFactory', UsageTypeGroupFactory);

    UsageTypeGroupFactory.$inject = [];

    function UsageTypeGroupFactory() {
        var data = {
            "00": [
                {"id": "01", "title": "WOHNBAUTEN__TITLE", "parent": true},
                {"id": "02", "title": "SCHULBAUTEN__TITLE", "parent": true},
                {"id": "03", "title": "LAGERBAUTEN__TITLE", "parent": true},
                {"id": "03A", "title": "PRODUKTIONSBAUTEN__TITLE", "parent": true},
                {"id": "04", "title": "LANDWIRTSCHAFT__TITLE", "parent": true},
                //{"id": "05", "title": "TECHNISCHE_ANLAGEN__TITLE", "parent": true},
                {"id": "06", "title": "LADENBAUTEN_HANDELSBAUTEN__TITLE", "parent": true},
                {"id": "06A", "title": "BUEROBAUTEN_VERWALTUNGSBAUTEN_DIENSTLEISTUNGSBAUTEN__TITLE", "parent": true},
                {"id": "07", "title": "JUSTIZBAUTEN__TITLE", "parent": true},
                {"id": "08", "title": "GESUNDHEITSBAUTEN__TITLE", "parent": true},
                {"id": "09", "title": "KULTUS__TITLE", "parent": true},
                {"id": "10", "title": "KULTUR__TITLE", "parent": true},
                {"id": "11", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE", "parent": true},
                {"id": "12", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TITLE", "parent": true},
                {"id": "13", "title": "VERKEHRSBAUTEN__TITLE", "parent": true},
                {"id": "14", "title": "SCHUTZBAUTEN__TITLE", "parent": true}
            ],
            "01": [
                {"id": "0101", "title": "WOHNBAUTEN__MFH", "parent": false},
                {"id": "0102", "title": "WOHNBAUTEN__TERRASSENHAEUSER", "parent": false},
                {"id": "0103", "title": "WOHNBAUTEN__EFH_REIHEN", "parent": false},
                {"id": "0104", "title": "WOHNBAUTEN__ALTERSWOHNUNGEN", "parent": false},
                {"id": "0105", "title": "WOHNBAUTEN__ALTERSHEIME", "parent": false},
                {"id": "0106", "title": "WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME", "parent": false},
                {"id": "0107", "title": "WOHNBAUTEN__STUDENTENWOHNHAEUSER", "parent": false},
                {"id": "0112", "title": "WOHNBAUTEN__PERSONALHAEUSER", "parent": false}
            ],
            "02": [
                {"id": "0201", "title": "SCHULBAUTEN__KINDERGAERTEN_HORTE", "parent": false},
                {"id": "0202", "title": "SCHULBAUTEN__VOLKSSCHULEN", "parent": false},
                {"id": "0203", "title": "SCHULBAUTEN__BERUFSSCHULEN", "parent": false},
                {"id": "0204", "title": "SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN", "parent": false},
                {"id": "0205", "title": "SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN", "parent": false},
                {"id": "0206", "title": "SCHULBAUTEN__BIBLIOTHEKEN", "parent": false},
                {"id": "0207", "title": "SCHULBAUTEN__SPEZIELLE_FORSCHUNGSBAUTEN", "parent": true}
            ],
            '0207': [
                { 'id': '020701', 'title': 'SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR', 'parent': false },
                { 'id': '020702', 'title': 'SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR', 'parent': false, 'disabled': true }
            ],
            "03": [
                {"id": "0301", "title": "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN", "parent": false},
                {"id": "0302", "title": "LAGERBAUTEN_MEHRSTOECKIGE", "parent": false},
                {"id": "0303", "title": "LAGERBAUTEN__VERTEILZENTRALEN", "parent": false, "disabled": true}
            ],
            "03A": [
                {"id": "0306", "title": "LAGERBAUTEN__INDUSTRIEHALLEN", "parent": false},
                {"id": "0307", "title": "LAGERBAUTEN_PRODUKTIONSBAUTEN", "parent": false}
            ],
            "04": [
                {"id": "0402", "title": "LANDWIRTSCHAFT__SCHEUNEN", "parent": false},
                {"id": "0404", "title": "LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG", "parent": false}
            ],
            "06": [
                {"id": "0601", "title": "BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL", "parent": false},
                {"id": "060201", "title": "BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM", "parent": false},
                // {"id": "0603", "title": "BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE", "parent": true} //Markt und Messe
                {"id": "060301", "title": "BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE", "parent": false}
            ],
            // "0603":[],
            "06A": [
                {"id": "0605", "title": "BUERO_HANDELSBAUTEN__BVB__BUERO", "parent": true},
                {"id": "060602", "title": "BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK", "parent": false},
                {"id": "060601", "title": "BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG", "parent": false},
                {"id": "0607", "title": "BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER", "parent": false},
                {"id": "0608", "title": "LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER", "parent": false}
            ],
            "0605": [
                {"id": "060501", "title": "BUEROBAUTEN__EDELROHBAU", "parent": false},
                {"id": "060502", "title": "BUEROBAUTEN__VOLLAUSBAU", "parent": false}],
            "07": [
                {"id": "0703", "title": "JUSTIZ__STRAFVOLLZUGSANSTALTEN", "parent": false}],
            "08": [
                {"id": "0804", "title": "GESUNDHEITSBAUTEN__KRANKENHAEUSER", "parent": true, "disabled": true},
                {"id": "0805", "title": "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN", "parent": true, "disabled": true},
                {"id": "0806", "title": "GESUNDHEIT__PFLEGEHEIME", "parent": false}
            ],
            "09": [
                //{"id": "0901", "title": "KULTUS__KAPELLE", "parent": false},
                //{"id": "0902", "title": "KULTUS__KIRCHE", "parent": false},
                {"id": "0903", "title": "KULTUS__KIRCHGEMEINDEHAEUSER", "parent": false}
            ],
            "10": [
                {"id": "1001", "title": "KULTUR__AUSSTELLUNGEN", "parent": false},
                {"id": "1002", "title": "KULTUR__MUSEEN", "parent": false},
                {"id": "1004", "title": "KULTUR__KULTUR_GEMEINSCHAFTSZENTREN", "parent": false},
                {"id": "1011", "title": "KULTUR__SAAL", "parent": false}
                //{"id": "1010", "title": "KULTUR__KONGRESS", "parent": false}
            ],
            "11": [
                {"id": "1101", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__RESTAURANT", "parent": false},
                {"id": "1102", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HOTELBAUTEN", "parent": false},
                {"id": "1103", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__KANTINEN", "parent": false},
                {"id": "1105", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HERBERGEN", "parent": false, "disabled": false}
            ],
            //{"id": "1107", "title": "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__SCHIRMHUETTEN", "parent": false}],
            "12": [{"id": "1201", "title": "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TURNHALLEN_SPORTHALLEN_MEHRZWECKHALLEN", "parent": false},
                {"id": "120402", "title": "FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE", "parent": false},
                {"id": "1206", "title": "FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER", "parent": false}
            ],
            "13": [
                {"id": "130101", "title": "VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH", "parent": false},
                {"id": "130103", "title": "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN", "parent": false},
                {"id": "130104", "title": "VERKEHRSBAUTEN__GARAGEN_FREISTEHEND", "parent": false},
                {"id": "1303", "title": "VERKEHRSBAUTEN__WERKHOEFE", "parent": false},
                {"id": "1305", "title": "VERKEHRSBAUTEN__ZOLL", "parent": false}],
            "14": [
                {"id": "1407", "title": "SCHUTZBAUTEN__FEUERWEHRGEBAEUDE", "parent": false}
            ]
        };

        function getAllPreviousIds(id) {
            var ret = [];
            while (!angular.equals(id, '00')) {
                id = getGroupIdById(id);
                ret.push(id);
            }
            return ret;
        }

        function getGroupIdById(id) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (id === obj.id) {
                        return key;
                    }
                }
            }
            return "00";
        }

        function getById(id) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (id == obj.id) {
                        return obj;
                    }
                }
            }
            return {};
        }

        function getByTitle(title) {
            for (var key in data) {
                var groupArr = data[key];
                for (var objKey in groupArr) {
                    var obj = groupArr[objKey];
                    if (title === obj.title) {
                        return obj;
                    }
                }
            }
            return {};
        }

        return {
            load: function (id) {
                return data[id];
            },
            getByTitle: function (title) {
                return getByTitle(title);
            },
            getGroupIdById: function (id) {
                return getGroupIdById(id);
            },
            getAllPreviousGroups: function (groupId) {
                var prevGroups = [];
                var prevIds = getAllPreviousIds(groupId);
                prevIds.forEach(function (prevId) {
                    var prevGroup = getById(prevId);
                    if (!angular.equals({}, prevGroup)) {
                        prevGroups.push(getById(prevId));
                    }
                });
                return prevGroups;
            },
            getPreviousGroup: function (title) {
                var obj = getByTitle(title);
                var groupId = getGroupIdById(obj.id);
                return getById(groupId);
            },
            getTitleOfPrevious: function (title) {
                var obj = getByTitle(title);
                var groupId = getGroupIdById(obj.id);
                return getById(groupId).title;
            }
        };
    }
})();
