(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildModernizationPresetService', RebuildModernizationPresetService);

    RebuildModernizationPresetService.$inject = ['$rootScope', '$timeout','RebuildInterventionGradeModernizationService', 'RbUsageTypeGroupFactory', 'RebuildEstateDataService', 'RebuildComponentSliderService'];

    function RebuildModernizationPresetService($rootScope, $timeout, RebuildInterventionGradeModernizationService, RbUsageTypeGroupFactory, RebuildEstateDataService, RebuildComponentSliderService) {

        function setDefaultValues(realEstate) {
            console.info("setDefaultValues", realEstate);
            realEstate.analysis.components.forEach(function (component) {
                component.totalMeasure = new ComponentMeasures();  // TODO move somewhere else
                component.mainModernizationMeasure = new ComponentMeasures();
                component.mainModernizationMeasure.setInterventionGradeQuantityPlaceholder(100);
            });
        }

        function isInput(input) {
            return input || input === 0;
        }

        function presetYear(component, year) {
            if (year != null) {
                component.yearPlaceholder = '' + year;
                if (!isInput(component.yearUserInput)) {
                    component.year = year;
                }
            }
        }

        function makePretty(value, minValue, minPretty, maxValue, maxPretty) {
            if (minValue !== undefined && value < minValue) return minPretty;
            if (maxValue !== undefined && value > maxValue) return maxPretty;
            return '' + value;
        }

        function areValuesDefined(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType) {
            if (!constructionYear) {
                console.info("RebuildModernizationPresetService: Construction Year is not yet defined");
                return false;
            }
            if (!usage || !usage.type) {
                console.info("RebuildModernizationPresetService: Usage is not yet defined");
                return false;
            }
            if ((component === 'CONSTRUCTION' || component === 'WALLCOVERING') && !facadeType) {
                console.info("RebuildModernizationPresetService: FacadeType is yet not defined");
                return false;
            }
            if (!maintenanceQualityType) {
                console.info("RebuildModernizationPresetService: MaintenanceQualityType is not yet defined");
                return false;
            }
            return true;
        }

        function presetYearOnRebuildYear() {
            _.forOwn(RebuildEstateDataService.get().analysis.components, function(component, key) {
                if (RebuildEstateDataService.get().rebuildYear || RebuildEstateDataService.get().rebuildYear > 0) {
                    presetYear(component, RebuildEstateDataService.get().rebuildYear);
                } else if (RebuildEstateDataService.get().constructionYear || RebuildEstateDataService.get().constructionYear !== 0) {
                    presetYear(component, RebuildEstateDataService.get().constructionYear);
                }
            });
        }

        function setConditionValue(element, data) {
            element.remainingValueBackend = data.remaining_value * 100;
            RebuildComponentSliderService.setRemainingValueInterval(element);
            element.conditionBackend = data.condition_class;
            if (!isInput(element.conditionUserInput)) {
                element.condition = element.conditionBackend;
                return false;
            }
            return true;
        }

        function setLifetimeValue(component, data) {
            var noRealValueChanged = true;
            if (data.remaining_economical_lifetime !== undefined) {

                component.residualEconomicLifetimeBackend = data.remaining_economical_lifetime;
                component.residualEconomicLifetimeMin = data.remaining_economical_lifetime_min;
                component.residualEconomicLifetimeMax = data.remaining_economical_lifetime_max;
                component.residualEconomicLifetimeMinPretty = makePretty(Math.round(component.residualEconomicLifetimeMin), 0, '0', 99, '99+');
                component.residualEconomicLifetimeMaxPretty = makePretty(Math.round(component.residualEconomicLifetimeMax), 0, '0', 99, '99+');
                component.residualEconomicLifetimePlaceholder = makePretty(Math.round(component.residualEconomicLifetimeBackend), 0, '0', 99, '99+');

                if (!isInput(component.residualEconomicLifetimeUserInput)) {
                    component.residualEconomicLifetime = component.residualEconomicLifetimeBackend;
                    noRealValueChanged = false;
                }
            }
            component.residualLifetimeBackend = data.remaining_technical_lifetime;
            component.residualLifetimeMin = data.remaining_technical_lifetime_min;
            component.residualLifetimeMax = data.remaining_technical_lifetime_max;
            component.residualLifetimeMinPretty = makePretty(Math.round(component.residualLifetimeMin), 0, '0', 99, '99+');
            component.residualLifetimeMaxPretty = makePretty(Math.round(component.residualLifetimeMax), 0, '0', 99, '99+');
            component.residualLifetimePlaceholder = makePretty(Math.round(component.residualLifetimeBackend), 0, '0', 99, '99+');

            if (!isInput(component.residualLifetimeUserInput)) {
                component.residualLifetime = component.residualLifetimeBackend;
                noRealValueChanged = false;
            }
            return noRealValueChanged;
        }

        function setMeasure(component, data) {
            component.mainModernizationMeasure.setMeasureBackend(
                data.recommended_measure_class
            );
            return false; // no user input possible currently
        }

        function setInterventionGrade(component, data) {
            component.mainModernizationMeasure.setInterventionGradeSigmaBackend(data.intervention_grade_sigma * 100);
            if (component.rebuildEstateComponent !== 'CONSTRUCTION' && data.intervention_grade > 0.8) {
                data.intervention_grade = 1.0;
            }
            return !component.mainModernizationMeasure.setInterventionGradeBackend(data.intervention_grade * 100);
        }

        function presetCondition(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQuality, fixedEconomicLifetime, fixedResidualLifetime) {
            if (component.year) {
                RebuildInterventionGradeModernizationService.fetchData(
                    component.year,
                    constructionYear,
                    component.rebuildEstateComponent,
                    RbUsageTypeGroupFactory.getRbUsage(usage),
                    facadeType,
                    heatingTypes,
                    'medium', // TODO standard from usage or extra input field?
                    maintenanceQuality,
                    undefined,
                    fixedEconomicLifetime,
                    null,
                    fixedResidualLifetime
                ).then(function (data) {
                    var userInput = false;
                    userInput = userInput || setConditionValue(component, data);
                    userInput = userInput || setLifetimeValue(component, data);
                    userInput = userInput || setMeasure(component, data);
                    return userInput || setInterventionGrade(component, data);
                });
            }
        }

        function presetLifetime(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQuality, fixedEconomicLifetime, fixedConditionClass, fixedResidualLifetime) {
            if (component.year) {
                RebuildInterventionGradeModernizationService.fetchData(
                    component.year,
                    constructionYear,
                    component.rebuildEstateComponent,
                    RbUsageTypeGroupFactory.getRbUsage(usage),
                    facadeType,
                    heatingTypes,
                    'medium', // TODO standard from usage or extra input field?
                    maintenanceQuality,
                    undefined,
                    fixedEconomicLifetime,
                    fixedConditionClass,
                    fixedResidualLifetime
                ).then(function (data) {
                    var userInput = false;
                    userInput = userInput || setLifetimeValue(component, data);
                    userInput = userInput || setMeasure(component, data);
                    return userInput || setInterventionGrade(component, data);
                });
            }
        }

        function presetMeasure(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQuality, fixedEconomicLifetime, fixedConditionClass, fixedResidualLifetime) {
            RebuildInterventionGradeModernizationService.fetchData(
                component.year,
                constructionYear,
                component.rebuildEstateComponent,
                RbUsageTypeGroupFactory.getRbUsage(usage),
                facadeType,
                heatingTypes,
                'medium', // TODO standard from usage or extra input field?
                maintenanceQuality,
                undefined,
                fixedEconomicLifetime,
                fixedConditionClass,
                fixedResidualLifetime
            ).then(function (data) {
                var userInput = false;
                userInput = userInput || setMeasure(component, data);
                return userInput || setInterventionGrade(component, data);
            });
        }

        function presetInterventionGrade(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQuality, fixedEconomicLifetime, fixedConditionClass, fixedResidualLifetime, fixedMeasureClass) {
            RebuildInterventionGradeModernizationService.fetchData(
                component.year,
                constructionYear,
                component.rebuildEstateComponent,
                RbUsageTypeGroupFactory.getRbUsage(usage),
                facadeType,
                heatingTypes,
                'medium', // TODO standard from usage or extra input field?
                maintenanceQuality,
                undefined,
                fixedEconomicLifetime,
                fixedConditionClass,
                fixedResidualLifetime,
                fixedMeasureClass
            ).then(function (data) {
                setInterventionGrade(component, data);
            });
        }

        return {
            setDefaultValues: setDefaultValues,
            presetYear: function(component, year) {
                return presetYear(component, year);
            },
            presetYearOnRebuildYear: function() {
                return presetYearOnRebuildYear();
            },
            presetCondition: function (component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType, isPresetSliderModelValuesDisabled) {
                if (isPresetSliderModelValuesDisabled) return null;
                if (areValuesDefined(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType)) {
                    return presetCondition(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType, component.residualEconomicLifetimeUserInput, component.residualLifetimeUserInput);
                }
                return null;
            },
            presetLifetime: function (component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType) {
                if (areValuesDefined(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType)) {
                    return presetLifetime(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType, component.residualEconomicLifetimeUserInput, component.conditionUserInput, component.residualLifetimeUserInput);
                }
                return null;
            },
            presetMeasure: function (component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType, isPresetSliderModelValuesDisabled) {
                if (isPresetSliderModelValuesDisabled) return null;
                if (areValuesDefined(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType)) {
                    return presetMeasure(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType, component.residualEconomicLifetimeUserInput, component.conditionUserInput, component.residualLifetimeUserInput);
                }
                return null;
            },
            presetInterventionGrade: function (component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType) {
                if (areValuesDefined(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType)) {
                    return presetInterventionGrade(component, constructionYear, usage, facadeType, heatingTypes, maintenanceQualityType, component.residualEconomicLifetimeUserInput, component.conditionUserInput, component.residualLifetimeUserInput, component.mainModernizationMeasure.measureUserInput || component.mainModernizationMeasure.measureClientOverwrite);
                }
                return null;
            }
        };

    }

})();
