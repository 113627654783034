(function () {
    'use strict';

    angular.module('kennwerteApp')
    .component('uploadProgress', {
        bindings: {
            translateDescriptionBaseKey: '@',
            translateBaseKey: '@'
        },
        templateUrl: 'app/components/file_upload/upload_progress/upload-progress.tpl.html',
        controller: UploadProgressController
    });

    UploadProgressController.$inject = ['$scope', '$rootScope', '$interval'];

    function UploadProgressController($scope, $rootScope, $interval) {
        var $ctrl = this;


        $rootScope.$on('uploadFileFinished', function (event,args) {
            if($ctrl.firstEvent) {
                $ctrl.step = 100 / args.length;
                $ctrl.firstEvent = false;
            }

            $ctrl.progress += $ctrl.step;
            $ctrl.buffer += $ctrl.step;
            if ($ctrl.progress >= 100) {
                $ctrl.uploadFinished = true;
                $ctrl.firstEvent = true;
                $ctrl.stopInterval();
            }
        });

        $rootScope.$on('uploadAttachments', function () {
            $ctrl.buffer = 10;
            $ctrl.startInterval();
            $ctrl.uploadStarted = true;
        });

        $rootScope.$on('allAttachmentsUploaded', function () {
            $ctrl.progress = 100;
            $ctrl.stopInterval();
            $ctrl.buffer = 0;
            $ctrl.uploadFinished = true;

        });

        this.$onInit = function () {
            $ctrl.uploadStarted = false;
            $ctrl.progress = 0;
            $ctrl.step = 1;
            $ctrl.length = 0;
            $ctrl.buffer = 0;
            $ctrl.bufferWithProgress = 0;
            $ctrl.uploadStarted = false;
            $ctrl.uploadFinished = false;
            $ctrl.firstEvent = true;
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';

        };

        $ctrl.startInterval = function () {
            // Don't start a new fight if we are already fighting
            if (angular.isDefined($ctrl.interval)) return;

            $ctrl.interval = $interval(function () {
                if ($ctrl.buffer >= 0) {
                    $ctrl.buffer += 1;
                    $ctrl.bufferWithProgress = $ctrl.progress + $ctrl.buffer;
                }
                if ($ctrl.buffer > 25) {
                    $ctrl.buffer = 0;
                    $ctrl.bufferWithProgress = $ctrl.progress + $ctrl.buffer;
                }
            }, 100);
        };

        $ctrl.stopInterval = function () {
            if (angular.isDefined($ctrl.interval)) {
                $interval.cancel($ctrl.interval);
                $ctrl.interval = undefined;
            }
        };
    }
})();
