(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('terms-of-use', {
                parent: 'app',
                url: '/terms-of-use',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/terms_of_use/terms-of-use.html',
                        controller: 'TermsOfUseController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate,$translatePartialLoder) {
                        $translatePartialLoder.addPart('terms-of-use');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            });
    }

})();
