(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildTotalMeasureService', RebuildTotalMeasureService);

    RebuildTotalMeasureService.$inject = ['NormalDistributionService', 'RebuildComponentSliderService'];

    function RebuildTotalMeasureService(NormalDistributionService, RebuildComponentSliderService) {

        // Copied file rebuild_intervention_grade_gap_config.json here.
        var interventionGradeGaps = {"CONSTRUCTION":{"gap":{"from":0.8,"to":1.0}},"FLATROOF":{"gap":{"from":0.4,"to":0.8}},"HEAT_GENERATION":{"gap":{"from":0.4,"to":0.8}},"HEAT_DISTRIBUTION":{"gap":{"from":0.4,"to":0.8}},"VENTILATION_GENERATION":{"gap":{"from":0.25,"to":0.8}},"VENTILATION_DISTRIBUTION":{"gap":{"from":0.25,"to":0.8}},"CLIMA_CHILLER_GENERATION":{"gap":{"from":0.25,"to":0.8}},"CLIMA_CHILLER_DISTRIBUTION":{"gap":{"from":0.25,"to":0.8}},"SANITARY_APPARATUS":{"gap":{"from":0.25,"to":0.8}},"SANITARY_PIPES":{"gap":{"from":0.25,"to":0.8}}};

        function calculateTotalMeasure(component, rebuildEstate) {

            if (component.mainModernizationMeasure.interventionGrade == null) return;
            if (component.totalMeasure == null) {
                component.totalMeasure = new ComponentMeasures();
                // set default values for first time calculation.
                component.totalMeasure.setInterventionGradeQuantityBackend(100);
            }
            var res = new ComponentMeasures();
            // copy values that do not change from old totalMeasure.
            res.name = component.totalMeasure.name;
            res.setMeasureUserInput(component.totalMeasure.measureUserInput);
            res.setInterventionGradeQuantityBackend(component.totalMeasure.interventionGradeQuantityBackend);
            res.setInterventionGradeQuantityUserInput(component.totalMeasure.interventionGradeQuantityUserInput);
            res.setInterventionGradeUserInput(component.totalMeasure.interventionGradeUserInput);
            // set new values based on modification and modernization measures
            var sigmaModernization = component.mainModernizationMeasure.interventionGradeSigmaBackend;
            var nd;
            if (!component.activeInRealEstate && component.active) {
                // component will be new in targetOverhaul => 100% intervention grade
                nd = NormalDistributionService.get(100, 10);
            } else if (component.changing) {
                // component changes from realEstate to targetOverhaul => 100% intervention grade
                nd = NormalDistributionService.get(100, 10);
            } else {
                // component does not change from realEstate to targetOverhaul
                if (component.modificationMeasure != null) {
                    // component is influenced by modification => combine mainModernization and modificaion intervention grade
                    var modificationPart = component.modificationMeasure.interventionGradeQuantity / 100;
                    var mainModernizationPart = component.mainModernizationMeasure.interventionGradeQuantity / 100 - modificationPart;
                    var sigmaModification = component.modificationMeasure.interventionGradeSigmaBackend;
                    nd = NormalDistributionService.add(
                        NormalDistributionService.get(component.mainModernizationMeasure.interventionGrade * mainModernizationPart, sigmaModernization),
                        NormalDistributionService.get(component.modificationMeasure.interventionGrade * modificationPart, sigmaModification)
                    );
                } else {
                    // component is not influenced by modification => take mainModernization intervention grade
                    nd = NormalDistributionService.get(component.mainModernizationMeasure.interventionGrade * component.mainModernizationMeasure.interventionGradeQuantity / 100, sigmaModernization);
                }
            }

            var componentGap = interventionGradeGaps[component.rebuildEstateComponent];
            if (component.rebuildEstateComponent === 'HEAT_GENERATION' && _.includes(rebuildEstate.quality.heatingTypes, 'DISTRICT_HEATING')) {
                componentGap = undefined; // Special case: Fernwärme does not have a gap => Remove gap.
            }
            var cdfInv = (componentGap === undefined)
                ? NormalDistributionService.cdfInverse(nd)
                : NormalDistributionService.invertCustomCdf(
                    NormalDistributionService.cdfCutInterval(nd, componentGap.gap.from * 100, componentGap.gap.to * 100)
                );
            var q = (componentGap === undefined)
                ? RebuildComponentSliderService.lookupQuantilesInterventionGrade(cdfInv)
                : RebuildComponentSliderService.lookupQuantilesInterventionGradeWithGap(cdfInv, componentGap.gap.from * 100, componentGap.gap.to * 100);

            // set measure
            res.setMeasureBackend(
                res.getMeasureValuesBasedOnInterventionGrade(q.total_intervention_grade),
                q.quantiles,
                q.gap
            );

            // set intervention grade
            if (component.totalMeasure.measureUserInput == null || component.totalMeasure.measureUserInput === res.measureBackend) {
                // User has not selected measure => Take suggested intervention grade from normal distribution.
                res.setInterventionGradeBackend(q.total_intervention_grade, nd.sigma);
            } else if (component.totalMeasure.measureUserInput === 'MK5') {
                // User has selected MK5 => Set suggested Intervention grade to 100.
                res.setInterventionGradeBackend(100, nd.sigma);
            } else {
                // User has selected MK1, MK2, MK3 or MK4 => Set suggested intervention grade in range suggested by selected measure by using the normal distribution.
                var igs = res.getIntervalFromMeasure(component.totalMeasure.measureUserInput);
                var cdfWithoutGap = NormalDistributionService.cdf(nd);  // take cdf without gaps to predict intervention grade based on selected measure.
                var cdfInvWithoutGap = NormalDistributionService.cdfInverse(nd); // take cdf inverse without gaps to predict intervention grade based on selected measure.
                var cdfFrom = cdfWithoutGap(igs[0]);
                var cdfTo = cdfWithoutGap(igs[1]);
                if (cdfFrom !== cdfTo) {
                    // set intervention grade to middle point of cdf in interval given by user selected measure.
                    res.setInterventionGradeBackend(cdfInvWithoutGap((cdfFrom + cdfTo) / 2), nd.sigma);
                } else {
                    // cdfFrom and cdfTo are the same (e.g. both 0), which can only be due to floating point precision. In this outlier case simply take the average of the intervention grades for user selected measure.
                    res.setInterventionGradeBackend((igs[0] + igs[1]) / 2, nd.sigma);
                }
            }
            return res;
        }

        return {
            calculateTotalMeasure: calculateTotalMeasure
        };
    }

})();
