(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('SmallBuildingAgvInsuranceCalculatingSuccessController', SmallBuildingAgvInsuranceCalculatingSuccessController);

    SmallBuildingAgvInsuranceCalculatingSuccessController.$inject = ['$scope', '$state', '$location', '$anchorScroll',
        'smoothScroll', '$sessionStorage', 'PdfService', 'XlsxService', '$timeout'];

    function SmallBuildingAgvInsuranceCalculatingSuccessController($scope, $state, $location, $anchorScroll, smoothScroll,
        $sessionStorage, PdfService, XlsxService, $timeout) {

        var vm = this;

        var hasClickedPdfDownload = false;
        var hasClickedExcelDownload = false;

        vm.output = $sessionStorage.output;
        vm.pdfId = $sessionStorage.pdfId;
        vm.pdfIdOfReleaseCandidate = $sessionStorage.pdfIdOfReleaseCandidate;
        vm.bkp0Present = false;

        vm.estimateValueToHigh = Number.parseInt(vm.output.smallBuildingCost.insuranceValue.replace(/'/g, '')) > 100000;

        vm.gotoStateEdit = function() {
            delete $sessionStorage.output;
            //ValidationDataService.resetComplete();
            $state.go('small-building-form.edit', {
                realEstateId: $scope.realEstateContainer.id,
                realEstate: null,
                withdrawalProductType: $scope.realEstateContainer.metaData.withdrawalProductType.toLowerCase()
            });
        };

        vm.downloadPdf = function () {
            if (!hasClickedPdfDownload) {
                hasClickedPdfDownload = true;
                PdfService.downloadPdf($scope.realEstateContainer.id, $scope.realEstateContainer.agv.externalVrNumber);
                hasClickedPdfDownload = false;
            }
        };

        vm.downloadPdfRc = function () {
            PdfService.downloadPdf($scope.realEstateContainer.id, $scope.realEstateContainer.agv.externalVrNumber);
        };

      /* Not Supported for now.
      vm.downloadXlsx = function () {
            if (!hasClickedExcelDownload) {
                hasClickedExcelDownload = true;
                XlsxService.downloadXlsx($scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
                hasClickedExcelDownload = false;
            }
        };*/

        vm.downloadXlsxRc = function () {
            XlsxService.downloadXlsx($scope.realEstateContainer.id, $scope.realEstateContainer.agv.externalVrNumber);
        };

        // vm.dataHasLoaded = true;
    }
})();
