(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('SmallBuildingAgvInsuranceHowtoSectionController', SmallBuildingAgvInsuranceHowtoSectionController);

    SmallBuildingAgvInsuranceHowtoSectionController.$inject = ['$scope', '$stateParams', '$state'];

    function SmallBuildingAgvInsuranceHowtoSectionController($scope, $stateParams, $state) {
        $scope.data = $stateParams.data;

        $scope.isCurrentStateInput = function() {
            return $state.includes('insurance.input');
        };
    }


})();

