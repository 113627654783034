(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .directive("decimalBoxPlotInput", DecimalBoxplotInputDirective);

    DecimalBoxplotInputDirective.$inject = ["$rootScope"];
    function DecimalBoxplotInputDirective($rootScope) {
        return {
            restrict: "E",
            scope: {
                reference: '=', // normal reference
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@?',
                isDisabled: '=',
                min: '@',
                max: '@',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                dynamicTranslations: '@?',
                inputFocusFun: '&',
                actionCallback: '&',
                isPresetSliderModelValuesEnabled: '=?',
                inputBlurFun: '&',
                defaultValue: '<?',
                boxPlotData: '<?'
            },
            templateUrl: "app/components/decimal_box_plot_input/decimal_box_plot_input.tpl.html",
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                scope.model = (scope.reference == null) ? scope.defaultValue : scope.reference;
                scope.translateLabelKey = scope.descriptionBaseKey + '.label';
                if (!angular.isFunction(scope.actionCallback)) {
                    scope.actionCallback = angular.noop;
                }

                scope.hasFocusOutFired = false;
                var inputField = tElement.find('input')[0];

                // Used for the validation
                $(tElement).attr('id', tAttrs.reference + '.formElement');
                $(inputField).attr('id', tAttrs.reference + '.input');
                //used for toast
                scope.toastId = tAttrs.reference +'.toast';

                if (tAttrs.required) {
                    inputField.required = true;
                }

                scope.updateDescriptionAndImage = function() {
                    $rootScope.$broadcast("updateImage", {
                        rowid: scope.rowid,
                        imageSrc: scope.image
                    });
                    $rootScope.$broadcast("updateDescription", {
                        rowid: scope.rowid,
                        descriptionBaseKey: scope.descriptionBaseKey
                    });
                };

                scope.$watch('model', function(newValue) {
                    scope.reference = (newValue === "") ? null : newValue;
                });
                scope.updateReference = function(value) {
                    scope.model = value;
                };
            }
        };
    }
})();
