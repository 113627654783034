(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('InputController', InputController);

    InputController.$inject = ['$scope', '$stateParams', '$state', '$sessionStorage', 'SharedControllerFnService', 'ValidationDataService', 'WithdrawalService', 'Principal', 'BillingModalService', 'LCCIntegrationService'];
    function InputController($scope, $stateParams, $state, $sessionStorage, SharedControllerFnService, ValidationDataService, WithdrawalService, Principal, BillingModalService, LCCIntegrationService) {

        /**
         * As this method manipulates $scope.realEstateContainer it has to live in input-ctrl, success-ctrl doesn't work.
         * Else there would be Ui-Router / AngularJS timeout issues in case form builds too fast.
         */
        $scope.performEstimation = function () {
            console.info("Server side validation successful. Extending the form.");

            delete $sessionStorage.realEstateContainer;
            delete $sessionStorage.output;
            delete $sessionStorage.pdfId;

            var realEstateContainerCopy = angular.copy($scope.realEstateContainer);
            realEstateContainerCopy = SharedControllerFnService.cleanRealEstateContainerUsages(realEstateContainerCopy);
            SharedControllerFnService.cleanDto(realEstateContainerCopy);

            $sessionStorage.realEstateContainer = $scope.realEstateContainer;
            // Logic for the feature "project control"
            if (!$scope.realEstateContainer.metaData.parentRealEstateId) {
                // "withdrawalProductType" should be selectable in state "process-form.not-logged-in"
                WithdrawalService.estimateBuildingMetrics(realEstateContainerCopy,
                    function (response) {
                        $sessionStorage.output = response.data.outputDTO;
                        $sessionStorage.output.realEstateId = response.data.realEstateId;
                        $sessionStorage.pdfId = response.data.pdfId;
                        $sessionStorage.pdfIdOfReleaseCandidate = response.data.pdfIdOfReleaseCandidate;
                        $sessionStorage.realEstateContainer.id = response.data.realEstateId;
                        $scope.realEstateContainer.id = response.data.realEstateId;
                        ValidationDataService.setCalculatingFalse();
                        ValidationDataService.setIsSuccess(true);
                    },
                    function (errorResponse) {
                        console.error(errorResponse);
                        ValidationDataService.setCalculatingFalse();
                        ValidationDataService.setIsSuccess(false);
                    });
            } else {
                WithdrawalService.estimateBuildingMetrics(realEstateContainerCopy,
                    function (response) {
                        $sessionStorage.output = response.data.outputDTO;
                        $sessionStorage.output.realEstateId = response.data.realEstateId;
                        $sessionStorage.pdfId = response.data.pdfId;
                        $sessionStorage.pdfIdOfReleaseCandidate = response.data.pdfIdOfReleaseCandidate;
                        $sessionStorage.realEstateContainer.id = response.data.realEstateId;
                        $scope.realEstateContainer.id = response.data.realEstateId;
                        ValidationDataService.setCalculatingFalse();
                        ValidationDataService.setIsSuccess(true);
                    },
                    function (errorResponse) {
                        console.error(errorResponse);
                        ValidationDataService.setCalculatingFalse();
                        ValidationDataService.setIsSuccess(false);
                    });
            }
        };

        $scope.clickPOM = function() {
            function refreshPOM() {
                Principal.identity(true); // force refresh of rights
                $scope.realEstateContainer.pom.hasPomData = Principal.hasModule("OPERATING_COSTS");
            }
            if (Principal.hasModule('OPERATING_COSTS')) {
                $scope.realEstateContainer.pom.hasPomData = !$scope.realEstateContainer.pom.hasPomData;
            } else {
                BillingModalService.openBillingModal(refreshPOM, ['OPERATING_COSTS']);
            }
        };

        $scope.clickLCC = function() {
            function refreshLCC() {
                Principal.identity(true); // force refresh of rights
                LCCIntegrationService.lccEnabledInBuildingCosts = LCCIntegrationService.hasModulesRequiredForLCCFromBuildingCosts();
                $scope.realEstateContainer.pom.hasPomData = LCCIntegrationService.lccEnabledInBuildingCosts;
            }
            if (LCCIntegrationService.hasModulesRequiredForLCCFromBuildingCosts()) {
                LCCIntegrationService.lccEnabledInBuildingCosts = !LCCIntegrationService.lccEnabledInBuildingCosts;
                $scope.realEstateContainer.pom.hasPomData = LCCIntegrationService.lccEnabledInBuildingCosts;
            } else {
                BillingModalService.openBillingModal(refreshLCC, LCCIntegrationService.modulesRequiredForLCCFromBuildingCosts);
            }
        };

    }

})();
