(function() {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('SmallBuildingAgvCostClassFormController', SmallBuildingAgvCostClassFormController);

    SmallBuildingAgvCostClassFormController.$inject = ['$scope'];

    function SmallBuildingAgvCostClassFormController($scope) {

        $scope.isNotIE = false;
        if (angular.isUndefined(document.documentMode)) {
            $scope.isNotIE = true;
        } else {
            $scope.isNotIE = false;
        }

    }




})();
