(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('SimpleImageModalController', SimpleImageModalController)
        .factory('SimpleImageModalService', SimpleImageModalService);

    SimpleImageModalService.$inject = ['$uibModal'];

    function SimpleImageModalService($uibModal) {
        var service = {
            open: open
        };

        var modalInstance = null;

        var resetModal = function() {
            modalInstance = null;
        }

        function open(idx, imgSrc, imgWidth, imgHeight) {
            if (modalInstance !== null) return;

            modalInstance = $uibModal.open({
                animation: false,
                size: "transparent",
                clickOutsideToClose: true,
                controller: SimpleImageModalController,
                templateUrl: "app/components/image_gallery/simple_image_modal/simple-image-modal.html",
                controllerAs: "vm",
                resolve: {
                    mainTranslatePartialLoader: ["$translate", "$translatePartialLoader", function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart("process_form")
                            .addPart("model_reference");
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    imgSrc: function() {
                      return imgSrc;
                    },
                    imgWidth: function() {
                        return imgWidth;
                    },
                    imgHeight: function() {
                        return imgHeight;
                    },
                    idx: function() {
                        return idx;
                    }
                }
            });
            modalInstance.result.then(resetModal, resetModal);
        }
        return service;
    }

    SimpleImageModalController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$window', 'imgSrc', 'imgWidth', 'imgHeight', 'idx'];

    function SimpleImageModalController($scope, $uibModalInstance, $timeout, $window, imgSrc, imgWidth, imgHeight, idx) {
        var vm = this;
        vm.idx = idx;
        vm.imgSrc = imgSrc;

        vm.ready = false;

        vm.clear = clear;
        vm.updateImageSize = updateImageSize;

        var aspectRatio = $window.innerWidth / $window.innerHeight;

        var heightBorderRatio = 0.065;
        var height = (1-heightBorderRatio)*$window.innerHeight;
        var width = (1-heightBorderRatio/aspectRatio)*$window.innerWidth;

        if (aspectRatio*imgHeight >= imgWidth) {
            vm.style = {
                'width': imgWidth * height / imgHeight + 'px',
                'height': height + 'px'
            };
        } else {
            vm.style = {
                'width': width + 'px',
                'height': imgHeight * width / imgWidth + 'px',
                'margin': '0 auto;'
            };
        }

        $timeout(function() {
            $('.modal-dialog').css("width", vm.style.width);
            $('.modal-dialog').css("height", vm.style.height);
            $('.modal').css("overflow-y", "hidden");
            vm.ready = true;
        });

        function updateImageSize(){
            vm.style = {
                'width': 800 + 'px',
                'height': 800 + 'px',
                // 'margin':'0 auto;'
            };
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

    }


})();
