(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('damage-form', {
                parent: 'app',
                abstract: true,
                url: '/damage-form',
                views: {
                    // Fill 'ui-view="content"' of 'index.html'
                    'content@': {
                        templateUrl: "app/damage_form/damage_form.html",
                        controller: 'DamageFormController',
                        controllerAs: 'vm'
                    },
                    'input@damage-form': {
                        templateUrl: 'app/damage_form/10_input/damage_input.html',
                        controller: 'DamageInputController',
                        controllerAs: 'vm'
                    },
                    'damage_calculating_error@damage-form': {
                        templateUrl: 'app/damage_form/20_output/10_calculating_error/damage_calculating_error.html',
                        controller: 'DamageCalculatingErrorController',
                        controllerAs: 'vm'
                    },
                    'damage_calculating_success@damage-form': {
                        templateUrl: 'app/damage_form/20_output/11_calculating_success/damage_calculating_success.html',
                        controller: 'DamageCalculatingSuccessController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    data: null,
                    withdrawalProductType: null,
                    realEstateId: null,
                    realEstate: null
                },
                //controller: 'DamageFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.damage-costs.input'
                },
                resolve: {
                    resetIsCompleteState: ['ValidationDataService', function (ValidationDataService) {
                        ValidationDataService.resetComplete();
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('damage_form')
                            .addPart('rebuild_form')
                            .addPart('model_rebuild')
                            .addPart('model_damage')
                            .addPart('process_form')
                            .addPart('realestates')
                            .addPart('model_geometry')
                            .addPart('operational-costs_form')
                            .addPart('model_quality')
                            .addPart('components_slider-values');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('damage-form.input', {
                url: '/input/',
                //controller: 'DamageFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.damage-costs.input',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        '10_damage_howto_section',
                        '11_damage_general_form',
                        '20_damage_template_form',
                        '30_damage_geometry_form',
                        '40_damage_state_analysis_form',
                        // '41_damage_target_form',
                        // '42_damage_complexity_form',
                        // '43_damage_quality_form',
                        '70_damage_additions_form',
                        '80_damage_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e){console.warn(e)});
                    }],
                    check: ['$state', 'Principal', function ($state, Principal) {
                        Principal.identity().then(function (e) {
                            if (!!e && !Principal.hasModule('DAMAGE_COSTS')) {
                                $state.go('insufficient-rights.damage-costs');
                            }
                        }).catch(function(e){console.warn(e)});
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('damage_form')
                            .addPart('model_rebuild')
                            .addPart('model_damage')
                            .addPart('process_form')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('operational-costs_form')
                            .addPart('model_quality');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('damage-form.edit-record', {
                url: '/edit-record',
                //controller: 'DamageFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'],
                    pageTitle: 'global.menu.damage-costs.edit',
                    showDivs: [
                        // Group
                        '10_input',
                        // Children
                        '15_damage_howto_edit_record',
                        // '15_damage_template_form',
                        '11_damage_general_form',
                        '20_damage_template_form',
                        '30_damage_geometry_form',
                        '40_damage_state_analysis_form',
                        // '41_damage_target_form',
                        // '42_damage_complexity_form',
                        // '43_damage_quality_form',
                        '70_damage_additions_form',
                        '80_damage_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license');
                                } else {
                                    return res.data.licenseState;
                                }
                            }).catch(function(e){console.warn(e)});
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('damage_form')
                            .addPart('rebuild_form')
                            .addPart('model_rebuild')
                            .addPart('model_damage')
                            .addPart('process_form')
                            .addPart('model_realEstate')
                            .addPart('model_geometry')
                            .addPart('operational-costs_form')
                            .addPart('model_quality');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            });
    }
})();
