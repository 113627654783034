(function () {
    'use strict';

    angular
    .module('kennwerteApp')
    .factory('ContactMeService', ContactMeService);

    ContactMeService.$inject = ['$resource'];

    function ContactMeService($resource) {
        return $resource('api/account/contactme', {}, {});

    }
})();
