(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageStateAnalysisFormController', DamageStateAnalysisFormController);

    DamageStateAnalysisFormController.$inject = ['$scope','RebuildActionService'];

    function DamageStateAnalysisFormController($scope, RebuildActionService) {

        this.$onInit = function () {
            $scope.dictComponentToNum = RebuildActionService.fetchComponentToNum();
        };
    }

})();
