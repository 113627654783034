(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DepositProductDialogController', DepositProductDialogController);

    DepositProductDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'DepositProductService'];

    function DepositProductDialogController($scope, $stateParams, $uibModalInstance, entity, DepositProductService) {

        $scope.depositProduct = entity;
        $scope.priceTypes = ["FIXED", "DYNAMIC_USER_DIFFERENCE"];
        $scope.currencyCodes = ["CHF", "EUR"];

        var onSaveSuccess = function (result) {
            $scope.isSaving = false;
            $uibModalInstance.close(result);
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.depositProduct.id != null) {
                DepositProductService.update($scope.depositProduct, onSaveSuccess, onSaveError);
            } else {
                DepositProductService.save($scope.depositProduct, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();

