(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('CreditTransactionService', CreditTransactionService);

    CreditTransactionService.$inject = ['$http'];

    function CreditTransactionService($http) {
        return {
            getAll: function (requestCreditTransactionDTO, responseFunction, errorFunction) {
                return $http.post('api/credit_transactions', requestCreditTransactionDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            getPageable: function (transactionPageableDTO, responseFunction, errorFunction) {
                return $http.post('api/credit_transactions/pageable', transactionPageableDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            sendCreditNote: function (sendCreditNoteDTO, responseFunction, errorFunction) {
                return $http.post('api/credit_transactions/send_note', sendCreditNoteDTO)
                    .then(function(response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            }
        };
    }

})();
