(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .factory("UsageToEnumResolverService", UsageToEnumResolverService);

    UsageToEnumResolverService.$inject = [];

    /**
     *
     * @constructor
     */
    function UsageToEnumResolverService() {
        var service = {
            resolve: resolve
        };

        function resolve(usages, mapping) {
            try {
                var mapEnumToW = {};
                angular.forEach(usages, function (u) {
                    var r = mapping[u['type']][u['standard']];
                    if (angular.isDefined(r)) {
                        if (r in mapEnumToW) {
                            mapEnumToW[r] += u['percentage'];
                        } else {
                            if (!!u['percentage']) {
                                mapEnumToW[r] = u['percentage'];
                            } else {
                                mapEnumToW[r] = 100;
                            }
                        }
                    }
                });
                return _.maxBy(_.keys(mapEnumToW), function (o) {
                    return mapEnumToW[o];
                });
            } catch (e) {
                console.error("Could not reolve usages", usages, mapping, e);
                return null;
            }
        }

        return service;

    }

})();
