/**
 * FROM https://github.com/NaturalNode/natural
 **/
(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('TrieService', TrieService);

    TrieService.$inject = [];

    /**
     * The basis of the TRIE structure.
     **/
    function Trie(keyExtractionFunction, caseSensitive) {
        this.dictionary = {};
        this.$ = false;

        this.keyExtractionFunction = keyExtractionFunction;

        if(typeof caseSensitive === "undefined") {
            caseSensitive = true;
        }

        this.cs = caseSensitive;
    }

    /**
     * Add a single string to the TRIE, returns true if the word was already in the
     * trieStem.
     **/
    Trie.prototype.addString = function(string) {
        if(this.cs === false) {
            string = string.toLowerCase();
        }

        // If the string has only one letter, mark this as a word.
        if(string.length === 0) {
            var wasWord = this.$;
            this.$ = true;
            return wasWord;
        }

        // Make sure there is a Trie node in our dictionary
        var next = this.dictionary[string[0]];

        if(!next) {
            this.dictionary[string[0]] = new Trie(this.cs);
            next = this.dictionary[string[0]];
        }

        // Continue adding the string
        return next.addString(string.substring(1));
    };

    /**
     * Add a single object to the TRIE, returns true if the string was already in the
     * trieStem.
     **/
    Trie.prototype.addObject = function(string, object) {
        if(this.cs === false) {
            string = string.toLowerCase();
        }

        // If the string has only one letter, mark this as a word.
        if(string.length === 0) {
            var wasWord = this.$;
            if(wasWord){
                this.jsonList.push(object);
            }else{
                this.jsonList = [];
                this.jsonList.push(object);
            }
            this.$ = true;
            return wasWord;
        }

        // Make sure there is a Trie node in our dictionary
        var next = this.dictionary[string[0]];

        if(!next) {
            this.dictionary[string[0]] = new Trie(this.cs);
            next = this.dictionary[string[0]];
        }

        // Continue adding the string
        return next.addObject(string.substring(1), object);
    };

    /**
     * Add multiple strings to the TRIE
     **/
    Trie.prototype.addStrings = function(list) {
        for(var i in list) {
            this.addString(list[i]);
        }
    };

    /**
     * Add multiple objects to the TRIE
     **/
    Trie.prototype.addObjects = function(list) {
        for(var i in list) {
            this.addObject(this.keyExtractionFunction(list[i]), list[i]);
        }
    };

    /**
     * A function to search the TRIE and return an array of
     * Objects stored in jsonList of words which have same prefix <prefix>
     * for example if we had the following words in our database:
     * a, ab, bc, cd, abc, abd
     * and we search the string: a
     * we will get :
     * [a, ab, abc, abd]
     **/
    Trie.prototype.objectsWithPrefix = function(prefix) {
        if(this.cs === false) {
            prefix = prefix.toLowerCase();
        }

        function isEmpty (object) {
            for (var key in object) if (object.hasOwnProperty(key)) return false;
            return true;
        }

        function get (node, word) {
            if(!node) return null;
            if(word.length == 0) return node;
            return get(node.dictionary[word[0]], word.substring(1));
        }

        function recurse ( node, stringAgg, resultsAgg) {
            if (!node) return;

            // Check if this is a word
            if (node.$) {
                resultsAgg.push.apply(resultsAgg, node.jsonList);
            }

            if (isEmpty(node.dictionary)) {
                return ;
            }

            for (var c in node.dictionary) {
                recurse(node.dictionary[c], stringAgg + c, resultsAgg);
            }
        }

        var results = [];
        recurse(get(this, prefix), prefix, results);
        return results;
    };

    /**
     * A function to search the given string and return true if it lands
     * on on a word. Essentially testing if the word exists in the database.
     **/
    Trie.prototype.contains = function(string) {
        if (this.cs === false) {
            string = string.toLowerCase();
        }

        if (string.length === 0) {
            return this.$;
        }

        // Otherwise, we need to continue searching
        var firstLetter = string[0];
        var next = this.dictionary[firstLetter];

        // If we don't have a node, this isn't a word
        if(!next) {
            return false;
        }

        // Otherwise continue the search at the next node
        return next.contains(string.substring(1));
    };

    /**
     * A function to search the TRIE and return an array of words which were encountered along the way.
     * This will only return words with full prefix matches.
     * for example if we had the following words in our database:
     * a, ab, bc, cd, abc
     * and we searched the string: abcd
     * we would get only:
     * [a, ab, abc]
     **/
    Trie.prototype.findMatchesOnPath = function(search) {
        if(this.cs === false) {
            search = search.toLowerCase();
        }

        function recurse(node, search, stringAgg, resultsAgg) {
            // Check if this is a word.
            if(node.$) {
                resultsAgg.push(stringAgg);
            }

            // Check if the have completed the seearch
            if(search.length === 0) {
                return resultsAgg;
            }

            // Otherwise, continue searching
            var next = node.dictionary[search[0]];
            if(!next) {
                return resultsAgg;
            }
            return recurse(next, search.substring(1), stringAgg + search[0], resultsAgg);
        }
        return recurse(this, search, "", []);
    };

    /**
     * Returns the longest match and the remaining part that could not be matched.
     * inspired by [NLTK containers.trieStem.find_prefix](http://nltk.googlecode.com/svn-/trunk/doc/api/nltk.containers.Trie-class.html).
     **/
    Trie.prototype.findPrefix = function(search) {
        if(this.cs === false) {
            search = search.toLowerCase();
        }

        function recurse(node, search, stringAgg, lastWord) {
            // Check if this is a word
            if(node.$) {
                lastWord = stringAgg;
            }

            // Check if we have no more to search
            if(search.length === 0) {
                return [lastWord, search];
            }

            // Continue searching
            var next = node.dictionary[search[0]];
            if(!next) {
                return [lastWord, search];
            }
            return recurse(next, search.substring(1), stringAgg + search[0], lastWord);
        }
        return recurse(this, search, "", null);
    };

    /**
     * Computes the number of actual nodes from this node to the end.
     * Note: This involves traversing the entire structure and may not be
     * good for frequent use.
     **/
    Trie.prototype.getSize = function() {
        var total = 1;
        for(var c in this.dictionary) {
            total += this.dictionary[c].getSize();
        }
        return total;
    };

    function TrieService() {
        return {
            createTrie: function (caseSensitive, keyExtractionFunction) {
                return new Trie(caseSensitive, keyExtractionFunction);
            }
        };
    }

})();
