(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('ContactDtmDialogController', ContactDtmDialogController);

    ContactDtmDialogController.$inject = ['$uibModalInstance', 'modul'];

    function ContactDtmDialogController($uibModalInstance, modul) {
        var vm = this;

        vm.clear = clear;
        vm.modul = modul.toUpperCase();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


    }
})();



