(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('showRoomHeight', ShowRoomHeight)
        .directive('roomHeight', RoomHeight)
        .controller('RoomHeightController', RoomHeightController);

    ShowRoomHeight.$inject = ['RebuildGeometryService'];

    function ShowRoomHeight(RebuildGeometryService) {
        return {
            restrict: 'E',
            scope: false,
            template: '<button type=\'button\' class=\'btn btn-elegant-black\' ' +
                'room-height translate=\'process-form.showRoomHeight\' ' +
                'style=\'margin-bottom: 20px\'>Geschosshöhe und Geschossfläche schätzen </button>'
        };
    }

    function parseToFloat(value) {
        if (value != null) {
            if (value.length > 2 && value.search(',') !== -1) {
                value = value.replace(',', '.');
            }
            value = parseFloat(value);
            return value;
        } else {
            return null;
        }
    }

    function CalcTotalFloorArea416(scope, roomHeight, RebuildGeometryService) {
        roomHeight = parseToFloat(roomHeight);
        var gf;
        if (scope.realEstateContainer.geometry.totalVolume416 && roomHeight > 0) {
            gf = Math.round(scope.realEstateContainer.geometry.totalVolume416 / roomHeight);
            if (gf > 0) {
                scope.realEstateContainer.geometry.totalFloorArea416 = gf;
            }
        }
    }

    function CalcVolume416(scope, roomHeight) {
        roomHeight = parseToFloat(roomHeight);
        if (scope.realEstateContainer.geometry.totalFloorArea416 && roomHeight > 0) {
            var gv = Math.round(scope.realEstateContainer.geometry.totalFloorArea416 * roomHeight);
            if (gv > 0) {
                scope.realEstateContainer.geometry.totalVolume416 = gv;
            }
        }
    }

    function genericCalcVolume416(totalFloorArea416, roomHeight, totalVolume416) {
        roomHeight = parseToFloat(roomHeight);
        if (totalFloorArea416 && roomHeight > 0) {
            var gv = Math.round(totalFloorArea416 * roomHeight);
            if (gv > 0) {
                totalVolume416 = gv;
                return totalVolume416;
            }
        }
    }

    function CalcRoomHeight(scope, area416, volume416) {
        if (area416 > 0 && volume416 > 0) {
            return Math.max(0, Math.round(volume416 * 100 / area416) / 100);
        } else {
            return null;
        }
    }

    RoomHeight.$inject = ['$rootScope', '$timeout', 'RebuildGeometryService'];

    function RoomHeight($rootScope, $timeout, RebuildGeometryService) {
        return {
            scope: false,
            controller: RoomHeightController,
            link: function(scope, element, attrs, ctrl) {

                element.bind('click', function() {
                    // fetch room height
                    scope.realEstateContainer.geometry.roomHeight = 2.9;
                    if (scope.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' || scope.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS') {
                        scope.realEstateContainer.targetOverhaul.roomHeight = 2.9;

                        if (RebuildGeometryService.isAdditionStory) {
                            scope.realEstateContainer.targetAdditionStory.roomHeight = 2.9;
                        }
                        if (RebuildGeometryService.isAnnex) {
                            scope.realEstateContainer.targetAnnex.roomHeight = 2.9;
                        }
                    }
                    // set variable
                    CalcTotalFloorArea416(scope, scope.realEstateContainer.geometry.roomHeight, RebuildGeometryService);
                    $rootScope.$broadcast('changeInvestments', {});

                    $rootScope.$broadcast('clickedRoomHeightEstimation', {});

                    $timeout(function() {
                        scope.$apply();
                        $rootScope.$broadcast('windowSizeChange', {
                            width: $(document).width
                        });
                    }, 100);
                });
            }
        };
    }

    RoomHeightController.$inject = ['$scope', '$rootScope', 'RoomHeightLimitsWarningService', 'RebuildGeometryService'];

    function RoomHeightController($scope, $rootScope, RoomHeightLimitsWarningService, RebuildGeometryService) {
        var vm = this;
        $scope.roomHeightHasFocus = false; //most likely not used anymore 19.05.2021 lfonti

        vm.focusedRoomHeight = function() {
            $scope.roomHeightHasFocus = true; //most likely not used anymore 19.05.2021 lfonti
        };

        vm.blurredRoomHeight = function() {
            $scope.roomHeightHasFocus = false; //most likely not used anymore 19.05.2021 lfonti
        };

        vm.isRoomHeightDisabled = true;

        var clickedRoomHeight = $rootScope.$on('clickedRoomHeightEstimation', function() {
            vm.isRoomHeightDisabled = false;
        });

        var areaChange = $rootScope.$on('AreaChange', function(event, data) {
            $scope.realEstateContainer.geometry.totalFloorArea416 = data.area416;
            $scope.realEstateContainer.geometry.totalVolume416 = data.volume416;
            if ($scope.realEstateContainer.metaData.withdrawalProductType == 'BUILDING_INSURANCE_VALUES') {
                $scope.realEstateContainer.geometry.totalVolume116 = data.volume116;
            }
            if (data.usages.length > 1) {
                var i = 0;
                data.usages.forEach(function(u) {
                    $scope.realEstateContainer.usages[i].percentage = u.percentage;
                    i++;
                });
            }
            RoomHeightLimitsWarningService.showWarningIfOutOfLimit($scope.realEstateContainer);
        });

        $scope.$watch('realEstateContainer.geometry.roomHeight', function(roomHeight, oldVal) {
            if (!vm.isRoomHeightDisabled && roomHeight != oldVal) {
                CalcTotalFloorArea416($scope, roomHeight, RebuildGeometryService);
            }
        });

        $scope.$watch('[realEstateContainer.geometry.totalFloorArea416,realEstateContainer.geometry.totalVolume416]', function(area416, oldValue) {
            if (!$scope.realEstateContainer.geometry.totalFloorArea416 ||
                isNaN(Number($scope.realEstateContainer.geometry.totalFloorArea416)) ||
                !$scope.realEstateContainer.geometry.totalVolume416 ||
                isNaN(Number($scope.realEstateContainer.geometry.totalVolume416))
            ) {
                vm.isRoomHeightDisabled = true;
                $scope.realEstateContainer.geometry.roomHeight = undefined;
            } else {
                var roomHeight = CalcRoomHeight($scope, $scope.realEstateContainer.geometry.totalFloorArea416, $scope.realEstateContainer.geometry.totalVolume416);
                if (roomHeight != null) {
                    $scope.realEstateContainer.geometry.roomHeight = roomHeight;
                }
            }
        });

        vm.genericCalcRoomHeightFromVolume = function(totalFloorArea416, roomHeight, volume416) {
            if (totalFloorArea416 && volume416 > 0) {
                var r = Math.round(volume416 * 100 / totalFloorArea416) / 100;
                if (r > 0) {
                    return r;
                }
            }
            return roomHeight;
        };

        vm.genericCalcRoomHeightFromArea = function(totalFloorArea416, roomHeight, volume416) {
            if (volume416 && totalFloorArea416 > 0) {
                var r = Math.round(volume416 * 100 / totalFloorArea416) / 100;
                if (r > 0) {
                    return r;
                }
            }
            return roomHeight;
        };

        vm.genericCalcTotalFloorArea = function() {
            if ($scope.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' || $scope.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS') {
                var gf;

                var roomHeightOverhaul = parseToFloat($scope.realEstateContainer.targetOverhaul.roomHeight);
                if ($scope.realEstateContainer.targetOverhaul.totalVolume416 && roomHeightOverhaul > 0) {
                    gf = Math.round($scope.realEstateContainer.targetOverhaul.totalVolume416 / roomHeightOverhaul);
                    if (gf > 0) {
                        $scope.realEstateContainer.targetOverhaul.totalFloorArea416 = gf;
                    }
                }

                if (RebuildGeometryService.isAdditionStory) {
                    var roomHeightAdditionStory = parseToFloat($scope.realEstateContainer.targetAdditionStory.roomHeight);
                    if ($scope.realEstateContainer.targetAdditionStory.totalVolume416 && roomHeightAdditionStory > 0) {
                        gf = Math.round($scope.realEstateContainer.targetAdditionStory.totalVolume416 / roomHeightAdditionStory);
                        if (gf > 0) {
                            $scope.realEstateContainer.targetAdditionStory.totalFloorArea416 = gf;
                        }
                    }
                }

                if (RebuildGeometryService.isAnnex) {
                    var roomHeightAnnex = parseToFloat($scope.realEstateContainer.targetAnnex.roomHeight);
                    if ($scope.realEstateContainer.targetAnnex.totalVolume416 && roomHeightAnnex > 0) {
                        gf = Math.round($scope.realEstateContainer.targetAnnex.totalVolume416 / roomHeightAnnex);
                        if (gf > 0) {
                            $scope.realEstateContainer.targetAnnex.totalFloorArea416 = gf;
                        }
                    }
                }
            }
        };

        if ($scope.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' || $scope.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS') {
            $scope.$watch('realEstateContainer.targetOverhaul.totalFloorArea416', function(area416, oldValue) {
                if (!$scope.realEstateContainer.targetOverhaul.totalFloorArea416 || isNaN(Number($scope.realEstateContainer.targetOverhaul.totalFloorArea416))) {
                    vm.isRoomHeightDisabled = true;
                    $scope.realEstateContainer.targetOverhaul.roomHeight = undefined;
                } else if (!$scope.roomHeightHasFocus) {
                    $scope.realEstateContainer.targetOverhaul.roomHeight = vm.genericCalcRoomHeightFromArea(area416, $scope.realEstateContainer.targetOverhaul.roomHeight, $scope.realEstateContainer.targetOverhaul.totalVolume416);
                }
            });
            $scope.$watch('realEstateContainer.targetAdditionStory.totalFloorArea416', function(area416, oldValue) {
                if (!$scope.realEstateContainer.targetAdditionStory.totalFloorArea416 || isNaN(Number($scope.realEstateContainer.targetAdditionStory.totalFloorArea416))) {
                    vm.isRoomHeightDisabled = true;
                    $scope.realEstateContainer.targetAdditionStory.roomHeight = undefined;
                } else if (!$scope.roomHeightHasFocus) {
                    $scope.realEstateContainer.targetAdditionStory.roomHeight = vm.genericCalcRoomHeightFromArea(area416, $scope.realEstateContainer.targetAdditionStory.roomHeight, $scope.realEstateContainer.targetAdditionStory.totalVolume416);
                }
            });
            $scope.$watch('realEstateContainer.targetAnnex.totalFloorArea416', function(area416, oldValue) {
                if (!$scope.realEstateContainer.targetAnnex.totalFloorArea416 || isNaN(Number($scope.realEstateContainer.targetAnnex.totalFloorArea416))) {
                    vm.isRoomHeightDisabled = true;
                    $scope.realEstateContainer.targetAnnex.roomHeight = undefined;
                } else if (!$scope.roomHeightHasFocus) {
                    $scope.realEstateContainer.targetAnnex.roomHeight = vm.genericCalcRoomHeightFromArea(area416, $scope.realEstateContainer.targetAnnex.roomHeight, $scope.realEstateContainer.targetAnnex.totalVolume416);
                }
            });

            $scope.$watch('realEstateContainer.targetOverhaul.totalVolume416', function(volume416, oldValue) {
                if (!$scope.roomHeightHasFocus) {
                    if (!volume416) {
                        vm.isRoomHeightDisabled = true;
                        $scope.realEstateContainer.targetOverhaul.roomHeight = undefined;
                    } else {
                        $scope.realEstateContainer.targetOverhaul.roomHeight = vm.genericCalcRoomHeightFromVolume($scope.realEstateContainer.targetOverhaul.totalFloorArea416, $scope.realEstateContainer.targetOverhaul.roomHeight, volume416);
                    }
                }
            });
            $scope.$watch('realEstateContainer.targetAdditionStory.totalVolume416', function(volume416, oldValue) {
                if (!$scope.roomHeightHasFocus) {
                    if (!volume416) {
                        vm.isRoomHeightDisabled = true;
                        $scope.realEstateContainer.targetAdditionStory.roomHeight = undefined;
                    } else {
                        $scope.realEstateContainer.targetAdditionStory.roomHeight = vm.genericCalcRoomHeightFromVolume($scope.realEstateContainer.targetAdditionStory.totalFloorArea416, $scope.realEstateContainer.targetAdditionStory.roomHeight, volume416);
                    }
                }
            });
            $scope.$watch('realEstateContainer.targetAnnex.totalVolume416', function(volume416, oldValue) {
                if (!$scope.roomHeightHasFocus) {
                    if (!volume416) {
                        vm.isRoomHeightDisabled = true;
                        $scope.realEstateContainer.targetAnnex.roomHeight = undefined;
                    } else {
                        $scope.realEstateContainer.targetAnnex.roomHeight = vm.genericCalcRoomHeightFromVolume($scope.realEstateContainer.targetAnnex.totalFloorArea416, $scope.realEstateContainer.targetAnnex.roomHeight, volume416);
                    }
                }
            });

            $scope.$watch('realEstateContainer.targetOverhaul.roomHeight', function(roomHeight, oldValue) {
                if (!vm.isRoomHeightDisabled && roomHeight != oldValue) {
                    vm.genericCalcTotalFloorArea();
                }
            });
            $scope.$watch('realEstateContainer.targetAdditionStory.roomHeight', function(roomHeight, oldValue) {
                if (!vm.isRoomHeightDisabled && roomHeight != oldValue) {
                    vm.genericCalcTotalFloorArea();
                }
            });
            $scope.$watch('realEstateContainer.targetAnnex.roomHeight', function(roomHeight, oldValue) {
                if (!vm.isRoomHeightDisabled && roomHeight != oldValue) {
                    vm.genericCalcTotalFloorArea();
                }
            });
        }

        this.$onDestroy = function() {
            clickedRoomHeight();
            areaChange();
        };

    }


})();
