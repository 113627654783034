(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService', 'Organisation'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService, Organisation) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.organisationCat = null;
        vm.organisationLoaded = false;
        vm.productType = ['Baukosten & Termine', 'Gebäudeversicherungswerte'];
        vm.user = entity;
        vm.isOrganisation = isOrganisation;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
            vm.id = vm.user.id;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                if(vm.organisationLoaded){
                    if(vm.organisationCat === "Gebäudeversicherungswerte"){
                        vm.organisation.category = "Gebäudeversicherungswerte";
                    }else{
                        vm.organisation.category = "Baukosten & Termine";
                    }

                    Organisation.update({id: vm.organisation.id}, vm.organisation, onSuccess, onError);
                }
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function isOrganisation(){
            if(vm.organisationLoaded){
                return true;
            }
            if(vm.user.login && vm.user.login.startsWith("org ") && vm.user.login.endsWith(" org") && vm.user.activated){
                vm.organisationLoaded = true;
                Organisation.get({id: vm.user.id}, onSuccess, onError);
                return true;
            }else{
                return false;
            }
        }

        function onSuccess(data){
            vm.organisation = data;
            if(vm.organisation.withdrawalProductType === "BUILDING_COSTS_AND_DATES"){
                vm.organisationCat = "Baukosten & Termine";
            }else{
                vm.organisationCat = "Gebäudeversicherungswerte";
            }
        }

        function onError(){

        }
    }
})();
