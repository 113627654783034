(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('AcceptOrganisationController', AcceptOrganisationController);

    AcceptOrganisationController.$inject = ['$state', '$stateParams', '$translate', 'Invite', 'Auth', 'LoginService'];

    function AcceptOrganisationController($state, $stateParams, $translate, Invite, Auth, LoginService) {
        var vm = this;
        vm.error = null;
        vm.acceptkey = $stateParams.key;
        vm.doNotMatch = null;
        vm.joinOrganisation = true;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;
        vm.init = init;
        vm.init();

        function init() {
            Invite.get({id: vm.acceptkey}, onSuccess, onError);
        }

        function onSuccess(data) {
            vm.registerAccount.email = data.email;
            vm.organisation = data.organisation;
        }

        function onError(error) {
            vm.error = 'ERROR';
        }

        function register() {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.langKey = $translate.use();
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.registerAccount.acceptKey = vm.acceptkey;

                Auth.createAccountByOrgInvite(vm.registerAccount,
                    function (response) {
                        vm.success = 'OK';
                    },
                    function (response) {
                        vm.success = null;
                        if (response.status === 400 && response.data === 'login already in use') {
                            vm.errorUserExists = 'ERROR';
                        } else if (response.status === 400 && response.data === 'email address already in use') {
                            vm.errorEmailExists = 'ERROR';
                        } else {
                            vm.error = 'ERROR';
                        }
                    });
            }
        }
    }
})();
