(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('InsuranceCalculatingSuccessController', InsuranceCalculatingSuccessController);

    InsuranceCalculatingSuccessController.$inject = ['$scope', '$state', '$location', '$anchorScroll',
        'smoothScroll', '$sessionStorage', 'PdfService', 'XlsxService'];

    function InsuranceCalculatingSuccessController($scope, $state, $location, $anchorScroll, smoothScroll,
                                                   $sessionStorage, PdfService, XlsxService) {

        var vm = this;

        var hasClickedPdfDownload = false;
        var hasClickedExcelDownload = false;

        vm.output = $sessionStorage.output;
        vm.pdfId = $sessionStorage.pdfId;
        vm.pdfIdOfReleaseCandidate = $sessionStorage.pdfIdOfReleaseCandidate;
        vm.bkp0Present = false;

        vm.gotoStateEdit = function() {
            delete $sessionStorage.output;
            //ValidationDataService.resetComplete();
            $state.go('insurance.edit-record', {
                realEstateId: $scope.realEstateContainer.id,
                withdrawalProductType: $scope.realEstateContainer.metaData.withdrawalProductType.toLowerCase()
            });
        };

        vm.downloadPdf = function () {
            if (!hasClickedPdfDownload) {
                hasClickedPdfDownload = true;
                PdfService.downloadPdf($scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
                hasClickedPdfDownload = false;
            }
        };

        vm.checkForEmptyVals = function (cost) {
            if ($scope.realEstateContainer.metaData.withdrawalProductType == 'BUILDING_COSTS_AND_DATES') {
                vm.checkForBkp0();
            }
            if (cost.key === 'BKP_0_GRUNDSTUECK' || cost.key === 'BKP_3_UTILITIES'
                || cost.key === 'BKP_4_ENVIRONMENT' || cost.key === 'BKP_6_RESERVE'
                || cost.key === 'BKP_7_RESERVE' || cost.key === 'BKP_8_RESERVE') {
                return cost.value.length > 4 && cost.key.length > 1;
            } else {
                return !cost.isAddition || cost.value.length > 4 && cost.key.length > 1;
            }
        };

        vm.checkForBkp0 = function () {
            vm.bkp0Present = _.find(vm.output.costs, function (cost) {
                return cost.key === 'BKP_0_GRUNDSTUECK';
            });
            if (angular.isUndefined(vm.bkp0Present)) {
                vm.bkp0Present = false;
            }
            return vm.bkp0Present;
        };

        vm.downloadPdfRc = function () {
            PdfService.downloadPdf(-$scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
        };

        vm.downloadXlsx = function () {
            if (!hasClickedExcelDownload) {
                hasClickedExcelDownload = true;
                XlsxService.downloadXlsx($scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
                hasClickedExcelDownload = false;
            }
        };

        vm.downloadXlsxRc = function () {
            XlsxService.downloadXlsx($scope.realEstateContainer.id, $scope.realEstateContainer.metaData.name);
        };
    }
})();
