(function () {
    'use strict';

    angular.module('kennwerteApp')
        .service('RoomHeightLimitsWarningService', RoomHeightLimitsWarningService);

    RoomHeightLimitsWarningService.$inject = ['$translate', '$timeout', 'RoomHeightLimitsService'];

    function RoomHeightLimitsWarningService($translate, $timeout, RoomHeightLimitsService) {

        function showWarningIfOutOfLimit(realEstateContainer) {
            // first destroy element
            if (realEstateContainer && realEstateContainer.usages) {
                var element = '#realEstateContainer\\.geometry\\.roomHeight\\.input';
                if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
                    $(element).tooltipster('close');
                }

                var isValid = true;
                var mapping = RoomHeightLimitsService.getMapping();
                var q0 = 0;
                var q100 = 0;
                var weightsSum = 0;
                angular.forEach(realEstateContainer.usages, function (usage) {
                    var container = mapping[usage['type']];
                    if (container) {
                        if (usage['type'] && usage['type'] != null && usage['percentage'] && usage['percentage'] != null) {
                            q0 += Number(usage['percentage']) * container[0];
                            q100 += Number(usage['percentage']) * container[2];
                            weightsSum += Number(usage['percentage']);
                        } else {
                            q0 = container[0] * 100;
                            q100 = container[2] * 100;
                            if (weightsSum != 0) {
                                isValid = false;
                            }
                            weightsSum = 100;
                        }
                    }
                });

                if (isValid && weightsSum > 97 && weightsSum < 103) {

                    var textBase = undefined;

                    if (realEstateContainer.geometry && Number(realEstateContainer.geometry.roomHeight) < q0 / weightsSum) {
                        textBase = 'process-form.roomHeightLimit.low.bp';
                    } else if (realEstateContainer.geometry && Number(realEstateContainer.geometry.roomHeight) > q100 / weightsSum) {
                        textBase = 'process-form.roomHeightLimit.high.bp';
                    }
                    if (textBase) {
                        var textStart = $translate.instant(textBase + '.start');
                        var text0 = $translate.instant(textBase + '.0');
                        var text1 = $translate.instant(textBase + '.1');
                        var text2 = $translate.instant(textBase + '.2');
                        var text =
                            "<div>" +
                            "<label>" + textStart + "</label>" +
                            "<div><span style='display:flex'><label class='clearLabel'>&bull;&nbsp;</label><label class='clearLabel'>" + text0 + "</label></span></div>" +
                            "<div><span style='display:flex'><label class='clearLabel'>&bull;&nbsp;</label><label class='clearLabel'>" + text1 + "</label></span></div>" +
                            "<div><span style='display:flex'><label class='clearLabel'>&bull;&nbsp;</label><label class='clearLabel'>" + text2 + "</label></span></div>" +
                            "</div>";
                        showToast(element, text);
                    }
                }
            }
        }

        /**
         * show toast, similar to usage change
         * @param element
         * @param text
         */
        function showToast(element, text) {
            if ($(element).hasClass("tooltipstered")) {
                var instance = $(element).tooltipster('instance');
                instance.content(text);
                instance.open();
            } else {
                $timeout(function () {
                    $(element).tooltipster({
                        contentAsHTML: true,
                        content: text,
                        theme: 'tooltipster-kw',
                        side: ['right'],
                        zIndex: 1048, // same as validation messages
                        maxWidth: 430,
                        trigger: 'custom',
                        plugins: ['sideTip'],
                        interactive: true,
                        repositionOnScroll: true,
                        functionReady: function (instance, helper) {
                            $(helper.tooltip).click(function () {
                                if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
                                    $(element).tooltipster('close');
                                }
                            });
                            $(helper.origin).click(function () {
                                if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
                                    $(element).tooltipster('close');
                                }
                            });
                        }
                    }).tooltipster('open');
                });
            }
        }

        return {
            showWarningIfOutOfLimit: showWarningIfOutOfLimit
        }
    }


})();
