(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES', 'Principal'];

    function JhiLanguageService($q, $http, $translate, LANGUAGES, Principal) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent
        };

        return service;

        function getAll() {
            var deferred = $q.defer();
            Principal.hasAuthority('ROLE_ADMIN')
                .then(function(result) {
                        var languages = LANGUAGES.slice();
                    if (result) {
                        deferred.resolve(languages);
                    } else {
                        // _.pull(languages, 'fr');
                        deferred.resolve(languages);
                    }
                });
            return deferred.promise;
        }

        function getCurrent () {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
            deferred.resolve(language);
            return deferred.promise;
        }
    }
})();
