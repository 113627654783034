(function () {
    'use strict';
    angular.module('kennwerteApp')
        .factory('RebuildTargetTypeDataService', RebuildTargetTypeDataService);

    RebuildTargetTypeDataService.$inject = [];

    function RebuildTargetTypeDataService() {

        var service = {};

        service.OVERHAUL = 'OVERHAUL';
        service.ANNEX = 'ANNEX';
        service.ADDITION_STORY = 'ADDITION_STORY';

        service.default = [
            service.OVERHAUL, service.ANNEX, service.ADDITION_STORY
        ];

        service.active = [ service.OVERHAUL ]

        service.updateActive = function(isAnnex, isAdditionStory) {
            var res = [ service.OVERHAUL ];
            if (isAnnex) res.push(service.ANNEX);
            if (isAdditionStory) res.push(service.ADDITION_STORY);
            service.active = res;
        }

        return service;
    }

})();
