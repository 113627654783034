(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('rebuildProcessOperationalForm', RebuildProcessOperationalFormDirective);

    RebuildProcessOperationalFormDirective.$inject = ['$rootScope',  '$translate', '$translatePartialLoader'];

    function RebuildProcessOperationalFormDirective($rootScope,    $translate, $translatePartialLoader) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                realEstateContainer: '=',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
            },
            templateUrl: 'app/rebuild_form/30_extension_forms/31_operational_costs/31_rebuild_process_operational_costs.html',
            controller: ProcessOperationalFormController,
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                $translatePartialLoader
                    .addPart('pom')
                    .addPart('model_complexity')
                    .addPart('operational-costs_form');
                $translate.refresh();
            }
        };
    }

    ProcessOperationalFormController.$inject = ['$rootScope',
        '$scope',
        'ValidationDataService',
        '$translate',
        '$translatePartialLoader',
        'ValidationFunctionsService'
    ];

    function ProcessOperationalFormController($rootScope,
                                              $scope,
                                              ValidationDataService,
                                              $translate,
                                              $translatePartialLoader,
                                              ValidationFunctionsService
    ) {

        $scope.validationDataService = ValidationDataService;
        $scope.dataHasLoaded = true;

        $scope.$watch('realEstateContainer.pom.hasPomData', function (newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            //removes duplicate entries in array.
            $scope.realEstateContainer.metaData.tasks = _.uniq($scope.realEstateContainer.metaData.tasks);
            if (newValue != null && newValue) {
                console.info("Add Betriebskostenrechner");
                if (!_.includes($scope.realEstateContainer.metaData.tasks,'OPERATING_COSTS')){
                    $scope.realEstateContainer.metaData.tasks.push('OPERATING_COSTS');
                }
                addValidators();
            } else {
                console.info("Remove Betriebskostenrechner");
                removeValidators();
                var idx = $scope.realEstateContainer.metaData.tasks.indexOf('OPERATING_COSTS');
                if (idx >= 0) {
                    $scope.realEstateContainer.metaData.tasks.splice(idx, 1);
                }
            }
        });

        function addValidators() {
            ValidationDataService.setZindex(1048);
            ValidationDataService.registerValidationFunctions([
                ValidationFunctionsService.validateSecurityLevel,
            ]);
        }

        function removeValidators() {
            ValidationDataService.deregisterValidationFunction(ValidationFunctionsService.validateSecurityLevel);
        }
    }
})();
