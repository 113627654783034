(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('Organisation', Organisation)
        .factory('OrganisationUser', OrganisationUser)
        .factory('OrganisationInvite', OrganisationInvite)
        .factory('Invite', Invite);


    Organisation.$inject = ['$resource'];

    function Organisation($resource) {

        var service = $resource('api/organisation/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'current': {
                method: 'GET',
                url: 'api/organisation/current'
            },
            'getName': {
                method: 'GET',
                url: 'api/organisation/name',
                transformResponse: function(data, headersGetter, status) {
                    return {content: data, status: status};
                }
            },
            'delete': {method: 'DELETE'},
            'adminGet': {
                method: 'GET',
                isArray: false,
                url: 'api/organisation/admin/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'adminUpdate': {
                method: 'PUT',
                url: 'api/organisation/admin/:id'
            },
            'adminUpdateLicense': {
                method: 'PUT',
                url: 'api/organisation/admin/update_license',
                transformResponse: function(data, headersGetter, status) {
                    return {content: data, status: status};
                }
            },
            'adminEnableLicenseRenewal': {
                method: 'POST',
                url: 'api/organisation/admin/enable_license_renewal/:id',
                params: {id: '@id'},
                transformResponse: function(data, headersGetter, status) {
                    return {content: data, status: status};
                }
            },
            'adminDisableLicenseRenewal': {
                method: 'POST',
                url: 'api/organisation/admin/disable_license_renewal/:id',
                params: {id: '@id'},
                transformResponse: function(data, headersGetter, status) {
                    return {content: data, status: status};
                }
            },
            'numOrganisations': {
                method: 'GET',
                url: 'api/organisation/count',
                transformResponse: function(data) {
                    return {cnt: data}
                }
            }
        });

        return service;
    }

    OrganisationUser.$inject = ['$resource'];

    function OrganisationUser($resource) {
        var service = $resource('api/organisation/user/:id', {}, {
            'join_as_user': {method: 'POST'},
            'query_users': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'change_role': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });
        return service;
    }

    OrganisationInvite.$inject = ['$http'];

    function OrganisationInvite($http) {
        return {
            'invite': function (inviteReqDTO, responseFunction, errorFunction) {
                return $http({
                    method: 'GET',
                    url: 'api/organisation/invite',
                    params: {
                        id: inviteReqDTO.id,
                        email: inviteReqDTO.email
                    }
                }).then(function (response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                });
            },
            'query_all': function (inviteReqDTO, responseFunction, errorFunction) {
                return $http({
                    method: 'GET',
                    url: 'api/organisation/invite_query',
                    params: {
                        id: inviteReqDTO.id,
                        email: inviteReqDTO.email
                    }
                }).then(function (response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                });
            },
            'get_invite': function (inviteReqDTO,responseFunction, errorFunction){
                return $http({
                    method: 'GET',
                    url: 'api/organisation/admin/invite',
                    params: {
                        email: inviteReqDTO.email
                    }
                }).then(function (response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                });
            },
            'delete_invite': function (inviteReqDTO, responseFunction, errorFunction) {
                return $http({
                    method: 'DELETE',
                    url: 'api/organisation/invite',
                    data: inviteReqDTO,
                    headers: {
                        "Content-Type": "application/json;charset=utf-8"
                    }
                }).then(function (response) {
                    return responseFunction(response);
                }, function (response) {
                    return errorFunction(response);
                });
            }
        };
        /*
        var service = $http('api/organisation/invite', {}, {
            'invite': {
                method: 'POST'
            },
            'query_all': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'deleteInvite': {
                method: 'DELETE'
            }
        });
        return service;
        */
    }

    Invite.$inject = ['$resource'];

    function Invite($resource) {
        var service = $resource('api/invite/:id', {}, {
            'get': {
                method: 'GET'
            }
        });
        return service;
    }

})();
