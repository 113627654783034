(function () {
    "use strict";

    angular.module('kennwerteApp')
        .directive('additionInput', AdditionInputDirective)
        .controller('additionInputCtrl', AdditionInputController);

    AdditionInputController.$inject = ['$scope', '$rootScope', '$translate'];
    function AdditionInputController($scope, $rootScope, $translate) {
        var vm = this;

        vm.translateKey = function (answer) {
            try {
                return $translate.instant('realestates.' + answer + '.label');
            } catch (e) {
                console.warn('translation error 1 addition input ctr',e);
            }
        };

        vm.translateNr = function (answer) {
            try {
                return $translate.instant('realestates.' + answer + '.number');
            }
            catch (e) {
                console.warn('translation error 2 addition input ctr',e);
            }
        };

        $scope.imageSrc = 'assets/images/calculation/bg/dienstleistungen.svg';
        $scope.tabIndex = $scope.baseTabIndex + Number($scope.index) * 3;
        $scope.tabIndex2 = $scope.tabIndex + 1;
        $scope.tabIndex3 = $scope.tabIndex + 2;

        vm.model = {};
        vm.keys = [
            "noAssignment",
            "BKP_0_GRUNDSTUECK",
            "BKP_1_PREPARATIONS",
            "BKP_20_BUILDING_PIT",
            "BKP_21_SHELL1",
            "BKP_22_SHELL2",
            "BKP_23_ELECTRICAL",
            "BKP_24_HEATING",
            "BKP_25_SANITATION",
            "BKP_26_TRANSPORTATION",
            "BKP_27_CONSTRUCTION1",
            "BKP_28_CONSTRUCTION2",
            "BKP_29_WAGES",
            "BKP_3_UTILITIES",
            "BKP_4_ENVIRONMENT",
            "BKP_5_SIDECOSTS",
            "BKP_6_RESERVE",
            "BKP_7_RESERVE",
            "BKP_8_RESERVE",
            "BKP_9_INTERIOR"
        ];

        var setupTranslations = function() {
            vm.answers = [];
            for (var i in vm.keys) {
                var k = vm.keys[i];
                vm.answers.push({
                    'key': k,
                    'translated': vm.translateNr(k) + ' ' + vm.translateKey(k)
                });
            }
            if ($scope.reference.bkp) {
                vm.model.selected = {
                    'key': $scope.reference.bkp,
                    'translated': vm.translateNr($scope.reference.bkp) + ' ' + vm.translateKey($scope.reference.bkp)
                };
            }
        };
        setupTranslations();

        vm.isEmptyModel = function() {
            return angular.equals({}, vm.model);
        };

        vm.onOpenClose = function(isOpen) {
            if (isOpen) {
                $rootScope.$broadcast('updateDescription', {
                    rowid: $scope.rowid,
                    descriptionBaseKey: $scope.descriptionBaseKey
                });
                $rootScope.$broadcast('updateImage', {
                    rowid: $scope.rowid,
                    imageSrc: $scope.imageSrc
                });
            }
        };

        $scope.$watch('vm.model.selected', function (selectedItem) {
            if (selectedItem !== undefined && !angular.equals({}, selectedItem)) {
                if (selectedItem.key === 'noAssignment') {
                    $scope.reference.bkp = null;
                } else {
                    $scope.reference.bkp = selectedItem.key;
                }
            }
        });

        vm.hasFocusOutFired = false;
        vm.onFocus = function () {
            vm.hasFocusOutFired = false;
            // Push event to CalculationRowController
            if ($scope.reference && $scope.reference.value !== undefined && $scope.reference.value !== "" && $scope.reference.value !== null) {
                var strValueOut = $scope.reference.value.toString();
                strValueOut = strValueOut.replace(/.-/g, '');
                $scope.reference.value = strValueOut;
            }

            var rowid = $scope.rowid;

            $rootScope.$broadcast("updateImage", {
                imageSrc: $scope.imageSrc,
                rowid: rowid
            });
            $rootScope.$broadcast("updateDescription", {
                rowid: $scope.rowid,
                descriptionBaseKey: $scope.descriptionBaseKey,
                dynamicTranslations: false
            });
        };

        vm.onFocusOut = function () {
            if (vm.hasFocusOutFired) {
                return;
            }
            vm.hasFocusOutFired = true;
            if ($scope.reference && $scope.reference.value !== undefined && $scope.reference.value !== "" && $scope.reference.value !== null) {
                $scope.reference.value = $scope.reference.value + ".-";
            }
        };

        var startsWith = function(str, prefix) {
            return str.substr(0, prefix.length) === prefix;
        };

        vm.onKeyUp = function () {
            try {
                if ($scope.reference && $scope.reference.value !== undefined && $scope.reference.value !== "" && $scope.reference.value !== null) {
                    var strValue = $scope.reference.value.toString();
                    var isMinus = startsWith(strValue, '-');
                    strValue = strValue.replace(/\D/g, '');
                    strValue = strValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
                    if (isMinus) {
                        strValue = '-' + strValue;
                    }
                    $scope.reference.value = strValue;
                }
            } catch (err) {
                console.log(err);
            }
        };

        vm.onRemove = function() {
            $scope.referenceArray.splice(Number($scope.index), 1);
        };

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function(_) {
            setupTranslations();
        });
        this.$onDestroy = function() {
            //unregister rootScope event by calling the return function
            translateSuccess();
        };

    }

    AdditionInputDirective.$inject = [];
    function AdditionInputDirective() {
        return {
            restrict: 'E',
            scope: {
                reference: '=reference',
                referenceArray: '=referenceArray',
                rowid: '=',
                translateBaseKey: '@translateBaseKey',
                descriptionBaseKey: '@descriptionBaseKey',
                required: '=required',
                placeholder: '@',
                isDisabled: '=',
                minlength: '@',
                maxlength: '@',
                index: '@',
                numberRestrict: '@',
                baseTabIndex: '=',
                tabGroup: '='
            },
            templateUrl: 'app/components/addition_input/addition-input.tpl.html',
            controller: AdditionInputController,
            controllerAs: 'vm',
            link: {
                pre: function ($scope, tElement, tAttrs, controller, transcludeFn) {
                    $scope.tabIndex = $scope.baseTabIndex + Number($scope.index) * 3;
                },
                post: function ($scope, tElement, tAttrs, controller, transcludeFn) {
                    var inputFieldArr = tElement.find("input");

                    if (tAttrs.required) {
                        inputFieldArr[0].required = true;
                        inputFieldArr[1].required = true;
                    }

                    var input = document.getElementById('inputid');
                }
            }
        };
    }


})();
