(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .directive('addAgvPValueButton', AddAgvPValueButtonDirective)
        .directive('removeAgvPValueButton', RemoveAgvPValueButtonDirective)
        .controller('addAgvPValue', AddAgvPValueCtrl);

    AddAgvPValueButtonDirective.$inject = [];
    function AddAgvPValueButtonDirective() {
        return {
            restrict: "E",
            scope: {
                reference: '='
            },
            controller: AddAgvPValueCtrl,
            controllerAs: 'vm',
            template: "<button type='button' class='btn btn-elegant-black kw-btn-grid' ng-click='vm.onClickAdd()' ng-disabled='vm.isAddDisabled()' data-translate='realEstate.agv.pValue.addPValue'>Click to add addition</button>"
        };
    }

    function RemoveAgvPValueButtonDirective() {
        return {
            restrict: "E",
            scope: {
                reference: '='
            },
            controller: AddAgvPValueCtrl,
            controllerAs: 'vm',
            template: "<button type='button' class='btn btn-elegant-black kw-btn-grid' ng-click='vm.onClickRemove()' ng-disabled='vm.isRemoveDisabled()' data-translate='realEstate.agv.pValue.removePValue'>Click to remove last addition</button>"
        };
    }

    AddAgvPValueCtrl.$inject = ['$scope'];
    function AddAgvPValueCtrl($scope) {
        var vm = this;
        vm.onClickAdd = function () {
            console.log($scope.reference);
            $scope.reference.push({});
        };
        vm.isAddDisabled = function () {
            return $scope.reference && $scope.reference.length >= 15;
        };
        vm.onClickRemove = function() {
            $scope.reference.splice($scope.reference.length - 1, 1);
        };
        vm.isRemoveDisabled = function() {
            return $scope.reference && $scope.reference.length <= 1;
        };
    }

})();
