(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('CalculatingSuccessController', CalculatingSuccessController);

    CalculatingSuccessController.$inject = ['$scope', '$state', '$location', '$anchorScroll',
        'smoothScroll', '$sessionStorage'];

    function CalculatingSuccessController($scope, $state, $location, $anchorScroll, smoothScroll, $sessionStorage) {

        var vm = this;

        vm.output = $sessionStorage.output;
        vm.realEstate = $sessionStorage.realEstateContainer;
        vm.pdfId = $sessionStorage.pdfId;
        vm.pdfIdOfReleaseCandidate = $sessionStorage.pdfIdOfReleaseCandidate;
        vm.bkp0Present = false;


        vm.gotoStateEdit = function() {
            delete $sessionStorage.output;
            //ValidationDataService.resetComplete();
            $state.go("process-form.edit-record", {
                realEstateId: $scope.realEstateContainer.id,
                realEstate: null,
                withdrawalProductType: $scope.realEstateContainer.metaData.withdrawalProductType.toLowerCase()
            });
        };

        vm.checkEstatesForBp = function() {
            if (vm.realEstate.metaData.withdrawalProductType =="BUILDING_COSTS_AND_DATES") {
                var bkp2 = _.find(vm.output.costs, function(cost) {
                    return cost.key === "BKP_2_BUILDING";
                });
                vm.bkp2Value = Number(bkp2.value.replace(/[^0-9]+/g, ""));
                if (vm.bkp2Value >= 8000000) {
                    var isGermanSpeaking = !_.startsWith(vm.realEstate.location.zipCode,1) ||
                        !_.startsWith(vm.realEstate.location.zipCode,2) ||
                        !_.startsWith(vm.realEstate.location.zipCode,65) ||
                        !_.startsWith(vm.realEstate.location.zipCode,66) ||
                        !_.startsWith(vm.realEstate.location.zipCode,67) ||
                        !_.startsWith(vm.realEstate.location.zipCode,68) ||
                        !_.startsWith(vm.realEstate.location.zipCode,69);
                    return isGermanSpeaking;
                }
            }
            return false;
        };
        vm.isBnP = vm.checkEstatesForBp();

    }
})();
