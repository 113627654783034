(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account($resource) {
        var service = $resource('api/account', {}, {
            'get': {
                method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response;
                    }
                }
            },
            'resend_activation': {
                url: 'api/account/resend_activation',
                method: 'POST'
            }
        });

        return service;
    }
})();
