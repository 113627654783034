(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('OperationalCostsGeneralFormController', OperationalCostsGeneralFormController);

    OperationalCostsGeneralFormController.$inject = ['$scope', '$stateParams', '$window', 'KwImageModelService'];

    function OperationalCostsGeneralFormController($scope, $stateParams, $window, KwImageModelService) {
        $scope.dataHasLoaded = false;
        $scope.data = $stateParams.data;
        $scope.currentYear = new Date().getFullYear();

        $(document).ready(function () {
            $scope.dataHasLoaded = true;
        });

        $scope.isSpecialUsage = function() {
            return KwImageModelService.isSpecialUsage($scope.realEstateContainer);
        };

        $scope.isKwImageModelInProgress = function() {
            return KwImageModelService.isEvalImageOnProgress();
        };


    }
})();
