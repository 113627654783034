(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .directive('sankeyPlot', SankeyPlotDirective);

    SankeyPlotDirective.$inject = ['d3Service', 'd3SankeyService'];

    var alignments = {
        left: 'sankeyLeft',
        right: 'sankeyRight',
        center: 'sankeyCenter',
        justify: 'sankeyJustified',
    };

    Object.freeze(alignments);

    function sankey(d3, data, alignment, width, height) {
        var sankey = d3.sankey()
            .nodeId(function (d) {
                return d.name;
            })
            .nodeAlign(d3[alignment])
            .nodeWidth(15)
            .nodePadding(10)
            .extent([[1, 5], [width - 1, height - 5]]);
        return sankey({
            nodes: data.nodes.map(function (d) {
                return Object.assign({}, d);
            }),
            links: data.links.map(function (d) {
                return Object.assign({}, d);
            })
        });
    }

    function calcOrig(node) {
        if (node.sourceLinks.length > 0) return node.sourceLinks[0].orig_value;
        if (node.targetLinks.length > 0 && node.operation === '+') return node.targetLinks.reduce(function(agg, l) { return agg + l.orig_value; }, 0);
        if (node.targetLinks.length === 1) return node.targetLinks[0].orig_value;
        return 0;
    }

    function formatNumber(d3, number) {
        var smallFormat = d3.format(",.2f");
        var bigFormat = d3.format(",.0f");
        var format = (number > 1000) ? bigFormat : smallFormat;
        return format(number);
    }

    var valueSourceColor = {
        "stratus_%": "blue",
        "neubau_model": "orange",
        "rebuild_factors": "green",
        "rebuild_form": "purple"
    };

    function preprocessRawData(d3, rawData) {
        var links = d3.csvParse(rawData.trim()).map(function (l) {
            l.orig_value = parseFloat(l.orig_value);
            return l;
        });
        var linksValueSumBySource = links.reduce(function(rv, x) {
            rv[x.target] = rv[x.target] || 0.0;
            rv[x.target] += x.orig_value;
            return rv;
        }, {});
        var normLinks = links.map(function (l) {
            if (l.operation === '*')
                l.value = 1;
            else if (l.operation === '/')
                l.value = 1;
            else if (l.operation === '=')
                l.value = 1;
            else if (l.operation === '-')
                l.value = Math.max(0.1, l.orig_value / linksValueSumBySource[l.target]);
            else if (l.operation === '+')
                l.value = Math.max(0.1, l.orig_value / linksValueSumBySource[l.target]);
            else if (l.operation === 'f')
                l.value = 1;
            return l;
        });
        var operations = normLinks.reduce(function(map, obj) {
            map[obj.target] = obj.operation;
            return map;
        }, {});
        var valueSources = normLinks.reduce(function(map, obj) {
            map[obj.source] = obj.orig_value_source;
            return map;
        }, {});
        var nodes;
        nodes = Array.from(new Set(normLinks.flatMap(function (l) {
            return [l.source, l.target];
        })), function (name) {
            return {
                name: name,
                category: name.replace(/ .*/, ""),
                operation: operations[name],
                valueSource: valueSources[name]
            };
        });
        return {nodes: nodes, links: normLinks, units: "CHF"};
    }

    function getColor(d) {
        return valueSourceColor[d.valueSource] || 'gray';
    }

    function SankeyPlotDirective(d3Service, d3SankeyService) {
        return {
            restrict: 'EA',
            scope: {
                rows: '=',
            },
            template: '',
            link: function (scope, element, attrs) {

                function draw(d3, data) {

                    var width = 2000;
                    var height = 1200;

                    var svg = d3.select(element[0]).append("svg")
                        .attr("viewBox", [0, 0, width, height]);

                    var obj = sankey(d3, data, alignments.right, width, height);
                    var nodes = obj.nodes;
                    var links = obj.links;

                    var rects = svg.append("g");
                    rects.attr("stroke", "#000")
                        .selectAll("rect")
                        .data(nodes)
                        .join("rect")
                        .attr("x", function (d) {
                            return d.x0;
                        })
                        .attr("y", function (d) {
                            return d.y0;
                        })
                        .attr("height", function (d) {
                            return d.y1 - d.y0;
                        })
                        .attr("width", function (d) {
                            return d.x1 - d.x0;
                        })
                        .attr("fill", function (d) {
                            return getColor(d);
                        })
                        .append("title")
                        .text(function (d) {
                            return d.name + " \n" + formatNumber(d3, calcOrig(d));
                        });

                    var link = svg.append("g")
                        .attr("fill", "none")
                        .attr("stroke-opacity", 0.5)
                        .selectAll("g")
                        .data(links)
                        .join("g")
                        .style("mix-blend-mode", "multiply");

                    link.append("path")
                        .attr("d", d3.sankeyLinkHorizontal())
                        .attr("stroke", function (d) {
                            return getColor(d.source);
                        })
                        .attr("stroke-width", function (d) {
                            return Math.max(1, d.width);
                        });

                    link.append("title")
                        .text(function (d) {
                            return d.source.name + " → " + d.target.name + "\n" + formatNumber(d3, d.orig_value);
                        });

                    svg.append("g")
                        .selectAll("text")
                        .data(nodes)
                        .join("text")
                        .attr("x", function (d) {
                            return d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6;
                        })
                        .attr("y", function (d) {
                            return (d.y1 + d.y0) / 2;
                        })
                        .attr("font-family", "sans-serif")
                        .attr("font-size", 10)
                        .attr("dy", "0.35em")
                        .attr("text-anchor", function (d) {
                            return d.x0 < width / 2 ? "start" : "end";
                        })
                        .text(function (d) {
                            var name = (d.name.startsWith("unnamed")) ? "" : d.name;
                            return name + " (" + formatNumber(d3, calcOrig(d)) + ")";
                        });

                    svg
                        .append("g")
                        .attr("font-family", "sans-serif")
                        .attr("font-size", 10)
                        .selectAll("text")
                        .data(nodes)
                        .join("text")
                        .attr("x", function (d) {
                            return (d.x0 + d.x1) / 2 - 3;
                        })
                        .attr("y", function (d) {
                            return (d.y1 + d.y0) / 2;
                        })
                        .attr("dy", "0.5em")
                        .text(function (d) {
                            return d.operation || "";
                        });

                }

                d3Service.load().then(function (d3) {
                    d3SankeyService.load().then(function (d3) {
                        draw(d3, preprocessRawData(d3, "target,operation,source,orig_value,orig_value_source,\n" + scope.rows.join('\n')));
                    });
                });

                // scope.$watch('data', function(newVal, oldVal) {
                //     rows(newVal !== undefined && newVal !== oldVal && newVal) {
                //         d3Service.load().then(function (d3) {
                //             d3SankeyService.load().then(function (d3) {
                //                 var data = getData(d3);
                //                 draw(d3, data);
                //             });
                //         });
                //     }
                // }, true);


            }
        };
    }

})();
