(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('UpdateLicenseModalController', UpdateLicenseModalController);

    UpdateLicenseModalController.$inject = ['$uibModalInstance', 'numAdditionalUsers', 'currentNumMaxUsers',
        'freeMode', 'licenseState', 'validUntil', '$scope', '$state', 'BillingService', '$location', '$timeout'];

    function UpdateLicenseModalController($uibModalInstance, numAdditionalUsers, currentNumMaxUsers,
                                          freeMode, licenseState, validUntil, $scope, $state, BillingService, $location, $timeout) {
        var vm = this;

        // variables
        vm.errorPaymentFormError = null;
        vm.errorPaymentFormSystemError = null;
        vm.numAdditionalUsers = numAdditionalUsers;
        vm.currentNumMaxUsers = currentNumMaxUsers;
        vm.freeMode = freeMode;
        vm.licenseState = licenseState;
        vm.validUntil = validUntil;
        vm.isAuthorized = null;
        vm.isClicked = false;

        vm.priceForCurrentWindow = null;
        vm.priceForCurrentWindowInclVat = null;
        vm.vat = null;
        vm.effectiveVat = null;
        vm.priceForNextWindow = null;

        // functions
        vm.cancel = cancel;
        vm.buyAdditionalUsers = buyAdditionalUsers;
        vm.authorizeUpdatePayment = authorizeUpdatePayment;

        BillingService.fetchAdditionalPrice(numAdditionalUsers, [], 0).then(
            function (ret) {
                vm.priceForCurrentWindow = ret.data.priceForCurrentWindow;
                vm.priceForCurrentWindowInclVat = ret.data.priceForCurrentWindowInclVat;
                vm.vat = ret.data.vat;
                vm.effectiveVat = ret.data.effectiveVat;
                vm.priceForNextWindow = ret.data.priceForNextWindow;
            });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
            $state.go('billing', {
                paymentMeansSuccess: null,
                paymentFormSuccess: null
            }, {reload: true});
        }

        function authorizeUpdatePayment() {
            buyAdditionalUsers();
        }

        function buyAdditionalUsers() {
            if(vm.isClicked){
                return;
            }
            vm.isClicked = true;

            var n = $location.$$absUrl.lastIndexOf($location.$$url);
            var contextPath = $location.$$absUrl.slice(0, n);
            var dto = {
                successUrl: contextPath + "/saferpay-success",
                failUrl: contextPath + "/saferpay-error",
                abortUrl: contextPath + "/saferpay-abort",
                payerLanguageCode: "DE",
                numAdditionalUsers: vm.numAdditionalUsers,
                currentNumMaxUsers: vm.currentNumMaxUsers
            };
            BillingService.updateLicense(
                dto,
                function(response) {
                    vm.authorized = response.data.authorized;
                    vm.invalidCard = response.data.invalidCard;
                    if (!vm.authorized) {
                        vm.errorPaymentFormError = null;
                    } else {
                        // SUCCESS
                        vm.errorPaymentFormError = null;
                        $timeout(function() {
                            cancel();
                        }, 2000);
                    }
                },
                function(errorResponse) {
                    vm.errorPaymentFormError = 'ERROR';
                    vm.isClicked = false;
                });
        }
    }

})();
