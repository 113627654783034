(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('landing_page_agv', {
                parent: 'app',
                url: '/agv',
                data: {
                    authorities: ["ROLE_ADMIN", "ROLE_USER"]
                },
                params: {
                    anchor: ''
                },
                views: {
                    "content@": {
                        templateUrl: "app/landing_page_agv/landing-page-agv.html",
                        controller: "LandingPageAgvController",
                        controllerAs: "vm"
                    },
                    "small_building_agv@landing_page_agv": {
                        templateUrl: "app/landing_page_agv/small_building_agv/small_building_agv.html"
                    },
                    "about_us@landing_page_agv": {
                        templateUrl: "app/website_fragments/about_us/about_us.html"
                    },
                    "advisory_board@landing_page_agv": {
                        templateUrl: "app/website_fragments/advisory_board/advisory_board.html"

                    }
                },
                resolve: {
                    check: ["$state", "Principal", function($state, Principal) {
                        Principal.identity().then(function(e) {
                            if (!!e && !Principal.hasModule("SMALL_BUILDING_AGV")) {
                                $state.go("insufficient-rights.insurance-values");
                            }
                        });
                    }],
                    mainTranslatePartialLoader: ["$translate", "$translatePartialLoader", function($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart("products")
                            .addPart("register")
                            .addPart("settings");

                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            });
    }

})();
