(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageHowtoSectionController', DamageHowtoSectionController);

    DamageHowtoSectionController.$inject = ['$scope', '$stateParams', '$state'];

    function DamageHowtoSectionController($scope, $stateParams, $state) {
        $scope.data = $stateParams.data;

        $scope.isCurrentStateInput = function() {
            return $state.includes('rebuild-form.input');
        };
    }

})();
