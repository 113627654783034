(function() {
    'use strict';

    angular.module('kennwerteApp')
        .directive('sliderInput', SliderInput);

    SliderInput.$inject = ['$rootScope', '$timeout', 'SliderIdentMappingService', '$translate', 'SliderPresetService'];

    function SliderInput($rootScope, $timeout, SliderIdentMappingService, $translate, SliderPresetService) {

        return {
            restrict: 'E',
            scope: {
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                realEstateContainer: '=',
                reference: '=',
                referencePreset: '=?',
                referenceInterval: '=?',
                referenceStr: '@',
                translateBaseKey: '@translateBaseKey',
                image: '=',
                mandatory: '@?',
                lessValues: '@',
                descriptionBaseKey: '@',
                tabindex: '@',
                tabgroup: '@',
                isLast: '@?',
                turnOnSlider: '=?',
                infotext: '@?'
            },
            templateUrl: 'app/components/slider/slider_input_partial.html',
            controller: function() {
            },
            link: function(scope, tElement, tAttrs, ctrl) {

                if (!_.startsWith(scope.reference, 'WK')) {
                    // set reference in case already set.
                    scope.reference = 'WK' + (scope.reference + 1);
                }

                scope.firstLoad = true;
                scope.sliderPresetMark = null;
                scope.prefix = '';
                scope.values = [];
                var sliderActive = false;

                if (scope.turnOnSlider == undefined) {
                    scope.turnOnSlider = true;
                }

                scope.doShowInterval = false;
                scope.boxPlotData = [{}];
                scope.wkBoxPlotData = [{}];
                scope.whConfig = undefined;

                var inputField = tElement.find('.normalSlider2Input');
                this.$label = inputField.data('ionRangeSlider');

                scope.values = SliderIdentMappingService.getValuesByMore();
                scope.indexFun = angular.noop();
                if (tAttrs.lessValues === 'max') {
                    scope.indexFun = function(value) {
                        return SliderIdentMappingService.getSliderValueIndexByMore(value);
                    };
                    scope.values = SliderIdentMappingService.getValuesByMore();
                } else if (tAttrs.lessValues === 'min') {
                    scope.indexFun = function(value) {
                        return SliderIdentMappingService.getSliderValueIndexByLess(value);
                    };
                    scope.values = SliderIdentMappingService.getValuesByLess();
                }
                if (tAttrs.class === 'subSlider') {
                    var inputElement = tElement.find('.cInputElement');
                    inputElement.addClass(tAttrs.class);
                }

                var pushEvent = function(nominalValue, numericValue) {
                    function mapValueToOrdinal(value) {
                        if (scope.values.length === 3) {
                            // map value 0, 1, 2 -> 1, 3, 5 (which standas for WK2, WK4, WK6)
                            return value * 2 + 1;
                        } else {
                            return value;
                        }
                    }

                    // firstLoad is to make sure that default image and Description are not overwritten.
                    if (!scope.firstLoad && sliderActive) {
                        $rootScope.$broadcast('updateImage', {
                            rowid: scope.rowid,
                            imageSrc: sanitizeUrl(tAttrs.image) + mapValueToOrdinal(numericValue) + '.png'
                        });
                        $rootScope.$broadcast('updateDescription', {
                            rowid: scope.rowid,
                            descriptionNumber: mapValueToOrdinal(numericValue),
                            descriptionBaseKey: tAttrs.descriptionBaseKey,
                            sliderNotPresent: true
                        });
                    } else {
                        scope.firstLoad = false;
                    }
                };

                var isCurrentSliderValue = function(value) {
                    var slider = inputField.data('ionRangeSlider');
                    var val = SliderIdentMappingService.extractValue(value,scope.lessValues === 'max') - 1;
                    // console.info(slider.result.from, val, slider.result.from_value);
                    return slider.result.from === val;
                };

                scope.$watch('reference', function(newValue, oldValue) {
                    // console.warn(newValue,oldValue);
                    var modifiedNewValue = newValue;
                    //we make sure that newValues are WK values. Presets are pushing ordinals so we change them to WK values for direct comparison.
                    if (!_.startsWith(modifiedNewValue, 'WK')) {
                        modifiedNewValue = 'WK' + (newValue + 1);
                    }
                    // console.warn(scope.referenceStr,scope.reference, 'new ' + modifiedNewValue, 'old ' + oldValue, scope.presentationModel, !isCurrentSliderValue(modifiedNewValue));
                    if (modifiedNewValue !== oldValue && !isCurrentSliderValue(modifiedNewValue) && scope.turnOnSlider) {
                        $timeout(function() {
                            setSlider(modifiedNewValue);
                        });
                    }
                    if (newValue == null) {
                        if (scope.referenceStr != null && scope.turnOnSlider) {
                            var ret = SliderPresetService.requestPresetEvent(scope.realEstateContainer, scope.referenceStr);
                            console.warn(ret);
                            addMarks(ret.preset);
                            scope.reference = 'WK' + (ret.preset + 1);
                            addInterval(ret.interval);
                        }
                    }
                }, true);

                function addMarks(value) {
                    function convertToPercent(num, min, max) {
                        return (num - min) / (max - min) * 100;
                    }

                    function toFixed(num) {
                        num = num.toFixed(5);
                        return +num;
                    }

                    if (value) {
                        // FIXME code clean up: This should be outside of slider-input.directive
                        scope.referencePreset = value;
                    }
                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exists
                    var sliderData = inputField.data('ionRangeSlider');
                    if (sliderData) {
                        var min = sliderData.result.min;
                        var max = sliderData.result.max;

                        var real_width = 100 - sliderData.coords.p_handle;
                        var val = toFixed(scope.getIdx('WK' + (value + 1)) / 100 * real_width);
                        var left = convertToPercent(val, min, max);
                        if (left >= 0 && left <= 100) {
                            scope.sliderPresetMark = '<span class="sliderMark" style="left: ' + left + '%"></span>';
                        } else {
                            scope.sliderPresetMark = null;
                        }
                        if (scope.turnOnSlider) {
                            ctrl.update(scope.sliderPresetMark);
                        }
                    }
                }

                var presetMark = $rootScope.$on('setPresetMark', function(event, args) {
                    // set the new image for the image in the corresponding row
                    if (args.referenceStr === scope.referenceStr && scope.turnOnSlider) {
                        // TODO: Why request preset event like below?
                        // TODO: Removed requestPresetEvent because current realEstateContainer might still hold old value
                        //var ret = SliderPresetService.requestPresetEvent(scope.realEstateContainer, scope.referenceStr);
                        addMarks(args.value);
                        addInterval(args.interval);
                    }
                });


                function addInterval(interval) {
                    //helpers
                    function rescale(val, b_min, b_max, a_min, a_max) {
                        // b_min: before min
                        // b_max: before max
                        // a_min: after min
                        // a_max: after max
                        return (a_max - a_min) * (val - b_min) / (b_max - b_min) + a_min;
                    }

                    function convertArr(arr, p_handle) {
                        return arr.map(function(e) {
                            var min = p_handle / 200;
                            var max = 1 - min;
                            return rescale(e / 7, 0, 1, min, max);
                        });
                    }

                    function convertArrToWk(arr, p_handle) {
                        return arr.map(function(e) {
                            var min = p_handle / 200;
                            var max = 1 - min;
                            return rescale(e, min, max, 1, 8);
                        });
                    }

                    //helpers end.

                    // sliderData: slider element
                    // interval: array containing the quartiles mapped to wk numbers [0, 7]
                    // because sliders may not exist yet (they are created with jquery), we have to check if result data already exist
                    if (interval) {
                        // FIXME code clean up: This should be outside of slider-input.directive
                        scope.referenceInterval = Array.prototype.slice.call(interval);
                    }
                    var sliderData = inputField.data('ionRangeSlider');
                    if (sliderData && interval) {
                        scope.whConfig = { width: sliderData.coords.w_rs, height: 10, barWidth: 10 - 2 };
                        var quartileData = interval.slice(2, 5);
                        var whiskerData = [interval[1], interval[5]];
                        var outlierData = [interval[0], interval[6]];
                        scope.boxPlotData[0] = {
                            'key': 0,
                            'quartile': convertArr(quartileData, sliderData.coords.p_handle),
                            'whiskers': convertArr(whiskerData, sliderData.coords.p_handle),
                            'outliers': convertArr(outlierData, sliderData.coords.p_handle),
                            'max': 1
                        };
                        scope.wkBoxPlotData[0] = {
                            'key': 0,
                            'quartile': convertArrToWk(scope.boxPlotData[0]['quartile'], sliderData.coords.p_handle),
                            'whiskers': convertArrToWk(scope.boxPlotData[0]['whiskers'], sliderData.coords.p_handle),
                            'outliers': convertArrToWk(scope.boxPlotData[0]['outliers'], sliderData.coords.p_handle),
                            'max': 1
                        };
                        if (scope.turnOnSlider) {
                            scope.doShowInterval = true;
                        }
                    }
                }

                scope.getIdx = function(value) {
                    return scope.indexFun(value);
                };

                var label;

                function Label(container) {
                    this.$label = $(container).find('.irs-single');
                    this.active = false;
                    this.ACTIVE = 'irs-single--active';
                }

                Label.prototype = {
                    start: function() {
                        if (!this.active) {
                            this.active = true;
                            this.$label.addClass(this.ACTIVE);
                        }
                    },
                    end: function() {
                        this.$label.removeClass(this.ACTIVE);
                        this.active = false;
                    }
                };

                var my_prettify = function(n) {
                    // console.warn(n + ' → ' + scope.prefix + (n + 1));
                    return scope.prefix + (n + 1);
                };

                scope.presentationModel = scope.getIdx(scope.reference);

                var ionRangeSliderOptions = {
                    type: 'single',
                    grid: false,
                    keyboard: true,
                    hide_min_max: true,
                    prettify_enabled: true,
                    prettify_separator: ',',
                    keyboard_step: 100 / scope.values.length + 1, /* In % ! */
                    //from value is the index on values e.g. [1, 3, 5] from: 1 -> 3.
                    from: SliderIdentMappingService.extractValue(scope.reference,scope.lessValues === 'max') - 1,
                    onStart: function(data) {
                        label = new Label(data.slider);
                    },
                    onChange: function(data) {
                        label.start();
                        sliderActive = true;
                        $timeout(function() {
                            //data.from_value is represents ordinal that's why we add +1.
                            scope.reference = 'WK' + (data.from_value + 1);
                        });
                        $timeout(function() {
                            pushEvent(data.from_value, data.from);
                        });
                    },
                    onFinish: function(data) {
                        // Change the model if the slider changes
                        $timeout(function() {
                            //data.from_value is represents ordinal that's why we add +1.
                            scope.reference = 'WK' + (data.from_value + 1);
                        });
                        $timeout(function() {
                            sliderActive = false;
                            label.end();
                        }, 1000);
                    },
                    onUpdate: function(data) {
                        $timeout(function() {
                            pushEvent(data.from_value, data.from);
                        });
                    },
                    prettify: my_prettify,
                    values: scope.values
                };
                inputField.ionRangeSlider(ionRangeSliderOptions);

                /**
                 * this must be below ionRangeSliderOptions.
                 * @param additionalHtml
                 */
                ctrl.update = function(additionalHtml) {
                    var slider = inputField.data('ionRangeSlider');
                    slider.destroy();
                    inputField.ionRangeSlider(ionRangeSliderOptions);
                    if (additionalHtml) {
                        slider = inputField.data('ionRangeSlider');
                        // $slider is a jquery function
                        var $slider = slider.result.slider;
                        $slider.append(additionalHtml);
                    }
                };
                /**
                 * this must be below ionRangeSliderOptions.
                 * @param newValue
                 */
                var setSlider = function(newValue) {

                    var modifiedNewValue = newValue;
                    if (!_.startsWith(modifiedNewValue, 'WK')) {
                        modifiedNewValue = 'WK' + (newValue + 1);
                    }
                    var valIndex = scope.indexFun(modifiedNewValue);
                    console.error(scope.translateBaseKey, valIndex, newValue,modifiedNewValue);

                    if (valIndex > -1) {
                        var slider = inputField.data('ionRangeSlider');
                        ionRangeSliderOptions.from = valIndex;
                        ionRangeSliderOptions.prettify = my_prettify;
                        slider.update({
                            from: valIndex
                        });
                        $timeout(function() {
                            if (scope.sliderPresetMark != null) {
                                ctrl.update(scope.sliderPresetMark);
                            } else {
                                ctrl.update();
                            }
                        });
                    }
                };

                $(document).ready(function() {
                    setLanguageForSlider();
                });

                var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
                    setLanguageForSlider();
                });

                this.$onDestroy = function() {
                    var slider = inputField.data('ionRangeSlider');
                    slider.destroy();
                    translateSuccess();
                    presetMark();
                };

                /**
                 * this must be below ionRangeSliderOptions.
                 */
                function setLanguageForSlider() {
                    $translate('process-form.slider.0.label')
                        .then(function(translation) {
                            scope.prefix = translation.slice(0, 2);
                            var slider = inputField.data('ionRangeSlider');
                            if (!angular.isUndefined(slider)) {
                                if (scope.sliderPresetMark != null) {
                                    ctrl.update(scope.sliderPresetMark);
                                } else {
                                    ctrl.update();
                                }
                            }
                        }, function(error) {
                            console.error(error);
                        }).catch(function(e) {
                        console.warn(e);
                    });
                }

                if (scope.isLast) {
                    $rootScope.$broadcast('requestPresetMark');
                }

                scope.$watch('turnOnSlider', function(newValue) {
                    if (scope.firstLoad) {
                        scope.firstLoad = false;
                        return;
                    }
                    if (scope.turnOnSlider) {
                        console.warn('turn on slider' + scope.translateBaseKey, scope.reference);

                        setSlider(scope.reference);
                        scope.latestBoxPlotData = SliderPresetService.requestPresetEvent(scope.realEstateContainer, scope.referenceStr);
                        $timeout(function() {
                            addMarks(scope.latestBoxPlotData.preset);
                            addInterval(scope.latestBoxPlotData.interval);
                        }, 500);
                    }
                });
            }
        };
    }

})();
