(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('addUsageTypeButton', AddUsageTypeButtonDirective)
        .directive('addUsageType', AddUsageTypeDirective)
        .directive('removeUsageTypeButton', RemoveUsageTypeButtonDirective)
        .directive('removeUsageType', RemoveUsageTypeDirective);

    AddUsageTypeButtonDirective.$inject = [];

    function AddUsageTypeButtonDirective() {
        return {
            restrict: "E",
            scope: {
                rowid : '=',
                realEstateContainer: '=',
                reference : '=',
            },
            template: "<button type='button' id='addUsageTypeButton' class='btn btn-elegant-black kw-btn-grid' add-usage-type ng-disabled='isLastUsageTypeEmpty(reference) || countUsages(reference) > 4' translate='process-form.addUsageType'>Click to add Teil-Nutzung</button>"
        };
    }

    AddUsageTypeDirective.$inject = ['$rootScope', '$timeout', 'SharedControllerFnService'];

    function AddUsageTypeDirective($rootScope, $timeout, SharedControllerFnService) {
        return {
            scope: false,
            link: function (scope, element, attrs) {
                element.bind("click", function () {
                    scope.reference.push({
                        type: null,
                        percentage: null,
                        standard: null
                    });
                    $rootScope.$broadcast("changeUsages", {});

                    $rootScope.$broadcast("updateDescription", {
                        rowid: scope.rowid,
                        descriptionNumber: 1,
                        descriptionBaseKey: 'realEstate.usages.teilNutzung'
                    });
                    // update calculation row
                    $timeout(function () {
                        scope.$apply();
                        $rootScope.$broadcast("windowSizeChange", {
                            width: $(document).width()
                        });
                    }, 100);
                    scope.countUsages = function (usages) {
                        return SharedControllerFnService.countUsages(usages);
                    };
                    scope.isLastUsageTypeEmpty = function (usages) {
                        return SharedControllerFnService.isLastUsageTypeEmpty(usages);
                    };
                    scope.countInvestments = function (usages) {
                        return SharedControllerFnService.countInvestments(usages);
                    };
                    scope.isLastInvestmentEmpty = function (usages) {
                        return SharedControllerFnService.countInvestments(usages);
                    };
                });
            }
        };
    }


    function RemoveUsageTypeButtonDirective() {
        return {
            restrict: "E",
            scope: {
                realEstateContainer: '=',
                reference : '=',
            },
            template: "<button type='button' class='btn btn-elegant-black kw-btn-grid' remove-usage-type ng-disabled='reference.length < 2' translate='process-form.removeUsageType'>Click to remove last Teil-Nutzung</button>"
        };
    }

    RemoveUsageTypeDirective.$inject = ['$rootScope', '$timeout'];

    function RemoveUsageTypeDirective($rootScope, $timeout) {
        return {
            scope: false,
            link: function (scope, element, attrs) {
                element.bind("click", function () {
                    scope.reference.pop();
                    if (scope.reference.length === 1) {
                        scope.reference[0].percentage = null;
                    }

                    $rootScope.$broadcast("changeUsages", {});

                    // update calculation row
                    $timeout(function () {
                        scope.$apply();
                        $rootScope.$broadcast("windowSizeChange", {
                            width: $(document).width()
                        });
                    }, 100);

                });
            }
        };
    }



})();
