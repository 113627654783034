(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('WithdrawalService', WithdrawalService);

    WithdrawalService.$inject = ['$http'];

    function WithdrawalService($http) {
        return {
            estimateBuildingMetrics: function (initWithdrawDTO, responseFunction, errorFunction) {
                return $http.post('api/withdrawal/building_metrics_estimation', initWithdrawDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            checkRealEstate: function (realEstateDTO, responseFunction, errorFunction) {
                return $http.post('api/withdrawal/building_metrics_estimation/check_realestate', realEstateDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            },
            estimateNumElevators: function (realEstateDTO, responseFunction, errorFunction) {
                return $http.post('api/withdrawal/num_elevators', realEstateDTO)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });
            }
        };
    }
})();
