(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$scope', 'Principal'];

    function ContactController($scope, Principal) {
        Principal.identity().then(function(account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
    }

})();
