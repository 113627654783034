(function () {
    "use strict";

    angular.module("kennwerteApp")
        .component("dynamicSingleSelecter", {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                selectables: '=?', // Array which has all selectable options inside.
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                defaultImage: '=?',
                image: '=',
                imageFormat: '=?',
                dynamicImage: '=?',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                classes: '@?',
                infotext: '@?',
                inputBlurFun: '&?'
            },
            templateUrl: "app/components/dynamic_single_selecter/dynamic-single-selecter.tpl.html",
            controller: DynamicSingleSelecterController
        });

    DynamicSingleSelecterController.$inject = ["$scope", "$translate"];

    function DynamicSingleSelecterController($scope, $translate) {
        var $ctrl = this;
        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + ".label";
            // $ctrl.idForInfoField = $ctrl.translateBaseKey.split(".").join("_");

            // Used for the validation
            // $(toggle).attr("id", $attrs.reference + ".drop2down-toggle");
        };
    }
})();
