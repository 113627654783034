(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .factory('AgvSliderIdentMappingService', AgvSliderIdentMappingService);

    AgvSliderIdentMappingService.$inject = [];

    function AgvSliderIdentMappingService() {
        var valuesMore = [0, 1, 2, 3, 4, 5, 6, 7];
        var valuesLess = [1, 3, 5];

        return {
            /**
             * Gets the slider ident (WK1, WK2, et.c) by the system considering "more" values (up to 8 wk)
             * @param value
             * @returns {string}
             */
            getSliderIdentByMore: function (value) {
                return valuesMore[Math.round(value)];
            },
            /**
             * Gets the slider ident (WK2, WK4, WK8) by the system considering "less" values (up to 3 wk)
             * @param value
             * @returns {string}
             */
            getSliderIdentByLess: function (value) {
                return valuesLess[Math.round(value)];
            },
            getSliderValueIndexByMore: function (value) {
                var val = Number(value.slice(-1)) - 1;
                return valuesMore.indexOf(val);
            },
            getSliderValueIndexByLess: function (value) {
                var val = Number(value.slice(-1)) - 1;
                return valuesLess.indexOf(val);
            },
            getValuesByLess: function () {
                return valuesLess;
            },
            getValuesByMore: function () {
                return valuesMore;
            },
            setValuesByLess: function (values) {
                valuesLess = values;

            },
            setValuesByMore: function (values) {
                valuesMore = values;
            },
            /**
             * this returns the value but not the Ordinal position (E.G. WK1 -> 1 and not the ordinal val: 0)
             * @param value WK1-WK8.
             * @returns {number} WK1 -> 1
             */
            extractValue: function(value) {
                return Number(value.slice(-1));
            }
        };
    }

})();
