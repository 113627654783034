(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('RebuildInputController', RebuildInputController);

    RebuildInputController.$inject = ['$scope', '$rootScope', '$timeout', '$stateParams', '$state', '$sessionStorage', 'RebuildModernizationPresetService', 'RebuildModificationPresetService', 'RebuildEstateDataService', 'RebuildEstateService', 'SharedControllerFnService', 'ValidationDataService', 'RebuildTotalMeasureService', 'RebuildTemplateService', 'Principal', 'BillingModalService', 'LCCIntegrationService'];

    function RebuildInputController($scope, $rootScope, $timeout, $stateParams, $state, $sessionStorage, RebuildModernizationPresetService, RebuildModificationPresetService, RebuildEstateDataService, RebuildEstateService, SharedControllerFnService, ValidationDataService, RebuildTotalMeasureService, RebuildTemplateService, Principal, BillingModalService, LCCIntegrationService) {

        $scope.isPresetSliderModelValuesDisabled = $state.includes('rebuild-form.edit-record');
        $scope.refreshCancelToken = null;

        $scope.showDiv = function (divId) {
            return $state.current.data.showDivs.indexOf(divId) > -1;
        };

        $scope.$on('enablePresetSliderModelValues', function () {
            $scope.isPresetSliderModelValuesDisabled = false;
        });

        function getFacadeType() {
            return $scope.realEstateContainer.quality.facadeType;
        }

        function getHeatingTypes() {
            return $scope.realEstateContainer.quality.heatingTypes || [];
        }

        function getMaintenanceQualityType() {
            return $scope.realEstateContainer.maintenanceQualityType;
        }

        function getMainUsageType() {
            return $scope.realEstateContainer.usages[0];
        }

        function getConstructionYear() {
            return $scope.realEstateContainer.constructionYear;
        }

        function setConditionPreset(component) {
            // TODO: wrap in timeout?
            RebuildModernizationPresetService.presetCondition(
                component,
                getConstructionYear(),
                getMainUsageType(),
                getFacadeType(),
                getHeatingTypes(),
                getMaintenanceQualityType(),
                $scope.isPresetSliderModelValuesDisabled);
        }

        function setLifetimePreset(component) {
            // TODO: wrap in timeout?
            RebuildModernizationPresetService.presetLifetime(
                component,
                getConstructionYear(),
                getMainUsageType(),
                getFacadeType(),
                getHeatingTypes(),
                getMaintenanceQualityType());
        }

        function setMeasurePreset(component) {
            // TODO: wrap in timeout?
            RebuildModernizationPresetService.presetMeasure(
                component,
                getConstructionYear(),
                getMainUsageType(),
                getFacadeType(),
                getHeatingTypes(),
                getMaintenanceQualityType(),
                $scope.isPresetSliderModelValuesDisabled);
        }

        function setInterventionGradePreset(component) {
            // TODO: wrap in timeout?
            RebuildModernizationPresetService.presetInterventionGrade(
                component,
                getConstructionYear(),
                getMainUsageType(),
                getFacadeType(),
                getHeatingTypes(),
                getMaintenanceQualityType());
        }

        function ensureValidModificationBalconyType(balconyType) {
            if (['NEW'].includes(balconyType))
                return balconyType;
            else {
                console.info("Use NONE instead of " + balconyType + " as balconyType for calculating modification intervention grade");
                return 'NONE';
            }
        }

        function ensureValidModificationElevatorType(elevatorType) {
            if (['NONE', 'NEW_INSIDE', 'NEW_OUTSIDE'].includes(elevatorType))
                return elevatorType;
            else {
                console.info("Use NONE instead of " + elevatorType + " as elevatorType for calculating modification intervention grade");
                return 'NONE';
            }
        }

        $scope.$watch('realEstateContainer.usages[0].type', function (newUsage, oldUsage) {
            if (hasUsageChanged(newUsage, oldUsage)) {
                $scope.realEstateContainer.analysis.components
                    .forEach(function (c) {
                        setConditionPreset(c);
                    });
            }
        });

        $scope.$watch('realEstateContainer.quality.facadeType', function (newFacade, oldFacade) {
            if (hasFacadeChanged(newFacade, oldFacade)) {
                $scope.realEstateContainer.analysis.components
                    .forEach(function (c) {
                        setConditionPreset(c);
                    });
            }
        });

        $scope.$watch('realEstateContainer.maintenanceQualityType', function(newMq, oldMq) {
            if (hasMaintenanceQualityChanged(newMq, oldMq)) {
                $scope.realEstateContainer.analysis.components
                    .forEach(function(c) {
                        setConditionPreset(c);
                    });
            }
        });

        $scope.$watch('realEstateContainer.quality.heatingTypes', function(newHt, oldHt) {
            if (newHt === undefined) return;
            $scope.realEstateContainer.analysis.components
                .forEach(function(c) {
                    setConditionPreset(c);
                });
        }, true);

        $scope.$watch('realEstateContainer.constructionYear', function(newCY, oldHt) {
            if (newCY === undefined) return;
            $scope.realEstateContainer.analysis.components
                .forEach(function(c) {
                    setConditionPreset(c);
                });
        }, true);

        /*
        START watch on elements
         */
        $scope.deregister = undefined;

        $scope.enablePresetFun = function () {
            if (angular.isUndefined($scope.deregister)) {
                $scope.deregister = $scope.$watch('realEstateContainer.analysis.components', function (components, oldComponents) {
                    if (components !== undefined) {
                        for (var index = 0; index < components.length; index++) {
                            var component = components[index];
                            if (component.parent) continue; // parents get updated by children, not by preset.
                            var noOldValue = oldComponents === undefined;
                            var oldComponent = noOldValue || index >= oldComponents.length ? undefined : oldComponents[index];
                            if (isYearValid(component.year)) {
                                if (noOldValue || hasYearChanged(component.year, oldComponent.year)) {
                                    setConditionPreset(component);
                                    setLifetimePreset(component);
                                }
                                if (noOldValue || hasConditionChanged(component.conditionUserInput, oldComponent.conditionUserInput)) {
                                    if (component.conditionUserInput != null)
                                        component.residualLifetimeUserInput = null; // residualLifetime and condition are coupled
                                    setLifetimePreset(component);
                                    setInterventionGradePreset(component); // Note: intervention grade depends on measure and condition class
                                }
                                if (noOldValue
                                    || hasResidualLifetimeChanged(component.residualLifetimeUserInput, oldComponent.residualLifetimeUserInput)
                                    || hasResidualLifetimeChanged(component.residualEconomicLifetimeUserInput, oldComponent.residualEconomicLifetimeUserInput)) {
                                    if (component.residualLifetimeUserInput != null)
                                        component.conditionUserInput = null; // residualLifetime and condition are coupled
                                    setConditionPreset(component);
                                    setMeasurePreset(component);
                                    setInterventionGradePreset(component);
                                }
                                if (noOldValue || hasMeasureChanged(component.mainModernizationMeasure.measureUserInput, oldComponent.mainModernizationMeasure.measureUserInput)) {
                                    setInterventionGradePreset(component);
                                }
                                component.totalMeasure = RebuildTotalMeasureService.calculateTotalMeasure(component, $scope.realEstateContainer);
                            }
                        }
                    }
                }, true);
            }
            $scope.$watch('[' + [
                'realEstateContainer.targetOverhaul.layoutChangesType',
                'realEstateContainer.structuralInterventionType',
                'realEstateContainer.targetOverhaul.balconyType',
                'realEstateContainer.geometry.elevatorInside.vertical',
                'realEstateContainer.geometry.elevatorInside.placeholder',
                'realEstateContainer.geometry.elevatorOutside.vertical',
                'realEstateContainer.geometry.elevatorOutside.placeholder',
                'realEstateContainer.targetOverhaul.elevatorInside.vertical',
                'realEstateContainer.targetOverhaul.elevatorInside.placeholder',
                'realEstateContainer.targetOverhaul.elevatorOutside.vertical',
                'realEstateContainer.targetOverhaul.elevatorOutside.placeholder'
            ].join(',') + ']', function (newValue, oldValue) {
                if (newValue === oldValue) return;
                if ($scope.refreshCancelToken !== null) {
                    $scope.refreshCancelToken.resolve(); // cancel old refresh request (to avoid race condition)
                }
                var numberOfCurrentTotalElevatorsInside = Number($scope.realEstateContainer.geometry.elevatorInside.vertical || $scope.realEstateContainer.geometry.elevatorInside.placeholder);
                var numberOfNewTotalElevatorsInside = Number($scope.realEstateContainer.targetOverhaul.elevatorInside.vertical || $scope.realEstateContainer.targetOverhaul.elevatorInside.placeholder);
                var numberOfCurrentTotalElevatorsOutside = Number($scope.realEstateContainer.geometry.elevatorOutside.vertical || $scope.realEstateContainer.geometry.elevatorOutside.placeholder);
                var numberOfNewTotalElevatorsOutside = Number($scope.realEstateContainer.targetOverhaul.elevatorOutside.vertical || $scope.realEstateContainer.targetOverhaul.elevatorOutside.placeholder);
                $scope.refreshCancelToken = RebuildModificationPresetService.refresh(
                    $scope.realEstateContainer,
                    $scope.realEstateContainer.targetOverhaul.layoutChangesType,
                    $scope.realEstateContainer.structuralInterventionType,  //TODO why is layoutChangesType on targetOverhaul and structuralInterventionType not?
                    ensureValidModificationBalconyType($scope.realEstateContainer.targetOverhaul.balconyType),
                    ensureValidModificationElevatorType($scope.realEstateContainer.targetOverhaul.elevatorType),
                    numberOfCurrentTotalElevatorsInside,
                    (numberOfNewTotalElevatorsInside - numberOfCurrentTotalElevatorsInside),
                    numberOfCurrentTotalElevatorsOutside,
                    (numberOfNewTotalElevatorsOutside - numberOfCurrentTotalElevatorsOutside)
                ) || $scope.refreshCancelToken;
            }, true);
        };

        $scope.enablePresetFun();

        $scope.disablePresetFun = function () {
            if (angular.isDefined($scope.deregister)) {
                $scope.deregister();
                $scope.deregister = undefined;
            }
        };

        $scope.clickPOM = function() {
            function refreshPOM() {
                Principal.identity(true); // force refresh of rights
                $scope.realEstateContainer.pom.hasPomData = Principal.hasModule("OPERATING_COSTS");
            }
            if (Principal.hasModule('OPERATING_COSTS')) {
                $scope.realEstateContainer.pom.hasPomData = !$scope.realEstateContainer.pom.hasPomData;
            } else {
                BillingModalService.openBillingModal(refreshPOM, ['OPERATING_COSTS']);
            }
        };

        $scope.clickLCC = function() {
            function refreshLCC() {
                Principal.identity(true); // force refresh of rights
                LCCIntegrationService.lccEnabledInRebuildCosts = LCCIntegrationService.hasModulesRequiredForLCCFromRebuildCosts();
                $scope.realEstateContainer.pom.hasPomData = LCCIntegrationService.lccEnabledInRebuildCosts;
            }
            if (LCCIntegrationService.hasModulesRequiredForLCCFromBuildingCosts()) {
                LCCIntegrationService.lccEnabledInRebuildCosts = !LCCIntegrationService.lccEnabledInRebuildCosts;
                $scope.realEstateContainer.pom.hasPomData = LCCIntegrationService.lccEnabledInRebuildCosts;
            } else {
                BillingModalService.openBillingModal(refreshLCC, LCCIntegrationService.modulesRequiredForLCCFromBuildingCosts);
            }
        };

        /**
         * As this method manipulates $scope.realEstateContainer it has to live in input-ctrl, success-ctrl doesn't work.
         * Else there would be Ui-Router / AngularJS timeout issues in case form builds too fast.
         */
        $scope.performEstimation = function () {
            console.info('Server side validation successful. Extending the form.');

            delete $sessionStorage.realEstateContainer;
            delete $sessionStorage.output;
            delete $sessionStorage.pdfId;

            var realEstateContainerCopy = angular.copy($scope.realEstateContainer);
            SharedControllerFnService.cleanRebuildDto(realEstateContainerCopy);
            realEstateContainerCopy = SharedControllerFnService.cleanRealEstateContainerUsages(realEstateContainerCopy);

            if (!RebuildTemplateService.isAnnex(realEstateContainerCopy.selectedTemplates)) {
                // Delete information of targetAnnex if there is no targetAnnex
                realEstateContainerCopy.targetAnnex = null;
            }
            if (!RebuildTemplateService.isAdditionStory(realEstateContainerCopy.selectedTemplates)) {
                // Delete information of targetAdditionStory if there is no targetAdditionStory
                realEstateContainerCopy.targetAdditionStory = null;
            }

            console.info("Start estimating realEstate", realEstateContainerCopy);

            RebuildEstateService.estimate(realEstateContainerCopy).$promise.then(
                function (response) {
                    try {
                        $sessionStorage.output = response.outputDTO;
                        $sessionStorage.rebuildReplacementBuildingInformation = response.rebuildReplacementBuildingInformationDTO;
                        $sessionStorage.output.realEstateId = response.realEstateId;
                        $sessionStorage.pdfId = response.pdfId;
                        $sessionStorage.pdfIdOfReleaseCandidate = response.pdfIdOfReleaseCandidate;
                        RebuildEstateDataService.get().id = response.realEstateId;
                        RebuildEstateDataService.saveToSession();
                        $scope.realEstateContainer.id = response.realEstateId;
                        ValidationDataService.setIsSuccess(true);
                    } catch (e) {
                        console.error(e);
                        ValidationDataService.setIsSuccess(false);
                    } finally {
                        ValidationDataService.setCalculatingFalse();
                    }
                    console.log(response);
                },
                function (errorResponse) {
                    console.error(errorResponse);
                    ValidationDataService.setCalculatingFalse();
                    ValidationDataService.setIsSuccess(false);
                });

            $scope.log = function () {
                console.log($scope.realEstateContainer);
            };

            $scope.loadSession = function () {
                angular.copy($sessionStorage.rebuildEstate, $scope.realEstateContainer);
            };
        };

        function hasFacadeChanged(newValue, oldValue) {
            return !!newValue && newValue !== oldValue;
        }

        function hasMaintenanceQualityChanged(newValue, oldValue) {
            return !!newValue && newValue !== oldValue;
        }

        function hasUsageChanged(newValue, oldValue) {
            return !!newValue && newValue !== oldValue;
        }

        function hasYearChanged(newValue, oldValue) {
            return isInput(newValue) && newValue !== oldValue;
        }

        function isYearValid(newValue) {
            return Number.isInteger(newValue) && newValue > 999 && newValue < 10000;
        }

        function isInput(input) {
            return input !== undefined;
        }

        function hasConditionChanged(newValue, oldValue) {
            return isInput(newValue) && newValue !== oldValue;
        }

        function hasMeasureChanged(newValue, oldValue) {
            return isInput(newValue) && newValue !== oldValue;
        }

        function hasResidualLifetimeChanged(newValue, oldValue) {
            return isInput(newValue) && newValue !== oldValue;
        }

    }

})();
