(function() {
    'use strict';

    angular.module('kennwerteApp')
        .run(GlobalJQuery);

    GlobalJQuery.$inject = [];
    function GlobalJQuery() {
        jQuery(document).ready(function($) {

            // Variable declaration
            var smartphoneSize;

            // Find out if client uses a smartphone or small tablet
            function detectWindowSize() {
                smartphoneSize = $(window).width() <= 845;
            }
            // Detect window size when loading script
            detectWindowSize();

            // Restricts how often a window resize has effects (max 10/sec).
            // Otherwise the detection of a resize can lead to strange reactions.
            // See: http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/ of John Hann
            // See: http://paulirish.com/2009/throttled-smartresize-jquery-event-handler/
            (function($, sr) {
                var debounce = function(func, threshold, execAsap) {
                    var timeout;
                    return function debounced() {
                        var obj = this,
                            args = arguments;
                        function delayed() {
                            if (!execAsap)
                                func.apply(obj, args);
                            timeout = null;
                        }
                        if (timeout)
                            clearTimeout(timeout);
                        else if (execAsap)
                            func.apply(obj, args);
                        timeout = setTimeout(delayed, threshold || 100);
                    };
                };
                // Smart Resize
                jQuery.fn[sr] = function(fn) {
                    return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
                };
            })(jQuery, 'smartresize');

            // Get size of the window on viewport change
            $(window).smartresize(function() {
                detectWindowSize();
            });

            $(document).ready(function() {

                var makeDarker = function() {
                    // Make main navigation darker when scrolling down
                    if ($(window).scrollTop() > 10) {
                        $(".navbar").addClass("makeDarker");
                    } else {
                        $(".navbar").removeClass("makeDarker");
                    }
                };
                $(window).scroll(makeDarker);

            });


            $(".cFormElement .cInfoButton").on("click", function() {
                $(".cFormElement .cAdditionalInfo").toggleClass("cAdditionalInfoClosed");
            });

        });

    }
})();
