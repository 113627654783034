(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('saferpay-success', {
                url: '/saferpay-success'
                /*
                 views: {
                 'content@': {
                 template: 'Success'
                 }
                 }
                 */
            })
            .state('saferpay-error', {
                url: '/saferpay-error'
                /*
                 views: {
                 'content@': {
                 template: 'Error'
                 }
                 }
                 */
            })
            .state('saferpay-abort', {
                url: '/saferpay-abort'
            });
    }
})();

