(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('compactHeatingTypeSelect', {
            bindings: {
                reference: '=?',
                realEstateContainer: '=?',
                referenceStr: '@?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '=',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                validationMessage2: '@?',
                descriptionBaseKey: '@',
                usages: '<',
                classes: '@?',
                infotext: '@?',
                defaultHeatingTypes: '<?',
                groupedHeatingTypes: '<'
            },
            templateUrl: 'app/components/compact_inputs/compact-heating-type-select/compact-heating-type_select.tpl.html',
            controller: CompactHeatingTypeSelectController
        });

    /* @Deprecated */
    CompactHeatingTypeSelectController.$inject = ['WithdrawalService', '$scope', '$rootScope', '$attrs', '$element', '$translate', 'HeatingTypeDataService'];

    function CompactHeatingTypeSelectController(WithdrawalService, $scope, $rootScope, $attrs, $element, $translate, HeatingTypeDataService) {
        var $ctrl = this;
        $ctrl.firstLoad = true;
        $ctrl.model = {};

        $ctrl.heatingTypes = [];
        $ctrl.displayShortString = '';

        $ctrl.translateKey = function(postfix) {
            try {
                return $translate.instant(
                    'realEstate.heatingType.' + postfix + '.label'
                );
            } catch (e) {
                console.warn(e);
                return 'realEstate.heatingType.' + postfix + '.label';
            }
        };

        $ctrl.syncSelectedHeatingTypes = function() {
            // deselect all that are not stored in reference.
            angular.forEach($ctrl.heatingTypes, function(value, key, array) {
                if ($ctrl.reference != null) {
                    value.isSelected = $ctrl.reference.includes(value.value);
                }
            });
        };

        $ctrl.createShortString = function() {
            $ctrl.displayShortString = '';
            _.forEach($ctrl.reference, function(element) {
                try {
                    if ($ctrl.displayShortString.length === 0) {
                        $ctrl.displayShortString = $translate.instant('realEstate.heatingType.' + element + '.short');
                    } else {
                        $ctrl.displayShortString = $ctrl.displayShortString + ', ' + $translate.instant('realEstate.heatingType.' + element + '.short');
                    }
                } catch (e) {
                    console.warn(e);
                }
            });
        };

        $ctrl.flattenHeatingTypes = function(groups) {
            var heatingTypes = [];
            var firstGroup = true;
            for (var j in groups) {
                var group = groups[j];
                var newGroup = true;
                for (var k in group) {
                    var elem = group[k];
                    var isSelected = $ctrl.reference != null ? _.includes($ctrl.reference, elem.value) : false;
                    heatingTypes.push({
                        group: elem.group,
                        value: elem.value,
                        translated: $ctrl.translateKey(elem.value),
                        isSelected: isSelected,
                        newGroup: newGroup,
                        firstGroup: firstGroup
                    });
                    newGroup = false;
                }
                firstGroup = false;
            }
            return heatingTypes;
        };

        $ctrl.onBlur = function() {
        };

        $ctrl.isEmptyModel = function(model) {
            return angular.equals({}, model);
        };

        $ctrl.onOpenClose = function(isOpen, type) {
            if (isOpen) {
                $ctrl.updateDescriptionAndImage(undefined);
            }
        };

        $ctrl.onSelect = function(selectedItem) {
            selectedItem.isSelected = !selectedItem.isSelected;
            if (selectedItem.isSelected) {
                console.info(selectedItem, HeatingTypeDataService, HeatingTypeDataService.EXTRA_GROUP, $ctrl.reference, selectedItem.group === HeatingTypeDataService.EXTRA_GROUP);
                if (selectedItem.group === HeatingTypeDataService.EXTRA_GROUP) {
                    // add extra selectedItem
                    $ctrl.reference.push(selectedItem.value);
                } else {
                    // set selectedItem, but keep extras
                    var selectedExtras = $ctrl.heatingTypes
                        .filter(function(heatingType) {
                            return heatingType.group === HeatingTypeDataService.EXTRA_GROUP && $ctrl.reference != null && $ctrl.reference.includes(heatingType.value);
                        });
                    $ctrl.reference = [selectedItem.value]
                        .concat(selectedExtras.map(function(ht) {
                            return ht.value;
                        }));
                }
                console.info($ctrl.reference);
            } else {
                // remove de-selectedItem
                $ctrl.reference = $ctrl.reference.filter(function(ht) {
                    return ht !== selectedItem.value;
                });
            }
            _.uniq($ctrl.reference);
            $ctrl.syncSelectedHeatingTypes();
            $ctrl.updateDescriptionAndImage(selectedItem);
            $ctrl.createShortString();
        };

        $ctrl.updateDescriptionAndImage = function(selectedItem) {
            if (!$ctrl.firstLoad) {
                if (selectedItem) {
                    $rootScope.$broadcast('updateImage', {
                        rowid: $ctrl.rowid,
                        imageSrc: $ctrl.image
                    });
                    $rootScope.$broadcast('updateDescription', {
                        rowid: $ctrl.rowid,
                        descriptionNumber: selectedItem.id,
                        descriptionBaseKey: $ctrl.descriptionBaseKey + '.' + selectedItem.value
                    });
                } else {
                    $rootScope.$broadcast('updateImage', {
                        rowid: $ctrl.rowid,
                        imageSrc: $ctrl.image
                    });
                    $rootScope.$broadcast('updateDescription', {
                        rowid: $ctrl.rowid,
                        // descriptionNumber: selectedItem.id,
                        descriptionBaseKey: $ctrl.descriptionBaseKey
                    });
                }
            } else {
                $ctrl.firstLoad = false;
            }
        };

        function filterIllegalValues(titles) {
            if (titles == null) return titles;
            var heatingTypeTitles = $ctrl.heatingTypes.map(function(ht) {
                return ht.value;
            });
            return titles.filter(function(title) {
                return heatingTypeTitles.includes(title);
            });
        }

        $scope.$watch('$ctrl.reference', function(newValue, oldValue) {
            if (newValue && angular.isDefined(newValue)) {
                // Remove values if not in heatingTypes.
                $ctrl.reference = filterIllegalValues(newValue);
                $ctrl.syncSelectedHeatingTypes();
                $ctrl.createShortString();
            }
        }, true);

        function updateHeatingTypes(groupedHeatingTypes) {
            $ctrl.heatingTypes = $ctrl.flattenHeatingTypes(groupedHeatingTypes);
            $ctrl.model = $ctrl.heatingTypes;
        }

        $scope.$watch('$ctrl.groupedHeatingTypes', function(newValue, oldValue) {
            updateHeatingTypes(newValue);
            // Remove values if no longer in heatingTypes
            $ctrl.reference = filterIllegalValues($ctrl.reference);
        });

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function(_) {
            for (var i = 0; i < $ctrl.heatingTypes.length; i++) {
                if ($ctrl.heatingTypes[i].value != null) {
                    $ctrl.heatingTypes[i].translated = $ctrl.translateKey($ctrl.heatingTypes[i].value);
                }
            }
            $ctrl.createShortString();
        });
        this.$onDestroy = function() {
            translateSuccess();
        };

        this.$onInit = function() {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');

            updateHeatingTypes($ctrl.groupedHeatingTypes);

            if ($ctrl.reference == null || $ctrl.reference.length === 0) {
                $ctrl.reference = filterIllegalValues($ctrl.defaultHeatingTypes);
            }

            // Used for the validation
            var toggle = $element.find('.compactHeatingTypeDropdown');
            if ($ctrl.referenceStr != null) {
                $(toggle).attr('id', $ctrl.referenceStr + '.drop2down-toggle');
            } else {
                $(toggle).attr('id', $attrs.reference + '.drop2down-toggle');
            }
        };
    }
})();
