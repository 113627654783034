(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('usageTypeSelect', UsageTypeSelectDirective)
        .directive('uiSelectFocuser', UiSelectFocuser);

    UsageTypeSelectController.$inject = ['$scope', '$state', 'usageTypeGroupFactory', '$timeout', '$rootScope', '$translate'];

    function UsageTypeSelectController($scope, $state, usageTypeGroupFactory, $timeout, $rootScope, $translate) {
        var vm = this;

        vm.translateKey = function (postfix) {
            try {
                return $translate.instant('realEstate.usages.' + postfix);
            } catch (e) {
                console.warn('translation error usageTypeSelect ctr', e);
            }
        };

        vm.showDisabledToast = showDisabledToast;

        vm.updateDescriptionAndImage = function (number) {
            var tempId;
            if (number) {
                tempId = number.id;
            } else {
                tempId = vm.model.id;
            }
            $rootScope.$broadcast("updateImage", {
                rowid: $scope.rowid,
                imageSrc: $scope.image
            });

            var key = $scope.descriptionBaseKey;
            var concatenatedGroupIds = "";
            var groupId = tempId;
            do {
                var prevGroupId = usageTypeGroupFactory.getGroupIdById(Number(groupId));
                prevGroupId = Number(prevGroupId);
                if (prevGroupId !== 0) {
                    if (concatenatedGroupIds !== "") {
                        concatenatedGroupIds = prevGroupId + "." + concatenatedGroupIds;
                    } else {
                        concatenatedGroupIds = prevGroupId;
                    }
                }
                groupId = prevGroupId;
            } while (groupId !== 0);
            var k = key;
            if (concatenatedGroupIds !== "") {
                k = key + "." + concatenatedGroupIds;
            }

            $rootScope.$broadcast("updateDescription", {
                rowid: $scope.rowid,
                descriptionNumber: tempId,
                dynamicTranslations: true,
                descriptionBaseKey: k
            });
        };
        vm.saveSelection = function (selectedItem) {
            if (!selectedItem.parent) {
                $scope.reference = selectedItem;
                vm.model = selectedItem;
                if ($scope.isMainUsage && $scope.usagesLength == 1) {
                    $scope.referenceArray.push({
                        type: null,
                        percentage: null,
                        standard: null
                    });
                }
            } else {
                $scope.reference = null;
                vm.model = {
                    "id": "00",
                    "title": "All",
                    "parent": true
                };
            }
        };

        vm.onSelect = function (selectedItem, $model) {
            vm.updateDescriptionAndImage(selectedItem);
            if (selectedItem.disabled) {
                showDisabledToast(selectedItem.translated);
                vm.model = usageTypeGroupFactory.getPreviousGroup(selectedItem.title);
                $scope.reference = null;
            } else {
                if (!selectedItem.parent) {
                    $scope.reference = selectedItem.title;
                    vm.validForPresetsMark();
                    $scope.$broadcast('uiSelectClose');
                    if ($scope.isMainUsage && $scope.usagesLength == 1) {
                        $scope.referenceArray.push({
                            type: null,
                            percentage: null,
                            standard: null
                        });
                    }
                } else {
                    // if (selectedItem.id.length < 3) {
                    //     $scope.reference = null;
                    // }
                    // $scope.reference = "";
                    vm.saveSelection(selectedItem);
                    $scope.loadChildGroupsOf(selectedItem);

                    var element = '#usageToast';
                    if ($(element).hasClass('tooltipstered')) {
                        $(element).tooltipster('destroy');
                    }
                }
            }
        };

        vm.onOpenClose = function (isOpen) {
            if (isOpen) {
                vm.updateDescriptionAndImage();
            }
        };

        function isCurrentStateEditRecord() {
            return $state.includes('*.edit-record');
            //return $state.current.name === 'process-form.edit-record';
        }

        function showToast(element, text) {
            if ($(element).hasClass("tooltipstered")) {
                var instance = $(element).tooltipster('instance');
                instance.content(text);
                instance.open();
            } else {
                $timeout(function () {
                    $(element).tooltipster({
                        contentAsHTML: true,
                        content: text,
                        theme: 'tooltipster-kw',
                        side: ['right'],
                        zIndex: 3,
                        interactive: true,
                        selfDestruction: true,
                        animation: 'fade',
                        maxWidth: 350,
                        plugins: ['sideTip'],
                        distance: 0,
                        arrow: false,
                        repositionOnScroll: true,
                        timer: 10000,
                        triggerClose: {
                            click: true,
                            scroll: false
                        }
                    }).tooltipster('open');
                });
            }
        }


        function showPresetsMarkTooltip() {
            var translated = '';
            if ($scope.isMainUsage) {
                try {
                    if (isCurrentStateEditRecord()) {
                        translated = $translate.instant('realEstate.general.process-form.edit-record.presetsMarkTooltip');
                    } else {
                        translated = $translate.instant('realEstate.general.process-form.input.presetsMarkTooltip');
                    }
                } catch (e) {
                    console.warn('translation error usage type presetMark', e);
                }

                var element = '#usageToast';
                return showToast(element, translated);
            }
        }

        function showDisabledToast(usageTitle) {
            var element = '#usageToast';
            try {
                var part1 = $translate.instant('realEstate.usages.usage-toast-data-1-1');
                var part2 = $translate.instant('realEstate.usages.usage-toast-data-1-2');
                var translated = part1 + ' ' + usageTitle + ' ' + part2;
                return showToast(element, translated);
            } catch (e) {
                console.error(e);
            }
        }


        vm.model = usageTypeGroupFactory.getByTitle($scope.reference);
        if (vm.model.id) {
            vm.model = {
                "id": vm.model.id,
                "title": vm.model.title,
                "parent": vm.model.parent,
                "translated": vm.translateKey(vm.model.title),
                "disabled": vm.model.disabled
            };
        } else {
            vm.model = {
                "id": "00",
                "title": "All",
                "parent": true
            };
        }

        /**
         * This watch checks if the reference changes and updates the whole component.
         * This can happen if we load data from the db async.
         */
        $scope.$watch('reference', function (newValue) {
            if (newValue && angular.isDefined(newValue)) {
                vm.model = usageTypeGroupFactory.getByTitle(newValue);
                if (vm.model.id) {
                    vm.model = {
                        "id": vm.model.id,
                        "title": vm.model.title,
                        "parent": vm.model.parent,
                        "translated": vm.translateKey(vm.model.title),
                        "disabled": vm.model.disabled
                    };
                } else {
                    vm.model = {
                        "id": "00",
                        "title": "All",
                        "parent": true
                    };
                }
            }
        });

        vm.clearReference = function() {
            $scope.reference = null;
        };

        $scope.$on('showSliderPresetTooltip', function() {
            showPresetsMarkTooltip();
        });

        vm.validForPresetsMark = function() {
            if ($scope.reference != null && $scope.standard != null) {
                showPresetsMarkTooltip();
            }
        };

        var translateSuccess = $rootScope.$on('$translateChangeSuccess', function() {
            if (vm.model != null) {
                vm.model.translated = vm.translateKey(vm.model.title);
            }
            for (var i = 0; i < $scope.groups.length; i++) {
                if ($scope.groups[i].title != null) {
                    $scope.groups[i].translated = vm.translateKey($scope.groups[i].title);
                }
            }
        });
        this.$onDestroy = function() {
            translateSuccess();
        };
    }

    UsageTypeSelectDirective.$inject = ['usageTypeGroupFactory', '$timeout', '$rootScope', '$translate'];

    function UsageTypeSelectDirective(usageTypeGroupFactory, $timeout, $rootScope, $translate) {
        return {
            restrict: 'E',
            scope: {
                isMainUsage: '=',
                usagesLength: '@',
                reference: '=',
                percentage: '=',
                standard: '=',
                referenceArray: '=',
                rowid: '=',
                image: '=image',
                required: '=required',
                isDisabled: '=',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                translateBaseKey: '@',
                descriptionBaseKey: '@',
                index: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?'
            },
            templateUrl: 'app/components/usage_type/usage-type-select.tpl.html',
            controller: UsageTypeSelectController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, ctrl) {
                try{
                    scope.translateKey = function (postfix) {
                        return $translate.instant('realEstate.usages.' + postfix);
                    };
                } catch (e) {
                    console.warn('translation error usage type select',e);
                }

                scope.breadcrumbs = [{"id": "00", "title": "All", "parent": true}];
                scope.model = usageTypeGroupFactory.getByTitle(scope.reference);

                var gs = [];
                if (scope.reference) {
                    var model = usageTypeGroupFactory.getByTitle(scope.reference);
                    var groupId = usageTypeGroupFactory.getGroupIdById(model.id);
                    if (groupId !== 0) {
                        usageTypeGroupFactory.getAllPreviousGroups(model.id)
                            .forEach(function (prevGroup) {
                                scope.breadcrumbs.push(prevGroup);
                            });
                    }
                    gs = usageTypeGroupFactory.load(groupId);
                    //adds the first usage on load.
                    if (scope.isMainUsage && scope.usagesLength == 1) {
                        scope.referenceArray.push({
                            type: null,
                            percentage: null,
                            standard: null
                        });
                    }
                } else {
                    gs = usageTypeGroupFactory.load("00");
                }

                var enhanceGroupFn = function (gs) {
                    for (var i in scope.groups) {
                        var oldg = scope.groups[i];
                        oldg.noshow = true;
                    }

                    var enhancedGroups = [];
                    gs.forEach(function (group) {
                        if (!scope.isMainUsage || (group.title !== "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN" && group.title !== "VERKEHRSBAUTEN__GARAGEN_FREISTEHEND")) {
                            // except for VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN and VERKEHRSBAUTEN__GARAGEN_FREISTEHEND don't enhance groups
                            enhancedGroups.push({
                                "id": group.id,
                                "title": group.title,
                                "parent": group.parent,
                                "translated": scope.translateKey(group.title),
                                "disabled": group.disabled
                            });
                        }
                    });
                    scope.groups = enhancedGroups;
                };

                // add property translated to to each group in groups
                enhanceGroupFn(gs);

                scope.loadChildGroupsOf = function (group) {
                    scope.breadcrumbs.push(group);
                    var gs = usageTypeGroupFactory.load(group.id);
                    enhanceGroupFn(gs);
                };

                scope.navigateBackTo = function (crumb) {
                    var index = _.findIndex(scope.breadcrumbs, {id: crumb.id});
                    scope.breadcrumbs.splice(index + 1, scope.breadcrumbs.length);
                    var gs = usageTypeGroupFactory.load(_.last(scope.breadcrumbs).id);
                    // ctrl.onSelect(crumb);
                    ctrl.saveSelection(crumb);
                    ctrl.updateDescriptionAndImage(crumb);
                    enhanceGroupFn(gs);
                };

                // Only allow numbers in the input field
                // The simpler 'type="number"' is causing the curser to jump
                // if a letter is entered.
                tElement.find(".percentageInputField").on('change keyup', function () {
                    // Remove invalid characters
                    var sanitized = $(this).val().replace(/[^0-9]/g, '');
                    // Update value

                    $(this).val(sanitized);
                });

                // Used for the validation
                var toggle = tElement.find(".drop2downUsageTypesOuter");
                ctrl.standardReferenceString = tAttrs.referenceArray + "[" + Number(scope.index) + "].standard";
                $(toggle).attr("id", tAttrs.referenceArray + "[" + Number(scope.index) + "].type.drop2down-toggle");
                var percentageElement = tElement.find(".percentageInputField");
                $(percentageElement).attr("id", tAttrs.referenceArray + "[" + Number(scope.index) + "].percentage.input");
            }
        };
    }

    UiSelectFocuser.$inject = ['$timeout'];

    function UiSelectFocuser($timeout) {
        return {
            restrict: 'A',
            require: '^uiSelect',
            link: function (scope, elem, attrs, uiSelect, ctrl) {
                scope.$on('uiSelectClose', function () {
                    uiSelect.close();
                });

                // resets the active Element always to the first Element
                scope.$on('uis:refresh', function () {
                    uiSelect.activeIndex = 0;

                });

                //this overwrites the function isActive in
                // ui-select
                // check select.js in bower_components (codeLine 615)
                uiSelect.isActive = function (item) {
                    if (!uiSelect.open) {
                        return false;
                    }
                    var itemIndex = uiSelect.items.indexOf(item[uiSelect.itemProperty]);
                    return itemIndex == uiSelect.activeIndex;

                };
            }
        };
    }
})();
