(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('SankeyModalController', SankeyModalController);

    SankeyModalController.$inject = ['$uibModalInstance', 'title', 'rows'];

    function SankeyModalController ($uibModalInstance, title, rows) {
        var vm = this;

        if (Array.isArray(rows)) {
            vm.rows = rows;
        } else {
            vm.rows = [ rows ];
        }

        vm.cancel = cancel;
        vm.title = title;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
