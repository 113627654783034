(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('accuracy_operating_costs', {
                parent: 'app',
                url: '/info-operating-costs',
                data: {
                    authorities: [],
                    pageTitle: 'info-operating-costs.pageTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/accuracy_operating_costs/accuracy-operating-costs.html',
                        controller: 'AccuracyOperatingCostsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader
                            .addPart('accuracy')
                            .addPart('seo')
                            .addPart('prices')
                            .addPart('operating_costs')
                            .addPart('process_form');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    metaTags: ['MetaTagService', function(MetaTagService) {
                        MetaTagService.setTags({
                            'description': 'Für Architekten & Bauherren: Tool zur Berechnung der Betriebskosten bei Liegenschaften, & Immobilien. Unterscheidung nach Kostenträger. Jetzt 7 Tage gratis testen.',
                            'Keywords': 'Betriebskosten Liegenschaft',
                            // OpenGraph
                            'og:type': 'website',
                            'og:title': 'kennwerte',
                            'og:description': 'Die innovative Lösung zur Baukostenschätzung und Baukostenberechnung: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
                            // Twitter
                            'twitter:card': 'summary_large_image',
                            'twitter:site': '@kennwerte',
                            'twitter:title': 'kennwerte',
                            'twitter:description': 'Die innovative Lösung zur Baukostenschätzung und Baukostenberechnung: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!'
                        });
                    }]
                }
            });
    }

})();
