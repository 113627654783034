(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('adminManipulateLicense', AdminManipulateLicense)
        .controller('adminManipulateLicenseCtrl', AdminManipulateLicenseCtrl);

    AdminManipulateLicense.$inject = [];

    function AdminManipulateLicense() {
        return {
            restrict: "E",
            replace: "true",
            scope: {
                wp: "=",
                organisation: "=",
                licenseStates: "=",
                baseLicenses: "=",
                withdrawalProducts: "=",
                paymentOptions: "=",
                updateFn: "&"
            },
            controller: AdminManipulateLicenseCtrl,
            controllerAs: 'ivm',
            templateUrl: 'app/admin/organisation-management/manipulate_license_directive/manipulate_license.tpl.html'
        };
    }

    AdminManipulateLicenseCtrl.$inject = ['$scope', '$translate'];

    function AdminManipulateLicenseCtrl($scope, $translate) {
        var ivm = this;
        ivm.organisation = $scope.organisation;
        ivm.licenseStates = $scope.licenseStates;
        ivm.baseLicenses = $scope.baseLicenses;
        ivm.withdrawalProducts = $scope.withdrawalProducts;
        ivm.paymentOptions = $scope.paymentOptions;

        ivm.colorizeLicense = colorizeLicense;
        ivm.printLicense = printLicense;
        ivm.updateLicense = updateLicense;
        ivm.translateLicenseStatus = translateLicenseStatus;

        function colorizeLicense(license, numEmployees) {
            if ((license.numMinEmployees !== -1 && numEmployees >= license.numMinEmployees)
                && (license.numMaxEmployees !== -1 && numEmployees <= license.numMaxEmployees)) {
                return "green";
            } else {
                return "red";
            }
        }

        function printLicense(baseLicense) {
            var strPrivatePrefix = baseLicense.isPrivate ? "PRIVATE" : "PUBLIC";
            return baseLicense.name + "\t" + strPrivatePrefix + " [ Monatl. Grundgebühr: " + baseLicense.monthlyBaseFee / 100 + " CHF ]";
        }

        function updateLicense() {
            $scope.updateFn();
        }


        function translateLicenseStatus(status) {
            try {
                return $translate.instant('billing.licenseStatus.' + status  +'.label');
            } catch (e) {
                console.warn('translate error transactionStatus', e);
            }
        }

    }

})();
