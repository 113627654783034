(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('GeneralFormController', GeneralFormController);

    GeneralFormController.$inject = ['$scope', '$stateParams', '$window', 'KwImageModelService'];

    function GeneralFormController($scope, $stateParams, $window, KwImageModelService) {
        $scope.data = $stateParams.data;

        $scope.isSpecialUsage = function() {
            return KwImageModelService.isSpecialUsage($scope.realEstateContainer);
        };

        $scope.isKwImageModelInProgress = function() {
            return KwImageModelService.isEvalImageOnProgress();
        };

    }

})();



