(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('lccCostsByKindTable', LccCostsByKindTable);

    LccCostsByKindTable.$inject = ['$rootScope', '$sce'];

    function LccCostsByKindTable($rootScope, $sce) {
        return {
            restrict: 'E',
            scope: {
                constructionRgb: '<',
                construction : '<',
                constructionFormatted : '<',
                operationalEkRgb: '<',
                operationalEk : '<',
                operationalEkFormatted : '<',
                operationalNkRgb: '<',
                operationalNk : '<',
                operationalNkFormatted : '<',
                operationalVkRgb: '<',
                operationalVk : '<',
                operationalVkFormatted : '<',
                rebuildRgb: '<',
                rebuild : '<',
                rebuildFormatted : '<',
                endOfLifeRgb: '<',
                endOfLife : '<',
                endOfLifeFormatted : '<',
                total : '<',
                totalFormatted : '<',
                observationPeriod : '<',
            },
            templateUrl: 'app/components/outputs/lcc_costs_by_kind_table/lcc-costs-by-kind-table.tpl.html',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                scope.imgUrl = 'https://kv-lcc-plots-renderer-prod--1-0-2-sguiwzzoqq-ez.a.run.app/dynamic_cost_split_plot/' +
                    '?plot_title=' +
                    '&construction_costs_label=' +
                    '&construction_costs_rgb=' + scope.constructionRgb +
                    '&construction_costs=' +  + scope.construction.total;
                if (scope.operationalEk != null)
                    scope.imgUrl +=
                        '&operational_costs_ek_label=' +
                        '&operational_costs_ek_rgb=' + scope.operationalEkRgb +
                        '&operational_costs_ek=' + scope.operationalEk.total;
                if (scope.operationalNk != null)
                    scope.imgUrl +=
                        '&operational_costs_nk_label=' +
                        '&operational_costs_nk_rgb=' + scope.operationalNkRgb +
                        '&operational_costs_nk=' + scope.operationalNk.total;
                if (scope.operationalVk != null)
                    scope.imgUrl +=
                        '&operational_costs_vk_label=' +
                        '&operational_costs_vk_rgb=' + scope.operationalVkRgb +
                        '&operational_costs_vk=' + scope.operationalVk.total;

                if (scope.endOfLife != null)
                    scope.imgUrl +=
                        '&end_of_life_costs_label=' +
                        '&end_of_life_costs_rgb=' + scope.endOfLifeRgb +
                        '&end_of_life_costs=' + scope.endOfLife.total;

                scope.imgUrl +=
                    '&rebuild_costs_label=' +
                    '&rebuild_costs_rgb=' + scope.rebuildRgb +
                    '&rebuild_costs=' + scope.rebuild.total;

                scope.constructionColor = 'rgb(' + scope.constructionRgb + ')';
                scope.operationalEkColor = 'rgb(' + scope.operationalEkRgb + ')';
                scope.operationalNkColor = 'rgb(' + scope.operationalNkRgb + ')';
                scope.operationalVkColor = 'rgb(' + scope.operationalVkRgb + ')';
                scope.rebuildColor = 'rgb(' + scope.rebuildRgb + ')';
                scope.endOfLifeColor = 'rgb(' + scope.endOfLifeRgb + ')';
            }
        };
    }

})();
