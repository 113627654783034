(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('adminFreeMonth', AdminFreeMonth)
        .controller('adminFreeMonth', AdminFreeMonthCtrl);

    AdminFreeMonth.$inject = [];

    function AdminFreeMonth() {
        return {
            restrict: "E",
            replace: "true",
            scope: {
                organisation: '=',
                requestFreeMonthUpdate: '='
            },
            controller: AdminFreeMonthCtrl,
            controllerAs: 'ivm',
            templateUrl: 'app/admin/organisation-management/free_month_directive/free_month.tpl.html'
        }
    }

    AdminFreeMonthCtrl.$inject = ['$scope', 'moment', '$q', 'WptFreeTrackingService'];

    function AdminFreeMonthCtrl($scope, moment, $q, WptFreeTrackingService) {
        var ivm = this;

        ivm.organisation = $scope.organisation;
        ivm.wptFreeTracking = null;
        ivm.newModuleType = null;

        ivm.updateWptFreeTracking = updateWptFreeTracking;
        ivm.spawnNewFreeModule = spawnNewFreeModule;
        ivm.removeFreeModule = removeFreeModule;
        ivm.setDateTimeInactive = setDateTimeInactive;
        ivm.unsetDateTimeInactive = unsetDateTimeInactive;
        ivm.unsetDateTimeOpInvoicingStart = unsetDateTimeOpInvoicingStart;
        ivm.setDateTimeOpInvoicingStart = setDateTimeOpInvoicingStart;
        ivm.removePaymentId = removePaymentId;

        loadAllWptsWithFreeMonths();
        loadWptFreeTracking();

        $scope.$watch('requestFreeMonthUpdate', function (newVal) {
            if (newVal) {
                loadAllWptsWithFreeMonths();
                loadWptFreeTracking();
            }
        }, true);

        function setWptsWithFreeMonthStillAvailable(wptFreeTracking, wptsWithFreeMonth) {
            if (wptFreeTracking && wptsWithFreeMonth) {
                var listOfWpts = wptFreeTracking.map(function (track) {
                    return track.wpt;
                });
                ivm.wptsWithFreeMonthStillAvailable = wptsWithFreeMonth.filter(function (wpt) {
                    return listOfWpts.indexOf(wpt) === -1;
                });
            }
        }

        function loadWptFreeTracking() {
            WptFreeTrackingService.queryByOrg({orgId: ivm.organisation.id}).$promise.then(
                function (wptTracking) {
                    // momentize dates
                    wptTracking.forEach(function (track) {
                        track.dateTimeFreeStart = moment(track.dateTimeFreeStart);
                        track.dateTimeFreeUntil = moment(track.dateTimeFreeUntil);
                        if (!!track.dateTimeInactive) {
                            track.dateTimeInactive = moment(track.dateTimeInactive);
                        }
                        if (!!track.dateTimeOpInvoicingStart) {
                            track.dateTimeOpInvoicingStart = moment(track.dateTimeOpInvoicingStart);
                        }
                    });
                    ivm.wptFreeTracking = wptTracking;
                    ivm.origWptFreeTracking = angular.copy(wptTracking);
                    setWptsWithFreeMonthStillAvailable(ivm.wptFreeTracking, ivm.wptsWithFreeMonth);
                    ivm.requestFreeMonthUpdate = false;
                }
            );
        }

        function setDateTimeInactive(track) {
            track.dateTimeInactive = moment(new Date());
        }

        function unsetDateTimeInactive(track) {
            track.dateTimeInactive = null;
        }

        function unsetDateTimeOpInvoicingStart(track) {
            track.dateTimeOpInvoicingStart = null;
        }

        function setDateTimeOpInvoicingStart(track) {
            track.dateTimeOpInvoicingStart = moment(new Date());
        }

        function removePaymentId(track){
            track.paymentId = null;
        }

        function removeFreeModule(wpt) {
            ivm.wptFreeTracking = ivm.wptFreeTracking.filter(function(track) {
                return track.wpt !== wpt;
            });
            setWptsWithFreeMonthStillAvailable(ivm.wptFreeTracking, ivm.wptsWithFreeMonth);
            console.log(wpt);
            console.log(ivm.wptFreeTracking);
        }

        function spawnNewFreeModule() {
            var now = new Date();
            var nowPlus7days = new Date(now);
            nowPlus7days.setDate(now.getDate() + 7);


            // set id if it is already present
            var id = null;
            // iterate through wptTracking and filter for all instances that are not contained in ivm.wptFreeTracking anymore
            ivm.origWptFreeTracking
                .filter(function(track) {
                    return track.wpt === ivm.newModuleType;
                })
                .forEach(function (track) {
                    id = track.id;
                });

            ivm.wptFreeTracking.push({
                id: id,
                wpt: ivm.newModuleType,
                orgId: ivm.organisation.id,
                dateTimeFreeStart: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())),
                dateTimeFreeUntil: moment(new Date(nowPlus7days.getFullYear(), nowPlus7days.getMonth(), nowPlus7days.getDate(), nowPlus7days.getHours(), nowPlus7days.getMinutes(), nowPlus7days.getSeconds())),
                dateTimeOpInvoicingStart: moment(new Date(nowPlus7days.getFullYear(), nowPlus7days.getMonth(), nowPlus7days.getDate(), nowPlus7days.getHours(), nowPlus7days.getMinutes(), nowPlus7days.getSeconds())),
                dateTimeInactive: null,
                paymentId: null
            });
            setWptsWithFreeMonthStillAvailable(ivm.wptFreeTracking, ivm.wptsWithFreeMonth);
            ivm.newModuleType = null;
        }

        function loadAllWptsWithFreeMonths() {
            WptFreeTrackingService.queryAllWptsWithFreeMonth().$promise.then(
                function (wptsWithFreeMonth) {
                    ivm.wptsWithFreeMonth = wptsWithFreeMonth;
                    setWptsWithFreeMonthStillAvailable(ivm.wptFreeTracking, ivm.wptsWithFreeMonth);
                }
            )
        }

        function doHandle(o) {
            var d = $q.defer();
            if (o.op === 'SAVE') {
                var result = WptFreeTrackingService.save(o.obj,
                    function() {
                        d.resolve(result);
                    },
                    function() {
                        d.resolve(result);
                    });
            } else if (o.op === 'UPDATE') {
                var result = WptFreeTrackingService.update(o.obj,
                    function() {
                        d.resolve(result);
                    },
                    function() {
                        d.resolve(result);
                    });
            } else if (o.op === 'DELETE') {
                var result = WptFreeTrackingService.delete(o.obj,
                    function() {
                        d.resolve(result);
                    },
                    function() {
                        d.resolve(result);
                    });
            }
            return d.promise;
        }

        function updateWptFreeTracking() {
            var copy = angular.copy(ivm.wptFreeTracking);
            copy.forEach(function (track) {
                track.dateTimeFreeStart = track.dateTimeFreeStart.toISOString();
                track.dateTimeFreeUntil = track.dateTimeFreeUntil.toISOString();
                if (track.dateTimeInactive) {
                    track.dateTimeInactive = track.dateTimeInactive.toISOString();
                }
                if (track.dateTimeOpInvoicingStart) {
                    track.dateTimeOpInvoicingStart = track.dateTimeOpInvoicingStart.toISOString();
                }
            });
            // for each wptFreeTracking instance, create new or update:
            var tracksToHandle = [];
            copy.forEach(function (track) {
                if (!track.id) {
                    tracksToHandle.push({'op': 'SAVE', 'obj': track});
                } else {
                    tracksToHandle.push({'op': 'UPDATE', 'obj': track});
                }
            });
            // check for wptFreeTracking instances to delete

            // iterate through wptTracking and filter for all instances that are not contained in origWptFreeTracking anymore
            var listOfPresentWpts = ivm.wptFreeTracking
                .map(function (track) {
                    return track.wpt;
                });
            ivm.origWptFreeTracking
                .filter(function (track) {
                    return listOfPresentWpts.indexOf(track.wpt) === -1;
                })
                .forEach(function(track) {
                    tracksToHandle.push({'op': 'DELETE', 'obj': track});
                });

            $q.all(
                tracksToHandle.map(function(o) {
                    return doHandle(o);
                })
            ).then(function(data) {
                loadWptFreeTracking();
                //TODO: reload pending withdrawal product in parent
            });

        }

    }

})();
