(function () {
    "use strict";

    angular
        .module("kennwerteApp")
        .controller("JhiHealthCheckController", JhiHealthCheckController);

    JhiHealthCheckController.$inject = ["JhiHealthService", "RebuildEstateDataService", "BillingService", "BexioService", "RealestateReferenceService",
        "ExistingWptService", "$uibModal", "$state", "$translate", "imageService"];

    function JhiHealthCheckController(JhiHealthService, RebuildEstateDataService, BillingService, BexioService, RealestateReferenceService,
                                      ExistingWptService, $uibModal, $state, $translate, imageService) {
        var vm = this;

        vm.updatingHealth = true;
        vm.getLabelClass = getLabelClass;
        vm.refresh = refresh;
        vm.showHealth = showHealth;
        vm.updateEstimatorUrl = updateEstimatorUrl;
        vm.transferData = transferData;
        vm.adminRealEstate = adminRealEstate;
        vm.downloadBaWpt = downloadBaWpt;
        vm.downloadPayments = downloadPayments;
        vm.downloadValidLicenses = downloadValidLicenses;
        vm.downloadCustomerRealestates = downloadCustomerRealestates;
        vm.syncStatik = syncStatik;
        vm.billLicenses = billLicenses;
        vm.syncBexio = syncBexio;
        vm.baseName = JhiHealthService.getBaseName;
        vm.subSystemName = JhiHealthService.getSubSystemName;

        vm.healImagesOnS3 = healImagesOnS3;
        vm.buttonHealImagesOnS3 = false;

        vm.ba_wpts = ExistingWptService.getAllExistingWpts();
        vm.ba_selected_wpts = [];
        vm.payment_selected_wpts = [];

        var now = moment.utc();
        vm.startDate = moment.utc().subtract(1, 'years');
        vm.endDate = now;

        vm.validLicensesDateTimeDeadline = now;

        vm.refresh();

        vm.isBillLicensesSuccessful = null;
        vm.isBexioSyncSuccessful = null;

        vm.statikSyncSuccessful = false;

        vm.realEstateId = null;


        function getLabelClass(statusState) {
            if (statusState === 'UP') {
                return 'label-success';
            } else {
                return 'label-danger';
            }
        }

        function refresh() {
            vm.updatingHealth = true;
            JhiHealthService.checkHealth().then(function (response) {
                vm.healthData = JhiHealthService.transformHealthData(response);
                vm.updatingHealth = false;
            }, function (response) {
                vm.healthData = JhiHealthService.transformHealthData(response.data);
                vm.updatingHealth = false;
            });
        }

        function showHealth(health) {
            $uibModal.open({
                templateUrl: 'app/admin/health/health.modal.html',
                controller: 'HealthModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    currentHealth: function () {
                        return health;
                    },
                    baseName: function () {
                        return vm.baseName;
                    },
                    subSystemName: function () {
                        return vm.subSystemName;
                    }

                }
            });
        }

        function updateEstimatorUrl() {
            JhiHealthService.updateEstimator().then(function (response) {
                console.log(response);
            });
        }

        function transferData() {
            // intended to sync data between s3 and db-statik
            JhiHealthService.transferData().then(function (response) {
                console.log(response);
            });
        }

        function adminRealEstate() {
            if (!!vm.realEstateId) {
                JhiHealthService.adminRealEstate(vm.realEstateId).then(function (response) {
                    switch (response.metaData.withdrawalProductType) {
                        case 'BUILDING_COSTS_AND_DATES':
                            $state.go('process-form.input', {
                                realEstate: response,
                                withdrawalProductType: 'building_costs_and_dates'
                            });
                            break;
                        case 'OPERATING_COSTS':
                            $state.go('operational-costs.input', {
                                realEstate: response,
                                withdrawalProductType: 'operating_costs'
                            });
                            break;
                        case 'REBUILD_COSTS':
                            RebuildEstateDataService.set(response,true);
                            $state.go('rebuild-form.input', {
                                realEstate: response,
                                withdrawalProductType: 'rebuild_costs'
                            });
                            break;
                        case 'DAMAGE_COSTS':
                            RebuildEstateDataService.set(response,true);
                            $state.go('damage-form.input', {
                                realEstate: response,
                                withdrawalProductType: 'damage_costs'
                            });
                            break;
                        case 'BUILDING_INSURANCE_VALUES':
                            $state.go('insurance.input', {
                                realEstate: response,
                                withdrawalProductType: 'building_insurance_values'
                            });
                            break;
                        case 'SMALL_BUILDING_AGV':
                            $state.go('small-building-form.input', {
                                realEstate: response,
                                withdrawalProductType: 'small_building_agv'
                            });
                            break;
                        default:
                            console.error('WithdrawalProductType is possibly wrong!', response);
                    }
                });
            }
        }

        function billLicenses() {
            BillingService.billLicenses().then(function (response) {
                vm.isBillLicensesSuccessful = true;
                console.log(response);
            }, function (error) {
                vm.isBillLicensesSuccessful = false;
                console.error(error);
            });
        }

        function syncBexio() {
            BexioService.sync().then(function(response) {
                vm.isBexioSyncSuccessful = true;
                console.log(response);
            }, function(error) {
                vm.isBexioSyncSuccessful = false;
                console.error(error);
            });
        }

        function syncStatik() {
            RealestateReferenceService.sync({}, function (response) {
                console.log(response);
                vm.statikSyncSuccessful = true;
            }, function (error) {
                console.error(error);
                vm.statikSyncSuccessful = false;
            });
        }

        function downloadPayments() {
            JhiHealthService.downloadPayments(vm.payment_selected_wpts, vm.startDate.toISOString(), vm.endDate.toISOString()).then(function(response) {
                console.log(response);
            });
        }

        function downloadBaWpt() {
            JhiHealthService.downloadBaWpt(vm.ba_selected_wpts).then(function(response) {
                console.log(response);
            });
        }

        function downloadValidLicenses() {
            JhiHealthService.downloadValidLicenses(vm.validLicensesDateTimeDeadline.toISOString()).then(function(response) {
               console.log(response);
            });
        }

        function downloadCustomerRealestates() {
            JhiHealthService.downloadCustomerRealestates().then(function(response) {
                console.log(response);
            });
        }

        function healImagesOnS3() {
            if (vm.buttonHealImagesOnS3) {
                return;
            }
            vm.buttonHealImagesOnS3 = true;
            imageService.healImagesOnS3(function(res) {
                console.log(res);
                vm.buttonHealImagesOnS3 = false;
            });

        }

    }
})();
