(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component( 'rebuildTypeRowSelect', {
            restrict: 'E',
            bindings: {
                isId: '<',
                toastId: '@',
                overhaulId: '<',
                additionStoryId: '<',
                annexId: '<',
                rowid: '<',
                elemWidth: '<',
                translateBaseKey: '@',
                image: '<?',
                imageF: '&?',
                imageDefault: '<?',
                possibleTypes: '<?',
                isPossibleTypes: '<?',
                targetPossibleTypes: '<?',
                nonSharableTypes: '<?',
                sharedTypeOverhaul: '<',
                sharedTypeAdditionStory: '<',
                sharedTypeAnnex: '<',
                noType: '<',
                isVisibility: '<',
                isReference: '=',
                overhaulReference: '=',
                additionStoryReference: '=',
                annexReference: '=',
                overhaulVisible: '<',
                annexVisible: '<',
                additionStoryVisible: '<'
            },
            templateUrl: 'app/components/rebuild_type_row_select/rebuild-type-row-select.tpl.html',
            controller: RebuildTypeRowController
        });

    RebuildTypeRowController.$inject = ['$scope', 'RebuildGeometryService'];

    function RebuildTypeRowController($scope, RebuildGeometryService) {

        var $ctrl = this;

        $ctrl.RebuildGeometryService = RebuildGeometryService;

        function isShared(value) {
            return value === $ctrl.sharedTypeOverhaul || value === $ctrl.sharedTypeAdditionStory || value === $ctrl.sharedTypeAnnex;
        }

        function ifOverhaulIsAny(values) {
            return !$ctrl.overhaulVisible || _.some(_.castArray(values), function(value) { return $ctrl.overhaulValue === value; });
        }

        function ifAdditionStoryIsAny(values) {
            return !$ctrl.additionStoryVisible || _.some(_.castArray(values), function(value) { return $ctrl.additionStoryValue === value; });
        }

        function ifAnnexIsAny(values) {
            return !$ctrl.annexVisible ||  _.some(_.castArray(values), function(value) { return $ctrl.annexValue === value; });
        }

        function getUnsharableTypes() {
            if ($ctrl.nonSharableTypes == null) return [$ctrl.noType];
            return $ctrl.nonSharableTypes.concat($ctrl.noType);
        }

        function refreshOverhaulPossibleTypes() {
            $ctrl.overhaulPossibleTypes = _.filter($ctrl.targetPossibleTypes, function (v) {
                if (v === $ctrl.sharedTypeOverhaul) return false;
                if (v === $ctrl.sharedTypeAdditionStory && ifAdditionStoryIsAny(getUnsharableTypes())) return false;
                if (v === $ctrl.sharedTypeAnnex && ifAnnexIsAny(getUnsharableTypes())) return false;
                return true;
            });
        }

        function refreshAdditionStoryPossibleTypes() {
            $ctrl.additionStoryPossibleTypes = _.filter($ctrl.targetPossibleTypes, function (v) {
                if (v === $ctrl.sharedTypeAdditionStory) return false;
                if (v === $ctrl.sharedTypeOverhaul && ifOverhaulIsAny(getUnsharableTypes().concat([$ctrl.sharedTypeAdditionStory, $ctrl.sharedTypeAnnex]))) return false;
                if (v === $ctrl.sharedTypeAnnex && ifAnnexIsAny(getUnsharableTypes().concat([$ctrl.sharedTypeOverhaul, $ctrl.sharedTypeAdditionStory]))) return false;
                return true;
            });
        }

        function refreshAnnexPossibleTypes() {
            $ctrl.annexPossibleTypes = _.filter($ctrl.targetPossibleTypes, function (v) {
                if (v === $ctrl.sharedTypeAnnex) return false;
                if (v === $ctrl.sharedTypeOverhaul && ifOverhaulIsAny(getUnsharableTypes().concat([$ctrl.sharedTypeAdditionStory, $ctrl.sharedTypeAnnex]))) return false;
                if (v === $ctrl.sharedTypeAdditionStory && ifAdditionStoryIsAny(getUnsharableTypes().concat([$ctrl.sharedTypeOverhaul, $ctrl.sharedTypeAnnex]))) return false;
                return true;
            });
        }

        function propagateIsChange(oldValue) {
            if ($ctrl.isValue !== null && ($ctrl.overhaulValue == null || $ctrl.overhaulValue === oldValue)) {
                if ($ctrl.targetPossibleTypes.includes($ctrl.isValue)) {
                    $ctrl.overhaulValue = $ctrl.isValue; // copy value from is to overhaul
                    propagateOverhaulChange();
                }
            }
        }

        function propagateOverhaulChange() {
            if ($ctrl.overhaulVisible && isShared($ctrl.overhaulValue)) {
                if ($ctrl.additionStoryValue === $ctrl.sharedTypeOverhaul) $ctrl.additionStoryValue = null;
                if ($ctrl.annexValue === $ctrl.sharedTypeOverhaul) $ctrl.annexValue = null;
            }
        }

        function propagateAnnexChange() {
            if ($ctrl.annexVisible && isShared($ctrl.annexValue)) {
                if ($ctrl.overhaulValue === $ctrl.sharedTypeAnnex) $ctrl.overhaulValue = null;
                if ($ctrl.additionStoryValue === $ctrl.sharedTypeAnnex) $ctrl.additionStoryValue = null;
            }
        }

        function propagateAdditionStoryChange() {
            if ($ctrl.additionStoryVisible && isShared($ctrl.additionStoryValue)) {
                if ($ctrl.overhaulValue === $ctrl.sharedTypeAdditionStory) $ctrl.overhaulValue = null;
                if ($ctrl.annexValue === $ctrl.sharedTypeAdditionStory) $ctrl.annexValue = null;
            }
        }

        $scope.$watch('$ctrl.overhaulVisible', function(n, o) {
            refreshAnnexPossibleTypes();
            refreshAdditionStoryPossibleTypes();
        });

        $scope.$watch('$ctrl.annexVisible', function(n, o) {
            refreshOverhaulPossibleTypes();
            refreshAdditionStoryPossibleTypes();
        });

        $scope.$watch('$ctrl.additionStoryVisible', function(n, o) {
            refreshOverhaulPossibleTypes();
            refreshAnnexPossibleTypes();
        });

        $scope.$watch('$ctrl.overhaulValue', function() {
            refreshAdditionStoryPossibleTypes();
            refreshAnnexPossibleTypes();
        });

        $scope.$watch('$ctrl.additionStoryValue', function() {
            refreshOverhaulPossibleTypes();
            refreshAnnexPossibleTypes();
        });

        $scope.$watch('$ctrl.annexValue', function() {
            refreshOverhaulPossibleTypes();
            refreshAdditionStoryPossibleTypes();
        });

        $ctrl.isChanged = function(value, oldValue) {
            $ctrl.isReference = (value == null) ? null : value.value;
            propagateIsChange(oldValue);
        };

        $ctrl.overhaulChanged = function(value) {
            $ctrl.overhaulReference = (value == null) ? null : value.value;
            propagateOverhaulChange();
        };

        $ctrl.additionStoryChanged = function(value) {
            $ctrl.additionStoryReference = (value == null) ? null : value.value;
            propagateAdditionStoryChange();
        };

        $ctrl.annexChanged = function(value) {
            $ctrl.annexReference = (value == null) ? null : value.value;
            propagateAnnexChange();
        };

        this.$onInit = function () {
            $ctrl.isPossibleTypes = $ctrl.isPossibleTypes || $ctrl.possibleTypes;
            $ctrl.targetPossibleTypes = $ctrl.targetPossibleTypes || $ctrl.possibleTypes;
            $ctrl.isValue = _.find($ctrl.targetPossibleTypes, function (v) {
                return v.value === $ctrl.isReference;
            });
            $ctrl.overhaulValue = _.find($ctrl.targetPossibleTypes, function (v) {
                return v.value === $ctrl.overhaulReference;
            });
            $ctrl.additionStoryValue = _.find($ctrl.targetPossibleTypes, function (v) {
                return v.value === $ctrl.additionStoryReference;
            });
            $ctrl.annexValue = _.find($ctrl.targetPossibleTypes, function (v) {
                return v.value === $ctrl.annexReference;
            });
            $ctrl.targetPossibleTypesWithoutShared = _.filter($ctrl.targetPossibleTypes, function (v) {
                return v !== $ctrl.sharedType;
            });
            refreshOverhaulPossibleTypes();
            refreshAdditionStoryPossibleTypes();
            refreshAnnexPossibleTypes();
        };

    }

})();
