function showDetailInfo(index) {
    var n = index.length;
    var toToggle = '#' + index.substring(0, n - 2);
    $(toToggle).toggle();
}

(function () {
    'use strict';

    angular.module('kennwerteApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('operational-costs', {
                parent: 'app',
                abstract: true,
                url: '/operational-costs',
                views: {
                    // Fill 'ui-view="content"' of 'index.html'
                    'content@': {
                        templateUrl: "app/operational_costs/form/operational_costs_form.html",
                        controller: 'OperationalCostsFormController',
                        controllerAs: 'vm'
                    },
                    'input@operational-costs': {
                        templateUrl: 'app/operational_costs/form/10_operational_costs_input/operational_costs_input.html',
                        controller: 'OperationalCostsInputController',
                        controllerAs: 'vm'
                    },
                    'calculating_error@operational-costs': {
                        templateUrl: 'app/operational_costs/form/20_operational_costs_output/10_operational_costs_calculating_error/10_operational_costs_calculating_error.controller.html',
                        controller: 'OperationalCostsCalculatingErrorController',
                        controllerAs: 'vm'
                    },
                    'calculating_success@operational-costs': {
                        templateUrl: 'app/operational_costs/form/20_operational_costs_output/11_operational_costs_calculating_success/11_operational_costs_calculating_success.html',
                        controller: 'OperationalCostsCalculatingSuccessController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    data: null,
                    withdrawalProductType: null,
                    realEstateId: null,
                    realEstate: null
                },
                //controller: 'OperationalCostsFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.operational-costs.input'
                },
                resolve: {
                    resetIsCompleteState: ['ValidationDataService', function (ValidationDataService) {
                        ValidationDataService.resetComplete();
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form');
                        $translatePartialLoader.addPart('operational-costs_form');
                        $translatePartialLoader.addPart('realestates');
                        $translatePartialLoader.addPart('pom');
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('operational-costs.input', {
                url: '/input',
                //controller: 'OperationalCostsFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'], // we may also be fine without requiring these roles
                    pageTitle: 'global.menu.operational-costs.input',
                    showDivs: [
                        // Group
                        '10_operational_costs_input',
                        // Children
                        '10_operational_costs_howto_section',
                        '20_operational_costs_general_form',
                        '30_operational_costs_geometry_form',
                        '40_operational_costs_complexity_form',
                        '50_operational_costs_quality_form',
                        '70_operational_costs_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ['$state', 'BillingService', function ($state, BillingService) {
                        BillingService.licenseState()
                            .then(function (res) {
                                if (res.data.licenseState === 'INVALID') {
                                    $state.go('insufficient-rights.license',{wpt: 'OPERATING_COSTS' });
                                } else {
                                    return res.data.licenseState;
                                }
                            });
                    }],
                    check: ['$state', 'Principal', function ($state, Principal) {
                        Principal.identity().then(function(e) {
                            if (!!e && !Principal.hasModule('OPERATING_COSTS')) {
                                $state.go('insufficient-rights.operational-costs');
                            }
                        });
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('process_form');
                        $translatePartialLoader.addPart('model_realEstate');
                        $translatePartialLoader.addPart('model_geometry');
                        $translatePartialLoader.addPart('model_complexity');
                        $translatePartialLoader.addPart('model_quality');
                        $translatePartialLoader.addPart('operational-costs_form');

                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }]
                }
            })
            .state('operational-costs.edit-record', {
                url: '/edit',
                //controller: 'OperationalCostsFormController',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER'],
                    pageTitle: 'global.menu.operational-costs.edit',
                    showDivs: [
                        // Group
                        '10_operational_costs_input',
                        // Children
                        '15_operational_costs_howto_edit_record',
                        '20_operational_costs_general_form',
                        '30_operational_costs_geometry_form',
                        '40_operational_costs_complexity_form',
                        '50_operational_costs_quality_form',
                        '70_operational_costs_complete_input'
                    ]
                },
                resolve: {
                    checkLicense: ["$state", "BillingService", function($state, BillingService) {
                        BillingService.licenseState()
                            .then(function(res) {
                                if (res.data.licenseState === "INVALID") {
                                    $state.go("insufficient-rights.license");
                                } else {
                                    return res.data.licenseState;
                                }
                            });
                    }],
                    mainTranslatePartialLoader: ["$translate", "$translatePartialLoader", function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart("process_form")
                            .addPart("model_realEstate")
                            .addPart("model_geometry")
                            .addPart("model_complexity")
                            .addPart("model_quality")
                            .addPart("operational-costs_form");
                        return $translate.refresh().catch(function(e){console.warn(e)});
                    }],
                    resolveModuleLoad: ["RealestateService", "$q", "$state", "$stateParams", "$timeout", "Principal",
                        function(RealestateService, $q, $state, $stateParams, $timeout, Principal) {
                            // first check principal
                            Principal.identity().then(function(e) {
                                if (!!e && !Principal.hasModule('OPERATING_COSTS')) {
                                    $state.go('insufficient-rights.operational-costs');
                                }
                            });
                            var thisModule = "OPERATING_COSTS";
                            var deferred = $q.defer();
                            // session cache lesen - da params empty.
                            if ($stateParams.realEstateId == null) {
                                deferred.reject();
                                console.error("no real estate id");
                                $state.go("building-costs.realestates");

                            } else {
                                RealestateService.getByIdCached($stateParams.realEstateId).then(function(res) {
                                    if (Principal.hasModule(res.metaData.withdrawalProductType)) {
                                        deferred.resolve(res);
                                        return deferred.promise;
                                    }
                                    var intersection = _.intersection(res.metaData.tasks, Principal.getModules());
                                    if (intersection.length == 0) {
                                        deferred.reject(res);
                                        $state.go("insufficient-rights.license");
                                    }
                                    if (_.includes(intersection, "BUILDING_COSTS_AND_DATES")) {
                                        deferred.resolve(res);
                                        res.metaData.withdrawalProductType = "BUILDING_COSTS_AND_DATES";
                                        res.metaData.tasks = ["BUILDING_COSTS_AND_DATES"];
                                        $state.go("process-form.edit-record", {
                                            realEstateId: res.id,
                                            withdrawalProductType: "BUILDING_COSTS_AND_DATES".toLowerCase()
                                        });
                                    }
                                    deferred.resolve(res);
                                });
                            }
                            return deferred.promise;
                        }]
                }
            });
    }
})();
