(function () {
    "use strict";

    angular.module("kennwerteApp")
        .component("licenseTableFixPrice", {
            bindings: {
                activeWpts: "=?",
                activeWp: "=?",
                baseContainer: "=?",
                baseLicense: "=?",
                freeWpt: "=?",
                organisation: "=?",
                price: "=?",
                wptFreeTracking: "=?",
                translateDescriptionBaseKey: "@"
            },
            templateUrl: "app/account/billing/billing.active/license_table_fix_price.tpl.html",
            controller: licenseTableFixPrice
        });

    licenseTableFixPrice.$inject = ["$scope", "$rootScope", "$q", "$location", "$element", "$translate", "$attrs",
        "ExistingWptService", "BillingService", "Principal", "moment"];

    function licenseTableFixPrice($scope, $rootScope, $q, $location, $element, $translate, $attrs,
                                  ExistingWptService, BillingService, Principal, moment) {

        var $ctrl = this;
        $ctrl.firstLoad = true;
        $ctrl.showModuleSelction = false;
        // $ctrl.price = $ctrl.baseContainer.price;
        // $ctrl.organisation = $ctrl.baseContainer.organisation;

        // console.warn($ctrl.organisation,$ctrl.price);
        // console.warn($ctrl.organisation);
        this.$onInit = function () {
            console.warn($ctrl.activeWp, $ctrl.price);
            console.warn($ctrl.organisation);
            $ctrl.activeWpts = fetchActiveWpts();
        };

        $scope.$watch('$ctrl.activeWp', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                $ctrl.activeWpts = fetchActiveWpts();
            }
        });

        $scope.$watch('$ctrl.wptFreeTracking', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                $ctrl.activeWpts = fetchActiveWpts();
            }
        });

        function fetchActiveWpts() {
            if ($ctrl.activeWp.licenseState !== 'FREE') {
                var freeWpts = $ctrl.wptFreeTracking
                    .filter(function(track) {
                        return !!track.dateTimeFreeUntil && moment.utc().isBefore(track.dateTimeFreeUntil) && !!track.dateTimeInactive;
                    }).map(function (track) {
                        return track.wpt;
                    });
                var ret = _.difference($ctrl.activeWp.withdrawalProductTypes, freeWpts);
                return ret;
            } else {
                // exclude inactive (but probably still usable) modules
                var inactiveWpts = $ctrl.wptFreeTracking.filter(function (track) {
                    return !!track.dateTimeInactive;
                }).map(function (track) {
                    return track.wpt;
                });
                var ret = _.difference($ctrl.activeWp.withdrawalProductTypes, inactiveWpts);
                return ret;
            }
        }


    }
})();
