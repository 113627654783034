(function () {
    'use strict';

    angular.module('kennwerteApp')
        .run(SelectizeChoicesTemplateCache);

    // This template caches renders the selectize choices.
    // It overwrites the definition in ui-select/dist/select.js in order to append breadcrumbs.
    SelectizeChoicesTemplateCache.$inject = ['$templateCache'];
    function SelectizeChoicesTemplateCache($templateCache) {
        $templateCache.put('selectize/choices.tpl.html', [
            '<div ng-show="$select.open"',
            '  class="ui-select-choices group-tree ui-select-dropdown selectize-dropdown" ng-class="{\'single\': !$select.multiple, \'multi\': $select.multiple}">',
            '  <div ng-show="breadcrumbs.length > 1" class="ui-select-breadcrumbs">',
            '   <span class="ui-breadcrumb-back" ng-click="navigateBackTo(breadcrumbs[breadcrumbs.length -2],$select)">',
            '           <i class="fa fa-chevron-left" aria-hidden="true"></i>&nbsp;&nbsp;',
            '   </span>',
            '   <span class="ui-breadcrumb" ng-repeat="crumb in breadcrumbs"',
            '       ng-click="navigateBackTo(crumb, $select)">',
            '       {{translateKey(crumb.title) | translate}}',
            '    </span>',
            '  </div>',
            '  <div class="ui-select-choices-content selectize-dropdown-content">',
            '    <div class="ui-select-choices-group optgroup">',
            '      <div ng-show="$select.isGrouped"',
            '        class="ui-select-choices-group-label optgroup-header" ng-bind="$group.name">',
            '      </div>',
            '      <div role="option" class="ui-select-choices-row" ng-class="{active: $select.isActive(this), disabled: $select.isDisabled(this)}">',
            '        <div class="option ui-select-choices-row-inner"',
            '           data-selectable="">',
            '        </div>',
            '      </div>',
            '    </div>',
            '  </div>',
            '</div>'
        ].join(''));
    }
})();
