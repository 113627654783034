(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('DamageQualityFormController', DamageQualityFormController);

    DamageQualityFormController.$inject = ['$scope', '$stateParams'];

    function DamageQualityFormController($scope, $stateParams) {
        $scope.data = $stateParams.data;
        $scope.isNotIE = false;
        if (angular.isUndefined(document.documentMode)) {
            $scope.isNotIE = true;
        } else {
            $scope.isNotIE = false;
        }

    }

})();

