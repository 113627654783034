(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('operationalCostsTable', OperationalCostsTableDirective);

    OperationalCostsTableDirective.$inject = ['$rootScope'];

    function OperationalCostsTableDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                output : '=',
            },
            templateUrl: 'app/components/outputs/operational_costs_table/operational-costs-table.tpl.html',
            controller: OperationalCostsTableDirectiveController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                controller.output.EK = [];
                controller.output.VK = [];
                controller.output.NK = [];

                function splitOutput() {
                    angular.forEach(controller.output.pomCosts, function (value, key) {
                        var deepClone = {};
                        angular.copy(value,deepClone);
                        if (deepClone.value === '0.-') {
                            deepClone.value = '';
                        }
                        if(deepClone.relativeValue === '0.00'){
                            deepClone.relativeValue = '';
                        }
                        switch (deepClone.owner) {
                        case 'EK':
                            controller.output.EK.push(deepClone);
                            break;
                        case 'VK':
                            controller.output.VK.push(deepClone);
                            break;
                        case 'NK':
                            controller.output.NK.push(deepClone);
                            break;
                        }
                    });
                }
                splitOutput();
            }
        };
    }

    OperationalCostsTableDirectiveController.$inject = ['$scope'];

    function OperationalCostsTableDirectiveController($scope) {
        var vm = this;
        vm.output = $scope.output;
    }

})();
