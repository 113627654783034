(function() {
    "use strict";

    angular.module("kennwerteApp")
        .component("compactDynamicMultiSelecter", {
            bindings: {
                reference: '=?',
                referenceString: '@?',
                selectables: '=', // Array which has all selectable options inside.
                realEstateContainer: '=?',
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '=',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                descriptionBaseKey: '@',
                classes: '@?',
                infotext: '@?',
                useShortStrings: '=?',
                inputBlurFun: '&?'

            },
            templateUrl: "app/components/dynamic_multi_selecter/compact-dynamic-multi-selecter.tpl.html",
            controller: CompactDynamicMultiSelecterController
        });

    CompactDynamicMultiSelecterController.$inject = ["$scope", "$rootScope", "$element", "$translate", "$attrs"];

    function CompactDynamicMultiSelecterController($scope, $rootScope, $element, $translate, $attrs) {
        var $ctrl = this;
        $ctrl.firstLoad = true;
        $ctrl.selected = [];
        $ctrl.counter = 0;
        $ctrl.displayShortString = '';
        $ctrl.model = {};

        function isSelected(type) {
            if ($ctrl.reference != null) {
                return $ctrl.reference.indexOf(type) > -1;
            }
        }

        if (angular.isUndefined($ctrl.useShortStrings)) {
            $ctrl.useShortStrings = false;
        }

        $ctrl.translateKey = function(postfix) {
            try {
                var instant = $translate.instant(
                    $ctrl.translateBaseKey + '.' + postfix + '.label'
                );
                return instant;
            } catch (e) {
                console.warn(e);
            }
        };

        $ctrl.createShortString = function() {
            $ctrl.displayShortString = '';
            var translateMe = [];
            _.forEach($ctrl.selected, function(element) {
                translateMe.push($ctrl.translateBaseKey + '.' + element + '.short');
                try {
                    if ($ctrl.displayShortString.length === 0) {
                        var initTemp = null;
                        try {
                            initTemp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.short');
                            if($ctrl.translateBaseKey + '.' + element + '.short' === initTemp){
                                throw $ctrl.translateBaseKey + '.' + element + '.short';
                            }
                        } catch (e) {
                            console.warn(e);
                            initTemp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.label');
                        }
                        $ctrl.displayShortString = initTemp;
                    } else {
                        var temp = null;
                        try {
                            temp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.short');
                            if($ctrl.translateBaseKey + '.' + element + '.short' === temp){
                                throw $ctrl.translateBaseKey + '.' + element + '.short';
                            }

                        } catch (e) {
                            console.warn(e);
                            temp = $translate.instant($ctrl.translateBaseKey + '.' + element + '.label');
                        }
                        $ctrl.displayShortString = $ctrl.displayShortString + ', ' + temp;
                    }
                } catch (e) {
                    console.warn(e);
                }
            });
        };

        var enhanceGroupFn = function(gs) {
            var enhancedGroups = [];
            for (var j in gs) {
                var group = gs[j];
                enhancedGroups.push({
                    title: group,
                    translated: $ctrl.translateKey(group),
                    isSelected: isSelected(group)
                });
            }
            $ctrl.groups = enhancedGroups;
        };

        $ctrl.resetGroups = function() {
            var gs;
            angular.copy($ctrl.selectables, gs);
            enhanceGroupFn(gs);
        };

        $ctrl.resetGroups();

        $ctrl.onBlur = function() {
        };

        $ctrl.isEmptyModel = function(model) {
            return angular.equals({}, model);
        };

        $ctrl.onOpenClose = function(isOpen, type) {
            if (isOpen) {
                $ctrl.updateDescriptionAndImage(undefined);
            }
        };

        $ctrl.onSelect = function(selectedItem) {
            selectedItem.isSelected = !selectedItem.isSelected;
            if (selectedItem.isSelected) {
                if (!_.includes($ctrl.selected, selectedItem.title)) {
                    $ctrl.selected.push(selectedItem.title);
                }
            } else {
                $ctrl.selected.splice($ctrl.selected.indexOf(selectedItem.title), 1);
            }
            _.uniq($ctrl.selected);
            $ctrl.updateDescriptionAndImage(selectedItem);
            if ($ctrl.useShortStrings) {
                $ctrl.createShortString();
            } else {
                $ctrl.updateCounter();
            }

        };

        $ctrl.updateCounter = function () {
            $ctrl.counter = $ctrl.selected.length;
        };

        $scope.$watch("$ctrl.model", function(selectedItem) {
            if (!$scope.firstLoad && selectedItem && !angular.equals({}, selectedItem)) {
                $ctrl.updateDescriptionAndImage(selectedItem);
            } else {
                $scope.firstLoad = false;
            }
        });

        $ctrl.updateDescriptionAndImage = function(selectedItem, model, string) {
            if (!$ctrl.firstLoad) {
                if (selectedItem) {
                    $ctrl.reference = $ctrl.selected;
                    $rootScope.$broadcast("updateImage", {
                        rowid: $ctrl.rowid,
                        imageSrc: $ctrl.image
                    });
                    $rootScope.$broadcast("updateDescription", {
                        rowid: $ctrl.rowid,
                        descriptionNumber: selectedItem.id,
                        descriptionBaseKey:
                            $ctrl.descriptionBaseKey + "." + selectedItem.title
                    });
                } else {
                    $rootScope.$broadcast("updateDescription", {
                        rowid: $ctrl.rowid,
                        // descriptionNumber: selectedItem.id,
                        descriptionBaseKey: $ctrl.descriptionBaseKey
                    });
                }
                //Blur function will only be called after firstLoad
                if ($ctrl.inputBlurFun != null) {
                    $ctrl.inputBlurFun();
                }
            } else {
                $ctrl.firstLoad = false;
            }

        };

        $scope.$watch("$ctrl.reference", function(newValue, oldValue) {
            if (newValue && angular.isDefined(newValue)) {
                $ctrl.selected = $ctrl.reference;
                angular.forEach($ctrl.reference, function(value, key, array) {
                    var idx = $ctrl.groups.findIndex(function(current) {
                        return value == current.title;
                    });
                    $ctrl.groups[idx].isSelected = true;
                });
                if ($ctrl.useShortStrings) {
                    $ctrl.createShortString();
                } else {
                    $ctrl.updateCounter();
                }
            }
        },true);

        this.$onInit = function() {

            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            // angular.copy($ctrl.selectables, $ctrl.model);
            enhanceGroupFn($ctrl.selectables);
            $ctrl.model = $ctrl.selectables;

            if ($ctrl.reference && angular.isDefined($ctrl.reference) && $ctrl.groups.length > 0) {
                try {
                    $ctrl.selected = $ctrl.reference;
                    _.forEach($ctrl.reference, function(value) {
                        var idx = $ctrl.groups.findIndex(function(current) {
                            return value == current.title;
                        });
                        if (idx >= 0) {
                            $ctrl.groups[idx].isSelected = true;
                        }
                    });
                    if ($ctrl.useShortStrings) {
                        $ctrl.createShortString();
                    } else {
                        $ctrl.updateCounter();
                    }
                } catch (e) {
                    console.error('error with: ', $ctrl.reference, $ctrl.groups);
                }
            }
            if ($ctrl.useShortStrings) {
                $ctrl.createShortString();
            } else {
                $ctrl.updateCounter();
            }
            // Used for the validation
            var toggle = $element.find('.dynamicMultiDropdown');
            $(toggle).attr('id', $ctrl.referenceString + '.drop2down-toggle');

        };
    }
})();
