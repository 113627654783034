(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .directive("exactPriceModal", ExactPriceModalDirective)
        .controller("exactPriceModalCtrl", ExactPriceModalController);

    ExactPriceModalDirective.$inject = [
        "$translate",
        "$translatePartialLoader"
    ];

    function ExactPriceModalDirective($translate, $translatePartialLoader) {
        return {
            restrict: "E",
            replace: false,
            scope: {},
            transclude: true,
            controllerAs: "ivm",
            controller: ExactPriceModalController,
            templateUrl:
                "app/components/exact_price_modal/exact-price-modal-button.tpl.html",
            link: function(scope, element, attrs, ctrl) {
                $translatePartialLoader.addPart("global").addPart("settings");
                return $translate.refresh();
            }
        };
    }

    ExactPriceModalController.$inject = ["$rootScope", "$scope", "$uibModal"];

    function ExactPriceModalController($rootScope, $scope, $uibModal) {
        var ivm = this;
        ivm.checkSuccess = null;
        // $scope.send = send;
    //TODO SE-607

        ivm.showAdvanced = function() {
            var modalInstance = $uibModal.open({
                controller: ModalController,
                animation: true,
                controllerAs: "ctrl",
                bindToController: true,
                scope: $scope,
                clickOutsideToClose: true,
                windowClass: "info-box-z-index",
                templateUrl:
                    "app/components/exact_price_modal/exact-price-modal.tpl.html"
            });
        };
        ModalController.$inject = [
            "$scope",
            "$uibModalInstance",
            "GTMService",
            "Organisation",
            "ExactPriceService",
            "$sessionStorage",
            "ProfileService"
        ];

        function ModalController(
            $scope,
            $uibModalInstance,
            GTMService,
            Organisation,
            ExactPriceService,
            $sessionStorage,
            ProfileService
        ) {
            $scope.request = {
                name: "",
                firm: "",
                email: "",
                phone: "",
                sendKwCalc: false,
                realEstateId: null,
                pdf: null
            };
            $scope.iform;
            $scope.isLoading = true;
            var realEstate = $sessionStorage.realEstateContainer;
            $scope.request.realEstateId = realEstate.id;

            Organisation.current().$promise.then(function(org) {
                $scope.request.firm = org.name;
                ProfileService.getProfileInfo().then(function(response) {
                    $scope.inProduction = response.inProduction;
                    $scope.swaggerEnabled = response.swaggerEnabled;
                    if ($scope.inProduction) {
                        GTMService.trackEvent({
                            action: "exact-price-modal-init",
                            category: "BP",
                            label: "org: " + $scope.request.firm
                        });
                    }
                    $scope.isLoading = false;
                });

            });
            ExactPriceService.init($scope.request, {}, function(res) {
                console.log(res);
            }, function(error) {
                console.error(error);
            });

            $scope.cancel = function() {
                $uibModalInstance.close();
            };

            $scope.send = function() {
                ExactPriceService.request($scope.request, {}, function(res) {
                    console.log(res);
                }, function(error) {
                    console.error(error);
                }).$promise.then(function(ret) {
                    $uibModalInstance.close();
                }, function(err) {
                    console.error(err);
                });
            };

            $scope.forward = function(){
                if($scope.iform.$invalid){
                    angular.forEach($scope.iform.$error.required, function(field) {
                    field.$setDirty();
                });
                } else {
                    $scope.send();
                    if ($scope.inProduction) {
                        GTMService.trackEvent({
                            action: "exact-price-modal-request",
                            category: "BP",
                            label: "org: " + $scope.request.firm
                        });
                    }
                }
            }
        }
    }
})();
