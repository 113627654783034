(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('kwCounter', {
            bindings: {},
            templateUrl: 'app/components/kw_counter/kw-counter.tpl.html',
            controller: KwCounterController
        });

    KwCounterController.$inject = ['$scope', '$rootScope', '$window', '$attrs', '$element', '$resource', '$timeout'];

    function KwCounterController($scope, $rootScope, $window, $attrs, $element, $resource, $timeout) {
        var $ctrl = this;
        $ctrl.showCounter = false;
        $ctrl.duration = 2;
        $ctrl.opt = {
            startVal: 0,
            useGrouping: false
        };

        angular.element(function() {
            $ctrl.dataHasLoaded = true;
        });

        var getValues = $resource('/api/realestates/cnt_total_num_objects');

        this.$onInit = function() {
            getValues.get(function(val) {
                $ctrl.countTo = val.cnt;
                if (val.cnt === 0) {
                    $ctrl.countTo = 9266;
                }
            }, function(error) {
                console.error(error);
                $ctrl.countTo = 9266;
                $ctrl.showCounter = true;
            });
        };


        $ctrl.isVisible = function($inview, $inviewInfo) {
            if ($inview) {
                $ctrl.showCounter = true;
            }
        };

    }
})();
