(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$rootScope', '$scope', 'settingsAccount', 'Auth', 'JhiLanguageService', '$translate', '$state', 'Organisation', 'SettingOrganisationService', 'OrganisationInvite', 'OrganisationUser'];

    function SettingsController($rootScope, $scope, settingsAccount, Auth, JhiLanguageService, $translate, $state, Organisation, SettingOrganisationService, OrganisationInvite, OrganisationUser) {
        var vm = this;

        // variables
        vm.error = null;
        vm.settingsAccount = copyAccount(settingsAccount);
        vm.success = null;
        vm.joined = false;
        vm.showOrg = false;
        vm.organisation = null;
        vm.productType = ['Baukosten & Termine', 'Gebäudeversicherungswerte'];

        // functions
        vm.save = save;
        vm.getOrganisation = getOrganisation;
        vm.openSettingsOrganisationModule = openSettingsOrganisationModule;
        vm.findInvitation = findInvitation;
        vm.joinOrganisation = joinOrganisation;
        vm.deleteInvitation = deleteInvitation;

        vm.getOrganisation();
        vm.findInvitation();

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        function copyAccount(account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                organisationId: account.organisationId,
                organisationRole: account.organisationRole
            };
        }

        function save() {
            Auth.updateAccount(vm.settingsAccount).then(function () {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function (account) {
                    vm.settingsAccount = copyAccount(account);
                    //$state.reload();
                });
                JhiLanguageService.getCurrent().then(function (current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        function onGetOrgSuccess(result) {
            vm.organisation = result;
            $scope.orgId = result.id;
            vm.settingsAccount.organisationId = result.id;
            vm.showOrg = true;
        }

        function onGetOrgError() {
            vm.error = 'ERROR';
            vm.showOrg = true;
        }

        function getOrganisation() {
            if (vm.settingsAccount.organisationId) {
                Organisation.get({'id': vm.settingsAccount.organisationId}, onGetOrgSuccess, onGetOrgError);
            } else {
                vm.showOrg = true;
            }
        }

        function findInvitation() {
            OrganisationInvite.query_all({id: 0, email: vm.settingsAccount.email}, onOrgSuccess, onError);
        }

        function onError() {
            vm.error = 'ERROR';
        }

        function onOrgSuccess(response) {
            vm.invites = response.data;
            if (vm.invites[0] !== null) {
                vm.invite = vm.invites[0];
            }
        }

        function onDeleteSuccess() {
            vm.invite = null;
            vm.findInvitation();
        }

        function onJoinSuccess() {
            vm.getOrganisation();
            vm.joined = true;
            vm.organisation = vm.invite.organisation;
            vm.invite = null;
            vm.findInvitation();
            $rootScope.$broadcast('updateNavbar');
        }

        function openSettingsOrganisationModule() {
            $scope.currentLogin = vm.settingsAccount.login;
            SettingOrganisationService.open($scope);
        }

        function joinOrganisation() {
            OrganisationUser.join_as_user({id: vm.invite.organisation.id}, vm.invite.email, onJoinSuccess, onError);
        }

        function deleteInvitation() {
            OrganisationInvite.delete_invite({
                id: vm.invite.organisation.id,
                email: vm.invite.email
            }, onDeleteSuccess, onError);
        }

    }
})();
