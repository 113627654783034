(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('UsageQuantilesService', UsageQuantilesService);

    UsageQuantilesService.$inject = [];

    /**
     * Is used in kw-lambda-node-usage-preset (separated repo) - microservice for kw-api!
     */
    function UsageQuantilesService() {

        /*
        support 0.8
        */
        var mapping = {
            "BANKEN": [[2.85, 2.85, 3.66, 4.2, 4.79, 5.91, 6.0], [1.51, 1.51, 2.58, 3.27, 4.25, 5.45, 5.45], [0.66, 0.66, 2.03, 3.27, 4.53, 6.46, 7.0], [2.6, 2.6, 3.54, 4.22, 4.85, 5.62, 5.62], [1.76, 1.76, 2.54, 3.01, 3.48, 4.27, 4.27], [2.95, 2.95, 4.22, 4.85, 5.38, 6.18, 6.18], [1.31, 1.31, 3.76, 5.12, 5.92, 6.89, 6.89], [1.6, 1.6, 2.43, 2.96, 3.48, 4.31, 4.31], [2.71, 2.71, 3.51, 4.0, 4.49, 5.29, 5.29], [2.69, 2.69, 3.52, 4.04, 4.57, 5.4, 5.4]],
            "BUEROBAUTEN": [[2.0, 2.01, 3.37, 4.14, 4.94, 6.39, 7.0], [1.36, 1.36, 2.13, 2.91, 3.89, 5.74, 6.0], [1.76, 1.76, 2.82, 3.71, 4.83, 5.63, 5.63], [2.0, 2.13, 3.31, 4.22, 5.14, 6.46, 7.0], [0.5, 0.5, 1.4, 2.39, 3.28, 5.16, 5.16], [3.61, 3.61, 4.57, 5.18, 5.82, 6.65, 7.0], [1.97, 1.97, 3.19, 4.45, 5.16, 6.55, 6.55], [0.93, 0.93, 1.62, 2.0, 2.37, 3.06, 3.06], [0.82, 0.82, 2.13, 3.27, 4.26, 5.86, 6.0], [0.81, 0.81, 1.93, 2.96, 4.58, 5.55, 5.55]],
            "BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER": [[3.21, 3.21, 3.76, 4.14, 4.58, 5.43, 5.43], [1.22, 1.22, 1.77, 2.14, 2.58, 3.43, 3.43], [1.11, 1.11, 2.48, 3.31, 4.21, 6.15, 6.15], [2.57, 2.57, 3.42, 3.86, 4.23, 4.78, 4.78], [0.33, 0.33, 1.32, 2.58, 4.01, 5.39, 5.39], [3.22, 3.22, 3.77, 4.14, 4.58, 5.43, 5.43], [1.44, 1.44, 2.78, 4.1, 5.0, 6.65, 6.65], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [1.52, 1.52, 2.59, 3.27, 4.27, 5.45, 5.45], [1.07, 1.07, 2.21, 3.02, 3.98, 5.39, 5.39]],
            "BUEROBAUTEN__EDELROHBAU": [[2.61, 2.61, 3.54, 4.08, 4.67, 6.05, 6.05], [0.41, 0.41, 1.62, 2.51, 3.39, 6.03, 6.03], [1.08, 1.08, 2.25, 3.23, 4.2, 5.4, 5.4], [3.28, 3.28, 3.79, 4.15, 4.59, 5.46, 5.46], [0.44, 0.44, 1.42, 2.3, 3.11, 4.25, 4.25], [2.5, 2.5, 3.53, 4.11, 4.79, 6.19, 6.19], [2.14, 2.14, 3.22, 4.01, 4.85, 6.58, 6.58], [1.22, 1.22, 1.72, 2.04, 2.39, 3.1, 3.1], [0.43, 0.43, 1.38, 2.16, 3.01, 4.26, 4.26], [0.44, 0.44, 1.36, 2.04, 2.82, 4.24, 4.24]],
            "BUEROBAUTEN__VOLLAUSBAU": [[2.13, 2.13, 3.28, 4.06, 4.84, 6.04, 7.0], [0.59, 0.59, 1.59, 2.26, 3.06, 4.47, 5.0], [1.35, 1.35, 2.46, 3.13, 3.93, 5.27, 5.27], [1.8, 1.8, 3.08, 3.93, 4.82, 6.25, 7.0], [0.4, 0.4, 1.37, 2.16, 3.01, 4.79, 5.0], [3.08, 3.08, 3.77, 4.26, 4.88, 6.12, 6.12], [1.59, 1.59, 3.43, 4.59, 5.29, 6.64, 6.64], [1.0, 1.27, 1.77, 1.99, 2.21, 2.61, 2.61], [0.86, 0.86, 2.16, 3.1, 4.02, 5.58, 6.0], [0.83, 0.83, 2.1, 3.04, 4.13, 5.49, 5.49]],
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GAERTEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GARTENANLAGE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GEWAECHSHAEUSER": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__PAVILLONS": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TURNHALLEN_SPORTHALLEN_MEHRZWECKHALLEN": [[1.64, 1.64, 3.08, 4.17, 5.13, 6.32, 6.32], [0.49, 0.49, 1.51, 2.19, 3.17, 4.98, 5.0], [0.0, 0.59, 2.14, 3.18, 4.2, 5.65, 6.0], [0.65, 0.65, 1.96, 3.0, 4.32, 6.49, 7.0], [0.47, 0.47, 1.57, 2.42, 3.35, 5.61, 6.0], [2.0, 2.24, 3.42, 4.09, 4.74, 5.61, 5.61], [1.76, 1.76, 3.27, 4.44, 5.16, 6.46, 6.46], [1.51, 1.51, 2.4, 3.0, 3.61, 4.7, 5.0], [0.82, 0.82, 1.66, 2.19, 2.79, 3.98, 5.0], [2.19, 2.19, 3.57, 4.69, 5.41, 6.33, 6.33]],
            "FREIZEITBAUTEN_SPORTBAUTEN__BOOTSHAEUSER": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__FREIBAD": [[2.57, 2.57, 4.2, 4.83, 5.24, 5.82, 5.82], [0.58, 0.58, 1.45, 2.01, 2.56, 3.46, 3.46], [2.56, 2.56, 3.46, 4.01, 4.56, 5.45, 5.45], [0.23, 0.23, 0.69, 1.01, 1.35, 1.88, 1.88], [1.6, 1.6, 2.81, 4.99, 5.88, 6.6, 6.6], [0.59, 0.59, 3.36, 4.26, 5.19, 6.36, 6.36], [0.54, 0.54, 1.85, 2.89, 3.45, 4.78, 4.78], [2.52, 2.52, 3.45, 4.13, 4.91, 6.74, 7.0], [0.64, 0.64, 1.84, 3.0, 3.87, 4.64, 4.64], [0.32, 0.32, 0.9, 1.41, 2.41, 4.44, 4.44]],
            "FREIZEITBAUTEN_SPORTBAUTEN__FREIZEITZENTREN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE": [[0.94, 0.94, 2.18, 3.02, 4.03, 5.87, 6.0], [0.63, 0.63, 1.46, 2.01, 2.56, 3.41, 3.41], [0.61, 0.61, 2.04, 3.14, 4.36, 6.26, 6.26], [0.47, 0.47, 1.32, 2.02, 2.72, 3.64, 3.64], [0.37, 0.37, 1.42, 2.19, 3.26, 5.68, 6.0], [3.18, 3.18, 3.74, 4.12, 4.55, 5.38, 5.38], [2.98, 2.98, 3.72, 4.22, 4.81, 6.49, 6.49], [4.0, 4.0, 4.69, 5.03, 5.43, 6.46, 6.46], [1.01, 1.01, 2.14, 3.11, 4.14, 5.4, 5.4], [1.39, 1.39, 2.68, 4.05, 5.0, 6.14, 6.14]],
            "FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER": [[2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [5.42, 5.42, 6.39, 7.0, 7.0, 7.0, 7.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__KINDERSPIELPLAETZE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__KUNSTEISBAHN_FREIBAD": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__PAERKE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__REITHALLEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__SKATEPARKS": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__SPORTPLAETZE": [[1.68, 1.68, 3.18, 4.47, 5.07, 5.73, 5.73], [0.7, 0.7, 1.61, 2.15, 2.74, 3.56, 3.56], [2.33, 2.33, 3.04, 3.65, 4.3, 5.33, 5.33], [0.29, 0.29, 0.84, 1.28, 1.97, 5.32, 5.32], [1.44, 1.44, 2.37, 4.65, 5.95, 6.78, 7.0], [0.72, 0.72, 3.64, 4.35, 5.11, 6.24, 6.24], [0.64, 0.64, 2.46, 3.28, 4.52, 6.87, 7.0], [2.42, 2.42, 3.28, 4.13, 5.35, 6.7, 7.0], [0.77, 0.77, 1.91, 3.02, 4.16, 5.32, 5.32], [0.4, 0.4, 1.11, 2.18, 4.18, 5.33, 5.33]],
            "FREIZEITBAUTEN_SPORTBAUTEN__STADIONANLAGEN": [[1.5, 1.5, 2.43, 3.19, 4.49, 6.21, 6.21], [0.43, 0.43, 1.17, 1.85, 2.58, 3.5, 3.5], [0.77, 0.77, 1.99, 3.01, 4.83, 6.46, 6.46], [0.43, 0.43, 1.19, 2.02, 2.83, 3.63, 3.63], [0.76, 0.76, 1.7, 2.34, 3.99, 6.2, 6.2], [3.3, 3.3, 3.97, 4.5, 5.02, 5.7, 5.7], [2.0, 3.47, 4.42, 4.99, 5.56, 6.75, 6.75], [3.75, 3.75, 5.19, 5.78, 6.19, 6.72, 6.72], [1.3, 1.3, 2.0, 3.07, 4.5, 5.5, 5.5], [1.3, 1.3, 2.0, 3.5, 5.0, 5.7, 5.7]],
            "FREIZEITBAUTEN_SPORTBAUTEN__TRIBUENENBAUTEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__ZOO_GAERTEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "FREIZEITBAUTEN_SPORTBAUTEN__ZOO_TIERHAEUSER": [[2.12, 2.12, 2.66, 3.0, 3.34, 3.88, 3.88], [1.3, 1.3, 1.98, 2.5, 3.03, 3.7, 3.7], [2.3, 2.3, 2.97, 3.5, 4.03, 4.7, 4.7], [2.3, 2.3, 3.0, 4.0, 5.0, 5.7, 5.7], [1.22, 1.22, 1.84, 2.34, 6.42, 6.92, 7.0], [3.3, 3.3, 3.97, 4.5, 5.02, 5.7, 5.7], [2.14, 2.14, 2.69, 3.04, 3.42, 5.5, 5.5], [1.3, 1.3, 2.0, 3.0, 4.0, 4.7, 4.7], [0.23, 0.23, 0.69, 1.01, 1.35, 1.88, 1.88], [0.0, 0.08, 0.58, 2.66, 3.16, 3.78, 3.78]],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HERBERGEN": [[1.22, 1.22, 1.84, 2.34, 3.66, 4.59, 4.59], [2.36, 2.36, 3.11, 3.76, 4.58, 6.87, 7.0], [0.0, 0.13, 2.42, 3.34, 4.84, 5.64, 5.64], [2.22, 2.22, 2.84, 3.33, 5.64, 6.54, 6.54], [1.41, 1.41, 2.34, 4.66, 5.16, 5.78, 5.78], [3.22, 3.22, 3.83, 4.28, 4.81, 5.59, 5.59], [3.3, 3.3, 3.97, 4.5, 5.02, 6.03, 6.03], [3.41, 3.41, 4.24, 4.99, 5.74, 6.54, 6.54], [2.41, 2.41, 3.34, 4.66, 5.16, 5.78, 5.78], [2.41, 2.41, 3.24, 4.0, 4.76, 5.59, 5.59]],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HOTELBAUTEN": [[1.03, 1.03, 2.09, 2.99, 4.02, 5.32, 5.32], [1.67, 1.67, 2.49, 2.9, 3.28, 3.84, 3.84], [0.51, 0.51, 1.3, 1.91, 2.51, 3.42, 3.42], [1.45, 1.45, 2.29, 2.9, 3.51, 4.42, 4.42], [0.84, 0.84, 2.2, 3.43, 4.83, 6.17, 6.17], [3.55, 3.55, 4.41, 4.83, 5.16, 5.6, 5.6], [2.0, 2.02, 3.73, 4.44, 5.17, 6.54, 6.54], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [2.64, 2.64, 3.47, 4.0, 4.53, 5.36, 5.36], [1.92, 1.92, 3.18, 3.83, 4.4, 5.32, 5.32]],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__KANTINEN": [[1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58]],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__MOTELS": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__RASTSTAETTEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__RESTAURANT": [[1.12, 1.12, 2.8, 3.88, 5.43, 6.83, 7.0], [2.2, 2.2, 2.76, 3.13, 3.57, 4.41, 4.41], [2.24, 2.24, 2.82, 3.26, 4.02, 5.4, 5.4], [2.34, 2.34, 3.32, 4.11, 4.85, 5.72, 5.72], [3.2, 3.2, 3.76, 4.13, 4.57, 5.41, 5.41], [2.35, 2.35, 3.95, 4.81, 5.62, 6.44, 6.44], [2.35, 2.35, 3.74, 4.36, 5.1, 6.45, 6.45], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [1.51, 1.51, 3.28, 4.71, 5.76, 6.6, 6.6], [1.51, 1.51, 3.28, 4.71, 5.76, 6.6, 6.6]],
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__SCHIRMHUETTEN": [[0.0, 0.13, 1.43, 2.34, 3.84, 4.64, 4.64], [5.97, 5.97, 6.42, 6.66, 6.84, 6.97, 7.0], [1.36, 1.36, 2.16, 3.66, 4.58, 6.87, 7.0], [1.22, 1.22, 1.83, 2.28, 2.81, 3.59, 3.59], [0.3, 0.3, 0.86, 1.36, 3.68, 4.59, 4.59], [4.22, 4.22, 4.83, 5.27, 5.8, 6.54, 6.54], [0.0, 0.05, 0.29, 0.62, 1.05, 2.03, 2.03], [1.41, 1.41, 2.33, 4.96, 5.74, 6.54, 6.54], [4.13, 4.13, 4.66, 5.0, 5.34, 5.88, 5.88], [1.22, 1.22, 1.83, 2.28, 2.81, 3.59, 3.59]],
            "GESUNDHEITSBAUTEN__ARZTPRAXEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GESUNDHEITSBAUTEN__KRANKENHAEUSER_BETTENTRAKT": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GESUNDHEITSBAUTEN__KRANKENHAEUSER_Logistik": [[3.31, 3.31, 4.01, 4.54, 5.06, 5.72, 5.72], [1.12, 1.12, 1.66, 2.0, 2.34, 2.87, 2.87], [1.31, 1.31, 2.01, 2.54, 3.06, 3.72, 3.72], [1.28, 1.28, 1.94, 2.46, 2.99, 3.69, 3.69], [1.28, 1.28, 1.94, 2.46, 2.99, 3.69, 3.69], [3.28, 3.28, 3.94, 4.46, 4.99, 5.69, 5.69], [1.28, 1.28, 1.96, 2.74, 5.94, 6.85, 6.85], [1.28, 1.28, 1.96, 2.74, 5.94, 6.62, 6.62], [1.32, 1.32, 2.04, 3.24, 4.04, 4.72, 4.72], [2.31, 2.31, 3.01, 3.54, 4.06, 4.72, 4.72]],
            "GESUNDHEITSBAUTEN__KRANKENHAEUSER_Untersuchung": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GESUNDHEITSBAUTEN__TAGESHEIME": [[1.66, 1.66, 2.49, 3.0, 3.51, 4.34, 4.34], [0.69, 0.69, 1.49, 2.0, 2.51, 3.34, 3.34], [0.0, 0.32, 1.88, 3.28, 4.77, 6.04, 6.04], [1.05, 1.05, 2.41, 3.1, 3.92, 6.04, 6.04], [0.45, 0.45, 1.36, 2.04, 2.8, 4.22, 4.22], [2.53, 2.53, 3.45, 4.08, 4.71, 5.56, 5.56], [1.26, 1.26, 2.37, 3.07, 3.87, 5.76, 5.76], [0.89, 0.89, 1.69, 2.23, 2.85, 4.03, 4.03], [1.76, 1.76, 3.03, 3.88, 4.68, 5.79, 5.79], [0.88, 0.88, 2.2, 3.16, 4.19, 6.11, 6.11]],
            "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN_FORSCHUNG": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GESUNDHEIT__HEILBAEDER": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GESUNDHEIT__PFLEGEHEIME": [[1.61, 1.61, 3.47, 4.52, 5.35, 6.45, 7.0], [1.33, 1.33, 2.17, 2.82, 3.51, 4.7, 5.0], [0.43, 0.43, 1.57, 2.47, 3.52, 5.37, 6.0], [2.41, 2.41, 3.28, 3.94, 4.61, 5.54, 5.54], [1.71, 1.71, 2.7, 3.4, 4.18, 5.73, 6.0], [2.92, 2.92, 4.25, 4.92, 5.51, 6.35, 6.35], [2.45, 2.45, 3.76, 4.49, 5.14, 6.27, 6.27], [2.8, 2.8, 4.07, 4.71, 5.23, 5.91, 6.0], [2.18, 2.18, 3.7, 4.61, 5.32, 6.23, 6.23], [2.4, 2.4, 3.34, 3.99, 4.66, 5.77, 6.0]],
            "GESUNDHEIT__REHABILITATIONSZENTREN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "GESUNDHEIT__SANATORIEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "JUSTIZ__GERICHT": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "JUSTIZ__POLIZEI": [[2.12, 2.12, 2.66, 3.0, 3.34, 3.88, 3.88], [3.29, 3.29, 3.99, 4.95, 5.98, 6.64, 6.64], [2.3, 2.3, 2.97, 3.5, 4.03, 4.7, 4.7], [2.3, 2.3, 2.97, 3.5, 4.03, 4.7, 4.7], [3.3, 3.3, 3.97, 4.5, 5.02, 5.7, 5.7], [5.12, 5.12, 5.65, 5.99, 6.31, 6.77, 6.77], [3.14, 3.14, 3.69, 4.04, 4.42, 6.45, 6.45], [1.29, 1.29, 1.99, 3.14, 5.98, 6.64, 6.64], [2.12, 2.12, 2.66, 3.0, 3.34, 3.88, 3.88], [2.3, 2.3, 2.97, 3.5, 4.03, 4.7, 4.7]],
            "JUSTIZ__STRAFVOLLZUGSANSTALTEN": [[2.0, 2.0, 3.29, 4.11, 4.91, 6.3, 7.0], [0.4, 0.4, 1.42, 2.25, 3.24, 4.95, 5.0], [2.81, 2.81, 3.71, 4.34, 5.05, 6.4, 7.0], [0.55, 0.55, 1.46, 2.07, 2.74, 3.98, 4.0], [0.76, 0.76, 2.1, 3.16, 4.41, 6.27, 6.27], [2.41, 2.41, 3.54, 4.22, 4.9, 6.06, 7.0], [2.0, 2.49, 3.47, 3.97, 4.45, 5.79, 5.79], [2.75, 2.75, 3.55, 4.04, 4.55, 5.37, 5.37], [1.83, 1.83, 3.17, 4.07, 4.86, 5.87, 5.87], [1.78, 1.78, 2.59, 3.09, 3.64, 4.83, 5.0]],
            "JUSTIZ__WIEDEREINGLIEDERUNGSSTAETTEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__AUSSTELLUNGEN": [[1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58]],
            "KULTUR__DISCO": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__FESTHALLEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__KINO": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__KONGRESS": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__KONZERTE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__KULTUR_GEMEINSCHAFTSZENTREN": [[1.83, 1.83, 3.08, 3.77, 4.37, 5.29, 5.29], [0.61, 0.61, 1.43, 1.86, 2.24, 2.78, 2.78], [0.63, 0.63, 1.55, 2.12, 2.81, 4.22, 4.22], [1.71, 1.71, 2.63, 3.23, 3.92, 5.17, 5.17], [0.66, 0.66, 2.52, 4.18, 5.12, 6.33, 6.33], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [3.0, 3.13, 3.67, 4.0, 4.32, 5.12, 5.12], [0.63, 0.63, 1.55, 2.12, 2.81, 4.22, 4.22], [0.52, 0.52, 1.68, 2.8, 4.3, 5.73, 5.73], [1.07, 1.07, 2.2, 3.02, 3.99, 5.4, 5.4]],
            "KULTUR__MUSEEN": [[3.08, 3.08, 4.75, 5.48, 6.22, 6.86, 7.0], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [0.52, 0.52, 1.84, 2.88, 4.77, 6.78, 7.0], [0.86, 0.86, 2.04, 3.07, 4.46, 5.65, 5.65], [2.16, 2.16, 2.73, 3.11, 3.53, 4.35, 4.35], [3.08, 3.08, 4.75, 5.48, 6.22, 6.86, 7.0], [3.61, 3.61, 4.77, 5.52, 6.02, 6.72, 6.72], [1.65, 1.65, 2.47, 2.89, 3.27, 3.84, 3.84], [1.43, 1.43, 2.53, 3.19, 4.04, 5.37, 5.37], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58]],
            "KULTUR__PAVILLONS": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__RADIO_FERNSEH_FILM": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUR__SAAL": [[3.16, 3.16, 3.73, 4.1, 4.52, 5.34, 5.34], [0.68, 0.68, 1.48, 1.9, 2.27, 2.84, 2.84], [0.58, 0.58, 1.57, 2.21, 3.08, 4.38, 4.38], [1.62, 1.62, 2.94, 3.81, 4.47, 5.57, 5.57], [2.16, 2.16, 2.73, 3.1, 3.52, 4.34, 4.34], [3.16, 3.16, 3.73, 4.1, 4.52, 5.34, 5.34], [3.16, 3.16, 3.73, 4.1, 4.52, 5.76, 5.76], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.26, 2.03, 2.8, 3.74, 3.74], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58]],
            "KULTUR__WOHLFAHRT": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUS__ABDANKUNG": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUS__FRIEDHOF": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUS__KAPELLE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUS__KIRCHE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUS__KIRCHGEMEINDEHAEUSER": [[3.26, 3.26, 3.74, 4.03, 4.34, 5.0, 5.0], [0.77, 0.77, 1.6, 2.12, 2.71, 3.94, 4.0], [1.0, 1.0, 1.72, 2.23, 2.81, 3.93, 4.0], [0.7, 0.7, 2.01, 3.02, 4.05, 5.44, 5.44], [0.79, 0.79, 1.96, 2.9, 4.15, 6.09, 6.09], [3.17, 3.17, 3.72, 4.08, 4.47, 5.27, 5.27], [1.33, 1.33, 2.41, 3.17, 3.95, 5.62, 5.62], [0.67, 0.67, 1.61, 2.23, 2.98, 4.27, 4.27], [1.13, 1.13, 2.32, 3.1, 4.07, 5.41, 5.41], [1.85, 1.85, 3.29, 4.07, 4.79, 5.8, 5.8]],
            "KULTUS__KLOESTER": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "KULTUS__KREMATORIUM": [[3.3, 3.3, 3.97, 4.5, 5.02, 5.7, 5.7], [3.12, 3.12, 3.66, 4.0, 4.34, 4.88, 4.88], [4.29, 4.29, 4.97, 5.49, 6.01, 6.64, 6.64], [0.23, 0.23, 0.69, 1.01, 1.35, 1.88, 1.88], [4.13, 4.13, 4.66, 5.0, 5.34, 5.88, 5.88], [4.22, 4.22, 4.84, 5.34, 6.43, 6.92, 7.0], [1.32, 1.32, 2.06, 5.31, 6.04, 6.87, 6.87], [2.12, 2.12, 2.66, 3.0, 3.34, 3.88, 3.88], [3.12, 3.12, 3.66, 4.0, 4.34, 4.88, 4.88], [2.3, 2.3, 2.97, 3.5, 4.03, 4.7, 4.7]],
            "LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL": [[2.59, 2.59, 3.43, 3.87, 4.25, 4.8, 4.8], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.0, 0.19, 1.18, 1.89, 2.52, 3.58, 3.58], [1.76, 1.76, 2.54, 2.93, 3.28, 3.83, 3.83], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.17, 2.17, 2.72, 3.07, 3.46, 4.24, 4.24], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [0.61, 0.61, 1.55, 2.15, 2.88, 4.27, 4.27], [1.17, 1.17, 1.72, 2.07, 2.46, 3.24, 3.24], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58]],
            "LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL": [[2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58]],
            "LADENBAUTEN_HANDELSBAUTEN__GROSSMAERKTE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "LADENBAUTEN_HANDELSBAUTEN__MARKTHALLEN": [[2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58]],
            "LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER": [[3.11, 3.11, 4.74, 5.46, 6.2, 6.86, 7.0], [2.22, 2.22, 2.82, 3.26, 3.98, 5.36, 5.36], [0.86, 0.86, 2.0, 2.96, 4.34, 5.62, 5.62], [3.17, 3.17, 3.74, 4.12, 4.55, 5.38, 5.38], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [3.11, 3.11, 4.74, 5.46, 6.2, 6.86, 7.0], [2.2, 2.2, 2.99, 3.6, 4.39, 6.14, 6.14], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [3.34, 3.34, 4.21, 4.72, 5.12, 5.6, 5.6], [0.65, 0.65, 1.92, 2.99, 4.86, 6.42, 6.42]],
            "LAGERBAUTEN_MEHRSTOECKIGE": [[1.61, 1.61, 3.27, 4.11, 5.1, 6.76, 7.0], [0.67, 0.67, 1.48, 1.89, 2.27, 2.84, 2.84], [0.0, 0.13, 0.76, 1.5, 2.24, 3.92, 3.92], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.22, 0.22, 0.96, 1.85, 3.56, 5.05, 5.05], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.4, 0.4, 0.87, 1.25, 1.75, 3.19, 3.19], [0.4, 0.4, 0.87, 1.25, 1.75, 2.65, 2.65], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58]],
            "LAGERBAUTEN_PRODUKTIONSBAUTEN": [[1.07, 1.07, 2.22, 3.11, 4.1, 5.39, 5.39], [0.55, 0.55, 1.47, 2.09, 2.75, 4.0, 4.0], [1.16, 1.16, 1.73, 2.1, 2.52, 3.34, 3.34], [1.33, 1.33, 2.54, 3.28, 4.09, 5.78, 6.0], [0.0, 0.28, 1.17, 1.93, 2.77, 4.23, 4.23], [1.9, 1.9, 2.71, 3.26, 3.91, 5.15, 5.15], [1.36, 1.36, 2.67, 4.33, 5.2, 6.63, 6.63], [0.66, 0.66, 1.45, 1.95, 2.44, 3.23, 3.23], [0.44, 0.44, 0.9, 1.3, 1.81, 2.66, 2.66], [0.37, 0.37, 0.93, 1.44, 2.06, 3.16, 3.16]],
            "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN": [[0.81, 0.81, 2.03, 2.83, 3.57, 4.68, 4.68], [0.32, 0.32, 1.13, 1.89, 2.83, 4.88, 5.0], [0.0, 0.1, 0.68, 1.66, 2.87, 4.83, 4.83], [0.0, 0.12, 0.75, 1.62, 2.55, 4.45, 4.45], [0.0, 0.07, 0.42, 0.94, 1.77, 3.58, 3.58], [1.49, 1.49, 2.3, 2.87, 3.43, 4.29, 4.29], [0.0, 0.03, 0.18, 0.39, 0.73, 1.89, 1.89], [0.0, 0.22, 0.74, 1.18, 1.72, 2.88, 2.88], [0.0, 0.22, 0.74, 1.18, 1.72, 2.88, 2.88], [0.0, 0.03, 0.17, 0.37, 0.67, 1.37, 1.37]],
            "LAGERBAUTEN__INDUSTRIEHALLEN": [[1.16, 1.16, 1.73, 2.11, 2.53, 3.35, 3.35], [0.51, 0.51, 0.93, 1.31, 1.81, 2.62, 2.62], [0.0, 0.23, 1.18, 2.08, 3.18, 4.62, 4.62], [0.43, 0.43, 0.85, 1.17, 1.58, 2.43, 2.43], [0.21, 0.21, 0.92, 1.63, 2.69, 4.64, 4.64], [1.66, 1.66, 2.48, 2.99, 3.5, 4.32, 4.32], [0.0, 0.31, 1.36, 1.99, 2.61, 4.47, 4.47], [0.0, 0.2, 0.76, 1.25, 1.86, 3.11, 3.11], [0.69, 0.69, 1.49, 2.01, 2.52, 3.35, 3.35], [0.0, 0.31, 0.78, 1.1, 1.48, 2.28, 2.28]],
            "LAGERBAUTEN__MECHANISIERTE_LAGER_KUEHLLAGER": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "LAGERBAUTEN__SILOBAUTEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "LAGERBAUTEN__VERTEILZENTRALEN": [[2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58]],
            "LANDWIRTSCHAFT_STALLUNGEN": [[0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "LANDWIRTSCHAFT__LANDWIRTSCHAFTLICHE_ANLAGEN": [[0.42438253587304664, 0.42438253587304664, 1.3929592248235263, 2, 2.6070407751764737, 3.5756174641269522, 3.5756174641269522], [0.42438253587304664, 0.42438253587304664, 1.3929592248235263, 2, 2.6070407751764737, 3.5756174641269522, 3.5756174641269522], [0, 0, 0.3929592248235264, 1, 1.6070407751764737, 2.5756174641269522, 2.5756174641269522], [0, 0, 0.3929592248235264, 1, 1.6070407751764737, 2.5756174641269522, 2.5756174641269522], [0, 0, 0.3929592248235264, 1, 1.6070407751764737, 2.5756174641269522, 2.5756174641269522], [0.42438253587304664, 0.42438253587304664, 1.3929592248235263, 2, 2.6070407751764737, 3.5756174641269522, 3.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0.3929592248235264, 1, 1.6070407751764737, 2.5756174641269522, 2.5756174641269522], [0, 0, 0.3929592248235264, 1, 1.6070407751764737, 2.5756174641269522, 2.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522]],
            "LANDWIRTSCHAFT__SCHEUNEN": [[1.16, 1.16, 1.73, 2.1, 2.52, 3.34, 3.34], [0.4, 0.4, 0.87, 1.26, 1.76, 2.65, 2.65], [0.0, 0.14, 0.77, 1.51, 2.25, 3.92, 3.92], [0.4, 0.4, 0.87, 1.26, 1.76, 2.65, 2.65], [0.0, 0.04, 0.26, 0.57, 1.0, 1.81, 1.81], [0.4, 0.4, 0.87, 1.26, 1.76, 2.65, 2.65], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.04, 0.26, 0.57, 1.0, 1.81, 1.81], [0.0, 0.04, 0.26, 0.57, 1.0, 1.81, 1.81], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "LANDWIRTSCHAFT__SCHLACHTHOEFE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "LANDWIRTSCHAFT__TIERHEIME": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "LANDWIRTSCHAFT__TIERSPITAELER": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "SCHULBAUTEN__BERUFSSCHULEN": [[0.92, 0.92, 2.2, 3.16, 4.08, 5.59, 7.0], [1.17, 1.17, 2.1, 2.82, 3.66, 5.12, 5.12], [0.0, 0.39, 2.12, 3.44, 4.89, 6.34, 6.34], [1.84, 1.84, 3.27, 4.17, 4.99, 6.24, 7.0], [0.83, 0.83, 2.2, 3.17, 4.29, 5.54, 5.54], [2.97, 2.97, 3.71, 4.22, 4.79, 5.86, 6.0], [1.0, 1.35, 3.19, 4.6, 5.53, 6.81, 6.81], [1.19, 1.19, 1.72, 2.06, 2.43, 3.2, 3.2], [1.83, 1.83, 3.04, 3.9, 4.86, 6.2, 6.2], [1.15, 1.15, 2.34, 3.11, 3.96, 5.52, 6.0]],
            "SCHULBAUTEN__BIBLIOTHEKEN": [[1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.88, 0.88, 2.11, 3.2, 4.56, 5.66, 5.66], [2.32, 2.32, 3.29, 4.09, 4.84, 5.72, 5.72], [0.0, 0.2, 0.72, 1.08, 1.51, 2.44, 2.44], [3.17, 3.17, 3.72, 4.08, 4.47, 5.26, 5.26], [1.46, 1.46, 2.89, 4.18, 5.04, 6.41, 6.41], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [1.17, 1.17, 1.72, 2.08, 2.47, 3.26, 3.26], [1.28, 1.28, 2.16, 2.91, 3.71, 4.68, 4.68]],
            "SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR": [[1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [4.42, 4.42, 5.39, 6.0, 6.61, 7.0, 7.0], [4.42, 4.42, 5.39, 6.0, 6.61, 7.0, 7.0], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58]],
            "SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR": [[1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.16, 1.16, 1.73, 2.1, 2.52, 3.34, 3.34], [0.71, 0.71, 2.07, 2.74, 3.19, 3.79, 3.79], [0.71, 0.71, 2.07, 2.74, 3.19, 3.79, 3.79], [0.29, 0.29, 1.18, 2.4, 4.31, 5.63, 5.63], [3.35, 3.35, 4.24, 4.74, 5.13, 5.61, 5.61], [0.73, 0.73, 2.77, 3.96, 5.04, 6.44, 6.44], [1.16, 1.16, 1.73, 2.1, 2.52, 3.34, 3.34], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58]],
            "SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN": [[3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58]],
            "SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN": [[0.75, 0.75, 2.14, 3.16, 4.3, 6.22, 7.0], [0.53, 0.53, 1.58, 2.29, 3.13, 4.85, 5.0], [0.0, 0.51, 1.95, 3.11, 4.57, 6.03, 6.03], [2.0, 2.09, 3.27, 4.07, 4.82, 5.76, 5.76], [0.85, 0.85, 2.29, 3.25, 4.25, 5.71, 6.0], [2.47, 2.47, 3.57, 4.36, 5.29, 6.39, 6.39], [1.55, 1.55, 3.46, 4.58, 5.38, 6.76, 6.76], [0.97, 0.97, 1.66, 2.11, 2.61, 3.57, 4.0], [1.0, 1.29, 2.96, 4.07, 4.95, 6.07, 6.07], [0.96, 0.96, 2.17, 3.07, 4.17, 5.73, 6.0]],
            "SCHULBAUTEN__KINDERGAERTEN_HORTE": [[1.2, 1.2, 2.34, 3.16, 4.1, 5.52, 6.0], [0.87, 0.87, 1.73, 2.32, 2.94, 3.95, 4.0], [0.0, 0.41, 1.94, 2.96, 4.17, 5.83, 6.0], [1.43, 1.43, 2.35, 3.02, 3.71, 4.72, 5.0], [0.87, 0.87, 2.22, 3.4, 4.39, 5.83, 7.0], [2.0, 2.23, 3.41, 4.12, 4.85, 6.04, 6.04], [1.59, 1.59, 3.16, 4.42, 5.2, 6.62, 6.62], [1.47, 1.47, 2.33, 2.92, 3.51, 4.55, 5.0], [0.0, 1.48, 3.02, 4.07, 5.05, 6.28, 6.28], [0.91, 0.91, 2.32, 3.24, 4.26, 6.08, 6.08]],
            "SCHULBAUTEN__VOLKSSCHULEN": [[1.25, 1.25, 2.64, 3.57, 4.52, 6.23, 7.0], [0.6, 0.6, 1.69, 2.47, 3.33, 4.94, 5.0], [0.0, 0.72, 2.06, 3.14, 4.34, 5.8, 6.0], [1.0, 1.19, 2.64, 3.52, 4.55, 6.03, 6.03], [0.7, 0.7, 2.01, 3.03, 4.12, 5.58, 6.0], [2.85, 2.85, 3.78, 4.41, 5.05, 6.05, 6.05], [1.0, 1.39, 2.87, 4.1, 4.99, 6.5, 6.5], [1.09, 1.09, 1.73, 2.16, 2.67, 3.67, 4.0], [1.85, 1.85, 3.11, 4.12, 4.99, 6.06, 6.06], [1.36, 1.36, 2.65, 3.53, 4.61, 5.83, 7.0]],
            "SCHUTZBAUTEN__AUSBILDUNGSZENTREN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "SCHUTZBAUTEN__BRAND_ZIVILSCHUTZSIMULATION": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "SCHUTZBAUTEN__FEUERWEHRGEBAEUDE": [[3.35, 3.35, 4.24, 4.74, 5.13, 5.61, 5.61], [0.0, 0.25, 1.92, 2.9, 3.73, 5.39, 5.39], [0.0, 0.17, 1.18, 2.31, 3.14, 4.48, 4.48], [2.16, 2.16, 2.73, 3.1, 3.52, 4.34, 4.34], [0.38, 0.38, 1.62, 2.53, 4.44, 6.39, 6.39], [3.21, 3.21, 3.81, 4.26, 4.93, 6.29, 6.29], [2.6, 2.6, 3.43, 3.87, 4.26, 5.12, 5.12], [0.86, 0.86, 2.03, 3.05, 4.44, 5.65, 5.65], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.42, 0.42, 1.26, 2.03, 2.8, 3.74, 3.74]],
            "SCHUTZBAUTEN__GESCHUETZTE_OPERATIONSSTELLEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "SCHUTZBAUTEN__KASERNEN": [[0.94, 0.94, 2.55, 4.4, 5.51, 6.77, 7.0], [1.51, 1.51, 2.39, 3.0, 3.61, 4.49, 4.49], [1.19, 1.19, 1.72, 2.06, 2.43, 3.2, 3.2], [1.8, 1.8, 2.56, 2.94, 3.28, 3.81, 3.81], [0.54, 0.54, 1.87, 3.04, 4.26, 5.68, 5.68], [2.37, 2.37, 3.36, 4.14, 4.85, 5.71, 5.71], [1.01, 1.01, 1.91, 2.56, 3.51, 5.74, 5.74], [2.51, 2.51, 3.39, 4.0, 4.61, 5.49, 5.49], [1.19, 1.19, 1.72, 2.06, 2.43, 3.2, 3.2], [0.47, 0.47, 1.58, 2.37, 3.26, 5.19, 5.19]],
            "SCHUTZBAUTEN__SANITAET": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "SCHUTZBAUTEN__SCHULUNGSBAUTEN": [[1.07, 1.07, 2.22, 3.2, 4.18, 5.39, 5.39], [1.19, 1.19, 1.72, 2.06, 2.43, 3.18, 3.18], [0.68, 0.68, 2.07, 3.41, 5.36, 6.5, 6.5], [0.59, 0.59, 1.75, 2.73, 3.42, 4.48, 4.48], [0.4, 0.4, 1.33, 2.78, 4.42, 5.87, 5.87], [2.71, 2.71, 3.63, 4.22, 4.9, 6.13, 6.13], [0.9, 0.9, 1.86, 2.54, 3.6, 6.01, 6.01], [0.63, 0.63, 1.55, 2.12, 2.78, 4.2, 4.2], [1.36, 1.36, 2.4, 3.7, 4.81, 6.06, 6.06], [0.91, 0.91, 1.97, 2.81, 4.07, 5.5, 5.5]],
            "SCHUTZBAUTEN__ZEUGHAEUSER": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "SCHUTZBAUTEN__ZIVILSCHUTZANLAGEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "TECHNISCHE_ANLAGEN__HEIZZENTRALE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "TECHNISCHE_ANLAGEN__KEHRICHTVERBRENNUNGSANLAGEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "TECHNISCHE_ANLAGEN__SONDERABFALLSAMMELSTELLE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "TECHNISCHE_ANLAGEN__TANKANLAGEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522]],
            "TECHNISCHE_ANLAGEN__UNTERWERK": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "TECHNISCHE_ANLAGEN__WASSERAUFBEREITUNGSANLAGEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__BAHNBETRIEB": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__BAHNHOEFE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__BUSBAHNHOF": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__EISENBAHN": [[2.29, 2.29, 2.99, 4.14, 5.98, 6.64, 6.64], [1.3, 1.3, 2.0, 3.0, 4.0, 4.7, 4.7], [0.0, 0.08, 0.58, 3.66, 4.16, 4.78, 4.78], [3.22, 3.22, 3.84, 4.34, 6.42, 6.92, 7.0], [1.3, 1.3, 1.98, 2.5, 3.03, 3.7, 3.7], [3.29, 3.29, 3.99, 4.95, 5.98, 6.64, 6.64], [3.3, 3.3, 3.97, 4.5, 5.02, 6.03, 6.03], [1.3, 1.3, 1.98, 2.5, 3.03, 3.7, 3.7], [1.12, 1.12, 1.66, 2.0, 2.34, 2.87, 2.87], [1.3, 1.3, 1.98, 2.5, 3.03, 3.7, 3.7]],
            "VERKEHRSBAUTEN__FLUGHAEFEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__FLUGHAEFEN_BETRIEB": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__FLUGHAEFEN_FRACHT": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__FLUGHAEFEN_WERFT": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__FUSSGAENGERZONEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__GARAGEN_FREISTEHEND": [[2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58], [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58]],
            "VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH": [[2.19, 2.19, 3.37, 4.31, 5.2, 6.32, 6.32], [3.36, 3.36, 3.85, 4.21, 4.74, 5.94, 6.0], [4.34, 4.34, 5.2, 5.71, 6.11, 6.58, 6.58], [0.0, 0.04, 0.27, 0.59, 1.03, 1.81, 1.81], [1.22, 1.22, 1.82, 2.25, 2.89, 4.24, 4.24], [1.99, 1.99, 2.65, 2.97, 3.27, 3.74, 3.74], [0.41, 0.41, 0.87, 1.25, 1.74, 3.15, 3.15], [0.42, 0.42, 0.89, 1.29, 1.8, 2.66, 2.66], [1.0, 1.0, 1.65, 1.97, 2.27, 2.74, 2.74], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58]],
            "VERKEHRSBAUTEN__POSTBETRIEBE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__POSTGEBAEUDE": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__RECHENZENTREN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__SEILBAHNEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__STRASSENVERKEHR": [[2.3, 2.3, 3.0, 4.0, 5.0, 5.7, 5.7], [1.3, 1.3, 1.98, 2.5, 3.03, 3.7, 3.7], [0.0, 0.07, 0.56, 5.64, 6.14, 6.7, 6.7], [1.3, 1.3, 2.0, 3.0, 4.0, 4.7, 4.7], [1.12, 1.12, 1.66, 2.0, 2.34, 2.87, 2.87], [5.22, 5.22, 5.82, 6.23, 6.61, 6.94, 7.0], [4.14, 4.14, 4.69, 5.04, 5.41, 6.49, 6.49], [2.12, 2.12, 2.66, 3.0, 3.34, 3.88, 3.88], [2.3, 2.3, 2.97, 3.5, 4.03, 4.7, 4.7], [2.3, 2.3, 2.97, 3.5, 4.03, 4.7, 4.7]],
            "VERKEHRSBAUTEN__TANKSTELLE": [[0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522], [0, 0, 0, 0, 0.6070407751764736, 1.5756174641269522, 1.5756174641269522]],
            "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN": [[2.67, 2.67, 3.48, 3.9, 4.27, 4.84, 4.84], [2.16, 2.16, 2.73, 3.11, 3.53, 4.35, 4.35], [0.56, 0.56, 1.98, 3.19, 4.52, 5.75, 5.75], [0.0, 0.1, 0.73, 2.07, 3.45, 5.12, 5.12], [0.68, 0.68, 1.55, 2.09, 2.7, 4.1, 4.1], [0.74, 0.74, 1.61, 2.17, 2.81, 4.06, 4.06], [0.21, 0.21, 0.91, 1.61, 2.6, 5.78, 5.78], [0.24, 0.24, 1.06, 1.98, 3.75, 6.33, 6.33], [0.22, 0.22, 1.0, 1.82, 3.22, 5.55, 5.55], [0.41, 0.41, 0.87, 1.27, 1.77, 2.66, 2.66]],
            "VERKEHRSBAUTEN__UEBERDACHUNGEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__VERKEHRSPAVILLONS": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "VERKEHRSBAUTEN__WERKHOEFE": [[1.79, 1.79, 3.57, 4.52, 5.32, 6.31, 6.31], [0.66, 0.66, 1.51, 2.06, 2.62, 3.65, 4.0], [0.0, 0.44, 2.05, 3.21, 4.24, 5.95, 6.0], [1.5, 1.5, 2.43, 3.03, 3.65, 4.71, 5.0], [0.65, 0.65, 1.52, 2.09, 2.69, 3.7, 4.0], [1.78, 1.78, 2.57, 3.04, 3.53, 4.34, 4.34], [0.0, 0.46, 1.53, 2.2, 2.93, 4.67, 4.67], [0.99, 0.99, 1.65, 2.06, 2.51, 3.47, 4.0], [0.0, 0.26, 0.93, 1.54, 2.21, 3.42, 3.42], [0.55, 0.55, 1.45, 2.01, 2.6, 3.94, 5.0]],
            "VERKEHRSBAUTEN__ZOLL": [[2.37, 2.37, 3.36, 4.14, 4.85, 5.71, 5.71], [1.19, 1.19, 1.72, 2.06, 2.43, 3.2, 3.2], [0.0, 0.25, 1.7, 2.98, 4.98, 6.48, 6.48], [1.57, 1.57, 2.42, 2.86, 3.23, 3.78, 3.78], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.75, 1.75, 3.75, 4.72, 5.72, 6.7, 7.0], [4.62, 4.62, 5.48, 5.87, 6.2, 6.82, 6.82], [1.22, 1.22, 1.77, 2.14, 2.58, 3.43, 3.43], [0.81, 0.81, 2.86, 3.85, 4.6, 6.05, 6.05], [2.57, 2.57, 3.42, 3.86, 4.23, 4.78, 4.78]],
            "VERWALTUNGSBAUTEN": [[1.6, 1.6, 3.32, 4.52, 5.47, 6.47, 6.47], [0.44, 0.44, 1.6, 2.4, 3.41, 5.71, 6.0], [0.91, 0.91, 2.26, 3.25, 4.29, 6.19, 6.19], [2.25, 2.25, 3.39, 4.23, 5.07, 6.24, 6.24], [0.66, 0.66, 1.95, 3.08, 4.23, 6.0, 6.0], [3.33, 3.33, 4.48, 5.01, 5.51, 6.45, 7.0], [1.9, 1.9, 3.37, 4.29, 5.41, 6.79, 6.79], [0.76, 0.76, 1.57, 2.09, 2.63, 3.45, 3.45], [1.76, 1.76, 2.67, 3.27, 3.98, 5.23, 5.23], [1.16, 1.16, 2.4, 3.24, 4.08, 5.77, 6.0]],
            "WOHNBAUTEN_BEHELFSWOHNUNGEN": [[0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58], [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58]],
            "WOHNBAUTEN__ALTERSHEIME": [[1.99, 1.99, 3.8, 4.7, 5.46, 6.5, 7.0], [1.46, 1.46, 2.31, 2.92, 3.53, 4.43, 4.43], [0.47, 0.47, 1.62, 2.43, 3.36, 5.33, 6.0], [1.5, 1.5, 2.55, 3.17, 3.86, 5.31, 6.0], [1.72, 1.72, 2.66, 3.29, 3.95, 5.24, 6.0], [3.58, 3.58, 4.43, 4.84, 5.16, 5.6, 5.6], [2.0, 2.02, 3.88, 4.68, 5.29, 6.56, 6.56], [2.92, 2.92, 3.66, 4.14, 4.65, 5.45, 5.45], [2.46, 2.46, 3.41, 4.06, 4.72, 5.76, 6.0], [2.0, 2.48, 3.43, 3.9, 4.34, 5.01, 5.01]],
            "WOHNBAUTEN__ALTERSWOHNUNGEN": [[1.83, 1.83, 2.61, 3.1, 3.64, 4.75, 5.0], [1.67, 1.67, 2.49, 3.0, 3.5, 4.32, 4.32], [0.41, 0.41, 1.48, 2.32, 3.12, 5.19, 6.0], [1.82, 1.82, 2.59, 3.06, 3.56, 4.36, 4.36], [1.57, 1.57, 2.52, 3.13, 3.77, 4.83, 5.0], [2.92, 2.92, 3.62, 3.96, 4.27, 4.77, 4.77], [1.71, 1.71, 3.11, 3.86, 4.47, 6.08, 6.08], [3.29, 3.29, 3.8, 4.15, 4.59, 5.47, 5.47], [1.23, 1.23, 1.82, 2.25, 2.88, 4.22, 4.22], [1.46, 1.46, 1.86, 2.14, 2.56, 3.51, 3.51]],
            "WOHNBAUTEN__EFH_REIHEN": [[1.37, 1.37, 2.3, 3.02, 3.8, 5.16, 7.0], [1.0, 1.27, 2.33, 2.92, 3.5, 5.13, 6.0], [0.0, 0.26, 1.38, 2.3, 3.1, 4.51, 5.0], [1.43, 1.43, 2.24, 2.84, 3.44, 4.55, 5.0], [1.0, 1.05, 2.56, 3.48, 4.16, 5.25, 6.0], [1.0, 1.4, 2.47, 3.14, 3.86, 5.11, 6.0], [1.72, 1.72, 2.91, 3.7, 4.39, 6.6, 7.0], [2.0, 2.5, 3.66, 4.37, 5.04, 6.03, 7.0], [0.0, 0.56, 1.74, 2.59, 3.59, 5.15, 7.0], [0.67, 0.67, 1.8, 2.6, 3.48, 5.04, 7.0]],
            "WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME": [[0.93, 0.93, 2.15, 3.11, 4.08, 5.93, 6.0], [1.0, 1.0, 2.15, 2.88, 3.57, 4.61, 4.61], [0.36, 0.36, 1.51, 2.67, 3.8, 5.87, 6.0], [1.67, 1.67, 2.53, 3.09, 3.66, 4.49, 4.49], [1.8, 1.8, 2.62, 3.15, 3.73, 4.87, 5.0], [3.03, 3.03, 4.18, 4.78, 5.27, 5.98, 6.0], [1.9, 1.9, 3.24, 4.3, 5.02, 6.0, 6.0], [2.42, 2.42, 3.72, 4.53, 5.15, 5.89, 5.89], [0.99, 0.99, 2.96, 4.2, 5.07, 6.18, 6.18], [2.5, 2.5, 3.33, 3.9, 4.46, 5.33, 5.33]],
            "WOHNBAUTEN__MFH": [[1.52, 1.52, 2.67, 3.44, 4.36, 5.81, 6.0], [1.0, 1.46, 2.46, 3.1, 3.82, 5.12, 6.0], [0.0, 0.45, 1.76, 2.74, 3.85, 5.45, 6.0], [1.56, 1.56, 2.53, 3.15, 3.87, 5.16, 5.16], [1.48, 1.48, 2.65, 3.55, 4.35, 5.61, 6.0], [1.57, 1.57, 2.44, 3.0, 3.56, 4.53, 6.0], [0.95, 0.95, 2.12, 3.23, 4.23, 5.96, 5.96], [2.0, 2.53, 3.56, 4.23, 4.89, 5.79, 6.0], [0.94, 0.94, 1.99, 2.72, 3.5, 5.07, 6.0], [0.89, 0.89, 1.89, 2.58, 3.36, 4.88, 6.0]],
            "WOHNBAUTEN__PERSONALHAEUSER": [[1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58], [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58]],
            "WOHNBAUTEN__STUDENTENWOHNHAEUSER": [[1.33, 1.33, 2.52, 3.22, 3.96, 5.75, 6.0], [2.54, 2.54, 3.38, 3.95, 4.5, 5.36, 5.36], [0.56, 0.56, 1.62, 2.4, 3.39, 4.95, 5.0], [0.89, 0.89, 2.04, 2.83, 3.62, 4.71, 4.71], [1.14, 1.14, 2.31, 3.08, 4.0, 5.38, 5.38], [1.89, 1.89, 3.17, 3.92, 4.61, 5.66, 5.66], [1.47, 1.47, 2.62, 3.77, 4.15, 4.89, 4.89], [2.63, 2.63, 3.5, 4.05, 4.62, 5.46, 5.46], [2.16, 2.16, 3.2, 3.92, 4.72, 6.14, 6.14], [1.32, 1.32, 1.81, 2.15, 2.58, 3.48, 3.48]],
            "WOHNBAUTEN__TERRASSENHAEUSER": [[2.21, 2.21, 2.72, 3.05, 3.4, 4.13, 4.13], [3.81, 3.81, 4.55, 4.88, 5.15, 5.55, 5.55], [0.97, 0.97, 1.7, 2.2, 2.76, 3.82, 4.0], [2.21, 2.21, 2.72, 3.05, 3.4, 4.13, 4.13], [3.12, 3.12, 4.22, 4.79, 5.25, 5.92, 6.0], [1.71, 1.71, 2.51, 2.91, 3.28, 3.84, 3.84], [2.0, 2.19, 3.16, 3.8, 4.43, 6.01, 6.01], [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58], [1.76, 1.76, 2.57, 3.08, 3.6, 4.42, 4.42], [1.84, 1.84, 2.62, 3.12, 3.66, 4.76, 5.0]]
        };


        return {
            getMapping: function () {
                return mapping;
            }
        };
    }

})();
