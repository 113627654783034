(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .factory("ExactPriceService", ExactPriceService);

    ExactPriceService.$inject = ["$resource"];

    function ExactPriceService($resource) {
        var service = $resource("api/exact-price", {}, {
            "init": {
                method: "POST",
                url: "api/exact-price/init"
            },
            "request": {
                method: "POST",
                url: "api/exact-price"
            },
            "get": {
                method: "GET",
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
        return service;
    }

})();
