(function () {
    'use strict';

    angular.module('kennwerteApp.agv')
        .controller('LandingPageAgvController', LandingPageAgvController);

    LandingPageAgvController.$inject = ['$scope', '$stateParams', 'Principal', '$sce', '$location', '$anchorScroll', 'GlobalModalService'];

    function LandingPageAgvController($scope, $stateParams, Principal, $sce, $location, $anchorScroll, GlobalModalService) {
        var vm = this;

        vm.isAuthenticated = false;
        vm.showBuildingCosts = null;
        vm.showInsuranceValues = null;

        vm.contactDtmModal = function(modul) {
            GlobalModalService.contactDtmModal(modul);
        }

        if ($stateParams.anchor) {
            $location.hash($stateParams.anchor);
            $anchorScroll.yOffset = 66;
            $anchorScroll();
        }

        vm.isAuthenticated = Principal.isAuthenticated();
        if (vm.isAuthenticated) {
            vm.showBuildingCosts = Principal.showBuildingCosts;
            vm.showInsuranceValues = Principal.showInsuranceValues;
            vm.hasAgv = Principal.hasModule("SMALL_BUILDING_AGV");
        } else {
            vm.showBuildingCosts = function () {return false;}
            vm.showInsuranceValues = function () {return false;}
        }

        $scope.$on('authenticationSuccess', function () {
            vm.showBuildingCosts = Principal.showBuildingCosts;
            vm.showInsuranceValues = Principal.showInsuranceValues;
            vm.hasAgv = Principal.hasModule("SMALL_BUILDING_AGV");
        });
    }

})();
