(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('LuucyConnectController', LuucyConnectController);

    LuucyConnectController.$inject = ['$scope', '$stateParams', '$window', '$state', 'Principal', 'LuucyRegisterService', 'LoginService'];

    function LuucyConnectController($scope, $stateParams, $window, $state, Principal, LuucyRegisterService, LoginService) {

        var vm = this;
        vm.hasModuleBuildingCostsAndDates = false;
        vm.validatedId = false;
        vm.isSaving = false;

        var checkDto = {'id': $stateParams.requestId};

        LuucyRegisterService.getLuucyInit(checkDto,
            function(response) {
                vm.validId = true;
                vm.validatedId = true;
                vm.luucyInit = response.data;
                if (vm.luucyInit.initState === 'CREATED') {
                    vm.successRegister = 'success';
                }
                vm.orgName = vm.luucyInit.orgName;
                vm.email = vm.luucyInit.userEmail;

                LuucyRegisterService.checkEmailAvailable(checkDto,
                    function(response) {
                        vm.isEmailUsed = !response.data.success
                    }, function(error) {
                        console.warn("Unable to check email available")
                    });
                var orgNameDto = {
                    'orgName': vm.orgName
                }
                LuucyRegisterService.checkOrgNameAvailable(orgNameDto,
                    function(response) {
                        vm.isLuucyOrgNameUsed = !response.data.success
                    }, function(error) {
                        console.warn("Unable to check org name available")
                    });
            },
            function(error) {
                vm.validId = false;
                vm.validatedId = true;
            });

        vm.isLoggedIn = Principal.isAuthenticated;

        vm.successRegister = null;

        vm.login = login;
        vm.register = register;
        vm.hasModuleBuildingCostsAndDates = hasModuleBuildingCostsAndDates;

        vm.connectLoggedIn = connectLoggedIn;
        vm.connectRegister = connectRegister;
        vm.onOrgNameBlur = onOrgNameBlur;

        function login() {
            LoginService.open();
        }

        function register() {
            var url = $state.href('register');
            $window.open(url, '_blank');
        }

        function hasModuleBuildingCostsAndDates() {
            return Principal.hasModule('BUILDING_COSTS_AND_DATES');
        }

        function onOrgNameBlur() {
            var orgNameDto = {
                'orgName': vm.orgName
            }
            LuucyRegisterService.checkOrgNameAvailable(orgNameDto,
                function(response) {
                    vm.isOrgNameUsed = !response.data.success
                }, function(error) {
                    console.warn("Unable to check email available")
                });
        }

        function connectLoggedIn() {
            vm.isSaving = true;
            var assignOrgToLuucyDto = {
                id: $stateParams.requestId
            };
            LuucyRegisterService.assignOrgToLuucy(assignOrgToLuucyDto,
                function(response) {
                    vm.isSaving = false;
                    vm.successRegister = 'success';
            }, function(error) {
                    vm.isSaving = false;
                    vm.successRegister = 'error';
            })
        }

        function connectRegister() {
            var registerOrgForLuucyRequestDto = {
                id: $stateParams.requestId,
                email: vm.email,
                password: vm.password
            };
            LuucyRegisterService.registerOrgToLuucy(registerOrgForLuucyRequestDto,
                function(response) {
                    vm.isSaving = false;
                    vm.successRegister = 'success';
                },
                function(error) {
                    vm.isSaving = false;
                    vm.successRegister = 'error';
                })
        }

        function registerOnBeforeUnload() {
            /*
            $window.onbeforeunload = function (e) {
                LuucyRegisterService.cancel({
                    id: $stateParams.requestId,
                },
                function() {},
                function() {});
            }
             */
        }

        $scope.$on('$destroy', function(e){
            $window.onbeforeunload = undefined;
        });

        registerOnBeforeUnload();

    }

})();
