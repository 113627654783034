(function () {
    'use strict';

    angular.module('kennwerteApp')
        .service('imageService', ImageService);

    ImageService.$inject = ['$http','$q'];

    function ImageService($http,$q) {
        return {
            getMainImageIdByRealEstateId: function (realEstateId, responseFunction, errorFunction) {
                return $http.get('api/image/main_id/' + realEstateId)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function (response) {
                        return errorFunction(response);
                    });

            },
            getAppendedImageIdsByRealEstateId: function(realEstateId, responseFunction, errorFunction) {
                return $http.get('api/image/appended/' + realEstateId)
                    .then(function (response) {
                        return responseFunction(response);
                    }, function(response) {
                        return errorFunction(response);
                    });
            },
            getImageByRealEstateId: function(realEstateId, responseFunction, errorFunction) {
                return $http.get("api/image/" + realEstateId)
                    .then(function(response) {
                        return responseFunction(response);
                    }, function(response) {
                        return errorFunction(response);
                    });

            },
            healImagesOnS3: function(responseFunc) {
                return $http.get("api/image/check")
                    .then(function(response) {
                        responseFunc(response);
                    }, function(response) {
                        responseFunc(response);
                    });
            },
            setNewMainImage: function(req,responseFunc) {
                return $http.post("api/image/thumbnail",req)
                    .then(function(response) {
                        responseFunc(response);
                    }, function(response) {
                        responseFunc(response);
                    });
            },
            healAttachmentsOfRealEstate: function(realEstateId,responseFunc) {
                return $http.post("api/image/check-single",realEstateId)
                    .then(function(response) {
                        responseFunc(response);
                    }, function(response) {
                        responseFunc(response);
                    });
            }

        };
    }

})();
