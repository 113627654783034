(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('rebuildGeometry', RebuildGeometryDirective);

    RebuildGeometryDirective.$inject = ['RebuildActionService'];

    function RebuildGeometryDirective(RebuildActionService) {
        return {
            scope: {
                rowid: '=',
                realEstateContainer: '='
            },
            templateUrl: 'app/components/rebuild_geometry/rebuild-geometry.tpl.html',
            controller: RebuildGeometryController,
            // controllerAs: 'vm',
            link: function (scope, elem, attrs, controller, transclude) {

            }
        };
    }

    RebuildGeometryController.$inject = ['$scope', '$timeout', '$translate', '$rootScope', '$element','RebuildActionService', 'RebuildGeometryService', 'RebuildInputMatrixService', 'SharedControllerFnService', 'HeatingTypeDataService', 'VentilationTypeDataService', 'ChillerTypeDataService'];

    function RebuildGeometryController($scope, $timeout, $translate, $rootScope, $element,RebuildActionService, RebuildGeometryService, RebuildInputMatrixService, SharedControllerFnService, HeatingTypeDataService, VentilationTypeDataService, ChillerTypeDataService) {

        function hideToast(element) {
            if ($(element).hasClass('tooltipstered')) {
                $(element).tooltipster('content', '');
                $(element).tooltipster('hide');
            }
        }

        function showToast(element, text) {
            if ($(element).hasClass('tooltipstered')) {
                $(element).tooltipster('content', text);
                $(element).tooltipster('show');
            } else {
                $(element).tooltipster({
                    contentAsHTML: true,
                    content: text,
                    theme: 'tooltipster-kw',
                    side: ['right'],
                    zIndex: 1048,// same as validation messages
                    interactive: true,
                    selfDestruction: true,
                    animation: 'fade',
                    maxWidth: 350,
                    plugins: ['sideTip'],
                    distance: 0,
                    arrow: false,
                    repositionOnScroll: true,
                    timer: 10000,
                    triggerClose: {
                        click: false,
                        scroll: true
                    }
                }).tooltipster('open');
            }
        }

        function isHeatPumpSelected(val) {
            var result = false;
            _.forEach(val, function (value, key) {
                switch (value) {
                case 'HEAT_PUMP_AIR':
                case 'HEAT_PUMP_GEOTHERMAL':
                case 'HEAT_PUMP_GROUND_COLLECTOR':
                case 'HEAT_PUMP_GROUND_WATER':
                    result = true;
                    break;
                }
            });
            return result;
        }

        $scope.RebuildActionService = RebuildActionService;

        $scope.RebuildGeometryService = RebuildGeometryService;

        $scope.inputFields = RebuildInputMatrixService.inputFields;

        $scope.HeatingTypeDataService = HeatingTypeDataService;
        $scope.VentilationTypeDataService = VentilationTypeDataService;
        $scope.ChillerTypeDataService = ChillerTypeDataService;

        $scope.imagePrefix = '';
        $scope.imageOnlyAnnexPrefix = '';

        $scope.dataHasLoaded = true;

        $scope.$watch('[RebuildGeometryService.isAnnex,RebuildGeometryService.isAdditionStory]', function() {
            $scope.elemWidth = 100 / (2 + RebuildGeometryService.isAnnex + RebuildGeometryService.isAdditionStory);
            createImagePrefix();
            $scope.elemWidthColspan = 100 - $scope.elemWidth;
        });

        function createImagePrefix() {
            var temp = '';
            if (RebuildGeometryService.isAnnex) {
                temp = temp.concat('annex_');
                $scope.imageOnlyAnnexPrefix = 'annex_';
            } else {
                $scope.imageOnlyAnnexPrefix = '';
            }
            if (RebuildGeometryService.isAdditionStory) {
                temp = temp.concat('additional_story_');
            }
            if (temp.length != $scope.imagePrefix.length) {
                $scope.imagePrefix = temp;
            }
        }

        $scope.$watch('[realEstateContainer.geometry.elevatorOutside.vertical,realEstateContainer.targetOverhaul.elevatorOutside.vertical]', function(newValue, oldValue) {
            $timeout(function() {
                var isElevatorOutside = $scope.realEstateContainer.geometry.elevatorOutside;
                var overhaulElevatorOutside = $scope.realEstateContainer.targetOverhaul.elevatorOutside;
                var numIsElevatorOutside = isElevatorOutside.vertical || isElevatorOutside.placeholder;
                var numOverhaulElevatorOutside = overhaulElevatorOutside.vertical || overhaulElevatorOutside.placeholder;
                if (isElevatorOutside.verticalDecision !== overhaulElevatorOutside.verticalDecision ||
                    (isElevatorOutside.verticalDecision === "yes" && overhaulElevatorOutside.verticalDecision === "yes" && numIsElevatorOutside !== numOverhaulElevatorOutside)) {
                    $translate('rebuild.toast.elevatorOutside').then(function(translation){
                        showToast('#elevatorOutsideToast', translation);
                    });
                } else {
                    hideToast('#elevatorOutsideToast');
                }
            });
        });

        $scope.$watch('[realEstateContainer.quality.heatingTypes,realEstateContainer.targetOverhaul.heatingTypes]', function(newValue) {
            $timeout(function() {
                if (!isHeatPumpSelected($scope.realEstateContainer.quality.heatingTypes) && isHeatPumpSelected($scope.realEstateContainer.targetOverhaul.heatingTypes)) {
                    $translate('rebuild.toast.heatingType').then(function(translation){
                        showToast('#heatingTypesToast', translation);
                    });
                } else {
                    hideToast('#heatingTypesToast');
                }
            });
        }, true);

        $scope.hasUsagesWithChiller = function() {
            return SharedControllerFnService.hasUsageWithChiller($scope.realEstateContainer);
        };
        $scope.checkUsageForValidChiller = function(usage) {
            return SharedControllerFnService.hasUsageArrayWithChiller(usage);
        };

        $scope.hasExistingBuildingUsageWithChiller = function() {
            return SharedControllerFnService.hasUsageArrayWithChiller(_.concat(
                $scope.realEstateContainer.usages,
                $scope.realEstateContainer.targetOverhaul.usages));
        };
    }
})();
