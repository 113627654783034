(function () {
    'use strict';
    angular.module('kennwerteApp')
        .factory('ecoStandardGroupFactory', EcoStandardGroupFactory);

    EcoStandardGroupFactory.$inject = [];

    function EcoStandardGroupFactory() {
        var data = {
            "0": [
                {"id": "00", "ref": "MINERG", "title": "NONE", "parent": false, "isSelected": false},
                {"id": "01", "ref": "MINERG", "title": "MINERGIE", "parent": false, "isSelected": false},
                {"id": "02", "ref": "MINERG", "title": "MINERGIE_P", "parent": false, "isSelected": false},
                {"id": "03", "ref": "MINERG", "title": "MINERGIE_A", "parent": false, "isSelected": false},
                {"id": "04", "ref": "ENERG", "title": "MINERGIE_ECO", "parent": false, "isSelected": false},
                {"id": "05", "ref": "ENERG", "title": "GOOD_CLIMATE", "parent": false, "isSelected": false},
                {"id": "06", "ref": "ENERG", "title": "WATT2000", "parent": false, "isSelected": false},
                {"id": "07", "ref": "PV", "title": "PV-ANLAGE", "parent": false, "isSelected": false}
            ]
        };

        return {
            load: function (id) {
                return data[id];
            },
            getByTitle: function (title) {
                for (var key in data) {
                    var groupArr = data[key];
                    for (var objKey in groupArr) {
                        var obj = groupArr[objKey];
                        if (title === obj.title) {
                            return obj;
                        }
                    }
                }
                return {};
            }
        };
    }


})();
