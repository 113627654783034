(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('compactSelectInput', CompactSelectInputDirective);

    CompactSelectInputCtr.$inject = ['$scope', '$rootScope'];
    function CompactSelectInputCtr($scope, $rootScope) {

        $scope.dataHasLoaded = false;

        var vm = this;

        vm.model = {};
        vm.answers = ["yes", "no"];
        // use polyfills for IE, TODO: Introduce a proper place for polyfills
        Number.isInteger = Number.isInteger || function(value) {
            return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
        };
        if ($scope.reference != null) {
            if ($scope.reference) {
                vm.model = vm.answers[0];
            } else {
                vm.model = vm.answers[1];
            }
        }
        if ($scope.dynamicImage == null) {
            $scope.dynamicImage = false;
        }

        vm.isEmptyModel = function() {
            return angular.equals({}, vm.model);
        };

        vm.translateKey = function(answer) {
            return 'global.' + answer;
        };

        vm.onOpenClose = function(isOpen) {
            if (isOpen) {
                $rootScope.$broadcast('updateDescription', {
                    rowid: $scope.rowid,
                    descriptionBaseKey: $scope.descriptionBaseKey
                });
                if ($scope.dynamicImage) {
                    var imgSrc = !vm.isEmptyModel() ? $scope.image + vm.model + '.' + $scope.imageFormat : $scope.defaultImage;
                    $rootScope.$broadcast('updateImage', {
                        rowid: $scope.rowid,
                        imageSrc: imgSrc
                    });
                } else {
                   $rootScope.$broadcast('updateImage', {
                        rowid: $scope.rowid,
                        imageSrc: $scope.image
                    });
                }
            }
        };

        $scope.$watch('vm.model', function (selectedItem) {
            if (!angular.equals({}, selectedItem)) {
                if ($scope.dataHasLoaded) {
                    var imgSrc = $scope.dynamicImage ? $scope.image + selectedItem + '.' + $scope.imageFormat : $scope.image;

                    $rootScope.$broadcast('updateImage', {
                        rowid: $scope.rowid,
                        imageSrc: imgSrc
                    });
                    $rootScope.$broadcast('updateDescription', {
                        rowid: $scope.rowid,
                        descriptionNumber: selectedItem,
                        descriptionBaseKey: $scope.descriptionBaseKey + '.' + selectedItem
                    });
                } else {
                    $scope.dataHasLoaded = true;
                }
                $scope.reference = selectedItem === 'yes';
            }
        });

        $scope.$watch('reference', function(newValue, oldValue) {
            if (newValue == null) {
                vm.model = {};
            } else if (newValue != oldValue) {
                if ($scope.reference) {
                    vm.model = vm.answers[0];
                } else {
                    vm.model = vm.answers[1];
                }
            }
        });
    }

    CompactSelectInputDirective.$inject = ['$rootScope'];
    function CompactSelectInputDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                reference: '=',
                realEstateContainer: '=',
                rowid: '=', // used to set the id of the row-image
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                imageFormat: '=?',
                dynamicImage: '=?',
                defaultImage: '=?',
                required: '=required',
                placeholder: '@',
                validationMessage: '@',
                mandatory: '@',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?',
                classes: '@?'
            },
            controller: CompactSelectInputCtr,
            templateUrl: 'app/components/compact_inputs/compact_select/compact-select-input.tpl.html',
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs) {

                // Used for the validation
                var toggle = tElement.find(".compactSelectInput");
                $(toggle).attr("id", tAttrs.reference+".drop2down-toggle");
            }
        };
    }

})();
