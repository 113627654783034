(function () {
    'use strict';

    angular.module('kennwerteApp')
        .factory('WptFreeTrackingService', WptFreeTrackingService);

    WptFreeTrackingService.$inject = ['$resource'];

    function WptFreeTrackingService($resource) {
        return $resource('api/wpt_free_tracking/:id', {}, {
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            "queryByOrg": {
                method: "GET",
                url: "api/wpt_free_tracking/query_by_org/:orgId",
                params: { orgId: "@orgId" },
                isArray: true
            },
            "queryAllWptsWithFreeMonth": {
                method: "GET",
                url: "api/wpt_free_tracking/query_all_wpts_with_free_month",
                isArray: true
            },
            "activateModule": {
                method: "POST",
                url: "api/wpt_free_tracking/activate_module"
            },
            "cancelModule": {
                method: "POST",
                url: "api/wpt_free_tracking/cancel_module"
            },
            "selectModuleToBuy": {
                method: "POST",
                url: "api/wpt_free_tracking/select_module_to_buy"
            },
            "deselectModuleToBuy": {
                method: "POST",
                url: "api/wpt_free_tracking/deselect_module_to_buy"
            }
        });
    }

})();
