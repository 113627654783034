(function() {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller("RebuildCompleteInputController", RebuildCompleteInputController);

    RebuildCompleteInputController.$inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        '$state',
        'WithdrawalService',
        'Principal',
        '$sessionStorage',
        '$filter',
        '$timeout',
        'RebuildEstateService',
        'RebuildEstateDataService',
        'usageTypeGroupFactory',
        'ValidationDataService',
        'ValidationFunctionsService',
        'RebuildTemplateService',
        'SharedControllerFnService',
        'RebuildInputMatrixService',
        'RebuildGeometryService',
        'POMIntegrationService',
        '$translate',
        'LCCIntegrationService',
        'RbUsageTypeGroupFactory'
    ];

    function RebuildCompleteInputController(
        $scope,
        $rootScope,
        $stateParams,
        $state,
        WithdrawalService,
        Principal,
        $sessionStorage,
        $filter,
        $timeout,
        RebuildEstateService,
        RebuildEstateDataService,
        usageTypeGroupFactory,
        ValidationDataService,
        ValidationFunctionsService,
        RebuildTemplateService,
        SharedControllerFnService,
        RebuildInputMatrixService,
        RebuildGeometryService,
        POMIntegrationService,
        $translate,
        LCCIntegrationService,
        RbUsageTypeGroupFactory
    ) {

        function hideToast(element) {
            if ($(element).hasClass('tooltipstered')) {
                $(element).tooltipster('content', '');
                $(element).tooltipster('hide');
            }
        }

        function showToast(element, text) {
            if ($(element).hasClass('tooltipstered')) {
                $(element).tooltipster('content', text);
                $(element).tooltipster('show');
            } else {
                $(element).tooltipster({
                    contentAsHTML: true,
                    content: text,
                    theme: 'tooltipster-kw',
                    side: ['right'],
                    zIndex: 1048,// same as validation messages
                    interactive: true,
                    selfDestruction: true,
                    animation: 'fade',
                    maxWidth: 350,
                    plugins: ['sideTip'],
                    distance: 0,
                    arrow: false,
                    repositionOnScroll: true,
                    timer: 10000,
                    triggerClose: {
                        click: false,
                        scroll: true
                    }
                }).tooltipster('open');
            }
        }

        $scope.elementsWithError = [];
        $scope.isPomEnabled = false;

        $scope.firstLoad = true;
        $scope.POMIntegrationService = POMIntegrationService;
        $scope.LCCIntegrationService = LCCIntegrationService;

        $scope.isEditState = $state.includes('*.edit-record');

        var usagesWatch = [];

        ValidationDataService.deregisterValidationFunctions();
        ValidationDataService.setZindex(449);
        ValidationDataService.registerValidationFunctions([
            ValidationFunctionsService.validateBuildingName,
            ValidationFunctionsService.validateZipCode,
            ValidationFunctionsService.validateYearOfConstruction,
            ValidationFunctionsService.validateMonumentProtectionType,
            ValidationFunctionsService.validateOccupationType,
            ValidationFunctionsService.validateMaintenanceQualityType,

            ValidationFunctionsService.validateRebuildHeatingTypes,
            ValidationFunctionsService.validateChiller,
            ValidationFunctionsService.validateRebuildChiller,
            ValidationFunctionsService.validateRoofTypes,

            ValidationFunctionsService.validateAllRebuildGeometryInputs,
            ValidationFunctionsService.annexLimitChecker,
            ValidationFunctionsService.validateGroundwork,
            ValidationFunctionsService.validateCompleteRenovation,
            ValidationFunctionsService.validateExternalRenovation,
            ValidationFunctionsService.validateInteriorRenovation,
            ValidationFunctionsService.validateRebuildOverhaulElevatorsInside,
            ValidationFunctionsService.validateRebuildOverhaulElevatorsOutside,
            ValidationFunctionsService.validateRebuildAnnexElevatorsInside,
            ValidationFunctionsService.validateRebuildAnnexElevatorsOutside,
            ValidationFunctionsService.validateRatioVolumeUnderTerrain,
            ValidationFunctionsService.validateAreaBuilding416,

            ValidationFunctionsService.validateRebuildElements,
            ValidationFunctionsService.validateBuildingsInConstruction,
            ValidationFunctionsService.validateArea,
            ValidationFunctionsService.validateVolume,
            ValidationFunctionsService.validateBuiltUpAreaRebuild,
            ValidationFunctionsService.validateOvergroundFloors,
            ValidationFunctionsService.validateUndergroundFloors,
            ValidationFunctionsService.validateRemark,
            ValidationFunctionsService.validateSumOfFloors,
            ValidationFunctionsService.validateFacadeType,
            ValidationFunctionsService.validateUndergroundParkplace,
            ValidationFunctionsService.validateRebuildElevatorsInside,
            ValidationFunctionsService.validateRebuildElevatorsOutside,

            ValidationFunctionsService.validateRebuildAdditions
        ]);

        $scope.$on('changeUsages', function(event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.$watch('realEstateContainer.targetOverhaul.builtUpArea', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if (
                !RebuildGeometryService.isAnnex &&
                $scope.realEstateContainer.targetOverhaul.builtUpArea != null &&
                $scope.realEstateContainer.geometry.builtUpArea != null &&
                ("" + $scope.realEstateContainer.targetOverhaul.builtUpArea) !== ("" + $scope.realEstateContainer.geometry.builtUpArea)
            ) {
                $translate('rebuild.toast.builtUpAreaChanges').then(function(translation) {
                    showToast('#builtUpAreaToast', translation);
                });
            } else {
                hideToast('#builtUpAreaToast');
            }
        });

        $scope.$watch('[realEstateContainer.geometry.totalVolume416,realEstateContainer.targetOverhaul.totalVolume416]', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            var newExtraVolume = $scope.realEstateContainer.targetOverhaul.totalVolume416 - $scope.realEstateContainer.geometry.totalVolume416;
            if ($scope.realEstateContainer.geometry.totalVolume416 * 0.015 < newExtraVolume) {
                $translate('rebuild.toast.tooMuchExtraVolume').then(function(translation){
                    showToast('#totalVolume416Toast', translation);
                });
            } else {
                hideToast('#totalVolume416Toast');
            }
        });

        $scope.$watch('[realEstateContainer.geometry.totalFloorArea416,realEstateContainer.targetOverhaul.totalFloorArea416]', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            var newExtraFloorArea = $scope.realEstateContainer.targetOverhaul.totalFloorArea416 - $scope.realEstateContainer.geometry.totalFloorArea416;
            if ($scope.realEstateContainer.geometry.totalFloorArea416 * 0.015 < newExtraFloorArea) {
                $translate('rebuild.toast.tooMuchExtraFloorArea').then(function(translation){
                    showToast('#totalFloorArea416Toast', translation);
                });
            } else {
                hideToast('#totalFloorArea416Toast');
            }
        });

        $scope.$watch('[realEstateContainer.geometry.totalFloorArea416,realEstateContainer.targetOverhaul.totalFloorArea416,realEstateContainer.geometry.totalVolume416,realEstateContainer.targetOverhaul.totalVolume416]', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            var removeVolume = $scope.realEstateContainer.geometry.totalVolume416 - $scope.realEstateContainer.targetOverhaul.totalVolume416;
            var removeFloorArea = $scope.realEstateContainer.geometry.totalFloorArea416 - $scope.realEstateContainer.targetOverhaul.totalFloorArea416;
            if (removeFloorArea > 0 && removeVolume <= 0) {
                $translate('rebuild.toast.onlyExtraFloorArea').then(function(translation){
                    showToast('#totalFloorArea416Toast', translation);
                });
            } else {
                hideToast('#totalFloorArea416Toast');
            }
        });

        $scope.$watch('realEstateContainer.geometry.totalFloorArea416', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.totalFloorArea416 == null || oldValue === $scope.realEstateContainer.targetOverhaul.totalFloorArea416) {
                $scope.realEstateContainer.targetOverhaul.totalFloorArea416 = $scope.realEstateContainer.geometry.totalFloorArea416;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.totalVolume416', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.totalVolume416 == null || oldValue === $scope.realEstateContainer.targetOverhaul.totalVolume416) {
                $scope.realEstateContainer.targetOverhaul.totalVolume416 = $scope.realEstateContainer.geometry.totalVolume416;
            }
        }, true);

        $scope.$watch('realEstateContainer.geometry.ratioVolumeUnderTerrain', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.ratioVolumeUnderTerrain == null || oldValue === $scope.realEstateContainer.targetOverhaul.ratioVolumeUnderTerrain) {
                $scope.realEstateContainer.targetOverhaul.ratioVolumeUnderTerrain = $scope.realEstateContainer.geometry.ratioVolumeUnderTerrain;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.builtUpArea', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if (!RebuildGeometryService.isAnnex) {
                if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.builtUpArea == null || oldValue === $scope.realEstateContainer.targetOverhaul.builtUpArea) {
                    $scope.realEstateContainer.targetOverhaul.builtUpArea = $scope.realEstateContainer.geometry.builtUpArea;
                }
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.buildings', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.buildings == null || oldValue === $scope.realEstateContainer.targetOverhaul.buildings) {
                $scope.realEstateContainer.targetOverhaul.buildings = $scope.realEstateContainer.geometry.buildings;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.overgroundFloors', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.overgroundFloors == null || oldValue === $scope.realEstateContainer.targetOverhaul.overgroundFloors) {
                $scope.realEstateContainer.targetOverhaul.overgroundFloors = $scope.realEstateContainer.geometry.overgroundFloors;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.undergroundFloors', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.undergroundFloors == null || oldValue === $scope.realEstateContainer.targetOverhaul.undergroundFloors) {
                $scope.realEstateContainer.targetOverhaul.undergroundFloors = $scope.realEstateContainer.geometry.undergroundFloors;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.undergroundParkingSpaces', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.undergroundParkingSpaces == null || oldValue === $scope.realEstateContainer.targetOverhaul.undergroundParkingSpaces) {
                $scope.realEstateContainer.targetOverhaul.undergroundParkingSpaces = $scope.realEstateContainer.geometry.undergroundParkingSpaces;
            }
        }, true);
        $scope.$watch('realEstateContainer.quality.facadeType', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.facadeType == null || oldValue === $scope.realEstateContainer.targetOverhaul.facadeType) {
                $scope.realEstateContainer.targetOverhaul.facadeType = $scope.realEstateContainer.quality.facadeType;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.elevatorInside.verticalDecision', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.elevatorInside.verticalDecision == null || oldValue === $scope.realEstateContainer.targetOverhaul.elevatorInside.verticalDecision) {
                $scope.realEstateContainer.targetOverhaul.elevatorInside.verticalDecision = $scope.realEstateContainer.geometry.elevatorInside.verticalDecision;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.elevatorInside.vertical', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.elevatorInside.vertical == null || oldValue === $scope.realEstateContainer.targetOverhaul.elevatorInside.vertical) {
                $scope.realEstateContainer.targetOverhaul.elevatorInside.vertical = $scope.realEstateContainer.geometry.elevatorInside.vertical;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.elevatorOutside.verticalDecision', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.elevatorOutside.verticalDecision == null || oldValue === $scope.realEstateContainer.targetOverhaul.elevatorOutside.verticalDecision) {
                $scope.realEstateContainer.targetOverhaul.elevatorOutside.verticalDecision = $scope.realEstateContainer.geometry.elevatorOutside.verticalDecision;
            }
        }, true);
        $scope.$watch('realEstateContainer.geometry.elevatorOutside.vertical', function(newValue, oldValue) {
            if (newValue === oldValue || $scope.firstLoad) return;
            if ($scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.elevatorOutside.vertical == null || oldValue === $scope.realEstateContainer.targetOverhaul.elevatorOutside.vertical) {
                $scope.realEstateContainer.targetOverhaul.elevatorOutside.vertical = $scope.realEstateContainer.geometry.elevatorOutside.vertical;
            }
        }, true);

        $scope.$watch('realEstateContainer.usages.length', function(newValue, oldValue) {
            //destroying any old existing watches.
            if (usagesWatch.length > 0) {
                for (var i = 0; i < usagesWatch.length; ++i) {
                    if (typeof usagesWatch[i] === 'function') {
                        usagesWatch[i]();
                    }
                }
            }
            usagesWatch = [];
            //adding watches for each array element.
            if (!!newValue && $scope.realEstateContainer.usages.length > 0) {
                _.forEach($scope.realEstateContainer.usages, function(value, key) {
                    usagesWatch.push($scope.$watch('realEstateContainer.usages[' + key + ']', function(newValue, oldValue) {
                        if ($scope.firstLoad) return;
                        if (($scope.realEstateContainer.targetOverhaul.usages[key].type == null || $scope.realEstateContainer.targetOverhaul.usages[key].type === '') || $scope.realEstateContainer.targetOverhaul.usages[key].type === oldValue.type) {
                            $scope.realEstateContainer.targetOverhaul.usages[key].type = newValue.type;
                            $scope.realEstateContainer.targetOverhaul.usages[key].percentage = newValue.percentage;
                            $scope.realEstateContainer.targetOverhaul.usages[key].standard = newValue.standard;
                        }
                    }, true));
                });
            }
        }, true);
        //
        // $scope.$watch('realEstateContainer.quality.heatingTypes', function(newValue, oldValue) {
        //     if (newValue === undefined || _.isEqual(newValue, oldValue) || $scope.firstLoad) return;
        //     if ($scope.realEstateContainer.targetOverhaul.heatingTypes == null || $scope.realEstateContainer.targetOverhaul.heatingTypes.length === 0 || _.isEqual(oldValue, $scope.realEstateContainer.targetOverhaul.heatingTypes)) {
        //         $scope.realEstateContainer.targetOverhaul.heatingTypes = newValue;
        //     }
        // }, true);
        //
        // $scope.$watch('realEstateContainer.quality.ventilationType', function(newValue, oldValue) {
        //     if (newValue === oldValue || $scope.firstLoad) return;
        //     if (newValue != null && $scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.ventilationType == null || oldValue === $scope.realEstateContainer.targetOverhaul.ventilationType) {
        //         $scope.realEstateContainer.targetOverhaul.ventilationType = $scope.realEstateContainer.quality.ventilationType;
        //     }
        // }, true);
        //
        // $scope.$watch('realEstateContainer.quality.hasConditioning', function(newValue, oldValue) {
        //     if (newValue === oldValue || $scope.firstLoad) return;
        //     if (newValue != null && $scope.realEstateContainer.targetOverhaul != null && $scope.realEstateContainer.targetOverhaul.hasConditioning == null || oldValue === $scope.realEstateContainer.targetOverhaul.hasConditioning) {
        //         $scope.realEstateContainer.targetOverhaul.hasConditioning = $scope.realEstateContainer.quality.hasConditioning;
        //     }
        // }, true);

        $scope.validateAndProceed = function(onSuccessFn) {
            RebuildInputMatrixService.fillDefaultValues($scope.realEstateContainer);
            $scope.realEstateContainer.selectedTemplates = RebuildTemplateService.getSelectedTemplates();
            //if templates has no ANNEX or ADDITION_STORY
            //realEstateContainer.selectedTemplates.indexOf('ANNEX') > -1 || realEstateContainer.selectedTemplates.indexOf('ADDITION_STORY') > -1
            // then copy geometry to target (overhaul)
            if (RebuildTemplateService.isOnlyTargetOverhaul()) {
                var realEstate = $scope.realEstateContainer;
                var targetOverhaul = realEstate.targetOverhaul;
                targetOverhaul.totalFloorArea416 = targetOverhaul.totalFloorArea416 || realEstate.geometry.totalFloorArea416;
                targetOverhaul.totalVolume416 = targetOverhaul.totalVolume416 || realEstate.geometry.totalVolume416;
                targetOverhaul.ratioVolumeUnderTerrain = targetOverhaul.ratioVolumeUnderTerrain || realEstate.geometry.ratioVolumeUnderTerrain;
                targetOverhaul.builtUpArea = targetOverhaul.builtUpArea || realEstate.geometry.builtUpArea;
                targetOverhaul.buildings = targetOverhaul.buildings || realEstate.geometry.buildings;
                targetOverhaul.overgroundFloors = targetOverhaul.overgroundFloors || realEstate.geometry.overgroundFloors;
                targetOverhaul.undergroundFloors = targetOverhaul.undergroundFloors || realEstate.geometry.undergroundFloors;
                targetOverhaul.undergroundParkingSpaces = targetOverhaul.undergroundParkingSpaces || realEstate.geometry.undergroundParkingSpaces;
                targetOverhaul.roofTypes = targetOverhaul.roofTypes || [];
                targetOverhaul.roofTypes = targetOverhaul.roofTypes.length > 0 ? targetOverhaul.roofTypes : realEstate.quality.roofTypes;
                targetOverhaul.heatingTypes = targetOverhaul.heatingTypes || [];
                targetOverhaul.heatingTypes = targetOverhaul.heatingTypes.length > 0 ? targetOverhaul.heatingTypes : realEstate.quality.heatingTypes;
                targetOverhaul.chillerType = targetOverhaul.chillerType || realEstate.quality.chillerType;
                targetOverhaul.ventilationType = targetOverhaul.ventilationType || realEstate.quality.ventilationType;
                targetOverhaul.facadeType = targetOverhaul.facadeType || realEstate.quality.facadeType;
            }

            ValidationDataService.validateAndProceed($scope.realEstateContainer, onSuccessFn, RebuildInputMatrixService.removeDefaultValues);
        };

        POMIntegrationService.init($scope.realEstateContainer);
        LCCIntegrationService.init($scope.realEstateContainer);
        $scope.$watch('realEstateContainer.targetOverhaul.usages[0].type', function (newValue, oldValue) {
            POMIntegrationService.checkUsageChange(newValue, $scope.realEstateContainer);
        });
        $scope.$watch('realEstateContainer.usages[0]', function (newValue, oldValue) {
            $scope.realEstateContainer.lcc.rbMainUsage = RbUsageTypeGroupFactory.getRbUsage(newValue);
        }, true);
        // if POM not supported also lcc not supported!
        $scope.$watch('POMIntegrationService.isPomEnabled', function (newValue, oldValue) {
            if (newValue === false) {
                LCCIntegrationService.lccEnabledInRebuildCosts = false;
            }
        });

        $(document).ready(function() {
            $timeout(function() {
                $scope.firstLoad = false;
                $scope.realEstateContainer.quality.minergieStandard = 'NONE'; // VS-9
                POMIntegrationService.init($scope.realEstateContainer);
            }, 3000);
        });

    }
})();
