(function() {
    "use strict";

    angular
        .module("kennwerteApp")
        .factory("FacadePresetService", FacadePresetService);

    FacadePresetService.$inject = ['UsageToEnumResolverService'];

    /**
     * Created by KR-20-5-preset-dict-final
     * - from kw-bkp-model data/export/KR-20/preset-final/facade-presets-final.json
     * Is used in kw-lambda-node-usage-preset (seperated repo) - microservice for kw-api!
     */
    function FacadePresetService(UsageToEnumResolverService) {
        var service = {
            presetFacade: presetFacade,
            getMapping: function() {
                return mapping;
            }
        };

        function presetFacade(usages, realEstateContainer) {
            var facadePreset = UsageToEnumResolverService.resolve(usages, service.getMapping());
            realEstateContainer.quality.facadeType = facadePreset;
        }

        var mapping = {
            "WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "WOHNBAUTEN__STUDENTENWOHNHAEUSER": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "WOHNBAUTEN__PERSONALHAEUSER": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "WOHNBAUTEN__MFH": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "AUSSENDAEMMUNG_PUTZ_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "WOHNBAUTEN__TERRASSENHAEUSER": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "WOHNBAUTEN__EFH_REIHEN": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "AUSSENDAEMMUNG_PUTZ_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "WOHNBAUTEN__ALTERSWOHNUNGEN": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "AUSSENDAEMMUNG_PUTZ_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "WOHNBAUTEN__ALTERSHEIME": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "KULTUR__TITLE": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "KULTUR__AUSSTELLUNGEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "KULTUR__KONGRESS": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "KULTUR__SAAL": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "KULTUR__MUSEEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "KULTUR__WOHLFAHRT": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "KULTUR__KULTUR_GEMEINSCHAFTSZENTREN": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__RESTAURANT": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HOTELBAUTEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__KANTINEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "GASTGEWERBE_FREMDENVERKEHRSBAUTEN__HERBERGEN": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "FREIZEIT_SPORT_ERHOLUNGSBAUTEN": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EDEL",
                "HIGH": "HOLZ_EDEL"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__REITHALLEN": {
                "LOW": "NICHT_ISOLIERT_HOLZ",
                "MEDIUM": "NICHT_ISOLIERT_HOLZ",
                "HIGH": "NICHT_ISOLIERT_HOLZ"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__FREIZEITZENTREN": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__TURNHALLEN_SPORTHALLEN_MEHRZWECKHALLEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE": {
                "LOW": "NICHT_ISOLIERT_BETON",
                "MEDIUM": "NICHT_ISOLIERT_BETON",
                "HIGH": "NICHT_ISOLIERT_BETON"
            },
            "VERKEHRSBAUTEN__PARKHAEUSER_OBERIRDISCH": {
                "LOW": "KEINE_FASSADE",
                "MEDIUM": "KEINE_FASSADE",
                "HIGH": "KEINE_FASSADE"
            },
            "VERKEHRSBAUTEN__GARAGEN_FREISTEHEND": {
                "LOW": "KEINE_FASSADE",
                "MEDIUM": "KEINE_FASSADE",
                "HIGH": "KEINE_FASSADE"
            },
            "VERKEHRSBAUTEN__WERKHOEFE": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN": {
                "LOW": "NICHT_ISOLIERT_BETON",
                "MEDIUM": "NICHT_ISOLIERT_BETON",
                "HIGH": "NICHT_ISOLIERT_BETON"
            },
            "VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH": {
                "LOW": "NICHT_ISOLIERT_BETON",
                "MEDIUM": "NICHT_ISOLIERT_BETON",
                "HIGH": "NICHT_ISOLIERT_BETON"
            },
            "VERKEHRSBAUTEN__ZOLL": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHUTZBAUTEN__SCHULUNGSBAUTEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHUTZBAUTEN__KASERNEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHUTZBAUTEN__FEUERWEHRGEBAEUDE": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EINFACH"
            },
            "SCHULBAUTEN__KINDERGAERTEN_HORTE": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EDEL",
                "HIGH": "HOLZ_EDEL"
            },
            "SCHULBAUTEN__VOLKSSCHULEN": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EDEL",
                "HIGH": "HOLZ_EDEL"
            },
            "SCHULBAUTEN__BERUFSSCHULEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN": {
                "LOW": "MASSIVBAU_EDEL",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHULBAUTEN__BIBLIOTHEKEN": {
                "LOW": "MASSIVBAU_EDEL",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHULBAUTEN__FORSCHUNGSBAUTEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN": {
                "LOW": "STAHL_KASSETENWANDFASSADE_EINFACH",
                "MEDIUM": "STAHL_KASSETENWANDFASSADE_EINFACH",
                "HIGH": "STAHL_KASSETENWANDFASSADE"
            },
            "LAGERBAUTEN_MEHRSTOECKIGE": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EINFACH"
            },
            "LAGERBAUTEN__INDUSTRIEHALLEN": {
                "LOW": "STAHL_KASSETENWANDFASSADE_EINFACH",
                "MEDIUM": "STAHL_KASSETENWANDFASSADE_EINFACH",
                "HIGH": "STAHL_KASSETENWANDFASSADE"
            },
            "LAGERBAUTEN_PRODUKTIONSBAUTEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EINFACH"
            },
            "LANDWIRTSCHAFT__SCHEUNEN": {
                "LOW": "NICHT_ISOLIERT_HOLZ",
                "MEDIUM": "NICHT_ISOLIERT_HOLZ",
                "HIGH": "NICHT_ISOLIERT_HOLZ"
            },
            "LANDWIRTSCHAFT_STALLUNGEN": {
                "LOW": "NICHT_ISOLIERT_HOLZ",
                "MEDIUM": "NICHT_ISOLIERT_HOLZ",
                "HIGH": "NICHT_ISOLIERT_HOLZ"
            },
            "LADENBAUTEN_HANDELSBAUTEN__MARKTHALLEN": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "BUEROBAUTEN__EDELROHBAU": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "BUEROBAUTEN__VOLLAUSBAU": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "BANKEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "VERWALTUNGSBAUTEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER": {
                "LOW": "MASSIVBAU_EDEL",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "GLASSFASSADE_GLASSFASSADE",
                "HIGH": "GLASSFASSADE_GLASSFASSADE"
            },
            "JUSTIZ__STRAFVOLLZUGSANSTALTEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "GESUNDHEIT__PFLEGEHEIME": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "KULTUS__KIRCHGEMEINDEHAEUSER": {
                "LOW": "HOLZ_EINFACH",
                "MEDIUM": "HOLZ_EINFACH",
                "HIGH": "HOLZ_EDEL"
            },
            "LAGERBAUTEN__VERTEILZENTRALEN": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "HIGH": "AUSSENDAEMMUNG_PUTZ_EINFACH"
            },
            "LANDWIRTSCHAFT__LANDWIRTSCHAFTLICHE_ANLAGEN": {
                "LOW": "NICHT_ISOLIERT_HOLZ",
                "MEDIUM": "NICHT_ISOLIERT_HOLZ",
                "HIGH": "NICHT_ISOLIERT_HOLZ"
            },
            "BUEROBAUTEN": {
                "LOW": "AUSSENDAEMMUNG_PUTZ_EINFACH",
                "MEDIUM": "MASSIVBAU_EINFACH",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "GESUNDHEITSBAUTEN__KRANKENHAEUSER": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            },
            "GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN": {
                "LOW": "MASSIVBAU_EINFACH",
                "MEDIUM": "MASSIVBAU_EDEL",
                "HIGH": "MASSIVBAU_EDEL"
            }
        };

        return service;
    }
})();
