(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('LuucyModalController', LuucyModalController);

    LuucyModalController.$inject = ['$state', '$uibModalInstance', '$window', '$sce', '$scope'];

    function LuucyModalController($state, $uibModalInstance, $window, $sce, $scope) {
        var vm = this;

        // TODO: Not working properly yet, need to embed proxied luucy probably, see: https://vividcode.io/embed-kibana-ui-in-spring-boot-2/
        vm.luucyUrl = $sce.trustAsResourceUrl("https://beta.luucy.ch/buttisholz?embedded=1");

        vm.cancel = cancel;

        vm.windowHeight = $window.innerHeight - 125 + 'px';

        $scope.onLuucyModalLoad = function () {
            // TODO: Not working properly yet, need to embed proxied luucy probably, see: https://vividcode.io/embed-kibana-ui-in-spring-boot-2/

            var luucyIframe = document.getElementById('luucyIframe');
            var src = luucyIframe.src;

            function endsWith(str, suffix) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            if (endsWith(src, "/login")) {
                console.log("User tries to login")
            }
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }


})();
