(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('OperationalCostsCompleteInputController', OperationalCostsCompleteInputController);

    OperationalCostsCompleteInputController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'WithdrawalService', 'Principal', '$sessionStorage', '$filter', 'usageTypeGroupFactory', 'ValidationDataService', 'ValidationFunctionsService','SharedControllerFnService'];

    function OperationalCostsCompleteInputController($scope, $rootScope, $stateParams, $state, WithdrawalService, Principal, $sessionStorage, $filter, usageTypeGroupFactory, ValidationDataService, ValidationFunctionsService, SharedControllerFnService) {

        $scope.elementsWithError = [];
        $scope.isPomEnabled = false;
        $scope.validationDataService = ValidationDataService;

        ValidationDataService.deregisterValidationFunctions();
        ValidationDataService.setZindex(1048);
        ValidationDataService.registerValidationFunctions([
            // General Validators
            ValidationFunctionsService.validateBuildingName,
            ValidationFunctionsService.validateZipCode,
            ValidationFunctionsService.validateMainUsage,
            ValidationFunctionsService.validateRatioVolumeUnderTerrain,
            ValidationFunctionsService.validateAreaBuilding416,
            ValidationFunctionsService.validateUsages,
            ValidationFunctionsService.validateBuildingsInConstruction,
            ValidationFunctionsService.validateArea,
            ValidationFunctionsService.validateVolume,
            ValidationFunctionsService.validateUndergroundFloorsOneLineInput,
            ValidationFunctionsService.validateOvergroundFloors,
            ValidationFunctionsService.validateSumOfFloors,
            ValidationFunctionsService.validateFacadeType,
            ValidationFunctionsService.validateUndergroundParkplace,
            ValidationFunctionsService.validateElevators,
            // for BUILDING_INSURANCE_VALUES


            // for OPERATIONAL_COSTS
            ValidationFunctionsService.validateBuiltUpAreaRequired,
            ValidationFunctionsService.validateSecurityLevel,
            // ValidationFunctionsService.validateRoofTypes,
            ValidationFunctionsService.validateRoofType,//TODO KR-450 replace with the above
            ValidationFunctionsService.validateLeMinergie,
            ValidationFunctionsService.validateHeatingType,
            ValidationFunctionsService.validateChiller,
            ValidationFunctionsService.validateConditioning,
            ValidationFunctionsService.validatePomConstructionYear
        ]);

        $scope.$on("changeUsages", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.$on("changeInvestments", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        /**
         * This watch checks if the usage type changes of an realEstate and handles the pom checkbox according to the usage,
         * as for some usages we might not offer pom functionality.
         */
        // $scope.$watch('realEstateContainer.usages[0].type', function (newValue, oldValue) {
        //     if ($scope.realEstateContainer.metaData.withdrawalProductType !== 'BUILDING_COSTS_AND_DATES') {
        //         $scope.isPomEnabled = false;
        //         $scope.realEstateContainer.pom.hasPomData = null;
        //         return;
        //     }
        //     if (newValue) { // checking for defined is not enough, newValue should also not be null
        //         var usage = usageTypeGroupFactory.getByTitle(newValue);
        //         try {
        //             var usageParentGroup = usage.id.substring(0, 2);
        //         } catch (e) {
        //             usageParentGroup = 'error';
        //         }
        //         if (
        //             usage.id != '130104' && usage.id != '130103' && (
        //             usageParentGroup == '01' || usageParentGroup == '02' ||
        //             usageParentGroup == '03' || usageParentGroup == '06' ||
        //             usageParentGroup == '08' || usageParentGroup == '13')
        //         ) {
        //             $scope.isPomEnabled = true;
        //         } else {
        //             $scope.isPomEnabled = false;
        //             $scope.realEstateContainer.pom.hasPomData = null;
        //         }
        //     } else {
        //         $scope.isPomEnabled = false;
        //         $scope.realEstateContainer.pom.hasPomData = null;
        //     }
        // });


        $scope.validateAndProceed = function (onSuccessFn) {
            ValidationDataService.validateAndProceed($scope.realEstateContainer, onSuccessFn);
        };

    }
})();



