(function() {
    'use strict';

    angular.module('kennwerteApp')
        .controller('WithdrawalProductDeleteController', WithdrawalProductDeleteController);

    WithdrawalProductDeleteController.$inject = ['$uibModalInstance', 'entity', 'WithdrawalProductService'];

    function WithdrawalProductDeleteController($uibModalInstance, entity, WithdrawalProductService) {
        var vm = this;

        vm.withdrawalProduct = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.isDeleting = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var onDeleteSuccess = function (result) {
            vm.isDeleting = false;
            $uibModalInstance.close(result);
        };

        var onDeleteError = function (result) {
            vm.isDeleting = false;
            console.error("Failed to delete " + result);
        };

        function confirmDelete() {
            vm.isDeleting = true;
            WithdrawalProductService.delete({id: vm.withdrawalProduct.id}, onDeleteSuccess, onDeleteError);
        }
    }

})();
