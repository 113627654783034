(function () {
    "use strict";
    angular.module('kennwerteApp')
        .service('SliderPresetService', SliderPresetService);

    SliderPresetService.$inject = ['$rootScope', '$timeout', 'RealEstateReferenceDataService',
        'UsageSliderMappingService', 'UsageQuantilesService', 'SliderIdentMappingService'];

    /**
     * This service delivers functionality to update sliders depending on usage.
     * @returns {{test: {}, setDefaultSlider: (function(*): *), setSliderModelValuesArr: setSliderModelValuesArr, correctUsages: correctUsages}}
     * @constructor
     */
    function SliderPresetService($rootScope, $timeout, RealEstateReferenceDataService,
                                 UsageSliderMappingService, UsageQuantilesService, SliderIdentMappingService) {

        //TODO this is currently only working on Reference objects. Should also be implemented in the normal RealEstate.

        var service = {
            setDefaultSliderModelValues: setDefaultSliderModelValues,
            setSliderModelValuesArr: setSliderModelValuesArr,
            correctUsages: correctUsages,
            getWeightedPresetValues: getWeightedPresetValues,
            getWeightedPresetIntervals: getWeightedPresetIntervals,
            requestPresetEvent: requestPresetEvent
        };

        /*
         * Set default values of the sliders
         */
        function setDefaultSliderModelValues(realEstate) {
            // Geometry and environment
            realEstate.quality.qualitaetDerGebaeudeform2 = "WK5";

            // Material and front
            if (realEstate.metaData.withdrawalProductType == 'BUILDING_INSURANCE_VALUES') {
                realEstate.quality.qualitaetvorbereitungsarbeiten = null;
                realEstate.quality.qualitaetUmgebung = null;
            } else {
                realEstate.quality.qualitaetvorbereitungsarbeiten = "WK5";
                realEstate.quality.qualitaetUmgebung = "WK5";
            }
            realEstate.quality.qualitaetVorfertigung = "WK4";
            realEstate.quality.qualitaetGrundstueck = "WK5";
            realEstate.quality.fensterAnteil = "WK5";

            // Technology
            realEstate.quality.qualitaetElektroAnlage = "WK5";
            realEstate.quality.qualitaetHeizungsanlage = "WK5";
            realEstate.quality.qualitaetSanitaeranlage = "WK5";

            // Finishings
            realEstate.quality.qualitaetAusbau1 = "WK5";
            realEstate.quality.qualitaetAusbau2 = "WK5";

            return realEstate;
        }

        /**
         * Additional amount for preaparatory work.
         * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
         * @returns {*}
         * @private
         */
        function _amountPreparatoryWork(overgroundFloors, undergroundFloors) {
            var amount = 0;
            if (undergroundFloors >= 2 && undergroundFloors < 3) {
                amount = Math.max(amount, 1);
            } else if (undergroundFloors >= 3 && undergroundFloors < 5) {
                amount = Math.max(amount, 2);
            } else if(undergroundFloors >= 5) {
                amount = Math.max(amount, 3);
            }
            if (overgroundFloors >= 4 &&  overgroundFloors < 7) {
                amount = Math.max(amount, 1);
            } else if (overgroundFloors >= 7 && overgroundFloors < 8) {
                amount = Math.max(amount, 2);
            } else if (overgroundFloors >= 8) {
                amount = Math.max(amount, 2);
            }
            return amount;
        }

        /**
         * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
         * @returns {number}
         * @private
         */
        function _amountLot(overgroundFloors, undergroundFloors) {
            var amount = 0;
            if (undergroundFloors >= 2) {
                amount = Math.max(amount, 1);
            }
            if (overgroundFloors >= 7) {
                amount = Math.max(amount, 1);
            }
            return amount;
        }

        /**
         * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
         * @returns {number}
         * @private
         */
        function _amountBuildingForm(overgroundFloors, undergroundFloors) {
            var amount = 0;
            if (undergroundFloors >= 2) {
                amount = Math.max(amount, 1);
            }
            return amount;
        }

        /**
         * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
         * @returns {number}
         * @private
         */
        function _amountSanitary(overgroundFloors, undergroundFloors) {
            var amount = 0;
            if (overgroundFloors >= 8) {
                amount = Math.max(amount, 1);
            }
            return amount;
        }

        /**
         * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
         * @returns {number}
         * @private
         */
        function _amountElectric(presetElectric, minergieStandard, pv) {
            var amount = 0;
            if (minergieStandard === "MINERGIE" || minergieStandard === "MINERGIE_P") {
                if (presetElectric < 4) {
                    amount = Math.max(amount, 1);
                }
            } else if (minergieStandard === "MINERGIE_A") {
                if (presetElectric < 4) {
                    amount = Math.max(amount, 2);
                } else if (presetElectric === 4) {
                    amount = Math.max(amount, 1);
                }
            }
            if (pv) {
                if (presetElectric < 4) {
                    amount = Math.max(amount, 1)
                }
            }
            return amount;
        }

        /**
         * This function sets value marks for the right preset for the sliders.
         * @param preset_arr array containing wk positions (may be float [0, 7]) for each slider except Vorfertigung - sliders sorted by
         *  [Umgebung, Grundstueck, Vorbereitungsarbeiten, FensterAnteil, Gebaeudeform2, ElektroAnlage, Heizungsanlage,
         *  Sanitaeranlage, Ausbau1, Ausbau2]
         *  @param preset_i_arr array containing interval positions (array of floats) for each slider except Vorfertigung - sliders sorted like arr.
         * @param realEstateContainer real estate data
         * @param isPresetSliderModelValuesEnabled if true, the slider values will be set as well (to the same value as the value marks).
         * @param filterSliderPropertyNamesArr if present, contains an array of slider property names
         *  (such as qualitaetvorbereitungsarbeiten, qualitaetGrundstueck). If array is present, only presets
         *  the properties present in this array.
         */
        function setSliderModelValuesArr(preset_arr, preset_i_arr, realEstateContainer, isPresetSliderModelValuesEnabled,
                                         filterSliderPropertyNamesArr) {
            var q = realEstateContainer.quality;

            // complexity
            var fensterAnteil = preset_arr[3];
            var i_fensterAnteil = preset_i_arr[3];
            var qualitaetDerGebaeudeform2 = preset_arr[4];
            var i_qualitaetDerGebaeudeform2 = preset_i_arr[4];
            var qualitaetVorfertigung = 2;
            var i_qualitaetVorfertigung = undefined;
            var qualitaetvorbereitungsarbeiten = null;
            var i_qualitaetvorbereitungsarbeiten = null;
            if (realEstateContainer.metaData.withdrawalProductType !== 'BUILDING_INSURANCE_VALUES') {
                qualitaetvorbereitungsarbeiten = preset_arr[2];
                i_qualitaetvorbereitungsarbeiten = preset_i_arr[2];
            }
            var qualitaetGrundstueck = preset_arr[1];
            var i_qualitaetGrundstueck = preset_i_arr[1];

            // quality
            var qualitaetUmgebung = null;
            var i_qualitaetUmgebung = null;
            if (realEstateContainer.metaData.withdrawalProductType !== 'BUILDING_INSURANCE_VALUES') {
                qualitaetUmgebung = preset_arr[0];
                i_qualitaetUmgebung = preset_i_arr[0];
            }
            var qualitaetAusbau1 = preset_arr[8];
            var i_qualitaetAusbau1 = preset_i_arr[8];
            var qualitaetAusbau2 = preset_arr[9];
            var i_qualitaetAusbau2 = preset_i_arr[9];
            var qualitaetSanitaeranlage = preset_arr[7];
            var i_qualitaetSanitaeranlage = preset_i_arr[7];
            var qualitaetHeizungsanlage = preset_arr[6];
            var i_qualitaetHeizungsanlage = preset_i_arr[6];
            var qualitaetElektroAnlage = preset_arr[5];
            var i_qualitaetElektroAnlage = preset_i_arr[5];

            if (isPresetSliderModelValuesEnabled) {
                if (!!filterSliderPropertyNamesArr) {
                    // only set if any additional amount is present and beq 1
                    if (_.includes(filterSliderPropertyNamesArr, 'qualitaetvorbereitungsarbeiten')) {
                        q.qualitaetvorbereitungsarbeiten = SliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten);
                    }
                    if (_.includes(filterSliderPropertyNamesArr, 'qualitaetGrundstueck')) {
                        q.qualitaetGrundstueck = SliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck);
                    }
                    if (_.includes(filterSliderPropertyNamesArr, 'qualitaetDerGebaeudeform2')) {
                        q.qualitaetDerGebaeudeform2 = SliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2);
                    }
                    if (_.includes(filterSliderPropertyNamesArr, 'qualitaetSanitaeranlage')) {
                        q.qualitaetSanitaeranlage = SliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage);
                    }
                    if (_.includes(filterSliderPropertyNamesArr, 'qualitaetElektroAnlage')) {
                        q.qualitaetElektroAnlage = SliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage);
                    }
                } else {
                    q.fensterAnteil = SliderIdentMappingService.getSliderIdentByMore(fensterAnteil);
                    q.qualitaetDerGebaeudeform2 = SliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2);
                    q.qualitaetVorfertigung = SliderIdentMappingService.getSliderIdentByLess(qualitaetVorfertigung);
                    q.qualitaetvorbereitungsarbeiten = SliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten);
                    $timeout(function () {
                        q.qualitaetGrundstueck = SliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck);
                    });
                    q.qualitaetUmgebung = SliderIdentMappingService.getSliderIdentByMore(qualitaetUmgebung);
                    q.qualitaetAusbau1 = SliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau1);
                    q.qualitaetAusbau2 = SliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau2);
                    q.qualitaetSanitaeranlage = SliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage);
                    q.qualitaetHeizungsanlage = SliderIdentMappingService.getSliderIdentByMore(qualitaetHeizungsanlage);
                    $timeout(function () {
                        q.qualitaetElektroAnlage = SliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage);
                    });
                }

            }

            $timeout(function () {
                setPresetMark("realEstateContainer.quality.fensterAnteil", SliderIdentMappingService.getSliderIdentByMore(fensterAnteil), i_fensterAnteil);
                setPresetMark("realEstateContainer.quality.qualitaetDerGebaeudeform2", SliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2), i_qualitaetDerGebaeudeform2);
                setPresetMark("realEstateContainer.quality.qualitaetVorfertigung", SliderIdentMappingService.getSliderIdentByLess(qualitaetVorfertigung), i_qualitaetVorfertigung);
                if (qualitaetvorbereitungsarbeiten) {
                    setPresetMark("realEstateContainer.quality.qualitaetvorbereitungsarbeiten", SliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten), i_qualitaetvorbereitungsarbeiten);
                }
                setPresetMark("realEstateContainer.quality.qualitaetGrundstueck", SliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck), i_qualitaetGrundstueck);
                if (qualitaetUmgebung) {
                    setPresetMark("realEstateContainer.quality.qualitaetUmgebung", SliderIdentMappingService.getSliderIdentByMore(qualitaetUmgebung), i_qualitaetUmgebung);
                }
                setPresetMark("realEstateContainer.quality.qualitaetAusbau2", SliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau2), i_qualitaetAusbau2);
                setPresetMark("realEstateContainer.quality.qualitaetAusbau1", SliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau1), i_qualitaetAusbau1);
                setPresetMark("realEstateContainer.quality.qualitaetSanitaeranlage", SliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage), i_qualitaetSanitaeranlage);
                setPresetMark("realEstateContainer.quality.qualitaetHeizungsanlage", SliderIdentMappingService.getSliderIdentByMore(qualitaetHeizungsanlage), i_qualitaetHeizungsanlage);
                setPresetMark("realEstateContainer.quality.qualitaetElektroAnlage", SliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage), i_qualitaetElektroAnlage);

            });
        }

        function correctUsages(usages) {
            if (typeof usages !== 'undefined' && usages.length > 0 && usages[0].type && usages[0].standard) {
                if (usages.length === 1) {
                    return usages;
                } else if (usages.length === 2 && (angular.isUndefined(usages[1].type) || usages[1].type === null || !usages[1].type) && (angular.isUndefined(usages[1].standard) || usages[1].standard === null || !usages[1].standard)) { //standard doesn't matter
                    return [usages[0]];
                } else if (usages.length > 1 && isUsagesReady(usages)) {
                    $rootScope.$broadcast('showSliderPresetTooltip');
                    return usages;
                    // Use outcommented code below if you want to remove VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN from usages
                    /*
                    $rootScope.$broadcast('showSliderPresetTooltip');
                    var correctedUsages = [];
                    for (var usage in usages) {
                        var usageObj = usages[usage];
                        if (usageObj.type !== 'VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN') {
                            correctedUsages.push(usageObj);
                        }
                    }
                    return correctedUsages;
                     */
                }
            } else {
                return undefined;
            }
        }

        function setPresetMark(referenceStr, value, interval) {
            $rootScope.$broadcast("setPresetMark", {
                referenceStr: referenceStr,
                value: value,
                interval: interval
            });
        }

        function arr_applyWeight(weightedArrEntry, entry, weight, isMatrix) {
            if (isMatrix) {
                // case entry and weightedArrEntry are of type Float32Array and have same size
                return weightedArrEntry.map(function (wae, i) {
                    if (weight === 0) {
                        return wae;
                    } else {
                        return wae + weight * entry[i];
                    }
                });
            } else {
                // case entry and weightedArrEntry are of type float
                if (weight === 0) {
                    return weightedArrEntry;
                } else {
                    return weightedArrEntry + weight * entry;
                }
            }
        }

        function arr_div(weightedArrEntry, dividend, isMatrix) {
            if (isMatrix) {
                // case weightedArrEntry is of type Float32Array
                return weightedArrEntry.map(function (wae) {
                    return wae / dividend;
                });
            } else {
                return weightedArrEntry / dividend;
            }
        }

        function arr_createArr(size, isMatrix) {
            if (isMatrix) {
                // in case the return array contains Float32Array's as elements
                return new Array(size).fill(new Float32Array(7));
            } else {
                return new Float32Array(size);
            }
        }

        function getWeightedPresetValues(usages, realEstateContainer,
                                         newValueMinergieStandard, newValuePv, newValueOvergroundFloors, newValueUndergroundFloors) {
            return getWeightedPresetValuesOrIntervals(usages, false, realEstateContainer,
                newValueMinergieStandard, newValuePv, newValueOvergroundFloors, newValueUndergroundFloors);
        }

        function getWeightedPresetIntervals(usages) {
            return getWeightedPresetValuesOrIntervals(usages, true);
        }

        /**
         * If isIntervals is true => the preset entry is a FloatArray consisting of 5 entries [lower whisker, p25, p50, p75, upper whisker]
         *
         * @param usages array containing usage objects. Each usage object contains percentage and type.
         * @param isIntervals Indicates whether intervals (FloatArrays := Intervals) or single values (Double := Non intervals) are preset entries.
         * @param realEstateContainer real estate, must be present
         * @param newValueMinergieStandard if present, is the new value for minergie standard, old value is still present in realEstateContainer
         * @param newValuePv if present, is the new value for pv, old value is still present in realEstateContainer
         * @param newValueOvergroundFloors if present, is the new value for pv, old value is still present in realEstateContainer
         * @param newValueUndergroundFloors if present, is the new value for pv, old value is still present in realEstateContainer
         * @returns {*|Float32Array}
         */
        function getWeightedPresetValuesOrIntervals(usages, isIntervals,
                                                    realEstateContainer,
                                                    newValueMinergieStandard, newValuePv,
                                                    newValueOvergroundFloors, newValueUndergroundFloors) {
            //            // newValue
            var usageSliderMapping = undefined;
            if (isIntervals) {
                usageSliderMapping = UsageQuantilesService.getMapping();
            } else {
                usageSliderMapping = UsageSliderMappingService.getMapping();
            }

            var arrs = [];
            var weights = [];
            for (var usage in usages) {
                var usageObj = usages[usage];
                var arr = undefined;
                if (!isIntervals) {
                    if (!!usageObj.type && !!usageObj.standard) {
                        arr = usageSliderMapping[usageObj.type][usageObj.standard].slice();
                    }
                } else {
                    arr = usageSliderMapping[usageObj.type].slice();
                }
                if (arr) {
                    arrs.push(arr);
                    if (usageObj.percentage) {
                        if (usageObj.type === 'VERKEHRSBAUTEN__TIEFGARAGEN_EINSTELLHALLEN' || usageObj.type === 'VERKEHRSBAUTEN__GARAGEN_FREISTEHEND') {
                            var p = parseInt(usageObj.percentage) - 20;
                            if (p < 0) {
                                p = 0;
                            }
                            weights.push(p);
                        } else {
                            weights.push(parseInt(usageObj.percentage));
                        }
                    }
                }
            }
            if (arrs.length > 0) {
                var weightedArr = arr_createArr(arrs[0].length, isIntervals);
                if (weights.length > 1) {
                    var sumWeightsArr = new Float32Array(arrs[0].length).fill(0);
                    for (var i = 0; i < weights.length; i++) {
                        var curArr = arrs[i];
                        var curWeight = weights[i] / 100;
                        if (angular.isUndefined(curWeight)) {
                            curWeight = 1;
                        }
                        for (var j = 0; j < curArr.length; j++) {
                            //weightedArr[j] = weightedArr[j] + curWeight * curArr[j];
                            var curWeightInner = curWeight;
                            if (isIntervals && (!Array.isArray(curArr[j]) || !curArr[j].length)) {
                                // curArr: Array does not exist, is not an array or is empty
                                // this is an expected case that occurs on usages the boxplot calculation should not be based on, e.g. Tiefgarage
                                curWeightInner = 0;
                                curArr[j] = new Float32Array(arrs[0].length).fill(0);
                            }
                            if (!isIntervals) {
                                var w = isNaN(curArr[j]) ? 0 : curWeightInner;
                                sumWeightsArr[j] += w;
                                weightedArr[j] = arr_applyWeight(weightedArr[j], curArr[j], w, isIntervals);
                            } else {
                                w = curWeightInner;
                                sumWeightsArr[j] += w;
                                weightedArr[j] = arr_applyWeight(weightedArr[j], curArr[j], w, isIntervals);
                            }

                        }
                    }
                    for (var i = 0; i < weightedArr.length; i++) {
                        weightedArr[i] = arr_div(weightedArr[i], sumWeightsArr[i], isIntervals);
                    }
                } else {
                    weightedArr = arrs[0];
                }

                // apply special rules (Spezial-Regelwerk)
                if (!isIntervals) {
                    // calc additional amounts
                    var overgroundFloors = realEstateContainer.geometry.overgroundFloors;
                    if (angular.isDefined(newValueOvergroundFloors)) {
                        overgroundFloors = newValueOvergroundFloors;
                    }
                    var undergroundFloors = realEstateContainer.geometry.undergroundFloors;
                    if (angular.isDefined(newValueUndergroundFloors)) {
                        undergroundFloors = newValueUndergroundFloors;
                    }
                    var minergieStandard = realEstateContainer.quality.minergieStandard;
                    if (angular.isDefined(newValueMinergieStandard)) {
                        minergieStandard = newValueMinergieStandard;
                    }
                    var pv = realEstateContainer.quality.pv;
                    if (angular.isDefined(newValuePv)) {
                        pv = newValuePv;
                    }

                    if (!!weightedArr[2]) {
                        var amountPrep = _amountPreparatoryWork(overgroundFloors, undergroundFloors);
                        weightedArr[2] += amountPrep;
                    }

                    var amountLot = _amountLot(overgroundFloors, undergroundFloors);
                    weightedArr[1] += amountLot;

                    var amountBuildingForm = _amountBuildingForm(overgroundFloors, undergroundFloors);
                    weightedArr[4] += amountBuildingForm;

                    var amountSanitary = _amountSanitary(overgroundFloors, undergroundFloors);
                    weightedArr[7] += amountSanitary;

                    var amountElectric = _amountElectric(weightedArr[5], minergieStandard, pv);
                    weightedArr[5] += amountElectric;
                }

                return weightedArr;
            }
        }

        function isUsagesReady(usages) {
            try {
                var totalPercentage = 0;
                for (var usage in usages) {
                    var usageObj = usages[usage];
                    // continue if usages not selected (empty row)
                    if (!angular.isUndefined(usageObj.type) && usageObj.type !== null && usageObj.type !== "" && !angular.isUndefined(usageObj.standard) && usageObj.standard !== null && usageObj.standard !== "") {
                        if (!usageObj.percentage) {
                            // usages set, but no percentage set => usages is not ready
                            return false;
                        } else {
                            totalPercentage += parseInt(usageObj.percentage);
                        }
                    }
                }
                return totalPercentage === 100;
            } catch (error) {
                console.error("Unexpected error in isUsageReady", usages, error);
                return false;
            }
        }

        function requestPresetEvent(realEstateContainer, referenceStr) {
            var usages = realEstateContainer.usages;
            if (realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' || realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS') {
                usages = realEstateContainer.targetOverhaul.usages;
            }
            var correctUsages = this.correctUsages(usages);
            if (correctUsages !== undefined) {
                var preset_arr = this.getWeightedPresetValues(correctUsages, realEstateContainer);
                var preset_i_arr = this.getWeightedPresetIntervals(correctUsages);

                switch (referenceStr) {
                case 'realEstateContainer.quality.fensterAnteil':
                    var fensterAnteil = SliderIdentMappingService.getSliderIdentByMore(preset_arr[3]);
                    var i_fensterAnteil = preset_i_arr[3];
                    return { preset: fensterAnteil, interval: i_fensterAnteil };

                case 'realEstateContainer.quality.qualitaetDerGebaeudeform2':
                    var qualitaetDerGebaeudeform2 = preset_arr[4];
                    var amountBuildingForm = _amountBuildingForm(realEstateContainer.geometry.overgroundFloors, realEstateContainer.geometry.undergroundFloors);
                    qualitaetDerGebaeudeform2 += amountBuildingForm;
                    var i_qualitaetDerGebaeudeform2 = preset_i_arr[4];
                    return {preset: SliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2), interval: i_qualitaetDerGebaeudeform2};

                case "realEstateContainer.quality.qualitaetVorfertigung":
                    var amountVorfertigung = _amountPreparatoryWork(realEstateContainer.geometry.overgroundFloors, realEstateContainer.geometry.undergroundFloors);
                    var qualitaetVorfertigung = SliderIdentMappingService.getSliderIdentByLess(2);
                    qualitaetVorfertigung += amountVorfertigung;
                    var i_qualitaetVorfertigung = undefined;
                    return {preset: qualitaetVorfertigung, interval: i_qualitaetVorfertigung};

                case "realEstateContainer.quality.qualitaetvorbereitungsarbeiten":
                    var amountVorbereitungsarbeiten = _amountPreparatoryWork(realEstateContainer.geometry.overgroundFloors, realEstateContainer.geometry.undergroundFloors);
                    var qualitaetvorbereitungsarbeiten = SliderIdentMappingService.getSliderIdentByMore(preset_arr[2]);
                    qualitaetvorbereitungsarbeiten += amountVorbereitungsarbeiten;
                    var i_qualitaetvorbereitungsarbeiten = preset_i_arr[2];
                    return {preset: qualitaetvorbereitungsarbeiten, interval: i_qualitaetvorbereitungsarbeiten};

                case "realEstateContainer.quality.qualitaetGrundstueck":
                    var amountLot = _amountLot(realEstateContainer.geometry.overgroundFloors, realEstateContainer.geometry.undergroundFloors);
                    var qualitaetGrundstueck = SliderIdentMappingService.getSliderIdentByMore(preset_arr[1]);
                    qualitaetGrundstueck += amountLot;
                    var i_qualitaetGrundstueck = preset_i_arr[1];
                    return {preset: qualitaetGrundstueck, interval: i_qualitaetGrundstueck};

                case "realEstateContainer.quality.qualitaetUmgebung":
                    var qualitaetUmgebung = SliderIdentMappingService.getSliderIdentByMore(preset_arr[0]);
                    var i_qualitaetUmgebung = preset_i_arr[0];
                    return {preset: qualitaetUmgebung, interval: i_qualitaetUmgebung};

                case "realEstateContainer.quality.qualitaetAusbau1":
                    var qualitaetAusbau1 = SliderIdentMappingService.getSliderIdentByMore(preset_arr[8]);
                    var i_qualitaetAusbau1 = preset_i_arr[8];
                    return {preset: qualitaetAusbau1, interval: i_qualitaetAusbau1};

                case "realEstateContainer.quality.qualitaetAusbau2":
                    var qualitaetAusbau2 = SliderIdentMappingService.getSliderIdentByMore(preset_arr[9]);
                    var i_qualitaetAusbau2 = preset_i_arr[9];
                    return {preset: qualitaetAusbau2, interval: i_qualitaetAusbau2};

                case "realEstateContainer.quality.qualitaetSanitaeranlage":
                    var qualitaetSanitaeranlage = SliderIdentMappingService.getSliderIdentByMore(preset_arr[7]);
                    var i_qualitaetSanitaeranlage = preset_i_arr[7];
                    return {preset: qualitaetSanitaeranlage, interval: i_qualitaetSanitaeranlage};

                case "realEstateContainer.quality.qualitaetHeizungsanlage":
                    var qualitaetHeizungsanlage = SliderIdentMappingService.getSliderIdentByMore(preset_arr[6]);
                    var i_qualitaetHeizungsanlage = preset_i_arr[6];
                    return {preset: qualitaetHeizungsanlage, interval: i_qualitaetHeizungsanlage};

                case "realEstateContainer.quality.qualitaetElektroAnlage":
                    var qualitaetElektroAnlage = SliderIdentMappingService.getSliderIdentByMore(preset_arr[5]);
                    var i_qualitaetElektroAnlage = preset_i_arr[5];
                    return {preset: qualitaetElektroAnlage, interval: i_qualitaetElektroAnlage};
                default:
                    return {preset: null, interval: null};
                }
            } else {
                return {preset: null, interval: null};
            }
        }
        return service;
    }
})();
