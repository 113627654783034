(function() {
    'use strict';

    angular.module('kennwerteApp')
        .component('camtFileUpload', {
            bindings: {

            },
            templateUrl: 'app/admin/organisation-management/camt_file_upload/camt-file-upload.tpl.html',
            controller: CamtFileUploadController
        });

    CamtFileUploadController.$inject = ['$scope', 'CamtService', '$uibModal'];

    function CamtFileUploadController($scope, CamtService, $uibModal) {
        var $ctrl = this;

        $ctrl.uploadCamtFile = function (file, invalidFiles) {
            $ctrl.uploadStarted = true;
            CamtService.upload(
                file,
                invalidFiles,
                function successFun(data) {
                    $ctrl.uploadStarted = false;
                    var matchedCamtDto = data;
                    // open modal
                    $uibModal.open({
                        animation: true,
                        templateUrl: 'app/admin/organisation-management/camt_file_processing_dialog/camt-file-processing-dialog.tpl.html',
                        controller: 'CamtFileProcessingDialogController',
                        scope: $scope,
                        controllerAs: 'vm',
                        size: 'lg',
                        backdrop: 'static',
                        keyboard: false,
                        resolve: {
                            matchedCamtDto: matchedCamtDto,
                            fileName: function() {
                                return file.name;
                            }
                        }
                    });

                },
                function errorFun(response) {
                    $ctrl.uploadStarted = false;
                    // show error message (response.status & response.data?)
                    if (response.status !== 500) {
                        alert(response.headers('error-msg'));
                    }


                })
        }

        this.$onInit = function () {
            $ctrl.uploadStarted = false;
        };

    }

})();
