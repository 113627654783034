(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('RebuildActionService', RebuildActionService);

    RebuildActionService.$inject = ['$q', '$rootScope', '$log', '$timeout', '$sessionStorage', 'RebuildEstateDataService', 'SharedControllerFnService', 'RebuildTemplateService', 'RebuildGeometryService', 'RebuildTargetTypeDataService', 'ValidationFunctionsService', 'ValidationDataService', 'RebuildDemolitionService', '$translate'];

    function RebuildActionService($q, $rootScope, $log, $timeout, $sessionStorage, RebuildEstateDataService, SharedControllerFnService, RebuildTemplateService, RebuildGeometryService, RebuildTargetTypeDataService, ValidationFunctionsService, ValidationDataService, RebuildDemolitionService, $translate) {

        var service = {
            realEstateLoaded: realEstateLoaded,
            processRoofInput: processRoofInput,
            processTemplateChanged: processTemplateChanged,
            processUsageInput: processUsageInput,
            copyRoofInput: copyRoofInput,
            addAnalysisComponent: addAnalysisComponent,
            removeAnalysisComponent: removeAnalysisComponent,
            processAnalysisQuantityUpdate: processAnalysisQuantityUpdate,
            annexLimitChecker: annexLimitChecker,
            fetchComponentToNum: fetchComponentToNum,
            totalFloorArea416Changed: totalFloorArea416Changed,
            totalVolume416Changed: totalVolume416Changed,
            isFacadeTypeChanged: isFacadeTypeChanged,
            checkIfBuiltUpAreaAddition: checkIfBuiltUpAreaAddition
        };
        var realEstateContainer = {};
        var components = {};
        var groupedComponents = {};
        var groupedChildrenComponents = {};
        var groupedChildrenNoUserInputComponents = {};

        //this dict and even other properties are good candidates for their own service.
        var dictComponentToNum = {};

        function _fetchRealEstate() {
            realEstateContainer = RebuildEstateDataService.get();
        }

        function fetchComponentToNum() {
            _fetchRealEstate();
            if (angular.equals({}, dictComponentToNum)) {
                realEstateContainer.analysis.components.forEach(function(c) {
                    var numBefore = dictComponentToNum[c.rebuildEstateComponent];
                    if (!numBefore) {
                        numBefore = 0;
                    }
                    dictComponentToNum[c.rebuildEstateComponent] = numBefore + 1;
                    RebuildTemplateService.register(c.rebuildEstateComponent, c.templateTypes);
                });
            }
            return dictComponentToNum;
        }

        function processRoofInput(newValue, oldValue, args) {
            $log.info('processRoofInput: ', newValue, oldValue, args);
            _updateDistributionRebuildEstateComponent('FLATROOF', args);
        }

        function copyRoofInput(newValue, oldValue, args) {
            $log.info('copyRoofInput: ', newValue, oldValue, args);
            _fetchRealEstate();
            if (newValue === oldValue) return;
            if (realEstateContainer.targetOverhaul != null && _.isArray(realEstateContainer.quality.roofTypes) || oldValue === realEstateContainer.targetOverhaul.roofTypes) {
                angular.copy(newValue, realEstateContainer.targetOverhaul.roofTypes);
                _updateDistributionRebuildEstateComponent('FLATROOF', args);
            }
        }

        /**
         * Processing the event when the Quantity on any AnalysisComponent changes.
         * @param rebuildEstateComponent
         * @param args
         */
        function processAnalysisQuantityUpdate(rebuildEstateComponent, args) {
            $log.info(rebuildEstateComponent, args);
            _updateDistributionRebuildEstateComponent(rebuildEstateComponent, args);
        }

        function totalFloorArea416Changed(newValue, oldValue, args) {
            this.annexLimitChecker(newValue, oldValue, args);
            checkIfDemolitionAddition(realEstateContainer.quality.facadeType);
        }

        function totalVolume416Changed(newValue, oldValue, args) {
            checkIfDemolitionAddition(realEstateContainer.quality.facadeType);
        }

        function isFacadeTypeChanged(newValue, oldValue, args) {
            checkIfDemolitionAddition(newValue);
        }

        function checkIfBuiltUpAreaAddition() {
            console.log('checkIfBuiltUpAreaAddition', realEstateContainer.geometry.builtUpArea, realEstateContainer.targetOverhaul.builtUpArea);
            var isDemolitionBuiltUpAreaHappening = realEstateContainer.geometry.builtUpArea > realEstateContainer.targetOverhaul.builtUpArea;
            var label = RebuildDemolitionService.getDemolitionBuiltUpAreaAdditionLabel();
            var demolitionBuiltUpAreaAdditionIndex = RebuildDemolitionService.findIndexOfDemolitionBuiltUpAreaAddition(realEstateContainer.additions);
            if (isDemolitionBuiltUpAreaHappening) {
                RebuildDemolitionService.calcSurroundingAreaCosts(
                    realEstateContainer.geometry.builtUpArea,
                    realEstateContainer.targetOverhaul.builtUpArea
                ).then(function(response) {
                    var value = Math.round(response.data / 100) * 100;
                    if (demolitionBuiltUpAreaAdditionIndex === -1) {
                        console.info('Demolition BuiltUpArea: insert new "' + label + '" addition', value);
                        realEstateContainer.additions.unshift({
                            label: label,
                            value: value,
                            bkp: 'BKP_1_PREPARATIONS',
                            targetType: RebuildTargetTypeDataService.OVERHAUL
                        });
                    } else {
                        console.info('Demolition BuiltUpArea: update "' + label + '" addition', value);
                        realEstateContainer.additions[demolitionBuiltUpAreaAdditionIndex].value = value;
                    }
                });
            } else {
                if (demolitionBuiltUpAreaAdditionIndex !== -1) {
                    console.info('Demolition BuiltUpArea: delete "' + label + '" addition');
                    realEstateContainer.additions.splice(demolitionBuiltUpAreaAdditionIndex, 1);
                }
            }
        }

        /**
         * Check if there is a volume or area change from "is" object to "target overhaul" object.
         * If there is a change add a demolition addition entry.
         */
        function checkIfDemolitionAddition(currentFacadeType) {
            var isDemolitionVolumeHappening = realEstateContainer.geometry.totalVolume416 > realEstateContainer.targetOverhaul.totalVolume416;
            var label = RebuildDemolitionService.getDemolitionAdditionLabel();
            var demolitionAdditionIndex = RebuildDemolitionService.findIndexOfDemolitionAddition(realEstateContainer.additions);
            if (isDemolitionVolumeHappening) {
                RebuildDemolitionService.calcVolumeCosts(
                    realEstateContainer.geometry.totalVolume416,
                    realEstateContainer.targetOverhaul.totalVolume416,
                    currentFacadeType
                ).then(function(response) {
                    var value = Math.round(response.data / 100) * 100;
                    if (demolitionAdditionIndex === -1) {
                        console.info('Demolition: insert new "' + label + '" addition', value);
                        realEstateContainer.additions.unshift({
                            label: label,
                            value: value,
                            bkp: 'BKP_1_PREPARATIONS',
                            targetType: RebuildTargetTypeDataService.OVERHAUL
                        });
                    } else {
                        console.info('Demolition: update "' + label + '" addition', value);
                        realEstateContainer.additions[demolitionAdditionIndex].value = value;
                    }
                });
            } else {
                if (demolitionAdditionIndex !== -1) {
                    console.info('Demolition: delete "' + label + '" addition');
                    realEstateContainer.additions.splice(demolitionAdditionIndex, 1);
                }
            }
        }

        function annexLimitChecker(newValue, oldValue, args) {
            _fetchRealEstate();
            // $log.info(newValue, oldValue, args);
            var ret = ValidationFunctionsService.annexLimitChecker(realEstateContainer);
            if (ret.elements.length > 0) {
                angular.forEach(ret.elements, function(value, key) {
                    ValidationDataService.showValidationError(value, ret.messages[key]);
                });
            }
        }

        function addAnalysisComponent(rebuildEstateComponent, component, index, args) {
            _fetchRealEstate();
            prepareAnalysisComponents();
            //we add two children if there weren't any children before.
            if (getNumberOfChildren(rebuildEstateComponent) === 0) {
                component.parent = true;
                doAddComponent();
                doAddComponent(); //we add two
            } else {
                doAddComponent();
            }

            function doAddComponent() {
                var copy = angular.copy(component);
                copy.parent = false;
                copy.totalMeasure.setInterventionGradeQuantityBackend(0.0);
                realEstateContainer.analysis.components.splice(index + 1, 0, copy);
                dictComponentToNum[rebuildEstateComponent] += 1;
                return copy;
            }

            function getNumberOfChildren(rebuildEstateComponent) {
                return dictComponentToNum[rebuildEstateComponent] - 1;
            }

            _updateDistributionRebuildEstateComponent(rebuildEstateComponent, { caller: 'internal' });
        }

        function removeAnalysisComponent(rebuildEstateComponent, component, index, args) {
            _fetchRealEstate();
            prepareAnalysisComponents();
            if (dictComponentToNum[rebuildEstateComponent] === 3) {
                var currentIndex = index;
                var parentIndex = currentIndex - 1;
                //check if last component then we need to move index pointer for one more position.
                var isLast = realEstateContainer.analysis.components[currentIndex + 1].rebuildEstateComponent !== rebuildEstateComponent;
                if (realEstateContainer.analysis.components[currentIndex - 1].rebuildEstateComponent === rebuildEstateComponent) {
                    if (isLast) {
                        currentIndex--;
                        parentIndex--;
                    }
                    realEstateContainer.analysis.components[parentIndex].parent = false;
                    realEstateContainer.analysis.components.splice(currentIndex, 2);
                }
                dictComponentToNum[rebuildEstateComponent] -= 2;
            } else {
                realEstateContainer.analysis.components.splice(index, 1);
                dictComponentToNum[rebuildEstateComponent] -= 1;
            }
            _updateDistributionRebuildEstateComponent(rebuildEstateComponent, { caller: 'internal' });
        }


        function _updateDistributionRebuildEstateComponent(rebuildEstateComponent, args) {

            function sumUserInputFor(rec) {
                var result = _.sum(
                    _.map(groupedChildrenComponents[rec], function(value) {
                        return (value.totalMeasure === undefined) ? 0 : +value.totalMeasure.interventionGradeQuantityUserInput;
                    })
                );
                return Number.isFinite(result) ? Number(result) : 0;
            }

            function uniformDistributeValue(length, value) {
                return chainNormalizeWithDistribution(
                    _.fill(Array(length), value / length),
                    value
                );
            }

            function setDistributionForChildren(children, distributionForChildren) {
                _.forEach(children, function(value) {
                    var component = _.find(realEstateContainer.analysis.components, value);
                    if (component.totalMeasure.interventionGradeQuantityUserInput == null) {
                        component.totalMeasure.setInterventionGradeQuantityBackend(distributionForChildren.shift());
                    } else {
                        //if we have a userInput we set BackendValue to null
                        component.totalMeasure.setInterventionGradeQuantityBackend(null);
                    }
                });
            }

            _fetchRealEstate();
            prepareAnalysisComponents();
            var remainingTargetPercentage;
            switch (rebuildEstateComponent) {
            case 'INCLINEDROOF':
            case 'FLATROOF':
                if (realEstateContainer.targetOverhaul.roofTypes.length > 0) {

                    remainingTargetPercentage = 100 - sumUserInputFor('INCLINEDROOF') - sumUserInputFor('FLATROOF');

                    if (remainingTargetPercentage > 0) {

                        var allRoofChildrenWithNoUserInputs = _.flatMap(realEstateContainer.targetOverhaul.roofTypes, function(roofType) {
                            return groupedChildrenNoUserInputComponents[roofType] || [];
                        });

                        if (allRoofChildrenWithNoUserInputs.length > 0) {

                            var allRoofChildren = _.flatMap(realEstateContainer.targetOverhaul.roofTypes, function(roofType) {
                                return groupedChildrenComponents[roofType];
                            });

                            setDistributionForChildren(
                                allRoofChildren,
                                uniformDistributeValue(
                                    Math.max(allRoofChildrenWithNoUserInputs.length, 1),
                                    remainingTargetPercentage
                                )
                            );
                        }
                    }
                }

                break;

            default:
                remainingTargetPercentage = 100 - sumUserInputFor(rebuildEstateComponent);

                if (remainingTargetPercentage > 0 && groupedChildrenNoUserInputComponents[rebuildEstateComponent] !== undefined) {
                    // split remainingTargetPercentage for children of rebuildEstateComponent with no user input
                    setDistributionForChildren(
                        groupedChildrenComponents[rebuildEstateComponent],
                        uniformDistributeValue(
                            Math.max(groupedChildrenNoUserInputComponents[rebuildEstateComponent].length, 1),
                            remainingTargetPercentage
                        )
                    );
                }

                break;

            }
        }

        /**
         * It takes a list of numbers and a target number, and returns a list of numbers that are as close to the original
         * numbers as possible, but whose sum is equal to the target number
         *
         * Example:
         *
         * _distributeValuesToMatchTarget([13.626332, 5.589636, 9.506008, 28.688024, 42.5], 100) => [14, 6, 10, 28, 42]
         * @param l - the list of values to distribute
         * @param target - The target number of items to distribute
         * @returns an array of rounded values.
         */
        function _distributeValuesToMatchTarget(l, target) {
            var roundError = target - _.reduce(l, function(acc, x) {
                return acc + Math.round(x);
            }, 0);
            return _.chain(l)
                .map(function(val, i) {
                    // Fix roundError by ceiling or flooring certain elements, so that sum of result is equal to target
                    var f = Math.round;
                    if (roundError > i) f = Math.ceil;
                    if (i >= l.length + roundError) f = Math.floor;
                    return f(val);
                }).value();
        }

        function _normalizeArrayToTarget(l, target) {
            var sumL = _.sum(l);
            return _.map(l, function(value, i, arr) {
                return value * target / sumL;
            });
        }

        function chainNormalizeWithDistribution(l, target) {
            var normL = _normalizeArrayToTarget(l, target);
            var distri = _distributeValuesToMatchTarget(normL, target);
            return _.map(distri, function(value, key) {
                if (Number.isNaN(value)) {
                    return 0;
                } else {
                    return value;
                }
            });
        }


        /**
         * Includes also parent components.
         * @param componentName
         * @returns {*}
         */
        function findComponentIndices(componentName) {
            prepareAnalysisComponents();
            // var founded = _.filter(components, { 'rebuildEstateComponent': componentName });
            var result = _.reduce(components, function(result, value, key) {
                if (value.rebuildEstateComponent === componentName) result.push(key);
                return result;
            }, []);
            return result;
        }

        function prepareAnalysisComponents() {
            components = angular.copy(realEstateContainer.analysis.components);
            var childComponents = components.filter(function(x) {
                return !x.parent;
            });
            var childrenNoUserInputComponents = components.filter(function(x) {
                return !x.parent && x.totalMeasure !== undefined && x.totalMeasure.interventionGradeQuantityUserInput == null;
            });
            groupedComponents = groupBy(components, 'rebuildEstateComponent');
            groupedChildrenComponents = groupBy(childComponents, 'rebuildEstateComponent');
            groupedChildrenNoUserInputComponents = groupBy(childrenNoUserInputComponents, 'rebuildEstateComponent');
        }

        function groupBy(arr, criteria) {
            return arr.reduce(function(obj, item) {

                // Check if the criteria is a function to run on the item or a property of it
                var key = typeof criteria === 'function' ? criteria(item) : item[criteria];

                // If the key doesn't exist yet, create it
                if (!obj.hasOwnProperty(key)) {
                    obj[key] = [];
                }

                // Push the value to the object
                obj[key].push(item);

                // Return the object to the next item in the loop
                return obj;

            }, {});
        }

        /**
         * Currently only normalizes array length to the same from every usage container.
         * @param newValue
         * @param oldValue
         * @param args
         */
        function processUsageInput(newValue, oldValue, args) {
            _fetchRealEstate();
            $log.info(newValue, oldValue, args);
            SharedControllerFnService.normalizeRebuildUsages(realEstateContainer);
            //here we could also check if newValue is valid for Chiller usage and enable chiller input.
        }

        function processTemplateChanged(newValue, oldValue, args) {
            function refreshCalcRow(newValue, oldValue, args) {
                _fetchRealEstate();
                $rootScope.$broadcast('refreshCalcRow', { rowId: args.rowid });
            }

            console.warn(args);
            RebuildGeometryService.refresh(newValue);
            refreshCalcRow(newValue, oldValue, args);
            RebuildTargetTypeDataService.updateActive(RebuildGeometryService.isAnnex, RebuildGeometryService.isAdditionStory);
        }

        function realEstateLoaded(realEstate) {
            RebuildGeometryService.refresh(realEstate.selectedTemplates);
        }

        //PLEASE READ
        /** the call back must be passed down to the component with the exact same name (newValue,oldvalue,args) https://tech.europace.de/post/passing-functions-to-angularjs-directives/
         * Please use this duplicate this template function. on args always add an origin key with hints from who the issuer is. e.g. $attrs.reference
         * @param newValue
         * @param oldValue
         * @param args some additional info can be passed here always contains an origin property.
         */
        function templateFun(newValue, oldValue, args) {
            //do something meaningful. :)

        }

        return service;
    }
})();
