(function () {
    'use strict';

    angular
        .module('kennwerteApp')
        .controller('BillingModalController', BillingModalController);

    BillingModalController.$inject = ['$uibModalInstance', 'moduleNames'];

    function BillingModalController($uibModalInstance, moduleNames) {

        var vm = this;

        vm.close = close;
        vm.modules = moduleNames;

        function close() {
            $uibModalInstance.close();
        }
    }

})();



