(function () {
    'use strict';

    angular.module('kennwerteApp')
        .controller('ReferenceFinishFormController', ReferenceFinishFormController);

    ReferenceFinishFormController.$inject = ['$scope',
        '$rootScope',
        '$stateParams',
        'RealEstateReferenceDataService',
        '$filter',
        '$timeout',
        'Principal',
        'GTMService',
        'ValidationDataService',
        'ValidationFunctionsService'
    ];

    function ReferenceFinishFormController($scope,
                                           $rootScope,
                                           $stateParams,
                                           RealEstateReferenceDataService,
                                           $filter,
                                           $timeout,
                                           Principal,
                                           GTMService,
                                           ValidationDataService,
                                           ValidationFunctionsService
    ) {

        $scope.isAdmin = undefined;
        $scope.login = undefined;
        $scope.validationDataService = ValidationDataService;

        ValidationDataService.deregisterValidationFunctions();
        ValidationDataService.setZindex(10000); // higher than modal zindex so we can see validation errors.
        ValidationDataService.registerValidationFunctions([
            ValidationFunctionsService.validateBuildingName,
            ValidationFunctionsService.validateZipCode,
            ValidationFunctionsService.validateReferenceMainUsage,
            ValidationFunctionsService.validateElevators,
            ValidationFunctionsService.validateRatioVolumeUnderTerrain,
            ValidationFunctionsService.validateAreaBuilding416,
            ValidationFunctionsService.validateUsages,
            ValidationFunctionsService.validateBuildingsInConstruction,
            ValidationFunctionsService.validateArea,
            ValidationFunctionsService.validateVolume,
            ValidationFunctionsService.validateBuiltUpArea,
            ValidationFunctionsService.validateOvergroundFloors,
            ValidationFunctionsService.validateUndergroundFloors,
            ValidationFunctionsService.validateRemark,
            ValidationFunctionsService.validateFacadeType,
            ValidationFunctionsService.validateUndergroundParkplace,
            ValidationFunctionsService.validateFileUploadPictures,
            ValidationFunctionsService.validateFileUploadBlueprints,
            ValidationFunctionsService.validateFileUploadBuildingDescription,
            ValidationFunctionsService.validateFileUploadConstructionAccounting
        ]);

        $scope.$on("changeUsages", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        $scope.$on("changeInvestments", function (event, args) {
            ValidationDataService.clientSideRevalidation($scope.realEstateContainer);
        });

        Principal.identity().then(function (account) {
            $scope.isAdmin = account.login === 'admin';
            $scope.login = account.login;
        });
        //DEV FUNCTIONS
        $scope.callService = function () {
            RealEstateReferenceDataService.saveToSession();
        };
        //DEV FUNCTIONS
        $scope.loadFromSession = function () {
            RealEstateReferenceDataService.loadFromSession();
        };
        //DEV FUNCTIONS
        $scope.print = function () {
            console.log(RealEstateReferenceDataService.get());
        };
        //DEV FUNCTIONS
        $scope.loadReference = function (id) {
            RealEstateReferenceDataService.load(id);
        };

        $scope.save = function () {
            if ($rootScope.inProduction && !$scope.isAdmin && $scope.login !== 'video') {
                GTMService.trackEvent({
                    action: 'reference-object-save',
                    category: 'Reference-object'
                });
            }
            RealEstateReferenceDataService.save();
            // FIXME: unfortunately save is non-blocking (async). setCalculatingFalse() should be applied after the save() operation.
            ValidationDataService.setCalculatingFalse();
        };

        $scope.data = $stateParams.data;
        //Set dynamicTranslations for additionsForm.
        $scope.noDynamicTranslations = false;

        $scope.clear = function clear() {
            $scope.$parent.cancel();
        };
        $scope.spawnRealEstateEvent = function () {
            RealEstateReferenceDataService.spawnRealEstateEvent();
        };

        // Validation part from here on down

        $scope.elementsWithError = [];

        $scope.upLoadIsFinished = false;

        $scope.hasClickedSendData = false;

        // $rootScope.$on('uploadFileFinished', function () {
        //     $scope.clear();
        // });

        $scope.$on("allAttachmentsUploaded", function (event, args) {
            $scope.uploadFinishedDiff = args.length - args.counter;
            RealEstateReferenceDataService.spawnRealEstateEvent()
                .then(function () {
                    $scope.realEstateEventSuccess = 'OK';
                    $scope.upLoadIsFinished = true;
                }).catch(function () {
                    $scope.realEstateEventSuccess = 'ERROR';
                    $scope.upLoadIsFinished = true;
                });
        });

        $scope.validateAndProceed = function (onSuccessFn) {
            $scope.hasClickedSendData = true;
            ValidationDataService.validateAndProceed($scope.realEstateContainer, onSuccessFn);
        };

    }

})();
