(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('electroAdvancedSliderInput', ElectroAdvancedSliderInput);

    ElectroAdvancedSliderInput.$inject = ['$rootScope'];

    function ElectroAdvancedSliderInput($rootScope) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                reference: '=reference',
                rowid: '=', // used to determine in what row this directive is placed, so the correct image can be updated
                translateBaseKey: '@translateBaseKey',
                image: '=image',
                required: '=required',
                placeholder: '@?',
                isDisabled: '=',
                minlength: '@',
                maxlength: '@',
                validationMessage: '@',
                mandatory: '=',
                descriptionBaseKey: '@',
                tabIndex: '=',
                tabGroup: '=',
                infotext: '@?'
            },
            templateUrl: 'app/components/advanced_slider_inputs/electro_advanced_slider_input/electro_advanced_slider_input.tpl.html',
            controller: ElectroAdvancedSliderInputController,
            controllerAs: 'vm',
            link: function (scope, tElement, tAttrs, controller, transcludeFn) {
                var input = document.getElementById('inputid');

                scope.doShowInterval = false;
                scope.boxPlotData = [{}];
                scope.wkBoxPlotData = [{}];
                scope.whConfig = undefined;

                var inputField = tElement.find("input")[0];

                if (tAttrs.required) {
                    inputField.required = true;
                }

                // Used for the validation
                $(tElement).attr("id", tAttrs.reference + ".formElement");
                $(inputField).attr("id", tAttrs.reference + ".input");

                // inputField.addEventListener("focus", function () {
                //
                //     // Push event to CalculationRowController
                //     $rootScope.$broadcast("updateImage", {
                //         imageSrc: sanitizeUrl(tAttrs.image),
                //         rowid: Number(tAttrs.rowid)
                //     });
                //     $rootScope.$broadcast("updateDescription", {
                //         rowid: Number(tAttrs.rowid),
                //         descriptionNumber: 0,
                //         descriptionBaseKey: tAttrs.descriptionBaseKey
                //     });
                // }, true);

            }
        };
    }

    ElectroAdvancedSliderInputController.$inject = ['$scope', '$timeout', '$translate', '$rootScope', '$element'];

    function ElectroAdvancedSliderInputController($scope, $timeout, $translate, $rootScope, $element) {

    }

})();
