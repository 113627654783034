(function () {
    'use strict';

    angular.module('kennwerteApp')
        .component('roofTypeSelecter', {
            bindings: {
                reference: '=?',
                realEstate: '=?', // only needed in case isReference is not set
                referencePlaceholder: '=?', // only needed in case isReference is not set
                isReference: '@?', // indicates if this input is used in reference process form
                rowid: '=',
                translateDescriptionBaseKey: '@',
                translateBaseKey: '@',
                image: '=',
                mandatory: '@?',
                placeholder: '@?',
                isDisabled: '=',
                validationMessage: '@?',
                validationMessage2: '@?',
                descriptionBaseKey: '@',
                usages: '<',
                infotext: '@?'
            },
            templateUrl: 'app/components/roof_type_selecter/roof_type_selecter.tpl.html',
            controller: RoofTypeSelecterController
        });

    RoofTypeSelecterController.$inject = ['$scope', '$rootScope', '$attrs', '$element', '$translate'];

    function RoofTypeSelecterController($scope, $rootScope, $attrs, $element, $translate) {
        var $ctrl = this;
        $scope.firstLoad = true;

        $ctrl.model = {};
        $ctrl.answers = ["FLATROOF", "INCLINEDROOF"];

        // use polyfills for IE, TODO: Introduce a proper place for polyfills
        Number.isInteger = Number.isInteger || function (value) {
            return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
        };

        $ctrl.onBlur = function () {
        };

        $ctrl.isEmptyModel = function (model) {
            return angular.equals({}, model);
        };

        $ctrl.translateKey = function (answer) {
            return $ctrl.translateBaseKey +'.' + answer +".label";
        };

        $ctrl.onOpenClose = function (isOpen, type) {
            var descriptionKey;
            var imageKey;
            descriptionKey = $ctrl.descriptionBaseKey;
            imageKey = $ctrl.image;
            if (isOpen) {
                $rootScope.$broadcast("updateDescription", {
                    rowid: $ctrl.rowid,
                    descriptionBaseKey: descriptionKey
                });
                $rootScope.$broadcast("updateImage", {
                    rowid: $ctrl.rowid,
                    imageSrc: imageKey
                });
            }
        };

        $scope.$watch('$ctrl.model', function (selectedItem) {
            if (!$scope.firstLoad && !angular.equals({}, selectedItem)) {
                $rootScope.$broadcast("updateImage", {
                    rowid: $ctrl.rowid,
                    imageSrc: $ctrl.image
                });

                $rootScope.$broadcast("updateDescription", {
                    rowid: $ctrl.rowid,
                    descriptionNumber: selectedItem,
                    descriptionBaseKey: $ctrl.descriptionBaseKey + '.' + selectedItem
                });
            } else {
                $scope.firstLoad = false;
            }
        });


        $ctrl.updateDescriptionAndImage = function (selectedItem, model, string) {
            var tempDescriptionKey;
            var imageKey;
            if (angular.isDefined(selectedItem)) {
                tempDescriptionKey = $ctrl.descriptionBaseKey + '.' + selectedItem;
                imageKey = $ctrl.image;
            } else {
                tempDescriptionKey = $ctrl.descriptionBaseKey;
            }
            $rootScope.$broadcast("updateImage", {
                rowid: $ctrl.rowid,
                imageSrc: imageKey
            });

            $rootScope.$broadcast("updateDescription", {
                rowid: $ctrl.rowid,
                descriptionNumber: selectedItem,
                descriptionBaseKey: tempDescriptionKey
            });
        };

        $scope.$watch('$ctrl.reference', function (newValue) {
            if (newValue) {
                if (newValue) {
                    $ctrl.model = newValue;
                }

            }
        });

        this.$onInit = function () {
            $ctrl.translateLabelKey = $ctrl.translateBaseKey + '.label';
            $ctrl.idForInfoField = $ctrl.translateBaseKey.split('.').join('_');
            if (angular.isDefined($ctrl.reference)) {
                $ctrl.model = $ctrl.reference;
            }
            // Used for the validation
            var toggle = $element.find(".roofTypeDropdown");
            $(toggle).attr("id", $attrs.reference + ".drop2down-toggle");

        };
    }


})();
