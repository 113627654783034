var maxTabIndex = {};

(function () {
    'use strict';

    angular.module('kennwerteApp')
        .directive('tabMan', tabManDirective);

    tabManDirective.$inject = ['$rootScope', '$timeout'];
    function tabManDirective($rootScope, $timeout) { ///directive
        return {
            restrict: 'A', /// accessible only by attribute
            scope: {
                tabIndex: '=',
                tabGroup: '='
            }, /// scope is not needed
            link: function(scope, element, attrs) { ///link function to add key-down event on the target element

                if (maxTabIndex[scope.tabGroup]) {
                    maxTabIndex[scope.tabGroup] = Math.max(maxTabIndex[scope.tabGroup], scope.tabIndex);
                } else {
                    maxTabIndex[scope.tabGroup] = 1;
                }

                /**
                 * Function that indicates to focus a next element.
                 * Will send a broadcast to focusElement.
                 *
                 * @param grp the tab group
                 * @param idx the index within the tab group
                 * @param event keyDown event
                 * @param prevElement sender element
                 */
                var gotoElement = function (grp, idx, event, prevElement) {
                    $rootScope.$broadcast("focusElement", {
                        tabGroup: grp,
                        tabIndex: idx,
                        event: event,
                        prevElement: prevElement
                    });
                };

                /**
                 * Callback function on focus element event.
                 */
                $rootScope.$on("focusElement", function(event, args) {
                    if (args.tabIndex === scope.tabIndex
                        && args.tabGroup === scope.tabGroup) {
                        args.prevElement.blur();
                        var prevUiSelectElement = args.prevElement[0].getElementsByClassName('ui-select-container')[0];
                        if (prevUiSelectElement) {
                            var prevUiSelect = angular.element(prevUiSelectElement).controller('uiSelect');
                            $timeout(function() {
                                prevUiSelect.close();
                            });
                        }
                        if (element[0].nodeName === 'INPUT') {
                            element.focus();
                        } else {
                            var uiSelectElement = element[0].getElementsByClassName('ui-select-container')[0];
                            // if it is a ui-select element
                            if (uiSelectElement) {
                                var uiSelect = angular.element(uiSelectElement).controller('uiSelect');
                                uiSelect.activate();
                            } else {
                                // try to find input elements
                                var inputElement = element[0].getElementsByTagName('input')[0];
                                if (inputElement) {
                                    inputElement.focus();
                                } else {
                                    var buttonElement = element[0].getElementsByTagName('button')[0];
                                    if (buttonElement) {
                                        $timeout(function() {
                                            buttonElement.focus();
                                            //buttonElement.click();
                                        });
                                    }
                                }
                            }
                        }
                        /// the work of tab is done by the directive so remove the default and stop the bubbeling
                        args.event.stopPropagation();
                        args.event.preventDefault();
                    }
                });

                $(element).keydown(function(event) {
                    if (event.keyCode === 9) { /// go inside if tab key is pressed
                        var nextTid = parseInt(scope.tabIndex.toString()) + 1; /// get the next index of element
                        nextTid = Number(nextTid); /// turn the index into number
                        if (nextTid > maxTabIndex[scope.tabGroup]) {
                            gotoElement(scope.tabGroup, 1, event, element);
                        } else {
                            gotoElement(scope.tabGroup, nextTid, event, element); /// go to the next element
                        }
                    }

                });
            }
        };
    }

})();
