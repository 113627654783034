(function() {
    'use strict';

    angular.module('kennwerteApp')
        .factory('ConstructionKindService', ConstructionKindService);

    ConstructionKindService.$inject = [];

    var construction_kind_mapping = {"KEINE_FASSADE":"light","NICHT_ISOLIERT_HOLZ":"light","NICHT_ISOLIERT_BETON":"massive","AUSSENDAEMMUNG_PUTZ_EINFACH":"massive","EINSTEINMAUERWERK_VERPUTZT":"massive","MAUERWERK":"massive","SICHTBETONFASSADE_BETONSANDWICHELEMENT":"massive","HOLZ_EINFACH":"light","AUSSENDAEMMUNG_PUTZ_EDEL":"massive","AUSSENDAEMMUNG_KLEID_EINFACH":"massive","MASSIVBAU_EINFACH":"massive","ZWEISCHALENMAUERWERK_VERPUTZT":"massive","SICHTBETONFASSADE_MISAPOR_MISAPOR":"massive","STAHL_KASSETENWANDFASSADE_EINFACH":"light","HOLZBAU_EINFACH":"light","AUSSENDAEMMUNG_KLEID_EDEL":"massive","SICHTBETONFASSADE_GIPS":"massive","ZWEISCHALENMAUERWERK_SICHTMAUERWERK":"massive","STAHL_KASSETENWANDFASSADE":"light","HOLZ_EDEL":"light","MASSIVBAU_EDEL":"massive","SICHTBETONFASSADE_BACKSTEIN":"massive","SICHTBETONFASSADE_SICHTMAUERWERK":"massive","MAUERWERK_SICHT":"massive","SICHTBETONFASSADE_INNEN_SICHTBETON":"massive","HOLZBAU_MITHINTERLUFT":"light","HOLZBAU_OHNE_MASSIVHOLZ":"light","GLASSFASSADE_GLASSFASSADE":"massive","GLASSFASSADE_GLASSFASSADE_DOPPEL":"massive"};

    function ConstructionKindService() {
        return {
            getConstructionKind: function(facadeType) {
                if (facadeType in construction_kind_mapping) {
                    return construction_kind_mapping[facadeType];
                } else {
                    console.error("facadeType \"" + facadeType + "\" not found in construction_kind_mapping. Assume \"massive\"");
                    return "massive";
                }
            }
        };
    }
})();
